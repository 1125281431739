import { Grid, Typography } from '@mui/material';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { getFormattedDateOnly } from 'helpers/dateFormatter';
import React, { FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { theme } from 'styles/theme';

interface IItineraryWebViewProps {
  onSubmitProcess?: any;
  setOnSubmitProcess?: any;
  setDrawerOpen?: any;
  url: string;
  id: string;
  width: string;
  height: string;
  travelConfirmed?: boolean;
}

export const ItineraryWebView: FC<IItineraryWebViewProps> = props => {
  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setDrawerOpen,
    url,
    id,
    width,
    height,
    travelConfirmed,
  } = props;

  const dispatch = useAppDispatch();

  const homePageStrikeInfo = useAppSelector(
    state => state?.homePageData?.strikeInfo,
  );

  const submit = useCallback(
    () => {
      if (setDrawerOpen) {
        setDrawerOpen(DrawerContentNames.CONFIRM_TRAVEL_DETAILS);
      } else {
        dispatch(
          assignmentActions.setDrawerState(
            DrawerContentNames.CONFIRM_TRAVEL_DETAILS,
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (onSubmitProcess) {
      submit();
      setOnSubmitProcess?.(false);
    }
  }, [onSubmitProcess, setOnSubmitProcess, submit]);

  const extractTimeFromDate = React.useMemo(() => {
    let timeToDisplay = '';
    if (homePageStrikeInfo?.arrivalTime) {
      const dateObj = new Date(homePageStrikeInfo.arrivalTime);

      // Given times for comparison
      const givenTimes = ['9:00am', '11:00am', '1:00pm'];

      // Convert given times into Date objects in the same format as the extracted time
      const formattedGivenTimes = givenTimes.map(givenTime => {
        const [time, suffix] = givenTime.split(/(?=[ap]m)/i);
        const [hours, minutes] = time.split(':').map(Number);
        const formattedGivenTime = new Date(
          dateObj.getFullYear(),
          dateObj.getMonth(),
          dateObj.getDate(),
          hours + (suffix.toLowerCase() === 'pm' && hours !== 12 ? 12 : 0),
          minutes,
        );
        return formattedGivenTime;
      });

      // Compare the extracted time with the given times
      formattedGivenTimes.forEach((formattedGivenTime, index) => {
        if (formattedGivenTime.getTime() === dateObj.getTime()) {
          if (givenTimes[index] === '9:00am') {
            timeToDisplay = '9:00am-11:00am';
          } else if (givenTimes[index] === '11:00am') {
            timeToDisplay = '11:00am-1:00pm';
          } else if (givenTimes[index] === '1:00pm') {
            timeToDisplay = '1:00pm-3:00pm';
          }
        }
      });
    }
    return timeToDisplay;
  }, [homePageStrikeInfo?.arrivalTime]);

  const isDriveTravelConfirmFormActive: boolean = React.useMemo(
    () =>
      !!homePageStrikeInfo &&
      !!homePageStrikeInfo.isDriveTravelConfirmFormActive,
    [homePageStrikeInfo],
  );

  return (
    <>
      <Grid item>
        {!travelConfirmed && (
          <Typography
            color={theme.palette.system.grayText}
            variant="body1"
            lineHeight="20px"
          >
            Please review and confirm your travel details for the Upcoming
            Strike.
          </Typography>
        )}
      </Grid>
      <br />
      {homePageStrikeInfo && isDriveTravelConfirmFormActive ? (
        <>
          <Typography
            color={theme.palette.system.grayText}
            variant="body1"
            lineHeight="20px"
          >
            Details
          </Typography>
          <Typography
            color={`${theme.palette.system.coolGray}`}
            variant="body1"
            sx={{
              marginTop: '12px',
            }}
          >
            {homePageStrikeInfo.startDate &&
              homePageStrikeInfo.arrivalDate &&
              `Driving to the strike event starting on ${getFormattedDateOnly(
                homePageStrikeInfo?.startDate,
                'MMMM D',
              )}, arriving on
          ${getFormattedDateOnly(homePageStrikeInfo.arrivalDate, 'MMMM D')} ${
                extractTimeFromDate?.length && 'between ' + extractTimeFromDate
              }.`}
          </Typography>
        </>
      ) : (
        <>
          <a href={url} target="_blank" rel="noreferrer">
            Click here to view your travel details.
          </a>
          <br />
          <em>* Please note clicking this will open a new tab.</em>
        </>
      )}
    </>
  );
};
