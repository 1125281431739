export enum ItineraryConfirmation {
  CONFIRM = 'confirm',
  CHANGE_TRAVEL_MODE = 'travel-mode-change',
  REMOVE_FROM_STRIKE = 'remove-from-strike',
  REJECT = 'reject',
}

export interface IRadioItems {
  id: string;
  name: string;
  value: string;
  radioLabel: string;
}

export interface DisplayHandler {
  changeRadioSelection: (value: string) => void;
}

export interface IConfirmationItineraryStatements {
  setActionButtonDisabled: (value: boolean) => void;
  isDirty: boolean;
  ref: React.Ref<DisplayHandler>;
}
