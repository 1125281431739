import * as React from 'react';
import { theme } from 'styles/theme';

export default function CredentialControlIcon({
  color = `${theme.palette.system.skyBlue}`,
  height = '23',
  width = '17',
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls_icon_Credentials"
      data-name="Controls/icon/Credentials"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35 35"
    >
      <rect
        id="Rectangle_2270"
        data-name="Rectangle 2270"
        width={width}
        height={height}
        fill={color}
        opacity="0"
      />
      <g id="Group_8888" data-name="Group 8888" transform="translate(6 0.999)">
        <path
          id="Path_6604"
          data-name="Path 6604"
          d="M337.49,390.462H317.246c-.046,0-.105,0-.164,0a3.208,3.208,0,0,1-3.193-3.037,3.431,3.431,0,0,1,0-.357V362.26a3.248,3.248,0,0,1,2.754-3.32c1.109-.252,2.245-.575,3.345-.887.421-.12.845-.24,1.267-.356q5.713-1.576,11.423-3.159l.072-.058.077-.012.9,0a3.636,3.636,0,0,1,2,1.155,3.2,3.2,0,0,1,.74,2.018c.006.326,0,.654,0,.995v.266h1.055c.044,0,.1,0,.159,0a3.223,3.223,0,0,1,3.2,3.395v24.777a3.212,3.212,0,0,1-3.008,3.389c-.063,0-.127.006-.19.006S337.552,390.466,337.49,390.462Zm-20.064-29.538a4.5,4.5,0,0,0-.488.026,1.142,1.142,0,0,0-.992.858,2.927,2.927,0,0,0-.048.646V386.91a4.539,4.539,0,0,0,.025.491,1.15,1.15,0,0,0,.851,1,3,3,0,0,0,.541.049l20.035,0a4.042,4.042,0,0,0,.474-.024,1.068,1.068,0,0,0,.955-.771,2.457,2.457,0,0,0,.087-.735q.011-6.129.005-12.234V362.5a4.712,4.712,0,0,0-.026-.525,1.139,1.139,0,0,0-.852-1,2.964,2.964,0,0,0-.55-.051Zm17.032-2.032,0-.224c.005-.342.011-.68-.011-1.017a1.144,1.144,0,0,0-1.135-1.158,1.126,1.126,0,0,0-.391.07c-1.92.528-3.817,1.055-5.714,1.584l-2.682.744Z"
          transform="translate(-313.884 -354.468)"
          fill={color}
        />
        <path
          id="Path_6605"
          data-name="Path 6605"
          d="M328.062,381.246a8.35,8.35,0,1,1,.005,0Zm-1.87-7.38a11.56,11.56,0,0,0,.8,3.955,5.78,5.78,0,0,0,.774,1.235.359.359,0,0,0,.3.175.337.337,0,0,0,.189-.059.342.342,0,0,0,.1-.1,3.668,3.668,0,0,0,.56-.787,9.029,9.029,0,0,0,.885-3.037c.049-.37.084-.736.119-1.108l.028-.283Zm5.786.007a14.871,14.871,0,0,1-.928,4.548,6.147,6.147,0,0,0,3.2-4.548Zm-10.12-.007a6.164,6.164,0,0,0,3.213,4.545,14.667,14.667,0,0,1-.929-4.545Zm12.408-2.056a6.162,6.162,0,0,0-3.185-4.521c.018.059.036.118.054.178.18.595.367,1.21.5,1.835.129.6.208,1.228.284,1.835.028.224.056.448.087.674Zm-4.312,0-.042-.376c-.053-.5-.105-.98-.187-1.46a7.422,7.422,0,0,0-1.142-3.108c-.121-.169-.315-.38-.519-.38s-.4.211-.528.39a6.528,6.528,0,0,0-.966,2.3,13.97,13.97,0,0,0-.384,2.637Zm-5.811,0a14.824,14.824,0,0,1,.937-4.557,6.2,6.2,0,0,0-3.2,4.557Z"
          transform="translate(-314.562 -355.587)"
          fill={color}
        />
        <path
          id="Path_6606"
          data-name="Path 6606"
          d="M320.536,386.484a1.029,1.029,0,0,1-1.018-.939,1.017,1.017,0,0,1,.917-1.062,2.573,2.573,0,0,1,.36-.025h14.1a2.891,2.891,0,0,1,.484.039.99.99,0,0,1,.84.979,1.01,1.01,0,0,1-.8.973,2.315,2.315,0,0,1-.374.03l-14.491,0-.014-.266Z"
          transform="translate(-314.54 -357.827)"
          fill={color}
        />
        <path
          id="Path_6607"
          data-name="Path 6607"
          d="M323.941,389.828a1.131,1.131,0,0,1-.918-.473.985.985,0,0,1-.154-.749.974.974,0,0,1,.416-.63,1,1,0,0,1,.216-.109,1.516,1.516,0,0,1,.489-.082h8.543a1.152,1.152,0,0,1,.9.365,1,1,0,0,1,.215.818,1.04,1.04,0,0,1-1.161.849h-3.718l-.514,0h-4.2A.659.659,0,0,1,323.941,389.828Z"
          transform="translate(-314.928 -358.199)"
          fill={color}
        />
      </g>
    </svg>
  );
}
