import {
  Box,
  Button,
  Link,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import AddIcon from 'assets/icons/Add/AddIcon';
import EditIcon from 'assets/icons/Edit/EditIcon';
import { TrashIcon } from 'assets/icons/Trash/Trash';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { ReactHookFormDropdown } from 'components/common/Form/Dropdown/ReactHookFormDropdown';
import ProfileLink from 'components/Profile/ProfileLink';
import { UserTypeId } from 'enums/userTypeId';
import { guidGenerator } from 'helpers/guidGenerator';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { cloneDeep } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import {
  setDisciplineOptions,
  setExpertiseFormFields,
} from 'store/slices/user/userPreferenceSlice';
import { theme } from 'styles/theme';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';

export const NurseExpertise = ({
  methods,
  setFormDirty,
}: {
  methods: any;
  setFormDirty?: Function;
}) => {
  const dispatch = useAppDispatch();

  const userProfile: IUserProfile = useAppSelector(state => state.userProfile);
  const expertiseFormFields = useAppSelector(
    state => state.userPreference.expertiseFormFields,
  );
  const disciplineOptions: IDropdownOption[] = useAppSelector(
    state => state.userPreference.disciplineOptions,
  );
  const disciplineList: ISuccessDisciplineResponse = useAppSelector(
    state => state.lookup.disciplines,
  );

  const exhaustiveDisciplineOption: IDropdownOption[] = useAppSelector(
    state => state.userPreference.exhaustiveDisciplineOptions,
  );

  const [showRequired, setShowRequired] = useState<boolean>(false);

  const {
    setValue,
    trigger,
    formState: { isDirty },
  } = methods;

  const addExpertiseFormField = () => {
    const newIndex = expertiseFormFields.length;
    let newField = {
      discipline_name: 'discipline_' + newIndex,
      discipline: '',
      disciplineValue: '',
      specialty_name: 'specialty_' + newIndex,
      specialty: '',
      specialtyValue: '',
      checked: true,
      edit: true,
      verified: false,
      specialtyOptions: [] as IDropdownOption[],
    };
    dispatch(setExpertiseFormFields([...expertiseFormFields, newField]));
    setFormDirty?.(true);
  };

  const removeExpertiseFormField = (
    _event: React.SyntheticEvent,
    index: number,
  ) => {
    const expFormFields = cloneDeep(expertiseFormFields);
    expFormFields.splice(index, 1);
    expFormFields.forEach((obj, idx) => {
      obj.discipline_name = 'discipline_' + idx;
      obj.specialty_name = 'specialty_' + idx;
    });
    dispatch(setExpertiseFormFields(expFormFields));
  };

  const handleDisciplineChange = (
    event: SelectChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    const selectedDisciplineId = event.target.value as string;
    const selectedDiscipline = disciplineList.items.find(
      item => item.disciplineId.toString() === selectedDisciplineId,
    );

    let data = cloneDeep(expertiseFormFields);
    const existingSpecialties: string[] = [] as string[];
    data.forEach(item => {
      if (item.discipline === selectedDisciplineId) {
        existingSpecialties.push(item.specialty);
      }
    });
    const specialtyOptions: IDropdownOption[] = [];
    selectedDiscipline?.specialities?.forEach(specialty => {
      // Don't add existing specialities to specialty options
      if (existingSpecialties.indexOf(specialty.specialtyId.toString()) < 0) {
        specialtyOptions.push({
          key: specialty.specialtyId.toString(),
          value: specialty.specialtyDescription,
        });
      }
    });

    data[index]['discipline'] = selectedDisciplineId;
    data[index]['disciplineValue'] =
      selectedDiscipline?.disciplineDescription || '';
    data[index]['specialtyOptions'] = specialtyOptions;
    data[index]['specialty'] = '';
    setValue(data[index]['discipline_name'], data[index]['disciplineValue']);
    dispatch(setExpertiseFormFields(data));
    trigger(data[index]['discipline_name']);
  };

  const handleSpecialty = (
    event: SelectChangeEvent<{ value: unknown }>,
    index: number,
  ) => {
    const value = event.target.value as string;
    let data = cloneDeep(expertiseFormFields);
    const specialtyObj = data[index].specialtyOptions.find(
      specialty => specialty.key === value,
    );
    data[index]['specialty'] = value;
    data[index]['specialtyValue'] = specialtyObj?.value.toString() || '';
    dispatch(setExpertiseFormFields(data));
    setValue(data[index]['specialty_name'], data[index]['specialtyValue']);
    trigger(data[index]['specialty_name']);
  };

  const handleExpertiseEdit = (e, index) => {
    if (userProfile.userDisciplineTypeId === UserTypeId.NURSE) {
      let data = cloneDeep(expertiseFormFields);
      data[index]['edit'] = true;
      dispatch(setExpertiseFormFields(data));
    }
  };

  const handleExpertiseOnChange = (event, index) => {
    let data = cloneDeep(expertiseFormFields);
    data[index]['checked'] = event?.target.checked;
    dispatch(setExpertiseFormFields(data));
    setFormDirty?.(true);
  };

  useEffect(() => {
    setShowRequired(false);
    if (userProfile.userDisciplineTypeId === UserTypeId.NURSE) {
      let allUnchecked = true;
      expertiseFormFields.forEach(formField => {
        setTimeout(() => {
          setValue(formField.discipline_name, formField.disciplineValue);
          setValue(formField.specialty_name, formField.specialtyValue);
        }, 1);
        if (formField.checked) {
          allUnchecked = false;
        }
      });
      setShowRequired(allUnchecked);
    }

    dispatch(setDisciplineOptions(disciplineList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertiseFormFields, userProfile.userDisciplineTypeId]);

  useEffect(() => {
    setShowRequired(false);
  }, [userProfile.userDisciplineTypeId]);

  function getSelectedItemName(selectedItemId) {
    const selectedItem = disciplineOptions.find(
      item => item.key === selectedItemId,
    );
    return selectedItem ? selectedItem.value : '';
  }

  useEffect(() => {
    if (isDirty) setFormDirty?.(true);
  }, [isDirty, setFormDirty]);

  return (
    <Grid container item id="nurse-expertise" display={'flex'}>
      {expertiseFormFields.map((form, index) => (
        <Fragment key={guidGenerator()}>
          {index === 0 || (index > 0 && form?.edit) ? (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                item
                xs={8}
                pb={index === 0 ? '12px' : 0}
                mt={index === 0 ? 0 : 3}
              >
                {index === 0 ? (
                  <Typography
                    variant="subtitle1"
                    color="system.midnightBlue"
                    lineHeight="22px"
                    textAlign="left"
                    sx={{ display: 'inline-block' }}
                  >
                    Expertise
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    color={theme.palette.system.grayText}
                    lineHeight="19px"
                  >{`Expertise ${index + 1}`}</Typography>
                )}
                {index === 0 && showRequired && (
                  <Typography
                    variant="body1"
                    color="system.red"
                    lineHeight="12px"
                    sx={{ display: 'inline-block', ml: '12px' }}
                  >
                    Required
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                mt={index === 0 ? 0 : 3}
              >
                {index === 0 ? (
                  <></>
                ) : (
                  <Button
                    variant="text"
                    size="xsmall"
                    onClick={e => removeExpertiseFormField(e, index)}
                    sx={{
                      padding: '0px',
                      height: 'auto',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="system.skyBlue"
                      lineHeight="12px"
                      sx={{
                        cursor: 'pointer',
                        textTransform: 'none',
                      }}
                    >
                      <TrashIcon
                        htmlColor={theme.palette.system.skyBlue}
                        style={{
                          paddingRight: '5px',
                          height: '17px',
                          width: 'auto',
                        }}
                      />
                      Delete
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : null}
          <Grid container item key={guidGenerator()}>
            {!form?.edit ? (
              <>
                <Grid item xs={9}>
                  <CheckBox
                    id={guidGenerator()}
                    checked={form.checked}
                    checklabel={`${form.disciplineValue} - ${form.specialtyValue}`}
                    onChange={event => handleExpertiseOnChange(event, index)}
                  />
                </Grid>

                {form?.verified ? (
                  <Grid
                    item
                    xs={3}
                    sx={{
                      paddingBottom: '6px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="system.coolGray"
                      lineHeight="14px"
                      sx={{ fontStyle: 'italic' }}
                    >
                      (Verified)
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={3}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                    onClick={e => handleExpertiseEdit(e, index)}
                  >
                    <Link
                      component="button"
                      underline="hover"
                      variant="body1"
                      sx={{
                        color: theme.palette.system.skyBlue,
                        fontFamily: theme.typography.fontFamily,
                      }}
                    >
                      {EditIcon(theme.palette.system.skyBlue, '17', '17')}
                      <Typography
                        component={'span'}
                        sx={{ paddingLeft: '5px' }}
                      >
                        Edit
                      </Typography>
                    </Link>
                  </Grid>
                )}
              </>
            ) : (
              <Grid container item gap={2} mt={2}>
                <Grid item xs={12} mb={2}>
                  <ReactHookFormDropdown
                    label="Discipline"
                    name={`discipline_${index}`}
                    data={exhaustiveDisciplineOption}
                    excludeExhaustedItem={true}
                    getSelectedItemName={getSelectedItemName}
                    selectedValue={form.discipline}
                    onChange={e => handleDisciplineChange(e, index)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormDropdown
                    label="Specialty"
                    name={`specialty_${index}`}
                    data={form.specialtyOptions}
                    selectedValue={form.specialty}
                    onChange={e => handleSpecialty(e, index)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Fragment>
      ))}

      <Grid container item mt={4}>
        <Grid item>
          <Box onClick={addExpertiseFormField}>
            <ProfileLink
              id="add-expertise"
              underline="hover"
              leftIcon={AddIcon(theme.palette.system.skyBlue, '17', '17')}
            >
              <Box role="button" aria-label="expertise-add-btn">
                Add Expertise
              </Box>
            </ProfileLink>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
