import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, useMediaQuery } from '@mui/material';
import {
  IAlliedDisciplineAndSpecialties,
  ISpecialty,
} from 'components/Jobs/JobSearch/JobPreference/IAlliedDisciplineAndSpecialties';
import { IFacilityType } from 'components/Jobs/JobSearch/JobPreference/IFacilityType';
import { IJobType } from 'components/Jobs/JobSearch/JobPreference/IJobType';
import { IShiftLength } from 'components/Jobs/JobSearch/JobPreference/IShiftLength';
import { IShiftTime } from 'components/Jobs/JobSearch/JobPreference/IShiftTime';
import { alterAllOptions } from 'components/Jobs/JobSearch/JobPreference/utils';
import { AppButton } from 'components/common/Button/Button';
import { AppCircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { ConfirmationPopup } from 'components/common/ConfirmationPopup/ConfirmationPopup';
import AlertDialog from 'components/common/Dialog/ErrorDialog';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { TOAST_MESSAGE } from 'constants/helperText';
import { LockoutMessageRow } from 'containers/LoginContainer/LoginWrapper.styles';
import { StatusCode } from 'enums/StatusCode';
import { AlertBarType } from 'enums/alertBarTypes';
import { DrawerButtonTypes } from 'enums/buttons';
import { DataLayerFormType } from 'enums/dataLayer';
import { searchRadiusEnum } from 'enums/searchRadiusEnums';
import { UserTypeId } from 'enums/userTypeId';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { closeAlert, openAlert } from 'store/slices/alertbar/alertbarSlice';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import {
  postUserPreference,
  resetIsUserPreferenceUpdated,
  resetPreferredLocations,
  unSetError,
} from 'store/slices/user/userPreferenceSlice';
import { desktopWidth } from 'styles/media';
import { theme } from 'styles/theme';
import {
  IJobDisciplineAndSpecialties,
  ILocationSearch,
  ILocationSearchResponse,
  UserPreference,
} from 'types/UserPreference';
import useTrackPageView from 'utils/hooks/useTrackPageView';
import * as Yup from 'yup';
import { IJobPreferenceProps } from './IJobPreferenceProps';
import { JobPreferenceForm } from './JobPreferenceForm';

const JobPreference: FC<IJobPreferenceProps> = ({
  open,
  toggleDrawer,
  withDrawer = true,
}) => {
  const DEFAULT_RADIUS = `${searchRadiusEnum.DEFAULT_RADIUS}`;

  const matchesMinWidth1024 = useMediaQuery(desktopWidth);
  const dispatch = useAppDispatch();

  const isJobPreferenceLoaderActive = useAppSelector(
    state => state.userPreference.isSubmitLoaderActive,
  );
  const userId = useAppSelector(state => state.auth.userId);
  const userPreference: UserPreference | null = useAppSelector(
    state => state.userPreference.userPreference,
  );
  const isUserPreferenceUpdated = useAppSelector(
    state => state.userPreference.isUserPreferenceUpdated,
  );
  const userProfile: IUserProfile = useAppSelector(state => state.userProfile);
  const error: IErrorResponse = useAppSelector(
    state => state.userPreference.errorResponse,
  );
  const expertiseFormFields = useAppSelector(
    state => state.userPreference.expertiseFormFields,
  );
  const strtDate = useAppSelector(state => state.userPreference.startDate);
  const alliedDisciplineAndSpecialties: IAlliedDisciplineAndSpecialties[] =
    useAppSelector(
      state => state.userPreference.alliedDisciplineAndSpecialties,
    );
  const facilityTypes = useAppSelector(
    state => state.userPreference.facilityTypes,
  );
  const jobTypes = useAppSelector(state => state.userPreference.jobTypes);
  const shiftLengths = useAppSelector(
    state => state.userPreference.shiftLengths,
  );
  const shiftTimes = useAppSelector(state => state.userPreference.shiftTimes);
  const isErrorDialogShow: boolean = useAppSelector(
    state => state.userPreference.error,
  );
  const locations = useAppSelector(state => state.userPreference.locations);
  const submitLoaderActive = useAppSelector(
    state => state.userPreference.isSubmitLoaderActive,
  );

  const [errorMessage, setErrorMessage] = useState({ title: '', message: '' });
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [disciplineError, setDisciplineError] = useState(false);

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isHandleProceedEnabled, setIsHandleProceedEnabled] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  //////////// Form Validations //////////////
  const [schemaObject, setSchemaObject] = useState({});

  const validationSchema = Yup.object(schemaObject);
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });
  const { handleSubmit, reset } = methods;
  //////////// End Form Validations //////////////

  const handleErrorDialogClose = () => {
    setIsSaveChanges(false);
    dispatch(unSetError());
  };

  const onError = data => {
    if (userProfile.userDisciplineTypeId === UserTypeId.ALLIED) {
      setDisciplineError(true);
    }
  };

  useTrackPageView('JobPreferences');

  const locationResponseToLocation = (
    locationResponse: ILocationSearchResponse,
  ): ILocationSearch => {
    return {
      searchText: locationResponse.searchText,
      cityInfo: locationResponse.cityInfo
        ? {
            cityId: locationResponse.cityInfo?.cityId,
            city: locationResponse.cityInfo.city,
          }
        : null,
      stateInfo: locationResponse.stateInfo
        ? {
            stateId: locationResponse.stateInfo?.stateId,
            stateDescription: locationResponse.stateInfo?.stateName,
          }
        : null,
      regionInfo: locationResponse.regionInfo
        ? {
            regionId: locationResponse.regionInfo?.regionId,
            region: locationResponse.regionInfo.region,
          }
        : null,
    };
  };

  const [submitCount, setSubmitCount] = useState(0);

  const isInCompactState = useAppSelector(
    state => state.userPreference.isInCompactState,
  );

  const handleSetFormDirty = (isDirty: boolean) => {
    setFormDirty(prev => isDirty);
  };

  const secondaryClick = useCallback(() => {
    if (formDirty) {
      setShowConfirmationPopup(true);
      setIsHandleProceedEnabled(true);
    } else {
      toggleDrawer?.(null, false);
    }
  }, [formDirty, toggleDrawer]);

  const handleDiscard = () => {
    setShowConfirmationPopup(false);
    setIsHandleProceedEnabled(false);
    handleSetFormDirty(false);
    toggleDrawer?.(null, false);
  };

  const handleCancelClick = () => {
    setShowConfirmationPopup(false);
    setIsHandleProceedEnabled(false);
  };

  const onSubmit = data => {
    setSubmitCount(prev => prev + 1);
    if (data['startDate'] === '') {
      data['startDate'] = moment(new Date());
    }
    setIsSaveChanges(true);
    let locationsToSave = [] as ILocationSearch[];
    locations.forEach(location => {
      if (location.searchText) {
        const loc = locationResponseToLocation(location);

        if (loc.cityInfo) {
          const cityInformation = { ...loc.cityInfo };
          cityInformation.radius = parseInt(DEFAULT_RADIUS);
          loc.cityInfo = { ...cityInformation };
        }
        locationsToSave.push(loc);
      }
    });
    const disciplinesAndSpecialties = [] as IJobDisciplineAndSpecialties[];
    if (userProfile.userDisciplineTypeId === UserTypeId.NURSE) {
      expertiseFormFields.forEach(formObj => {
        formObj.disciplineValue &&
          disciplinesAndSpecialties.push({
            disciplineId: parseInt(formObj.discipline),
            discipline: formObj.disciplineValue,
            specialtyId: parseInt(formObj.specialty),
            specialty: formObj.specialtyValue,
            checked: formObj.checked,
          });
      });
    } else if (userProfile.userDisciplineTypeId === UserTypeId.ALLIED) {
      alliedDisciplineAndSpecialties.forEach(disciplineObj => {
        let checkedSpecialties: ISpecialty[] = [];
        if (disciplineObj.specialities.length > 0) {
          checkedSpecialties = disciplineObj.specialities.filter(
            s => s.checked,
          );
        }
        if (
          !disciplineObj.anySpecialty &&
          (disciplineObj.specialities.length < 1 ||
            checkedSpecialties.length < 1)
        ) {
          disciplinesAndSpecialties.push({
            disciplineId: parseInt(disciplineObj.disciplineId),
            discipline: disciplineObj.discipline,
            specialtyId: null,
            specialty: null,
            checked: false,
          });
        } else {
          if (disciplineObj.anySpecialty) {
            disciplinesAndSpecialties.push({
              disciplineId: parseInt(disciplineObj.disciplineId),
              discipline: disciplineObj.discipline,
              specialtyId: null,
              specialty: null,
              checked: true,
            });
          } else {
            disciplineObj.specialities.forEach(specialtyObj => {
              const specialtyChecked = disciplineObj.anySpecialty
                ? true
                : specialtyObj.checked;
              disciplinesAndSpecialties.push({
                disciplineId: parseInt(disciplineObj.disciplineId),
                discipline: disciplineObj.discipline,
                specialtyId: parseInt(specialtyObj.specialtyId),
                specialty: specialtyObj.specialtyDescription,
                checked: specialtyChecked,
              });
            });
          }
        }
      });
    }

    // Job types
    let tempJobTypes = [...jobTypes];
    if (tempJobTypes[0]['isActive']) {
      tempJobTypes = alterAllOptions(tempJobTypes, false) as IJobType[];
    }
    tempJobTypes.splice(0, 1);

    // Shift times
    let tempShiftTimes = [...shiftTimes];
    if (tempShiftTimes[0]['isActive']) {
      tempShiftTimes = alterAllOptions(tempShiftTimes, false) as IShiftTime[];
    }
    tempShiftTimes.splice(0, 1);

    // Shift lengths
    let tempShiftLengths = [...shiftLengths];
    if (tempShiftLengths[0]['isActive']) {
      tempShiftLengths = alterAllOptions(
        tempShiftLengths,
        false,
      ) as IShiftLength[];
    }
    tempShiftLengths.splice(0, 1);

    // Facility types
    let tempFacilityTypes = [...facilityTypes];
    if (tempFacilityTypes[0]['isActive']) {
      tempFacilityTypes = alterAllOptions(
        tempFacilityTypes,
        false,
      ) as IFacilityType[];
    }
    tempFacilityTypes.splice(0, 1);

    // Check for atleast one checked discipline
    const atleastOneChecked = disciplinesAndSpecialties.some(
      item => item.checked,
    );

    const disciplinesAndSpecialtiesForPersona = (
      disc: IJobDisciplineAndSpecialties[],
    ) => {
      switch (userProfile.userDisciplineTypeId) {
        case UserTypeId.NURSE:
          return disc.filter(d => d.disciplineId);
        case UserTypeId.ALLIED:
          return disc.filter(
            d =>
              d.disciplineId &&
              (d.checked === true ||
                (d.checked === false &&
                  d.specialtyId === null &&
                  d.specialty === null)),
          );
        default:
          return [];
      }
    };

    if (!atleastOneChecked) {
      onError({});
    } else {
      const jobs = { ...userPreference?.jobs };
      jobs.locations = locationsToSave;
      jobs.disciplinesAndSpecialties = disciplinesAndSpecialtiesForPersona(
        disciplinesAndSpecialties,
      );

      jobs.placementTypes = [...tempJobTypes];
      jobs.shifts = [...tempShiftTimes, ...tempShiftLengths];
      jobs.facilityTypes = [...tempFacilityTypes];
      jobs.isInCompactState = isInCompactState;
      if (data && data.startDate !== 'Invalid date') {
        jobs.startDate = moment(data.startDate).format('MM-DD-YYYY');
        dispatch(
          postUserPreference({
            id: userPreference?.id,
            userId: userId,
            jobs,
            match: { ...userPreference?.match },
            feedback: { ...userPreference?.feedback },
            notifications: { ...userPreference?.notifications },
          }),
        );
      }
    }
    dispatch(
      dataLayerActions.formCompleteAction(DataLayerFormType.JOB_PREFERENCES),
    );
  };

  useEffect(() => {
    const scmObject = {};
    scmObject['startDate'] = Yup.string()
      .test(
        'Invalid Date',
        d => 'Invalid Date',
        value => {
          let dateValid = true;
          if (value) {
            dateValid = moment(value).isValid() || value === '';
          }
          return dateValid;
        },
      )
      .optional();
    if (userProfile.userDisciplineTypeId === UserTypeId.NURSE) {
      expertiseFormFields.forEach(f => {
        scmObject[f.discipline_name] = Yup.string().required(
          'This field is required',
        );
        scmObject[f.specialty_name] = Yup.string().required(
          'This field is required',
        );
      });
    }
    setSchemaObject(scmObject);
  }, [expertiseFormFields, strtDate]);

  useEffect(() => {
    /**
     * Reset redux errors on steps to prevent error leaking on different pages.
     */
    return () => {
      dispatch(unSetError());
      dispatch(closeAlert({ alertbarRefId: AlertBarType.JOB_PREFERENCES }));
      if (submitCount === 0) {
        dispatch(resetPreferredLocations());
      }
      setSubmitCount(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isErrorDialogShow) {
      setErrorMessage({
        // eslint-disable-next-line quotes
        title: "Can't Continue",
        message: 'Please select Discipline and Specialty or Delete Expertise.',
      });
    }
    return () => {
      dispatch(closeAlert({ alertbarRefId: AlertBarType.JOB_ALERT }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorDialogShow, isSaveChanges]);

  useEffect(() => {
    if (error && (error.errorCode ?? 0) >= StatusCode.BadRequest) {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
    }
    return () => {
      dispatch(closeAlert({ alertbarRefId: AlertBarType.JOB_ALERT }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.errorCode]);

  useEffect(() => {
    if (!open) {
      reset();
      dispatch(resetIsUserPreferenceUpdated());
    }

    if (isUserPreferenceUpdated?.success) {
      toggleDrawer?.(null, false, true);

      return () => {
        dispatch(resetIsUserPreferenceUpdated());
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isUserPreferenceUpdated?.success]);

  return (
    <>
      <AlertDialog
        open={isSaveChanges && isErrorDialogShow}
        handleClose={handleErrorDialogClose}
        title={errorMessage.title}
        dismissText="DISMISS"
      >
        <LockoutMessageRow>{errorMessage.message}</LockoutMessageRow>
      </AlertDialog>
      {withDrawer ? (
        <DrawerPanel
          contentName="Job Preferences"
          toggleDrawer={toggleDrawer}
          open={open || isJobPreferenceLoaderActive}
          actionButtonText="Save Changes"
          actionButtonType={DrawerButtonTypes.SUBMIT}
          primaryOnClick={handleSubmit}
          onSubmit={onSubmit}
          onError={onError}
          isSubmitLoaderActive={isJobPreferenceLoaderActive}
          alertId={AlertBarType.JOB_PREFERENCES}
          secondaryOnClick={secondaryClick}
          drawerCloseValidation={secondaryClick}
          secondaryButtonType={DrawerButtonTypes.POPUP}
        >
          {error && (error.status ?? 0) >= StatusCode.BadRequest ? (
            <></>
          ) : (
            <JobPreferenceForm
              methods={methods}
              disciplineError={disciplineError}
              setDisciplineError={setDisciplineError}
              withDrawer={withDrawer}
              userDisciplineId={userProfile.userDisciplineTypeId}
              setFormDirty={handleSetFormDirty}
            />
          )}
        </DrawerPanel>
      ) : (
        <>
          <JobPreferenceForm
            methods={methods}
            disciplineError={disciplineError}
            setDisciplineError={setDisciplineError}
            userDisciplineId={userProfile.userDisciplineTypeId}
          />
          <Grid item xs={12}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                pt: '22px',
                position: { xs: 'fixed', sm: 'unset' },
                bottom: { xs: 0, sm: 'auto' },
                background: { xs: theme.palette.system.white, sm: 'auto' },
                paddingBottom: { xs: '24px', sm: 0 },
              }}
            >
              <AppButton
                id="save-button"
                type="submit"
                size={matchesMinWidth1024 ? 'md' : 'xl'}
                variant="primary"
                onClick={handleSubmit(onSubmit)}
                onKeyDown={handleSubmit(onSubmit)}
              >
                Save Changes
              </AppButton>
            </Box>
          </Grid>
          {submitLoaderActive && <AppCircularProgress />}
        </>
      )}
      {showConfirmationPopup && (
        <ConfirmationPopup
          title="Are you sure?"
          content="You have unsaved changes on this page, are you sure you want to discard them?"
          isPanelOpen={showConfirmationPopup}
          handleClose={handleCancelClick}
          negative="Cancel"
          isHandleProceedEnabled={isHandleProceedEnabled}
          handleProceed={handleDiscard}
          affirmative="Discard"
        />
      )}
    </>
  );
};

export { JobPreference };
