import { http } from 'services/BaseApi';
import {
  IAssignmentRating,
  IAssignmentRatingRequest,
} from 'types/AssignmentRating';

export const fetchAssignmentRating = async (
  userId: string,
  placementId: number,
): Promise<IAssignmentRating> => {
  return await http.get(`/ratings/assignment/${userId}/${placementId}`);
};

export const saveAssignmentRating = async (
  userId: string,
  payload: IAssignmentRatingRequest,
): Promise<string> => {
  return await http.post(`/ratings/assignment/${userId}`, payload);
};
