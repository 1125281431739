import { Divider, Grid, Skeleton, Typography } from '@mui/material';
import { ContractStatusIdStates } from 'enums/contracts';
import { IContract } from 'interfaces/Contracts/Contract';
import React from 'react';
import { useAppSelector } from 'store/hooks';

const ContractPanelSkeleton = () => {
  const currentContract: IContract = useAppSelector(
    state => state.contracts.currentContract,
  );
  return (
    <>
      <Grid container mb={4}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant="rectangular"
            height="27px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />

          <Skeleton
            variant="rectangular"
            height="27px"
            sx={{ maxWidth: '90px', width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="27px"
            sx={{ maxWidth: '230px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid pt={1} container>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="27px"
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid pt={1} container>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height="27px"
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '100px', width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid container pt={3}>
        <Grid item xs={3}>
          <Skeleton
            variant="rectangular"
            height="27px"
            sx={{ maxWidth: '80px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={9}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '200px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        </Grid>
        <Grid item pt={1} xs={12}>
          <Skeleton
            variant="rectangular"
            height="20px"
            sx={{ maxWidth: '300px', width: '100%' }}
          />
        </Grid>
      </Grid>

      <Divider
        sx={{
          my: 4,
          width: '100%',
          height: '1px',
        }}
      />

      {currentContract?.statusId === ContractStatusIdStates.READY_TO_SIGN ? (
        <Grid container mb={4}>
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Skeleton
              variant="rectangular"
              height="27px"
              sx={{ maxWidth: '20px', width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={11}
            pt={3}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Skeleton
              variant="rectangular"
              height="50px"
              sx={{ maxWidth: '90%', width: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            pt={3}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Skeleton
              variant="rectangular"
              height="60px"
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container mb={4}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="subtitle1"
              color="system.coolGray"
              fontWeight="700"
            >
              AGREED AND ACCEPTED
            </Typography>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={3}
              mt={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" color="system.coolGray">
                Professional:
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              mt={2}
              pl={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="20px"
                sx={{ maxWidth: '90px', width: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={3}
              mt={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" color="system.coolGray">
                Traveler ID:
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              mt={2}
              pl={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="20px"
                sx={{ maxWidth: '90px', width: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={3}
              mt={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" color="system.coolGray">
                Signed:
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              mt={2}
              pl={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="20px"
                sx={{ maxWidth: '140px', width: '100%' }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              mt={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography py={2} variant="body1" color="system.coolGray">
                AMN Services, Inc. By:{' '}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="50px"
                sx={{ maxWidth: '200px', width: '100%' }}
              />
            </Grid>
            <Grid
              item
              pt={1}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="20px"
                sx={{ maxWidth: '100px', width: '100%' }}
              />
            </Grid>
            <Grid
              item
              pt={1}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="20px"
                sx={{ maxWidth: '100px', width: '100%' }}
              />
            </Grid>
            <Grid
              item
              pt={1}
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                height="20px"
                sx={{ maxWidth: '100px', width: '100%' }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ContractPanelSkeleton;
