import { makeStyles } from '@mui/styles';

export const ContactCardImageStyles = makeStyles(theme => ({
  imageBox: {
    padding: '0px',
    margin: '0px',
    border: `1px solid ${theme.palette?.system?.gray}`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarText: {
    lineHeight: '33px',
  },
}));
