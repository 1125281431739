import { FeatureToggles } from 'enums/featureToggles';
import { useAppSelector } from 'store/hooks';

export const useFeatureToggle = (FeatureToggle: FeatureToggles) => {
  const featureToggles = useAppSelector(
    state => state.userProfile.featureToggles,
  );

  /**
   * This hook may be accessed before the feature toggles state is initialized. If so, return undefined.
   * In this case, wait for the value to resolve to true or false.
   */
  if ((featureToggles?.length || 0) === 0) {
    return undefined;
  }

  return featureToggles?.find(el => el.key === FeatureToggle)?.value ?? false;
};
