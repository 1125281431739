import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Radio, Typography } from '@mui/material';
import { IRadioButtonProps } from 'interfaces/Props/IRadioButtonProps';
import { useFormContext } from 'react-hook-form';

export const RadioButton = (props: IRadioButtonProps) => {
  const {
    id,
    name,
    value,
    radioLabel,
    radioStyle,
    radioClassName,
    labelStyle,
    labelClassName,
    checked,
    disabled,
    radioGroup,
    sx,
    className,
    formInputLabelStyle,
  } = props;

  const { register } = useFormContext();

  return (
    <FormControlLabel
      id={`formControlLabel_${id}`}
      key={`formControlLabel_${id}`}
      {...register(name)}
      disabled={disabled ? true : false}
      className={className}
      radioGroup={radioGroup}
      control={
        <Radio
          id={id}
          key={id}
          value={value}
          checked={checked}
          disabled={disabled}
          readOnly={disabled}
          className={radioClassName}
          sx={{
            ...sx,
            color: `${radioStyle?.borderColor ?? 'system.coolGray'}`,
            '&.Mui-checked': {
              color: `${radioStyle?.fillColor ?? 'system.skyBlue'}`,
            },
            '&.Mui-disabled': {
              color: `${radioStyle?.fillColor ?? 'system.gray'}`,
            },
            '& .MuiSvgIcon-root': { fontSize: `${radioStyle?.size ?? 20}px` },
          }}
        />
      }
      label={
        <Typography className={labelClassName} sx={{ ...labelStyle }}>
          {radioLabel}
        </Typography>
      }
      sx={{
        ...formInputLabelStyle,
        color: 'system.midnightBlue',
      }}
    />
  );
};
