import { CancelTokenSource } from 'axios';
import { http } from 'services/BaseApi';
import { IFeaturedContentResponse } from 'types/FeaturedContentResponse';

/**
 * Fetch Featured content
 * @param offset Offset number as string
 * @param limit Limit number as string
 * @returns
 */
export const fetchFeaturedContent = async (
  userId: string,
  offset: string,
  limit: string,
): Promise<IFeaturedContentResponse> => {
  return await http.get<IFeaturedContentResponse>(
    `/featured-content/${userId}?&offset=${offset}&limit=${limit}`,
  );
};

export const getSecureImage = async (url: string): Promise<any> => {
  return await http.get<string>(url);
};

export async function fetchPdf(url: string) {
  return await http
    .getWithoutAuthorization(url + '/url')
    .then(response => response.fileUrl);
}

export async function downloadPdfFromBlobUrl(url: string) {
  const fileUrl = await http.get(`${url}/url`);
  const pdfString = await http.getFetchFile(fileUrl?.data?.fileUrl);

  return URL.createObjectURL(pdfString);
}

export async function getImageByUrl(url: string, source?: CancelTokenSource) {
  return await http
    .get<string>(
      `${url}/url`,
      source
        ? {
            cancelToken: source.token,
          }
        : {},
    )
    .then(response => response.data?.fileUrl);
}
export const getSecureContent = async (url: string): Promise<any> => {
  return await http.get<string>(url).then(response => response.data);
};

// Promises to get Files formatted correctly
export const getUrl = url => {
  return new Promise((resolve, reject) => {
    fetch(url).then(res => {
      if (res) {
        const buf = res.arrayBuffer();
        resolve(buf);
      } else {
        reject('Error');
      }
    });
  });
};

export async function activateLead(userId: string) {
  return await http.put(`/user/${userId}/activate`).then(response => {
    return response.data.isCreated;
  });
}
