import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IReferral } from 'interfaces/Referral/IReferral';
import { IReferralState } from './referralState';

const initialState: IReferralState = {
  referrals: {} as IReferral,
  isLoading: false,
};

export const referralSlice = createSlice({
  name: 'referralSlice',
  initialState,
  reducers: {
    getReferrals(state) {
      state.isLoading = true;
    },
    setReferrals(state, action: PayloadAction<IReferral>) {
      state.referrals = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        isLoading: true,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
  },
});

export const { getReferrals, setReferrals, setError } = referralSlice.actions;
export default referralSlice.reducer;
