import { IAssignmentSummary } from 'interfaces/Assignment/AssignmentSummary';
import {
  IExtendAssignment,
  IExtendAssignmentResponse,
} from 'interfaces/Assignment/ExtendAssignment';
import { ISuccessResponse } from 'interfaces/Common/ICommonContract';
import { http } from 'services/BaseApi';

/**
 * Method to get assignment data based on user id and status (booked=13,onAssignment=23)
 *
 * @param userId - user Id of the logged in user
 */
export const getAssignmentsByStatus = async (
  userId: string,
): Promise<IAssignmentSummary> => {
  const limit = 100;
  const assignmentStatus = `1,2,3,6,8,9,10,11,12,13,14,15,16,17,18,21,22,23,24,25,26,27,28,29`;
  try {
    return await http.get<string>(
      `/v2/assignments/${userId}?status=${assignmentStatus}&offset=0&limit=${limit}`,
    );
  } catch (error) {
    console.log(error);
    return error as any;
  }
};

export const getAssignmentDetails = async (
  userId: string,
  activePlacementId: number,
): Promise<any> => {
  try {
    return await http.get(`/assignments/${userId}/${activePlacementId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postAssignmentExtension = async (
  userId: string,
  placementId: number,
  extendAssignmentData: IExtendAssignment,
): Promise<IExtendAssignmentResponse> => {
  return await http.post(
    `/assignments/${userId}/${placementId}/extend`,
    extendAssignmentData,
  );
};

export const fetchAssignments = async (
  userId: string,
  status: string,
): Promise<IAssignmentSummary> => {
  return await http.get<string>(`/v2/assignments/${userId}?status=${status}`);
};

export const saveWiselyPaycardInfo = async (
  userId: string,
  placementId: string,
  optIn: boolean,
): Promise<ISuccessResponse> => {
  return await http.post(`/assignments/${userId}/${placementId}/pay-card`, {
    optIn: optIn,
  });
};

export const deleteAssignmentItemForStrike = async (
  userId: string,
  placementId: string,
): Promise<{
  success: boolean;
}> => {
  return await http.delete<string>(`/assignments/${userId}/${placementId}`);
};

export const postItineraryConfirmation = async (
  userId: string,
  placementId: number,
  confirmItineraryData: { travelConfirmed: boolean },
): Promise<boolean> => {
  return await http.post<any>(
    `v2/assignments/${userId}/${placementId}/travel-confirm`,
    confirmItineraryData,
  );
};

export const dismissCancelledAssigments = async (
  userId: string,
  placementId: number,
): Promise<boolean> => {
  return await http.post<any>(`assignments/${userId}/${placementId}/dismiss`);
};

export const strikeTravelDateConfirm = async (
  userId: string,
  placementId: number,
): Promise<boolean> => {
  return await http.post<any>(
    `assignments/${userId}/${placementId}/travel-dates-confirm`,
    {
      travelDatesConfirmed: true,
    },
  );
};

export const strikeTravelAcknowledgement = async (
  userId: string,
  placementId: number,
): Promise<boolean> => {
  return await http.post<any>(
    `assignments/${userId}/${placementId}/travel-acknowledgment`,
    {
      hasAccepted: true,
    },
  );
};

export const extensionOfferConfirmationApi = async (
  userId: string,
  placementId: number,
  hasExtensionConfirmed: boolean,
): Promise<boolean> => {
  return await http.post<any>(
    `assignments/${userId}/${placementId}/extend-strike-consecutive-wave`,
    {
      hasExtensionConfirmed,
    },
  );
};
