import { Card, Grid, Typography } from '@mui/material';
import AMNQRCode from 'components/common/AMNQRCode/AMNQRCode';
import ProfileAvatar from 'components/Profile/ProfileAvatar';
import { AvatarTextVariant } from 'enums/avatarTextVariant';
import { IAMNBadgeProps } from 'interfaces/Props/IAMNBadgeProps';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/configureStore';
import { setPhotoEditingState } from 'store/slices/photo/photoSlice';
import { theme } from 'styles/theme';
import amnLogo from '../../assets/images/amn-healthcare-logo/amn-healthcare-logo.svg';
import useTrackPageView from 'utils/hooks/useTrackPageView';

const AMNBadge: FC<IAMNBadgeProps> = ({
  imageUrl,
  travelerId,
  firstName,
  lastName,
  discipline,
  specialty,
}) => {
  const dispatch = useAppDispatch();
  useTrackPageView('AMNBadge');

  const downloadError = useSelector((state: RootState) => {
    return state.error.downloadError;
  });

  const handleOpenPhotoEditor = () => {
    dispatch(setPhotoEditingState(true));
  };

  return (
    <>
      <Card
        sx={{
          width: { xs: '327px', sm: '350px' },
          height: '475px',
          boxShadow: `0px 1px 15px ${theme.palette.system.darkShadowGray}`,
          borderRadius: '10px',
        }}
      >
        <Grid
          container
          p={4}
          gap={4}
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <img src={amnLogo} width="100px" alt="amn-logo" />
          </Grid>
          <Grid item>
            <ProfileAvatar
              image={imageUrl}
              border={`2px solid ${
                !imageUrl && downloadError
                  ? theme.palette.system.error
                  : theme.palette.system.purple
              }`}
              onClick={handleOpenPhotoEditor}
              placeholderVariant={AvatarTextVariant.FIRST_LAST}
              userName={`${firstName} ${lastName}`}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h2"
              lineHeight="14px"
              color={theme.palette.system.midnightBlue}
              fontWeight={500}
            >
              {`${firstName} ${lastName}`}
            </Typography>
          </Grid>
          <Grid
            container
            item
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                color={theme.palette.system.purple}
                lineHeight="28px"
                fontWeight={500}
              >
                {discipline}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                color={theme.palette.system.midnightBlue}
                lineHeight="28px"
                fontWeight={500}
              >
                Traveler ID: {travelerId}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <AMNQRCode
              value={`{"Name": "${firstName} ${lastName}", "TravelerId": "${travelerId}", "DisciplineName": "${discipline}", "Specialties": ["${specialty}"], "CompanyName": "AMN Healthcare"}`}
              size={75}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AMNBadge;
