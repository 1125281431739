import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ISimilarJobsRequest } from 'interfaces/Jobs/ISimilarJobsRequest';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as service from 'services/jobs/jobService';
import { setError } from 'store/slices/error/errorSlice';
import { similarJobsActions } from 'store/slices/jobs/similarJobsSlice';

const trackedSimilarJobsData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-similar-jobs');

export function* getSimilarJobsSaga(action) {
  const similarJobsRequest = action.payload as ISimilarJobsRequest;
  try {
    const response = yield call(
      trackedSimilarJobsData,
      service.fetchSimilarJobs,
      similarJobsRequest,
    );
    const { data } = response;
    if (response && data !== '') {
      if (data) {
        yield put(similarJobsActions.setSimilarJobs(data));
      }
    } else {
      yield put(similarJobsActions.resetSimilarJobs());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

export function* similarJobsSaga() {
  yield all([
    takeLatest(
      similarJobsActions.fetchSimilarJobsAction.type,
      getSimilarJobsSaga,
    ),
  ]);
}
