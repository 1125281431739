import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

export const CustomerSupport = ({
  text = 'For questions',
  displayType = 'page',
  color = 'system.coolGray',
  numberColor = 'system.midnightBlue',
  sx = {},
}: {
  text?: string;
  displayType?: 'page' | 'popup' | 'pageLeft';
  color?: string;
  numberColor?: string;
  sx?: SxProps;
}) => {
  return (
    <>
      {displayType === 'popup' && (
        <Typography
          component="span"
          variant="body1"
          color={color}
          fontWeight={400}
          lineHeight="20px"
          textAlign="left"
          sx={{
            width: { xs: '280px', sm: '100%' },
            ...sx,
          }}
        >
          <Box>{text},</Box>
          <Box>please call Customer Support at</Box>
          <Box fontWeight="500" display="inline" color={numberColor}>
            <strong> (877) 777-8086 </strong>
          </Box>
          <Box>(Mon - Fri, 4 AM - 7 PM PST).</Box>
        </Typography>
      )}

      {(displayType === 'page' || displayType === 'pageLeft') && (
        <Typography
          component="span"
          variant="body1"
          color={color}
          fontWeight={400}
          lineHeight="20px"
          textAlign={displayType === 'page' ? 'center' : 'left'}
          sx={{
            width: { xs: '280px', sm: '100%' },
            ...sx,
          }}
        >
          <Box>{text}, please call</Box>
          <Box display="inline">Customer Support at</Box>
          <Box fontWeight="500" display="inline" color={numberColor}>
            <strong> (877) 777-8086 </strong>
          </Box>
          <Box>(Mon - Fri, 4 AM - 7 PM PST).</Box>
        </Typography>
      )}
    </>
  );
};
