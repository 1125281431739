import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActionItem } from 'interfaces/ActionItem/IActionItem';
import { ActionItemElement } from 'interfaces/ActionItem/IActionitemElement';
import { IActionItems } from 'interfaces/ActionItem/IActionItems';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ActionItemState } from './actionItemState';

const initialState: ActionItemState = {
  actionItems: [] as IActionItems[],
  removeActionItems: [],
  isLoading: false,
  selectedItem: {} as IActionItems,
  selectedClearanceItem: {} as IActionItem,
};

const dismissActionItemForClearanceItem = createAction(
  'DISMISS_ACTION_ITEM_FOR_CLEARANCE_ITEM',
);

export const actionItemSlice = createSlice({
  name: 'actionItemSlice',
  initialState,
  reducers: {
    getActionItem(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },
    setActionItem(state, action: PayloadAction<ActionItemElement>) {
      let actionItemElement = action.payload;
      state.actionItems = actionItemElement.actionItems;
      state.isLoading = false;
    },
    removeActionItem(state, action) {},
    setDismissActionItem(state, action: PayloadAction<string>) {
      let itemToRemove = state.actionItems?.find(
        a => a.id === action.payload,
      ) as IActionItems;
      state.removeActionItems.push(itemToRemove);
      state.actionItems = state.actionItems.filter(
        a => a.id !== action.payload,
      );
    },
    removeDismissActionItem(state, action: PayloadAction<string>) {
      let itemToRestore = state.removeActionItems?.find(
        a => a.id === action.payload,
      ) as IActionItems;
      state.actionItems.push(itemToRestore);
      state.removeActionItems = state.removeActionItems.filter(
        a => a.id !== action.payload,
      );
    },
    setSelectedItem(state, action: PayloadAction<any>) {
      state.selectedItem = action.payload;
    },
    resetSelectedItem(state) {
      state.selectedItem = undefined;
    },
    dismissActionItem() {},
    removeDismissedActionItem(state, action) {
      state.actionItems = state.actionItems.filter(
        a => a.id !== action.payload,
      );
    },
    getDocusignUrl() {},
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        isLoading: true,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
    savePaycardInfo(state, action) {},
    setSelectedClearanceItem(state, action) {
      return {
        ...state,
        selectedClearanceItem: action.payload,
      };
    },
    resetSelectedClearanceItem(state) {
      return {
        ...state,
        selectedClearanceItem: undefined,
      };
    },
  },
});

export const actionItemActions = {
  ...actionItemSlice.actions,
  dismissActionItemForClearanceItem,
};

export default actionItemSlice.reducer;
