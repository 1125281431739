import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommunityState } from 'interfaces/Community/ICommunityState';
import { INewsFeedGetRequest } from 'interfaces/Community/INewsFeedGetRequest';
import { INewsFeedTagsSaveRequest } from 'interfaces/Community/INewsFeedTagsSaveRequest';
import { IUserStory } from 'interfaces/Community/IUserStory';
import { IUserStoryGetRequest } from 'interfaces/Community/IUserStoryGetRequest';
import { INewsFeed } from 'interfaces/Community/INewsFeed';
import { AutoPlayType } from 'enums/autoPlayType';
import { CommunityTabType } from 'enums/communityTabType';
import { INewsFeedLikeRequest } from 'interfaces/Community/INewsFeedLikeRequest';
import { ILikeResponse } from 'interfaces/Community/ILikeResponse';

const initialState: ICommunityState = {
  newsFeeds: {
    items: [],
    paging: {
      limit: '0',
      offset: '0',
      total: '0',
    },
  },
  tags: [],
  userStories: {
    items: [],
    paging: {
      limit: '0',
      offset: '0',
      total: '0',
    },
  },
  success: false,
  videoPlayerOpen: false,
  currentVideoIndex: -1,
  displayPrevNextVideoNav: true,
  newsFeedDetail: {
    author: '',
    contentLink: { id: 0, url: '' },
    name: '',
    title: '',
    metaTitle: '',
    pageDescription: '',
    parentLink: { id: 0, tag: '' },
    subHeading: '',
    teaserText: '',
    mainImage: { id: 0, url: '' },
    publishDate: '',
    updateDate: '',
    mainBody: '',
    keywords: '',
  },
  userStoryDetail: {
    id: '',
    userId: '',
    firstName: '',
    lastName: '',
    profileImageUrl: '',
    prompt: '',
    videoUrl: '',
    thumbnailUrl: '',
  },
  autoPlayType: AutoPlayType.LOOP,
  activeCommunityTab: CommunityTabType.HIGHLIGHTS,
  likeResponse: undefined,
};

const getNewsFeeds = createAction<INewsFeedGetRequest>('GET_NEWS_FEEDS');
const getNewsFeedTags = createAction('GET_NEWS_FEED_TAGS');
const saveNewsFeedTags = createAction(
  'SAVE_NEWS_FEED_TAGS',
  function prepare(saveRequest: INewsFeedTagsSaveRequest) {
    return {
      payload: {
        ...saveRequest,
      },
    };
  },
);
const getUserStories = createAction<IUserStoryGetRequest>('GET_USER_STORIES');
const getNewsFeedById = createAction<number>('GET_NEWS_FEED_BY_ID');
const getUserStoryById = createAction<number>('GET_USER_STORY_BY_ID');
const likeNewsFeed = createAction<INewsFeedLikeRequest>('LIKE_NEWS_FEED');

const communitySlice = createSlice({
  name: 'communitySlice',
  initialState,
  reducers: {
    resetSaveNewsFeedTagsStatus(state) {
      state.success = false;
    },
    resetNewsFeeds(state) {
      state.newsFeeds = { ...initialState.newsFeeds };
      state.success = initialState.success;
    },
    setNewsFeeds(state, action) {
      state.newsFeeds = {
        ...state.newsFeeds,
        items: state.newsFeeds?.items?.some(
          item =>
            item.title.toLowerCase() ===
            action?.payload?.items[0]?.title.toLowerCase(),
        )
          ? action?.payload?.items ?? []
          : [
              ...(state.newsFeeds?.items ?? []),
              ...(action?.payload?.items ?? []),
            ],
        paging: action.payload?.paging,
      };
    },
    setNewsFeedTags(state, action) {
      state.tags = action.payload;
    },
    setSaveNewsFeedTagsStatus(state, action) {
      state.success = action.payload;
    },
    setUserStories(state, action) {
      state.userStories = {
        ...state.userStories,
        items: state.userStories?.items?.some(
          item => item.id === action?.payload?.items[0]?.id,
        )
          ? action?.payload?.items ?? []
          : [
              ...(state.userStories?.items ?? []),
              ...(action?.payload?.items ?? []),
            ],
        paging: action.payload?.paging,
      };
    },
    setVideoPlayerOpenState(state, action: PayloadAction<boolean>) {
      state.videoPlayerOpen = action.payload;
    },
    setCurrentVideoIndex(state, action: PayloadAction<number>) {
      state.currentVideoIndex = action.payload;
    },
    setDisplayPrevNextVideoNav(state, action: PayloadAction<boolean>) {
      state.displayPrevNextVideoNav = action.payload;
    },
    setNewsFeedDetail(state, action: PayloadAction<INewsFeed>) {
      state.newsFeedDetail = action.payload;
    },
    resetNewsFeedDetail(state) {
      state.newsFeedDetail = initialState.newsFeedDetail;
    },
    setUserStoryDetail(state, action: PayloadAction<IUserStory>) {
      state.userStoryDetail = action.payload;
    },
    resetUserStoryDetail(state) {
      state.userStoryDetail = initialState.userStoryDetail;
    },
    setAutoPlayType(state, action: PayloadAction<AutoPlayType>) {
      state.autoPlayType = action.payload;
    },
    setActiveCommunityTab(state, action) {
      state.activeCommunityTab = action.payload;
    },
    resetActiveCommunityTab(state) {
      state.activeCommunityTab = initialState?.activeCommunityTab;
    },
    updateLikeCount(state, action: PayloadAction<ILikeResponse>) {
      if (state.newsFeedDetail?.contentLink?.id === action.payload.contentId) {
        state!.newsFeedDetail!.likes = parseInt(
          action.payload.likeCount ?? '0',
        );
      }
      const objIndex = state.newsFeeds?.items?.findIndex(
        obj => obj.contentLink.id === action.payload.contentId,
      );
      if (objIndex && objIndex >= 0) {
        const selected = state.newsFeeds?.items;
        if (selected) {
          selected[objIndex].likes = parseInt(action.payload.likeCount ?? '0');
        }
      }
    },
  },
});

export const communityActions = {
  getNewsFeeds,
  getNewsFeedTags,
  getUserStories,
  saveNewsFeedTags,
  getNewsFeedById,
  getUserStoryById,
  likeNewsFeed,
  ...communitySlice.actions,
};

export default communitySlice.reducer;
