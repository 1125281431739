import React from 'react';
import * as ReactDOM from 'react-dom';
import { ConfirmationPopup } from 'components/common/ConfirmationPopup/ConfirmationPopup';
import { theme } from 'styles/theme';

export function showConsent(
  title: string,
  description: string,
  negative: string,
  affirmative: string,
): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    function hide() {
      ReactDOM.unmountComponentAtNode(container);
      container.remove();
    }

    const dialogProps = {
      isPanelOpen: true,
      title,
      content: description,
      handleClose: () => {
        reject(false);
        hide();
      },
      handleProceed: () => {
        resolve(true);
        hide();
      },
      negative,
      affirmative,
      isHandleProceedEnabled: true,
      titleSx: {
        color: theme.palette.system.midnightBlue,
      },
    };

    const dialogRef = React.createElement(ConfirmationPopup, dialogProps);

    ReactDOM.render(dialogRef, container);

    return () => hide();
  });
}
