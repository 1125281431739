export enum JobFilterType {
  CITY = 'City',
  STATE = 'State',
  LOCATION = 'Location',
  SKILLSET = 'Skillset',
  DISCIPLINE = 'Discipline',
  SPECIALTY = 'Specialty',
  MODALITY = 'Modality',
  START_DATE = 'StartDate',
  SHIFT_NORMALIZED = 'ShiftNormalized',
  DURATION_INT = 'DurationInt',
  TAGS = 'Tags',
  JOB_TYPE = 'JobType',
  JOB_ID = 'JobID',
  COMMUNITIES = 'Communities',
  FACILITY_TYPE = 'FacilityType',
  EXPERIENCE_LEVEL = 'ExperienceLevel',
  SOURCE_SYSTEM = 'SourceSystem',
}
