import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import { ActionRequiredIcon } from 'assets/icons/Credentials/ActionRequiredIcon';
import { CredentialChecklist } from 'classes/Credential/CredentialChecklist';
import { AddCredential } from 'components/Credentials/AddCredential/AddCredential';
import { UploadedCredentialDocuments } from 'components/Credentials/AddCredential/UploadedCredentialDocuments';
import { CredentialsChecklistCard } from 'components/Credentials/Checklist/CredentialsChecklistCard';
import { CovidDrawerContent } from 'components/Credentials/CovidDrawer/CovidDrawerContent';
import { SkillsChecklistIFrame } from 'components/SkillsChecklist/SkillsChecklistIFrame';
import { TOAST_MESSAGE } from 'constants/helperText';
import { actionItemTypeId } from 'enums/actionType';
import { AlertBarType } from 'enums/alertBarTypes';
import { CredSubType } from 'enums/credSubTypeConfig';
import {
  CredentialChecklistCategory,
  OpenRequirementCredentialType,
  TaskCenterCategoryType,
  TaskCenterCategoryTypeId,
} from 'enums/credential';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { LinkType } from 'enums/linkConfig';
import {
  docuSignActionRequiredDialog,
  filterRedirectedOpenRequirement,
} from 'helpers/credentialCenterHelper';
import {
  isSafariOrFirefox,
  openInCurrentTab,
  openInNewTab,
} from 'helpers/openInNewTab';
import { isCredentialStatusOpen } from 'helpers/statusChecker';
import { ActionRequiredDialogProps } from 'interfaces/Props/IDialogProps';
import { AddIdentification } from 'pages/TaskCenter/AddIdentification';
import { FormStackForm } from 'pages/TaskCenter/FormStackForm';
import { LicenseVerification } from 'pages/TaskCenter/LicenseVerification';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import useQueryParams from 'utils/hooks/useQueryParams';
import ActionRequiredDialog from '../Dialog/ActionRequiredDialog';
import { DrawerPanel } from '../Drawer/Drawer';
import { AppRouteNames } from 'routes/appRouteNames';
import { addRedirectUrl } from 'helpers/appendRedirectUrl';

export const TaskCredentialActionDrawer = ({
  selectedOpenTask,
  setSelectedOpenTask,
}) => {
  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const history = useHistory();

  const credentials = useAppSelector(state => state.credential);
  const credentialChecklistData = useAppSelector(
    state => state.credential?.credentialsChecklist,
  );
  const skillListUrl = useAppSelector(
    state => state.auth.versionCheck?.skillsChecklistViewUrl,
  );
  const docUrl = useAppSelector(state => state.credential.formStackData);
  const envUrl = useAppSelector(state => state.credential.docuSignEnvelopeUrl);
  const drawerOpen = useAppSelector(
    state => state.credential.taskCredentialActionDrawer,
  );

  const [submitButtonText, setSubmitButtonText] = useState<string>(
    'Submit for Approval',
  );
  const [actionRequiredDialog, setActionRequiredDialog] = useState<
    ActionRequiredDialogProps | undefined
  >(undefined);

  const [skillsChecklistId, setSkillsChecklistId] = useState<
    string | undefined
  >();

  const openRequirementItems = useMemo(
    () =>
      credentials?.openRequirements.filter(
        t => t?.skillsChecklist?.latestCompletedDate == null,
      ),
    [credentials?.openRequirements],
  );

  //** CREDENTIALS FUNCTIONS */
  const dispatchDrawerAction = action =>
    dispatch(credentialActions.setTaskCredentialActionDrawer(action));
  const removeDocusignEnv = () =>
    dispatch(credentialActions.setDocEnvUrl(undefined));

  const getChecklistData = useCallback(
    () => ({
      [CredentialChecklistCategory.Client_Specific_Documents]: {
        order: 1,
        title: 'Client Specific Documents',
        items:
          (credentialChecklistData?.list || [])
            .filter(
              c =>
                c.categoryId ===
                  CredentialChecklistCategory.Client_Specific_Documents &&
                isCredentialStatusOpen(c.statusId || -1),
            )
            .map(c => ({
              id: c?.id,
              title: c?.description,
              category: c?.category,
            })) || [],
      },
      [CredentialChecklistCategory.Clinical_Qualifications]: {
        order: 2,
        title: 'Clinical Qualifications',
        items:
          (credentialChecklistData?.list || [])
            .filter(
              c =>
                c.categoryId ===
                  CredentialChecklistCategory.Clinical_Qualifications &&
                isCredentialStatusOpen(c.statusId || -1),
            )
            .map(c => ({
              id: c?.id,
              title: c?.description,
              category: c?.category,
            })) || [],
      },
      [CredentialChecklistCategory.Credentials]: {
        order: 3,
        title: 'Credentials',
        items:
          (credentialChecklistData?.list || [])
            .filter(
              c =>
                c.categoryId === CredentialChecklistCategory.Credentials &&
                isCredentialStatusOpen(c.statusId || -1),
            )
            .map(c => ({
              id: c?.id,
              title: c?.description,
              category: c?.category,
            })) || [],
      },
      [CredentialChecklistCategory.Employee_Health]: {
        order: 4,
        title: 'Employee Health',
        items:
          (credentialChecklistData?.list || [])
            .filter(
              c =>
                c.categoryId === CredentialChecklistCategory.Employee_Health &&
                isCredentialStatusOpen(c.statusId || -1),
            )
            .map(c => ({
              id: c?.id,
              title: c?.description,
              category: c?.category,
            })) || [],
      },
      [CredentialChecklistCategory.Human_Resources]: {
        order: 5,
        title: 'Human Resources',
        items:
          (credentialChecklistData?.list || [])
            .filter(
              c =>
                c.categoryId === CredentialChecklistCategory.Human_Resources &&
                isCredentialStatusOpen(c.statusId || -1),
            )
            .map(c => ({
              id: c?.id,
              title: c?.description,
              category: c?.category,
            })) || [],
      },
    }),
    [credentialChecklistData?.list],
  );

  const isReadOnlyCredentialItem = useMemo(
    () =>
      selectedOpenTask?.links &&
      selectedOpenTask?.links[0]?.url?.includes('ReadOnlyCredential'),
    [selectedOpenTask],
  );

  const DrawerContent = useMemo(() => {
    let content = <></>;
    let contentName = '';
    let actionButtonText = '';
    let cancelButtonText = '';

    switch (drawerOpen) {
      case DrawerContentNames.ADD_CREDENTIAL:
        content = (
          <AddCredential
            setDrawerOpen={dispatchDrawerAction}
            selectedOpenRequirement={selectedOpenTask}
          />
        );
        contentName = 'Add Credential';
        actionButtonText = 'Submit for Approval';
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.CREDENTIAL_COVID_VACCINE_RECORD:
        content = <CovidDrawerContent setDrawerOpen={dispatchDrawerAction} />;
        contentName = 'COVID-19 Vaccination Record';
        actionButtonText = 'Submit for Approval';
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.ADD_IDENTIFICATION:
        content = (
          <AddIdentification
            setDrawerOpen={dispatchDrawerAction}
            licenseSubmitButtonText={submitButtonText}
            setLicenseSubmitButtonText={setSubmitButtonText}
          />
        );
        contentName = 'Add Identification';
        actionButtonText = submitButtonText;
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.CREDENTIAL_CENTER_UPLOAD:
        if ((credentialChecklistData?.list || []).length === 0) return;
        const data = getChecklistData();
        content = (
          <CredentialsChecklistCard
            hideFooterContent
            openCredentials={data}
            setDrawerOpen={dispatchDrawerAction}
            cardSx={{ padding: 0, marginBottom: 0, boxShadow: 'none' }}
            isLicense={
              selectedOpenTask?.credentialTypeId ===
              OpenRequirementCredentialType.License_Verification
            }
            isOpenRequirement={true}
            actionButtonText={submitButtonText}
            setActionButtonText={setSubmitButtonText}
          />
        );
        contentName = selectedOpenTask?.title;
        actionButtonText = isReadOnlyCredentialItem
          ? 'Mark as Complete'
          : submitButtonText;
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.LICENSE_VERIFICATION:
        content = <LicenseVerification />;
        contentName = 'Licensure Verification';
        actionButtonText = 'Submit for Approval';
        cancelButtonText = 'CANCEL';
        break;
      case DrawerContentNames.SKILLS_CHECKLIST:
        let appendUrl = '';
        if (!skillListUrl) return;
        if (selectedOpenTask?.latestCompletedId) {
          appendUrl = `sk/hp/${selectedOpenTask?.latestCompletedId}`;
        } else if (selectedOpenTask?.id) {
          if (selectedOpenTask?.links?.length > 0) {
            appendUrl = `sk/${skillsChecklistId}`;
          } else appendUrl = `sk/${selectedOpenTask?.id}`;
        }
        const skillListExternalUrl = skillListUrl + appendUrl;
        contentName = selectedOpenTask?.title;
        content = (
          <SkillsChecklistIFrame
            url={skillListExternalUrl}
            placementId={selectedOpenTask?.placementId}
            credentialId={selectedOpenTask?.id}
          />
        );
        break;
      case DrawerContentNames.FORMSTACK:
      case DrawerContentNames.CRED_DECLINATION_FORM:
        const formStackData = selectedOpenTask?.links?.filter(
          r => String(r.typeId) === LinkType.Formstack,
        )[0];
        if (isSafariOrFirefox && !docUrl?.url) break;
        else if (isSafariOrFirefox) {
          setTimeout(() => {
            openInCurrentTab(docUrl.url);
            dispatch(credentialActions.setFormStackData(undefined));
            dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER);
          });
        } else {
          contentName = formStackData?.title || 'Form';
          content = (
            <FormStackForm
              url={formStackData?.url}
              placementId={selectedOpenTask?.placementId}
              credentialId={selectedOpenTask?.id}
            />
          );
        }
        break;
      case DrawerContentNames.UPLOADED_DOCUMENTS:
        content = (
          <UploadedCredentialDocuments
            selectedOpenRequirementsItem={selectedOpenTask}
          />
        );
        contentName = selectedOpenTask?.title;
        break;
      case DrawerContentNames.CLOSED_DRAWER:
      default:
        dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER);
        setSelectedOpenTask(undefined);
        break;
    }

    return !!contentName ? (
      <DrawerPanel
        open={!!contentName}
        actionButtonText={actionButtonText}
        cancelButtonText={cancelButtonText}
        toggleDrawer={(e, open) =>
          open === false &&
          dispatchDrawerAction(DrawerContentNames.CLOSED_DRAWER)
        }
        contentName={contentName}
        showFooter={
          !(
            drawerOpen === DrawerContentNames.CRED_DECLINATION_FORM ||
            drawerOpen === DrawerContentNames.FORMSTACK ||
            drawerOpen === DrawerContentNames.SKILLS_CHECKLIST
          ) && !selectedOpenTask?.isPendingCredentialItem
        }
        innerContainerStyles={{
          width: {
            xs: '100%',
            sm:
              drawerOpen === DrawerContentNames.SKILLS_CHECKLIST
                ? '800px !important'
                : 'auto',
          },
        }}
      >
        {content}
      </DrawerPanel>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    drawerOpen,
    credentialChecklistData?.list,
    docUrl?.url,
    isSafariOrFirefox,
    skillListUrl,
    submitButtonText,
  ]);

  // Use Effect for Open Requirement actions
  useEffect(() => {
    if (!selectedOpenTask) return;
    if (
      selectedOpenTask.taskCenterCategoryType ===
      TaskCenterCategoryType.Docusign
    ) {
      dispatch(credentialActions.getDocEnvUrl(selectedOpenTask.envelopeId));
    } else if (selectedOpenTask.actionItemTypeId === actionItemTypeId.Survey) {
      openInNewTab(selectedOpenTask?.url);
      setSelectedOpenTask(undefined);
    } else if (
      selectedOpenTask?.credentialSubtype === CredSubType.UserCredential
    ) {
      if (selectedOpenTask?.isPendingCredentialItem) {
        dispatchDrawerAction(DrawerContentNames.UPLOADED_DOCUMENTS);
      } else if (
        ((selectedOpenTask?.links || [])[0]?.url || '').includes(
          'ImageVerification',
        )
      ) {
        dispatchDrawerAction(DrawerContentNames.ADD_IDENTIFICATION);
      } else dispatchDrawerAction(DrawerContentNames.ADD_CREDENTIAL);
    } else if (selectedOpenTask?.isPendingCredentialItem) {
      if (
        selectedOpenTask?.credentialTypeId ===
          OpenRequirementCredentialType.Document_Uploads ||
        selectedOpenTask?.credentialTypeId ===
          OpenRequirementCredentialType.License_Verification
      ) {
        dispatchDrawerAction(DrawerContentNames.UPLOADED_DOCUMENTS);
      } else return;
    } else if (
      selectedOpenTask?.credentialSubtype === CredSubType.SkillsChecklist ||
      (selectedOpenTask?.credentialSubtype ===
        CredSubType.AssignmentCredential &&
        selectedOpenTask?.credentialTypeId ===
          OpenRequirementCredentialType.Skills_Checklists)
    ) {
      if (!isSafariOrFirefox) {
        if (
          selectedOpenTask?.credentialSubtype === CredSubType.SkillsChecklist
        ) {
          setSkillsChecklistId(selectedOpenTask?.id);
          dispatchDrawerAction(DrawerContentNames.SKILLS_CHECKLIST);
        } else {
          const params = new URLSearchParams(
            selectedOpenTask?.links[0].url.split('?')[1] || '',
          );
          const skillsChecklistId = params.get('ChecklistId');
          if (!skillsChecklistId) {
            history.push(`/${AppRouteNames.SKILLS_CHECKLISTS}`);
          } else {
            setSkillsChecklistId(skillsChecklistId);
            dispatchDrawerAction(DrawerContentNames.SKILLS_CHECKLIST);
          }
        }
      }
    } else if (
      selectedOpenTask?.credentialTypeId ===
        OpenRequirementCredentialType.License_Verification ||
      (selectedOpenTask?.typeId === +LinkType.MobileDeepLink &&
        (((selectedOpenTask?.links || [])[0]?.url || '').includes(
          'CredentialItem',
        ) ||
          ((selectedOpenTask?.links || [])[0]?.url || '').includes(
            'ImageVerification',
          ))) ||
      isReadOnlyCredentialItem ||
      selectedOpenTask?.typeId === Number(LinkType.PDF)
    ) {
      if (
        ((selectedOpenTask?.links || [])[0]?.url || '').includes('?') ||
        selectedOpenTask?.typeId === Number(LinkType.PDF) ||
        isReadOnlyCredentialItem ||
        selectedOpenTask?.credentialTypeId ===
          OpenRequirementCredentialType.License_Verification
      ) {
        dispatch(
          credentialActions.getCredentialChecklistFromCredCenterData(
            selectedOpenTask?.placementId,
          ),
        );
        // create new selected checklist item
        dispatch(
          credentialActions.setSelectedChecklistItem(
            new CredentialChecklist(selectedOpenTask),
          ),
        );
        dispatchDrawerAction(DrawerContentNames.CREDENTIAL_CENTER_UPLOAD);
      } else {
        dispatchDrawerAction(DrawerContentNames.ADD_CREDENTIAL);
      }
    } else if (selectedOpenTask?.typeId === +LinkType.Formstack) {
      if (!docUrl.url) {
        const formStackData = selectedOpenTask?.links?.filter(
          r => String(r.typeId) === LinkType.Formstack,
        )[0];
        const originalUrl = formStackData?.url?.substring(
          formStackData?.url?.indexOf('forms/'),
        );
        const redirectUrl =
          `${window.location.origin}/${AppRouteNames.TASK_CENTER}?` +
          encodeURIComponent(
            `placementId=${selectedOpenTask?.placementId}&credentialId=${
              selectedOpenTask?.id
            }&credentialType=${
              TaskCenterCategoryTypeId.Credentials
            }&formstackSubmitted=${true}`,
          );
        const docUri = addRedirectUrl(originalUrl, redirectUrl);
        dispatch(credentialActions.getFormStackDataAction(docUri));
      }

      dispatchDrawerAction(DrawerContentNames.FORMSTACK);
    } else if (selectedOpenTask?.linkTypeId === +LinkType.Web) {
      openInNewTab((selectedOpenTask?.links || [])[0]?.url);
      setSelectedOpenTask(undefined);
    } else if (
      selectedOpenTask?.typeId === +LinkType.Multiple &&
      ((selectedOpenTask?.links || [])[0]?.url || '').includes('CredentialItem')
    ) {
      let obj: ActionRequiredDialogProps = {
        title: '',
        body: undefined,
        open: false,
      };
      obj.open = true;
      obj.title = 'Action Required';
      obj.body = selectedOpenTask?.instructions;
      obj.icon = (
        <ActionRequiredIcon style={{ height: '57px', width: '65px' }} />
      );
      obj.buttons = [
        {
          id: 'upload-btn',
          text: 'Upload',
          onClick: () => {
            if (((selectedOpenTask?.links || [])[0]?.url || '').includes('?')) {
              dispatch(
                credentialActions.getCredentialChecklistFromCredCenterData(
                  selectedOpenTask?.placementId,
                ),
              );
              dispatch(
                credentialActions.setSelectedChecklistItem(
                  new CredentialChecklist(selectedOpenTask),
                ),
              );
              dispatchDrawerAction(DrawerContentNames.CREDENTIAL_CENTER_UPLOAD);
            } else {
              dispatchDrawerAction(DrawerContentNames.ADD_CREDENTIAL);
            }
            setActionRequiredDialog(undefined);
          },
        },
        {
          id: 'declination-form-btn',
          text: 'Declination Form',
          onClick: () => {
            if (isSafariOrFirefox) {
              const formStackData = selectedOpenTask?.links?.filter(
                r => String(r.typeId) === LinkType.Formstack,
              )[0];

              const originalUrl = formStackData?.url?.substring(
                formStackData?.url?.indexOf('forms/'),
              );
              const redirectUrl =
                `${window.location.origin}/${AppRouteNames.TASK_CENTER}?` +
                encodeURIComponent(
                  `placementId=${selectedOpenTask?.placementId}&credentialId=${
                    selectedOpenTask?.id
                  }&credentialType=${
                    TaskCenterCategoryTypeId.Credentials
                  }&formstackSubmitted=${true}`,
                );
              const docUri = addRedirectUrl(originalUrl, redirectUrl);
              dispatch(credentialActions.getFormStackDataAction(docUri));
            }
            dispatchDrawerAction(DrawerContentNames.CRED_DECLINATION_FORM);
            setActionRequiredDialog(undefined);
          },
        },
        {
          id: 'cancel-btn',
          text: 'Cancel',
          onClick: () => {
            setActionRequiredDialog(undefined);
            setSelectedOpenTask(undefined);
          },
        },
      ];
      setActionRequiredDialog(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpenTask, isSafariOrFirefox]);

  useEffect(() => {
    if (
      history.location &&
      (history.location.search.includes('signing_complete') ||
        history.location.search.includes('viewing_complete'))
    ) {
      dispatch(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.SuccessfullySubmitted,
          alertbarRefId: AlertBarType.CREDENTIAL_CENTER,
        }),
      );
      dispatch(credentialActions.getCredentialDetailAction(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    // due to the action being attached incorrectly after all the params,
    // we need to parse the url to grab the action
    const action = history.location.search.slice(
      history.location.search.indexOf('action') + 7,
    );
    // due to the action being attached incorrectly after all the params,
    // we need to parse credentialType and remove extra query param
    const credentialTypeId = (query.get('credentialType') || '')[0];
    const placementId = !_isEmpty(query.get('placementId'))
      ? Number(query.get('placementId'))
      : undefined;
    const credentialId = !_isEmpty(query.get('credentialId'))
      ? Number(query.get('credentialId'))
      : undefined;
    if (
      isSafariOrFirefox &&
      !!action &&
      !!credentialTypeId &&
      +credentialTypeId === OpenRequirementCredentialType.Skills_Checklists
    ) {
      if (action === 'close') {
        dispatch(
          openAlert({
            variant: 'success',
            message: TOAST_MESSAGE.SkillsChecklistSubmitted,
          }),
        );
        if (!!placementId && !!credentialId) {
          dispatch(
            credentialActions.setCredentialStatusAction({
              placementId,
              credentialId,
            }),
          );
        }
        dispatch(credentialActions.getCredentialDetailAction(true));
        dispatch(credentialActions.getSubmissions());
        dispatch(credentialActions.getAssignmentCredentialSubmission());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafariOrFirefox]);

  /* For Safari/Firefox browsers - Formstack messaging/event listeners
  Also handling deeplinks for credential center
  */
  useEffect(() => {
    const credentialTypeId = (query.get('credentialType') || '')[0];
    const placementId = Number(query.get('placementId'));
    const credentialId = query.get('credentialId');
    const isSubmitted = query.get('formstackSubmitted');
    if (
      isSafariOrFirefox &&
      !!credentialTypeId &&
      +credentialTypeId === OpenRequirementCredentialType.Forms &&
      isSubmitted
    ) {
      dispatch(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.FormSubmitted,
        }),
      );
      if (Number.isNaN(credentialId)) {
        dispatch(
          actionItemActions.removeActionItem({
            dismissals: {
              dismissals: [credentialId],
            },
          }),
        );
      } else if (!!placementId && !!credentialId) {
        dispatch(
          credentialActions.setCredentialStatusAction({
            placementId,
            credentialId: Number(credentialId),
          }),
        );
      }
      setTimeout(() => {
        dispatch(credentialActions.getSubmissions());
        dispatch(credentialActions.getCredentialDetailAction(true));
      }, 1);
    }

    // For deeplink credential center redirect item
    const envelopeId = query.get('envelopeId');
    if (!isSubmitted) {
      if (envelopeId) {
        dispatch(credentialActions.getDocEnvUrl(envelopeId));
      }
      if (credentialId) {
        filterRedirectedOpenRequirement(
          openRequirementItems,
          credentialId,
          setSelectedOpenTask,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafariOrFirefox]);

  useEffect(() => {
    // if the user navigates away from the page, and redirects back without signing it
    removeDocusignEnv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {DrawerContent}
      {actionRequiredDialog && (
        <ActionRequiredDialog {...actionRequiredDialog} />
      )}
      {envUrl &&
        docuSignActionRequiredDialog(!!envUrl, removeDocusignEnv, envUrl)}
    </>
  );
};
