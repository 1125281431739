import React from 'react';
import { createSvgIcon } from '@mui/material';

export const PDFIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 60">
    <g id="pdf_icon" data-name="pdf icon" transform="translate(15069 13102)">
      <path
        id="Path_7598"
        data-name="Path 7598"
        d="M49.5,78.834H4.5a4.505,4.505,0,0,1-4.5-4.5v-51a4.5,4.5,0,0,1,4.5-4.5H36.557L54,36.278V74.334A4.505,4.505,0,0,1,49.5,78.834Z"
        transform="translate(-15069 -13120.834)"
        fill="#0098db"
      />
      <path
        id="Path_7597"
        data-name="Path 7597"
        d="M18.29,30.31H5.54a4.5,4.5,0,0,1-4.5-4.5V13.061L18.289,30.309Z"
        transform="translate(-15033.29 -13115.061)"
        fill="#0279ad"
      />
      <path
        id="Path_7596"
        data-name="Path 7596"
        d="M-34.272-9.238h.747a2.488,2.488,0,0,0,1.567-.414,1.448,1.448,0,0,0,.52-1.2,1.485,1.485,0,0,0-.436-1.179,2.042,2.042,0,0,0-1.366-.381h-1.033Zm5.127-1.7a3.273,3.273,0,0,1-1.08,2.644,4.62,4.62,0,0,1-3.073.916h-.974v3.809h-2.271V-14.277h3.42a4.549,4.549,0,0,1,2.963.839A3.058,3.058,0,0,1-29.146-10.937ZM-18.24-9.026a5.273,5.273,0,0,1-1.505,4.05,6.138,6.138,0,0,1-4.347,1.406h-3.032V-14.277h3.362a5.661,5.661,0,0,1,4.072,1.384A5.092,5.092,0,0,1-18.24-9.026Zm-2.358.059q0-3.45-3.047-3.45h-1.208v6.973h.974Q-20.6-5.444-20.6-8.967Zm6.812,5.4h-2.234V-14.277h6.138v1.86h-3.9v2.761h3.633V-7.8h-3.633Z"
        transform="translate(-15018.614 -13062.431)"
        fill="#fff"
      />
    </g>
  </svg>,
  'PDFIcon',
);
