import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { boolean, date, object, string } from 'yup';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ClearanceItem from 'components/ClearanceItem/ClearanceItem';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { ConfirmationPopup } from 'components/common/ConfirmationPopup/ConfirmationPopup';
import AlertDialog from 'components/common/Dialog/ErrorDialog';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import Address from 'components/common/Form/Address/Address';
import ZipCodeAndState from 'components/common/Form/Address/ZipCodeAndState';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { LockoutMessageRow } from 'containers/LoginContainer/LoginWrapper.styles';
import { AddressType } from 'enums/addressType';
import { DrawerButtonTypes } from 'enums/buttons';
import { PhoneNumberType } from 'enums/phoneNumberType';
import { getValidationPatternByCountry } from 'helpers/validationHelper';
import { IPersonalInformationProps } from 'interfaces/Props/IPersonalInformationProps';
import moment from 'moment';
import { RootState, useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import {
  getUserProfile,
  updateProfile,
} from 'store/slices/user/userProfile/userProfileSlice';
import EmergencyContact from './EmergencyContact';
import LegalInformation from './LegalInformation';
import { PhoneNumberWrapper } from './PhoneNumberWrapper';
import {
  extractServiceData,
  extractServiceDataWithDefaultEmergencyContact,
  getPostData,
} from './helper';
import { defaultValues } from './utils';
const min_dob = '01/01/1940';

const PersonalInformation: FC<IPersonalInformationProps> = ({
  open,
  setOpen,
  afterSubmit,
  scrollInto,
  isSsnActionItem,
  clearanceItemProps,
}) => {
  const dispatch = useAppDispatch();

  const scrollToRef = useRef<HTMLDivElement>(null);

  const isLoadingActive = useAppSelector(
    (state: RootState) => state.userProfile.isSubmitLoaderActive,
  );
  const user = useAppSelector(state => state.userProfile);
  const userId = useAppSelector(state => state.auth.userId);

  const [isHandleProceedEnabled, setIsHandleProceedEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ title: '', message: '' });
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  /**
   *  Validation schema for Personal Information Drawer
   */
  const validationSchema = object({
    emailAddress: string().email('Invalid format').required('Required'),
    emailAddress2: string().email('Invalid format').notRequired().nullable(),
    mobilePhone: object({
      countryDialingCode: string().nullable(),
      phoneNumber: string().nullable(),
    })
      .test(
        'is-required',
        () => 'Required',
        (val, context) => {
          return !val?.phoneNumber && !context?.parent?.mobilePhone?.phoneNumber
            ? false
            : true;
        },
      )
      .test('is-phone-number', 'Invalid format', val =>
        genericValidationFxn(
          val?.phoneNumber,
          /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
        ),
      )
      .nullable(),
    homePhone: object({
      countryDialingCode: string().nullable(),
      phoneNumber: string().nullable(),
    })
      .test('is-phone-number', 'Invalid format', val =>
        genericValidationFxn(
          val?.phoneNumber,
          /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
        ),
      )
      .notRequired()
      .nullable(),
    primarySocialMedia: string().optional(),
    facebookUrl: string()
      .test('is-url', 'Invalid format', val =>
        genericValidationFxn(
          val,
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/,
        ),
      )
      .nullable()
      .optional(),
    linkedInUrl: string()
      .test('is-url', 'Invalid format', val =>
        genericValidationFxn(
          val,
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/,
        ),
      )
      .nullable()
      .optional(),
    instagramUrl: string()
      .test('is-url', 'Invalid format', val =>
        genericValidationFxn(
          val,
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/,
        ),
      )
      .nullable()
      .optional(),
    twitterUrl: string()
      .test('is-url', 'Invalid format', val =>
        genericValidationFxn(
          val,
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/,
        ),
      )
      .nullable()
      .optional(),
    tiktokUrl: string()
      .test('is-url', 'Invalid format', val =>
        genericValidationFxn(
          val,
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/,
        ),
      )
      .nullable()
      .optional(),
    sameAddress: boolean().notRequired(),
    permanentAddress: object({
      country: string().required('Required'),
      iso2CountryCode: string().optional(),
      state: string().required('Required'),
      zipCode: string()
        .test('is-valid-zip-by-country', 'Invalid format', (val, context) =>
          genericValidationFxn(
            val,
            getValidationPatternByCountry(context?.parent?.country),
          ),
        )
        .required('Required'),
      address1: string().required('Required'),
      city: string().required('Required'),
    }),
    currentAddress: object({
      country: string().notRequired(),
      iso2CountryCode: string().optional(),
      state: string().notRequired(),
      zipCode: string()
        .test('is-valid-zip-by-country', 'Invalid format', (val, context) =>
          genericValidationFxn(
            val,
            getValidationPatternByCountry(context?.parent?.country),
          ),
        )
        .notRequired(),
      address1: string().notRequired(),
      city: string().notRequired(),
    }),
    emergencyContact: object({
      name: string().notRequired(),
      iso2CountryCode: string().optional(),
      relationship: string().notRequired(),
      phoneNumber: string()
        .test('is-phone-number', 'Invalid format', val =>
          genericValidationFxn(val, /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/),
        )
        .notRequired(),
    }),
    birthDate: (() => {
      const validation = date()
        .min(new Date(min_dob), 'Invalid Date')
        .max(new Date(), 'Invalid Date')
        .typeError('Invalid Date')
        .nullable();

      if (clearanceItemProps?.id === 'date-of-birth') {
        return validation.required('Required');
      } else if (user.birthDate) {
        return validation.notRequired().nullable(false);
      } else {
        return validation.notRequired();
      }
    })(),
    socialSecurityNumber: (() => {
      const validation = string().test(
        'match the format',
        'Invalid format',
        val =>
          genericValidationFxn(
            val,
            /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
          ),
      );

      return clearanceItemProps?.id === 'social-security-number'
        ? validation.required('Required')
        : validation.notRequired();
    })(),
  });

  const methods = useForm<any>({
    shouldUnregister: false,
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  /**
   * Initialize Form methods
   */
  const {
    setValue,
    reset,
    handleSubmit,
    watch,
    getValues,
    setError,
    register,
    clearErrors,
    formState: { isDirty },
  } = methods;
  const formData = watch();

  const genericValidationFxn = (val, regex) =>
    !!val ? (val?.match(new RegExp(regex))?.length ?? 0) > 0 : true;

  useEffect(() => {
    if (open) {
      const hasNoEmergencyContact =
        user.emergencyContact === null ||
        Object.keys(user.emergencyContact).length === 0;

      if (hasNoEmergencyContact) {
        reset(extractServiceDataWithDefaultEmergencyContact(user));
      } else {
        reset(extractServiceData(user));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const resetDialogState = () => {
    setErrorMsg({
      title: '',
      message: '',
    });
  };

  const onError = (errors, e) => {
    setIsHandleProceedEnabled(false);
    setErrorMsg({
      title: 'Something Went Wrong',
      message:
        'Your changes could not be saved. One or more errors found. Please resolve the errors indicated in red.',
    });
  };

  const onSubmit = data => {
    if (data.sameAddress) {
      setValue('currentAddress', getValues('permanentAddress'));
      setValue('currentAddress.addressType', AddressType.COMPANYASSIGNMENT);
      data.currentAddress = { ...data.permanentAddress };
      data.currentAddress.addressType = AddressType.COMPANYASSIGNMENT;
    }
    let requestObj = getPostData(data);
    if (requestObj.emergencyContact) {
      const emergencyPhone = requestObj.emergencyContact.phoneNumber;
      const cellPhone = requestObj.phoneNumbers.find(
        phone => phone.phoneNumberType === PhoneNumberType.CELLPHONE,
      )?.phoneNumber;
      if (cellPhone && cellPhone === emergencyPhone) {
        setError('emergencyContact.phoneNumber', {
          type: 'custom',
          message: 'Number Already Used',
        });
        return;
      }
    }
    if (requestObj?.birthDate) {
      let dateObj = moment(requestObj.birthDate);
      dateObj.startOf('day');
      requestObj.birthDate = dateObj.format('ddd MMM DD YYYY HH:mm:ss');
    }
    // make sure only valid phone numbers are sent
    requestObj['phoneNumbers'] =
      requestObj.phoneNumbers.filter(
        p => p.phoneNumberType && p.countryDialingCode && p.phoneNumber,
      ) || [];
    if (data.socialSecurityNumber) {
      requestObj['socialSecurityNumber'] = data.socialSecurityNumber;
    }
    dispatch(updateProfile(requestObj));
    if (afterSubmit) afterSubmit();
    setOpen(false);
    if (
      clearanceItemProps?.id === 'date-of-birth' ||
      clearanceItemProps?.id === 'social-security-number'
    ) {
      dispatch(credentialActions.getCredentialDetailAction(true));
    }
  };

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent | null, open: boolean) => {
      if (open) {
        setIsHandleProceedEnabled(false);
      }
      setOpen(open);
      resetDialogState();
    },
    [setOpen],
  );

  const handleClose = () => {
    resetDialogState();
  };

  const handleDiscard = () => {
    resetDialogState();
    setShowConfirmationPopup(false);
    setIsHandleProceedEnabled(false);
    toggleDrawer(null, false);
  };

  const secondaryClick = () => {
    if (isDirty) {
      setShowConfirmationPopup(true);
      setIsHandleProceedEnabled(true);
    } else toggleDrawer(null, false);
  };

  const handleCancelClick = () => {
    setShowConfirmationPopup(false);
    setIsHandleProceedEnabled(false);
  };

  useEffect(() => {
    if (open && scrollInto) {
      // Add a .5 second delay to ensure that the div is rendered before scrolling to it
      setTimeout(() => {
        scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }, [open, scrollInto]);

  useEffect(() => {
    if (open) {
      dispatch(getUserProfile(userId));
    }
  }, [open]);

  return (
    <DrawerPanel
      open={open || isLoadingActive}
      actionButtonText="Save Changes"
      actionButtonType={DrawerButtonTypes.SUBMIT}
      toggleDrawer={toggleDrawer}
      contentName="Personal Information"
      primaryOnClick={handleSubmit}
      secondaryOnClick={secondaryClick}
      secondaryButtonType={DrawerButtonTypes.POPUP}
      onSubmit={onSubmit}
      onError={onError}
      isSubmitLoaderActive={isLoadingActive}
      drawerCloseValidation={secondaryClick}
    >
      <>
        {clearanceItemProps && (
          <ClearanceItem
            {...clearanceItemProps}
            sx={{
              marginTop: '0px !important',
              marginBottom: '24px',
              ...clearanceItemProps.sx,
            }}
            iconProps={{
              height: '44px',
            }}
          />
        )}
        {showConfirmationPopup && (
          <ConfirmationPopup
            title="Are you sure?"
            content="You have unsaved changes on this page, are you sure you want to discard them?"
            isPanelOpen={showConfirmationPopup}
            handleClose={handleCancelClick}
            negative="Cancel"
            isHandleProceedEnabled={isHandleProceedEnabled}
            handleProceed={handleDiscard}
            affirmative="Discard"
          />
        )}
        {!!errorMsg.title && (
          <AlertDialog
            open={!!errorMsg.title}
            handleClose={handleClose}
            title={errorMsg.title}
            dismissText="Dismiss"
            id="personal-information-alert"
          >
            <LockoutMessageRow>{errorMsg.message}</LockoutMessageRow>
          </AlertDialog>
        )}
        <Grid
          container
          gap={2}
          mb={6}
          mt={scrollInto === 'legal-information' ? 12 : 'auto'}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="system.midnightBlue"
              lineHeight="22px"
            >
              Contact Information
            </Typography>
          </Grid>
          <FormProvider {...methods}>
            <Grid item xs={12} mb={2}>
              <ReactHookFormTextField
                label="Email"
                name="emailAddress"
                defaultValue={formData?.emailAddress}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <ReactHookFormTextField
                label="Additional Email"
                name="emailAddress2"
                defaultValue={formData?.emailAddress2}
              />
            </Grid>
            <PhoneNumberWrapper />
            <Grid item xs={12} mt={4}>
              <Typography
                variant="subtitle1"
                color="system.midnightBlue"
                lineHeight="22px"
              >
                Permanent Address
              </Typography>
            </Grid>
            <Address nestedName="permanentAddress" />
            <ZipCodeAndState nestedName="permanentAddress" />
            <Grid item xs={12} mt={4}>
              <Typography
                variant="subtitle1"
                color="system.midnightBlue"
                lineHeight="22px"
              >
                Current Address
              </Typography>
            </Grid>
            <Grid
              container
              item
              display="flex"
              flexDirection="row"
              alignItems="baseline"
            >
              <Grid item xs={1}>
                <CheckBox
                  checked={formData?.sameAddress}
                  {...register('sameAddress')}
                  onChange={event => {
                    setValue('sameAddress', event.target.checked);
                    clearErrors('currentAddress');
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="subtitle1"
                  color="system.midnightBlue"
                  lineHeight="22px"
                >
                  My current address is the same as my permanent address
                </Typography>
              </Grid>
            </Grid>

            {!formData.sameAddress && (
              <>
                <Address nestedName="currentAddress" />
                <ZipCodeAndState nestedName="currentAddress" />
              </>
            )}
            <div ref={scrollToRef} id="emergency-contact-helper"></div>
            <Grid item xs={12} mt={4}>
              <Typography
                variant="subtitle1"
                color="system.midnightBlue"
                lineHeight="22px"
              >
                Emergency Contact
              </Typography>
            </Grid>
            <EmergencyContact />
            <div ref={scrollToRef} id="legal-information-helper"></div>
            <LegalInformation isSsnActionItem={isSsnActionItem} />
          </FormProvider>
        </Grid>
      </>
    </DrawerPanel>
  );
};

export { PersonalInformation };
