import {
  IDropdownItem,
  IDropdownOption,
} from 'interfaces/Props/IDropdownOption';

export class IDropdown implements IDropdownItem {
  label?: string;
  selectedValue?: any;
  defaultValue?: any;
  menuItems?: IDropdownOption[];
  disabled?: boolean;
  handleOnChange?: (event) => void;
  constructor({
    label,
    selectedValue,
    defaultValue,
    menuItems,
    disabled,
    handleOnChange,
  }: IDropdownItem) {
    this.label = label;
    this.selectedValue = selectedValue;
    this.defaultValue = defaultValue;
    this.menuItems = menuItems;
    this.disabled = disabled;
    this.handleOnChange = handleOnChange;
  }
}
