import Grid from '@mui/material/Grid';
import { CompactLocationCheckbox } from 'components/Jobs/JobSearch/JobPreference/CompactLocationCheckbox/CompactLocationCheckbox';
import { AppAlertbar } from 'components/common/Alert/AppAlertbar';
import { StatusCode } from 'enums/StatusCode';
import { AlertBarType } from 'enums/alertBarTypes';
import { IDisciplineId } from 'enums/userDisciplineTypeId';
import { UserTypeId } from 'enums/userTypeId';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import ExpertiseForm from './Expertise';
import FacilityTypeForm from './FacilityType';
import JobTypeForm from './JobType';
import { LocationForm } from './Location';
import ShiftLengthForm from './ShiftLength';
import ShiftTimeForm from './ShiftTime';
import StartDateForm from './StartDate';

export const JobPreferenceForm = ({
  methods,
  disciplineError,
  setDisciplineError,
  withDrawer = false,
  userDisciplineId,
  setFormDirty,
}: {
  methods;
  disciplineError: boolean;
  setDisciplineError: Function;
  withDrawer?: boolean;
  userDisciplineId: number;
  setFormDirty?: Function;
}) => {
  const error: IErrorResponse = useAppSelector(
    state => state.userPreference.errorResponse,
  );

  const userPreferences = useAppSelector(state => state.userPreference);

  const isToShowCompactCheckbox = useMemo(() => {
    let flag = false;
    if (userDisciplineId === UserTypeId.NURSE) flag = true;
    if (userDisciplineId === UserTypeId.ALLIED) {
      flag = userPreferences?.alliedDisciplineAndSpecialties?.some(
        obj =>
          (obj.disciplineId.toString() === IDisciplineId.PT.toString() ||
            obj.disciplineId.toString() === IDisciplineId.PTA.toString()) &&
          obj.checked === true,
      );
    }
    return flag;
  }, [userDisciplineId, userPreferences?.alliedDisciplineAndSpecialties]);

  useEffect(() => {
    return () => {
      setFormDirty?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      {!withDrawer && error && error?.errorCode >= StatusCode.BadRequest ? (
        <AppAlertbar
          sx={{
            height: 'fit-content',
            '&.MuiSnackbar-root': {
              width: { xs: '327px !important', sm: 'auto' },
              maxWidth: { xs: '327px !important', sm: '426px' },
            },
          }}
          id={AlertBarType.JOB_PREFERENCES}
          refId={AlertBarType.JOB_PREFERENCES}
        />
      ) : (
        <Grid
          container
          gap={5}
          display="flex"
          flexDirection="column"
          mb={{ xs: withDrawer ? '24px' : '100px', sm: '0px' }}
        >
          <Grid container item gap={2} mt={{ xs: 0, sm: 2 }}>
            <LocationForm
              setFormDirty={setFormDirty}
              placeholderText="City, State, Region"
            />
            {isToShowCompactCheckbox && (
              <CompactLocationCheckbox setFormDirty={setFormDirty} />
            )}
          </Grid>
          <Grid container item>
            <ExpertiseForm
              methods={methods}
              disciplineError={disciplineError}
              setDisciplineError={setDisciplineError}
              setFormDirty={setFormDirty}
            />
          </Grid>
          <Grid container item display="flex" flexDirection="column">
            <JobTypeForm setFormDirty={setFormDirty} />
          </Grid>
          <Grid container item gap={2}>
            <StartDateForm setFormDirty={setFormDirty} />
          </Grid>
          <Grid container item display="flex" flexDirection="column">
            <ShiftTimeForm setFormDirty={setFormDirty} />
          </Grid>
          <Grid container item display="flex" flexDirection="column">
            <ShiftLengthForm setFormDirty={setFormDirty} />
          </Grid>
          <Grid container item display="flex" flexDirection="column">
            <FacilityTypeForm setFormDirty={setFormDirty} />
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
};
