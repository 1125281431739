import React from 'react';

export default function ActionRequiredIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '57'}
      height={height ?? '65'}
      viewBox="0 0 57 65"
    >
      <g
        id="Action_Required_Icon"
        data-name="Action Required Icon"
        transform="translate(-144.911 -227.001)"
      >
        <g id="document" transform="translate(4)">
          <path
            id="Path_366"
            data-name="Path 366"
            d="M53.845,9.946h-23.1A9.136,9.136,0,0,0,25.6,11.753l-7.347,6.025a6.77,6.77,0,0,0-2.3,4.73V58.156a3.961,3.961,0,0,0,4.1,3.79H53.845a3.961,3.961,0,0,0,4.1-3.79V13.736A3.961,3.961,0,0,0,53.845,9.946ZM28.073,14.33a3.074,3.074,0,0,1,.542-.339v6.1H21.044ZM54.218,58.156a.365.365,0,0,1-.373.345H20.051a.364.364,0,0,1-.373-.345V23.541h10.8a1.8,1.8,0,0,0,1.865-1.723V13.391h21.5a.365.365,0,0,1,.373.345Z"
            transform="translate(124.963 217.055)"
            fill="#75787b"
          />
          <path
            id="Path_367"
            data-name="Path 367"
            d="M42.123,25.289H24.7a1.841,1.841,0,1,0,0,3.682H42.123a1.841,1.841,0,1,0,0-3.682Z"
            transform="translate(128.318 222.692)"
            fill="#75787b"
          />
          <path
            id="Path_368"
            data-name="Path 368"
            d="M42.123,30.981H24.7a1.841,1.841,0,1,0,0,3.682H42.123a1.841,1.841,0,1,0,0-3.682Z"
            transform="translate(128.318 224.783)"
            fill="#75787b"
          />
          <path
            id="Path_369"
            data-name="Path 369"
            d="M42.123,36.674H24.7a1.841,1.841,0,1,0,0,3.682H42.123a1.841,1.841,0,1,0,0-3.682Z"
            transform="translate(128.318 226.875)"
            fill="#75787b"
          />
        </g>
        <circle
          id="Ellipse_685"
          data-name="Ellipse 685"
          cx="15"
          cy="15"
          r="15"
          transform="translate(171.911 262)"
          fill="#fff"
        />
        <g id="Controls-Icon-Alert" transform="translate(227.911 275)">
          <g
            id="Group_2868"
            data-name="Group 2868"
            transform="translate(-70.635 -27.635)"
          >
            <path
              id="Path_3735"
              data-name="Path 3735"
              d="M29.564,41.564a12,12,0,1,1,12-12A12.014,12.014,0,0,1,29.564,41.564Z"
              transform="translate(0 0)"
              fill="#d9553d"
            />
            <g
              id="Group_2867"
              data-name="Group 2867"
              transform="translate(28.165 21.618)"
            >
              <path
                id="Path_3736"
                data-name="Path 3736"
                d="M29.916,32.034a1.166,1.166,0,0,0,1.166-1.166v-8.22a1.166,1.166,0,1,0-2.332,0v8.22A1.166,1.166,0,0,0,29.916,32.034Z"
                transform="translate(-28.517 -21.482)"
                fill="#fff"
              />
              <circle
                id="Ellipse_601"
                data-name="Ellipse 601"
                cx="1.399"
                cy="1.399"
                r="1.399"
                transform="translate(0 13.092)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
