// based on default browser size of 16px;

export const amnFontSize = {
  fs10: '0.625rem',
  fs12: '0.75rem',
  fs14: '0.875rem',
  fs16: '1rem',
  fs18: '1.125rem',
  fs20: '1.250rem',
  fs24: '1.5rem',
  fs30: '1.875rem',
  fs42: '2.625rem',
};
