import { AxiosResponse } from 'axios';
import _uniqWith from 'lodash/uniqWith';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { TimeEntryBreakResponseTypeIds } from 'enums/mealperiod';
import { TimeEntryStatus } from 'enums/status';
import { httpSuccess } from 'helpers/serviceHelper';
import {
  IErrorResponse,
  IErrorResponseData,
} from 'interfaces/Common/IErrorResponse';
import { INonPassportTimeEntry } from 'interfaces/TimeEntry/INonPassportTimeEntry';
import { ITimeEntryNavCard } from 'interfaces/TimeEntry/ITimeEntry';
import { ITimesheetApprover } from 'interfaces/TimeEntry/ITimeSheetApprover';
import { ITimesheetApprovers } from 'interfaces/TimeEntry/ITimeSheetApprovers';
import { ITimesheetDetails } from 'interfaces/TimeEntry/ITimesheetDetails';
import { trackEvent } from 'services/logging/appInsights';
import {
  fetchKaiserTimeSheet,
  fetchNonPassportTimesheet,
  fetchPreviousTimeSheetData,
  fetchTimesheet,
  fetchTimesheetApprovers,
  fetchTimesheetApproversForPlacement,
  fetchTimesheets,
  postTimesheet,
  postTimesheetApprover,
  resubmitTimesheet,
  updateTimesheet,
} from 'services/timeEntry/timeEntryService';
import { userIdSelector } from 'store/selectors/authSelector';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import {
  approverSelector,
  isOpenTimesheetSelector,
  timeEntrysActions,
} from 'store/slices/timeEntry/timeEntrySlice';
import { TOAST_MESSAGE } from 'constants/helperText';
import { AppUserType } from 'enums/AppUserType';
import store from '../../../store/configureStore';

const trackedTimesheetData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-timesheets');
const trackedTimesheetUpdate = (fn, ...args) =>
  trackPromise(fn(...args), 'update-timesheets');
const trackedSelectedTimesheetData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-selected-timesheet');
const trackedSelectedNonPassportTimesheetAction = (fn, ...args) =>
  trackPromise(fn(...args), 'get-selected-nonpassport-timesheet');
const trackedSelectedApproverData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-selected-approver');
const trackedMoreTimesheetsData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-more-timesheets');

function* getTimesheetData(action) {
  try {
    const userId = yield select(userIdSelector);
    const isOpen = yield select(isOpenTimesheetSelector);
    const userType = store.getState()?.auth?.userType;
    const isLocums = userType === AppUserType.LOCUMS ? true : false;
    const response = yield call(trackedTimesheetData, fetchTimesheets, {
      userId,
      statuses: isOpen
        ? `${TimeEntryStatus.Open},${TimeEntryStatus.Rejected}`
        : `${TimeEntryStatus.Pending},${TimeEntryStatus.Approved}`,
      limit: action.payload?.limit,
      placementId: action.payload?.placementId,
      isLocums: isLocums,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status)) {
      if (data) {
        const { items } = data;
        const { paging } = data;
        yield put(
          timeEntrysActions.setTimesheetState({
            openTimesheets: isOpen ? items : [],
            closedTimesheets: !isOpen ? items : [],
          }),
        );
        yield put(timeEntrysActions.setTotalTimesheetCount(paging?.total));

        // Set up dropdown list if submitted time sheets is selected
        // and it already hasnt been sat
        if (!action.payload?.placementId && !isOpen) {
          let temp = [
            {
              key: 'All',
              value: 'All Assignments',
            },
          ];

          items.forEach((item, idx) => {
            temp.push({
              key: item?.placementId || idx,
              value: item?.facilityName || `Facility ${idx}`,
            });
          });
          temp = _uniqWith(temp, (a, b) => a.key === b.key);
          yield put(timeEntrysActions.setTimesheetAssignments(temp));
        }
      } else {
        yield put(
          timeEntrysActions.setTimesheetState({
            openTimesheets: [],
            closedTimesheets: [],
          }),
        );
        yield put(timeEntrysActions.setTotalTimesheetCount(0));
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

function* getMoreTimesheetData(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(trackedMoreTimesheetsData, fetchTimesheets, {
      userId,
      statuses: action.payload?.statuses ? action.payload?.statuses : '',
      limit: action.payload?.limit,
      offset: action.payload?.offset,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      const { items } = data;
      const { paging } = data;
      const openTimesheets =
        items?.filter(
          (ts: ITimeEntryNavCard) =>
            ts.statusId === TimeEntryStatus.Open ||
            ts.statusId === TimeEntryStatus.Rejected,
        ) || [];
      const closedTimesheets =
        items?.filter(
          (ts: ITimeEntryNavCard) =>
            ts.statusId === TimeEntryStatus.Approved ||
            ts.statusId === TimeEntryStatus.Pending,
        ) || [];

      yield put(
        timeEntrysActions.updateTimesheetState({
          openTimesheets,
          closedTimesheets,
        }),
      );
      yield put(timeEntrysActions.setTotalTimesheetCount(paging?.total));
      let placementIds = items.map(item => item.placementId);
      yield put(timeEntrysActions.getTimesheetApproversAction(placementIds));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

function* getSelectedTimesheetData(action) {
  try {
    yield put(timeEntrysActions.setError(false));
    const userId = yield select(userIdSelector);
    let timesheetId = action.payload;
    const userType = store.getState()?.auth?.userType;
    const isLocums = userType === AppUserType.LOCUMS ? true : false;
    const response: AxiosResponse<ITimesheetDetails> = yield call(
      trackedSelectedTimesheetData,
      fetchTimesheet,
      userId,
      timesheetId,
      isLocums,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(timeEntrysActions.setSelectedTimesheet(data));
      if (data.placementId) {
        const res: AxiosResponse<ITimesheetApprovers[]> = yield call(
          trackedSelectedApproverData,
          fetchTimesheetApproversForPlacement,
          userId,
          data.placementId,
        );
        if (res && httpSuccess(res?.status)) {
          res.data.length > 0
            ? yield put(
                timeEntrysActions.setTimesheetApproversForPlacement(
                  res.data[0].timesheetApprovers,
                ),
              )
            : yield put(
                timeEntrysActions.setTimesheetApproversForPlacement([]),
              );
        }
      }
    } else {
      //if not success
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
      yield put(timeEntrysActions.setError(true));
    }
  } catch (e: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: e?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(timeEntrysActions.setError(true));
  }
}

function* getSelectedNonPassportTimesheetData(action) {
  try {
    yield put(timeEntrysActions.setError(false));
    const userId = yield select(userIdSelector);
    let params = action.payload;
    const response: AxiosResponse<INonPassportTimeEntry> = yield call(
      trackedSelectedNonPassportTimesheetAction,
      fetchNonPassportTimesheet,
      userId,
      params?.placementId,
      params?.startDate,
      params?.version,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(timeEntrysActions.setSelectedNonPassportTimesheet(data));
    } else {
      //if not success
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
      yield put(timeEntrysActions.setError(true));
    }
  } catch (e: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: e?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    yield put(timeEntrysActions.setError(true));
  }
}

function* getTimesheetApprovers(action) {
  const userId: string = yield select(userIdSelector);
  let placementIds: string[] = action.payload;
  try {
    const response = yield call(fetchTimesheetApprovers, userId, placementIds);
    if (response && httpSuccess(response?.status)) {
      const { data } = response;
      yield put(timeEntrysActions.setTimesheetApprovers(data));
    } else {
      yield put(timeEntrysActions.setTimesheetApprovers([]));
    }
  } catch (e: any) {
    const err = e.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

function* saveTimesheetApprover(action) {
  const userId: string = yield select(userIdSelector);
  const tsApprovers = yield select(approverSelector);
  let placementId = action.payload.placementId;

  let ids = new Set(action.payload.approvers.map(a => a.id));

  let approverRequest = action.payload.approvers.map(approver => {
    let a: ITimesheetApprover = {
      name: approver.name,
      emailAddress: approver.emailAddress,
      title: approver.title,
      phoneNumber: approver.phoneNumber,
    };
    return a;
  });

  try {
    const response = yield call(postTimesheetApprover, userId, placementId, {
      timesheetApprovers: [
        ...tsApprovers.filter(a => !ids.has(a.id)),
        ...approverRequest,
      ],
    });
    if (response && httpSuccess(response?.status)) {
      yield put(
        timeEntrysActions.updateTimesheetApproversForPlacement(
          action.payload.approvers,
        ),
      );
    }
    return response;
  } catch (e: any) {
    const err = e.data as IErrorResponseData;
    // eslint-disable-next-line no-console
    console.error(e);
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

function* saveTimesheet(action) {
  const userId: string = yield select(userIdSelector);
  const isOpen = yield select(isOpenTimesheetSelector);
  let timesheetId = action.payload.timesheetId;
  let timesheetRequest = action.payload.timesheetRequest;
  let isSubmitting = action.payload.isSubmitting;

  try {
    const response = yield call(
      trackedTimesheetUpdate,
      updateTimesheet,
      userId,
      timesheetId,
      timesheetRequest,
    );
    if (!isSubmitting && response && httpSuccess(response?.status)) {
      yield put(timeEntrysActions.setUpdateSuccess(true));
      yield put(timeEntrysActions.getSelectedTimesheetAction(timesheetId));
      yield put(
        timeEntrysActions.getTimesheetsAction({
          statuses: isOpen
            ? `${TimeEntryStatus.Open},${TimeEntryStatus.Rejected}`
            : `${TimeEntryStatus.Pending},${TimeEntryStatus.Approved}`,
          limit: 5,
        }),
      );
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.TimesheetSaved,
        }),
      );
    }
    if (isSubmitting && response && httpSuccess(response?.status)) {
      const subResponse = yield call(
        trackedTimesheetUpdate,
        postTimesheet,
        userId,
        timesheetId,
      );
      if (subResponse && httpSuccess(subResponse?.status)) {
        yield put(timeEntrysActions.setSubmissionSuccess(true));
        yield put(timeEntrysActions.setSelectedTimesheet({}));
        yield put(timeEntrysActions.setSelectedNonPassportTimesheet({}));
        yield put(
          timeEntrysActions.getTimesheetsAction({
            statuses: isOpen
              ? `${TimeEntryStatus.Open},${TimeEntryStatus.Rejected}`
              : `${TimeEntryStatus.Pending},${TimeEntryStatus.Approved}`,
            limit: 5,
          }),
        );
      }
    }
    if (response && !httpSuccess(response?.status)) {
      const err = response.data as IErrorResponse;
      if (err.errorCode > 0) {
        yield put(timeEntrysActions.setSubmissionError(err.errorCode));
      }
    }

    trackEvent('acct_time_entry_submitted');
  } catch (e: any) {
    const err = e.data as IErrorResponse;
    if (err.errorCode > 0) {
      yield put(timeEntrysActions.setSubmissionError(err.errorCode));
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: err?.errorMessage,
        }),
      );
    }
  }
}

function* resubmitTimesheetSaga(action) {
  const userId: string = yield select(userIdSelector);
  const isOpen = yield select(isOpenTimesheetSelector);
  const timesheetId = action.payload.timesheetId;
  const timesheetRequest = action.payload.timesheetRequest;
  const isSubmitting = action.payload.isSubmitting;
  const isReSubmitBtnClicked = action.payload.isSubmitBtnClicked;
  const timesheetApproverDTO = action.payload.timesheetApproverDTO || undefined;
  const newTimesheet = action.payload.newTimesheet;
  try {
    if (timesheetApproverDTO) {
      const updatedApproverResponse = yield call(saveTimesheetApprover, {
        payload: timesheetApproverDTO,
      });
      if (
        updatedApproverResponse &&
        !httpSuccess(updatedApproverResponse?.status)
      ) {
        const err = updatedApproverResponse.data as IErrorResponse;
        if (err?.errorCode > 0) {
          yield put(timeEntrysActions.setSubmissionError(err.errorCode));
        }
        yield put(
          openAlert({
            variant: 'error',
            message: TOAST_MESSAGE.ErrorUpdatingTimesheet,
          }),
        );
        yield put(timeEntrysActions.setSubmissionSuccess(false));
        return;
      }
    }

    const response = yield call(
      trackedTimesheetUpdate,
      resubmitTimesheet,
      userId,
      timesheetId,
      timesheetRequest,
    );

    if (response && !httpSuccess(response?.status)) {
      const err = response.data as IErrorResponse;
      if (err.errorCode > 0) {
        yield put(timeEntrysActions.setSubmissionError(err.errorCode));
      } else {
        yield put(
          openAlert({
            variant: 'error',
            message: err?.errorMessage,
          }),
        );
      }
    }

    if (!isSubmitting && response && httpSuccess(response?.status)) {
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.TimesheetSaved,
        }),
      );
      yield put(timeEntrysActions.getSelectedTimesheetAction(timesheetId));
      yield put(
        timeEntrysActions.getTimesheetsAction({
          statuses: isOpen
            ? `${TimeEntryStatus.Open},${TimeEntryStatus.Rejected}`
            : `${TimeEntryStatus.Pending},${TimeEntryStatus.Approved}`,
          limit: 5,
        }),
      );
      if (newTimesheet) {
        yield put(timeEntrysActions.setSelectedTimesheet(newTimesheet));
      }
    }

    if (isReSubmitBtnClicked && response && httpSuccess(response?.status)) {
      yield put(timeEntrysActions.setSubmissionSuccess(true));
      yield put(timeEntrysActions.setSelectedTimesheet(newTimesheet));
      yield put(timeEntrysActions.getSelectedTimesheetAction(timesheetId));
      yield put(
        timeEntrysActions.getTimesheetsAction({
          statuses: isOpen
            ? `${TimeEntryStatus.Open},${TimeEntryStatus.Rejected}`
            : `${TimeEntryStatus.Pending},${TimeEntryStatus.Approved}`,
          limit: 5,
        }),
      );
    }

    trackEvent('acct_time_entry_submitted');
  } catch (e: any) {
    const err = e.data as IErrorResponse;
    if (err?.errorCode > 0) {
      yield put(timeEntrysActions.setSubmissionError(err.errorCode));
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: err?.errorMessage,
        }),
      );
    }
  }
}

function* fetchKaiserTileData() {
  const userId: string = yield select(userIdSelector);
  try {
    const response = yield call(fetchKaiserTimeSheet, userId);
    const { data } = response;
    if (response && httpSuccess(response?.status)) {
      yield put(timeEntrysActions.setKaiserTimeSheet(data));
    }
  } catch (e: any) {
    const err = e.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

function* fetchPreviousTimeSheetEntry(action) {
  const userId: string = yield select(userIdSelector);
  try {
    const response = yield call(
      fetchPreviousTimeSheetData,
      userId,
      action.payload.placementId,
      action.payload.startDate,
    );
    const { data } = response;
    let temp = data?.entries
      ? data?.entries.map(entry => ({
          ...entry,
          mealPeriods: entry.mealPeriods.map(mp => {
            if (mp.isTaken === undefined || mp.isTaken === null) {
              let temp = { ...mp };

              if (
                (mp.elapsedTime && mp.elapsedTime !== '00:00:00') ||
                !!mp.clockInTime ||
                !!mp.clockOutTime
              ) {
                temp.isTaken = true;
                temp.typeId = TimeEntryBreakResponseTypeIds.OpportunityAndTaken;
                temp.type = 'OpportunityAndTaken';
              } else {
                temp.isTaken = false;
                temp.typeId =
                  TimeEntryBreakResponseTypeIds.OpportunityAndNotTaken;
                temp.type = 'OpportunityAndNotTaken';
              }
              return temp;
            } else {
              return mp;
            }
          }),
        })) || []
      : [];
    yield put(timeEntrysActions.setPreviousTimeSheetData(temp));
  } catch (e: any) {
    const err = e.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

export function* timeEntrySaga() {
  yield all([
    takeLatest(timeEntrysActions.getTimesheetsAction.type, getTimesheetData),
    takeLatest(
      timeEntrysActions.getSelectedTimesheetAction.type,
      getSelectedTimesheetData,
    ),
    takeLatest(
      timeEntrysActions.getSelectedNonPassportTimesheetAction.type,
      getSelectedNonPassportTimesheetData,
    ),
    takeLatest(
      timeEntrysActions.getTimesheetApproversAction.type,
      getTimesheetApprovers,
    ),
    takeLatest(
      timeEntrysActions.getMoreTimesheetsAction.type,
      getMoreTimesheetData,
    ),
    takeLatest(
      timeEntrysActions.postTimesheetApproverAction.type,
      saveTimesheetApprover,
    ),
    takeLatest(timeEntrysActions.saveTimesheetAction.type, saveTimesheet),

    takeLatest(
      timeEntrysActions.resubmitTimesheetAction.type,
      resubmitTimesheetSaga,
    ),
    takeLatest(
      timeEntrysActions.getKaiserTimeSheetAction.type,
      fetchKaiserTileData,
    ),
    takeLatest(
      timeEntrysActions.getPreviousTimeSheetAction.type,
      fetchPreviousTimeSheetEntry,
    ),
  ]);
}
