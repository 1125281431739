import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IFaq } from 'interfaces/FAQ/IFaq';
import { IFaqState } from './faqState';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { IContactResponse } from 'types/ContactResponse';

const initialState: IFaqState = {
  faqs: [] as IFaq[],
  isLoading: false,
  drawerOpen: DrawerContentNames.CLOSED_DRAWER,
  assignmentContacts: {
    placementContacts: [],
    strikeContacts: [],
    userContacts: [],
  },
};

export const faqSlice = createSlice({
  name: 'faqSlice',
  initialState,
  reducers: {
    getFaqs(state) {
      state.isLoading = true;
    },
    getAssignmentContacts(state) {
      state.isLoading = true;
    },
    setFaqs(state, action: PayloadAction<IFaq[]>) {
      state.faqs = action.payload;
      state.isLoading = false;
    },
    setAssignmentContacts(state, action: PayloadAction<IContactResponse>) {
      state.assignmentContacts = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        isLoading: true,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
    setDrawerState(state, action: PayloadAction<DrawerContentNames>) {
      state.drawerOpen = action.payload;
    },
  },
});

export const {
  getFaqs,
  getAssignmentContacts,
  setFaqs,
  setAssignmentContacts,
  setError,
  setDrawerState,
} = faqSlice.actions;
export default faqSlice.reducer;
