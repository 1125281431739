import { Box, Typography } from '@mui/material';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { IAssignmentContacts } from 'interfaces/Assignment/AssignmentSummaryItem';
import { IContact } from 'interfaces/User/Contact/IContact';
import PreferredRecruiter from 'pages/ContactsPage/PreferredRecruiter';
import React from 'react';
import { theme } from 'styles/theme';

const PersonalDetails = ({
  name,
  designation,
  contact,
  showPreferredBadge,
}: {
  name: string | undefined;
  designation: string | undefined;
  contact: IContact | IAssignmentContacts;
  showPreferredBadge: boolean;
  isStrike?: boolean;
}) => {
  const showMarkAsPreferred =
    showPreferredBadge && contact.employeeRoleId === AssignmentRoleId.RECRUITER;

  const isHSGRecruiter = contact.firstName === 'HSG';

  return (
    <>
      <Box
        sx={{
          justifyContent: 'center',
        }}
      >
        <PreferredRecruiter
          contact={contact}
          showPreferredBadge={showMarkAsPreferred}
        ></PreferredRecruiter>
        <Typography
          sx={{ color: 'system.grayText', marginBottom: '2px' }}
          data-testid="name"
        >
          {name}
        </Typography>
        {!isHSGRecruiter && (
          <Typography
            sx={{
              color: 'system.coolGray',
              [theme.breakpoints.up('md')]: {
                marginLeft: '0px',
              },
            }}
            data-testid="designation"
          >
            {designation}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default PersonalDetails;
