import useMediaQuery from '@mui/material/useMediaQuery';

export const isMobileQuery = '@media (max-width: 480px)';

const mobileQuery = '(max-width: 480px)';
const media1280 = '(max-width: 1280px)';

const xsQuery = '(max-width: 767px)';
const smQuery = '(max-width: 1023px)';
const mdQuery = '(max-width: 1365px)';
const lgQuery = '(max-width: 1919px)';

export function useMobileMediaQuery() {
  return useMediaQuery(mobileQuery);
}

export function useMedia1280px() {
  return useMediaQuery(media1280);
}

////////////////////////////////////////////////////

export function useXsMediaQuery() {
  return useMediaQuery(xsQuery);
}

export function useSmMediaQuery() {
  return useMediaQuery(smQuery);
}

export function useMdMediaQuery() {
  return useMediaQuery(mdQuery);
}

export function useLgMediaQuery() {
  return useMediaQuery(lgQuery);
}
