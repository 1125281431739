import React from 'react';
import { createSvgIcon } from '@mui/material';

export const DownloadIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" id="Controls-icon-Download">
    <g id="Group_2892" data-name="Group 2892">
      <rect id="Rectangle_2311" data-name="Rectangle 2311" opacity="0" />
      <g
        id="Group_2891"
        data-name="Group 2891"
        transform="translate(0.474 1.421)"
      >
        <path
          id="Path_3764"
          data-name="Path 3764"
          d="M29,28.946a.609.609,0,0,0-.611.608v5.764a.124.124,0,0,1-.122.122H13.908a.124.124,0,0,1-.122-.122V29.554a.611.611,0,0,0-1.223,0v5.764a1.342,1.342,0,0,0,1.345,1.337H28.271a1.342,1.342,0,0,0,1.345-1.337V29.554A.61.61,0,0,0,29,28.946Z"
          transform="translate(-12.563 -21.023)"
        />
        <path
          id="Path_3765"
          data-name="Path 3765"
          d="M25.647,23c.006.005.013.007.019.012a.6.6,0,0,0,.179.12h0A.613.613,0,0,0,26.511,23l3.139-3.121a.605.605,0,0,0,0-.859.614.614,0,0,0-.865,0L26.69,21.1V13.254a.611.611,0,0,0-1.223,0V21.1l-2.1-2.084a.614.614,0,0,0-.865,0,.605.605,0,0,0,0,.859Z"
          transform="translate(-17.553 -12.646)"
        />
      </g>
    </g>
  </svg>,
  'Download',
);
