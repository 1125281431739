export enum FeatureFlagType {
  Home_Feature_Content = 100,
  Home_Action_Items = 101,
  Home_COVID = 102,
  Assignments = 200,
  Jobs = 300,
  Credential_Center = 404,
  Credential_Center_Open = 401,
  Credential_Center_Docusign = 402,
  Credential_Center_Wallet = 403,
  Profile = 500,
  Profile_Personal = 501,
  Profile_Emergency = 502,
  Resources = 600,
  Preferences = 700,
  Preferences_Jobs = 701,
  Time_Entry = 1400,
  Customer_Support = 1800,
}
