import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { theme } from 'styles/theme';

export const AppCircularProgress = props => {
  return (
    <Backdrop
      id="app-circular-progress"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        position: 'absolute',
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(245, 245, 245, 0.9)',
      }}
      open={true}
    >
      <CircularProgress
        size="72px"
        sx={{
          position: 'absolute',
          top: '44%',
          left: '42%',
          zIndex: '100',
        }}
      />
    </Backdrop>
  );
};
