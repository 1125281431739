const PdfFileDownloader = (data: any, fileName: string) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/pdf' },
  };
  fetch(`${data}`, requestOptions)
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      // create file link in browser's memory
      const href = URL.createObjectURL(blob);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch(err => {
      return Promise.reject({ Error: 'Something Went Wrong', err });
    });
};

export default PdfFileDownloader;
