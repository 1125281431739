import * as React from 'react';
import { Card, Grid, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { theme } from 'styles/theme';

export default function SkeletonFeaturedContent() {
  const showSingle = useMediaQuery(theme.breakpoints.down('sm'));
  const arr = showSingle ? [0] : [0, 1, 2];
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginLeft="-10px"
        spacing={2}
      >
        {arr.map(value => (
          <Grid container item xs={12} sm={4} key={value}>
            <Card
              sx={{
                backgroundColor: 'system.main',
                borderRadius: '10px',
                opacity: '1',
                height: '132px',
                width: '290px',
              }}
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                direction="row"
                alignItems="center"
                mt={2}
                p={4}
              >
                <Grid item xs={12} pb={1}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: '160px',
                      height: '24px',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: '40%',
                      height: '24px',
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
