import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Modal,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppButton } from 'components/common/Button/Button';
import { ActionListDialog } from 'components/common/Dialog/ActionListDialog';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import { useAppDispatch } from 'store/configureStore';
import { theme } from 'styles/theme';
import { getCroppedImg } from 'helpers/cropImage';
import {
  deleteUserProfilePhoto,
  uploadUserProfilePhoto,
} from 'store/slices/user/userProfile/userProfileSlice';
import { setPhotoEditingState } from 'store/slices/photo/photoSlice';
import Camera from 'react-html5-camera-photo';
import CloseIcon from '@mui/icons-material/Close';

import AlertDialog from 'components/common/Dialog/ErrorDialog';
import { LockoutMessageRow } from 'containers/LoginContainer/LoginWrapper.styles';
import { FileUploadErrorsEnum } from 'enums/fileUploadType';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-html5-camera-photo/build/css/index.css';

const PhotoUpload: FC<any> = ({
  user,
  openModal,
  handleClose: handleCloseOptions,
}) => {
  const scale = 1;
  const aspect = 1;
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [rotate, setRotate] = useState(0);
  const [fileType, setFileType] = useState<string>('');
  const [openCameraDialog, setOpenCameraDialog] = useState(false);
  const [error, setError] = useState(FileUploadErrorsEnum.NO_ERROR);
  const [errorTypes] = useState({
    [FileUploadErrorsEnum.INVALID_FILE_TYPE]: {
      title: 'Invalid File Type',
      message: (
        <>
          This file type is not supported. You can only upload{' '}
          <Typography variant="body1" fontWeight="700" component="span">
            jpg, jpeg
          </Typography>{' '}
          or{' '}
          <Typography variant="body1" fontWeight="700" component="span">
            png
          </Typography>{' '}
          files.
        </>
      ),
    },
    [FileUploadErrorsEnum.EXCEEDS_FILE_SIZE_LIMIT]: {
      title: 'Exceeds File Size Limit',
      message:
        'The selected file exceeds the maximum allowed file size of 5MB. Optimize file to reduce size.',
    },
  });
  const dispatch = useAppDispatch();

  const photoDialogStyles = makeStyles(theme => ({
    cropper: {
      display: 'flex',
      '& .ReactCrop__crop-selection': {
        border: `1px ${theme.palette.system.inputBackground} solid`,
        '& .ReactCrop__drag-elements': {
          '& .ReactCrop__drag-handle::after': {
            border: 'none',
            outline: 'none',
            backgroundColor: 'rgba(0,0,0,0)',
          },
          '& .ReactCrop__drag-handle.ord-ne': {
            height: '24px',
            width: '24px',
            borderRight: `5px solid ${theme.palette.system.white}`,
            borderTop: `5px solid ${theme.palette.system.white}`,
          },
          '& .ReactCrop__drag-handle.ord-nw': {
            height: '24px',
            width: '24px',
            borderLeft: `5px solid ${theme.palette.system.white}`,
            borderTop: `5px solid ${theme.palette.system.white}`,
          },
          '& .ReactCrop__drag-handle.ord-se': {
            height: '24px',
            width: '24px',
            borderRight: `5px solid ${theme.palette.system.white}`,
            borderBottom: `5px solid ${theme.palette.system.white}`,
          },
          '& .ReactCrop__drag-handle.ord-sw': {
            height: '24px',
            width: '24px',
            borderLeft: `5px solid ${theme.palette.system.white}`,
            borderBottom: `5px solid ${theme.palette.system.white}`,
          },
        },
      },
    },
  }));
  const dialogStyles = photoDialogStyles();

  const handleClose = () => {
    handleCloseOptions();
    setFileType('');
  };

  const handleClosePhotoModal = () => {
    setPhotoDialogOpen(false);
  };

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      let fileSize = e.target.files[0].size / 1024 / 1024;
      let fileType = e.target.files[0].type.toString();
      const allowedFileTypes: string[] = ['image/jpeg', 'image/png'];
      if (!allowedFileTypes.includes(fileType)) {
        setError(FileUploadErrorsEnum.INVALID_FILE_TYPE);
        return;
      }
      if (fileSize > 5) {
        setError(FileUploadErrorsEnum.EXCEEDS_FILE_SIZE_LIMIT);
        return;
      }

      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(e.target.files[0]);
      setFileType(fileType);
      handleClose();
      setPhotoDialogOpen(true);
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  const openFile = () => {
    document.getElementById('profile_photo_input')?.click();
  };

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  const handleSetRotate = () => {
    if (rotate === 360) {
      setRotate(0);
    } else {
      setRotate(prevState => prevState + 90);
    }
  };

  const handleCrop = () => {
    let imageData: string;
    const croppedImage = getCroppedImg(
      imgRef.current,
      completedCrop as PixelCrop,
      rotate,
      fileType,
    );
    if (croppedImage) {
      if (croppedImage.includes('data')) {
        imageData = croppedImage.split(',')[1];
      } else {
        imageData = croppedImage;
      }
      dispatch(
        uploadUserProfilePhoto({
          body: imageData,
          userId: user.userId,
        }),
      );
      setPhotoDialogOpen(false);
      setCrop(undefined);
      setCompletedCrop(undefined);
      setRotate(0);
      handleClose();
    }
  };

  const handleDelete = () => {
    dispatch(deleteUserProfilePhoto(user.userId));
    setPhotoDialogOpen(false);
    handleClose();
    setFileType('');
  };

  // The library expects this to be declared as Vanilla javascript function and not an arrow function.
  function handleTakePhoto(dataUri) {
    if (dataUri && dataUri?.toString() && dataUri !== 'data:,') {
      setImgSrc(dataUri?.toString() || '');
      setOpenCameraDialog(false);
      setPhotoDialogOpen(true);
    } else {
      //Close camera dialog when invalid data is coming due to permission issue
      setPhotoDialogOpen(false);
      setOpenCameraDialog(false);
    }
  }

  useEffect(() => {
    dispatch(
      setPhotoEditingState(openModal || photoDialogOpen || openCameraDialog),
    );
  }, [openModal, photoDialogOpen, openCameraDialog, dispatch]);

  const handleCloseError = useCallback(() => {
    setError(FileUploadErrorsEnum.NO_ERROR);
  }, []);

  return (
    <>
      <AlertDialog
        open={error !== FileUploadErrorsEnum.NO_ERROR}
        handleClose={handleCloseError}
        title={errorTypes[error]?.title}
        dismissText="DISMISS"
      >
        <LockoutMessageRow>{errorTypes[error]?.message}</LockoutMessageRow>
      </AlertDialog>
      <ActionListDialog
        title="Update Profile Picture"
        open={openModal}
        onClose={handleClose}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color={theme.palette.system.skyBlue}
              onClick={e => setOpenCameraDialog(true)}
              sx={{
                cursor: 'pointer',
              }}
            >
              Take Photo
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="photo_upload" onClick={openFile}>
              <Typography
                variant="subtitle1"
                color={theme.palette.system.skyBlue}
                sx={{
                  cursor: 'pointer',
                }}
              >
                Upload Photo
              </Typography>
              <input
                id="profile_photo_input"
                type="file"
                onChange={onSelectFile}
                name="photo_upload"
                hidden={true}
              />
            </label>
          </Grid>
          {user?.profileImage?.url && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color={theme.palette.system.skyBlue}
                onClick={handleDelete}
                sx={{
                  cursor: 'pointer',
                }}
              >
                Delete Photo
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color={theme.palette.system.skyBlue}
              onClick={handleClose}
              sx={{
                cursor: 'pointer',
              }}
            >
              Cancel
            </Typography>
          </Grid>
        </Grid>
      </ActionListDialog>
      <Modal open={openCameraDialog} onClose={e => setOpenCameraDialog(false)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Camera
            onTakePhoto={dataUri => {
              handleTakePhoto(dataUri);
            }}
            isFullscreen={false}
            idealResolution={{ width: 640, height: 480 }}
          />
        </div>
      </Modal>
      {Boolean(imgSrc) && (
        <Dialog
          open={photoDialogOpen}
          onClose={handleClosePhotoModal}
          maxWidth={'md'}
          sx={{
            '& .MuiDialog-paperWidthMd': {
              marginLeft: '24px',
              marginRight: '24px',
            },
          }}
        >
          {/* setting title typography inline to prevent console error */}
          <DialogTitle
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              color: `${theme.palette.system.midnightBlue}`,
              lineHeight: '35px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            Crop & Rotate
            <CloseIcon
              onClick={handleClosePhotoModal}
              htmlColor={theme.palette.system.coolGray}
              height="17px"
              width="17px"
            />
          </DialogTitle>
          <DialogContent
            sx={{
              padding: '0px',
              borderTop: `1px solid ${theme.palette.system.inputBackground}`,
              borderBottom: `1px solid ${theme.palette.system.inputBackground}`,
              maxHeight: '450px',
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={1}
              className={dialogStyles.cropper}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </DialogContent>

          <DialogActions
            sx={{
              padding: {
                xs: `${theme.spacing(2)} ${theme.spacing(2)}`,
                md: `${theme.spacing(2)} ${theme.spacing(4)}`,
              },
            }}
          >
            <Grid
              container
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Typography
                  onClick={handleClosePhotoModal}
                  variant="body1"
                  color={theme.palette.system.skyBlue}
                  mr={3}
                  letterSpacing="1.25px"
                  lineHeight="16px"
                  fontWeight={600}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  CANCEL
                </Typography>
              </Grid>
              <Grid item>
                <AppButton
                  id="rotate-input"
                  variant="primary"
                  type="button"
                  width="100px"
                  disabled={!imgSrc}
                  onClick={handleSetRotate}
                  sx={{
                    marginRight: '6px',
                  }}
                >
                  Rotate
                </AppButton>
              </Grid>
              <Grid item ml={2}>
                <AppButton
                  id="crop-input"
                  type="button"
                  variant="primary"
                  width="100px"
                  disabled={!imgSrc}
                  onClick={handleCrop}
                >
                  Done
                </AppButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export { PhotoUpload };
