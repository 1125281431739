import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material';
import { CompactStateFlagDialogContent } from 'components/Jobs/JobSearch/JobPreference/CompactLocationCheckbox/CompactStateFlagDialogContent';
import { CustomizedDialog } from 'components/common/Dialog/CustomizedDialog';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { setInCompactState } from 'store/slices/user/userPreferenceSlice';

export const CompactLocationCheckbox = ({
  setFormDirty,
}: {
  setFormDirty?: Function;
}) => {
  const dispatch = useDispatch();
  const [showFlags, setShowFlags] = React.useState<boolean>(false);
  const isInCompactState = useAppSelector(state => {
    return state.userPreference.isInCompactState;
  });

  const onChange = (event: any) => {
    setFormDirty?.(true);
    dispatch(
      setInCompactState(
        !event.target.checked ? undefined : event.target.checked,
      ),
    );
  };

  const handleClose = () => {
    setShowFlags(false);
  };

  return (
    <Grid
      item
      id="compact-location-checkbox"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '6px',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            id={`compact_states`}
            checked={isInCompactState === undefined ? false : isInCompactState}
            onChange={onChange}
          />
        }
        label="Include Compact States"
        sx={{
          color: 'system.midnightBlue',
          '& .MuiTypography-root': {
            fontSize: '16px',
          },
        }}
      />
      <IconButton onClick={() => setShowFlags(true)} sx={{ padding: '0px' }}>
        <InfoOutlinedIcon fontSize="small" />
      </IconButton>
      {showFlags && (
        <CustomizedDialog
          title="Compact States"
          body={<CompactStateFlagDialogContent />}
          isOpen={showFlags}
          handleClose={handleClose}
          dialogContentSX={{ height: '60vh' }}
        />
      )}
    </Grid>
  );
};
