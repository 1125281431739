import React from 'react';
import { theme } from 'styles/theme';

export default function SearchIcon({
  color,
  size,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      id="Controls_Icon_Search"
      data-name="Controls/Icon/Search"
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '38'}
      height={size ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2254"
        data-name="Rectangle 2254"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <path
        id="Path_2934"
        data-name="Path 2934"
        d="M49.417,46.4l-4.1-4.105L40.3,37.284a14.723,14.723,0,1,0-2.774,2.772l9.119,9.119A1.96,1.96,0,0,0,49.417,46.4ZM16.794,28.411A11.86,11.86,0,1,1,28.654,40.272,11.874,11.874,0,0,1,16.794,28.411Z"
        transform="translate(-12.992 -12.75)"
        fill={color ?? theme.palette.system.coolGray}
      />
    </svg>
  );
}
