import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';
import { IOpenCredential, IRequirement } from './ICredentialStatusProps';
import CredentialPercentage from './CredentialPercentage';
import { StatusCode } from 'enums/StatusCode';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { TOAST_MESSAGE } from 'constants/helperText';
import useTrackPageView from 'utils/hooks/useTrackPageView';

const CredentialStatusContent = () => {
  const credentialStatus = useAppSelector(state => {
    return state.credentialStatus;
  });
  const credentialStatusData = credentialStatus.credentialStatus;
  const error = credentialStatus?.error;
  const {
    totalItems,
    totalCompliantItems,
    compliantPercentage,
    openCredentials,
    approvedCredentials,
  } = credentialStatusData || {};
  const preventClick = (e: any) => {
    e.stopPropagation();
  };
  const dispatch = useAppDispatch();
  useTrackPageView('JobCredentialStatus');

  useEffect(() => {
    if (error) {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
          alertbarRefId: 'credential-status',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const CredentialTitle = (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{ marginBottom: '30px' }}
    >
      <Grid item xs={12} textAlign="left">
        {Boolean(compliantPercentage) ? (
          <>
            <CredentialPercentage
              width="50px"
              height="50px"
              marginRight="10px"
              credentialPercentage={
                credentialStatusData?.compliantPercentage ?? 0
              }
            ></CredentialPercentage>

            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                overflowWrap: 'break-word',
                inlineSize: { xs: '230px', md: '323px' },
              }}
            >
              <Typography
                color="system.skyblue"
                sx={{
                  color: 'system.midnightBlue',
                  fontWeight: 600,
                  fontSize: '17px',
                }}
              >
                {totalCompliantItems}/{totalItems} Credentials Approved For This
                Job
              </Typography>
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'inline-flex', justifyContent: 'center' }}>
            <Typography
              color="system.skyblue"
              sx={{
                color: 'system.midnightBlue',
                fontWeight: 600,
                fontSize: '17px',
              }}
            >
              No Credentials For This Job Yet!
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={12} textAlign="left">
        <Box sx={{ display: 'inline-flex' }}>
          <Typography sx={{ color: 'system.coolGray' }}>
            <b style={{ color: 'black' }}>Get a head start!</b> All open
            credentials shown are required for you to take this assignment. The
            more credentials you have completed prior to applying, the higher
            ranking you will show as a candidate. Additional credentials may be
            required once you are booked on this job.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const OpenCredentials = (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{ marginBottom: '30px' }}
    >
      <Grid item xs={12} textAlign="left">
        <Box>
          <Typography
            color="system.skyblue"
            sx={{
              color: 'system.midnightBlue',
              fontWeight: 600,
              fontSize: '17px',
            }}
          >
            Open Credentials
          </Typography>
        </Box>
      </Grid>
      {openCredentials?.map(
        (openCredential: IOpenCredential, index: number) => (
          <Grid
            key={index}
            item
            xs={12}
            textAlign="left"
            sx={{ marginBotton: '10px' }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '15px',
                }}
              >
                {openCredential.category}
              </Typography>
            </Box>

            {openCredential?.requirements.map(
              (requirement: IRequirement, idx: number) => (
                <Box key={idx}>
                  <Typography
                    color="system.skyblue"
                    sx={{
                      color: 'system.coolGray',
                    }}
                  >
                    {requirement.requirementFullDescription}
                  </Typography>
                </Box>
              ),
            )}
          </Grid>
        ),
      )}
    </Grid>
  );

  const ApprovedCredentials = (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginBottom: '20px' }}
      >
        <Grid item xs={12} textAlign="left">
          <Box>
            <Typography
              color="system.skyblue"
              sx={{
                color: 'system.midnightBlue',
                fontWeight: 600,
                fontSize: '17px',
              }}
            >
              Approved Credentials
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {approvedCredentials?.length ? (
        approvedCredentials.map(
          (approvedCredential: IRequirement, index: number) => (
            <Grid
              key={index}
              container
              spacing={2}
              alignItems="center"
              sx={{ marginBottom: '10px' }}
            >
              <Grid item xs={12} textAlign="left">
                <CheckCircleIcon
                  sx={{
                    color: 'system.grassGreen',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '10px',
                  }}
                ></CheckCircleIcon>

                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    overflowWrap: 'break-word',
                    inlineSize: { xs: '220px', md: '300px' },
                  }}
                >
                  <Typography component="span" color="system.coolGray">
                    {approvedCredential?.requirementFullDescription}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ),
        )
      ) : (
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginBottom: '10px' }}
        >
          <Grid item xs={12} textAlign="left">
            <Typography sx={{ color: 'system.coolGray', fontStyle: 'italic' }}>
              You currently have no approved credentials related to this
              opportunity.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <Box onClick={e => preventClick(e)}>
      {error && error?.errorCode >= StatusCode.InternalServerError ? (
        <></>
      ) : (
        <>
          {CredentialTitle}
          {OpenCredentials}
          {ApprovedCredentials}
        </>
      )}
    </Box>
  );
};

export default CredentialStatusContent;
