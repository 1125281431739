import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { theme } from 'styles/theme';

export const TextWithUnderline = ({ children, isActive, onClickCallback }) => {
  return (
    <Box
      sx={{
        borderBottom: isActive
          ? `2px solid ${theme.palette.system.skyBlue}`
          : 'none',
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: isActive
            ? theme.palette.system.skyBlue
            : theme.palette.system.coolGray,
          width: 'max-content',
        }}
        onClick={onClickCallback}
      >
        {children}
      </Typography>
    </Box>
  );
};
