import { Backdrop } from '@mui/material';
import { IJobsData } from 'interfaces/Jobs/IJobsData';
import { DivIcon } from 'leaflet';
import React, { useCallback, useMemo, useState } from 'react';
import { MapContainer, Marker, TileLayer, ZoomControl } from 'react-leaflet';
import { theme } from 'styles/theme';
import { Swiper, SwiperSlide } from 'swiper/react';
import { JobDetails } from '../JobDetails/JobDetails';
import { createJobSearchStyles } from './JobSearch.styles';
import { JobSearchCard as MobileJobSearchCard } from './JobSearchCard';
import { trackEvent } from 'services/logging/appInsights';

export const MapJobViewContent = ({
  jobs,
  setMapRef,
  selectedJob,
  onClickFxn,
  onSwipe,
}: {
  jobs?: IJobsData[];
  setMapRef: any;
  selectedJob?: number;
  onClickFxn: (item, index) => void;
  onSwipe?: (item) => void;
}) => {
  const [expandCard, setExpandCard] = useState<boolean>(false);
  const classes = createJobSearchStyles();

  const displayMarker = active => {
    return new DivIcon({
      html: `<svg
          id="Controls_icon_Pinpoint-Filled"
          data-name="Controls/icon/Pinpoint-Filled"
          xmlns="http://www.w3.org/2000/svg"
          width="${active ? 45 : 35}"
          height="${active ? 45 : 35}"
          viewBox="0 0 38 38"
        >
          <g id="Group_2909" data-name="Group 2909">
            <path
              id="Path_3784"
              data-name="Path 3784"
              d="M29.635,11.7A15.15,15.15,0,0,0,19.012,37.5l.029.028,9.731,9.812a1.214,1.214,0,0,0,1.726,0l9.678-9.761c.018-.016.059-.056.076-.074l0,0h0A15.148,15.148,0,0,0,29.635,11.7Zm0,20.751a5.628,5.628,0,1,1,5.582-5.628A5.612,5.612,0,0,1,29.635,32.45Z"
              transform="translate(-10.635 -10.699)"
              fill="${
                active
                  ? theme.palette.system.skyBlue
                  : theme.palette.system.error
              }"
            />
          </g>
        </svg>`,
    });
  };

  const mapComponent = useMemo(
    () => (
      <MapContainer
        ref={setMapRef}
        center={[37.0902, -95.7129]}
        zoom={window.outerWidth > theme.breakpoints.values.md ? 5 : 3}
        zoomControl={false}
      >
        <ZoomControl position="topright" />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {jobs?.map(
          (job: IJobsData, index) =>
            job.address?.location?.coordinates && (
              <Marker
                key={job.jobId}
                position={job.address?.location?.coordinates}
                icon={displayMarker(selectedJob === job.jobId)}
                eventHandlers={{
                  click: () => {
                    onClickFxn(job, index);
                    trackEvent('job_location_click', { jobId: job.jobId });
                  },
                }}
              />
            ),
        )}
      </MapContainer>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobs, selectedJob],
  );

  const focusMarker = e => {
    let jobIdx = e.activeIndex;
    if (jobs && onSwipe) {
      onSwipe(jobs[jobIdx]);
    }
  };

  const swiperInit = useCallback(
    swiper => {
      swiper?.slideTo?.(
        (jobs || []).reduce((prev: number, next: IJobsData, index: number) => {
          if (next?.jobId === selectedJob) {
            return index;
          }

          return prev;
        }, 0),
      );
    },
    [selectedJob, jobs],
  );

  return (
    <div id="map-view-content" className={classes.mapContainer}>
      {mapComponent}
      {window.outerWidth < theme.breakpoints.values.sm ? (
        <div className={classes.mobileSwiper}>
          <Swiper
            spaceBetween={12}
            slidesPerView={1.1}
            onSlideChange={focusMarker}
            onInit={swiperInit}
          >
            {jobs?.map((job: IJobsData, index) => (
              <SwiperSlide key={`${job.jobId}-${index}`}>
                <MobileJobSearchCard
                  topJob={job}
                  onClick={() => onClickFxn(job, index)}
                  showMap={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <Backdrop
          sx={{
            zIndex: expandCard ? theme.zIndex.drawer - 1 : 'initial',
            position: 'absolute',
            borderRadius: '10px',
          }}
          invisible={!expandCard}
          open={true}
        >
          {jobs && jobs?.length > 0 ? (
            <JobDetails
              isCardExpanded={expandCard}
              showClose={expandCard}
              classes={
                expandCard
                  ? classes.mapJobContainerExpanded
                  : classes.mapJobContainer
              }
              onCardClick={() => setExpandCard(true)}
              onCloseClick={() => setExpandCard(false)}
              showMap={true}
            />
          ) : (
            <></>
          )}
        </Backdrop>
      )}
    </div>
  );
};
