import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Typography } from '@mui/material';
import QuestionsIcon from 'assets/icons/Jobs/QuestionsIcon';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { theme } from 'styles/theme';
import QuestionsDrawerTemplate from './QuestionsDrawerTemplate';
import { trackEvent } from 'services/logging/appInsights';

const Questions = props => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
    open: boolean,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    event && event.stopPropagation();
    setOpen(open);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          left: 0,
          cursor: 'pointer',
          borderTop: `1px solid ${theme.palette.system.border}`,
        }}
        onClick={e => toggleDrawer(e, true)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '12px',
            width: '100%',
          }}
        >
          <QuestionsIcon width="17px" height="17px" />
          <Button
            sx={{
              width: '100% !important',
              height: 'auto',
              textTransform: 'none',
              color: 'system.skyBlue',
              font: 'normal normal normal 14px/19px Open Sans',
              letterSpacing: '0px',
              padding: '0px !important',
              '&:hover': {
                background: theme.palette.system.white,
              },
              '& .MuiButton-endIcon': {
                marginLeft: 'auto',
              },
            }}
            variant="text"
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
            onClick={() =>
              trackEvent('job_questions_click', { jobId: props.jobId })
            }
          >
            <Typography
              color="system.skyblue"
              variant="body1"
              sx={{
                color: 'system.skyBlue',
                font: 'normal normal normal 14px/20px Open Sans',
                letterSpacing: '0px',
                paddingLeft: '6px',
              }}
            >
              Questions?
            </Typography>
          </Button>
        </Box>
      </Box>
      {open && (
        <DrawerPanel
          contentName="Questions?"
          open={open}
          toggleDrawer={toggleDrawer}
          showFooter={true}
          cancelButtonText=""
        >
          <QuestionsDrawerTemplate
            isStrike={props.isStrike}
          ></QuestionsDrawerTemplate>
        </DrawerPanel>
      )}
    </>
  );
};

export default Questions;
