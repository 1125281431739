import { Grid, Typography } from '@mui/material';
import ArrowRightIcon from 'assets/icons/Controls/ArrowRight';
import InfoIcon from 'assets/icons/Info/InfoIcon';
import { IQualificationListItem } from 'interfaces/Props/IActionItemProps';
import React from 'react';
import { theme } from 'styles/theme';

export const QualificationItemPanelListItem: React.FC<
  IQualificationListItem
> = ({ data }) => {
  return (
    <>
      <Grid
        container
        item
        onClick={() => {
          if (data?.onMenuClick) data?.onMenuClick(data?.options[0], data?.id);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          minHeight: '23px',
          flexWrap: 'nowrap',
          width: '100%',
        }}
      >
        <Grid item sx={{ marginRight: '12px' }}>
          {InfoIcon(theme.palette.system.midnightBlue, '23.35', '23.25')}
        </Grid>
        <Grid item flexGrow={1}>
          <Typography
            variant="body1"
            component="span"
            sx={{
              textOverflow: 'ellipsis',
              color: 'system.midnightBlue',
            }}
          >
            {data?.title}
          </Typography>
        </Grid>
        <Grid item>
          {ArrowRightIcon({
            color: theme.palette.system.coolGray,
            height: '16',
            width: '16',
          })}
        </Grid>
      </Grid>
    </>
  );
};
