import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ArrowDownIcon } from 'assets/icons/Arrows/Arrow-Down';
import { IAccordionProps } from 'interfaces/Props/IAccordionProps';
import { theme } from 'styles/theme';
import { nestedAccordStyles } from './Accordion.styles';

/**
 * Expanding accordion component
 */
export const ExpandingAccordion = ({
  accordionTitle,
  accordionContent,
  expanded = undefined,
  onChange,
  sx,
}: IAccordionProps) => {
  const classes = nestedAccordStyles();
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      className={classes.container}
    >
      <AccordionSummary
        className={classes.summary}
        id="accordion-content"
        aria-controls="accordion-content"
        expandIcon={
          <ArrowDownIcon
            htmlColor={theme.palette.system.skyBlue}
            sx={{
              width: '14px',
              height: '14px',
            }}
          />
        }
        sx={{ ...sx }}
      >
        {accordionTitle}
      </AccordionSummary>
      <AccordionDetails
        className={classes.details}
        sx={{ paddingLeft: '36px' }}
      >
        {accordionContent}
      </AccordionDetails>
    </Accordion>
  );
};
