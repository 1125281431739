import { apiErrors } from 'enums/apiErrors';
import { httpSuccess } from 'helpers/serviceHelper';
import { IActionPayloadType } from 'interfaces/Common/IActionPayloadType';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IUserEmailRequest } from 'interfaces/User/UserEmail/IUserEmailRequest';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AppRouteNames } from 'routes/appRouteNames';
import * as authenticationService from 'services/authentication/authenticationService';
import {
  createUser,
  getUserDetail,
  validateUserCode,
} from 'services/user/userService';
import { setError } from 'store/slices/error/errorSlice';
import {
  checkForValidUserLogin,
  createUserAccount,
  setLoadingAction,
  setNewUserAccount,
  setNewUserCode,
  setUserStatusLogin,
  validateNewUserCode,
} from 'store/slices/user/userLogin/userLoginSlice';
import { CreateUserRequest } from 'types/CreateUserRequest';

// Worker Sagas //
function* getLoginUserState(action: IActionPayloadType) {
  try {
    const userEmail = action.payload as string;
    const userValidityResponse = yield call(() => getUserDetail(userEmail));
    let { data } = userValidityResponse;
    if (data) {
      if (!data.exists) {
        data.exists = undefined;
        data.hasLoggedInBefore = false;
        data.userEmail = userEmail;
        data.redirectRoute = AppRouteNames.CREATE_ACCOUNT;
        yield put(setUserStatusLogin(data));
      }
      if (data.exists && data.hasLoggedInBefore) {
        yield put(
          setError({
            errorCode: apiErrors.EmailAlreadyExist,
            errorData: {
              emailAddress: userEmail,
            },
            errorDetails: '',
            errorMessage: 'Account already exists.',
            operationId: '',
            correlationId: '',
          }),
        );
        data.exists = undefined;
        data.hasLoggedInBefore = false;
        data.userEmail = userEmail;
        data.redirectRoute = AppRouteNames.SIGN_IN;
        yield put(setUserStatusLogin(data));
      } else if (data.exists && !data.hasLoggedInBefore) {
        data.userEmail = userEmail;
        data.redirectRoute = AppRouteNames.EMAIL_VERIFICATION;
        try {
          const res = yield call(authenticationService.sendPasswordResetCode, {
            emailAddress: userEmail,
          });
          if (res && httpSuccess(res?.status)) {
            yield put(setUserStatusLogin(data));
          }
        } catch (error: any) {
          const err = error.data as IErrorResponse;
          yield put(setError(err));
        }
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

function* validateUserCodeWatcher(action) {
  yield put(setLoadingAction(true));
  try {
    const userDetail = action.payload as IUserEmailRequest;
    const userCodeResponse = yield call(() => validateUserCode(userDetail));
    const { data } = userCodeResponse;
    yield put(setNewUserCode(data));
    yield put(setLoadingAction(false));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoadingAction(false));
  }
}

function* createAccount(action: IActionPayloadType) {
  yield put(setLoadingAction(true));
  try {
    const newUser = action.payload as CreateUserRequest;
    const userCodeResponse = yield call(() => createUser(newUser));
    const { data } = userCodeResponse;
    yield put(setNewUserAccount(data));
    yield put(setLoadingAction(false));
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoadingAction(false));
  }
}

// Watcher Sagas //

function* getValidUserWatcher() {
  yield takeLatest(checkForValidUserLogin.type, getLoginUserState);
}

function* validateNewUserWatcher() {
  yield takeLatest(validateNewUserCode.type, validateUserCodeWatcher);
}

function* createUserAccountWatcher() {
  yield takeLatest(createUserAccount.type, createAccount);
}

export function* userLoginSaga() {
  yield all([
    getValidUserWatcher(),
    validateNewUserWatcher(),
    createUserAccountWatcher(),
  ]);
}
