import { Grid } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';
import { createDetailFeatureStyles } from './PageLayout.styles';

export const DetailFeaturePage = ({
  featureContent,
  subHeaderBarContent,
}: {
  featureContent?: any;
  subHeaderBarContent?: any;
}) => {
  const classes = createDetailFeatureStyles();
  return (
    <>
      <Grid container id="detail-feature-page">
        {subHeaderBarContent && (
          <Grid
            container
            spacing={3}
            className={classes?.subHeaderBar}
            sx={{
              borderBottom: `1px solid ${theme.palette.system.border}`,
              height: '60px',
              backgroundColor: 'system.white',
            }}
          >
            {subHeaderBarContent}
          </Grid>
        )}
        <Grid container item className={classes?.detailFeatureContainer}>
          <Grid
            item
            xs={12}
            md={8}
            lg={9}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {featureContent}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
