import moment from 'moment';

export const formatDate = (dateString?: string) => {
  let formattedDate = '';
  if (dateString) {
    formattedDate = moment(dateString).format('ddd - MMM D, YYYY');
  }
  return formattedDate;
};

export const formatShiftTimeRange = (
  startDateTime?: string,
  endDateTime?: string,
) => {
  let timeRange = '';
  if (startDateTime && endDateTime) {
    const startTime = moment(startDateTime).format('h:mm A');
    const endTime = moment(endDateTime).format('h:mm A');
    timeRange = `${startTime} - ${endTime}`;
  }
  return timeRange;
};
