import { createAction, createSlice } from '@reduxjs/toolkit';
import { IImpactTrackerState } from 'interfaces/ImpactTracker/IImpactTrackerState';

const initialState: IImpactTrackerState = {
  patientCareHours: 0,
  communitiesServed: {
    total: 0,
    communities: [],
  },
};

const getImpactTracker = createAction('GET_IMPACT_TRACKER');

const impactTrackerSlice = createSlice({
  name: 'impactTrackerSlice',
  initialState,
  reducers: {
    setImpactTracker(state, action) {
      state.patientCareHours = action.payload.patientCareHours;
      state.communitiesServed = action.payload.communitiesServed;
    },
  },
});

export const impactTrackerActions = {
  getImpactTracker,
  ...impactTrackerSlice.actions,
};

export default impactTrackerSlice.reducer;
