import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { verificationCodeState } from './verificationCodeState';

const initialState: verificationCodeState = {
  isVerificationCodeSent: false,
  isLoading: false,
};

const verificationCodeSlice = createSlice({
  name: 'verificationCodeSlice',
  initialState,
  reducers: {
    createUserCode(state, action) {},
    setVerification(state, action: PayloadAction<boolean>) {
      state.isVerificationCodeSent = action.payload;
    },
    cancelVerification(state) {
      state.isVerificationCodeSent = false;
    },
    setLoadingAction(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const {
  createUserCode,
  setVerification,
  cancelVerification,
  setLoadingAction,
} = verificationCodeSlice.actions;
export default verificationCodeSlice.reducer;
