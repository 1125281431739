import axios from 'axios';
import { IImageSize } from 'interfaces/Common/IImageSize';
import { useEffect, useState } from 'react';
import { http } from 'services/BaseApi';

/**
 * Method to handle calling API to get image using protected url
 * Take a look at app/src/components/ContactCard/ContactCardImage.tsx for an ex
 *
 * @param imageUrl - image url from the API
 */
export const useGetAuthenticatedImage = (
  imageUrl?: string,
  imageSize?: IImageSize,
) => {
  const [imgState, setImgState] = useState<string>();

  useEffect(() => {
    if (!imageUrl) return;
    const source = axios.CancelToken.source();
    if (!source) return;

    let requestUrl = `${imageUrl}/url`;

    if (imageSize) {
      let size = Object.keys(imageSize)
        .map(function (key) {
          return key + '=' + imageSize[key];
        })
        .join('&');

      requestUrl += '?' + size;
    }

    http
      .get<string>(requestUrl, { cancelToken: source?.token })
      .then(response => {
        setImgState(response.data.fileUrl);
      })
      .catch(err => {
        setImgState(undefined);
      });
    return () => {
      source.cancel();
      setImgState(undefined);
    };
  }, []);

  return imgState;
};
