import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { FeatureFlagType } from 'enums/featureFlags';
import { MenuIcons } from 'enums/menuIcons';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { INavSectionIcon } from 'interfaces/Nav/INavSectionIcon';
import { RootState } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { isLocumsSelector } from 'store/selectors/authSelector';
import { jobShareActions } from 'store/slices/jobs/jobShareSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import MenuIcon from '../../../assets/icons/NavMenuIcon/NavMenuIcon';
import { trackEvent } from '../../../services/logging/appInsights';
import { useAppDispatch } from '../../../store/configureStore';
import mapMenuIconsection from '../../../utils/mapMenuIconNameSection';
import {
  activeTheme,
  StylesActiveLeftBorder,
  StylesActiveLeftBorderHeight2Lines,
  StylesActiveLeftBorderHeight3Lines,
  StylesDivider,
  StylesLocumsDivider,
  StylesDrawerContainer,
  StylesDrawerList,
  StylesHoverDivider,
  StylesLocumsHoverDivider,
  StylesHoverDrawerContainer,
  StylesHoverDrawerList,
  StylesHoverListItem,
  StylesListItem,
  StylesMenuIconHoverLastRow,
  StylesMenuIconRow,
  StylesMenuIconText,
  StylesMenuIconTextHoverLastRow,
  theme,
} from './NavMenu.styles';
import { toolTipsTourActions } from 'store/slices/toolTipsTour/toolTipsTourSlice';
import { TippyToolTip } from 'components/common/ToolTip/TippyToolTip';
import { ToolTipsTour } from 'components/common/ToolTipsTour/ToolTipsTour';
import { ADP_URL } from 'constants/passportUrls';

export default function NavMenu() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const active: MenuIcons | string = useAppSelector(
    (state: RootState) => state.nav.activePage,
  );

  const [mouseOver, setMouseOver] = useState(false);

  const containerRef = useRef(null);

  const featureFlags = useAppSelector(state => state.userProfile.featureFlags);

  const userStatusTypeId: number = useAppSelector(
    state => state.userProfile.userStatusTypeId,
  );

  const assignmentData = useAppSelector(
    state => state.assignmentSummary.assignmentState,
  );

  const useOpenRequirementsFlag = useMemo(() => {
    return (
      featureFlags && featureFlags[FeatureFlagType.Credential_Center]?.isEnabled
    );
  }, [featureFlags]);

  const handleMouseOver = () => {
    setMouseOver(true);
  };
  const handleMouseOut = () => {
    setMouseOver(false);
  };

  const handleClick = (value: MenuIcons | string) => {
    trackEvent(`${value}_MenuItem_Clicked`);
    if (value === MenuIcons.Pay) {
      if (assignmentData?.items?.length === 0) {
        dispatch(setActivePage(MenuIcons.Pay));
        history.push('/payroll');
      } else {
        const adpWindow = window.open(ADP_URL, '_blank', 'noopener,noreferrer');
        if (adpWindow) adpWindow.opener = null;
      }
    } else if (value === MenuIcons.Jobs) {
      if (navigator['share']) {
        dispatch(jobShareActions.setCanUseNativeShare(true));
        dispatch(setActivePage(value));
        dispatch(toolTipsTourActions.setIndex(1));
        history.push(`${mapMenuIconsection(value)}`);
      } else {
        dispatch(setActivePage(value));
        history.push(`${mapMenuIconsection(value)}`);
      }
    } else {
      dispatch(setActivePage(value));
      history.push(`${mapMenuIconsection(value)}`);
    }
  };

  const isLocums: boolean = useAppSelector(isLocumsSelector);

  const topSectionIcons: INavSectionIcon[] = isLocums
    ? []
    : [
        {
          name: MenuIcons.Home,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Home}
              icon={MenuIcons.Home}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        {
          name: MenuIcons.Contacts,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Contacts}
              icon={MenuIcons.Contacts}
            />
          ),
          transform: 'scale(1.3) translate(-3px,0px)',
        },
        {
          name: MenuIcons.Jobs,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Jobs}
              icon={MenuIcons.Jobs}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.Assignments,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Assignments}
              icon={MenuIcons.Assignments}
            />
          ),
          transform: 'scale(1)',
        },
        {
          name: MenuIcons.TaskCenter,
          icon: (
            <MenuIcon
              active={active === MenuIcons.TaskCenter}
              icon={MenuIcons.TaskCenter}
            />
          ),
          transform: 'scale(1.2) translate(-3px,0px)',
        },
        {
          name: MenuIcons.SkillsChecklist,
          icon: (
            <MenuIcon
              active={active === MenuIcons.SkillsChecklist}
              icon={MenuIcons.SkillsChecklist}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        {
          name: MenuIcons.Resources,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Resources}
              icon={MenuIcons.Resources}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },
        // {
        //   name: MenuIcons.HousingTravel, // Hidden for future use per US24963
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.HousingTravel}
        //       icon={MenuIcons.HousingTravel}
        //     />
        //   ),
        //   transform: 'scale(1.1) translate(-2px,0px)',
        // },
        {
          name: MenuIcons.Preferences,
          icon: (
            <MenuIcon
              active={active === MenuIcons.Preferences}
              icon={MenuIcons.Preferences}
            />
          ),
          transform: 'scale(1.1) translate(-2px,0px)',
        },

        userStatusTypeId !== UserStatusTypeId.LEAD
          ? {
              name: MenuIcons.ShareAndRefer,
              icon: (
                <MenuIcon
                  active={active === MenuIcons.ShareAndRefer}
                  icon={MenuIcons.ShareAndRefer}
                />
              ),
              transform: 'scale(1.1) translate(-2px,0px)',
            }
          : {},
      ];

  const timeAndPaySectionIcons = [
    {
      name: MenuIcons.Pay,
      icon: <MenuIcon active={active === MenuIcons.Pay} icon={MenuIcons.Pay} />,
      transform: 'scale(1.1) translate(-2px,0px)',
    },
    {
      name: MenuIcons.TimeEntry,
      icon: (
        <MenuIcon
          active={active === MenuIcons.TimeEntry}
          icon={MenuIcons.TimeEntry}
        />
      ),
      transform: 'scale(1.3) translate(-4px,0px)',
    },
    {
      name: MenuIcons.Reimbursements,
      icon: (
        <MenuIcon
          active={active === MenuIcons.Reimbursements}
          icon={MenuIcons.Reimbursements}
        />
      ),
      transform: 'scale(1.1) translate(-2px,0px)',
    },
  ];

  const otherSectionIcons = isLocums
    ? []
    : [
        // {
        //   name: MenuIcons.RewardsRecognition, // Hidden for future use per US24963
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.RewardsRecognition}
        //       icon={MenuIcons.RewardsRecognition}
        //     />
        //   ),
        //   transform: 'scale(1.1) translate(-1px, 0)',
        // },
        // {
        //   name: MenuIcons.EducationTrainingCommunity, // Hidden for future use per US24963
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.EducationTrainingCommunity}
        //       icon={MenuIcons.EducationTrainingCommunity}
        //     />
        //   ),
        //   transform: 'scale(1.2) translate(-2px, 0px)',
        // },
        {
          name: MenuIcons.Faq,
          icon: (
            <MenuIcon active={active === MenuIcons.Faq} icon={MenuIcons.Faq} />
          ),
          transform: 'scale(1)',
        },
        // {/* Hiding for Minimum Viable Product requirements for US 31532*/}
        // {
        //   name: MenuIcons.Feedback,
        //   icon: (
        //     <MenuIcon
        //       active={active === MenuIcons.Feedback}
        //       icon={MenuIcons.Feedback}
        //     />
        //   ),
        //   transform: 'scale(1)',
        // },
      ];

  const mapIconTranslation = (iconName: string) => {
    const translateIcon = {
      'Rewards & Recognition': 'scale(1.1) translate(0px, -6px)',
      'Education, Training, & Community': 'scale(1.2) translate(-2px, -16px)',
      FAQs: 'translate(0px, -6px)',
      'Privacy Policy / Terms of Use': 'translate(0px, -6px)',
    };
    return translateIcon[iconName];
  };

  const mapCorrectBorderHeight = (iconName: string) => {
    // {/* Hiding for Minimum Viable Product requirements for US 31532*/}
    // if (active === iconName && active === MenuIcons.Feedback)
    //   return <Box sx={StylesActiveLeftBorderHeight1HalfLines}></Box>;
    // else
    if (active === iconName && active === MenuIcons.RewardsRecognition)
      return <Box sx={StylesActiveLeftBorderHeight2Lines}></Box>;
    else if (active === iconName && active === MenuIcons.PrivacyPolicy)
      return <Box sx={StylesActiveLeftBorderHeight2Lines}></Box>;
    else if (active === iconName && active === MenuIcons.Faq)
      return <Box sx={StylesActiveLeftBorderHeight2Lines}></Box>;
    else if (
      active === iconName &&
      active === MenuIcons.EducationTrainingCommunity
    )
      return <Box sx={StylesActiveLeftBorderHeight3Lines}></Box>;
    else if (active === iconName)
      return <Box sx={StylesActiveLeftBorder}></Box>;
  };

  const getNavigationMenu = (iconName: string) => {
    switch (iconName) {
      case MenuIcons.Faq:
        return <Typography variant="body1">{MenuIcons.FaqNav}</Typography>;
      case MenuIcons.Reimbursements:
        return (
          <Typography variant="body1">{MenuIcons.ReimbursementsNav}</Typography>
        );
      default:
        return <Typography variant="body1">{iconName}</Typography>;
    }
  };

  const HoverDrawer = useMemo(
    () => (
      <Box onMouseLeave={handleMouseOut} sx={StylesHoverDrawerContainer}>
        <List sx={StylesHoverDrawerList}>
          {topSectionIcons?.reduce((prev, icon) => {
            if (Object.keys(icon).length > 0) {
              return [
                ...prev,
                <Box
                  id={`nav-${icon.name?.replace(' ', '-')}`}
                  key={icon.name}
                  onClick={() => handleClick(icon.name ?? '')}
                  sx={StylesMenuIconRow}
                >
                  <ListItemIcon
                    key={icon.name}
                    style={{
                      transform: icon.transform,
                    }}
                    sx={StylesHoverListItem}
                  >
                    {icon.icon}
                  </ListItemIcon>
                  <Box sx={StylesMenuIconText}>
                    <ThemeProvider
                      theme={active === icon.name ? activeTheme : theme}
                    >
                      {getNavigationMenu(icon.name ?? '')}
                    </ThemeProvider>
                  </Box>
                  {active === icon.name && (
                    <Box sx={StylesActiveLeftBorder}></Box>
                  )}
                </Box>,
              ];
            }
            return prev;
          }, [] as ReactElement[])}

          <Divider
            sx={isLocums ? StylesLocumsHoverDivider : StylesHoverDivider}
          />

          {timeAndPaySectionIcons.map(icon => (
            <Box
              key={icon.name}
              onClick={() => handleClick(icon.name)}
              sx={StylesMenuIconRow}
            >
              <ListItemIcon
                key={icon.name}
                style={{
                  transform: icon.transform,
                }}
                sx={StylesHoverListItem}
              >
                {icon.icon}
              </ListItemIcon>
              <Box sx={StylesMenuIconText}>
                <ThemeProvider
                  theme={active === icon.name ? activeTheme : theme}
                >
                  {getNavigationMenu(icon.name)}
                </ThemeProvider>
              </Box>
              {active === icon.name && <Box sx={StylesActiveLeftBorder}></Box>}
            </Box>
          ))}

          <Divider
            sx={isLocums ? StylesLocumsHoverDivider : StylesHoverDivider}
          />

          {otherSectionIcons.map(icon => (
            <Box
              key={icon.name}
              onClick={() => handleClick(icon.name)}
              sx={StylesMenuIconHoverLastRow}
            >
              <ListItemIcon
                key={icon.name}
                style={{
                  transform: mapIconTranslation(icon.name),
                }}
                sx={StylesHoverListItem}
              >
                {icon.icon}
              </ListItemIcon>
              <Box sx={StylesMenuIconTextHoverLastRow}>
                <ThemeProvider
                  theme={active === icon.name ? activeTheme : theme}
                >
                  {getNavigationMenu(icon.name)}
                </ThemeProvider>
              </Box>
              {mapCorrectBorderHeight(icon.name)}
            </Box>
          ))}
        </List>
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      active,
      mapCorrectBorderHeight,
      otherSectionIcons,
      timeAndPaySectionIcons,
      topSectionIcons,
    ],
  );

  const isHomePage = useMemo(
    () => location.pathname === '/home',
    [location.pathname],
  );

  return (
    <>
      <Box
        role="navigation"
        onMouseEnter={handleMouseOver}
        sx={StylesDrawerContainer}
      >
        <List sx={StylesDrawerList}>
          {topSectionIcons?.reduce((prev, icon) => {
            if (Object.keys(icon).length > 0) {
              return [
                ...prev,
                <Box key={icon.name} sx={StylesMenuIconRow}>
                  <ListItemIcon
                    key={icon.name}
                    style={{
                      transform: icon.transform,
                    }}
                    sx={StylesListItem}
                  >
                    {icon.icon}
                  </ListItemIcon>
                  {active === icon.name && (
                    <Box sx={StylesActiveLeftBorder}></Box>
                  )}
                </Box>,
              ];
            }
            return prev;
          }, [] as ReactElement[])}

          <Divider sx={isLocums ? StylesLocumsDivider : StylesDivider} />

          {timeAndPaySectionIcons.map(icon => (
            <Box key={icon.name} sx={StylesMenuIconRow}>
              <ListItemIcon
                key={icon.name}
                style={{
                  transform: icon.transform,
                }}
                sx={StylesListItem}
              >
                {icon.icon}
              </ListItemIcon>
              {active === icon.name && <Box sx={StylesActiveLeftBorder}></Box>}
            </Box>
          ))}

          <Divider sx={isLocums ? StylesLocumsDivider : StylesDivider} />

          {otherSectionIcons.map(icon => (
            <Box key={icon.name} sx={StylesMenuIconRow}>
              <ListItemIcon
                key={icon.name}
                style={{
                  transform: icon.transform,
                }}
                sx={StylesListItem}
              >
                {icon.icon}
              </ListItemIcon>
              {active === icon.name && <Box sx={StylesActiveLeftBorder}></Box>}
            </Box>
          ))}
        </List>
      </Box>
      <Box ref={containerRef}>
        <Slide
          direction="right"
          in={mouseOver}
          container={containerRef.current}
        >
          {HoverDrawer}
        </Slide>
      </Box>
      {isHomePage && (
        <ToolTipsTour id="100" index={0}>
          <TippyToolTip
            id="ApplyJob-0"
            selector="#nav-Job-Search"
            placement="right"
            message="Search for your next job!"
          />
        </ToolTipsTour>
      )}
    </>
  );
}
