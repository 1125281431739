import React from 'react';
import Box from '@mui/material/Box';
import { IApplyJobMessageProps } from 'interfaces/Props/IApplyJobMessageProps';
import { theme } from 'styles/theme';
import { ApplyJobStatusMessageContent } from './ApplyJobStatusMessageContent';
import { ApplyJobStatusMessageContentNonStrike } from './ApplyJobStatusMessageContentNonStrike';

export const ApplyJobStatusMessageCard = (props: IApplyJobMessageProps) => {
  const { id, description, message, open, isStrike, isExpanded = true } = props;
  const isSignedUpText = props && props.isStrike && !props.isExpanded;

  return (
    <Box
      id={id}
      sx={{
        width: '100%',
        [theme.breakpoints.up('lg')]: { maxWidth: '177px' },
        [theme.breakpoints.up('md')]: {
          maxWidth: !isStrike ? 'auto' : '148px',
          height: !isStrike ? '100%' : isExpanded ? '188px' : '42px',
          backgroundColor: theme.palette.system.inputBackground,
          padding: !isStrike
            ? '0px 22px 12px 22px'
            : isSignedUpText
            ? '12px'
            : '0 12px 12px 12px',
          border: `1px solid ${theme.palette.system.border}`,
          borderRadius: '7px',
          display: open === true ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      }}
    >
      {!isStrike ? (
        <ApplyJobStatusMessageContentNonStrike
          id={id}
          description={description}
          isExpanded={isExpanded}
        />
      ) : (
        <ApplyJobStatusMessageContent
          id={id}
          message={message}
          isStrike={isStrike}
          isExpanded={isExpanded}
          description={description}
          messageSx={{
            margin: isSignedUpText ? '2px 12px' : '6px 0px 0px',
          }}
        />
      )}
    </Box>
  );
};
