import { allowedFileTypes } from 'constants/credentials';
import React, { useMemo } from 'react';
import { PDFIcon } from 'assets/icons/Files/PDFIcon';
import { DocIcon } from 'assets/icons/Files/DocIcon';
import { IUploadedFileState } from 'store/slices/fileUpload/fileUploadState';
import { makeStyles } from '@mui/styles';

const fileThumbnailStyles = makeStyles(theme => ({
  fileImg: {
    width: '100% !important',
    position: 'absolute',
  },
  icon: {
    width: '60px !important',
    height: '100% !important',
  },
}));
export const FileUploadThumbnail = ({ file }: { file: IUploadedFileState }) => {
  const styles = fileThumbnailStyles();
  const returnThumbnail = useMemo(
    () => {
      switch (file.fileType) {
        case allowedFileTypes.pdf:
          return (
            <PDFIcon
              className={styles.icon}
              data-testid="file-upload-thumbnail_pdf"
            />
          );
        case allowedFileTypes.doc:
        case allowedFileTypes.docx:
          return (
            <DocIcon
              className={styles.icon}
              data-testid="file-upload-thumbnail_doc"
            />
          );
        default:
          return (
            <img
              className={styles.fileImg}
              alt="Document"
              src={file.fileLocalUrl}
              data-testid="file-upload-thumbnail_img"
            />
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file],
  );
  return <>{returnThumbnail}</>;
};
