import { PayloadAction } from '@reduxjs/toolkit';
import { IShiftLength } from 'components/Jobs/JobSearch/JobPreference/IShiftLength';
import { IShiftTime } from 'components/Jobs/JobSearch/JobPreference/IShiftTime';
import { alterAllOptions } from 'components/Jobs/JobSearch/JobPreference/utils';
import { TOAST_MESSAGE } from 'constants/helperText';
import { StatusCode } from 'enums/StatusCode';
import { JobSortType } from 'enums/jobSortType';
import { searchRadiusEnum } from 'enums/searchRadiusEnums';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { jobSearchPageResultTransformer } from 'services/jobs/jobSearchResponseTransformer';
import * as jobService from 'services/jobs/jobService';
import { trackEvent } from 'services/logging/appInsights';
import {
  fetchSearchLocation,
  fetchUserPreference,
  updateAutoSubmitPreferences,
  updatePreferredRecruiter,
  updateUserPreference,
} from 'services/user/userService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import {
  setHasOnlyRecommended,
  setJobSearchFilter,
  setJobs,
  setSelectedJob,
} from 'store/slices/jobs/jobsSlice';
import {
  markPreferredRecruiter,
  postAutoSubmissionsPreference,
  postUserPreference,
  retrieveUserPreference,
  saveAutoSubmitPreferences,
  searchLocation,
  setError,
  setExhaustiveDisciplineOptions,
  setExpertiseFormFields,
  setLocationAPIError,
  setPreferredRecruiter,
  setSearchLocation,
  setSubmitLoader,
  setUserPreference,
  setUserPreferenceSuccess,
  toggleCategoryStatus,
  toggleSubCategoryStatus,
  updateCategory,
  updateUserPreferenceNotificationSetting,
} from 'store/slices/user/userPreferenceSlice';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';
import {
  IAutoSubmitPreference,
  ICategoryElement,
  ILocationSearch,
  ISubcategoryElement,
  UserPreference,
  UserPreferenceRequest,
} from 'types/UserPreference';
import { locationResponseToLocation } from 'utils/locationHelper';
import {
  locationToLocationResponse,
  mapExpertiseFormFields,
  mapFacilityTypes,
  mapLocationsForRadius,
  mapShifts,
  mapStartDate,
  mapjobTypes,
} from './userPreferenceSagaHelper';

export const userId = (state: RootState) => state.auth.userId;
const user = (state: RootState): IUserProfile => state.userProfile;
const disciplineOptions = (state: RootState) =>
  state.userPreference.disciplineOptions;
const disciplineList = (state: RootState) => state.lookup.disciplines;
const currentCategories = (state: RootState): ICategoryElement[] | undefined =>
  state.userPreference.userPreference?.notifications.categories;

const userPreference = (state: RootState): UserPreference =>
  state.userPreference.userPreference;

const disciplineResponse = (state: RootState): ISuccessDisciplineResponse =>
  state.lookup.disciplines;

const assignmentSummaryItem = (state: RootState) =>
  state.assignmentSummary.assignmentState?.items;

const hasRecommended = (state: RootState) => state.jobs.hasRecommended;
const hasOnlyRecommended = (state: RootState) => state.jobs.hasOnlyRecommended;
const getJobFilter = (state: RootState) => state.jobs.jobSearchFilter;

function* getUserPreference(action) {
  const currentUserId = yield select(userId);

  try {
    const response = yield call(
      f => fetchUserPreference(currentUserId),
      action.payload,
    );
    const { data } = response;
    const userProfile = yield select(user);
    const disciplineList = yield select(disciplineResponse);
    const assignmentDetails = yield select(assignmentSummaryItem);

    const locations = locationToLocationResponse(data?.jobs?.locations);
    const initialLocations = locations;
    const isInCompactState = data?.jobs?.isInCompactState;
    const mappedValues = mapExpertiseFormFields(
      data?.jobs?.disciplinesAndSpecialties,
      userProfile,
      disciplineList,
    );

    const expertiseFormFields = mappedValues['expertiseFormFields'];
    const alliedDisciplineAndSpecialties =
      mappedValues['alliedDisciplineAndSpecialties'];

    const jobTypes = mapjobTypes(data?.jobs?.placementTypes);
    const startDate = mapStartDate(assignmentDetails, data?.jobs?.startDate);
    const shifts = mapShifts(data?.jobs?.shifts);
    const shiftTimes = shifts['shiftTimes'];
    const shiftLengths = shifts['shiftLengths'];
    const facilityTypes = mapFacilityTypes(data?.jobs.facilityTypes);
    const newsFeed = data?.newsfeed;

    // Auto Submission Preferences
    const autoSubmitLocations = locationToLocationResponse(
      data?.autoSubmitPreference?.locations || [],
    );
    const autoSubmitShifts = mapShifts(data?.autoSubmitPreference?.shifts);
    const autoSubmitShiftTimes = autoSubmitShifts['shiftTimes'];
    const autoSubmitShiftLengths = autoSubmitShifts['shiftLengths'];
    yield put(
      setUserPreference({
        userPreference: {
          ...data,
          autoSubmitPreference: {
            enableAutoSubmissions:
              autoSubmitLocations?.length > 0
                ? data?.autoSubmitPreference?.optIn ?? null
                : null,
            locations: autoSubmitLocations,
            minimumPay: data?.autoSubmitPreference?.minPayRate,
            earliestDate: data?.autoSubmitPreference?.earliestStartDate,
            shiftTimes: autoSubmitShiftTimes,
            shiftLengths: autoSubmitShiftLengths,
            isSigned: data?.autoSubmitPreference?.isSigned,
            locationRadiusOptIn:
              data?.autoSubmitPreference?.locationRadiusOptIn,
            disciplinesAndSpecialties:
              data?.autoSubmitPreference?.disciplinesAndSpecialties,
          },
        },
        locations,
        initialLocations,
        isInCompactState,
        expertiseFormFields,
        alliedDisciplineAndSpecialties,
        jobTypes,
        startDate,
        shiftTimes,
        shiftLengths,
        facilityTypes,
        newsFeed,
      }),
    );
  } catch (error: any) {
    console.error(error);
  }
}

export function* putUserPreference(
  action: PayloadAction<UserPreferenceRequest>,
) {
  const currentUserId = yield select(userId);

  let hasOnlyRecommendedValue = yield select(hasOnlyRecommended);
  let hasRecommendedValue = yield select(hasRecommended);

  let currentFilter = yield select(getJobFilter);
  const minJobCount = 25;

  let locations: ILocationSearch[] = action.payload?.jobs?.locations || [];

  let hasRadiusToGrow = false;
  locations?.some?.(location => {
    const radius = location?.cityInfo?.radius;
    if (typeof radius === 'number' && radius < searchRadiusEnum.FOURTH_RADIUS) {
      hasRadiusToGrow = true;
      return true;
    }
    return false;
  });

  yield put(setSubmitLoader(true));
  try {
    yield call(updateUserPreference, currentUserId, action.payload);
    yield put(setUserPreferenceSuccess());
    yield call(getUserPreference, action);

    if (action?.payload?.hasOwnProperty('jobs')) {
      if (hasOnlyRecommendedValue) {
        yield put(setHasOnlyRecommended(false));
        yield put(
          setJobSearchFilter({
            ...currentFilter,
            limit: currentFilter.limit,
            sort: currentFilter.sort || JobSortType.WeeklyPay,
          }),
        );
      }
      // calling endpoint directly rather than using saga
      const response = yield call(jobService.fetchJobs, {
        userId: currentUserId,
        limit: currentFilter.limit || 40,
        sort: currentFilter.sort,
      });
      let data = response?.data;
      let _locations: ILocationSearch[] = [];
      switch (response.status) {
        case StatusCode.OK:
          if (response?.data?.items?.length < minJobCount && hasRadiusToGrow) {
            _locations = mapLocationsForRadius(locations);
            let updatedPreferenceRequest = {
              ...action.payload,
              jobs: {
                ...action.payload.jobs,
                locations: _locations,
              },
            };
            yield put(postUserPreference({ ...updatedPreferenceRequest }));
          }
          hasOnlyRecommendedValue = false;
          break;
        case StatusCode.NoContent:
          if (hasRadiusToGrow) {
            _locations = mapLocationsForRadius(locations);
            let updatedPreferenceRequest = {
              ...action.payload,
              jobs: {
                ...action.payload.jobs,
                locations: _locations,
              },
            };
            yield put(postUserPreference({ ...updatedPreferenceRequest }));
          } else {
            const response = yield call(jobService.fetchJobs, {
              userId: currentUserId,
              limit: currentFilter.limit || 40,
              sort: JobSortType.Recommended,
            });
            switch (response.status) {
              case StatusCode.OK:
                data = response.data;
                hasRecommendedValue = true;
                hasOnlyRecommendedValue = true;
                break;
              case StatusCode.NoContent:
                hasOnlyRecommendedValue = false;
                hasRecommendedValue = false;
                break;
            }
          }
          break;
        default:
          break;
      }

      let jobs = data.items || [];
      for (const item of jobs) {
        // coordinates are sent as long, lat instead of expected lat, long
        item.address.location.coordinates =
          item?.address?.location?.coordinates.reverse();
      }
      if (data) {
        data.items = jobSearchPageResultTransformer(jobs);
        yield put(
          setJobs({
            ...data,
            hasOnlyRecommended: hasOnlyRecommendedValue,
            hasRecommended: hasRecommendedValue,
          }),
        );
      } else {
        yield put(
          setJobs({
            items: jobs,
            hasOnlyRecommended: hasOnlyRecommendedValue,
            hasRecommended: hasRecommendedValue,
          }),
        );
      }

      jobs.length > 0
        ? yield put(setSelectedJob(jobs[0].jobId))
        : yield put(setSelectedJob(undefined));
    }
    yield put(setSubmitLoader(false));
    yield put(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.ChangesSaved,
      }),
    );
    trackEvent('JobPreferences_Saved');
  } catch (error: any) {
    const err = {
      status: error.status,
      ...error.data,
    } as IErrorResponse;
    yield put(setError(err));
    yield put(setSubmitLoader(false));
    trackEvent('JobPreferences_FailedToUpdate');
  }
}

export function* putAutoSubmissionPreferences(
  action: PayloadAction<IAutoSubmitPreference>,
) {
  const currentUserId = yield select(userId);
  yield put(setSubmitLoader(true));

  let locationsToSave = [] as ILocationSearch[];

  action.payload.locations.forEach(location => {
    if (location.searchText) {
      const loc = locationResponseToLocation(location);

      if (loc.cityInfo) {
        const cityInformation = { ...loc.cityInfo };
        loc.cityInfo = { ...cityInformation };
      }
      locationsToSave.push(loc);
    }
  });

  // Shift times
  let tempShiftTimes = [...action.payload.shiftTimes];
  if (tempShiftTimes[0]['isActive']) {
    tempShiftTimes = alterAllOptions(tempShiftTimes, false) as IShiftTime[];
  }
  tempShiftTimes.splice(0, 1);

  // Shift lengths
  let tempShiftLengths = [...action.payload.shiftLengths];
  if (tempShiftLengths[0]['isActive']) {
    tempShiftLengths = alterAllOptions(
      tempShiftLengths,
      false,
    ) as IShiftLength[];
  }
  tempShiftLengths.splice(0, 1);

  const postData = {
    optin: !!action.payload.enableAutoSubmissions,
    locations: locationsToSave,
    minPayRate: action.payload.minimumPay,
    earliestStartDate: action.payload.earliestDate,
    shifts: [...tempShiftTimes, ...tempShiftLengths],
    locationRadiusOptIn: action.payload.locationRadiusOptIn,
    disciplinesAndSpecialties: action.payload.disciplinesAndSpecialties,
  };

  try {
    yield call(updateAutoSubmitPreferences, currentUserId, postData);
    yield put(saveAutoSubmitPreferences(action.payload));
    yield put(setSubmitLoader(false));
    yield put(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.ChangesSaved,
      }),
    );
    trackEvent('AutoSubmissionsPreferences_Saved');
  } catch (error: any) {
    const err = {
      status: error.status,
      ...error.data,
    } as IErrorResponse;
    yield put(setError(err));
    yield put(setSubmitLoader(false));
    trackEvent('AutoSubmissionsPreferences_FailedToUpdate');
  }
}

export function* putUserPreferredRecruiter(action) {
  const currentUserId = yield select(userId);
  try {
    const result = yield call(
      f => updatePreferredRecruiter(currentUserId, action.payload),
      action.payload,
    );
    yield put(setPreferredRecruiter(result.data));
  } catch (error: any) {
    const err = { status: error.status, ...error.data } as IErrorResponse;
    yield put(setError(err));
  }
}

export function* postSearchLocation(action) {
  const currentUserId = yield select(userId);
  try {
    const response = yield call(
      f => fetchSearchLocation(currentUserId, f.limit, f.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSearchLocation(data));
    yield put(setLocationAPIError(false));
  } catch (error: any) {
    yield put(setSearchLocation([]));
    yield put(setLocationAPIError(true));
  }
}

export function* updateCategoryStatus(action) {
  const categories = yield select(currentCategories);
  const index = categories?.findIndex(e => e.categoryId === action.payload);
  const currentStatus = categories[index].isActive;
  let updatedCategory = categories?.map((e: ICategoryElement) => {
    if (e.categoryId === action.payload) {
      let updatedSubCategory = [...e.subcategories].map(
        (sub: ISubcategoryElement) => {
          if (!sub.isEditable) return { ...sub };
          return {
            ...sub,
            isActive: !currentStatus,
          };
        },
      );
      return {
        ...e,
        isActive: !currentStatus,
        subcategories: updatedSubCategory,
      };
    }
    return { ...e };
  }) as ICategoryElement[];

  yield put(updateCategory(updatedCategory));
  yield put(updateUserPreferenceNotificationSetting());
}

export function* updateSubCategoryStatus(action) {
  let categoriesState = yield select(currentCategories);
  const index = categoriesState?.findIndex(
    e => e.categoryId === action.payload.categoryId,
  );
  let selectedCategory = { ...categoriesState[index] } as ICategoryElement;
  const updatedSubCategory = selectedCategory.subcategories?.map(
    (e: ISubcategoryElement) => {
      if (e.subcategoryId === action.payload.subCategoryId) {
        return { ...e, isActive: !e.isActive };
      }
      return { ...e };
    },
  ) as ISubcategoryElement[];

  const shouldMakeParentSettingInactive = updatedSubCategory
    .filter(e => e.isEditable)
    .every(e => !e.isActive);

  const shouldMakeParentSettingActive = updatedSubCategory
    .filter(e => e.isEditable)
    .some(e => e.isActive);

  let categories = [...categoriesState];
  selectedCategory.subcategories = [...updatedSubCategory];
  // change parent setting value depending on child status
  if (shouldMakeParentSettingInactive) selectedCategory.isActive = false;
  if (shouldMakeParentSettingActive) selectedCategory.isActive = true;

  categories[index] = { ...selectedCategory };
  yield put(updateCategory(categories));
  yield put(updateUserPreferenceNotificationSetting());
}

export function* updateUserPreferenceNotification() {
  const currentUserPreference = yield select(userPreference);
  const copy = { ...currentUserPreference } as UserPreference;
  const currentUserId = yield select(userId);
  const request: UserPreferenceRequest = {
    id: copy.id,
    jobs: copy.jobs,
    notifications: copy.notifications,
    feedback: copy.feedback,
    userId: currentUserId,
    match: copy.match,
  };
  try {
    yield call(f => updateUserPreference(currentUserId, f), request);
  } catch (error: any) {
    const err = {
      status: error.status,
      ...error.data,
    } as IErrorResponse;
    yield put(setError(err));
  }
}

export function* setExpertiseFormFieldsSaga(action) {
  try {
    const expertiseFormField = action.payload;
    const currentDisciplineOptions = yield select(disciplineOptions);
    const currentDisciplineList = yield select(disciplineList);
    if (
      currentDisciplineList &&
      currentDisciplineList.items?.length &&
      currentDisciplineOptions?.length &&
      expertiseFormField?.length
    ) {
      const specialtyCountMap = currentDisciplineList.items.reduce(
        (accumulator, currentValue) => {
          accumulator.set(
            currentValue.disciplineId,
            currentValue.specialities.length,
          );
          return accumulator;
        },
        new Map(),
      );

      expertiseFormField.forEach((val: any, index: number) => {
        if (val.discipline === '') {
          return;
        }
        const id = Number(val.discipline);
        const count = specialtyCountMap.get(id);
        specialtyCountMap.set(id, count - 1);
      });

      const filtered = currentDisciplineOptions.filter((val: any) => {
        const id = Number(val.key);
        return specialtyCountMap.get(id) !== 0;
      });

      yield put(setExhaustiveDisciplineOptions(filtered));
    }
  } catch (error: any) {
    const err = {
      status: error.status,
      ...error.data,
    } as IErrorResponse;
    yield put(setError(err));
  }
}

function* watchRetrieveUserPreference() {
  yield takeLatest(retrieveUserPreference.type, getUserPreference);
}

function* watchUpdateUserPreference() {
  yield takeLatest(postUserPreference.type, putUserPreference);
}

function* watchUpdateUserPreferredRecruiter() {
  yield takeLatest(markPreferredRecruiter.type, putUserPreferredRecruiter);
}

function* watchsearchLocation() {
  yield takeLatest(searchLocation.type, postSearchLocation);
}

function* watchUpdateCategoryStatus() {
  yield takeLatest(toggleCategoryStatus.type, updateCategoryStatus);
}

function* watchUpdateSubCategoryStatus() {
  yield takeLatest(toggleSubCategoryStatus.type, updateSubCategoryStatus);
}

function* watchUpdateUserPreferenceNotificationSetting() {
  yield takeLatest(
    updateUserPreferenceNotificationSetting.type,
    updateUserPreferenceNotification,
  );
}

function* watchSetExpertiseFormFieldSaga() {
  yield takeLatest(setExpertiseFormFields.type, setExpertiseFormFieldsSaga);
}

function* watchUpdateAutoSubmitPreferencesSaga() {
  yield takeLatest(
    postAutoSubmissionsPreference.type,
    putAutoSubmissionPreferences,
  );
}

export function* userPreferenceSaga() {
  yield all([
    watchRetrieveUserPreference(),
    watchUpdateUserPreference(),
    watchUpdateUserPreferredRecruiter(),
    watchsearchLocation(),
    watchUpdateCategoryStatus(),
    watchUpdateSubCategoryStatus(),
    watchUpdateUserPreferenceNotificationSetting(),
    watchSetExpertiseFormFieldSaga(),
    watchUpdateAutoSubmitPreferencesSaga(),
  ]);
}
