import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { CallIcon } from 'assets/icons/Contacts/CallIcon';
import { AppLink } from 'components/common/Link/Link';
import React from 'react';
import { theme } from 'styles/theme';
import {
  DisplayHandler,
  IConfirmationItineraryStatements,
  ItineraryConfirmation,
} from './ConfirmItinerary';

const ConfirmItineraryStatements = React.forwardRef<
  DisplayHandler,
  IConfirmationItineraryStatements
>((props, ref) => {
  const { setActionButtonDisabled, isDirty } = props;
  const [selected, setSelected] = React.useState<string>('');
  const [checked, setChecked] = React.useState(false);

  const handleChangeCheckbox = e => {
    setChecked(e.target.checked);
  };

  React.useImperativeHandle(ref, () => ({
    changeRadioSelection: (desc: string) => {
      setSelected(desc);
    },
  }));

  React.useEffect(() => {
    if (selected === ItineraryConfirmation.REMOVE_FROM_STRIKE) {
      if (checked) {
        setActionButtonDisabled(false);
      } else {
        setActionButtonDisabled(true);
      }
    } else {
      setActionButtonDisabled(!isDirty);
    }
    return () => {
      setActionButtonDisabled(false);
    };
  }, [checked, isDirty, selected, setActionButtonDisabled]);

  return (
    <>
      {selected === ItineraryConfirmation.REJECT && (
        <Grid width="100%">
          <Divider
            className="top-divider"
            sx={{ margin: '13px 0px 30px 0px' }}
          />
          <Grid container item gap={2}>
            <Grid item>
              <Typography
                color={theme.palette.system.grayText}
                variant="body1"
                lineHeight="20px"
              >
                Please reach out to Strike Support to make changes to your
                travel details.
              </Typography>
            </Grid>

            <Grid
              item
              display={'flex'}
              flexDirection={'row'}
              sx={{
                wordBreak: 'break-word',
              }}
            >
              <CallIcon
                sx={{
                  color: theme.palette.system.skyBlue,
                  width: '18px',
                  height: '18px',
                  mr: 1,
                }}
              />
              <AppLink component={'a'} href={`tel:${18004588973}`}>
                (800) 458-8973
              </AppLink>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selected === ItineraryConfirmation.CHANGE_TRAVEL_MODE && (
        <Grid width="100%">
          <Divider
            className="top-divider"
            sx={{ margin: '13px 0px 30px 0px' }}
          />
          <Grid container item gap={2}>
            <Grid item>
              <Typography
                color={theme.palette.system.grayText}
                variant="body1"
                lineHeight="20px"
              >
                To change mode of travel (fly or drive), continue to next page
                to update travel information.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selected === ItineraryConfirmation.REMOVE_FROM_STRIKE && (
        <Grid width="100%">
          <Divider
            className="top-divider"
            sx={{ margin: '32px 0px 24px 0px' }}
          />
          <Grid>
            <FormControlLabel
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                color: theme.palette.system.coolGray,
                lineHeight: '20px',
                height: '50px',
              }}
              id="my-checkbox-id"
              disableTypography={true}
              control={
                <Checkbox
                  color="secondary"
                  checked={checked}
                  onChange={handleChangeCheckbox}
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  fontSize="14px"
                  alignSelf="flex-end"
                >
                  {
                    'By confirming, I understand I will be removed from the Strike Assignment.'
                  }
                </Typography>
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default ConfirmItineraryStatements;
