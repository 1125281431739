import React from 'react';

export default function ProfileIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls_icon_Profile"
      data-name="Controls/icon/Profile"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <g id="Group_2861" data-name="Group 2861" transform="translate(-11 -11)">
        <rect
          id="Rectangle_2264"
          data-name="Rectangle 2264"
          width="38"
          height="38"
          transform="translate(11 11)"
          fill="none"
          opacity="0"
        />
        <g
          id="Group_2860"
          data-name="Group 2860"
          transform="translate(-0.332 -0.332)"
        >
          <path
            id="Path_3727"
            data-name="Path 3727"
            d="M30.332,12.332a18,18,0,1,0,18,18A18.02,18.02,0,0,0,30.332,12.332ZM42.032,40.4c-1.706-4.482-8.106-6.474-8.106-6.474a9.307,9.307,0,0,1-3.594.936,9.308,9.308,0,0,1-3.6-.936s-6.4,1.993-8.106,6.473a15.453,15.453,0,1,1,23.4,0Z"
            fill={color}
          />
          <ellipse
            id="Ellipse_600"
            data-name="Ellipse 600"
            cx="6.784"
            cy="7.757"
            rx="6.784"
            ry="7.757"
            transform="translate(23.548 18.026)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
