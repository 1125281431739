import { Link, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IErrorDialogProps } from 'interfaces/Props/IErrorDialogProps';
import React from 'react';
import { theme } from 'styles/theme';

export default function ErrorDialog({
  open,
  handleClose,
  title,
  children,
  dismissText,
  id,
  paperStyle,
  confirmText,
  handleConfirm,
}: IErrorDialogProps) {
  return (
    <Dialog
      open={open}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      sx={{
        lineHeight: '20px',
        borderRadius: { paper: '4px' },
      }}
      PaperProps={{ style: { ...paperStyle } }}
    >
      <DialogTitle
        id="error-dialog-title"
        sx={{ padding: '24px 24px 12px 24px' }}
      >
        <Typography
          component="span"
          variant="h3"
          color={theme.palette.system.error}
          fontWeight={600}
          lineHeight="24px"
          letterSpacing="0.18px"
          data-testid="dialog-title"
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '0 24px',
          color: `${theme.palette.system.dialogTextGray}`,
          fontSize: '14px',
          fontWeight: 'regular',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          overflow: 'hidden',
        }}
        data-testid="dialog-content"
      >
        {children}
      </DialogContent>
      <DialogActions sx={{ padding: '20px 24px' }}>
        <Link
          onClick={handleClose}
          id={`${id}_dismiss`}
          sx={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="body1"
            fontWeight={600}
            color={theme.palette.system.skyBlue}
            lineHeight="16px"
            letterSpacing="1.25px"
            textTransform="uppercase"
          >
            {dismissText}
          </Typography>
        </Link>
        {confirmText && (
          <Link
            onClick={handleConfirm}
            id={`${id}_confirm`}
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="body1"
              fontWeight={600}
              color={theme.palette.system.skyBlue}
              lineHeight="16px"
              letterSpacing="1.25px"
              textTransform="uppercase"
              sx={{ marginLeft: '12px' }}
            >
              {confirmText}
            </Typography>
          </Link>
        )}
      </DialogActions>
    </Dialog>
  );
}
