import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobSearchLimit } from 'enums/jobSearchLimit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import { IJobRequest } from 'interfaces/Jobs/IJobsRequest';
import { IJobState } from './jobsState';

const initialState: IJobState = {
  items: [],
  paging: {
    offset: '',
    limit: '',
    total: '',
  },
  jobSearchFilter: {
    limit: JobSearchLimit.WEB,
    sort: 4,
    maxRadius: 50,
  },
  selectedJobId: undefined,
  hasRecommended: false,
  hasOnlyRecommended: false,
};

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    resetJobs(state) {
      let currentFilter = state.jobSearchFilter;
      let hasRecommended = state.hasRecommended;
      let hasOnlyRecommended = state.hasOnlyRecommended;
      return {
        ...initialState,
        hasRecommended: hasRecommended,
        hasOnlyRecommended: hasOnlyRecommended,
        jobSearchFilter: currentFilter,
      };
    },
    fetchJobs(
      state,
      action: PayloadAction<
        { request: IJobRequest; resetSelected?: boolean } | undefined
      >,
    ) {},
    setJobSearchFilter(state, action: PayloadAction<IJobSearchRequestSlice>) {
      state.jobSearchFilter = action.payload;
    },
    setJobs(state, action: PayloadAction<IJobState>) {
      if (action.payload?.paging?.offset && action.payload?.items) {
        state.items = [...state.items, ...action.payload.items];
      } else {
        state.items = action.payload?.items;
      }
      state.paging = action.payload?.paging;
      state.hasOnlyRecommended = action.payload?.hasOnlyRecommended
        ? action.payload?.hasOnlyRecommended
        : state.hasOnlyRecommended;
      state.hasRecommended = action.payload?.hasRecommended
        ? action.payload?.hasRecommended
        : state.hasRecommended;
    },
    postFavorite(state, action) {},
    deleteFavorite(state, action) {},
    setPostFavorite(state, action: PayloadAction<number>) {
      const jobId = action.payload;
      const jobs = state.items;

      var updatedJobs = jobs.map(item => {
        if (item.jobId === jobId) {
          return { ...item, isFavorite: true };
        }
        return { ...item };
      });
      state.items = updatedJobs;
    },
    setDeleteFavorite(state, action: PayloadAction<number>) {
      const jobId = action.payload;
      const jobs = state.items;
      var updatedJobs = jobs.map(item => {
        if (item.jobId === jobId) {
          return { ...item, isFavorite: false };
        }
        return { ...item };
      });
      state.items = updatedJobs;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
    setHasRecommended(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasRecommended: action.payload,
      };
    },
    setHasOnlyRecommended(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasOnlyRecommended: action.payload,
      };
    },
    setSelectedJob(state, action: PayloadAction<number | undefined>) {
      return {
        ...state,
        selectedJobId: action.payload,
      };
    },
    fetchStrikeJobs(state, action: PayloadAction<string | undefined>) {},
  },
});

export const {
  resetJobs,
  fetchJobs,
  setJobs,
  postFavorite,
  deleteFavorite,
  setPostFavorite,
  setDeleteFavorite,
  setJobSearchFilter,
  setError,
  setHasRecommended,
  setHasOnlyRecommended,
  setSelectedJob,
  fetchStrikeJobs,
} = jobSlice.actions;
export default jobSlice.reducer;
