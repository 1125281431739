const roundToNearestNumber = (value: number) => Math.round(value);

const roundToNearestUpperNumber = (value: number) => Math.round(value);

const roundToNearestLowerNumber = (value: number) => Math.round(value);

export {
  roundToNearestNumber,
  roundToNearestUpperNumber,
  roundToNearestLowerNumber,
};
