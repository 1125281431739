import { IContactRatingDetails } from 'interfaces/Ratings/IContactRatingDetails';
import { IContactRatingSaveRequest } from 'interfaces/Ratings/IContactRatingSaveRequest';
import { http } from 'services/BaseApi';

export const fetchContactRatings = async (
  userId: string,
  employeeId: string,
): Promise<IContactRatingDetails> => {
  return await http.get<string>(
    `ratings/contact/${userId}?employeeId=${employeeId}`,
  );
};

export const postContactRatings = async (
  userId: string,
  request: IContactRatingSaveRequest,
): Promise<boolean> => {
  return await http.post<IContactRatingSaveRequest>(
    `ratings/contact/${userId}`,
    request,
  );
};
