import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import logo from 'assets/images/Controls-Logo.svg';
import domPurify from 'dompurify';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/configureStore';
import { fetchTerms } from 'store/slices/termsAndConditions/termsSlice';
import { theme } from 'styles/theme';

const useStyles = makeStyles({
  box: {
    boxShadow: `0px 1px 15px ${theme.palette.system.darkShadowGray}`,
    borderRadius: '10px',
    width: '670px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '0px',
      margin: '0px',
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '54px',
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      marginTop: '66px',
      padding: 0,
      '& h2': {
        fontSize: '14px',
      },
    },
  },

  termsText: {
    '& p': {
      color: theme.palette.system.coolGray,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '12px',
      padding: '22px',
      paddingBottom: '6px',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.system.white,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.system.gray,
  },

  logo: {
    width: 'clamp(169px, 40vw ,242px)',
  },
});

export const FooterTermsOfService = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTerms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sanitizedHTML = useSelector((state: RootState) => {
    const terms = state.terms.items[0].terms;
    const htmlTerms = domPurify.sanitize(terms);
    return { __html: htmlTerms.toString() };
  });

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <div>
        <Box>
          <AppBar position="fixed" className={classes.header} elevation={0}>
            <Toolbar data-testid="tool-bar">
              <Grid container>
                <Grid item xs={4}>
                  <Box>
                    <img src={logo} alt="amn-logo" className={classes.logo} />
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <Container className={classes.container}>
        <Box
          mt={4}
          mb={10}
          sx={{ backgroundColor: 'system.white' }}
          className={classes.box}
        >
          <Box mt={4} textAlign="center">
            <Typography color="system.midnightBlue" sx={theme.typography.h2}>
              Terms of Service
            </Typography>
          </Box>

          <Box p={8} pt={2} pb={1} className={classes.termsText}>
            <Typography
              sx={{
                h2: {
                  fontSize: '14px',
                },
              }}
              data-testid="terms-text"
              dangerouslySetInnerHTML={sanitizedHTML}
            ></Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};
