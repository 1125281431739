import React from 'react';
import { theme } from 'styles/theme';

export default function ScheduleShiftIcon({
  color = theme?.palette?.system?.error,
  height = '24.001',
  width = '24',
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <g data-name="Group 13379" transform="translate(.5 -.501)">
        <g fill={color} transform="translate(1 2.001)">
          <path
            d="M25.663 15.875h-9.7a1.06 1.06 0 00-1.079 1.039v8.594a1.06 1.06 0 001.079 1.039h9.7a1.06 1.06 0 001.079-1.039v-8.594a1.06 1.06 0 00-1.079-1.039zm0 9.727h-9.7a.1.1 0 01-.1-.094V18.3h9.9v7.211a.1.1 0 01-.1.089z"
            data-name="Path 3745"
            transform="translate(-14.885 -15.875)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2283"
            transform="translate(4.472 3.44)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2284"
            transform="translate(6.414 3.44)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2285"
            transform="translate(8.356 3.44)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2286"
            transform="translate(2.53 5.311)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2287"
            transform="translate(4.472 5.311)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2288"
            transform="translate(6.414 5.311)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2289"
            transform="translate(8.356 5.311)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2290"
            transform="translate(2.53 7.181)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2291"
            transform="translate(4.472 7.181)"
          ></path>
          <path
            d="M0 0H0.971V0.935H0z"
            data-name="Rectangle 2292"
            transform="translate(6.414 7.181)"
          ></path>
        </g>
        <g transform="translate(6.335 7.336)">
          <circle
            cx="5"
            cy="5"
            r="5"
            fill={theme.palette.system.white}
            data-name="Ellipse 1611"
            transform="translate(-.335 -.336)"
          ></circle>
          <g fill={color} data-name="Group 2897">
            <path
              d="M19.353 14.517A4.743 4.743 0 1024.1 19.26a4.748 4.748 0 00-4.747-4.743zm2.962 7.378l-.263-.263a.232.232 0 00-.327.327l.263.263a3.95 3.95 0 01-2.4 1v-.373a.232.232 0 00-.463 0v.373a3.949 3.949 0 01-2.4-1l.263-.263a.232.232 0 00-.327-.327l-.263.263a3.951 3.951 0 01-1-2.4h.373a.232.232 0 100-.463h-.373a3.951 3.951 0 011-2.4l.263.263a.231.231 0 10.327-.327l-.263-.263a3.949 3.949 0 012.4-1v.373a.232.232 0 00.463 0V15.3a3.95 3.95 0 012.4 1l-.263.263a.231.231 0 10.327.327l.263-.263a3.947 3.947 0 011 2.4h-.373a.232.232 0 000 .463h.373a3.948 3.948 0 01-1 2.405z"
              data-name="Path 3772"
              transform="translate(-14.61 -14.517)"
            ></path>
            <path
              d="M28.442 25.586H26l-1.517-1.521a.232.232 0 00-.328.327l1.585 1.589h.006a.225.225 0 00.069.046.234.234 0 00.089.018h2.536a.232.232 0 100-.463z"
              data-name="Path 3773"
              transform="translate(-21.164 -21.071)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
