import { makeStyles } from '@mui/styles';
import { theme } from 'styles/theme';

const phoneNumberStyles = makeStyles(() => ({
  error: {
    maxHeight: '56px',
    borderBottomColor: `${theme.palette.system.error}`,
    borderBottomWidth: '2px',
  },
  errorLabel: {
    '& .MuiFormHelperText-root.Mui-error ': {
      color: `${theme.palette.system.error}`,
      marginLeft: '-70px',
      marginTop: '0px',
    },
  },
}));
export { phoneNumberStyles };
