import { createAction, createSlice } from '@reduxjs/toolkit';
import { IBannersState } from 'interfaces/Banners/IBanners';

const initialState: IBannersState = {
  banners: [],
};
const getBanners = createAction('GET_BANNERS');

const bannersSlice = createSlice({
  name: 'bannersSlice',
  initialState,
  reducers: {
    setBanners(state, action) {
      state.banners = action.payload;
    },
    resetBanners(state) {
      state.banners = [];
    },
  },
});

export const bannersActions = {
  getBanners,
  ...bannersSlice.actions,
};

export default bannersSlice.reducer;
