import { Box, useMediaQuery } from '@mui/material';
import { GeneralErrorBoundary } from 'components/ErrorBoundaries/GeneralErrorBoundary';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import SiteFooter from 'components/SiteFooter/SiteFooter';
import { SiteHeader } from 'components/SiteHeader/SiteHeader';
import { FeatureToggles } from 'enums/featureToggles';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authenticationSelector } from 'store/selectors/authSelector';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import { mobileTabletWidth } from 'styles/media';
import { createLayoutStyles } from './Layout.styles';

export const Layout: FC = ({ children }) => {
  const classes = createLayoutStyles();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const matchesMaxWidth1024 = useMediaQuery(mobileTabletWidth);
  const isACSJobSearch = useFeatureToggle(FeatureToggles.ENABLE_ACS_JOB_SEARCH);

  const needsNewAccessToken = useAppSelector(state => {
    return state.auth.accessExpired;
  });
  const refreshToken = useAppSelector(state => {
    return state.auth.loginResponse.refreshToken;
  });
  const isAuthenticated: boolean = useAppSelector(authenticationSelector);
  const isMenuOpen: boolean = useAppSelector(state => state.nav.isMenuOpen);

  const [mobileContentAvailable, setContentAvailable] = useState<number>(0);

  const scrollRef: any = useRef(null);

  useEffect(() => {
    dispatch(
      authenticationActions.refreshAccessTokenAction({
        refreshToken,
        correlationId: '',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(authenticationActions.getCookie());
    if (needsNewAccessToken) {
      dispatch(
        authenticationActions.refreshAccessTokenAction({
          refreshToken,
          correlationId: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsNewAccessToken]);

  const adjustFooterHeight = useCallback(() => {
    if (matchesMaxWidth1024) {
      const footer = window.document.getElementById('mobile-footer');

      if (footer && footer.getBoundingClientRect) {
        const { height: footerHeight } = footer.getBoundingClientRect();

        setContentAvailable(footerHeight);
      }
    }
  }, [matchesMaxWidth1024]);

  useEffect(() => {
    adjustFooterHeight();
    /*
     * Want to run this on mount, not on changes
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = () => {
      setContentAvailable(0);
      adjustFooterHeight();
    };
    let timeout = setTimeout(
      () => window.addEventListener('resize', handler),
      100,
    );

    return () => {
      window.removeEventListener('resize', handler);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //=============== scroll to top =================//
  useEffect(() => {
    /**
     * We need the ability to scroll to a specific anchor (eg. see Impact Tracker)
     */

    // adding a check here for the jobs page to override maintaining scroll position
    if (
      location.hash === '' &&
      location.pathname !== `/${AppRouteNames.JOB_SEARCH}`
    ) {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth', top: 0 });
    }
  }, [location.pathname, location.hash]);
  //===============================================//

  const isCognitiveJobSearch = useMemo(() => {
    return (
      location.pathname.match(new RegExp(`/${AppRouteNames.JOB_SEARCH}/?$`)) &&
      isACSJobSearch
    );
  }, [location.pathname, isACSJobSearch]);

  return (
    <>
      {isAuthenticated ? (
        <div
          ref={scrollRef}
          style={{ height: '100%' }}
          id="scroll-ref-container"
        >
          <SiteHeader />
          {isMenuOpen === false && (
            <Box
              id="pageContentId"
              className={classes.customScrollbar}
              sx={{
                maxHeight: `calc(100% - 66px - ${mobileContentAvailable}px)`,
              }}
            >
              <GeneralErrorBoundary>
                <div className={classes.pageContent} id="page-content-children">
                  {children}
                </div>
              </GeneralErrorBoundary>
              {!isCognitiveJobSearch && <SiteFooter />}
            </Box>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
