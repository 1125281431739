import React from 'react';
import { createSvgIcon } from '@mui/material';

export const HotSpotIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g id="Hotspot" transform="translate(-342 -67)">
      <circle
        id="Ellipse_611"
        data-name="Ellipse 611"
        cx="5"
        cy="5"
        r="5"
        transform="translate(345 70)"
        fill="#eb4545"
        opacity="0.797"
      />
      <circle
        id="Ellipse_612"
        data-name="Ellipse 612"
        cx="8"
        cy="8"
        r="8"
        transform="translate(342 67)"
        fill="#eb4545"
        opacity="0.496"
      />
    </g>
  </svg>,
  'HotSpotIcon',
);
