import React, { useCallback, useEffect } from 'react';
import { HotSpotIcon } from 'assets/icons/HotSpot/HotSpotIcon';
import { ToolTipContainer } from './Styled/ToolTipContainer.styled';
import { ToolTipContent } from './Styled/ToolTipContent.styled';
import Box from '@mui/material/Box';
import CloseIcon from 'assets/icons/Close/Close';
import tippy, { Placement, sticky } from 'tippy.js';
import ReactDomServer from 'react-dom/server';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';
import 'styles/tool-tip-tour-theme.css';
import { ToolTipContentText } from './Styled/ToolTipContentText.styled';
import { ToolTipCloseButton } from './Styled/ToolTipCloseButton.styled';
import { SxProps } from '@mui/material';

export const TippyToolTip = ({
  id = 'tippyToolTip',
  isOpen = true,
  message = 'Tippy Tool Tip',
  selector,
  showCloseButton = true,
  showHotSpot = true,
  placement = 'bottom',
  onClose,
  sx,
  wrapperTop,
  debug = false,
}: {
  id?: string;
  isOpen?: boolean;
  message?: string;
  selector: string;
  placement?: string;
  showCloseButton?: boolean;
  showHotSpot?: boolean;
  onClose?: () => void;
  sx?: SxProps;
  wrapperTop?: string;
  debug?: boolean;
}) => {
  const createToolTip = useCallback(
    targets => {
      const content = () => {
        return (
          <>
            {isOpen && (
              <ToolTipContainer
                className={`red-dot-container`}
                sx={{ height: '43px', ...sx }}
              >
                {showHotSpot && (
                  <HotSpotIcon
                    className="hot-spot"
                    sx={{ width: '16px', height: '16px' }}
                    data-testid="hotspot-icon"
                  />
                )}
                <ToolTipContent>
                  <ToolTipContentText data-testid="tooltip-message">
                    {message ?? ''}
                  </ToolTipContentText>
                </ToolTipContent>
                {showCloseButton && (
                  <Box>
                    <ToolTipCloseButton
                      id="cancel-btn"
                      className="cancel-btn"
                      style={{ padding: '0px', width: '15px' }}
                      data-testid="close-button"
                      type="button"
                    >
                      <CloseIcon width="12px" height="12px" />
                    </ToolTipCloseButton>
                  </Box>
                )}
              </ToolTipContainer>
            )}
          </>
        );
      };

      return tippy(targets, {
        sticky: true,
        plugins: [sticky],
        allowHTML: true,
        placement: placement as Placement,
        arrow: true,
        theme: 'tool-tip-tour-theme',
        content: `${ReactDomServer.renderToString(content())}`,
        trigger: 'manual',
        interactive: true,
        hideOnClick: false,

        onShown(instance) {
          const cancelButton: any =
            instance?.popper?.querySelector('#cancel-btn');

          if (cancelButton) {
            cancelButton?.addEventListener('click', () => {
              instance.hide();

              if (onClose) {
                onClose();
              }
            });
          }

          const tippyBox: any =
            instance.popper.getElementsByClassName('tippy-box');
          if (tippyBox && tippyBox.length > 0 && tippyBox[0]) {
            tippyBox[0]!.style!.top = wrapperTop ?? '0px';
          }
        },
        onCreate(instance) {
          const cancelButton: any =
            instance?.popper?.querySelector('#cancel-btn');

          if (cancelButton) {
            cancelButton?.addEventListener('click', () => {
              instance.hide();
              if (onClose) {
                onClose();
              }
            });
          }
        },
      });
    },
    [onClose, placement],
  );

  useEffect(() => {
    const target = document.querySelector(selector);
    if (debug) console.log(`${id}-target`, target);
    if (!target) return;

    const tip = createToolTip(selector);

    if (tip && tip.length > 0) {
      tip[0].show();
    }

    return () => {
      tip[0].destroy();
    };
  }, [selector, id, debug, createToolTip]);

  return <></>;
};
