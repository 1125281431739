import { Grid, Typography } from '@mui/material';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { Shifts } from 'enums/shiftId';
import _ from 'lodash';
import React from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setShiftTimes } from 'store/slices/user/userPreferenceSlice';
import {
  areAllOptionsChecked,
  areAllOptionsUnchecked,
  handleAllChecked,
  handleAnyChecked,
} from '../utils';

const ShiftTimeForm = ({ setFormDirty }: { setFormDirty?: Function }) => {
  const dispatch = useAppDispatch();

  const shiftTimes = useAppSelector(state => state.userPreference.shiftTimes);

  //===== Here we modify shiftTimes array to change verbiage Mid to Evening =====//
  const shiftTimesModified = shiftTimes?.map(obj =>
    obj.typeId === Shifts.MID ? { ...obj, type: 'Evening' } : obj,
  );
  //=============================================================================//

  const handleShiftTimeChange = (e, index) => {
    setFormDirty?.(true);
    const anyIdx = shiftTimes.findIndex(option => option.typeId === 0);
    let tempShiftTimes = _.cloneDeep(shiftTimes);

    if (tempShiftTimes[anyIdx].isActive && index === anyIdx) return;

    tempShiftTimes[index].isActive = !tempShiftTimes[index].isActive;

    if (tempShiftTimes[index].typeId === 0 && tempShiftTimes[index].isActive) {
      tempShiftTimes = handleAnyChecked(tempShiftTimes);
    } else {
      tempShiftTimes[0].isActive = false;
    }

    if (areAllOptionsChecked(tempShiftTimes)) {
      tempShiftTimes = handleAllChecked(tempShiftTimes);
    } else if (
      areAllOptionsUnchecked(tempShiftTimes) &&
      !tempShiftTimes[0].isActive
    ) {
      return;
    }

    dispatch(setShiftTimes(tempShiftTimes));
  };

  return (
    <Grid
      container
      item
      id="shift-time-form"
      display="flex"
      flexDirection="column"
    >
      <Grid item>
        <Typography
          variant="subtitle1"
          color="system.midnightBlue"
          lineHeight="22px"
          textAlign={'left'}
        >
          Shift Time
        </Typography>
      </Grid>
      <Grid item display="flex" flexDirection="column">
        {shiftTimesModified?.map((shiftTime, index) => (
          <CheckBox
            key={index}
            id={`shiftTime_type_${index}`}
            checked={shiftTime.isActive}
            onChange={e => handleShiftTimeChange(e, index)}
            checklabel={shiftTime.type}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ShiftTimeForm;
