import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApplyJobState } from './applyJobsState';

const initialState: IApplyJobState = {
  isLoading: false,
  applyJob: {
    jobId: '',
    matchRanking: 0,
    recruiters: [],
    searchSortTypeId: 0,
    userId: '',
  },
  hasApplied: false,
  externalLinkDrawerForStrikeJobApply: false,
  strikeJobSignUpUrl: '',
};
const applyJobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    applyJob(state, action: PayloadAction<IApplyJobState>) {
      state.isLoading = true;
    },
    setApplyJob(state, action: PayloadAction<IApplyJobState>) {
      state.applyJob = action.payload.applyJob;
      state.hasApplied = action.payload.hasApplied;
      state.externalLinkDrawerForStrikeJobApply =
        action.payload.externalLinkDrawerForStrikeJobApply;
      state.strikeJobSignUpUrl = action.payload.strikeJobSignUpUrl;
      state.isLoading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setApplyJobExternalLinkDrawerClose(state) {
      state.externalLinkDrawerForStrikeJobApply = false;
    },
    setHasApplied(state, action: PayloadAction<boolean>) {
      state.hasApplied = action.payload;
    },
  },
});
export const {
  applyJob,
  setApplyJob,
  setLoading,
  setApplyJobExternalLinkDrawerClose,
  setHasApplied,
} = applyJobsSlice.actions;
export default applyJobsSlice.reducer;
