import React from 'react';
import { theme } from 'styles/theme';
import { IBadge } from 'interfaces/Jobs/IBadge';
import { Typography, Box } from '@mui/material';

export const CompactStateBadge: React.FC<IBadge> = badgeDetail => {
  return (
    <>
      {!badgeDetail.name && <span></span>}
      {badgeDetail.name && (
        <Box
          id="compact-state-badge"
          sx={{
            backgroundColor: badgeDetail.color,
            borderRadius: '50%',
            display: 'inline-block',
            padding: '0px 6px 1px 4.5px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              fontWeight={600}
              color={theme.palette.system.white}
            >
              {badgeDetail.name}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
