import React, { useCallback } from 'react';
import { useSmMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { IToolTipsTourState } from 'interfaces/ToolTipsTour/IToolTipsTourState';
import { useAppSelector } from 'store/hooks';
import { toolTipsTourActions } from 'store/slices/toolTipsTour/toolTipsTourSlice';
import { useAppDispatch } from '../../../store/configureStore';

export const ToolTipsTour = ({
  id,
  children,
  index,
  isMobile = false,
}: {
  id: string;
  children: React.ReactElement;
  index: number;
  isMobile?: boolean;
}) => {
  const toolTipsTourData: IToolTipsTourState = useAppSelector(
    state => state?.toolTipsTour,
  );

  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );

  // We need to handle mobile and tablet view
  const isSmallDevice = useSmMediaQuery();
  const dispatch = useAppDispatch();

  const handleOnClose = useCallback(() => {
    dispatch(toolTipsTourActions.hideToolTip());
  }, [dispatch]);

  if (userStatusIsLead) {
    return <></>;
  }

  if (
    toolTipsTourData?.toolTipsTourDetail.showToolTip &&
    toolTipsTourData?.toolTipsTourDetail.currentStateIndex === index &&
    isMobile === isSmallDevice
  ) {
    return (
      <>
        {React.cloneElement(children, {
          onClose: handleOnClose,
        })}
      </>
    );
  }

  return <></>;
};
