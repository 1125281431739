export enum userCredentials {
  CERTIFICATIONS = 1,
  LICENSES = 6,
  ClinicalQualifications = 2,
  EducationOnlineExams = 3,
  EmployeeHealth = 4,
  HumanResources = 5,
  Submissions = 9998,
  VaccinationRecord = 9999,
  OpenRequirements,
  OpenTasks,
  MyDocuments = 8,
}

export enum CredentialChecklistCategory {
  Default,
  Client_Specific_Documents,
  Clinical_Qualifications,
  Credentials,
  Employee_Health,
  Human_Resources,
}

export enum BadgeStatus {
  ToDo = 'TO DO',
  Pending = 'PENDING',
  Expiring = 'EXPIRING',
  PastDue = 'PAST DUE',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
  Approved = 'APPROVED',
  PendingApproval = 'PENDING APPROVAL',
  Compliant = 'COMPLIANT',
  Duplicate = 'DUPLICATE',
  Accepted = 'ACCEPTED',
  Processed = 'PROCESSED',
}

export enum CredentialOpenRequirementsStatus {
  Open = 100,
  Pending = 200,
  Accepted = 250,
  Rejected = 300,
  Added = 400,
  Compliant = 500,
}

export enum CredentialRejectedReason {
  None,
  Duplicate_Received,
  Incomplete_Personal_Info,
  Incomplete_HP_Name,
  Incomplete_Required,
  Ineligible_Expired,
  Ineligible_Image,
  Ineligible_Name,
  Bad_Image = 100,
  Expired_Doc = 200,
  Not_Compliant = 300,
}
export enum CredentialSubmissionStatus {
  Pending = 100,
  Rejected = 200,
  Processed = 300,
  Verified = 500,
}

export enum COVIDDrawer {
  VaccineCard = 'COVIDVaccineFile',
  QRCode = 'COVIDQRFile',
}

export enum OpenRequirementCredentialType {
  Document_Uploads = 1,
  License_Verification = 2,
  Forms,
  Skills_Checklists,
  Links,
  Education_Exams,
  Read_Only_PDFs,
}

export enum OpenRequirementCredentialCategoryType {
  License_Verification = 'Licenses',
  Certifications = 'Certifications',
  Education_Exams = 'Education & Exams',
  Human_Resources = 'Human Resources',
  Employee_Health = 'Employee Health',
  Clinical_Qualification = 'Clinical Qualifications',
  Miscellaneous = 'Miscellaneous',
}

export enum OpenRequirementCredentialCategoryTypeId {
  License_Verification,
  Certifications,
  Education_Exams,
  Human_Resources,
  Employee_Health,
  Clinical_Qualification,
  Miscellaneous,
}

export enum TaskCenterCategoryTypeId {
  Profile,
  Credentials,
  Contract,
  TimeAndPay,
  Docusign,
  Strike,
  General,
}

export enum TaskCenterCategoryType {
  Profile = 'Profile',
  Credentials = 'Credentials',
  Contract = 'Contract',
  TimeAndPay = 'Time & Pay',
  Docusign = 'Docusign',
  Strike = 'Strike',
  General = 'General',
  None = 'None',
}

export enum CredentialSubType {
  Action_Item = 'actionItem',
  Skills_Checklist = 'skillsChecklist',
  Assignment_Credential = 'assignmentCredential',
  User_Credential = 'userCredential',
  Contract = 'contract',
}

export enum DocumentType {
  Identity,
  DriverLicense,
  Passport,
}

export enum SubmissionType {
  Wallet = 'Wallet',
  Checklist = 'Checklist',
}

export enum DisciplineId {
  RN = 1,
  LPN = 2,
}

export enum Country {
  CAN,
  USA,
}
