import { IActionItemMenuOptions } from 'enums/actionItemMenuOptions';
import { actionItemTypeId, actionType } from 'enums/actionType';
import { LinkType } from 'enums/linkConfig';
import { sortByDateDescending } from 'helpers/dateFormatter';
import { IActionItem } from 'interfaces/ActionItem/IActionItem';
import { IActionItems } from 'interfaces/ActionItem/IActionItems';
import {
  IActionItemProps,
  IClearanceMessage,
} from 'interfaces/Props/IActionItemProps';

export const actionDialogContent = new Map([
  [
    actionItemTypeId.Covid19VaccinationCard,
    'Upload your COVID-19 Vaccination Card or complete the COVID-19 Vaccination Declination.',
  ],
  [
    actionItemTypeId.StrikeUploadInfluenzaVaccination,
    'Upload your Influenza vaccination record or complete the Influenza Declination Form.',
  ],
  [
    actionItemTypeId.StrikeUploadTuberculosisTest,
    'Upload your Tuberculosis test or complete the Tuberculosis Screening Questionnaire.',
  ],
  [
    actionItemTypeId.StrikeUploadTdapVaccination,
    'Upload your Tetanus, Diphtheria & Pertussis (TDAP) vaccination record or complete the TDAP Declination Form.',
  ],
  [
    actionItemTypeId.StrikeUploadFitTest,
    'Upload your Fit Test or complete the Fit Test Questionnaire Form.',
  ],
]);

export const getLinkTypeId = (actionItem?: IActionItems) => {
  switch (actionItem?.actionItemTypeId) {
    case actionItemTypeId.Docusign:
    case actionItemTypeId.SkillsChecklist:
    case actionItemTypeId.Survey:
    case actionItemTypeId.VirtualInterview:
      return LinkType.Web;
    // all instances of 'Confirm Travel Details' should be handled as MobileDeepLink even in cases where linkType is Multiple
    case actionItemTypeId.ConfirmyourTravelItinerary:
    case actionItemTypeId.ArbitrationAgreement:
      return LinkType.MobileDeepLink;
    case actionItemTypeId.CovidVaccinationTrainingModules:
      return LinkType.PDF;
    case actionItemTypeId.SkillsChecklistUpdateRequest:
    case actionItemTypeId.SkillsChecklistPreQualificationUpdate:
    case actionItemTypeId.StrikeEducationUpdateRequest:
    case actionItemTypeId.EducationPreQualificationUpdate:
    case actionItemTypeId.WorkHistoryExperienceUpdateRequest:
    case actionItemTypeId.WorkHistoryExperiencePreQualificationUpdate:
    case actionItemTypeId.WorkHistoryGapPreQualificationUpdate:
    case actionItemTypeId.ReferencePreQualificationUpdate:
    case actionItemTypeId.WorkHistoryGapUpdate:
    case actionItemTypeId.ExtendedWorkHistory:
    case actionItemTypeId.MissingEducationInformation:
      return LinkType.InAppLink;
    default:
      return (
        actionItem?.actionItem?.typeId ||
        actionItem?.survey?.typeId ||
        actionItem?.contract?.typeId
      );
  }
};

export const createOptions = (type: string) => {
  switch (type) {
    case actionType.actionItem:
    case actionType.skillsChecklist:
    case actionType.survey:
    case actionType.missingInformation:
      return [
        IActionItemMenuOptions[1],
        IActionItemMenuOptions[2],
        IActionItemMenuOptions[3],
      ];
    case actionType.docusign:
      return [IActionItemMenuOptions[1], IActionItemMenuOptions[3]];
    default:
      return [];
  }
};

export const selectData = (
  type: string,
  actionItemsResponse: IActionItems[],
) => {
  let actionItem: any = actionItemsResponse
    .filter(a => {
      return a[type] !== undefined;
    })
    .map(el => el[type]);
  switch (type) {
    case actionType.actionItem:
      actionItem = sortByDateDescending(actionItem, 'createDate');
      break;
    case actionType.docusign:
      actionItem = sortByDateDescending(actionItem, 'createDate');
      break;
    case actionType.skillsChecklist:
      actionItem = sortByDateDescending(actionItem, 'createDate');
      break;
    case actionType.survey:
      actionItem = sortByDateDescending(actionItem, 'createDate');
      break;
    case actionType.missingInformation:
      actionItem = sortByDateDescending(actionItem, 'createDate');
      break;
    default:
      break;
  }
  var items: any = actionItem.map(el => {
    let passingItem: IActionItemProps = {
      id: '',
      title: '',
      options: [],
      actionItemTypeId: 0,
      createDate: undefined,
      onMenuClick: () => {},
    };
    // TODO: This code does not work (Needs rework)
    switch (type) {
      case actionType.actionItem:
        passingItem.id = el.id;
        passingItem.title = el.title;
        break;
      case actionType.docusign:
        passingItem.id = el.envelopeId;
        passingItem.title = el.subject;
        break;
      case actionType.skillsChecklist:
        passingItem.id = el.id;
        passingItem.title = el.type;
        break;
      case actionType.survey:
        passingItem.id = el.id;
        passingItem.title = el.title;
        break;
      case actionType.missingInformation:
        passingItem.id = el.id;
        passingItem.title = el.title;
        break;
    }
    passingItem.createDate = el.createDate;
    passingItem.actionItemTypeId = el.actionItemTypeId;
    passingItem.options = createOptions(type);
    if (
      el.actionItemTypeId >= actionItemTypeId.SkillsChecklistUpdateRequest &&
      el.actionItemTypeId <= actionItemTypeId.WorkHistoryGapUpdate
    ) {
      passingItem.options = createOptions(actionType.missingInformation);
    }
    return { ...passingItem };
  });
  return items;
};

export const mergeActionItems = (actionItemsResponse: IActionItems[]) => {
  let passingList: IActionItemProps[] = [];
  passingList = [
    ...passingList,
    ...selectData(actionType.actionItem, actionItemsResponse),
  ];
  passingList = [
    ...passingList,
    ...selectData(actionType.docusign, actionItemsResponse),
  ];
  passingList = [
    ...passingList,
    ...selectData(actionType.skillsChecklist, actionItemsResponse),
  ];
  passingList = [
    ...passingList,
    ...selectData(actionType.survey, actionItemsResponse),
  ];
  passingList = [
    ...passingList,
    ...selectData(actionType.missingInformation, actionItemsResponse),
  ];
  let clearanceItems = extractClearanceItems(passingList);
  let remainingItems = removeClearanceItemsFromItemsList(
    passingList,
    clearanceItems,
  );
  passingList = clearanceItems.concat(remainingItems);
  return passingList;
};

export const sortByColumn = (arr: any[], fieldName: string) => {
  const sorter = (a, b) => {
    return a[fieldName] - b[fieldName];
  };
  return arr.sort(sorter);
};

const extractClearanceItems = (
  items: IActionItemProps[],
): IActionItemProps[] => {
  return items.filter(
    e =>
      e.actionItemTypeId &&
      e.actionItemTypeId >= actionItemTypeId.SkillsChecklistUpdateRequest &&
      e.actionItemTypeId <= actionItemTypeId.WorkHistoryGapUpdate,
  );
};

const removeClearanceItemsFromItemsList = (
  actionItems: IActionItemProps[],
  clearanceItems: IActionItemProps[],
) => {
  return actionItems.filter(el => {
    return !clearanceItems.includes(el);
  });
};

export const returnClearanceItemMessageFromLink = (
  clearanceItem: IActionItem,
): IClearanceMessage => {
  let clearanceMessageData: IClearanceMessage = {
    title: '',
    description: '',
  };

  let links = clearanceItem?.links || [];
  if (links.length > 0) {
    const params = new URLSearchParams(links[0].url.split('?')[1] || '');
    let descriptionText = params.get('description') ?? '';
    let titleText = params.get('title') ?? '';
    clearanceMessageData.title = decodeURIComponent(titleText);
    if (clearanceMessageData.title.length === 0 && clearanceItem?.title) {
      clearanceMessageData.title = clearanceItem.title;
    }
    clearanceMessageData.description = decodeURIComponent(descriptionText);
    if (
      clearanceMessageData.description.length === 0 &&
      clearanceItem?.description
    ) {
      clearanceMessageData.description = clearanceItem.description;
    }
  }
  return clearanceMessageData;
};
