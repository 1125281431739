import React, { FC } from 'react';
import { ILinkProps } from '../../../interfaces/Props/ILinkProps';
import { Link } from '@mui/material';
import { theme } from 'styles/theme';

/**
 *  Common Link component
 */
export const AppLink: FC<ILinkProps> = ({
  href,
  children,
  onClick,
  target,
  underline,
  linkStyles,
  component,
  id,
  rel,
  propagateEvent = true,
}) => {
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    onClick?.();

    if (!propagateEvent) {
      event.stopPropagation();
    }
  };

  return (
    <Link
      component={component}
      onClick={handleClick}
      href={href}
      rel={rel}
      target={target}
      underline={underline ?? 'hover'}
      variant="body1"
      sx={{
        color: theme.palette.system.skyBlue,
        fontFamily: theme.typography.fontFamily,
        ...linkStyles,
      }}
      id={id}
    >
      {children}
    </Link>
  );
};
