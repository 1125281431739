import { ILinkConfig } from 'interfaces/Common/ILink';
import {
  ICredentialChecklist,
  ICredentialDocumentItem,
} from 'interfaces/Credential/ICredentialChecklist';

export class CredentialChecklist implements ICredentialChecklist {
  id?: number;
  categoryId?: number;
  category?: string;
  description?: string;
  statusId?: number;
  status?: string;
  isCompliant?: boolean;
  expirationDate?: string;
  reasonId?: number;
  reason?: string;
  instructions?: string;
  comments?: string;
  requirementTypeId?: number;
  requirementGroupNameId?: number;
  completeDate?: string;
  linkTypeId?: number;
  linkType?: string;
  links?: ILinkConfig[];
  documents?: ICredentialDocumentItem;
  facilityName?: string;
  placementId?: number;
  constructor({
    id,
    categoryId,
    category,
    description,
    statusId,
    status,
    isCompliant,
    expirationDate,
    reasonId,
    reason,
    instructions,
    comments,
    requirementTypeId,
    requirementGroupNameId,
    completeDate,
    linkTypeId,
    linkType,
    links,
    documents,
    facilityName,
    placementId,
  }: ICredentialChecklist) {
    this.id = id;
    this.categoryId = categoryId;
    this.category = category;
    this.description = description;
    this.statusId = statusId;
    this.status = status;
    this.isCompliant = isCompliant;
    this.expirationDate = expirationDate;
    this.reasonId = reasonId;
    this.reason = reason;
    this.instructions = instructions;
    this.comments = comments;
    this.requirementTypeId = requirementTypeId;
    this.requirementGroupNameId = requirementGroupNameId;
    this.completeDate = completeDate;
    this.linkTypeId = linkTypeId;
    this.linkType = linkType;
    this.links = links;
    this.documents = documents;
    this.facilityName = facilityName;
    this.placementId = placementId;
  }
}
