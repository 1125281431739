import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: ${theme.palette.system.inputBackground};
  }

  body {
    font-family: 'Open Sans', sans-serif;
  }

  #root {
   height: 100vh;
   width: 100vw;
  }

  p,
  label {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  a {
    color: ${theme.palette.system.skyBlue};
    font-weight: 600;
  }

  ::-ms-reveal {
    display: none;
  }

  // Hides number input arrows & spinners
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Smart Banner - Hide description */
  .smartbanner-description {
    display: none;
  }
`;
