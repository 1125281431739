import React, { useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SxProps } from '@mui/system';

import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { RootState } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { WebTravelerApplication } from './WebTravelerApplication';
import { trackEvent } from 'services/logging/appInsights';

export const LeadButton = ({
  sxProps: sx,
  preventDrawerToggle,
  onClick,
}: {
  sxProps?: {
    container?: SxProps;
    button?: SxProps;
  };
  preventDrawerToggle?: boolean;
  onClick?: () => void;
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const user: IUserProfile | undefined = useAppSelector((state: RootState) => {
    if (state.userProfile) {
      return state.userProfile;
    }
  });

  const toggleDrawer = useCallback(() => {
    if (!preventDrawerToggle) {
      setDrawerOpen(prevState => !prevState);
    }
    onClick?.();
    trackEvent('acct_submit_profile');
  }, [preventDrawerToggle, onClick]);

  const readyToSubmit = useMemo(() => {
    return (
      user?.travelerApplicationStatus?.personalInformation &&
      user?.travelerApplicationStatus?.education &&
      user?.travelerApplicationStatus?.certifications &&
      user?.travelerApplicationStatus?.employmentHistory
    );
  }, [user?.travelerApplicationStatus]);

  return (
    <>
      {!user?.travelerApplicationStatus?.hasSubmittedApp ? (
        <>
          <Box
            role="button"
            aria-label="drawer-action-button"
            sx={{ display: 'flex', width: 300, ...sx?.container }}
          >
            <Button
              onClick={toggleDrawer}
              variant="primary"
              sx={{
                padding: '10px 22px',
                width: '100%',
                ...sx?.button,
              }}
              data-testid={
                readyToSubmit
                  ? 'submit-profile-button'
                  : 'complete-profile-button'
              }
            >
              {readyToSubmit ? 'Submit My Profile' : 'Complete My Profile'}
            </Button>
          </Box>
          <WebTravelerApplication
            open={drawerOpen && !preventDrawerToggle}
            toggleDrawer={(e, open) => !open && toggleDrawer()}
          />
        </>
      ) : (
        <Grid container flexDirection="column" alignItems="center" gap={1}>
          <Grid item>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              data-testid="success-title"
              color="system.grayText"
              sx={{ lineHeight: '20px' }}
            >
              You Did It!
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              color="system.coolGray"
              data-testid="success-message"
              textAlign={'center'}
              sx={{ lineHeight: '22px' }}
            >
              Hold tight while we double check everything and match you with the
              perfect recruiter.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
