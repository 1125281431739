import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultStrikeRecruiter } from 'components/AssignmentDetail/AssignmentContacts/constants';
import { IContactRatingDetails } from 'interfaces/Ratings/IContactRatingDetails';
import { IContact } from 'interfaces/User/Contact/IContact';
import { IContactState } from './userContactState';

const initialState: IContactState = {
  isLoading: false,
  userContactState: {
    contactImage: {
      file: '',
      url: '',
      date: new Date(),
    },
    emailAddress: '',
    employeeId: 0,
    employeeRole: '',
    employeeRoleId: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    smsNumber: '',
    workDayId: 0,
  },
  strikeContactState: defaultStrikeRecruiter,
  userContacts: {
    userContacts: [],
    placementContacts: [],
    strikeContacts: [],
  },
  error: false,
  contactError: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  updateSuccess: false,
};

const postContactRatingsAction = createAction(
  'POST_CONTACT_RATINGS',
  function prepare(details: IContactRatingDetails) {
    return {
      payload: {
        ...details,
      },
    };
  },
);

const userContactSlice = createSlice({
  name: 'userContactSlice',
  initialState,
  reducers: {
    getContactRecruiter(state, action) {},
    setContactRecruiter(state, action: PayloadAction<IContact>) {
      state.userContactState = action.payload;
    },
    fetchUserContacts(state, action) {
      state.isLoading = true;
      state.error = false;
    },
    setUserContacts(state, action) {
      state.isLoading = false;
      state.error = false;
      state.userContacts = {
        ...action.payload,
      };
    },
    setContactsError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.userContacts = {
        ...initialState.userContacts,
      };
      state.contactError = {
        ...action.payload,
      };
    },
    unSetContactsError(state) {
      state = {
        ...initialState,
      };
    },
    updateContactRatings(state, action) {
      let userIdx = state.userContacts.userContacts.findIndex(contact => {
        return contact.employeeId === action.payload.employeeId;
      });
      let placementIdx = state.userContacts.placementContacts.findIndex(
        contact => {
          return contact.employeeId === action.payload.employeeId;
        },
      );
      let strikeIdx = state.userContacts.strikeContacts.findIndex(contact => {
        return contact.employeeId === action.payload.employeeId;
      });
      if (userIdx > -1) {
        let contact = JSON.parse(
          JSON.stringify(state.userContacts.userContacts[userIdx]),
        );
        contact.ratingsData = action.payload;
        let contacts = state.userContacts.userContacts.slice();
        contact.ratingsData = action.payload;
        contacts.splice(userIdx, 1, contact);
        return (state = {
          ...state,
          userContacts: {
            userContacts: contacts,
            placementContacts: state.userContacts.placementContacts,
            strikeContacts: state.userContacts.strikeContacts,
          },
        });
      }
      if (placementIdx > -1) {
        let contact = JSON.parse(
          JSON.stringify(state.userContacts.placementContacts[placementIdx]),
        );
        contact.ratingsData = action.payload;
        let contacts = state.userContacts.placementContacts.slice();
        contact.ratingsData = action.payload;
        contacts.splice(placementIdx, 1, contact);
        return (state = {
          ...state,
          userContacts: {
            userContacts: state.userContacts.userContacts,
            placementContacts: contacts,
            strikeContacts: state.userContacts.strikeContacts,
          },
        });
      }
      if (strikeIdx > -1) {
        let contact = JSON.parse(
          JSON.stringify(state.userContacts.strikeContacts[strikeIdx]),
        );
        contact.ratingsData = action.payload;
        let contacts = state.userContacts.strikeContacts.slice();
        contact.ratingsData = action.payload;
        contacts.splice(userIdx, 1, contact);
        return (state = {
          ...state,
          userContacts: {
            userContacts: state.userContacts.userContacts,
            placementContacts: state.userContacts.placementContacts,
            strikeContacts: contacts,
          },
        });
      }
    },
    setUpdateContactSuccess(state, action) {
      return (state = {
        ...state,
        updateSuccess: action.payload,
      });
    },
  },
});

export const contactActions = {
  ...userContactSlice.actions,
  postContactRatingsAction,
};

export default userContactSlice.reducer;
