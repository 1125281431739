import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchAssignmentRating,
  saveAssignmentRating,
} from 'services/rating/assignmentRatingService';
import { RootState } from 'store/configureStore';
import {
  postAssignmentRating,
  retrieveAssignmentRating,
  setError,
  setLoading,
  setRating,
  setOldRating,
  setRatingSuccess,
} from 'store/slices/rating/assignmentRatingSlice';

export const userId = (state: RootState) => state.auth.userId;

function* getRating(action) {
  const currentUserId = yield select(userId);

  if (action.type === 'assignmentRating/postAssignmentRating') {
    action.payload = action.payload.payload.placementId;
  }

  try {
    if (currentUserId && action?.payload) {
      const response = yield call(
        f => fetchAssignmentRating(currentUserId, action.payload),
        action.payload,
      );
      const { data } = response;

      yield put(
        setRating({
          data: {
            rating: { ...data },
          },
        }),
      );

      yield put(
        setOldRating({
          data: {
            oldRating: { ...data },
          },
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoading());
  }
}

export function* saveRating(action) {
  const currentUserId = yield select(userId);
  try {
    yield call(
      f => saveAssignmentRating(currentUserId, f.payload),
      action.payload,
    );

    yield call(getRating, action);
    yield put(setRatingSuccess());
  } catch (error: any) {
    const err = {
      status: error.status,
      ...error.data,
    } as IErrorResponse;
    yield put(setError(err));
  }
}

function* watchRetrieveRating() {
  yield takeLatest(retrieveAssignmentRating.type, getRating);
}

function* watchPostRating() {
  yield takeLatest(postAssignmentRating.type, saveRating);
}

export function* assignmentRatingSaga() {
  yield all([watchRetrieveRating(), watchPostRating()]);
}
