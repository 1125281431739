import { AlertBarType } from 'enums/alertBarTypes';
import { IContract } from 'interfaces/Contracts/Contract';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchAuthImgUrl,
  fetchContract,
  generateContractsPdf,
  signContract,
} from 'services/contracts/contractService';
import { RootState } from 'store/configureStore';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { contractSliceActions } from 'store/slices/contracts/contractSlice';
import { fetchAssignmentDetails } from '../assignments/assignmentSaga';
import { TOAST_MESSAGE } from 'constants/helperText';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { downloadBase64 } from 'utils/helper';

const contractSelector = (state: RootState) => state.contracts.currentContract;
const signatureSelector = (state: RootState) => state.contracts.signature;
const userIdSelector = (state: RootState) => state.auth.userId;
const placementIdSelector = (state: RootState): number =>
  state.assignmentSummary.placementId;
const signatureImgUrlSelector = (state: RootState): string | undefined =>
  state?.contracts?.currentContract?.agreement
    ?.authorizedSignatorySignatureImageUrl;

export function* getAuthImgUrlWatcher() {
  try {
    const imgUrl = yield select(signatureImgUrlSelector);
    if (imgUrl) {
      const authImgUrlResponse = yield call(fetchAuthImgUrl, imgUrl);
      if (authImgUrlResponse?.data?.fileUrl) {
        yield put(
          contractSliceActions.setAuthSignatureImgUrl(
            authImgUrlResponse?.data?.fileUrl,
          ),
        );
      }
    }
  } catch (err: any) {
    console.error(err);
  }
}

export function* getContractWatcher() {
  try {
    const contract: IContract = yield select(contractSelector);
    const contractUrl = contract?.contractDocument?.url;

    if (contractUrl) {
      const contractUrlResponse = yield call(fetchContract, contractUrl);
      yield put(
        contractSliceActions.setContractUrl(contractUrlResponse?.data?.fileUrl),
      );
    }
  } catch (e: any) {
    console.error({ contractSagaError: e });
    yield put(
      openAlert({
        isOpen: true,
        variant: 'error',
        duration: null,
        alertbarRefId: AlertBarType.CONTRACT_DRAWER,
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
  }
}

export function* signContractWatcher() {
  try {
    const contract: IContract = yield select(contractSelector);
    const userId: string = yield select(userIdSelector);
    const placementId: number = yield select(placementIdSelector);
    const signature: string = yield select(signatureSelector);
    const signResponse = yield call(signContract, {
      userId,
      placementId,
      contractId: contract.contractId,
      signature,
    });

    if (signResponse?.status === 200 && signResponse?.data?.isCreated) {
      yield call(fetchAssignmentDetails, {
        payload: { userId, activePlacementId: placementId },
        type: assignmentActions.getAssignmentDetailsAction.type,
      });
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ChangesSaved,
          alertbarRefId: AlertBarType.CONTRACT_DRAWER,
        }),
      );
      yield put(assignmentActions.getAssignmentSummaryAction());
    }
  } catch (e: any) {
    console.error(e);
  }
}

function* getContractDocumentSaga(action) {
  try {
    const { placementId, contractIds, facilityName } = action.payload;
    const userId = yield select(userIdSelector);
    const successfullyDownloadedContracts: string[] = [];
    yield put(contractSliceActions.setContractDownloadProgress(true));

    for (const contractId of contractIds) {
      try {
        const response = yield call(
          generateContractsPdf,
          userId,
          placementId,
          contractId,
        );

        if (response.status === 204) {
          yield put(contractSliceActions.setIsContractSaveSuccess(false));
          yield put(
            openAlert({
              variant: 'error',
              message: TOAST_MESSAGE.NoAttachment,
            }),
          );
        } else if (response.data && response.data.file) {
          const dataoptions = {
            filename: `${
              facilityName ? facilityName + '_' : ''
            }${contractId}.pdf`,
            fileType: 'pdf',
            data: response.data.file,
          };
          yield call(downloadBase64, dataoptions);
          successfullyDownloadedContracts.push(contractId);
          yield delay(1000);
        }
      } catch (error: any) {
        yield put(contractSliceActions.setIsContractSaveSuccess(false));
        const err = error?.data as IErrorResponse;
        yield put(
          openAlert({
            variant: 'error',
            message: err?.errorMessage,
          }),
        );
      }
    }

    // Check if all contracts have been successfully downloaded
    if (successfullyDownloadedContracts.length === contractIds.length) {
      yield put(contractSliceActions.setIsContractSaveSuccess(true));
    }
  } catch (error: any) {
    yield put(contractSliceActions.setIsContractSaveSuccess(false));
    const err = error?.data as IErrorResponse;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.errorMessage,
      }),
    );
  } finally {
    yield put(contractSliceActions.setContractDownloadProgress(false));
  }
}

export function* contractSaga() {
  yield all([
    takeLatest(
      contractSliceActions.getAuthorizedImgUrl.type,
      getAuthImgUrlWatcher,
    ),
    takeLatest(contractSliceActions.getContract.type, getContractWatcher),
    takeLatest(contractSliceActions.signContract.type, signContractWatcher),
    takeLatest(
      contractSliceActions.getContractDocument.type,
      getContractDocumentSaga,
    ),
  ]);
}
