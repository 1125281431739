export const getEmailRegex = () => {
  // eslint-disable-next-line no-control-regex, no-useless-escape
  return /^((([a-zA-Z]|\d|[!#\$%&'\*\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
};

export const getUrlRegex = () => {
  return /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;
};
export const getPhoneRegex = () => {
  return /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
};
export const getUSZipRegex = () => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
};
export const getCanadaZipRegex = () => {
  return /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
};

/**
 * Get Regex Pattern based on the country code.
 * return allow any for except us & canada
 * @param countryCode Country code CAN for Canada and USA for United states
 * @returns RegEx
 */
export const getValidationPatternByCountry = (countryCode: string): RegExp => {
  if (countryCode === 'CAN') {
    return getCanadaZipRegex();
  } else if (countryCode === 'USA') {
    return getUSZipRegex();
  } else {
    return /./;
  }
};
