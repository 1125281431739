import { IAcceptTerms } from 'store/slices/termsAndConditions/termsState';
import { http } from '../BaseApi';

const fetchTerms = async (): Promise<string> => {
  return await http.get('/auth/terms');
};

const acceptTerms = async (
  userTermsRequest: IAcceptTerms,
  isLocums: boolean,
): Promise<string> => {
  return isLocums === true
    ? await http.patch(
        `/userprofile/${userTermsRequest.userId}/termsAndConditions`,
        {
          hasAcceptedTermsAndConditions:
            userTermsRequest.hasAcceptedTermsAndConditions,
        },
        { headers: { 'x-userType': 'locums' } },
      )
    : await http.patch(
        `/userprofile/${userTermsRequest.userId}/termsAndConditions`,
        {
          hasAcceptedTermsAndConditions:
            userTermsRequest.hasAcceptedTermsAndConditions,
        },
      );
};

export { acceptTerms, fetchTerms };
