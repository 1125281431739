import { Grid, Typography } from '@mui/material';
import { JobSearchCard } from 'components/Jobs/JobSearch/JobSearchCard';
import { Carousel, CarouselItem } from 'components/common/Carousel/Carousel';
import { DataLayerImpressionList } from 'enums/dataLayer';
import { IJobsData } from 'interfaces/Jobs/IJobsData';
import { AMNCardContainer } from 'pages/JobSearch/JobSearchPage.styles';
import React, { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { setSelectedJob } from 'store/slices/jobs/jobsSlice';
import { theme } from 'styles/theme';
interface ISimilarJobsProps {
  similarJobs: IJobsData[];
}

const SimilarJobs: React.FC<ISimilarJobsProps> = ({ similarJobs }) => {
  const dispatch = useAppDispatch();

  const onJobCardClick = useCallback((item, index) => {
    dispatch(setSelectedJob(item?.jobId));
    dispatch(
      dataLayerActions.setJobImpressionClick({
        list: DataLayerImpressionList.JOB_SEARCH,
        position: index,
      }),
    );
  }, []);

  const similarJobCarousel = useMemo(() => {
    return similarJobs && similarJobs.length > 0 ? (
      similarJobs.length > 1 ? (
        <Carousel
          breakpoints={{
            200: {
              slidesPerView: 1,
            },
            375: {
              slidesPerView: 1.132,
            },
            400: {
              slidesPerView: 1.132,
            },
            425: {
              slidesPerView: 1.132,
            },
            504: {
              slidesPerView: 1.5,
            },
            592: {
              slidesPerView: 2,
            },
            710: {
              slidesPerView: 2.132,
            },
            768: {
              slidesPerView: 2.132,
            },
            1024: {
              slidesPerView: 3,
            },
            1920: {
              slidesPerView: 3,
            },
          }}
        >
          {similarJobs?.slice(0, 3)?.map((item: IJobsData, index) => (
            <CarouselItem key={item.jobId}>
              <JobSearchCard
                key={item.jobId}
                similarJobs={true}
                topJob={item}
                onClick={() => {
                  onJobCardClick(item, index);
                }}
              />
            </CarouselItem>
          ))}
        </Carousel>
      ) : (
        <JobSearchCard
          key={similarJobs[0].jobId}
          similarJobs={true}
          topJob={similarJobs[0]}
          onClick={() => {
            onJobCardClick(similarJobs[0], 0);
          }}
        />
      )
    ) : null;
  }, [onJobCardClick, similarJobs]);

  return (
    <Grid
      container
      item
      display="flex"
      flexDirection="column"
      id="similar-jobs-container"
    >
      <Grid item>
        <Typography color={theme.palette.system.grayText} variant="h4">
          Explore Similar Jobs
        </Typography>
      </Grid>
      <Grid item sx={{ maxWidth: '100%' }}>
        <AMNCardContainer>{similarJobCarousel}</AMNCardContainer>
      </Grid>
    </Grid>
  );
};

export default SimilarJobs;
