import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResourceCategories } from 'enums/resources';
import {
  IResourcesResponse,
  IResourceFormSubmissionsResponse,
  IResourcesState,
} from 'interfaces/Resources/IResources';

const getResourcesAction = createAction('GET_RESOURCES_DETAILS');
const getFormstackAction = createAction<string>('GET_FORMSTACK_DATA');

const initialState: IResourcesState = {
  payrollResources: [],
  benefitsResources: [],
  housingResources: [],
  employmentResources: [],
  educationResources: [],
  formsResources: {
    items: [],
    paging: { limit: '20', offset: '0', total: '0' },
  },
  formContent: '',
  paging: {
    offset: '',
    limit: '',
    total: '',
  },
  latestSubmission: {
    id: '',
    sourceTypeId: 0,
    sourceType: '',
    submitDate: '',
    formTitle: '',
    signature: '',
    requestSourceTypeId: 0,
    requestSourceType: '',
    formstackData: [],
    urlKey: '',
    url: '',
    hasFiles: false,
    hasForm: null,
  },
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    getCardFormattedResources(
      state,
      action: PayloadAction<IResourcesResponse>,
    ) {
      state.paging = action.payload?.paging;
      state.payrollResources =
        action.payload?.items.filter(
          i => i.categoryId === ResourceCategories.Payroll,
        ) || [];
      state.benefitsResources =
        action.payload?.items.filter(
          i => i.categoryId === ResourceCategories.Benefits,
        ) || [];
      state.housingResources =
        action.payload?.items.filter(
          i => i.categoryId === ResourceCategories.Housing_Travel,
        ) || [];
      state.employmentResources =
        action.payload?.items.filter(
          i => i.categoryId === ResourceCategories.Employment_Requirements,
        ) || [];
      state.educationResources =
        action.payload?.items.filter(
          i => i.categoryId === ResourceCategories.Education_Testing,
        ) || [];
    },
    setOpenFormContent(state, action) {
      state.formContent = action.payload;
    },
    resetOpenFormContent(state) {
      state.formContent = '';
    },
    getFormSubmissions(state) {},
    loadMoreFormSubmissions(state, action) {},
    setFormSubmissions(
      state,
      action: PayloadAction<IResourceFormSubmissionsResponse>,
    ) {
      state.formsResources.items = action.payload?.items;
      state.formsResources.paging = action.payload.paging;
    },
    getLatestFormSubmission(state, action) {},
    setLatestFormSubmission(state, action) {
      state.latestSubmission = {
        ...initialState.latestSubmission,
        ...action?.payload,
      };
    },
    resetLatestFormSubmission(state) {
      state.latestSubmission = initialState.latestSubmission;
    },
    resetFormSubmissions(state) {
      /*
       * Prevents slowing of application by removing this from redux
       */
      return {
        ...initialState,
      };
    },
    getFormById(state, action) {},
    downloadResourceById(state, action) {},
    getFormSubmissionById(state, action) {},
    setSelectedResourceCategory(state, action) {
      state.selectedResourceCategory = action.payload;
    },
  },
});

export const resourcesActions = {
  ...resourcesSlice.actions,
  getResourcesAction,
  getFormstackAction,
};
export default resourcesSlice.reducer;
