import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as service from 'services/faq/faqService';
import { RootState } from 'store/configureStore';
import { setError } from 'store/slices/faq/faqSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import {
  getFaqs,
  setFaqs,
  getAssignmentContacts,
  setAssignmentContacts,
} from 'store/slices/faq/faqSlice';
import { TOAST_MESSAGE } from 'constants/helperText';

export const userId = (state: RootState) => state.auth.userId;

function* getFaqSaga(action) {
  try {
    let currentUserId = yield select(userId);
    const response = yield call(service.getFaqs, currentUserId);
    yield put(setFaqs(response.data));
  } catch (error: any) {
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: error.errorMessage,
      }),
    );
  }
}

function* getAssignmentContactsSaga(action) {
  try {
    let currentUserId = yield select(userId);
    const response = yield call(
      service.getContactsOfAssignmentsAndContracts,
      currentUserId,
    );
    yield put(setAssignmentContacts(response.data));
  } catch (error: any) {
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: error.errorMessage,
      }),
    );
  }
}

function* watchActionItem() {
  yield takeEvery(getFaqs.type, getFaqSaga);
  yield takeEvery(getAssignmentContacts.type, getAssignmentContactsSaga);
}

export function* faqSaga() {
  yield all([watchActionItem()]);
}
