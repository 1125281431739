import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Grid, Typography } from '@mui/material';
import FilterIcon from 'assets/icons/Filter/FilterIcon';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import React, { FC } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useAppDispatch } from 'store/configureStore';
import { fetchJobs, setJobSearchFilter } from 'store/slices/jobs/jobsSlice';

export interface IQuickFilterProps {
  setShowJobPreferenceDrawer: Function;
  toggleDrawer: Function;
  jobSearchFilter: IJobSearchRequestSlice;
}

export const QuickJobFilters: FC<IQuickFilterProps> = ({
  setShowJobPreferenceDrawer,
  toggleDrawer,
  jobSearchFilter,
}) => {
  const dispatch = useAppDispatch();
  const { promiseInProgress: isDisciplineLoading } = usePromiseTracker({
    area: 'get-discipline-data',
    delay: 0,
  });

  const handleCancelGoBack = () => {
    dispatch(
      setJobSearchFilter({
        ...jobSearchFilter,
        limit: jobSearchFilter.limit,
        sort: jobSearchFilter.sort,
        isFavorite: false,
      }),
    );
    dispatch(fetchJobs());
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent={jobSearchFilter.isFavorite ? 'flex-start' : 'flex-end'}
        alignItems="center"
      >
        {jobSearchFilter.isFavorite && (
          <Grid
            item
            xs={10}
            sx={{ display: { xs: 'none', md: 'flex' } }}
            pl="28px"
          >
            <Button
              variant="text"
              onClick={handleCancelGoBack}
              startIcon={<ArrowBackIosNewIcon />}
              size="xsmall"
            >
              <Box>
                <Typography variant="body1">Back to Search</Typography>
              </Box>
            </Button>
          </Grid>
        )}
        {!jobSearchFilter.isFavorite && !isDisciplineLoading && (
          <Grid
            item
            xs={2}
            textAlign="right"
            onClick={e => {
              setShowJobPreferenceDrawer(true);
              toggleDrawer(e, true);
            }}
            sx={{
              cursor: 'pointer',
              paddingRight: '24px',
            }}
          >
            <FilterIcon />
          </Grid>
        )}
      </Grid>
    </>
  );
};
