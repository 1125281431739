import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DefaultDialogProps } from 'interfaces/Props/IDialogProps';
import React from 'react';
import { amnFontSize } from 'styles/fontSizes';
import { theme } from 'styles/theme';

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: `${amnFontSize.fs14}`,
    padding: '10px 25px',
    borderRadius: '7px',
  },
}));

export function DefaultDialog(props: DefaultDialogProps) {
  const {
    open,
    type,
    title,
    body,
    dialogSx,
    onConfirm,
    primaryButtonText = 'Confirm',
    onClose = () => {},
    secondaryButtonText = 'CANCEL',
    dialogPaperSx,
  } = props;
  const classes = useStyles();
  const getTypeColor = () => {
    switch (type) {
      case 'alert':
        return theme.palette.system.error;

      default:
        return theme.palette.system.midnightBlue;
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      sx={{ ...dialogSx, zIndex: 2500 }}
      PaperProps={{ sx: { ...dialogPaperSx } }}
    >
      <Box
        padding={4}
        sx={{
          borderRadius: '4px',
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          ...dialogSx?.['outerBoxSx'],
        }}
      >
        <Typography
          variant="h3"
          mb={2}
          fontWeight="600"
          color={getTypeColor()}
          sx={{ letterSpacing: '0.18px' }}
          data-testid="dialog-title"
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          mb={4}
          color="system.dialogTextGray"
          sx={{ letterSpacing: '0.25px' }}
          data-testid="dialog-body"
        >
          {body}
        </Typography>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button
            id="default-dialog-cancel-button"
            sx={{
              color: theme.palette.system.skyBlue,
              '&:hover': {
                color: theme.palette.system.skyBlue,
              },
              width: 'auto',
              ...dialogSx?.['cancelSx'],
            }}
            className={classes.button}
            onClick={onClose}
          >
            {secondaryButtonText}
          </Button>
          {onConfirm && primaryButtonText && (
            <Button
              id="default-dialog-confirm-button"
              sx={{
                color: theme.palette.system.white,
                background: theme.palette.system.midnightBlue,
                '&:hover': {
                  color: theme.palette.system.white,
                  background: theme.palette.system.midnightBlue,
                },
                minWidth: '100px',
                ...dialogSx?.['confirmSx'],
              }}
              className={classes.button}
              onClick={onConfirm}
            >
              {primaryButtonText}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
