import { makeStyles } from '@mui/styles';
import { theme } from 'styles/theme';

export const useFooterStyles = makeStyles(() => ({
  footerContainer: {
    bottom: '0',
    width: '100%',
    left: '0',
    right: '0',
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  logo: {
    height: '32px',
    width: 'clamp(169px, 40vw ,200px)',
  },
}));
