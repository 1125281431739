import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEducationSchool } from 'interfaces/User/IEducationSchool';
import { ITravelerApplicationStatusResponse } from 'interfaces/User/ITravelerApplicationStatus';
import { IPayTransparencyRequest } from 'interfaces/User/UserProfile/IPayTransparencyRequest';
import {
  FeatureFlags,
  IFeatureToggle,
  IPromotionNotifications,
  IToolTip,
  IUserProfile,
} from 'interfaces/User/UserProfile/IUserProfile';
import { IUserProfileRequest } from 'interfaces/User/UserProfile/IUserProfileRequest';
import { PhotoUploadRequest } from 'types/Photo';

const initialState: IUserProfile = {
  userId: '',
  mobileUserId: '',
  oneLoginId: '',
  travelerId: 0,
  npi: '',
  goldenRecordId: '',
  userStatusTypeId: 0,
  userStatusType: '',
  userDisciplineTypeId: 0,
  userDisciplineType: '',
  userTypeId: 0,
  userType: '',
  companyId: 0,
  firstName: '',
  middleName: '',
  lastName: '',
  nickname: '',
  birthDate: '',
  emailAddress: '',
  emailAddress2: '',
  profileImage: {
    file: '',
    url: '',
    date: new Date(),
  },
  canWorkInUsa: false,
  resumeSubmissionType: '',
  isLicenseSuspended: false,
  licenseSuspendedExplanation: '',
  hasAcceptedTermsAndConditions: undefined,
  acceptedTermsAndConditionsVersion: '',
  travelerApplicationStatus: {
    appId: '',
    personalInformation: false,
    contactInformation: false,
    additionalInformation: false,
    licenses: false,
    certifications: false,
    education: false,
    employmentHistory: false,
    hasSubmittedApp: false,
  },
  travelerApplicationResponse: {
    summary: {
      totalItemsRequested: 0,
      totalItemsToProcess: 0,
      totalItemsProcessed: 0,
      totalRequestUnits: 0,
      totalSuccesses: 0,
      totalFailures: 0,
    },
    items: [],
  },
  phoneNumbers: [],
  addresses: [],
  emergencyContact: {
    name: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    relationship: '',
  },
  disciplinesAndSpecialties: [],
  employmentHistory: [],
  educationSchools: [],
  contacts: [],
  socialMedia: {
    preferredId: 0,
    preferred: '',
    links: [],
  },
  isSubmitLoaderActive: false,
  featureFlags: {},
  //TODO move this as a seperate error object
  //Added the error here as using the error from the error slice was causing some overlap issues (Other component alert was getting popped up)
  //Specific to errorwithoutpayload
  error: false,
  featureToggles: [],
  gender: '',
  ethnicityTypeId: 0,
  ethnicityType: '',
  toolTips: [],
  promotionNotifications: {
    unreadCount: 0,
  },
  emergencyContactUpdated: false,
};

export const patchPayTransparencyDetailsAction = createAction(
  'PATCH_PAY_TRANSPARENCY_DETAILS',
  function prepare(payTansparencyRequest: IPayTransparencyRequest) {
    return {
      payload: payTansparencyRequest,
    };
  },
);

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    getWebTravelerApplicationStatus(state, action: PayloadAction<string>) {},
    getUserProfile(state, action: PayloadAction<string>) {},
    setUserProfile(state, action: PayloadAction<IUserProfile>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    toggleSubmitLoader(state, action: PayloadAction<boolean>) {
      state.isSubmitLoaderActive = action.payload;
    },
    setTravelerApplicationResponse(
      state,
      action: PayloadAction<ITravelerApplicationStatusResponse>,
    ) {
      state.travelerApplicationResponse = action.payload;
    },
    updateEducationSchools(state, action: PayloadAction<IEducationSchool>) {
      state.educationSchools.push(action.payload);

      return state;
    },
    updateProfile(state, action: PayloadAction<IUserProfileRequest>) {
      state.isSubmitLoaderActive = true;
    },
    setUpdatedUserProfile(state, action: PayloadAction<IUserProfileRequest>) {
      state.isSubmitLoaderActive = false;
      state = Object.assign(state, action.payload);
      return state;
    },
    uploadUserProfilePhoto(state, action: PayloadAction<PhotoUploadRequest>) {},
    deleteUserProfilePhoto(state, action: PayloadAction<string>) {},
    setErrorWithoutPayload(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    unSetError(state) {
      return {
        ...state,
        error: false,
        isSubmitLoaderActive: false,
      };
    },
    setTermsAndConditons(state, action: PayloadAction<boolean>) {
      state.hasAcceptedTermsAndConditions = action.payload;
    },
    updateWelcomeBanner() {},
    setWelcomeBannerStatus(state, action: PayloadAction<boolean>) {
      state.hasSeenWelcomeBanner = action.payload;
    },
    setFeatureFlag(
      state,
      action: PayloadAction<{ [id: number]: FeatureFlags }>,
    ) {
      state.featureFlags = action.payload;
    },
    setFeatureToggles(state, action: PayloadAction<IFeatureToggle[]>) {
      state.featureToggles = action.payload;
    },
    setToolTips(state, action: PayloadAction<IToolTip[]>) {
      state.toolTips = action.payload;
    },
    updatePayTransparencyDetails(
      state,
      action: PayloadAction<IPayTransparencyRequest>,
    ) {
      state.gender = action.payload.gender;
      state.ethnicityTypeId = action.payload.ethnicityTypeId;
      return state;
    },
    setPromotionNotifications(
      state,
      action: PayloadAction<IPromotionNotifications>,
    ) {
      state.promotionNotifications = action.payload;
    },
    setEmergencyContactUpdatedAction(state, action: PayloadAction<boolean>) {
      state.emergencyContactUpdated = action.payload;
      return state;
    },
  },
});

export const {
  getUserProfile,
  setUserProfile,
  updateEducationSchools,
  updateProfile,
  toggleSubmitLoader,
  setUpdatedUserProfile,
  uploadUserProfilePhoto,
  deleteUserProfilePhoto,
  setErrorWithoutPayload,
  unSetError,
  setTermsAndConditons,
  setTravelerApplicationResponse,
  getWebTravelerApplicationStatus,
  updateWelcomeBanner,
  setWelcomeBannerStatus,
  setFeatureFlag,
  setFeatureToggles,
  updatePayTransparencyDetails,
  setToolTips,
  setPromotionNotifications,
  setEmergencyContactUpdatedAction,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
