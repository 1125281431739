import React, { useState } from 'react';
import { IPdfViewerProps } from 'interfaces/Props/IPdfViewerProps';
import { Box, Grid } from '@mui/material';
import SiteHeaderWithMenuOption from 'components/SiteHeader/SiteHeaderWithMenuOption';
import { PageStyle, PdfDocument } from './PdfViewer.styles';
import { Page } from 'react-pdf/dist/esm/entry.webpack';

export const PdfViewer = (props: IPdfViewerProps) => {
  const { id, title, pdfData, menuItems } = props;

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  const classes = PageStyle();

  return (
    <Grid container id={id}>
      <Grid item xs={12}>
        <SiteHeaderWithMenuOption
          id="pdfViewerHeader"
          title={title}
          menuItems={menuItems}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'system.inputBackground',
            height: 'calc(100vh - 70px)',
            overflow: 'auto',
          }}
        >
          <PdfDocument
            file={`data:application/pdf;base64,${pdfData}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className={classes.page}
              />
            ))}
          </PdfDocument>
        </Box>
      </Grid>
    </Grid>
  );
};
