import { Grid } from '@mui/material';
import { AppCircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { IFrameDrawer } from 'components/common/Drawer/IFrameDrawer';
import { TOAST_MESSAGE } from 'constants/helperText';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { setEmergencyContactUpdatedAction } from 'store/slices/user/userProfile/userProfileSlice';

export const FormStackForm = props => {
  const dispatch = useAppDispatch();
  const docUrl = useAppSelector(state => state.credential.formStackData);
  const id = [props.credentialId];

  const { toggleDrawer, url } = props;

  useEffect(() => {
    //This is formstack message event handler
    const formStackMessegeHandler = event => {
      if (event?.data === 'fs-form-submit') {
        if (url.includes('medical_records_release_statement')) {
          dispatch(setEmergencyContactUpdatedAction(true));
        }
        setTimeout(() => {
          toggleDrawer?.(null, false);
          dispatch(
            openAlert({
              variant: 'success',
              message: TOAST_MESSAGE.FormSubmitted,
            }),
          );
        }, 3000);

        if (typeof id[0] === 'string') {
          let dismissals: any = { dismissals: id };
          dispatch(
            actionItemActions.removeActionItem({ dismissals: dismissals }),
          );
        } else if (!!props.placementId && !!props.credentialId) {
          dispatch(
            credentialActions.setCredentialStatusAction({
              placementId: props.placementId,
              credentialId: props.credentialId,
            }),
          );
        }
        dispatch(credentialActions.getSubmissions());
        dispatch(credentialActions.getCredentialDetailAction(true));
      }
    };
    window.addEventListener('message', formStackMessegeHandler);
    return () => {
      window.removeEventListener('message', formStackMessegeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(credentialActions.setFormStackData({}));
    const docUri = url?.substring(url?.indexOf('forms/'));
    dispatch(credentialActions.getFormStackDataAction(docUri));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    return () => {
      dispatch(credentialActions.setFormStackData({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      id="formstack-form"
      sx={{
        position: 'absolute',
        top: '73px',
        height: 'calc(100% - 73px)',
        left: 0,
      }}
    >
      {docUrl?.url ? (
        <IFrameDrawer url={docUrl.url} />
      ) : (
        <AppCircularProgress />
      )}
    </Grid>
  );
};
