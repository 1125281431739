import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AppButton } from 'components/common/Button/Button';
import { AppLink } from 'components/common/Link/Link';
import { LoginWrapper } from 'containers/LoginContainer/LoginWrapper';
import { openInNewTab } from 'helpers/openInNewTab';
import { IPasswordInputs } from 'interfaces/Authentication/IPasswordInputs';
import { ICountryAndProvince } from 'interfaces/Lookup/ICountryAndProvince';
import {
  ValidationDot,
  ValidationRuleRow,
  ValidationRulesContainer,
} from 'pages/ResetPasswordPage/ResetPasswordPage.styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { verifyPasswordReset } from 'store/slices/authentication/resetPasswordSlice';
import {
  createUserAccount,
  setLoadingAction,
} from 'store/slices/user/userLogin/userLoginSlice';
import { IUserLogin } from 'store/slices/user/userLogin/userLoginState';
import { theme } from 'styles/theme';
import { CreateUserRequest } from 'types/CreateUserRequest';
import * as yup from 'yup';
import logo from '../../../assets/images/Controls-Logo@2x.png';
import { PRIVACY_POLICY_URL } from 'constants/passportUrls';

export const CreatePassword = () => {
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useAppDispatch();
  const history = useHistory();

  const resetCode = useAppSelector(state => state.resetPassword.code);
  const isPasswordReset = useAppSelector(
    state => state.resetPassword.isPasswordReset,
  );

  const [showPassword, setshowPassword] = useState(false);
  const [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [numberError, setNumberError] = useState(false);
  const [alphaError, setAlphaError] = useState(false);
  const [specialError, setSpecialError] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = yup
    .object()
    .shape({
      newPassword: yup
        .string()
        .required('New Password is required')
        .min(10, 'Password must be at least 10 characters')
        .matches(/[a-z]+/, 'One lowercase character')
        .matches(/[A-Z]+/, 'One uppercase character')
        .matches(/[@$!%*#?&]+/, 'One special character')
        .matches(/\d+/, 'One number'),
      newPasswordConfirmation: yup
        .string()
        .when('newPassword', (newPassword, schema) => {
          if (newPassword)
            return schema.required('Confirm Password is required');
        })
        .oneOf([yup.ref('newPassword')], 'Passwords must match'),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IPasswordInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const userData: IUserLogin = useAppSelector(state => {
    return state.userExistStatus;
  });

  const countryAndProvices: ICountryAndProvince[] = useAppSelector(state => {
    return state.lookup?.stateAndProvince;
  });
  const userLoginState = useAppSelector(state => state.userExistStatus);
  const redirectRoute = useAppSelector(
    state => state.userExistStatus.redirectRoute,
  );

  const isVerificationLoading = userLoginState.isLoading;

  useEffect(() => {
    let numReg = new RegExp(/\d/);
    let specCharReg = new RegExp(/[!@#$%&*]/);
    let lowerReg = new RegExp(/[a-z]/);
    let upperReg = new RegExp(/[A-Z]/);

    newPassword.match(numReg) === null
      ? setNumberError(true)
      : setNumberError(false);
    newPassword.match(upperReg) === null || newPassword.match(lowerReg) === null
      ? setAlphaError(true)
      : setAlphaError(false);
    newPassword.match(specCharReg) === null
      ? setSpecialError(true)
      : setSpecialError(false);
    newPassword.length >= 10 ? setLengthError(false) : setLengthError(true);
  }, [newPassword]);

  useEffect(() => {
    if (
      !isValid ||
      lengthError ||
      numberError ||
      alphaError ||
      specialError ||
      !(Object.keys(errors).length === 0)
    ) {
      setIsSubmitted(false);
    }
  }, [isValid, lengthError, numberError, specialError, errors, alphaError]);

  useEffect(() => {
    if (userData.isNewUserAccountCreated || isPasswordReset) {
      setIsSubmitted(false);
      history.push(AppRouteNames.SIGN_IN);
      dispatch(setLoadingAction(false));
    }
  }, [dispatch, history, userData.isNewUserAccountCreated, isPasswordReset]);

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setshowPasswordConfirm(!showPasswordConfirm);
  };

  const handleSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.currentTarget.value);
  };

  const onSubmit = useCallback(() => {
    switch (redirectRoute) {
      case AppRouteNames.CREATE_ACCOUNT:
        if (
          userData.loginState.userEmail !== undefined &&
          userData.createAccountData !== undefined
        ) {
          let selectedState = '';
          if (userData.createAccountData.country === 'United States') {
            selectedState =
              countryAndProvices[1].statesAndProvinces.find(
                state => state.stateName === userData.createAccountData.state,
              )?.stateAbbreviation ?? 'CA';
          } else {
            selectedState =
              countryAndProvices[0].statesAndProvinces.find(
                state => state.stateName === userData.createAccountData.state,
              )?.stateAbbreviation ?? 'AB';
          }

          let newUser: CreateUserRequest = {
            emailAddress: userData.loginState.userEmail,
            password: newPassword,
            firstName: userData.createAccountData.firstName,
            lastName: userData.createAccountData.lastName,
            phoneNumber: userData.createAccountData.phoneNumber,
            country: userData.createAccountData.country,
            address1: userData.createAccountData.address,
            city: userData.createAccountData.city,
            stateProvince: selectedState,
            zip: userData.createAccountData.zip,
            disciplineID: userData.createAccountData.discipline,
            specialtyID: userData.createAccountData.specialty,
            code: userData.createAccountData.code,
          };

          //Conditional add properties

          if (userData?.createAccountData?.address2) {
            newUser.address2 = userData.createAccountData.address2;
          }

          if (userData?.createAccountData?.secondSpecialty)
            newUser.hearsaySpecialtyId = Number(
              userData.createAccountData.secondSpecialty,
            );

          if (userData?.createAccountData?.travelAssignments)
            newUser.travelAssignmentInterest =
              userData.createAccountData.travelAssignments;

          if (userData?.createAccountData?.criticalStaffing)
            newUser.criticalStaffingRapidResponseInterest =
              userData.createAccountData.criticalStaffing;

          if (userData?.createAccountData.localPerDiem)
            newUser.localPerDiemInterest =
              userData.createAccountData.localPerDiem;

          if (userData?.createAccountData.strike)
            newUser.strikeOpportunityInterest =
              userData.createAccountData.strike;

          dispatch(createUserAccount(newUser));
          setIsSubmitted(true);
        }
        break;
      case AppRouteNames.EMAIL_VERIFICATION:
        let createPasswordRequest = {
          emailAddress: userData.loginState.userEmail,
          newPassword: newPassword,
          newPasswordConfirmation: newPassword,
          code: resetCode,
        };
        dispatch(verifyPasswordReset(createPasswordRequest));
        setIsSubmitted(true);
        break;
      default:
        break;
    }
  }, [
    countryAndProvices,
    dispatch,
    newPassword,
    redirectRoute,
    resetCode,
    userData.createAccountData,
    userData.loginState.userEmail,
  ]);

  const routeToHome = () => {
    history.push(AppRouteNames.SIGN_IN);
    dispatch(setLoadingAction(false));
  };

  const checkWidth = () => {
    if (matches) {
      return '300px';
    } else {
      return '188px';
    }
  };

  return (
    <LoginWrapper>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="300px"
        gap={2}
      >
        <Grid item mb={{ xs: 4, md: '70px' }}>
          <img src={logo} alt="amn-passport-logo" width={checkWidth()} />
        </Grid>
        <Grid item>
          <Typography
            variant="h2"
            color={theme.palette.system.midnightBlue}
            textAlign="center"
            lineHeight="33px"
          >
            Thanks for verifying
          </Typography>
          <Typography
            variant="h2"
            color={theme.palette.system.midnightBlue}
            textAlign="center"
            lineHeight="33px"
          >
            your email.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            color={theme.palette.system.grayText}
            mb={2}
            textAlign="center"
            lineHeight="20px"
          >
            Create a password to secure your account.
          </Typography>
        </Grid>
        {/* @TODO: Refactor to use FormProvider component*/}
        <form>
          <Grid item>
            {/* @TODO: Refactor to use ReactHookFormTextField component*/}
            <TextField
              id="newPassword"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              margin="dense"
              {...register('newPassword')}
              variant="filled"
              error={errors.newPassword ? true : false}
              onFocus={e => setDisplayError(true)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSetPassword}
            />
          </Grid>
          <Grid item mb={2}>
            {displayError && (
              <ValidationRulesContainer>
                <ValidationRuleRow>
                  <ValidationDot
                    color={
                      lengthError
                        ? `${theme.palette.system.gray}`.toString()
                        : `${theme.palette.system.grassGreen}`.toString()
                    }
                  />
                  <span>Use at least 10 characters</span>
                </ValidationRuleRow>
                <ValidationRuleRow>
                  <ValidationDot
                    color={
                      alphaError
                        ? `${theme.palette.system.gray}`.toString()
                        : `${theme.palette.system.grassGreen}`.toString()
                    }
                  />
                  <span>Use upper and lower case characters</span>
                </ValidationRuleRow>
                <ValidationRuleRow>
                  <ValidationDot
                    color={
                      numberError
                        ? `${theme.palette.system.gray}`.toString()
                        : `${theme.palette.system.grassGreen}`.toString()
                    }
                  />
                  <span>Use numbers</span>
                </ValidationRuleRow>
                <ValidationRuleRow>
                  <ValidationDot
                    color={
                      specialError
                        ? `${theme.palette.system.gray}`.toString()
                        : `${theme.palette.system.grassGreen}`.toString()
                    }
                  />
                  <span>Use special characters (i.e.: $, %, &)</span>
                </ValidationRuleRow>
              </ValidationRulesContainer>
            )}
          </Grid>
          <Grid item mb={2}>
            {/* @TODO: Refactor to use ReactHookFormTextField component*/}
            <TextField
              id="newPasswordConfirmation"
              label="Confirm Password"
              type={showPasswordConfirm ? 'text' : 'password'}
              fullWidth
              margin="dense"
              {...register('newPasswordConfirmation')}
              error={errors.newPasswordConfirmation ? true : false}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      size="large"
                    >
                      {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <AppButton
              id="sign-in-back-btn"
              type="button"
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={
                !isValid ||
                lengthError ||
                numberError ||
                alphaError ||
                specialError ||
                !(Object.keys(errors).length === 0) ||
                isSubmitted ||
                isVerificationLoading
              }
            >
              {isVerificationLoading ? 'Creating Account…' : 'Next'}
            </AppButton>
          </Grid>
          <Grid item mb={2}>
            <AppButton
              id="reset-code-back-btn"
              type="button"
              variant="secondary"
              onClick={routeToHome}
            >
              Cancel
            </AppButton>
          </Grid>
          <Grid item display="flex" justifyContent="center">
            <AppLink
              component="a"
              underline="hover"
              onClick={() => openInNewTab(PRIVACY_POLICY_URL)}
              id="privacy-policy-link"
              linkStyles={{
                cursor: 'pointer',
              }}
            >
              Privacy Policy
            </AppLink>
          </Grid>
        </form>
      </Grid>
    </LoginWrapper>
  );
};
