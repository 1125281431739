import React, { useCallback, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { FileUploadModal } from 'components/common/FileUploadModal/FileUploadModal';
import { MAIN_FLOWS, MAX_FILE_SIZE } from 'constants/fileTypes';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { NOTE_MESSAGE } from 'constants/helperText';
import useTrackPageView from 'utils/hooks/useTrackPageView';

const AddCredential = props => {
  const dispatch = useAppDispatch();
  const filesUploaded = useAppSelector(state => state.fileUpload.uploadedFiles);

  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setDrawerOpen,
    setActionButtonDisabled,
    selectedOpenRequirement,
  } = props;

  const submit = useCallback(() => {
    setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
    dispatch(credentialActions.setModalState(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDrawerOpen]);

  useTrackPageView('AddCredentialItem');

  useEffect(
    () => {
      if (onSubmitProcess) {
        submit();
        dispatch(
          credentialActions.submitCredentialForApprovalFromDrawerAction(
            selectedOpenRequirement,
          ),
        );
        setOnSubmitProcess?.(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmitProcess, setOnSubmitProcess, submit],
  );

  useEffect(() => {
    return () => {
      dispatch(fileUploadsActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActionButtonDisabled(filesUploaded?.length === 0);
  }, [setActionButtonDisabled, filesUploaded]);

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container maxWidth={'550px'}>
        <Grid item xs={12}>
          <Typography color="system.midnightBlue" variant="subtitle1">
            Add new or updated credentials.
          </Typography>
        </Grid>
        <Grid item pt={2} pb={2} xs={12}>
          <Typography color="system.grayText" variant="body1">
            Information will be reviewed and verified.
          </Typography>
        </Grid>

        <FileUploadModal
          required
          canUploadMultiple
          id={'upload-credential-file-upload'}
          label={'Add Documents'}
          customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
          acceptedFileTypes={MAIN_FLOWS}
        />
        <Grid item xs={12}>
          <Typography variant="body1" color="system.coolGray" mt={4}>
            {NOTE_MESSAGE.CredentialUpload}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AddCredential };
