import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginResponse } from 'interfaces/Authentication/ILoginResponse';
import { IRefreshTokenRequestData } from 'interfaces/Authentication/IRefreshTokenRequestData';
import { IVersionCheckResponse } from 'interfaces/Authentication/IVersionCheckResponse';
import { IUserCredentials } from 'interfaces/User/IUserCredentials';
import { CookieService } from 'services/cookie/cookieService';
import { decodeToken } from 'utils/tokenHelper';
import { AuthenticationState } from './authenticationState';
import { AppUserType } from 'enums/AppUserType';

const initialState: AuthenticationState = {
  accessExpired: false,
  isLoggedIn: false,
  isLoading: false,
  loginResponse: {
    accessToken: '',
    refreshToken: '',
    idToken: '',
    correlationId: '',
    featureToggles: [],
    sessionToken: '',
    targetUrl: '',
  },
  versionCheck: {
    currentPassportVersion: '',
    minimumPassportVersion: '',
    currentPassportVersionIos: '',
    minimumPassportVersionIos: '',
    currentPassportVersionAndroid: '',
    minimumPassportVersionAndroid: '',
    skillsChecklistAddUrl: '',
    skillsChecklistViewExistingUrl: '',
    skillsChecklistViewUrl: '',
    workReferenceAddUrl: '',
    formstackStrikeSignUpFormUrl: '',
    formstackStrikeSignUpFormUrlV2: '',
    timeAndPayMessaging: '',
    timeAndPayMessagingTitle: '',
    reimbursementAmountPerMile: 0,
  },
  userId: '',
  userType: '',
};

const loginUserAction = createAction(
  'LOGIN_USER',
  function prepare(loginData: IUserCredentials) {
    return {
      payload: loginData,
    };
  },
);
const refreshAccessTokenAction = createAction(
  'REFRESH_ACCESS_TOKEN',
  function prepare(refreshTokenRequestData: IRefreshTokenRequestData) {
    return {
      payload: refreshTokenRequestData,
    };
  },
);
const logoutUserAction = createAction('LOGOUT_USER');
const getVersionCheckAction = createAction('GET_VERSION_CHECK');
const activateUserAction = createAction<string>('ACTIVATE_USER');

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    getCookie(state) {
      const result = CookieService.authenticateViaCookie();
      return { ...state, ...result };
    },
    setAccessToken(state, action: PayloadAction<string>) {
      CookieService.setAccessToken(action.payload);
      state.loginResponse.accessToken = action.payload;
      state.accessExpired = false;
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.loginResponse.refreshToken = action.payload;
    },
    setVersionCheck(state, action: PayloadAction<IVersionCheckResponse>) {
      state.versionCheck = action.payload;
    },
    setLoggedInUser(state, action: PayloadAction<ILoginResponse>) {
      const { userid } = decodeToken(action.payload.accessToken);
      const token = decodeToken(action.payload.idToken);
      const usertype =
        token?.params?.PassportMobileAppUserType ?? AppUserType.PASSPORT;

      CookieService.setCookies(action);
      return {
        accessExpired: false,
        isLoggedIn: true,
        isLoading: true,
        loginResponse: {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          idToken: action.payload.idToken,
          correlationId: action.payload.correlationId,
          featureToggles: action.payload.featureToggles,
          sessionToken: action.payload.sessionToken,
          targetUrl: action.payload.targetUrl,
        },
        userId: userid,
        userType: usertype,
      };
    },
    setLoggedOut() {
      CookieService.clearCookies();
      return {
        accessExpired: false,
        isLoggedIn: false,
        isLoading: false,
        loginResponse: {
          accessToken: '',
          refreshToken: '',
          idToken: '',
          correlationId: '',
          featureToggles: [],
          sessionToken: '',
          targetUrl: '',
        },
        userId: '',
        userType: '',
      };
    },
    setLoadingAction(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const authenticationActions = {
  ...authSlice.actions,
  logoutUserAction,
  getVersionCheckAction,
  loginUserAction,
  refreshAccessTokenAction,
  activateUserAction,
};

export default authSlice.reducer;
