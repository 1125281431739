import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlliedDisciplineAndSpecialties } from 'components/Jobs/JobSearch/JobPreference/IAlliedDisciplineAndSpecialties';
import { ExpertiseFormField } from 'components/Jobs/JobSearch/JobPreference/IExpertiseFormField';
import { IFacilityType } from 'components/Jobs/JobSearch/JobPreference/IFacilityType';
import { IJobType } from 'components/Jobs/JobSearch/JobPreference/IJobType';
import { IShiftLength } from 'components/Jobs/JobSearch/JobPreference/IShiftLength';
import { IShiftTime } from 'components/Jobs/JobSearch/JobPreference/IShiftTime';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import moment, { Moment } from 'moment';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';
import { SuccessResponse } from 'types/SuccessResponse';
import {
  IAutoSubmitPreference,
  ILocationSearchResponse,
  UserPreference,
} from 'types/UserPreference';
import {
  getInitialShiftLengths,
  getInitialShiftTimes,
} from 'utils/userPreferenceHelper';
import { ErrorState } from '../error/errorState';

export interface UserPreferenceState extends ErrorState {
  isUserPreferenceUpdated: SuccessResponse;
  isPreferredRecruiterUpdated: SuccessResponse;
  isSubmitLoaderActive: boolean;
  userPreference: UserPreference;
  searchLocations: ILocationSearchResponse[] | [];
  locationAPIError: boolean;
  locations: ILocationSearchResponse[];
  initialLocations: ILocationSearchResponse[];
  activeLocationIndex: number;
  expertiseFormFields: ExpertiseFormField[];
  exhaustiveDisciplineOptions: IDropdownOption[];
  disciplineOptions: IDropdownOption[];
  alliedDisciplineAndSpecialties: IAlliedDisciplineAndSpecialties[];
  jobTypes: IJobType[];
  startDate: Moment | string | Date;
  shiftTimes: IShiftTime[];
  shiftLengths: IShiftLength[];
  facilityTypes: IFacilityType[];
  newsFeed?: string[];
  isInCompactState: undefined | boolean;
}

const userPreferenceInitialState: UserPreference = {
  id: '',
  jobs: {
    applications: [],
    disciplinesAndSpecialties: [],
    facilityTypes: [],
    favorites: [],
    placementTypes: [],
    locations: [],
    shifts: [],
    startDate: new Date().toUTCString(),
    isInCompactState: undefined,
  },
  notifications: { categories: [] },
  autoSubmitPreference: {
    enableAutoSubmissions: null,
    locations: [],
    minimumPay: 0,
    earliestDate: '',
    shiftTimes: getInitialShiftTimes(),
    shiftLengths: getInitialShiftLengths(),
    isSigned: false,
    locationRadiusOptIn: null,
    disciplinesAndSpecialties: [],
  },
};

const initialState: UserPreferenceState = {
  isUserPreferenceUpdated: { success: false },
  isPreferredRecruiterUpdated: { success: false },
  isSubmitLoaderActive: false,
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  downloadError: false,
  userPreference: userPreferenceInitialState,
  locationAPIError: false,
  searchLocations: [],
  activeLocationIndex: -1,
  locations: [],
  initialLocations: [],
  expertiseFormFields: [] as ExpertiseFormField[],
  exhaustiveDisciplineOptions: [] as IDropdownOption[],
  disciplineOptions: [] as IDropdownOption[],
  alliedDisciplineAndSpecialties: [] as IAlliedDisciplineAndSpecialties[],
  jobTypes: [
    { isActive: true, note: '', type: 'Any Job Type', typeId: 0 },
    {
      isActive: false,
      note: '13 Week Travel Assignment',
      type: '13 Week Travel Assignment',
      typeId: 100,
    },
    {
      isActive: false,
      note: 'Critical Staffing',
      type: 'Critical Staffing',
      typeId: 200,
    },
    { isActive: false, note: 'Strike', type: 'Strike', typeId: 300 },
    { isActive: false, note: 'Vaccine', type: 'Vaccine', typeId: 400 },
    {
      isActive: false,
      note: 'Clinical Fellowship',
      type: 'Clinical Fellowship',
      typeId: 500,
    },
  ] as IJobType[],
  startDate: moment.utc(new Date(), 'ddd MMM DD YYYY HH:mm:ssZ'),
  shiftTimes: getInitialShiftTimes(),
  shiftLengths: getInitialShiftLengths(),
  facilityTypes: [
    { type: 'Any Facility Type', isActive: true, typeId: 0 },
    { type: 'Magnet', isActive: false, typeId: 200 },
    { type: 'Teaching', isActive: false, typeId: 300 },
    { type: 'Trauma', isActive: false, typeId: 400 },
  ] as IFacilityType[],
  newsFeed: [],
  isInCompactState: undefined,
};

const userPreferenceSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    retrieveUserPreference(state) {},
    postUserPreference(state, action) {},
    postAutoSubmissionsPreference(state, action) {},
    updateUserPreferenceNotificationSetting() {},

    markPreferredRecruiter(state, action) {},
    setPreferredRecruiter(state, action) {
      state.userPreference = action.payload;
      state.isPreferredRecruiterUpdated.success = true;
    },
    searchLocation(state, action) {},
    setSearchLocation(state, action: PayloadAction<ILocationSearchResponse[]>) {
      let responseData = action.payload;
      // eslint-disable-next-line no-extra-boolean-cast
      if (!Boolean(responseData)) {
        responseData = [];
      }
      state.searchLocations = responseData;
    },
    setLocationAPIError(state, action: PayloadAction<boolean>) {
      state.locationAPIError = action.payload;
    },

    setSubmitLoader(
      state,
      { payload = false }: PayloadAction<boolean | undefined>,
    ) {
      state.isSubmitLoaderActive = payload;
    },
    setUserPreference(
      state,
      {
        payload: {
          userPreference,
          locations,
          initialLocations,
          isInCompactState,
          expertiseFormFields,
          exhaustiveDisciplineOptions,
          alliedDisciplineAndSpecialties,
          jobTypes,
          startDate,
          shiftTimes,
          shiftLengths,
          facilityTypes,
          newsFeed,
        },
      }: PayloadAction<any>,
    ) {
      state.userPreference = userPreference;
      state.locations = locations;
      state.initialLocations = initialLocations;
      state.isInCompactState = isInCompactState;
      state.expertiseFormFields = expertiseFormFields;
      state.exhaustiveDisciplineOptions = exhaustiveDisciplineOptions;
      state.alliedDisciplineAndSpecialties = alliedDisciplineAndSpecialties;
      state.jobTypes = jobTypes;
      state.startDate = startDate;
      state.shiftTimes = shiftTimes;
      state.shiftLengths = shiftLengths;
      state.facilityTypes = facilityTypes;
      state.newsFeed = newsFeed;
    },
    setUserPreferenceSuccess(state) {
      state.isUserPreferenceUpdated.success = true;
    },
    resetIsUserPreferenceUpdated(state) {
      state.isUserPreferenceUpdated.success = false;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      state.error = true;
      state.errorResponse = {
        errorCode: action.payload.errorCode,
        errorData: action.payload.errorData,
        errorDetails: action.payload.errorDetails,
        errorMessage: action.payload.errorMessage,
        operationId: action.payload.operationId,
        correlationId: action.payload.correlationId,
      };
      state.downloadError = false;
    },
    setErrorWithoutPayload(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    unSetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.locationAPIError = false;
      stateValues.errorResponse = {
        errorCode: 0,
        errorData: {},
        errorDetails: '',
        errorMessage: '',
        operationId: '',
        correlationId: '',
      };
      return {
        ...stateValues,
      };
    },
    resetUserPreference() {
      return { ...initialState };
    },
    resetPreferredRecruiter(state) {
      state.isPreferredRecruiterUpdated = { success: false };
    },

    // Job Preferences methods
    setActiveLocationIndex(state, action: PayloadAction<number>) {
      state.activeLocationIndex = action.payload;
    },
    addPreferredLocation(
      state,
      action: PayloadAction<ILocationSearchResponse>,
    ) {
      return {
        ...state,
        locations: [...state.locations, action.payload],
      };
    },
    removePreferredLocation(
      state,
      action: PayloadAction<ILocationSearchResponse>,
    ) {
      return {
        ...state,
        locations: state.locations.filter(
          location => location.searchText !== action.payload.searchText,
        ),
      };
    },
    clearLocations(state) {
      return {
        ...state,
        locations: [],
      };
    },
    resetPreferredLocations(state) {
      return {
        ...state,
        locations: state.initialLocations,
      };
    },

    setInCompactState(state, action) {
      state.isInCompactState = action.payload;
    },

    setExpertiseFormFields(state, action: PayloadAction<ExpertiseFormField[]>) {
      state.expertiseFormFields = action.payload;
    },
    setExhaustiveDisciplineOptions(state, action: PayloadAction<any>) {
      state.exhaustiveDisciplineOptions = action.payload;
    },
    setJobTypes(state, action: PayloadAction<IJobType[]>) {
      state.jobTypes = action.payload;
    },
    setStartDate(state, action: PayloadAction<Moment>) {
      state.startDate = action.payload;
    },
    setShiftTimes(state, action: PayloadAction<IShiftTime[]>) {
      state.shiftTimes = action.payload;
    },
    setShiftLengths(state, action: PayloadAction<IShiftLength[]>) {
      state.shiftLengths = action.payload;
    },
    setFacilityTypes(state, action: PayloadAction<IFacilityType[]>) {
      state.facilityTypes = action.payload;
    },
    setDisciplineOptions(
      state,
      action: PayloadAction<ISuccessDisciplineResponse>,
    ) {
      const discOptions = [] as IDropdownOption[];
      action.payload.items?.map(discipline =>
        discOptions.push({
          key: discipline.disciplineId.toString(),
          value: discipline.disciplineDescription,
        }),
      );
      state.disciplineOptions = discOptions;
    },
    setAlliedDisciplineAndSpecialties(
      state,
      action: PayloadAction<IAlliedDisciplineAndSpecialties[]>,
    ) {
      state.alliedDisciplineAndSpecialties = action.payload;
    },
    toggleCategoryStatus(state, action) {},
    updateCategory(state, action) {
      state.userPreference.notifications.categories = action.payload;
    },
    toggleSubCategoryStatus(
      state,
      action: PayloadAction<{ categoryId: number; subCategoryId: number }>,
    ) {},
    // ----------- Start Auto Submissions reducers ------------
    saveAutoSubmitPreferences(
      state,
      action: PayloadAction<IAutoSubmitPreference>,
    ) {
      state.userPreference = {
        ...state.userPreference,
        autoSubmitPreference: action.payload,
      };
    },
    // ----------- End Auto Submissions reducers ------------
  },
});

export const {
  retrieveUserPreference,
  postUserPreference,
  markPreferredRecruiter,
  setPreferredRecruiter,
  searchLocation,
  setUserPreference,
  setUserPreferenceSuccess,
  setSearchLocation,
  resetIsUserPreferenceUpdated,
  setError,
  setLocationAPIError,
  setErrorWithoutPayload,
  unSetError,
  resetUserPreference,
  resetPreferredRecruiter,
  setSubmitLoader,

  // Job Preferences methods
  setActiveLocationIndex,
  setExpertiseFormFields,
  setExhaustiveDisciplineOptions,
  setDisciplineOptions,
  setAlliedDisciplineAndSpecialties,
  setJobTypes,
  setStartDate,
  setShiftTimes,
  setShiftLengths,
  setFacilityTypes,
  addPreferredLocation,
  removePreferredLocation,
  clearLocations,
  resetPreferredLocations,
  setInCompactState,

  // notification preferences
  toggleCategoryStatus,
  toggleSubCategoryStatus,
  updateUserPreferenceNotificationSetting,
  updateCategory,

  // Auto Submissions methods
  postAutoSubmissionsPreference,
  saveAutoSubmitPreferences,
} = userPreferenceSlice.actions;

export default userPreferenceSlice.reducer;
