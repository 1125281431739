import React, { FC, Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import { ReactHookFormLocationField } from 'components/common/LocationSearch/ReactHookFormLocationField';
import { ReactHookFormStateField } from 'components/common/LocationSearch/ReactHookFormStateField';
import { AddressComponentTypes } from 'enums/googleAddressComponents';
import { SearchTypes } from 'enums/googleSearchTypeEnum';
import { ICountryAndProvince } from 'interfaces/Lookup/ICountryAndProvince';
import { IAddressProps } from 'interfaces/Props/IAddressProps';
import { RootState } from 'store/configureStore';

const ZipCodeAndState: FC<IAddressProps> = ({ nestedName }) => {
  let stateName = nestedName ? `${nestedName}.state` : 'state';
  let zipCodeName = nestedName ? `${nestedName}.zipCode` : 'zipCode';
  let countryName = nestedName ? `${nestedName}.country` : 'country';
  let cityName = nestedName ? `${nestedName}.city` : 'city';
  let iso2CountryCodeName = nestedName
    ? `${nestedName}.iso2CountryCode`
    : 'iso2CountryCode';

  const stateFromStore: ICountryAndProvince[] = useSelector(
    (state: RootState) => {
      return state.lookup.stateAndProvince;
    },
  );
  const { setValue, watch } = useFormContext();
  const countryWatcher = watch(countryName);
  const formData = watch();

  //check if it is canada then set states value as states canada
  const [stateLabel, setStateLabel] = useState('State');
  const [zipCodeLabel, setzipCodeLabel] = useState('Zip Code');

  const setValuesForSelectedCountry = () => {
    if (countryWatcher === 'CAN' && stateFromStore.length) {
      setStateLabel('Province');
      setzipCodeLabel('Postal Code');
    } else if (countryWatcher === 'USA' && stateFromStore.length) {
      setStateLabel('State');
      setzipCodeLabel('Zip Code');
    } else {
      setStateLabel('State/Province');
      setzipCodeLabel('Postal Code');
      setValue(stateName, formData[nestedName]?.state);
    }
  };

  useEffect(() => {
    setValuesForSelectedCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryWatcher]);

  return (
    <Fragment key={nestedName}>
      <Grid item xs={12} mb={2}>
        <ReactHookFormStateField
          hideDropdown
          label={stateLabel}
          name={stateName}
          country={countryWatcher}
        />
      </Grid>

      <Grid item xs={12} mb={2}>
        <ReactHookFormLocationField
          type={SearchTypes.POSTAL_CODE}
          fieldMap={{
            [stateName]: {
              type: AddressComponentTypes.STATE,
              useShortName: true,
            },
            [cityName]: AddressComponentTypes.CITY,
          }}
          name={zipCodeName}
          label={zipCodeLabel}
          addressType={AddressComponentTypes.POSTAL_CODE}
          hideDropdown
          hideIcon
          country={watch(iso2CountryCodeName)}
        />
      </Grid>
    </Fragment>
  );
};
export default ZipCodeAndState;
