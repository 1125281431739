import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, IconButton, Typography } from '@mui/material';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { CustomizedDialog } from 'components/common/Dialog/CustomizedDialog';
import _ from 'lodash';
import React, { useState } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setJobTypes } from 'store/slices/user/userPreferenceSlice';
import { IJobType } from '../IJobType';
import { alterAllOptions, areAllOptionsChecked } from '../utils';
import { JobTypeDialogContent } from './JobTypeDialogContent';

const JobTypeForm = ({ setFormDirty }: { setFormDirty?: Function }) => {
  const dispatch = useAppDispatch();

  const jobTypes = useAppSelector(state => state.userPreference.jobTypes);

  const handleJobTypeChange = (e, index) => {
    setFormDirty?.(true);
    let tempJobTypes = _.cloneDeep(jobTypes);
    tempJobTypes[index]['isActive'] = e.target.checked;

    // Check for any job type
    if (index === 0) {
      tempJobTypes = alterAllOptions(tempJobTypes, false) as IJobType[];
    } else {
      tempJobTypes[0]['isActive'] = false;
    }
    dispatch(setJobTypes(tempJobTypes));

    if (areAllOptionsChecked(tempJobTypes)) {
      let tmpJobTypes = _.cloneDeep(tempJobTypes);
      tmpJobTypes[0]['isActive'] = false;
      dispatch(setJobTypes(tmpJobTypes));
    }
  };

  const [showFlags, setShowFlags] = useState<boolean>(false);
  const handleClose = () => {
    setShowFlags(false);
  };

  return (
    <Grid
      container
      item
      id="job-type-form"
      display="flex"
      flexDirection="column"
    >
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle1"
          color="system.midnightBlue"
          lineHeight="22px"
          textAlign={'left'}
        >
          Job Types
        </Typography>
        <IconButton
          sx={{ padding: '0px' }}
          onClick={() => setShowFlags(true)}
          data-testid="info-icon-button"
        >
          <InfoOutlinedIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item flexDirection="column" display="flex">
        {jobTypes.map((jobType, index) => (
          <CheckBox
            key={index}
            id={`jobType_type_${index}`}
            checked={jobType.isActive}
            onChange={e => handleJobTypeChange(e, index)}
            checklabel={jobType.type}
          />
        ))}
      </Grid>
      {showFlags && (
        <CustomizedDialog
          title="Job Types"
          body={<JobTypeDialogContent></JobTypeDialogContent>}
          isOpen={showFlags}
          handleClose={handleClose}
          paperPropSx={{ maxWidth: '640px', maxHeight: '523px' }}
        />
      )}
    </Grid>
  );
};

export default JobTypeForm;
