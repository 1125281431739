import { http } from 'services/BaseApi';

export const fetchAuthImgUrl = async (imgUrl: string): Promise<any> => {
  try {
    return await http.get<string>(`${imgUrl}/url`);
  } catch (error) {
    console.log(error);
  }
};

export const signArbitrationAgreement = async ({
  userId,
  entityName,
  signature,
}: {
  userId: string;
  entityName: string;
  signature: string;
}) => {
  try {
    return await http.put(`arbitration/${userId}/${entityName}/sign`, {
      signature,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};
