import { TOAST_MESSAGE } from 'constants/helperText';
import { FeatureFlagType } from 'enums/featureFlags';
import { openInNewTab } from 'helpers/openInNewTab';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as service from 'services/actionItem/actionItemService';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';

export const userId = (state: RootState) => state.auth.userId;
export const placementId = (state: RootState) =>
  state.actionItem?.selectedItem?.actionItem?.placementId;
export const selectedItem = (state: RootState) => state.actionItem.selectedItem;
export const featureFlagsSelector = (state: RootState) =>
  state.userProfile.featureFlags;
const selectedClearanceItem = (state: RootState) =>
  state.actionItem.selectedClearanceItem;
const userActionItems = (state: RootState) => state.actionItem.actionItems;
const userAssignments = (state: RootState) =>
  state.assignmentSummary.assignmentState.items;
// @TODO: Potentially delete get action items as we don't use it
function* getActionItems(action) {
  try {
    let featureFlags = yield select(featureFlagsSelector);
    let value = featureFlags
      ? featureFlags[FeatureFlagType.Credential_Center]?.isEnabled
      : false;

    const apiCall = value ? service.getActionItemsV3 : service.getActionItems;
    const response = yield call(apiCall, action.payload);
    yield put(actionItemActions.setActionItem(response.data));
  } catch (error: any) {
    yield put(actionItemActions.setError(error));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
  }
}

function* deleteActionItem(action) {
  try {
    let currentUserId = yield select(userId);
    let selectedActionItem = yield select(selectedItem);
    const response = yield call(
      f => service.dismissActionItem(currentUserId, action.payload.dismissals),
      action.payload,
    );
    const { data } = response;

    if (data) {
      if (selectedActionItem) {
        yield put(
          actionItemActions.removeDismissedActionItem(selectedActionItem?.id),
        );
        yield put(actionItemActions.resetSelectedItem());
      } else {
        let ids = action.payload.dismissals.dismissals;
        for (const id of ids) {
          yield put(actionItemActions.removeDismissedActionItem(id));
        }
      }
      yield put(credentialActions.getCredentialDetailAction(true));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(actionItemActions.setError(err));
  }
}

function* savePaycard(action) {
  try {
    let currentUserId = yield select(userId);
    let placementID = yield select(placementId);
    let selectedActionItem = yield select(selectedItem);
    const response = yield call(() =>
      service.saveWiselyPaycardInfo(currentUserId, placementID, action.payload),
    );
    const { data } = response;
    if (data) {
      let dismissals: any = { dismissals: [selectedActionItem.actionItem.id] };
      yield put({
        type: actionItemActions.removeActionItem.type,
        payload: { dismissals: dismissals },
      });
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(
      openAlert({
        variant: 'error',
        message: `${err.errorMessage}`,
      }),
    );
  }
}

function* getDocusignUrlSaga(action) {
  try {
    let currentUserId = yield select(userId);
    let selectedActionItem = yield select(selectedItem);
    const response = yield call(() =>
      service.getDocusignEnvelopeUrl(
        currentUserId,
        selectedActionItem?.docusign?.envelopeId,
      ),
    );
    const { data } = response;
    if (data) {
      openInNewTab(data.url);
      yield put(actionItemActions.resetSelectedItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(actionItemActions.setError(err));
  }
}

// @todo: i think this needs to be looked at - we're no longer using action items
// and instead open tasks so we may need to switch this over to that
function* dismissActionItemForClearanceItem() {
  const clearanceItem = yield select(selectedClearanceItem);
  const actionItems = yield select(userActionItems);
  const assignments = yield select(userAssignments);

  if (
    actionItems.some(
      item => item.id.toString() === clearanceItem.id.toString(),
    ) ||
    assignments?.some(assignment =>
      assignment.actionItems.some(
        item => item.id.toString() === clearanceItem.id.toString(),
      ),
    )
  ) {
    let dismissals: any = { dismissals: [clearanceItem.id] };
    yield put(actionItemActions.removeActionItem({ dismissals: dismissals }));
    let removeActionItemForAssignmentPayload = {
      isAssignmentPage: !!window.location.pathname.includes('/assignments'),
      clearanceItemId: clearanceItem.id,
    };
    yield put(
      assignmentActions.removeActionItemForAssignment(
        removeActionItemForAssignmentPayload,
      ),
    );
    yield put(actionItemActions.resetSelectedClearanceItem());
  } else {
    return;
  }
}

function* watchActionItem() {
  yield takeEvery(actionItemActions.getActionItem.type, getActionItems);
}
function* watchRemoveActionItem() {
  yield takeEvery(actionItemActions.removeActionItem.type, deleteActionItem);
}
function* watchSavePaycardInfo() {
  yield takeEvery(actionItemActions.savePaycardInfo.type, savePaycard);
}
function* watchDocusignUrl() {
  yield takeEvery(actionItemActions.getDocusignUrl.type, getDocusignUrlSaga);
}
function* watchDismissActionItemForClearanceItem() {
  yield takeEvery(
    actionItemActions.dismissActionItemForClearanceItem.type,
    dismissActionItemForClearanceItem,
  );
}

export function* actionItemSaga() {
  yield all([
    watchActionItem(),
    watchRemoveActionItem(),
    watchSavePaycardInfo(),
    watchDocusignUrl(),
    watchDismissActionItemForClearanceItem(),
  ]);
}
