import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IFeatureContentSuccessResponse } from 'types/FeaturedContentResponse';
import { DomainState } from './domainState';

const initialState: DomainState = {
  featuredContent: {
    paging: {
      limit: '10',
      offset: '0',
      total: '10',
    },
    items: [],
  },
  isLoading: false,
  pdfLink: undefined,
};

export const domainSlice = createSlice({
  name: 'domainSlice',
  initialState,
  reducers: {
    getFeaturedContent(state, action) {
      state.isLoading = true;
    },
    openPDF(state, action) {},
    downloadPDF(state, action) {},
    setFeaturedContent(
      state,
      action: PayloadAction<IFeatureContentSuccessResponse>,
    ) {
      state.featuredContent = action.payload;
      state.isLoading = false;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        isLoading: true,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
  },
});

export const {
  getFeaturedContent,
  setFeaturedContent,
  setError,
  openPDF,
  downloadPDF,
} = domainSlice.actions;
export default domainSlice.reducer;
