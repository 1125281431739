import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import PhoneNumber from 'components/common/Form/Dropdown/PhoneNumber';
import { PersonalInformationNumberType } from 'enums/phoneNumberType';
import { RootState } from 'store/configureStore';

export const PhoneNumberWrapper = () => {
  const countries = useSelector((state: RootState) => state.lookup.countries);

  return (
    <Grid container item gap={4}>
      <Grid item xs={12}>
        <PhoneNumber
          options={countries}
          label="Mobile Phone"
          name={PersonalInformationNumberType.MOBILEPHONE}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneNumber
          options={countries}
          label="Home Phone"
          name={PersonalInformationNumberType.HOMEPHONE}
        />
      </Grid>
    </Grid>
  );
};
