import { CredentialStatus, TimeEntryStatus } from 'enums/status';

/**
 * All Credential Status helpers
 * @param status checks to see if status id passed matches funtion used
 * @returns boolean
 */
export const isCredentialStatusOpen = (status: number) => {
  return (
    status === CredentialStatus.Open || status === CredentialStatus.Rejected
  );
};
export const isCredentialStatusPending = (status: number) => {
  return (
    status === CredentialStatus.Pending || status === CredentialStatus.Accepted
  );
};
export const isCredentialStatusApproved = (status: number) => {
  return status === CredentialStatus.Compliant;
};

/**
 * All Timesheet Status helpers
 * @param status checks to see if status id passed matches funtion used
 * @returns boolean
 */
export const isTimesheetOpen = (status: number) => {
  return status === TimeEntryStatus.Open || status === TimeEntryStatus.Rejected;
};
export const isTimesheetSubmitted = (status: number) => {
  return (
    status === TimeEntryStatus.Pending || status === TimeEntryStatus.Approved
  );
};
