import React, { useCallback, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { FileUploadModal } from 'components/common/FileUploadModal/FileUploadModal';
import { MAIN_FLOWS, MAX_FILE_SIZE } from 'constants/fileTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setErrorWithoutPayload } from 'store/slices/error/errorSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { useForm } from 'react-hook-form';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { AMNSnackbar } from 'components/common/Alert/AMNSnackbar';
import { TOAST_MESSAGE } from 'constants/helperText';
import { DocumentType } from 'enums/credential';

export const AddIdentification = ({
  onSubmitProcess,
  setActionButtonDisabled,
  setOnSubmitProcess,
  setDrawerOpen,
  licenseSubmitButtonText,
  setLicenseSubmitButtonText,
}: {
  onSubmitProcess?: any;
  setActionButtonDisabled?: any;
  setOnSubmitProcess?: any;
  setDrawerOpen?: any;
  licenseSubmitButtonText?: any;
  setLicenseSubmitButtonText?: any;
}) => {
  const dispatch = useAppDispatch();

  const fileUploaded = useAppSelector(state => state.fileUpload.uploadedFile);
  const documentUploadResponse = useAppSelector(
    state => state.credential.documentUploadResponse,
  );

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isSubmitted },
  } = formMethods;

  const submit = useCallback(() => {
    if (fileUploaded && fileUploaded.fileBase64) {
      setLicenseSubmitButtonText('Submitting...');
      dispatch(
        credentialActions.submitCredentialDocumentAction({
          documentTypeId: DocumentType.Identity,
          files: [fileUploaded.fileBase64],
        }),
      );
    }
  }, [dispatch, fileUploaded, setLicenseSubmitButtonText]);

  const onError = useCallback(() => {
    dispatch(setErrorWithoutPayload(true));
  }, [dispatch]);

  useEffect(() => {
    if (documentUploadResponse?.errorMessage === null) {
      setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
      dispatch(credentialActions.resetDocumentUploadResponse());
      dispatch(credentialActions.getSubmissions());
    }
  }, [dispatch, setDrawerOpen, onSubmitProcess, documentUploadResponse]);

  useEffect(() => {
    setActionButtonDisabled(
      !fileUploaded ||
        (documentUploadResponse?.errorMessage &&
          documentUploadResponse.errorMessage.length > 0) ||
        licenseSubmitButtonText === 'Submitting...',
    );
    if (
      documentUploadResponse?.errorMessage &&
      documentUploadResponse.errorMessage.length > 0
    ) {
      setLicenseSubmitButtonText('Submit for Approval');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setActionButtonDisabled,
    fileUploaded,
    licenseSubmitButtonText,
    setLicenseSubmitButtonText,
    documentUploadResponse,
  ]);

  useEffect(() => {
    setLicenseSubmitButtonText('Submit for Approval');
    dispatch(credentialActions.resetDocumentUploadResponse());
    return () => {
      dispatch(fileUploadsActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      documentUploadResponse?.errorMessage &&
      documentUploadResponse.errorMessage.length > 0
    ) {
      dispatch(fileUploadsActions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUploadResponse]);

  useEffect(() => {
    if (onSubmitProcess) {
      handleSubmit(submit, onError)();
      setOnSubmitProcess?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, onError, onSubmitProcess, setOnSubmitProcess, submit]);

  useEffect(() => {
    if (fileUploaded) {
      dispatch(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.DocumentUploaded,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploaded]);

  return (
    <Grid container>
      {documentUploadResponse?.errorMessage &&
        documentUploadResponse.errorMessage.length > 0 && (
          <Grid container item xs={12} mb={{ xs: '80px', sm: '60px' }}>
            <AMNSnackbar
              id="credential-submission-alert-bar"
              open={true}
              variant="error"
              message={documentUploadResponse.errorMessage}
            />
          </Grid>
        )}
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="system.midnightBlue" pb={2}>
          Add new or updated driver's license or passport.
        </Typography>
        <Typography variant="body1" color="system.grayText">
          Take a photo of the front of your driver's license or information page
          of your passport for review and verification.
        </Typography>
      </Grid>
      <Grid item xs={12} pt={4}>
        <FileUploadModal
          required
          id={'uploadCredential'}
          label="Add Identification"
          isSubmitted={isSubmitted}
          customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
          acceptedFileTypes={MAIN_FLOWS}
          onUploadedFile={() =>
            dispatch(credentialActions.resetDocumentUploadResponse())
          }
        />
      </Grid>
    </Grid>
  );
};
