export enum apiErrors {
  UserForEmailAddressNotFound = 1500,
  UserAccountUnapproved = 1600,
  UserAccountRejected = 1602,
  UserAccountUnlicensed = 1603,
  UserAccountUnactivated = 1610,
  UserAccountSuspended = 1612,
  UserAccountLocked = 1613,
  UserAccountPasswordExpired = 1614,
  UserAccountAwaitingPasswordReset = 1615,
  UserAccountPendingPassword = 1617,
  UserAccountSecurityQuestionsRequired = 1618,
  VerificationCodeNotFound = 1700,
  VerificationCodeIsNotValid = 1750,
  VerificationCodeFoundButIsInvalid = 1800,
  PasswordResetFailedGenericError = 1900,
  PasswordResetPasswordPolicyViolation = 1901,
  PasswordResetMustGenerateNewCode = 1902,
  AssignmentForUserIdAndPlacementIdNotFound = 2000,
  AssignmentForUserIdAndPlacementIdExistsConflict = 2050,
  AssignmentForUserIdAndPlacementIdActivityStatusTypeInvalid = 2075,
  CredentialsForUserIdAndPlacementIdNotFound = 2100,
  CredentialForUserIdAndPlacementIdExistsConflict = 2150,
  ContactForUserIdPlacementIdAndEmployeeIdNotFound = 2200,
  ContactForUserIdPlacementIdAndEmployeeRoleIdNotFound = 2225,
  ContactForUserIdPlacementIdAndEmployeeRoleIdExistsConflict = 2250,
  ContractForContractIdNotFound = 2300,
  ContractForUserIdAndContractIdNotFound = 2325,
  ContractForContractIdExistsConflict = 2350,
  ContractSignatureNotProvided = 2375,
  JobForJobIdNotFound = 3000,
  JobForJobIdExistsConflict = 3050,
  TimePayForIdAndUserIdNotFound = 4000,
  TimePayForUserIdStatusIdAndPayPeriodDatesNotFound = 4025,
  TimePayForUserIdStatusIdAndPayPeriodDatesExistsConflict = 4050,
  TimesheetBelowMinimumHours = 4100,
  TimesheetChargeHoursLimit = 4200,
  TimesheetPreceptorHoursLimit = 4201,
  TimesheetChargeHoursInvalid = 4250,
  TimesheetPreceptorHoursInvalid = 4251,
  TimesheetPerDayTotalTimeInvalid = 4260,
  TimesheetDuplicateTrcUnitId = 4261,
  TimesheetCheckInOutOverlapWorkPeriod = 4262,
  TimesheetCheckInOutOverlapBreakPeriod = 4263,
  UserProfileForUserIdNotFound = 5000,
  Unauthorized = 6000,
  JwtTokenInvalid = 6010,
  JwtTokenNotFound = 6050,
  UserIdMismatch = 6999,
  ResourceForUserIdNotFound = 9000,
  ResourceForProvidedRangeNotFound = 9025,
  ResourceForUserIdExistsConflict = 9050,
  DeviceRegistrationNotFound = 9075,
  CitiesNotFound = 9100,
  DisciplinesNotFound = 9125,
  FileNotProvided = 9200,
  EmailAlreadyExist = 9300,
  CosmosException = 9500,
  ValidationError = 9600,
  BadRequestError = 9700,
  DemoAccountFeatureUnavailable = 9800,
  DependentServiceError = 9900,
  InternalSystemError = 9999,
}
