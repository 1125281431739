import { RaceEthnicity } from 'enums/raceEthnicity';

export const raceEthnicityOptions = [
  {
    value: RaceEthnicity.AMERICAN_INDIAN_OR_ALASKAN_NATIVE,
    description: 'American Indian or Alaskan Native',
  },
  {
    value: RaceEthnicity.ASIAN,
    description: 'Asian',
  },
  {
    value: RaceEthnicity.BLACK_OR_AFRICAN_AMERICAN,
    description: 'Black or African American',
  },
  {
    value: RaceEthnicity.NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER,
    description: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    value: RaceEthnicity.WHITE,
    description: 'White',
  },
  {
    value: RaceEthnicity.TWO_OR_MORE,
    description: 'Two or More Races',
  },
];
