import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertBarType } from 'enums/alertBarTypes';
import { IAlertbarEmptyState, IAlertbarState } from './alertbarState';

export const emptyAlertState: IAlertbarEmptyState = {
  isOpen: false,
  variant: 'success',
  message: '',
  alertbarRefId: '',
  duration: 5000,
  closeONWindowClick: true,
  onClose: (fromAction?: any) => {},
  style: {},
};

const initialState: IAlertbarState = {
  [AlertBarType.APP_ALERT]: emptyAlertState,
};

const alertbarSlice = createSlice({
  name: 'alertbarSlice',
  initialState,
  reducers: {
    openAlert(state, action: PayloadAction<IAlertbarEmptyState>) {
      const refId =
        (action.payload.alertbarRefId ?? '') === ''
          ? AlertBarType.APP_ALERT
          : action.payload.alertbarRefId;

      const newState = { ...emptyAlertState };

      newState.isOpen = true;
      newState.message = action.payload.message;
      newState.variant = action.payload
        .variant as IAlertbarEmptyState['variant'];

      if (action.payload.hasOwnProperty('duration')) {
        newState.duration = action.payload
          .duration as IAlertbarEmptyState['duration'];
      } else {
        newState.duration = 5000;
      }

      if (action.payload.hasOwnProperty('closeONWindowClick')) {
        newState.closeONWindowClick = action.payload
          .closeONWindowClick as IAlertbarEmptyState['closeONWindowClick'];
      } else {
        newState.closeONWindowClick = true;
      }

      newState.alertbarRefId = refId as IAlertbarEmptyState['alertbarRefId'];

      if (action.payload.actionButton)
        newState.actionButton = action.payload.actionButton;

      if (action.payload.onClose) newState.onClose = action.payload.onClose;

      if (action.payload.style) newState.style = action.payload.style;

      state[refId as string] = { ...newState };
    },
    closeAlert(state, action?: PayloadAction<IAlertbarEmptyState | undefined>) {
      const refId =
        (action?.payload?.alertbarRefId ?? '') === ''
          ? AlertBarType.APP_ALERT
          : (action?.payload?.alertbarRefId as string);
      return {
        ...state,

        [refId]: { ...emptyAlertState },
      };
    },
  },
});

export const { openAlert, closeAlert } = alertbarSlice.actions;

export default alertbarSlice.reducer;
