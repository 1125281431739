import { Box, Typography } from '@mui/material';
import { ConfirmationPopup } from 'components/common/ConfirmationPopup/ConfirmationPopup';
import { IAssignmentContacts } from 'interfaces/Assignment/AssignmentSummaryItem';
import { IContact } from 'interfaces/User/Contact/IContact';
import { IPlacementContact } from 'interfaces/User/Contact/IPlacementContact';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { markPreferredRecruiter } from 'store/slices/user/userPreferenceSlice';
import { theme } from 'styles/theme';
import favouriteIcon from '../../assets/icons/favouriteIcon.svg';

export interface IPreferredRecruiter {
  contact: IContact | IPlacementContact | IAssignmentContacts;
  showPreferredBadge: boolean;
}

const messageContent = contact => {
  const recruiterName =
    ' ' + contact?.firstName + ' ' + contact?.lastName + ' ';
  return (
    <Typography>
      Would you like to make
      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          color: theme.palette.system.midnightBlue,
          letterSpacing: '0.25px',
          display: 'inline',
        }}
      >
        {recruiterName}
      </Typography>
      your preferred recruiter.
    </Typography>
  );
};

const PreferredRecruiter: React.FC<IPreferredRecruiter> = ({
  contact,
  showPreferredBadge,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const userPreferredRecruiterId = useAppSelector(
    state => state.userPreference.userPreference?.preferredRecruiter,
  );

  let isPreferredRecruiter = useMemo(
    () => (userPreferredRecruiterId === contact.employeeId ? true : false),
    [contact.employeeId, userPreferredRecruiterId],
  );

  const handleDrawerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setOpen(!open);
  };

  const handlePreferredRecruiter = () => {
    setOpen(false);
    dispatch(markPreferredRecruiter(contact.employeeId));
  };

  return showPreferredBadge ? (
    <div>
      {!isPreferredRecruiter ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ cursor: 'pointer', marginBottom: '12px' }}
          onClick={handleDrawerClick}
        >
          <Box>
            <img
              height="17px"
              width="17px"
              src={favouriteIcon}
              alt="mark-as-preferred"
            />
          </Box>
          <Typography
            variant="body1"
            color={theme.palette.system.skyBlue}
            ml={1}
          >
            Mark as Preferred
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: '100%',
            width: '90px',
            height: '27px',
            borderRadius: '5px',
            backgroundColor: 'rgba(0,154,217,0.2)',
            marginBottom: '12px',
          }}
          textAlign="center"
        >
          <Typography
            variant="body1"
            component="span"
            color="system.skyBlue"
            fontWeight="600"
            sx={{
              pt: '4px',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            Preferred
          </Typography>
        </Box>
      )}
      <ConfirmationPopup
        title="Preferred Recruiter"
        content={messageContent(contact)}
        isPanelOpen={open}
        isHandleProceedEnabled={true}
        handleClose={() => {
          setOpen(false);
        }}
        handleProceed={handlePreferredRecruiter}
        affirmative={'YES'}
        negative={'NO'}
      />
    </div>
  ) : (
    <></>
  );
};

export default PreferredRecruiter;
