import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { cancelCreateUserFlow } from 'store/slices/user/userLogin/userLoginSlice';
import { cancelVerification } from 'store/slices/verificationCode/verificationCodeSlice';

/**
 * Reset all the values to initial values in the store When cancelling the create Button
 */
export function ResetCreateAccountFlow(dispatch: Dispatch<AnyAction>) {
  dispatch(cancelCreateUserFlow());
  dispatch(cancelVerification());
}
