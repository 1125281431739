import { createAction, createSlice } from '@reduxjs/toolkit';
import { IPromotionState } from 'interfaces/Promotions/IPromotionState';
import { IPromotionsGetRequest } from 'interfaces/Promotions/IPromotionsGetRequest';

const initialState: IPromotionState = {
  promotions: {
    items: [],
    paging: {
      limit: '0',
      offset: '0',
      total: '0',
    },
  },
  success: false,
  selectedPromotionDetail: {
    actionText: '',
    actionUrl: '',
    description: '',
    header: '',
    id: '',
    image: '',
    isRead: false,
    subHeader: '',
  },
};

const getPromotions = createAction<IPromotionsGetRequest>('GET_PROMOTIONS');
const markPromotionsAsRead = createAction('UPDATE_PROMOTIONS_READ');

const promotionsSlice = createSlice({
  name: 'promotionsSlice',
  initialState,
  reducers: {
    setPromotions(state, action) {
      state.promotions = action.payload;
    },
    resetPromotions(state) {
      state.promotions = { ...initialState.promotions };
      state.success = initialState.success;
    },
    setPromotionDetail(state, action) {
      state.selectedPromotionDetail = action.payload;
    },
    resetPromotionDetail(state) {
      state.selectedPromotionDetail = initialState.selectedPromotionDetail;
    },
  },
});

export const promotionsActions = {
  getPromotions,
  markPromotionsAsRead,
  ...promotionsSlice.actions,
};

export default promotionsSlice.reducer;
