import { makeStyles } from '@mui/styles';

export const createLayoutStyles = makeStyles(theme => ({
  customScrollbar: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: '63px',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '0',
    },
    '&::-webkit-scrollbar': {
      //@TODO: Lets put this in the global styles so all scrollbars are styled the same
      width: '8px',
      height: '45%',
    },
    '&::-webkit-scrollbar-thumb': {
      //@TODO: Lets put this in the global styles so all scrollbars are styled the same
      backgroundColor: theme.palette.system.scrollBarColor,
      borderRight: '2px solid',
      borderRightColor: theme.palette.system.white,
      backgroundClip: 'padding-box',
      borderRadius: '25px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      margin: '20px',
    },
  },
  pageContent: {
    minHeight: `calc(100vh - 146px)`,
  },
}));
