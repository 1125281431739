import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfileMenuState } from './profileMenuState';

const initialState: IProfileMenuState = {
  isOpen: false,
};

const profileMenuSlice = createSlice({
  name: 'profileMenuSlice',
  initialState,
  reducers: {
    setProfileMenuState(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { setProfileMenuState } = profileMenuSlice.actions;

export default profileMenuSlice.reducer;
