import { UserTypeId } from 'enums/userTypeId';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { AlliedExpertise } from './AlliedExpertise';
import { NurseExpertise } from './NurseExpertise';

const ExpertiseForm = ({
  disciplineError,
  setDisciplineError,
  methods,
  setFormDirty,
}: {
  disciplineError: boolean;
  setDisciplineError: Function;
  methods: any;
  setFormDirty?: Function;
}) => {
  const userProfile: IUserProfile = useAppSelector(state => state.userProfile);

  return (
    <>
      {userProfile.userDisciplineTypeId === UserTypeId.NURSE && (
        <NurseExpertise methods={methods} setFormDirty={setFormDirty} />
      )}

      {userProfile.userDisciplineTypeId === UserTypeId.ALLIED && (
        <AlliedExpertise
          disciplineError={disciplineError}
          setDisciplineError={setDisciplineError}
          setFormDirty={setFormDirty}
        />
      )}
    </>
  );
};

export default ExpertiseForm;
