import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Calendar from 'assets/icons/Calendar.svg';
import Clock from 'assets/icons/Clock.svg';
import Flag from 'assets/icons/Flag.svg';
import Map from 'assets/icons/Map-Pinpoint.svg';
import Travel from 'assets/icons/Travel-Jobs.svg';
import { ApplyJobCard } from 'components/Jobs/ApplyJob/ApplyJobCard';
import CredentialStatus from 'components/Jobs/CredentialStatus';
import { JobShare } from 'components/Jobs/JobDetails/JobShare';
import { TextWithUnderline } from 'components/Jobs/JobDetails/TextWithUnderline';
import SimilarJobs from 'components/SimilarJobs/SimilarJobs';
import SkeletonSimilarJobs from 'components/SimilarJobs/SkeletonSimilarJobs';
import { JobBadge } from 'components/common/JobBadge/JobBadge';
import { AppLink } from 'components/common/Link/Link';
import {
  useMobileMediaQuery,
  useSmMediaQuery,
} from 'containers/MobileDesktopContainer/useMediaQuery';
import domPurify from 'dompurify';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { getMMMddyyyy } from 'helpers/dateFormatter';
import { IACSJobDetails } from 'interfaces/Jobs/IACSJobDetails';
import { IApplyJobRequest } from 'interfaces/Jobs/IApplyJobRequest';
import { IACSJobDetailsPage } from 'interfaces/Jobs/IJobDetailsPage';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import { IJobsData } from 'interfaces/Jobs/IJobsData';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { getJobBadgeInformationByTags } from 'services/jobs/jobUIService';
import { trackEvent } from 'services/logging/appInsights';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { applyJob } from 'store/slices/jobs/ApplyJobsSlice';
import { fetchJobs } from 'store/slices/jobs/jobsSlice';
import { IJobState } from 'store/slices/jobs/jobsState';
import { similarJobsActions } from 'store/slices/jobs/similarJobsSlice';
import { contactActions } from 'store/slices/user/userContact/userContactSlice';
import { IContactState } from 'store/slices/user/userContact/userContactState';
import { theme } from 'styles/theme';
import defaultFacilityImage from '../../../../assets/images/facilityImageNotFound.jpg';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { CONFIDENTIAL_LOCATION } from 'constants/jobFilter';

enum JobDetailSections {
  JOB_DETAILS = 'job-details-section',
  REQUIREMENTS = 'requirements-section',
  BENEFITS = 'benefits-section',
  ABOUT = 'about-section',
  SIMILAR_JOBS = 'similar-jobs-section',
}

export const CognitiveJobDetailsMobile: React.FC<IACSJobDetailsPage> = ({
  classes,
  onCardClick,
  isCardExpanded = true,
  showClose = false,
  onCloseClick,
  showMap,
  isNotStrikeFilter = true,
  jobDetailsData,
  isFromNonConsecutiveDrawer,
}: {
  classes?: any;
  onCardClick?: () => void;
  isCardExpanded?: boolean;
  showClose?: boolean;
  onCloseClick?: () => void;
  showMap?: boolean;
  isNotStrikeFilter?: boolean;
  jobDetailsData: IACSJobDetails;
  isFromNonConsecutiveDrawer?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const pageContent = isFromNonConsecutiveDrawer
    ? document.getElementById('drawerContent')
    : document.getElementById('pageContentId');

  // height of header + sticky horizontal scroll bar
  const upperOffset = 120;

  const userStatusTypeId = useAppSelector(state => {
    return state.userProfile.userStatusTypeId;
  }) as number;
  const userContact = useAppSelector(state => {
    return state.userContact;
  }) as IContactState;
  const userId = useAppSelector(state => state.auth.userId);
  const jobsData = useAppSelector(state => {
    return state.jobs;
  }) as IJobState;
  const similarJobsData = useAppSelector(
    state => state.similarJobs.similarJobsData,
  ) as IJobsData[];
  const jobSearchFilter = useAppSelector(state => {
    return state.jobs.jobSearchFilter;
  }) as IJobSearchRequestSlice;

  const requestFilter = useAppSelector(state => state?.jobsV2?.requestFilter);

  // const jobDetails = useAppSelector(state => state.jobsV2.selectedJobDetails);
  // const { jobId } = useParams<JobDetailsParams>();

  const [isSticky, setIsSticky] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState<string>(
    JobDetailSections.JOB_DETAILS,
  );
  const [, setSelectedRecruiters] = useState<number[]>([]);

  const similarJobsRef = useRef<HTMLDivElement>(null);

  const { promiseInProgress: similarJobsLoading } = usePromiseTracker({
    area: 'get-similar-jobs',
    delay: 0,
  });

  const isStrike = jobDetailsData?.jobType === 'Strike';

  const tags = jobDetailsData.tags ? jobDetailsData.tags[0] : '';
  const badgeDetails = getJobBadgeInformationByTags(tags ?? 0, isStrike);

  const startDate = getMMMddyyyy(
    jobDetailsData?.startDate?.toString() === undefined
      ? ''
      : jobDetailsData?.startDate?.toString(),
  );

  const refreshJob = () => {
    dispatch(cognitiveJobActions.setAppliedDateForJob());
  };

  const sanitizedHTMLBenefits: any = domPurify.sanitize(
    jobDetailsData?.jobBenefits === undefined
      ? ''
      : jobDetailsData?.jobBenefits,
  );
  const benefits: any = { __html: sanitizedHTMLBenefits.toString() };

  const sanitizedHTMLCompany: any = domPurify.sanitize(
    jobDetailsData?.divisionCompany.aboutCompany === undefined
      ? ''
      : jobDetailsData?.divisionCompany.aboutCompany,
  );
  const company: any = { __html: sanitizedHTMLCompany.toString() };

  const sanitizedHTMLRequirements: any = domPurify.sanitize(
    jobDetailsData?.requiredQualifications === undefined
      ? ''
      : jobDetailsData?.requiredQualifications,
  );
  const requirements: any = { __html: sanitizedHTMLRequirements.toString() };

  const sanitizedHTMLPreferredRequirements: any = domPurify.sanitize(
    jobDetailsData?.preferredQualifications === undefined
      ? ''
      : jobDetailsData?.preferredQualifications,
  );
  const preferredRequirements: any = {
    __html: sanitizedHTMLPreferredRequirements.toString(),
  };

  const job = jobsData?.items?.filter(
    j => j.jobId.toString() === jobDetailsData?.jobID,
  );

  const jobData = job ? (job[0] as IJobsData) : null;

  const address1 = jobDetailsData?.organization?.address1;
  const address2 = jobDetailsData?.organization?.address2;
  const city = jobDetailsData?.city?.name;
  const state = jobDetailsData?.state?.name;
  const zip = jobDetailsData?.organization?.zip;

  const onBackButtonClick = () => {
    onCloseClick?.();
    if (isNotStrikeFilter) {
      dispatch(fetchJobs());
    }
    resetCurrentSort();
  };

  const resetCurrentSort = useCallback(() => {
    dispatch(cognitiveJobActions.setCurrentSort(requestFilter?.orderBy));
  }, [requestFilter, requestFilter?.orderBy]);

  const handleScrollSimilarJobsIntoView = () => {
    similarJobsRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const debouncedSetActiveTabIndex = debounce(id => {
    setActiveTabIndex(prev => id);
  }, 400);

  const rate = useMemo(() => {
    let payRate = '$***';
    let maxPayRate = (jobDetailsData?.payRate.maxPayRate || '').toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );
    let minPayRate = (jobDetailsData?.payRate.minPayRate || '').toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );

    if (
      Math.round(jobDetailsData?.payRate.maxPayRate || -1) !== 0 &&
      Math.round(jobDetailsData?.payRate.minPayRate || -1) !== 0 &&
      minPayRate !== maxPayRate
    ) {
      payRate = `${minPayRate} - ${maxPayRate}`;
    } else if (Math.round(jobDetailsData?.payRate.maxPayRate || -1) !== 0) {
      payRate = maxPayRate;
    } else {
      payRate = minPayRate;
    }

    return payRate;
  }, [jobDetailsData?.payRate.maxPayRate, jobDetailsData?.payRate.minPayRate]);

  const postedDay = useMemo(() => {
    let days = 0;
    let postedDays = '';

    const postedDate = moment(jobDetailsData?.datePosted || '');
    days = moment().diff(postedDate, 'days');

    if (days === 1) {
      postedDays = `Posted ${days} Day ago`;
    } else if (days < 1) {
      days = moment().diff(postedDate, 'hours');
      postedDays = `Posted ${days} Hours ago`;
    } else {
      postedDays = `Posted ${days} Days ago`;
    }
    return postedDays;
  }, [jobDetailsData?.datePosted]);

  const handleSectionClick = useCallback(
    (id: string) => {
      const sectionElement = document.getElementById(id);

      const elementPosition = sectionElement?.getBoundingClientRect().top;
      if (!elementPosition) return;
      const offsetPosition =
        elementPosition + pageContent?.scrollTop! - upperOffset;

      pageContent?.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setActiveTabIndex(prev => id);
    },
    [pageContent],
  );

  const addressCoordinates = useMemo(() => {
    const latitude = jobDetailsData?.location?.coordinates[0];
    const longitude = jobDetailsData?.location?.coordinates[1];
    return `${latitude},${longitude}`;
  }, [jobDetailsData]);

  const address = useMemo(() => {
    return `${address1}${address2 ? ' ' + address2 : ''}${
      city ? ', ' + city : ''
    }${state ? ', ' + state : ''} ${zip}`;
  }, [address1, address2, city, state, zip]);

  const hasJobDetails = useMemo(() => {
    return isStrike ? true : jobDetailsData?.descriptionLong;
  }, [isStrike, jobDetailsData?.descriptionLong]);

  const similarJobs = useMemo(() => {
    return (
      <div ref={similarJobsRef} style={{ width: '100%' }}>
        {similarJobsLoading ? (
          <SkeletonSimilarJobs />
        ) : similarJobsData.length > 0 ? (
          <SimilarJobs similarJobs={similarJobsData} />
        ) : null}
      </div>
    );
  }, [similarJobsData, similarJobsLoading]);

  const sectionDetails = useMemo(() => {
    let details: any = [];

    if (hasJobDetails) {
      details.push({
        description: 'Job Details',
        id: JobDetailSections.JOB_DETAILS,
      });
    }
    if (jobDetailsData?.requiredQualifications) {
      details.push({
        description: 'Requirements',
        id: JobDetailSections.REQUIREMENTS,
      });
    }
    if (jobDetailsData?.jobBenefits) {
      details.push({
        description: 'Benefits',
        id: JobDetailSections.BENEFITS,
      });
    }
    if (jobDetailsData?.divisionCompany.aboutCompany) {
      details.push({
        description: 'About',
        id: JobDetailSections.ABOUT,
      });
    }
    if (similarJobsData?.length > 0) {
      details.push({
        description: 'Similar Jobs',
        id: JobDetailSections.SIMILAR_JOBS,
      });
    }

    return details;
  }, [
    hasJobDetails,
    jobDetailsData?.requiredQualifications,
    jobDetailsData?.jobBenefits,
    jobDetailsData?.divisionCompany.aboutCompany,
    similarJobsData?.length,
  ]);

  const horizontalScroll = useMemo(() => {
    return (
      <Box
        display="flex"
        sx={{
          height: isSticky ? '46px' : '34px',
          columnGap: '12px',
          paddingTop: isSticky ? '12px' : '0px',
          width: '100%',
        }}
        flexDirection="row"
      >
        {sectionDetails.map((section, idx) => {
          return (
            <TextWithUnderline
              key={`${section.description}-${idx}`}
              isActive={activeTabIndex === section.id}
              onClickCallback={() => handleSectionClick(section.id)}
            >
              {section.description}
            </TextWithUnderline>
          );
        })}
      </Box>
    );
  }, [isSticky, activeTabIndex, sectionDetails, handleSectionClick]);

  const handleSectionScroll = useCallback(() => {
    sectionDetails.forEach(section => {
      const element = document.getElementById(section.id);
      if (!element || !pageContent) return;
      const sectionTop = element.offsetTop - upperOffset;
      // add a range to avoid issue with floating point precision that is evident when using ===
      if (
        pageContent.scrollTop >= sectionTop - 5 &&
        pageContent.scrollTop <= sectionTop + 5
      ) {
        debouncedSetActiveTabIndex(section.id);
      }
    });
  }, [pageContent, sectionDetails, debouncedSetActiveTabIndex]);

  useEffect(
    () => {
      if (
        !userContact ||
        userContact.userContacts?.userContacts?.length === 0
      ) {
        dispatch(contactActions.fetchUserContacts(userId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobDetailsData?.payRate.maxPayRate, jobDetailsData?.payRate.minPayRate],
  );

  useEffect(() => {
    if (!isFromNonConsecutiveDrawer) {
      let header = document.getElementById('header');
      if (!header) return;
      header.style.display = 'none';
      return () => {
        if (header) {
          header.style.display = 'flex';
        }
      };
    }
  }, [isFromNonConsecutiveDrawer]);

  useEffect(() => {
    if (jobDetailsData?.jobID && userId && !isFromNonConsecutiveDrawer) {
      dispatch(
        similarJobsActions.fetchSimilarJobsAction({
          userId: userId,
          jobId: +jobDetailsData.jobID,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDetailsData.jobID, userId]);

  useEffect(
    () => {
      setActiveTabIndex(sectionDetails[0].id);
      const sectionElement = document.getElementById('sticky-header');
      if (!sectionElement || !pageContent) return;

      const sectionOffsetTop = sectionElement.offsetTop - 70;

      pageContent?.addEventListener('scroll', event => {
        setIsSticky(pageContent.scrollTop >= sectionOffsetTop);
      });

      pageContent?.addEventListener('scroll', handleSectionScroll);

      return () => {
        pageContent?.removeEventListener('scroll', e => {});
        pageContent?.removeEventListener('scroll', handleSectionScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isMobileDevice = useMobileMediaQuery();
  const isTabletDevice = useSmMediaQuery();

  const isWebDevices = !isMobileDevice && !isTabletDevice;

  const handleApplyJob = (recruiters: number[] | []) => {
    let recruiterArray: number[] | [] = [];
    if (Array.isArray(recruiters) && recruiters.length > 0) {
      recruiterArray = recruiters;
    }
    let request = {
      jobId: jobDetailsData?.jobID,
      userId: userId,
      matchRanking: jobData?.matchRanking,
      recruiters: recruiterArray,
      searchSortTypeId: jobSearchFilter.sort,
    } as IApplyJobRequest;

    dispatch(
      applyJob({
        applyJob: { ...request },
        hasApplied: false,
        isLoading: false,
        externalLinkDrawerForStrikeJobApply: false,
        isStrike,
        strikeJobSignUpUrl: '',
      }),
    );
    dispatch(cognitiveJobActions.setAppliedDateForJob());

    if (showMap) {
      trackEvent('ApplyJob_MapView', {
        userId: `${userId}`,
        jobId: `${jobDetailsData?.jobID}`,
      });
    }
  };

  return (
    <>
      <Grid container id="job-details-mobile">
        <Grid
          container
          item
          xs={12}
          sx={{
            padding: '0px 32px 0px 24px',
            backgroundColor: theme.palette.system.white,
            position: isWebDevices ? 'absolute' : 'fixed',
            height: '72px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.system.border,
            zIndex: theme.zIndex.appBar,
            flexFlow: 'nowrap',
          }}
        >
          <Grid item xs={1} display="flex" justifyContent="center">
            <Button
              id="Close-JobDetailPage"
              variant="text"
              size="small"
              onClick={onBackButtonClick}
              startIcon={
                <ArrowBackIosNewIcon
                  sx={{
                    color: 'system.coolGray',
                    height: '22px',
                    width: '22px',
                  }}
                />
              }
              sx={{
                padding: '0px 0px',
              }}
            />
          </Grid>
          <Grid item xs={10} display="flex" justifyContent="center">
            <Typography
              role="job-title"
              aria-label="job-title"
              variant="subtitle1"
              color={theme.palette.system.midnightBlue}
              textAlign="center"
              noWrap
              lineHeight="24px"
              sx={{
                maxWidth: '228px',
                textOverflow: 'ellipsis',
              }}
            >
              {jobDetailsData?.jobTitle}
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            {jobDetailsData?.jobID && (
              <JobShare
                jobId={jobDetailsData?.jobID}
                shareTitle={`${jobDetailsData?.disciplineSpecialty.disciplineName} - ${jobDetailsData?.disciplineSpecialty.specialtyName}`}
              />
            )}
          </Grid>
          <Divider />
        </Grid>

        <Grid
          container
          item
          p={4}
          gap={2}
          mt={'70px'}
          sx={{
            top: '70px !important',
            backgroundColor: theme.palette.system.white,
          }}
        >
          <Grid
            container
            item
            gap={4}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ flexWrap: 'nowrap' }}
          >
            <Grid item xs={3}>
              <Box
                className="card-55img"
                height={132}
                sx={{
                  backgroundImage: `url(${defaultFacilityImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '125px',
                  borderRadius: '8px',
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Grid container gap={2}>
                <Grid
                  container
                  item
                  display="flex"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <JobBadge
                      name={badgeDetails.name}
                      src={badgeDetails.src}
                      color={badgeDetails.color}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <span className="card-tray">{postedDay}</span>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    color={theme.palette.system.coolGray}
                  >
                    ID:
                    <Typography
                      component="span"
                      color={theme.palette.system.coolGray}
                      variant="body1"
                      fontWeight="600"
                    >
                      {` ${jobDetailsData?.sourceJobID}`}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {(jobDetailsData?.payRate?.maxPayRate ?? 0) > 0 ? (
                    <Typography
                      variant="h4"
                      fontWeight="600"
                      component="div"
                      color={theme.palette.system.grassGreen}
                    >
                      {rate}
                      <Typography
                        variant="body1"
                        component="span"
                        color={theme.palette.system.coolGray}
                      >
                        {isStrike ? ' per hour' : ' per week*'}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      color={theme.palette.system.coolGray}
                    >
                      Contact for Details
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              flexWrap="wrap"
              color={theme.palette.system.subTitleBlack}
            >
              {jobDetailsData?.jobTitle}
            </Typography>
          </Grid>
          {jobDetailsData?.organization?.name && (
            <Typography
              variant="subtitle1"
              color="system.coolGray"
              fontSize="14px"
              marginBottom="7px"
              data-testid="facility-name"
            >
              {jobDetailsData?.organization?.name}
            </Typography>
          )}

          {!isStrike ? (
            address && address?.trim()?.length > 0 ? (
              <Grid item xs={12} display="flex">
                <AppLink
                  component="a"
                  target="_blank"
                  rel="noopener"
                  href={`https://www.google.com/maps/place/${address}/@${addressCoordinates}`}
                  id="map-icon-link"
                  linkStyles={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img height="17px" src={Map} alt="Map Icon" />
                </AppLink>
                <AppLink
                  component="a"
                  target="_blank"
                  rel="noopener"
                  href={`https://www.google.com/maps/place/${address}/@${addressCoordinates}`}
                  id="map-address-link"
                  underline="none"
                  linkStyles={{
                    color: 'system.skyBlue',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    paddingLeft: '8px',
                  }}
                >
                  {address}
                </AppLink>
              </Grid>
            ) : (
              <Grid item xs={12} display="flex">
                <Typography
                  variant="subtitle1"
                  color="system.subTitleBlack"
                  data-testid="confidencial-location"
                >
                  {CONFIDENTIAL_LOCATION}
                </Typography>
              </Grid>
            )
          ) : (
            <></>
          )}
          <Grid container item xs={12} display="flex" flexDirection="row">
            <Grid item display="flex" alignItems="center">
              <img height="13px" src={Flag} alt="Flag" />
              <Typography
                variant="body1"
                color={theme.palette.system.coolGray}
                sx={{ paddingLeft: '8px', lineHeight: '19px' }}
              >
                {!isStrike && <span>{startDate}</span>}
                {isStrike && <span>Start Date TBD</span>}
              </Typography>
            </Grid>

            {/* Uncomment when we have solution for MRH */}
            {/* {!isStrike && jobDetails?.minimumRequiredHours && (
              <Grid item display="flex" alignItems="center" pl={3}>
                <img height="14px" src={Hours} alt="Hour Glass" />
                <Typography
                  variant="body1"
                  color={theme.palette.system.coolGray}
                >
                  <span style={{ paddingLeft: '8px' }}>
                    {jobDetails?.minimumRequiredHours} Hrs/wk
                  </span>
                </Typography>
              </Grid>
            )} */}
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {userStatusTypeId === UserStatusTypeId.ACTIVE && (
              <CredentialStatus jobId={jobDetailsData?.jobID || ''} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Divider />
              <Grid
                container
                id="job-description"
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
              >
                {!isStrike ? (
                  <>
                    {(jobDetailsData?.durationInt ?? 0) > 0 && (
                      <Grid
                        item
                        xs={4}
                        paddingTop={2}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <img height="15px" src={Calendar} alt="Calendar" />
                        <Typography
                          variant="body1"
                          color={theme.palette.system.coolGray}
                          lineHeight="19px"
                          sx={{ paddingLeft: '6px' }}
                        >
                          {jobDetailsData?.duration} weeks
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={4}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Travel} alt="Travel" />{' '}
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        {jobDetailsData?.jobType}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Clock} alt="Clock" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        {jobDetailsData?.shift ? jobDetailsData?.shift : 'N/A'}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="17px" src={Clock} alt="Clock" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        Variable
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Travel} alt="Travel" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        Strike
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider sx={{ paddingTop: '12px' }} />
            </Box>
          </Grid>
          <>
            <Grid
              id="sticky-header"
              item
              sx={{
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                position: isSticky ? 'sticky' : 'inherit',
                top: isSticky ? '72px' : 'inherit',
                zIndex: 1,
                width: '100%',
                backgroundColor: theme.palette.system.white,
              }}
            >
              {horizontalScroll}
            </Grid>

            <Grid container item gap={1}>
              {hasJobDetails && (
                <>
                  <Grid item xs={12} id={JobDetailSections.JOB_DETAILS}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      Job Details
                    </Typography>
                  </Grid>

                  <Grid container item pb={3}>
                    <Grid item>
                      <Typography color={theme.palette.system.coolGray}>
                        {jobDetailsData?.descriptionLong}
                      </Typography>
                    </Grid>

                    {isStrike && (
                      <Grid item pt={jobDetailsData?.descriptionLong ? 4 : 0}>
                        <Typography color={theme.palette.system.coolGray}>
                          Pay and dates will be finalized once an offer is
                          extended. Clinicians must be flexible on shift.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}

              {(jobDetailsData?.preferredQualifications ||
                jobDetailsData?.requiredQualifications) && (
                <>
                  <Grid item xs={12} id={JobDetailSections.REQUIREMENTS}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      Requirements
                    </Typography>
                  </Grid>
                  {jobDetailsData?.requiredQualifications && (
                    <Grid item xs={12} pb={3}>
                      <Typography
                        color={theme.palette.system.coolGray}
                        dangerouslySetInnerHTML={requirements}
                      />
                    </Grid>
                  )}
                  {jobDetailsData?.preferredQualifications && (
                    <>
                      <Typography
                        id="preferred-job-requirements"
                        marginBottom="6px"
                      >
                        Preferred Requirements
                      </Typography>
                      <Typography
                        color="system.coolGray"
                        marginBottom="12px"
                        dangerouslySetInnerHTML={preferredRequirements}
                        whiteSpace="pre-line"
                      ></Typography>
                    </>
                  )}
                </>
              )}

              {jobDetailsData?.jobBenefits && (
                <>
                  <Grid item xs={12} id={JobDetailSections.BENEFITS}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      Job Benefits
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pb={3}>
                    {!isStrike ? (
                      <Typography
                        color="system.coolGray"
                        dangerouslySetInnerHTML={benefits}
                      ></Typography>
                    ) : (
                      <Typography color="system.coolGray">
                        Not Included
                      </Typography>
                    )}
                  </Grid>
                </>
              )}

              {jobDetailsData?.divisionCompany && (
                <>
                  <Grid item xs={12} id={JobDetailSections.ABOUT}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      About the Company
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!isStrike ? (
                      <Typography
                        color="system.coolGray"
                        marginBottom="12px"
                        dangerouslySetInnerHTML={company}
                      ></Typography>
                    ) : (
                      <Typography color="system.coolGray" marginBottom="12px">
                        AMN Healthcare is a leading strike nursing staffing
                        agency that places skilled health professionals in
                        quality positions across the United States. We have
                        become an industry leader by providing a comprehensive
                        service that ensures health professionals a safe and
                        rewarding journey. Our extensive experience gives us the
                        unique ability to understand and meet a wide variety of
                        staffing needs.
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
              {!isStrike && (
                <Typography
                  variant="body1"
                  lineHeight="20px"
                  sx={{ fontStyle: 'italic' }}
                  color={theme.palette.system.coolGray}
                >
                  {
                    '*Estimate of weekly payments is intended for informational purposes and includes hourly wages, as well as reimbursements for meal & incidental expenses and housing expenses incurred on behalf of the Company. Please speak with a recruiter for additional details.'
                  }
                </Typography>
              )}
            </Grid>
          </>
        </Grid>
        {!isFromNonConsecutiveDrawer && (
          <>
            <Grid
              id={JobDetailSections.SIMILAR_JOBS}
              container
              item
              xs={12}
              sx={{
                backgroundColor: `${theme.palette.system.inputBackground}`,
                padding: '24px',
                zIndex: 0,
              }}
            >
              {similarJobs}
            </Grid>
            <Grid container item>
              <Grid
                item
                container
                xs={12}
                sx={{
                  backgroundColor: theme.palette.system.white,
                  position: 'absolute',
                  bottom: 0,
                }}
              >
                <ApplyJobCard
                  id="apply-job-mobile"
                  handleApplyJob={handleApplyJob}
                  setSelectedRecruiters={setSelectedRecruiters}
                  isMFS={jobDetailsData?.isMFS}
                  appliedDate={jobDetailsData?.appliedDate}
                  jobId={(jobDetailsData?.jobID ?? '').toString()}
                  isStrike={isStrike}
                  isExpanded={true}
                  secondaryAction={refreshJob}
                  similarJobs={similarJobsData?.length > 0}
                  onScrollIntoView={handleScrollSimilarJobsIntoView}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
