import { theme } from 'styles/theme';
import { styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { desktopWidthBreakpoint } from 'styles/media';
import { IDrawerPanelProps } from 'interfaces/Drawer/IDrawerProps';

export const DrawerWidth = 448;

const FooterBackdrop = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: theme?.zIndex?.drawer + 1,
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(10px)',
  '@supports (-moz-appearance:none)': {
    backgroundColor: 'rgba(255, 255, 255, .95)',
    WebkitFilter: 'blur(10px)',
    msFilter: 'blur(10px)',
  },
  [theme?.breakpoints?.down('md')]: {
    backgroundColor: 'rgba(255, 255, 255)',
    boxShadow: `0px -1px 15px ${theme?.palette?.system?.scrollBarColor}`,
    filter: 'none',
    backdropFilter: 'none',
  },
});

const DrawerStyles = makeStyles(theme => ({
  buttonFooter: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: theme?.zIndex?.drawer,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    paddingBottom: '21px',
    [theme?.breakpoints?.down(desktopWidthBreakpoint)]: {
      justifyContent: 'center',
    },
  },
  buttonFooterBlurBackdropWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
  },
  cancelButton: {
    [theme?.breakpoints?.down(desktopWidthBreakpoint)]: {
      display: 'none',
    },
  },
  closeIconWrapper: {
    cursor: 'pointer',
    transform: 'scale(0.4)',
    paddingRight: '24px',
  },
  drawerContent: {
    '&::-webkit-scrollbar': {
      //@TODO: Lets put this in the global styles so all scrollbars are styled the same
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      //@TODO: Lets put this in the global styles so all scrollbars are styled the same
      backgroundColor: theme?.palette?.system?.scrollBarColor,
      borderRight: '2px solid',
      borderRightColor: '#FFFFFF00',
      backgroundClip: 'padding-box',
      borderRadius: '25px',
      width: '20px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
      margin: '14px',
      marginBottom: '120px',
    },
    maxHeight: 'calc(100% - 50px)',
    overflowY: 'scroll',
    padding: '24px',
    paddingBottom: (props: Partial<IDrawerPanelProps>) => {
      if (props.showFooter) return '100px';

      return '24px';
    },
  },
  drawerHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: theme?.palette?.system?.border,
  },

  drawerBackHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderBottomColor: theme?.palette?.system?.border,
    '& h4': {
      width: (props: Partial<IDrawerPanelProps>) => {
        if (props.centerTitle) return '100%';
      },
      textAlign: (props: Partial<IDrawerPanelProps>) => {
        if (props.centerTitle) return 'center';
      },
      margin: (props: Partial<IDrawerPanelProps>) => {
        if (props.centerTitle) return '0';
      },
    },
  },
  layout: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
    [theme?.breakpoints?.up(desktopWidthBreakpoint)]: {
      width: DrawerWidth,
    },
    [theme?.breakpoints?.down(desktopWidthBreakpoint)]: {
      width: '100vw !important', // Makes drawer full width regardless of passed in css for other media's
    },
  },
}));

export { FooterBackdrop, DrawerStyles };
