import { Grid } from '@mui/material';
import { AppAlertbar } from 'components/common/Alert/AppAlertbar';
import { FeaturedContent } from 'components/FeaturedContent/FeaturedContent';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { Layout } from 'components/Layout/Layout';
import { PersonalInformation } from 'components/PersonalInformation/PersonalInformation';
import { FooterTermsOfService } from 'components/SiteFooter/FooterTermsOfService';
import { Sso } from 'components/SSO/Sso';
import { AlertBarType } from 'enums/alertBarTypes';
import { FeatureToggles } from 'enums/featureToggles';
import { guidGenerator } from 'helpers/guidGenerator';
import { AssignmentsDetails } from 'pages/AssignmentsDetail/Loadable';
import { CommunityBlogPostPage, CommunityPage } from 'pages/Community/Loadable';
import { ContactsPage } from 'pages/ContactsPage/Loadable';
import { ContentNotFound } from 'pages/ContentNotFound/Loadable';
import { CreatePassword } from 'pages/CreateAccount/CreatePassword/CreatePassword';
import { EmailVerification } from 'pages/CreateAccount/EmailVerification/EmailVerification';
import { CreateAccountPage } from 'pages/CreateAccount/Loadable';
import { FaqPage } from 'pages/Faq/Loadable';
import { HearFromCommunityPage } from 'pages/HearFromCommunity/Loadable';
import { HomePage } from 'pages/HomePage/Loadable';
import { JobSearchPage } from 'pages/JobSearch/JobSearchPage';
import { JobShareDetail } from 'pages/JobSearch/JobShareDetail';
import { ACSJobSearchPage, ACSJobShareDetail } from 'pages/JobSearch/Loadable';
import RecommendedJobsPage from 'pages/JobSearch/RecommendedJobsPage';
import { EmailValidation } from 'pages/LoginPage/EmailValidation/Loadable';
import { SignIn } from 'pages/LoginPage/SignIn/Loadable';
import { NotificationsPage } from 'pages/Notifications/Loadable';
import { OneloginSSOPage } from 'pages/OneloginSSOPage';
import { PayrollPage } from 'pages/Payroll/Loadable';
import { PDFViewerPage } from 'pages/PDFViewer/PDFViewerPage';
import { PreferencesPage } from 'pages/Preferences/Loadable';
import { GenerateCVPdfViewer } from 'pages/ProfilePage/GenerateCV/GenerateCVPdfViewer';
import { ProfilePage } from 'pages/ProfilePage/Loadable';
import { PromotionsPage } from 'pages/Promotions/Loadable';
import { ReferralPage } from 'pages/Refer/Loadable';
import { ReimbursementPage } from 'pages/Reimbursement/Loadable';
import { ResetPassword } from 'pages/ResetPasswordPage/Loadable';
import { ResourcesPage } from 'pages/Resources/Loadable';
import { ScreenShareContentNotFound } from 'pages/ScreenShareContentNotFound/Loadable';
import { SignInHelpPage } from 'pages/SignInHelpPage/Loadable';
import { SkillsChecklistPage } from 'pages/SkillsChecklist/Loadable';
import { SupportCenterPage } from 'pages/SupportCenter/Loadable';
import { TaskCenterPage } from 'pages/TaskCenter/TaskCenterPage';
import { TermsAndConditions } from 'pages/TermsAndConditions/Loadable';
import { TimeEntryPage } from 'pages/TimeEntry/Loadable';
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { appInsights } from 'services/logging/appInsights';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authenticationSelector } from 'store/selectors/authSelector';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import { jobShareActions } from 'store/slices/jobs/jobShareSlice';
import { fetchState } from 'store/slices/lookups/lookupsSlice';
import { retrieveUserPreference } from 'store/slices/user/userPreferenceSlice';
import { GlobalStyle } from 'styles/global-styles';
import { theme } from 'styles/theme';
import { useCobrowse } from 'utils/hooks/useCobrowse';
import { isLocumsSelector } from 'store/selectors/authSelector';

export function App() {
  const dispatch = useAppDispatch();
  const { start, cobrowsing } = useCobrowse();

  const isAuthenticated: boolean = useAppSelector(authenticationSelector);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [activePage, setActivePage] = useState<string>('');
  const shareOriginUrl: string = useAppSelector(
    state => state.jobShare.originUrl,
  );
  const userProfile = useAppSelector(state => state.userProfile);

  const unprotectedRoutes = {
    '': SignIn,
    'create-account': CreateAccountPage,
    'create-password': CreatePassword,
    'email-validation': EmailValidation,
    'email-verification': EmailVerification,
    'reset-password': ResetPassword,
    'sign-in-help': SignInHelpPage,
    'terms-and-conditions': TermsAndConditions,
  };
  const unprotectedIndependentRoutes = {
    'profile-resume': GenerateCVPdfViewer,
    kaiser: Sso,
    'terms-of-service': FooterTermsOfService,
    pdf: PDFViewerPage,
  };
  const isACSJobSearch = useFeatureToggle(FeatureToggles.ENABLE_ACS_JOB_SEARCH);
  const enabledScreenShare = useFeatureToggle(
    FeatureToggles.ENABLE_SCREEN_SHARE,
  );
  let isLocums: boolean = useAppSelector(isLocumsSelector);
  let protectedRoutes = isLocums
    ? {
        reimbursements: ReimbursementPage,
        'time-entry': TimeEntryPage,
        onelogin: OneloginSSOPage,
        'content-not-found': ContentNotFound,
      }
    : {
        assignments: AssignmentsDetails,
        featured: FeaturedContent,
        home: HomePage,
        personal: PersonalInformation,
        profile: ProfilePage,
        jobs: isACSJobSearch ? ACSJobSearchPage : JobSearchPage,
        'jobs/:jobId': isACSJobSearch ? ACSJobShareDetail : JobShareDetail,
        resources: ResourcesPage,
        'skills-checklists': SkillsChecklistPage,
        'skills-checklists/:checklistId': SkillsChecklistPage,
        reimbursements: ReimbursementPage,
        'task-center': TaskCenterPage,
        contacts: ContactsPage,
        'time-entry': TimeEntryPage,
        'help-center': FaqPage,
        preferences: PreferencesPage,
        onelogin: OneloginSSOPage,
        'refer-a-clinician': ReferralPage,
        notifications: NotificationsPage,
        cases: SupportCenterPage,
        community: CommunityPage,
        'community/:postId': CommunityBlogPostPage,
        'hear-from-your-community': HearFromCommunityPage,
        'hear-from-your-community/:videoId': HearFromCommunityPage,
        promotions: PromotionsPage,
        'content-not-found': ContentNotFound,
        payroll: PayrollPage,
        'help-center/screen-share': enabledScreenShare
          ? FaqPage
          : ScreenShareContentNotFound,
        'recommended-jobs': RecommendedJobsPage,
      };

  const routeToAlertRefMap = useMemo(
    () => ({
      jobs: AlertBarType.JOB_ALERT,
      requestEdit: AlertBarType.REQUEST_EDIT_ALERT,
    }),
    [],
  );

  useEffect(
    () => {
      if (!appInsights?.appInsights?.isInitialized()) {
        appInsights.loadAppInsights();
      }
      if (!shareOriginUrl) {
        let url = '';
        if (window.history?.state?.state?.from?.pathname) {
          url = window.history?.state?.state?.from?.pathname.toString();
        }

        if (window.history?.state?.state?.from?.search) {
          url = url + window.history?.state?.state?.from?.search.toString();
        }
        dispatch(jobShareActions.setOriginUrl(url));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (cobrowsing) {
        start({
          customData: {
            user_id: userProfile.userId,
            user_name: userProfile.emailAddress,
            user_email: userProfile.emailAddress,
            device_id: userProfile.userId,
            device_name: `${userProfile.firstName} ${userProfile.lastName}`,
          },
          customSessionControls: true,
        });
      }
      //== Pages with existing userPreference calls ====//
      const pathsToCheck = [
        `/${AppRouteNames.JOB_SEARCH}`,
        `/${AppRouteNames.PREFERENCES}`,
        `/${AppRouteNames.PROFILE}`,
        `/${AppRouteNames.CREATE_ACCOUNT}`,
      ];
      //================================================//

      dispatch(authenticationActions.getVersionCheckAction());
      if (!pathsToCheck.includes(window.location.pathname)) {
        dispatch(retrieveUserPreference());
      }
      dispatch(fetchState());
    }
  }, [isAuthenticated, dispatch]);

  const appBanner = useMemo(() => {
    return (
      <Grid
        item
        sx={{
          zIndex: '1400',
          right: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px auto',
          top: '66px!important',
          bottom: 'auto',
          position: 'relative',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: {
            top: '73px!important',
            margin: '6px',
          },
        }}
      >
        <AppAlertbar id="app-common-alert" sx={{ justifyContent: 'center' }} />
        {Object.keys(routeToAlertRefMap).map(alertPage => {
          return (
            <AppAlertbar
              key={`${alertPage}-alerts-id`}
              id={`${alertPage}-alerts-id`}
              refId={routeToAlertRefMap[alertPage]}
            />
          );
        })}
      </Grid>
    );
  }, [routeToAlertRefMap]);

  return (
    <>
      <Grid container position="absolute" justifyContent="center">
        {appBanner}
      </Grid>
      <BrowserRouter>
        <Switch>
          {Object.entries(unprotectedIndependentRoutes).map(
            ([upRoute, upComponent]) => (
              <Route
                key={guidGenerator()}
                exact
                path={`/${upRoute}`}
                component={upComponent}
              />
            ),
          )}
          <Layout>
            <Switch>
              {Object.entries(unprotectedRoutes).map(
                ([upRoute, upComponent]) => (
                  <Route
                    key={upRoute}
                    exact
                    path={`/${upRoute}`}
                    component={upComponent}
                  />
                ),
              )}
              {Object.entries(protectedRoutes).map(([pRoute, pComponent]) => (
                <ProtectedRoute
                  key={pRoute}
                  exact
                  path={`/${pRoute}`}
                  setActivePage={setActivePage}
                  component={pComponent}
                />
              ))}
              <Route path="*" component={ContentNotFound} />
            </Switch>
          </Layout>
        </Switch>
        <GlobalStyle />
      </BrowserRouter>
    </>
  );
}
