import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { ActionRequiredDialogProps } from 'interfaces/Props/IDialogProps';

import { theme } from 'styles/theme';

export default function ActionRequiredDialog(props: ActionRequiredDialogProps) {
  const { icon, title, open, body, buttons, type = 'ActionRequired' } = props;
  return (
    <Dialog
      id={'action-dialog-' + title}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: '327px',
          minHeight: '404px',
        },
        zIndex: 2200,
      }}
      open={open}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        paddingTop={type === 'Strike' ? 4 : 6}
        data-testid="dialog-icon"
      >
        <Grid item>{icon}</Grid>
      </Grid>
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: theme.palette.system.midnightBlue,
          fontSize: '20px',
          padding: '12px 24px',
          fontWeight: 600,
        }}
        data-testid="dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{ padding: '0 24px', overflowY: 'hidden', flex: 'none' }}
        data-testid="dialog-content"
      >
        {typeof body === 'string' ? (
          <Typography
            variant="subtitle1"
            color="system.coolGray"
            textAlign="center"
          >
            {body}
          </Typography>
        ) : (
          body
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <Grid container spacing={2} direction="column">
          {buttons?.map((button, index, array) => (
            <Grid item xs={12} key={button.id}>
              <Button
                key={button.id}
                id={button.id}
                variant={array.length - 1 === index ? 'secondary' : 'primary'}
                sx={{
                  width: '100%',
                  padding: '0px',
                }}
                onClick={button.onClick}
                disabled={button.disabled ?? false}
              >
                {button.text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
