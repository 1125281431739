import React from 'react';

export const ADPIcon = (color?: string, height?: string, width?: string) => {
  return (
    <svg
      id="Controls-icon-ADP"
      xmlns="http://www.w3.org/2000/svg"
      width={width || '38'}
      height={height || '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2268"
        data-name="Rectangle 2268"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g id="ADP_Logo" data-name="ADP Logo" transform="translate(1 11)">
        <path
          id="Path_6437"
          data-name="Path 6437"
          d="M233.872,380.42h-5.691l-.013,2.757a7.993,7.993,0,0,0-6.049-2.752h-8.48l-9.448,15.992h6.4l2.02-3.448h4.24v3.448l5.265,0a8,8,0,0,0,6.049-2.759l0,2.756h5.478V392.97h.228a6.275,6.275,0,1,0,0-12.55Zm0,10.572h-2.22v3.448h-1.48v-3.445h-2.556a6.056,6.056,0,0,1-5.5,3.433l-3.273.013v-3.448h-7.427l-2.049,3.448h-1.622l7.114-12.027h7.257a6.041,6.041,0,0,1,6.013,5.126l2.041.005V382.4h3.7a4.3,4.3,0,1,1,0,8.593Z"
          transform="translate(-204.191 -380.42)"
          fill={color}
          fillRule="evenodd"
        />
        <path
          id="Path_6438"
          data-name="Path 6438"
          d="M288.568,398.161h-1.793v1.956l1.793,0a2.584,2.584,0,1,1,0,5.168s-1.793-.026-1.793,0v1.98l1.793.006a4.557,4.557,0,1,0,0-9.113Z"
          transform="translate(-270.639 -394.718)"
          fill={color}
          fillRule="evenodd"
        />
        <path
          id="Path_6439"
          data-name="Path 6439"
          d="M346.963,398.2h-2.22v1.963l2.22,0a.859.859,0,1,1,0,1.719h-2.22v1.96h2.22a2.821,2.821,0,1,0,0-5.643Z"
          transform="translate(-317.281 -394.75)"
          fill={color}
          fillRule="evenodd"
        />
        <path
          id="Path_6440"
          data-name="Path 6440"
          d="M250.138,401.867h-3.415l-1.167,1.978h6.574v-5.652h-1.992v3.674Z"
          transform="translate(-237.474 -394.744)"
          fill={color}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
