import { http } from 'services/BaseApi';
import { IContactResponse } from 'types/ContactResponse';

/**
 * Method to get recruiter from user contacts
 *
 * @param userId - user Id of the logged in user
 */
export const getUserContacts = async (
  userId: string,
): Promise<IContactResponse> => {
  return await http.get<string>(`/contacts/${userId}`);
};
