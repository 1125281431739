import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as userService from 'services/user/userService';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import {
  addEducationHistory,
  invokeEditEducationHistory,
  setAddEducationHistory,
  setEditEducationHistory,
  setEducationError,
  setError,
  setLoading,
} from 'store/slices/education/educationSlice';
import { updateEducationSchools } from 'store/slices/user/userProfile/userProfileSlice';

const selectedClearanceItem = (state: RootState) =>
  state.actionItem.selectedClearanceItem;

function* editEducation(action) {
  try {
    const response = yield call(
      f => userService.editUserEducation(f.userId, f.editEducation),
      action.payload,
    );
    const { data } = response;
    yield put(setEditEducationHistory(data));
    const clearanceItem = yield select(selectedClearanceItem);
    if (clearanceItem && clearanceItem.id) {
      yield put(actionItemActions.dismissActionItemForClearanceItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setEducationError());
    yield put(setError(err));
  }
}

function* addEducation(action) {
  try {
    const response = yield call(
      f => userService.addUserEducation(f.userId, f.addEducation),
      action.payload,
    );
    const { data } = response;
    yield put(setAddEducationHistory(data));

    if (data?.success) {
      const schoolToAdd = {
        ...action.payload?.addEducation,
        name: action.payload?.addEducation?.school,
      };

      yield put(updateEducationSchools(schoolToAdd));

      const clearanceItem = yield select(selectedClearanceItem);
      if (clearanceItem && clearanceItem.id) {
        yield put(actionItemActions.dismissActionItemForClearanceItem());
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoading());
  }
}

function* editEducationWatcher() {
  yield takeEvery(invokeEditEducationHistory.type, editEducation);
}
function* addEducationWatcher() {
  yield takeLatest(addEducationHistory.type, addEducation);
}

export function* educationSaga() {
  yield all([editEducationWatcher(), addEducationWatcher()]);
}
