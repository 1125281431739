/* eslint-disable no-unused-vars */
export enum AddressComponentTypes {
  CITY = 'locality',
  CITY2 = 'administrative_area_level_3',
  CITY3 = 'postal_town',
  STATE = 'administrative_area_level_1',
  COUNTY = 'administrative_area_level_2',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  COMMA = ',',
  NAME = 'name',
}

export const AddressComponentCompoundTypes = {
  ADDRESS: [AddressComponentTypes.STREET_NUMBER, AddressComponentTypes.ROUTE],
  FORMATTED_ADDRESS: [
    AddressComponentTypes.STREET_NUMBER,
    AddressComponentTypes.ROUTE,
    AddressComponentTypes.COMMA,
    AddressComponentTypes.CITY,
    AddressComponentTypes.COMMA,
    AddressComponentTypes.STATE,
    AddressComponentTypes.COMMA,
    AddressComponentTypes.POSTAL_CODE,
  ],
};
