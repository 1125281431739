import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import {
  INotification,
  INotificationInfo,
  INotificationReadInfo,
  INotificationsRequest,
} from 'interfaces/Notifications/INotifications';
import { INotificationsState } from './notificationsState';

const initialState: INotificationsState = {
  notifications: [],
  unreadNotificationsCount: 0,
  totalNotificationsCount: 0,
  markedNotificationIds: [],
  copyNotifications: [],
  copyUnreadNotificationsCount: 0,
};

const getNotificationsAction =
  createAction<INotificationsRequest>('GET_NOTIFICATIONS');
const getUnreadNotificationsAction = createAction('GET_UNREAD_NOTIFICATIONS');
const markNotificationAsDelete = createAction(
  'DELETE_NOTIFICATIONS',
  function prepare(notificationIds: string[]) {
    return {
      payload: notificationIds,
    };
  },
);

const markNotificationAsUnRead = createAction(
  'UNREAD_NOTIFICATIONS',
  function prepare(notificationIds: string[]) {
    return {
      payload: notificationIds,
    };
  },
);

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setNotificationsList(state, action: PayloadAction<INotification[]>) {
      state.notifications = action.payload;
    },
    setUnreadNotificationsCount(state, action: PayloadAction<number>) {
      state.unreadNotificationsCount = action.payload;
    },
    setTotalNotificationsCount(state, action: PayloadAction<number>) {
      state.totalNotificationsCount = action.payload;
    },

    markNotificationAsRead(state, action) {},

    tempDeleteNotification(state, action) {
      state.copyNotifications = state.notifications;
      state.copyUnreadNotificationsCount = state.unreadNotificationsCount;
      state.notifications = state.notifications.filter(
        n => n.notificationId !== action.payload.id,
      );
      if (!action.payload.isRead) {
        state.unreadNotificationsCount =
          Math.max(0, state.unreadNotificationsCount - 1) || 0;
      }
    },
    undoDeleteNotification(state) {
      state.notifications = state.copyNotifications;
      state.unreadNotificationsCount = state.copyUnreadNotificationsCount;
      state.copyNotifications = [];
      state.copyUnreadNotificationsCount = 0;
    },
    deleteNotification(state, action: PayloadAction<INotificationInfo[]>) {
      const deletedNotifications = action.payload.map(
        info => info.notificationId,
      );
      state.notifications = state.notifications.filter(
        ({ notificationId }) =>
          !deletedNotifications.includes(notificationId ?? ''),
      );
      state.copyNotifications = [];
    },

    setNotificationAsRead(
      state,
      action: PayloadAction<INotificationReadInfo[]>,
    ) {
      action.payload.map(({ notificationId }) => {
        return state.notifications?.some((notification, index) => {
          if (notification.notificationId === notificationId) {
            state.notifications[index] = { ...notification, isRead: true };
            state.unreadNotificationsCount =
              Math.max(0, state.unreadNotificationsCount - 1) || 0;
            return true;
          }
          return false;
        });
      });
    },
    reset() {
      return { ...initialState };
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
    unSetError() {
      return {
        ...initialState,
      };
    },

    setNotificationAsAllRead(state, action: PayloadAction<INotification[]>) {
      state.copyNotifications = action.payload;
      state.copyUnreadNotificationsCount = state.unreadNotificationsCount;
      return state.notifications?.forEach((notification, index) => {
        if (notification.isRead === false) {
          state.notifications[index] = { ...notification, isRead: true };
          notification?.notificationId &&
            state.markedNotificationIds.push(notification?.notificationId);
          state.unreadNotificationsCount =
            Math.max(0, state.unreadNotificationsCount - 1) || 0;
        }
      });
    },
    undoNotificationAsAllRead(state) {
      state.notifications = state.copyNotifications;
      state.unreadNotificationsCount = state.copyUnreadNotificationsCount;
      state.copyNotifications = [];
      state.copyUnreadNotificationsCount = 0;
    },
    setNotificationAsAllUnRead(
      state,
      action: PayloadAction<INotificationReadInfo[]>,
    ) {
      action.payload.map(({ notificationId }) => {
        return state.notifications?.some((notification, index) => {
          if (notification.notificationId === notificationId) {
            state.notifications[index] = { ...notification, isRead: false };
            state.markedNotificationIds = state.markedNotificationIds.filter(
              id => id !== notificationId,
            );
            state.unreadNotificationsCount = state.unreadNotificationsCount + 1;

            return true;
          }
          return false;
        });
      });
    },
    resetReadAll(state) {
      const notificationIds = [...state.markedNotificationIds];

      notificationIds.map(notificationId => {
        return state.notifications?.some((notification, index) => {
          if (notification.notificationId === notificationId) {
            state.notifications[index] = { ...notification, isRead: false };
            state.unreadNotificationsCount = state.unreadNotificationsCount + 1;
            state.markedNotificationIds = state.markedNotificationIds.filter(
              id => id !== notificationId,
            );
            return true;
          }
          return false;
        });
      });
    },
  },
});

export const notificationsActions = {
  ...notificationsSlice.actions,
  getNotificationsAction,
  getUnreadNotificationsAction,
  markNotificationAsDelete,
  markNotificationAsUnRead,
};

export default notificationsSlice.reducer;
