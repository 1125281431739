import Grid from '@mui/material/Grid';
import { IFrameDrawer } from 'components/common/Drawer/IFrameDrawer';
import { TOAST_MESSAGE } from 'constants/helperText';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';

export const SkillsChecklistIFrame = props => {
  const dispatch = useAppDispatch();
  const { toggleDrawer, url } = props;

  useEffect(() => {
    const formStackMessegeHandler = event => {
      // @TODO: Message will update
      if (event?.data?.action === 'close') {
        toggleDrawer?.(null, false);
        dispatch(
          openAlert({
            variant: 'success',
            message: TOAST_MESSAGE.SkillsChecklistSubmitted,
          }),
        );
        dispatch(credentialActions.getSubmissions());
        dispatch(credentialActions.getCredentialDetailAction(true));
      }
      if (event?.data?.action === 'cancel') toggleDrawer?.(null, false);
    };
    window.addEventListener('message', formStackMessegeHandler);

    return () => {
      window.removeEventListener('message', formStackMessegeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      id="skills-checklist-iframe"
      sx={{
        position: 'absolute',
        top: '73px',
        height: 'calc(100% - 73px)',
        left: 0,
      }}
    >
      <IFrameDrawer url={url} />
    </Grid>
  );
};
