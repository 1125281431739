import { MenuIcons } from 'enums/menuIcons';
import { openInCurrentTab } from 'helpers/openInNewTab';
import { httpSuccess } from 'helpers/serviceHelper';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getNewsFeedsService,
  getNewsFeedTagService,
  getUserStoriesService,
  saveNewsFeedTagService,
  getNewsFeedByIdService,
  getUserStoryByIdService,
  likeContentService,
} from 'services/community/communityService';
import { RootState } from 'store/configureStore';
import { communityActions } from 'store/slices/community/communitySlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import mapMenuIconsection from 'utils/mapMenuIconNameSection';
import { TOAST_MESSAGE } from 'constants/helperText';

const trackedNewsFeedsGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-news-feeds');

const trackedNewsFeedTagsGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-news-feed-tags');

const trackedNewsFeedTagsSave = (fn, ...args) =>
  trackPromise(fn(...args), 'save-news-feed-tags');

const trackedUserStoriesGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-user-stories');

const trackNewsFeedGetById = (fn, ...args) =>
  trackPromise(fn(...args), 'get-news-feed-by-id');

const trackedUserStoryGetById = (fn, ...args) =>
  trackPromise(fn(...args), 'get-user-story-by-id');

const trackedNewsFeedLike = (fn, ...args) =>
  trackPromise(fn(...args), 'like-news-feed');

const userId = (state: RootState) => state.auth.userId;

function* getNewsFeeds(action) {
  try {
    if ((action.payload?.offset ?? 0) === 0) {
      yield put(communityActions.resetNewsFeeds());
    }
    const currentUserId = yield select(userId);
    const response = yield call(trackedNewsFeedsGet, getNewsFeedsService, {
      userId: currentUserId,
      limit: action.payload?.limit,
      offset: action.payload?.offset,
      status: action.payload?.status,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(communityActions.setNewsFeeds(data));
    }
  } catch (error: any) {}
}

function* getUserStories(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(trackedUserStoriesGet, getUserStoriesService, {
      userId: currentUserId,
      limit: action.payload?.limit,
      offset: action.payload?.offset,
      status: action.payload?.status,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(communityActions.setUserStories(data));
    }
  } catch (error: any) {}
}

function* getNewsFeedTags() {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedNewsFeedTagsGet,
      getNewsFeedTagService,
      currentUserId,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(communityActions.setNewsFeedTags(data));
    }
  } catch (error: any) {}
}

function* saveNewsFeedTags(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedNewsFeedTagsSave,
      saveNewsFeedTagService,
      currentUserId,
      action.payload,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(
        communityActions.setSaveNewsFeedTagsStatus(data?.success ?? false),
      );
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.CommunityInterestSaved,
        }),
      );
    }
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: error?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

function* getNewsFeedById(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackNewsFeedGetById,
      getNewsFeedByIdService,
      currentUserId,
      action.payload,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(communityActions.setNewsFeedDetail(data));
    } else {
      openInCurrentTab(mapMenuIconsection(MenuIcons.ContentNotFound));
      yield put(communityActions.resetNewsFeedDetail());
    }
  } catch (error: any) {}
}

function* getUserStoryById(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedUserStoryGetById,
      getUserStoryByIdService,
      currentUserId,
      action.payload,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(communityActions.setUserStoryDetail(data));
    } else {
      openInCurrentTab(mapMenuIconsection(MenuIcons.ContentNotFound));
    }
  } catch (error: any) {}
}

function* likeNewsFeed(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedNewsFeedLike,
      likeContentService,
      currentUserId,
      action.payload.newsFeedId,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      data.contentId = action.payload.newsFeedId;
      yield put(communityActions.updateLikeCount(data));
    }
  } catch (error: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: error?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

export function* communitySaga() {
  yield all([
    takeLatest(communityActions.getNewsFeeds.type, getNewsFeeds),
    takeLatest(communityActions.getNewsFeedTags.type, getNewsFeedTags),
    takeLatest(communityActions.saveNewsFeedTags.type, saveNewsFeedTags),
    takeLatest(communityActions.getUserStories.type, getUserStories),
    takeLatest(communityActions.getNewsFeedById.type, getNewsFeedById),
    takeLatest(communityActions.getUserStoryById.type, getUserStoryById),
    takeLatest(communityActions.likeNewsFeed.type, likeNewsFeed),
  ]);
}
