import Styled, { css } from 'styled-components';

export interface ISlopedButtonProps {
  barHeight?: string;
  isSlantedView: boolean;
  position: number;
  totalTabs: number;
  isActiveTab: boolean;
  responseActiveTabId: number;
  buttonTabId: number[];
  bgColor: string | undefined;
  isActive: boolean;
  width: string;
}

export interface IpseudoSlantedDivProps {
  barHeight?: string;
  bgColor: string | undefined;
  width?: string;
  isActive: boolean;
}

export const SlopedButton = Styled.div<ISlopedButtonProps>`
  height: ${props => (props.barHeight ? props.barHeight : ' 29px')};
  display: inline-block;
  width: ${props => props.width};
  position: relative;
  background-color: ${props => props.bgColor};
  transition: all 0.3s linear;

${props =>
  props.position === 0
    ? css`
        border-top: 0px solid transparent;
        border-bottom: 29px solid transparent;
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        border-top-left-radius: 29px;
        border-bottom-left-radius: 29px;
        margin-right: -16px;
      `
    : props.position === props.totalTabs - 1
    ? css`
        border-top: 0px solid transparent;
        border-bottom: 29px solid transparent;
        border-left: 29px solid transparent;
        border-right: 0px solid transparent;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        z-index: 5;
      `
    : css`
        transform: ${props.isSlantedView ? 'skewX(-45deg)' : 'skewX(0deg)'};
        -ms-transform: ${props.isSlantedView ? 'skewX(-45deg)' : 'skewX(0deg)'};
        -webkit-transform: ${props.isSlantedView
          ? 'skewX(-45deg)'
          : 'skewX(0deg)'};
        border-left: 4px solid #fff;
        border-right: 4px solid #fff;
        display: flex;
        z-index: 10;
        margin-right: -16px;
        height: ${props.barHeight ? props.barHeight : ' 29px'};
      `}
`;

export const PseudoSlantedDiv = Styled.div<IpseudoSlantedDivProps>`
  position: absolute;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  display: flex;
  z-index: 1;
  opacity: 1;
  height: ${props => (props.barHeight ? props.barHeight : ' 29px')};
  width: 100%;
  opacity: ${props => (!props.isActive ? 0.6 : 0)};
  z-index: 5;
  background-color: #fff;
`;

export const PseudoSlantedRoundedDiv = Styled.div<IpseudoSlantedDivProps>`
  position: absolute;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 0px solid transparent;
  border-bottom: 29px solid transparent;
  border-left: 29px solid transparent;
  border-right: 0px solid transparent;
  display: flex;
  z-index: 1;
  opacity: 1;
  height: ${props => (props.barHeight ? props.barHeight : ' 29px')};
  opacity: ${props => (!props.isActive ? 0.6 : 0)};
  z-index: 5;
  background-color: #fff;
  left: -29px;
  width: calc(100% + 29px);
`;
