import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ArrowLeftIcon } from 'assets/icons/Arrows/Arrow-Left';
import { ShareIcon } from 'assets/icons/Share-Icon';
import { ISiteHeaderWithMenuOption } from 'interfaces/Props/ISiteHeaderWithMenuOption';
import { amnFontSize } from 'styles/fontSizes';
import { theme } from 'styles/theme';
import AMNLogo from '../../assets/images/Controls-Logo.svg';
import {
  MenuOption,
  MoreOptions,
  StylesHomePageIcon,
} from './SiteHeaderWithMenuOption.styles';

export default function SiteHeaderWithMenuOption(
  props: ISiteHeaderWithMenuOption,
) {
  const { id, title, menuItems } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      id={id}
      container
      p={1}
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: 'system.white',
        height: '72px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid`,
        borderBottomColor: 'system.border',
        position: 'relative',
      }}
    >
      <Grid item xs={4}>
        <Box
          pl={4}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          role="img"
          aria-label="amn-passport-logo"
        >
          <img
            src={AMNLogo}
            style={StylesHomePageIcon}
            alt={'AMN Passport Logo'}
          />
        </Box>
        <Box
          pl={4}
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'left',
          }}
        >
          <ArrowLeftIcon sx={{ width: '16px', height: '16px' }} />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            justifyContent: 'center',
          }}
        >
          <Typography
            role="heading"
            aria-label="current-page"
            color="system.midnightBlue"
            sx={{
              fontSize: { xs: '16px', md: '23px' },
              lineHeight: { xs: '24px', md: '23px' },
            }}
            textAlign="center"
          >
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <MoreOptions>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon sx={{ display: { xs: 'none', md: 'flex' } }} />
            <ShareIcon
              sx={{
                display: { xs: 'flex', md: 'none' },
                width: '24px',
                height: '24px',
                color: 'system.skyBlue',
              }}
            />
          </IconButton>
          <Menu
            sx={{ zIndex: 10000 }}
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '20ch',
                boxShadow: `0px 2px 16px ${theme.palette.system.iconShadowGray}`,
                background: `${theme.palette.system.white} 0% 0% no-repeat padding-box`,
                fontSize: `${amnFontSize.fs14}`,
                marginTop: '10px',
                left: '200px',
              },
            }}
          >
            {menuItems?.map(option => (
              <MenuItem key={option.id} onClick={option.onClick}>
                <MenuOption>{option.menuItem}</MenuOption>
              </MenuItem>
            ))}
          </Menu>
        </MoreOptions>
      </Grid>
    </Grid>
  );
}
