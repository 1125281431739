import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import facebook from 'assets/icons/SocialMediaIcons/facebook.svg';
import instagram from 'assets/icons/SocialMediaIcons/instagram.svg';
import linkedin from 'assets/icons/SocialMediaIcons/linkedin.svg';
import tiktok from 'assets/icons/SocialMediaIcons/tiktok.svg';
import twitter from 'assets/icons/SocialMediaIcons/twitter.svg';
import youtube from 'assets/icons/SocialMediaIcons/youtube.svg';
import AMNLogo from 'assets/images/Controls-Logo.svg';
import {
  COOKIE_POLICY_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LEGAL_URL,
  LINKEDIN_URL,
  PRIVACY_POLICY_URL,
  RIGHTS_PROTECTIONS_URL,
  TIKTOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from 'constants/passportUrls';
import { MenuIcons } from 'enums/menuIcons';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { authenticationSelector } from 'store/selectors/authSelector';
import {
  setActivePage,
  setMenuState,
} from 'store/slices/navigation/navigationSlice';
import { theme } from 'styles/theme';
import { useFooterStyles } from './SiteFooter.styles';

const SiteFooter = () => {
  const classes = useFooterStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAuthenticated: boolean = useAppSelector(authenticationSelector);

  const handleHomePageIconClick = () => {
    dispatch(setActivePage(MenuIcons.Home));
    dispatch(setMenuState(false));
    history.push(MenuIcons.Home);
  };

  let year = new Date().getFullYear();

  const onetrustPcSdkYourPrivacyRightsBtn =
    document.querySelector('#ot-sdk-btn');

  const openOnetrustPanel = () => {
    const sidePanel: HTMLElement | null =
      document.querySelector('#onetrust-pc-sdk');
    const sidePanelDOMTokenClassList = sidePanel?.classList;

    sidePanelDOMTokenClassList?.add(
      'ot-slide-in-left',
      'ot-fade-in',
      'ot-animated',
    );
    sidePanelDOMTokenClassList?.remove('ot-slide-out-left', 'ot-hide');

    if (!sidePanel) return;
    sidePanel.style.display = 'block';
    sidePanel.style.opacity = '1';
    sidePanel.style.visibility = 'visible';

    // Ensure backdrop filter appears
    const onetrustPcDarkFilter = document.querySelector(
      '.onetrust-pc-dark-filter',
    ) as any;

    if (!onetrustPcDarkFilter) return;
    onetrustPcDarkFilter.classList.remove('ot-hide');
    onetrustPcDarkFilter.style.display = 'block';
    onetrustPcDarkFilter.style.opacity = '1';
    onetrustPcDarkFilter.style.visibility = 'visible';
  };

  onetrustPcSdkYourPrivacyRightsBtn?.addEventListener(
    'click',
    openOnetrustPanel,
  );

  useEffect(() => {
    const closePcBtnHanderId = 'close-pc-btn-handler';

    const hideOverlay = (e: any) => {
      const onetrustPcDarkFilter = document.querySelector(
        '.onetrust-pc-dark-filter',
      );
      if (e?.target?.id === closePcBtnHanderId) {
        (onetrustPcDarkFilter as any).style.display = 'none';
      }
    };

    document?.addEventListener('click', hideOverlay);

    return () => {
      document?.removeEventListener('click', hideOverlay);
      onetrustPcSdkYourPrivacyRightsBtn?.removeEventListener(
        'click',
        openOnetrustPanel,
      );
    };
  }, [onetrustPcSdkYourPrivacyRightsBtn]);

  return (
    <>
      <Grid
        id="site-footer"
        container
        sx={{
          justifyContent: { md: 'space-between' },
          zIndex: { md: '1' },
        }}
        className={classes.footerContainer}
      >
        <Grid
          container
          item
          sx={{
            backgroundColor: 'system.white',
            flexFlow: { xs: 'column', sm: 'row' },
          }}
          alignItems={{ xs: 'center', sm: 'baseline' }}
          justifyContent="space-between"
          xs={12}
        >
          <Grid
            item
            id="footer-social-media-links"
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexWrap="nowrap"
            mt={{ xs: '24px', sm: 0 }}
            sx={{
              pl: { xs: 0, sm: 4 },
              gap: '24px',
            }}
          >
            <Link href={INSTAGRAM_URL} target="_blank" rel="noopener">
              <img src={instagram} alt="instagram" />
            </Link>
            <Link href={YOUTUBE_URL} target="_blank" rel="noopener">
              <img src={youtube} alt="youtube" />
            </Link>
            <Link href={LINKEDIN_URL} target="_blank" rel="noopener">
              <img src={linkedin} alt="linkedin" />
            </Link>
            <Link href={TWITTER_URL} target="_blank" rel="noopener">
              <img src={twitter} alt="twitter" />
            </Link>
            <Link href={FACEBOOK_URL} target="_blank" rel="noopener">
              <img src={facebook} alt="facebook" />
            </Link>
            <Link href={TIKTOK_URL} target="_blank" rel="noopener">
              <img src={tiktok} alt="tiktok" />
            </Link>
          </Grid>
          <Grid
            item
            id="footer-important-links"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            display="flex"
            mt={{ xs: '24px', sm: '31px' }}
            mb={{ xs: '24px', sm: '31px' }}
            pr={{ xs: 0, sm: '24px' }}
            pl={{ xs: 0, sm: '24px' }}
            sx={{
              flexWrap: { xs: 'nowrap', sm: 'wrap !important' },
              flexFlow: { xs: 'column', sm: 'row' },
              gap: '24px',
            }}
          >
            <Link
              href="terms-of-service"
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography color="system.skyBlue" variant="body1">
                {'Terms of Service'}
              </Typography>
            </Link>
            <Link
              href={PRIVACY_POLICY_URL}
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography color="system.skyBlue" variant="body1">
                {'Privacy Policy'}
              </Typography>
            </Link>
            <Link
              href={COOKIE_POLICY_URL}
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography color="system.skyBlue" variant="body1">
                {'Ad & Cookie Policy'}
              </Typography>
            </Link>
            <Link
              href={RIGHTS_PROTECTIONS_URL}
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography color="system.skyBlue" variant="body1">
                {'Rights & Protections'}
              </Typography>
            </Link>
            <Link
              href={LEGAL_URL}
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography color="system.skyBlue" variant="body1">
                {'Legal'}
              </Typography>
            </Link>

            {/*  OptanonCookieSettingsButtonStart
              This is a third-party button required for the pop-up config for AMN policies outlined in UserStory 27857.
              The id and className here are required for this button to work correctly.
              Had to override the default styles with inline styles.
            */}
            <button
              id="ot-sdk-btn"
              className="ot-sdk-show-settings"
              style={{
                background: 'none',
                color: theme.palette.system.skyBlue,
                cursor: 'pointer',
                border: 'none',
                fontSize: '14px',
                padding: '0px',
              }}
            >
              Your Privacy Rights
            </button>
          </Grid>
          <Grid
            item
            id="footer-site-link"
            sx={{
              cursor: isAuthenticated ? 'pointer' : '',
              pr: { xs: 0, sm: 4 },
              justifyContent: { xs: 'center', md: 'end' },
            }}
            onClick={() => (isAuthenticated ? handleHomePageIconClick() : null)}
            textAlign="center"
            className={classes.logoContainer}
          >
            <img
              src={AMNLogo}
              alt={'AMN Passport Logo'}
              className={classes.logo}
            />
          </Grid>
        </Grid>
        <Grid
          item
          id="footer-copyright"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: 'system.midnightBlue',
            pt: '21px',
            pb: '21px',
          }}
          xs={12}
        >
          <Typography color="system.white">
            Copyright © {year} AMN Healthcare
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SiteFooter;
