import React from 'react';

export default function TimePayIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls-icon-Time-and-Pay"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2281"
        data-name="Rectangle 2281"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g id="Group_2874" data-name="Group 2874" transform="translate(1 1)">
        <rect
          id="Rectangle_2271"
          data-name="Rectangle 2271"
          width="1.849"
          height="1.858"
          transform="translate(8.787 7.108)"
          fill={color}
        />
        <rect
          id="Rectangle_2272"
          data-name="Rectangle 2272"
          width="1.849"
          height="1.858"
          transform="translate(12.486 7.108)"
          fill={color}
        />
        <rect
          id="Rectangle_2273"
          data-name="Rectangle 2273"
          width="1.849"
          height="1.858"
          transform="translate(16.185 7.108)"
          fill={color}
        />
        <rect
          id="Rectangle_2274"
          data-name="Rectangle 2274"
          width="1.849"
          height="1.858"
          transform="translate(5.088 10.825)"
          fill={color}
        />
        <rect
          id="Rectangle_2275"
          data-name="Rectangle 2275"
          width="1.849"
          height="1.858"
          transform="translate(8.787 10.825)"
          fill={color}
        />
        <rect
          id="Rectangle_2276"
          data-name="Rectangle 2276"
          width="1.849"
          height="1.858"
          transform="translate(12.486 10.825)"
          fill={color}
        />
        <rect
          id="Rectangle_2277"
          data-name="Rectangle 2277"
          width="1.849"
          height="1.858"
          transform="translate(16.185 10.825)"
          fill={color}
        />
        <rect
          id="Rectangle_2278"
          data-name="Rectangle 2278"
          width="1.849"
          height="1.858"
          transform="translate(5.088 14.542)"
          fill={color}
        />
        <rect
          id="Rectangle_2279"
          data-name="Rectangle 2279"
          width="1.849"
          height="1.858"
          transform="translate(8.787 14.542)"
          fill={color}
        />
        <rect
          id="Rectangle_2280"
          data-name="Rectangle 2280"
          width="1.849"
          height="1.858"
          transform="translate(12.486 14.542)"
          fill={color}
        />
        <path
          id="Path_3743"
          data-name="Path 3743"
          d="M35.9,23.962a11.375,11.375,0,0,0-1.45.1V14.593A2.66,2.66,0,0,0,31.8,11.93H13.973a2.66,2.66,0,0,0-2.65,2.663V31.015a2.659,2.659,0,0,0,2.65,2.663H24.617A11.417,11.417,0,1,0,35.9,23.962Zm-21.922,7.3a.244.244,0,0,1-.241-.242v-14h18.3v7.636a11.493,11.493,0,0,0-6.77,6.607ZM35.9,44.509a9.063,9.063,0,1,1,9.018-9.063A9.051,9.051,0,0,1,35.9,44.509Z"
          transform="translate(-11.323 -11.93)"
          fill={color}
        />
        <path
          id="Path_3744"
          data-name="Path 3744"
          d="M37.714,34.71l-.507-.1V32.993a5.977,5.977,0,0,1,1.273.216,4.6,4.6,0,0,1,1.045.433H39.8V31.809a6.248,6.248,0,0,0-1.232-.324,10.44,10.44,0,0,0-1.365-.166v-1.47h-1.1v1.483a4.481,4.481,0,0,0-2.388.814,2,2,0,0,0-.861,1.622,2.541,2.541,0,0,0,.2,1.059,1.985,1.985,0,0,0,.55.721,2.811,2.811,0,0,0,.845.472,9.1,9.1,0,0,0,1.074.312l.614.139v1.743a8.94,8.94,0,0,1-1.5-.248,5.65,5.65,0,0,1-1.338-.508H33.01v1.857a5.95,5.95,0,0,0,1.455.414,9.97,9.97,0,0,0,1.641.171v2.272h1.1v-2.31a3.979,3.979,0,0,0,2.387-.872,2.352,2.352,0,0,0,.9-1.845,1.963,1.963,0,0,0-.675-1.587A4.65,4.65,0,0,0,37.714,34.71Zm-1.507-.241a2.222,2.222,0,0,1-.866-.287.549.549,0,0,1-.216-.464.563.563,0,0,1,.332-.513,1.846,1.846,0,0,1,.75-.212Zm1.69,3.477a1.754,1.754,0,0,1-.715.245V36.613a2.109,2.109,0,0,1,.675.231.59.59,0,0,1,.339.551A.61.61,0,0,1,37.9,37.945Z"
          transform="translate(-12.104 -12.495)"
          fill={color}
        />
      </g>
    </svg>
  );
}
