import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { authenticationSelector } from 'store/selectors/authSelector';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import { decodeToken } from 'utils/tokenHelper';

export const Timer = () => {
  const [isIdle, setIsIdle] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated: boolean = useAppSelector(authenticationSelector);

  const bearerToken: string = useAppSelector(state => {
    if (state.auth.loginResponse.accessToken) {
      return state.auth.loginResponse.accessToken;
    } else {
      return '';
    }
  });

  const refresh = useAppSelector(state => {
    return state.auth.loginResponse.refreshToken;
  });

  const correlationId = useAppSelector(state => {
    return state.auth.loginResponse.correlationId;
  });

  useEffect(() => {
    let timeout: any = null;
    if (isAuthenticated) {
      const now = moment();
      const expires = moment.unix(decodeToken(bearerToken)?.exp);
      let diffInMilliseconds = expires.diff(now, 'milliseconds');

      if (diffInMilliseconds > 0) {
        if (diffInMilliseconds < 1000 * 60 * 5 /* 5 minutes */) {
          dispatch(
            authenticationActions.refreshAccessTokenAction({
              refreshToken: refresh,
              correlationId,
            }),
          );
        } else {
          const isLeadUser =
            decodeToken(bearerToken)?.userstatustype === 'lead';

          // We want to refresh the token every 5 minutes to check whether the lead user has been upgraded
          const timeOutValue = isLeadUser
            ? 1000 * 60 * 5 /* 5 minutes */
            : diffInMilliseconds / 2;

          timeout = setTimeout(() => {
            dispatch(
              authenticationActions.refreshAccessTokenAction({
                refreshToken: refresh,
                correlationId,
              }),
            );
          }, timeOutValue);
        }
      } else {
        const refreshExpires = moment.unix(decodeToken(refresh)?.exp);
        diffInMilliseconds = refreshExpires.diff(now, 'milliseconds');

        if (diffInMilliseconds > 0) {
          dispatch(
            authenticationActions.refreshAccessTokenAction({
              refreshToken: refresh,
              correlationId,
            }),
          );
        }
      }
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correlationId, isAuthenticated, refresh, bearerToken]);

  useEffect(() => {
    if (isIdle && isAuthenticated) {
      dispatch(authenticationActions.logoutUserAction());
    }
  }, [isIdle, isAuthenticated, dispatch]);

  const handleOnIdle = () => {
    setIsIdle(() => {
      return true;
    });
  };

  const handleOnActive = () => {
    setIsIdle(() => {
      return false;
    });
  };

  const handleAction = () => {
    setIsIdle(() => {
      return false;
    });
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleAction,
    debounce: 500,
  });

  return <></>;
};
