import React, { Fragment, ReactElement, useCallback, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles/';
import { ArrowRightIcon } from 'assets/icons/Arrows/Arrow-Right';
import ArrowDown from 'assets/icons/Controls/ArrowDown';
import { MenuIcons } from 'enums/menuIcons';
import {
  ICard,
  ICardDetails,
  INestedCardDetails,
} from 'interfaces/PageLayout/ICardListPage';
import { theme } from 'styles/theme';
import { ExpandingAccordion } from '../Accordion/ExpandingAccordion';
import { Dropdown } from '../Dropdown/Dropdown';
import { createCardStyles } from './Cards.styles';
import { IContact } from 'interfaces/User/Contact/IContact';
import { HelpContact } from './HelpContact';

export const CardDetails = ({
  cardDetails,
  isPreference = false,
  component = <></>,
  titleSx,
  expandAccordion,
  helpContact = undefined,
  showTitle, // used when the card details is pulled up on the parent page
}: {
  cardDetails?: ICardDetails;
  isPreference?: boolean;
  component?: ReactElement;
  titleSx?: SxProps<Theme>;
  expandAccordion?: string;
  helpContact?: IContact | undefined;
  showTitle?: boolean;
}) => {
  const classes = createCardStyles();
  const [showShowMore, setShowShowMore] = useState(true);
  const showMoreFunction = cardDetails?.showMore
    ? cardDetails.showMore
    : (a: boolean) => {};

  return (
    <>
      {cardDetails?.verbiage && cardDetails?.verbiage}
      {cardDetails?.headerLink1 && (
        <Box
          id="card-header-link-1"
          pb={3}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            width: { lg: '555px' },
          }}
        >
          {cardDetails?.headerLink1}
        </Box>
      )}
      {(!!cardDetails?.filter1 || !!cardDetails?.filter2) && (
        <Grid
          container
          justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
          className={classes.filterContainer}
          sx={{
            backgroundColor: {
              xs: theme.palette.system.inputBackground,
            },
            width: {
              xs: 'calc(100% + 45px)',
              md: '555px',
            },
            padding: {
              xs: '12px',
              md: '0',
            },
            margin: {
              xs: '-24px -24px 0 -24px',
              md: '0 0 21px 0',
            },
            borderTop: {
              xs: '1px solid #E0E0E0',
              sm: 'none',
            },
          }}
        >
          {cardDetails?.filter1 && (
            <Grid
              item
              id="card-filter-1"
              xs={6}
              pr={{ xs: showTitle ? 2 : 0, md: 1 }}
            >
              <Dropdown
                label={cardDetails?.filter1?.label}
                selectedValue={cardDetails?.filter1?.selectedValue}
                menuItems={cardDetails?.filter1?.menuItems}
                disabled={cardDetails?.filter1?.disabled}
                handleOnChange={cardDetails?.filter1?.handleOnChange}
              />
            </Grid>
          )}
          {cardDetails?.filter2 && (
            <Grid item id="card-filter-2" xs={6} pl={{ xs: 0, md: 1 }}>
              <Dropdown
                label={cardDetails?.filter2?.label}
                selectedValue={cardDetails?.filter2?.selectedValue}
                menuItems={cardDetails?.filter2?.menuItems}
                disabled={cardDetails?.filter2?.disabled}
                handleOnChange={cardDetails?.filter2?.handleOnChange}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Card
        id="card-details"
        sx={{
          m: { xs: showTitle ? '0 -24px 0 -24px' : 'auto', md: 0 },
          padding: { xs: showTitle ? '24px 24px 0 24px' : 0, sm: '24px' },
          boxShadow: { xs: 'none', sm: '0px 1px 15px #B9BBBC40' },
          borderRadius: showTitle ? 0 : '10px',
          paddingBottom:
            cardDetails?.module === MenuIcons.Faq ? { sm: '36px' } : 0,
          width: { md: '555px' },
          pb: { xs: showTitle ? 3 : 0, md: 4 },
          ...cardDetails?.contentStyle,
        }}
      >
        <Box
          sx={{
            display: { xs: showTitle ? 'flex' : 'auto', sm: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" gap={2} pb={2}>
            <Box display="flex">
              <Typography
                sx={{
                  ...titleSx,
                }}
                variant="subtitle1"
                color="system.midnightBlue"
                display={{ xs: showTitle ? 'flex' : 'none', sm: 'block' }}
              >
                {cardDetails?.title}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: !!cardDetails?.headerLink1
                ? { xs: 'space-between', sm: 'space-around' }
                : 'flex-end',
            }}
          >
            {cardDetails?.headerLink1 && (
              <Box
                id="card-header-link-1"
                pb={4}
                sx={{
                  display: { sm: 'none' },
                  alignItems: 'flex-start',
                  width: { lg: '555px' },
                  pb: { xs: '12px', sm: '24px' },
                }}
              >
                {cardDetails?.headerLink1}
              </Box>
            )}
            {cardDetails?.headerLink2 && (
              <Box
                id="card-header-link-2"
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                {cardDetails?.headerLink2}
              </Box>
            )}
            {cardDetails?.headerIcon && (
              <Box
                id="card-header-icon"
                onClick={cardDetails?.headerIconClick}
                sx={{
                  width: 16,
                  height: 16,
                  color: cardDetails?.noDataScreen
                    ? theme.palette.system.coolGray
                    : theme.palette.system.skyBlue,
                  cursor: cardDetails?.headerIconClick ? 'pointer' : '',
                  pl: { xs: 0, sm: 4 },
                  pr: { xs: 0, sm: 4 },
                }}
                display={{ xs: 'none', sm: 'block' }}
              >
                {cardDetails?.headerIcon}
              </Box>
            )}
          </Box>
        </Box>
        {/* Secondary link array: Placed under the Card title. Is a secondary array of links if needed */}
        {cardDetails?.secondaryLinks && (
          <Box
            id="card-secondary-link"
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              marginTop: '12px',
              marginBottom: '12px',
            }}
          >
            {(cardDetails?.secondaryLinks || []).map(link => link)}
          </Box>
        )}
        {isPreference && component}
        {cardDetails?.noDataScreen
          ? cardDetails?.noDataScreen
          : Array.from(
              cardDetails?.items || [],
              ([key, item]) =>
                !_isEmpty(item.items) && (
                  <Fragment key={key}>
                    {cardDetails?.showSubtitle && (
                      <Typography
                        sx={{
                          marginTop: {
                            xs: item?.title ? '12px' : '6px',
                            sm: item?.title ? '24px' : '6px',
                          },
                        }}
                        variant="body1"
                        color="system.grayText"
                      >
                        {item.title}
                      </Typography>
                    )}
                    {cardDetails?.isAccordion ? (
                      <NestedCardDetailsItem
                        cards={item.items}
                        expandAccordion={expandAccordion}
                      />
                    ) : (
                      <CardDetailsItem cards={item.items} />
                    )}
                    {cardDetails?.showMore && (
                      <>
                        <Divider
                          sx={{
                            my: 1.75,
                          }}
                        />
                        <Button
                          id="cardlist-show-more"
                          variant="text"
                          endIcon={<ArrowDown height="15" />}
                          onClick={() => {
                            setShowShowMore(!showShowMore);
                            showMoreFunction(showShowMore);
                          }}
                          sx={{
                            width: '100%',
                            borderRadius: '4px',
                            textAlign: 'center',
                            padding: '6px',
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                        >
                          {showShowMore ? 'SHOW MORE' : 'SHOW LESS'}
                        </Button>
                      </>
                    )}
                  </Fragment>
                ),
            )}
        {cardDetails?.footerContent && cardDetails?.footerContent}
      </Card>
      {helpContact && <HelpContact contactDetails={helpContact} />}
    </>
  );
};

export const CardDetailsItem = ({
  cards,
  onCardClick,
}: {
  cards?: ICard[];
  onCardClick?: (card?: ICard) => void;
}) => {
  const onClick = useCallback(
    (card: ICard, e?: any) => {
      card?.onClick?.(card);
      onCardClick?.(card);
    },
    [onCardClick],
  );

  return (
    <>
      {cards?.map((card, idx) =>
        !!card.detailComponent ? (
          card.detailComponent
        ) : !!card.title ? (
          <Box
            id={card?.title}
            key={`${card?.id}-${idx}`}
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              paddingTop: '12px',
              paddingBottom: '12px',
              zIndex: 2,
              borderTop:
                idx > 0 ? `1px solid ${theme.palette.system.border}` : 'none',
              cursor:
                card?.titleColor === theme.palette.system.coolGray
                  ? 'default'
                  : 'pointer',
            }}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.system.hover,
              },
              ...card?.sx,
            }}
            onClick={e => onClick(card, e)}
          >
            {card?.icon}
            <Grid
              container
              ml={card?.sx && !card?.icon ? '12px' : 0}
              pl={card?.icon ? '12px' : 0}
              pr="20px"
            >
              <Grid item xs={12} sm={card?.visualIndicator ? 9 : 12}>
                <Typography
                  variant={card?.titleVariant || 'body1'}
                  color={card?.titleColor || 'system.midnightBlue'}
                  sx={{
                    lineHeight: '19px',
                  }}
                >
                  {card?.title}
                </Typography>
              </Grid>
              {!!card?.visualIndicator && (
                <Grid
                  item
                  sm={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-end"
                  paddingRight="6px"
                  sx={{
                    ...card?.visualIndicator?.sx,
                    display: {
                      xs: 'none',
                      sm: 'flex',
                    },
                  }}
                >
                  <Badge
                    id="visual-indicator"
                    badgeContent={card?.visualIndicator?.content}
                    sx={{
                      '& .MuiBadge-badge': {
                        fontWeight: 700,
                        padding: '4px 12px',
                        height: '27px',
                        fontSize: '14px',
                        borderRadius: '30px',
                        backgroundColor: card?.visualIndicator?.color,
                        color: 'white',
                        width: 'max-content',
                      },
                      marginLeft: '12px',
                    }}
                  />
                </Grid>
              )}
              {card?.subtitle && (
                <Grid
                  item
                  id="cardDetails_subtitle-container"
                  xs={12}
                  color={theme.palette.system.coolGray}
                  sx={{
                    lineHeight: '19px',
                    fontSize: '14px',
                  }}
                >
                  {card?.subtitle}
                </Grid>
              )}

              {!!card?.visualIndicator?.content && (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  pt="24px"
                  pl="32px"
                  pb="12px"
                  sx={{ display: { xs: 'flex', sm: 'none' } }}
                >
                  <Badge
                    badgeContent={card?.visualIndicator?.content}
                    sx={{
                      '& .MuiBadge-badge': {
                        fontWeight: 700,
                        padding: '4px 12px',
                        height: '27px',
                        fontSize: '14px',
                        borderRadius: '30px',
                        backgroundColor: card?.visualIndicator?.color,
                        color: 'white',
                        width: 'max-content',
                      },
                      marginLeft: '12px',
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {!card.disableEndAdornment ? (
              !!card.hasMenuItems ? (
                card?.selectFromMenu
              ) : (
                <ArrowRightIcon
                  htmlColor={theme.palette.system.coolGray}
                  sx={{ width: '16px', height: '16px' }}
                />
              )
            ) : (
              <></>
            )}
          </Box>
        ) : null,
      )}
    </>
  );
};

export const NestedCardDetailsItem = ({
  cards,
  expandAccordion = undefined,
}: {
  cards?: { [id: number]: INestedCardDetails | any } | any;
  expandAccordion?: string;
}) => {
  const onClick = useCallback(
    (card: INestedCardDetails, key?: string, e?: any) => {
      card?.onClick?.(key);
    },
    [],
  );

  return (
    <>
      {Object.keys(cards).map(key => (
        <ExpandingAccordion
          key={key}
          accordionTitle={
            <CardDetailsItem
              cards={[
                {
                  id: cards[key]?.id,
                  title: cards[key]?.title,
                  icon: cards[key]?.icon,
                  disableEndAdornment: true,
                  sx: {
                    width: '100%',
                    '&:hover': {
                      backgroundColor: 'none',
                    },
                  },
                } as ICard,
              ]}
            />
          }
          accordionContent={<CardDetailsItem cards={cards[key]?.items} />}
          onChange={() => onClick(cards[key], key)}
          expanded={
            expandAccordion === 'ALL' || expandAccordion?.toString() === key
          }
        />
      ))}
    </>
  );
};

// This is used only for the HomePageTaskCenter.tsx Task Center Widget
export const SingleCardDetailsItem = ({
  card,
  idx,
}: {
  card?: ICard;
  idx: number;
}) => {
  const onClick = useCallback((card: ICard, e?: any) => {
    card?.onClick?.(card);
  }, []);

  return (
    <>
      {!!card?.title ? (
        <Box
          id={`${card?.id}-${idx}`}
          key={`${card?.id}-${idx}`}
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '12px',
            paddingBottom: '12px',
            zIndex: 2,
            borderTop:
              idx > 0 ? `1px solid ${theme.palette.system.border}` : 'none',
            cursor:
              card?.titleColor === theme.palette.system.coolGray
                ? 'default'
                : 'pointer',
          }}
          sx={{
            ...card?.sx,
          }}
          onClick={e => onClick(card, e)}
        >
          {card?.icon}
          <Grid
            container
            ml={card?.sx && !card?.icon ? '12px' : 0}
            pl={card?.icon ? '6px' : 0}
          >
            <Grid item xs={12}>
              <Typography
                variant={card?.titleVariant || 'body1'}
                color={card?.titleColor || 'system.midnightBlue'}
                sx={{
                  lineHeight: '19px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '175px',
                }}
              >
                {card?.title}
              </Typography>
            </Grid>
            {card?.subtitle && (
              <Grid
                item
                id="cardDetails_subtitle-container"
                xs={12}
                color={theme.palette.system.coolGray}
                sx={{
                  lineHeight: '19px',
                  fontSize: '14px',
                }}
              >
                {card?.subtitle}
              </Grid>
            )}
          </Grid>
          <Grid container height="19px" width="19px">
            {!!card?.visualIndicator && (
              <Grid
                item
                display="flex"
                alignItems="center"
                paddingRight="12px"
                sx={{
                  ...card?.visualIndicator?.sx,
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                <Badge
                  id="visual-indicator"
                  badgeContent={card?.visualIndicator?.content}
                  sx={{
                    '& .MuiBadge-badge': {
                      fontWeight: 700,
                      marginRight: '12px',
                      height: '20px',
                      width: '20px',
                      fontSize: '14px',
                      borderRadius: '30px',
                      backgroundColor: card?.visualIndicator?.color,
                      color: 'white',
                    },
                    marginLeft: '12px',
                  }}
                />
              </Grid>
            )}
          </Grid>
          {!card.disableEndAdornment ? (
            !!card.hasMenuItems ? (
              card?.selectFromMenu
            ) : (
              <ArrowRightIcon
                htmlColor={theme.palette.system.coolGray}
                sx={{ width: '16px', height: '16px' }}
              />
            )
          ) : (
            <></>
          )}
        </Box>
      ) : null}
    </>
  );
};
