import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Box,
  Divider,
  Grid,
  IconButton,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import JobDetailSkeleton from 'components/Jobs/JobDetails/JobDetailSkeleton';
import { JobDetails } from 'components/Jobs/JobDetails/JobDetails';
import { JobFlagDialogContent } from 'components/Jobs/JobFlagDialogContent';
import { JobPreference } from 'components/Jobs/JobSearch/JobPreference';
import { JobSearchCard } from 'components/Jobs/JobSearch/JobSearchCard';
import JobSearchSkeletonCard from 'components/Jobs/JobSearch/JobSearchSkeletonCard';
import { MapJobViewContent } from 'components/Jobs/JobSearch/MapJobViewContent';
import NoSearchedJobs from 'components/Jobs/JobSearch/NoSearchedJobs';
import { QuickJobFilters } from 'components/Jobs/JobSearch/QuickJobFilters';
import { CustomizedDialog } from 'components/common/Dialog/CustomizedDialog';
import { LeftScrollPage } from 'components/common/PageLayout/LeftScrollPage';
import { TippyToolTip } from 'components/common/ToolTip/TippyToolTip';
import { ToolTipsTour } from 'components/common/ToolTipsTour/ToolTipsTour';
import { useSmMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { AlertBarType } from 'enums/alertBarTypes';
import { DataLayerImpressionList, DataLayerPageName } from 'enums/dataLayer';
import { JobSearchLimit } from 'enums/jobSearchLimit';
import { JobSortType } from 'enums/jobSortType';
import { MenuIcons } from 'enums/menuIcons';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { getAutoSubmitShortcuts } from 'helpers/getAutoSubmitShortcuts';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { trackEvent } from 'services/logging/appInsights';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { closeAlert } from 'store/slices/alertbar/alertbarSlice';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { setDisplayFilter } from 'store/slices/filter/filterSlice';
import {
  fetchJobs,
  fetchStrikeJobs,
  setJobSearchFilter,
  setSelectedJob,
} from 'store/slices/jobs/jobsSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { toolTipsTourActions } from 'store/slices/toolTipsTour/toolTipsTourSlice';
import {
  resetIsUserPreferenceUpdated,
  retrieveUserPreference,
  unSetError,
} from 'store/slices/user/userPreferenceSlice';
import { theme } from 'styles/theme';
import useQueryParams from 'utils/hooks/useQueryParams';
import { AMNFilterContainer } from './AMNFilterContainer';
import {
  AMNCardContainer,
  createJobSearchStyles,
} from './JobSearchPage.styles';
import { JobSelectedRenderer } from './JobSelectedRenderer';
import NoJobSearchSplashCard from './NoJobSearchSplashCard';
import { fetchDiscipline } from 'store/slices/lookups/lookupsSlice';
import { UserDisciplineTypeId } from 'enums/userDisciplineTypeId';

export const JobSearchPage = props => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isMobileDevice = useSmMediaQuery();
  const classes = createJobSearchStyles();
  const siteFooter = document?.getElementById('site-footer');

  const [mapRef, setMapRef] = useState<any>();
  const [mobilemapRef, setMobileMapRef] = useState<any>();
  const [sort, setSorting] = useState('0');
  const [currentSelectedJob, setCurrentSelectedJob] = useState<number>();
  const [isSelectedAJob, setSelectedForMobile] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [showFlags, setShowFlags] = useState<boolean>(false);
  const [showNoJobs, setShowNoJobs] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [showJobPreferenceDrawer, setShowJobPreferenceDrawer] =
    useState<boolean>(true);
  const [openPreferences, setOpenPreferences] = useState(false);

  const jobs = useAppSelector(state => state.jobs.items);

  const hasOnlyRecommended = useAppSelector(
    state => state.jobs.hasOnlyRecommended,
  );
  const totalJobs = useAppSelector(state => {
    const total = state?.jobs?.paging?.total;
    return total ? +total : total;
  }) as number | undefined;
  const jobSearchFilter = useAppSelector(state => {
    return state.jobs.jobSearchFilter;
  }) as IJobSearchRequestSlice;
  const userId = useAppSelector(state => state.auth.userId);

  const locations = useAppSelector(state => state.userPreference.locations);
  const expertiseFormFields = useAppSelector(
    state => state.userPreference.expertiseFormFields,
  );
  const selectedJobId = useAppSelector(state => state.jobs.selectedJobId);
  const userDisciplineId = useAppSelector(
    state => state.userProfile.userDisciplineTypeId,
  );

  const { promiseInProgress: isDisciplineLoading } = usePromiseTracker({
    area: 'get-discipline-data',
    delay: 0,
  });

  React.useEffect(() => {
    if (!isDisciplineLoading) {
      dispatch(
        fetchDiscipline({
          offset: '0',
          limit: '60',
          disciplineId: userDisciplineId
            ? userDisciplineId.toString()
            : `${UserDisciplineTypeId.NURSE},${UserDisciplineTypeId.ALLIED}`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Open job search drawer by default when navigated back from Auto submit page
  React.useEffect(() => {
    const { state } = window.history;
    if (
      state?.state?.from === 'toJobSearchMobile' &&
      !!state?.state?.selectedId
    ) {
      dispatch(setSelectedJob(state?.state?.selectedId));
      setCurrentSelectedJob(state.state.selectedId);
      setSelectedForMobile(true);
      setTimeout(() => {
        history.replace(`/${AppRouteNames.JOB_SEARCH}`);
      }, 1000);
    }
  }, [dispatch, history, isMobileDevice, selectedJobId]);

  const loadedForTagManager = useAppSelector(
    state => state.assignmentSummary.loadedForTagManager,
  );
  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );
  const getJobSearchLimit = useCallback(() => {
    if (window.outerWidth < theme.breakpoints.values.md) {
      return JobSearchLimit.MOBILE;
    }
    return JobSearchLimit.WEB;
  }, []);

  const query = useQueryParams();
  const isNotStrikeFilter = query.get('filter') !== 'strike';

  // infinite scroll
  const [offset, setOffset] = useState<number>(0);
  const [listLength, setListLength] = useState(offset);

  const fetchMoreData = useCallback(() => {
    const handler = setTimeout(() => {
      let newOffset = offset + getJobSearchLimit();
      setOffset(_prev => newOffset);
      if (!isNotStrikeFilter) {
        dispatch(fetchStrikeJobs(userId));
      } else {
        dispatch(
          fetchJobs({
            request: { userId, limit: getJobSearchLimit(), offset: newOffset },
          }),
        );
      }
      setListLength(newOffset);
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [offset, getJobSearchLimit, isNotStrikeFilter, dispatch, userId]);

  // Job Preference Drawer
  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent | null, open: boolean) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      if (!open) {
        dispatch(unSetError());
        dispatch(retrieveUserPreference());
      }
      if (open) {
        dispatch(resetIsUserPreferenceUpdated());
      }
      setOpenPreferences(open);
    },
    [dispatch],
  );

  const { promiseInProgress: jobsLoading } = usePromiseTracker({
    area: 'get-jobs',
    delay: 0,
  });

  const { promiseInProgress: isJobDetailLoading } = usePromiseTracker({
    area: 'get-job-details',
    delay: 0,
  });

  const setSortingPlaceholder = useCallback(
    (response: any) => {
      let value = 0;
      if (!hasOnlyRecommended) {
        if (response) {
          value = response;
        }
      } else {
        if (sort) {
          if (parseInt(sort) > 0) {
            value = parseInt(sort);
          } else {
            value = JobSortType.WeeklyPay;
          }
        } else {
          value = JobSortType.WeeklyPay;
        }
      }
      return value.toString();
    },
    [hasOnlyRecommended, sort],
  );

  const handleSortChange = (event: SelectChangeEvent) => {
    setSorting(event.target.value);
    setOffset(0);
    setListLength(0);
    dispatch(
      setJobSearchFilter({
        ...jobSearchFilter,
        limit: getJobSearchLimit(),
        sort: Number(event.target.value),
      }),
    );
  };

  const onJobCardClick = useCallback(
    (item, index) => {
      if (isMobileDevice) {
        setSelectedForMobile(true);
        if (mobilemapRef) {
          mobilemapRef.setView(item?.address?.location?.coordinates, 15);
        }
      }
      if (item?.jobId && item?.jobId !== currentSelectedJob) {
        dispatch(setSelectedJob(item?.jobId));
        setCurrentSelectedJob(item?.jobId);
      }
      if (mapRef) {
        mapRef.setView(item?.address?.location?.coordinates, 15);
      }
      dispatch(
        dataLayerActions.setJobImpressionClick({
          list: DataLayerImpressionList.JOB_SEARCH,
          position: index,
        }),
      );
    },
    [
      dispatch,
      isMobileDevice,
      mapRef,
      mobilemapRef,
      currentSelectedJob,
      userId,
    ],
  );

  const handleClose = () => {
    setShowFlags(false);
  };

  const onJobCardSwipe = useCallback(
    item => {
      setCurrentSelectedJob(item?.jobId);
      dispatch(setSelectedJob(item?.jobId));
      if (mobilemapRef) {
        mobilemapRef.setView(item?.address?.location?.coordinates, 15);
      }
      dispatch(
        dataLayerActions.setJobImpressionClick(
          DataLayerImpressionList.JOB_SEARCH,
        ),
      );
    },
    [dispatch, mobilemapRef, userId],
  );

  const handleDebounceFn = () => {
    dispatch(closeAlert({ alertbarRefId: AlertBarType.JOB_ALERT }));
    if (!isNotStrikeFilter) {
      dispatch(fetchStrikeJobs(userId));
    } else {
      dispatch(fetchJobs());
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFilterSubmit = useCallback(_debounce(handleDebounceFn, 200), []);

  const hasMore = useMemo(() => {
    let hasMore = false;
    if (totalJobs) hasMore = totalJobs - listLength > getJobSearchLimit();
    return hasMore;
  }, [getJobSearchLimit, listLength, totalJobs]);

  const jobsWithShortcuts = jobs?.map((job, index) => {
    let AUTO_SUBMIT_SHORTCUTS = getAutoSubmitShortcuts;
    const componentKeys = Object.keys(AUTO_SUBMIT_SHORTCUTS);
    const defaultComponent = 'AutoSubmitShortcut';
    const useComponent = index < getJobSearchLimit();

    const component =
      useComponent &&
      (index === 0
        ? AUTO_SUBMIT_SHORTCUTS[defaultComponent]
        : index % 10 === 0 && index !== 0
        ? AUTO_SUBMIT_SHORTCUTS[
            componentKeys[Math.floor(index / 10) % componentKeys.length]
          ]
        : null);

    return { ...job, component };
  });

  const jobsToRender = !userStatusIsLead
    ? jobsWithShortcuts
    : jobs.map(job => ({ ...job, component: null }));

  const jobCardScroll = useMemo(() => {
    let dataLength = offset + getJobSearchLimit();
    let id = 'job-search-card';
    const scrollComponent = (
      <>
        <AMNCardContainer id="amn-card-container">
          <InfiniteScroll
            className={classes.customScroll}
            dataLength={dataLength}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<JobSearchSkeletonCard />}
            height={'calc(100vh - 186px)'}
          >
            {jobsToRender?.map((item, index) => {
              const AutoSubmitShortcutComponent = item.component;

              return (
                <React.Fragment>
                  <JobSearchCard
                    id={`${id}-${index}`}
                    key={`${item.jobId}-${index}`}
                    selectedJobId={currentSelectedJob}
                    topJob={item}
                    onClick={() => {
                      onJobCardClick(item, index);
                      dispatch(toolTipsTourActions.setIndex(2));
                    }}
                    isNotStrikeFilter={isNotStrikeFilter}
                    showMap={showMap}
                  />
                  {AutoSubmitShortcutComponent && AutoSubmitShortcutComponent}
                </React.Fragment>
              );
            })}
          </InfiniteScroll>
        </AMNCardContainer>
      </>
    );
    return jobsLoading ? (
      jobs && jobs.length > 0 ? (
        // is loading, has jobs
        scrollComponent
      ) : (
        // is loading, no jobs
        <>
          <JobSearchSkeletonCard />
        </>
      )
    ) : jobs && jobs.length > 0 ? (
      // not loading, has jobs
      scrollComponent
    ) : // not loading, no jobs
    jobSearchFilter.isFavorite ? (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          color={theme.palette.system.coolGray}
          fontStyle="italic"
          lineHeight="24px"
        >
          No favorites saved.
        </Typography>
      </Box>
    ) : (
      <>
        <NoJobSearchSplashCard
          sx={
            isNotStrikeFilter
              ? { marginTop: '24px !important' }
              : {
                  marginTop: {
                    xs: '24px !important',
                    md: '0px !important',
                  },
                }
          }
          isStrikeJobs={!isNotStrikeFilter}
        />
      </>
    );
  }, [
    fetchMoreData,
    jobs,
    jobsLoading,
    listLength,
    onJobCardClick,
    currentSelectedJob,
    totalJobs,
    offset,
    isMobileDevice,
    getJobSearchLimit,
    jobSearchFilter.isFavorite,
    jobSearchFilter.sort,
    isNotStrikeFilter,
    hasMore,
    showMap,
  ]);

  function LeftContainerMobileView(showMap: boolean) {
    if (showMap && !(isSelectedAJob && isMobileDevice)) {
      return (
        <Grid container className={classes.rightContainer}>
          <AMNFilterContainer
            classname={classes.mapFilter}
            sort={sort}
            handleSortChange={handleSortChange}
            setShowMap={handleShowMap}
            showMap={showMap}
            toggleDrawer={toggleDrawer}
            setShowJobPreferenceDrawer={setShowJobPreferenceDrawer}
            locations={locations}
            expertiseFormFields={expertiseFormFields}
            getJobSearchLimit={getJobSearchLimit}
            jobSearchFilter={jobSearchFilter}
            onFilterSubmit={onFilterSubmit}
          />
          <MapJobViewContent
            jobs={jobs}
            setMapRef={setMobileMapRef}
            selectedJob={currentSelectedJob}
            onClickFxn={onJobCardClick}
            onSwipe={onJobCardSwipe}
          />
        </Grid>
      );
    } else {
      return (
        <JobSelectedRenderer
          isSelectedJob={isSelectedAJob}
          showMap={showMap}
          mobileJobListingCompoent={MobileJobListingComponent}
          onDetailPageCloseClick={setSelectedForMobile}
          isNotStrikeFilter={isNotStrikeFilter}
        />
      );
    }
  }

  const handleShowMap = () => {
    if (!showMap) {
      trackEvent('JobSearch_MapView_Clicked');
    }
    setShowMap(!showMap);
  };

  const MobileJobListingComponent = (
    <LeftScrollPage
      leftContent={
        <>
          <div className={classes.filterHeader}>
            {isNotStrikeFilter && (
              <AMNFilterContainer
                sort={sort}
                handleSortChange={handleSortChange}
                setShowMap={handleShowMap}
                showMap={showMap}
                toggleDrawer={toggleDrawer}
                setShowJobPreferenceDrawer={setShowJobPreferenceDrawer}
                locations={locations}
                expertiseFormFields={expertiseFormFields}
                getJobSearchLimit={getJobSearchLimit}
                jobSearchFilter={jobSearchFilter}
                onFilterSubmit={onFilterSubmit}
              />
            )}
            {hasOnlyRecommended && (
              <NoSearchedJobs isStrikeJobs={!isNotStrikeFilter} />
            )}
            {!showNoJobs && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '12px',
                  height: '28px',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  {!(
                    jobSearchFilter.isFavorite ||
                    sort === JobSortType.Recommended.toString()
                  ) &&
                    !hasOnlyRecommended &&
                    isNotStrikeFilter && (
                      <Typography variant="body1" component="p">
                        {` ${total} jobs found based on the filters applied`}
                      </Typography>
                    )}
                  {jobSearchFilter.isFavorite && isNotStrikeFilter && (
                    <Typography variant="body1" component="p">
                      Favorites
                    </Typography>
                  )}
                  {hasOnlyRecommended && isNotStrikeFilter && (
                    <Typography
                      color={theme.palette.system.grayText}
                      align="left"
                      sx={{
                        font: 'normal normal normal 16px/22px Open Sans',
                      }}
                      aria-label="action-items-title"
                    >
                      Recommended Jobs
                    </Typography>
                  )}
                </Box>
                {isNotStrikeFilter && (
                  <Box
                    sx={{
                      width: 'auto',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      onClick={() => setShowFlags(true)}
                      sx={{ padding: '0px' }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
          </div>
          {showFlags && (
            <CustomizedDialog
              title="Job Flags"
              body={<JobFlagDialogContent></JobFlagDialogContent>}
              isOpen={showFlags}
              handleClose={handleClose}
              dialogContentSX={{ height: '60vh', overflowY: 'scroll' }}
            />
          )}
          {!showNoJobs ? jobCardScroll : <NoSearchedJobs />}
          {isNotStrikeFilter && (
            <ToolTipsTour id="100" index={1} isMobile>
              <TippyToolTip
                id="ApplyJob-1-mobile"
                selector="#amn-card-container"
                placement="top"
                message="Select to learn more!"
              />
            </ToolTipsTour>
          )}
        </>
      }
    />
  );

  const rightContainer = useCallback(
    (showMap: boolean, showNoJobs: boolean) => {
      if (showMap) {
        return (
          <Grid
            container
            id="right-container"
            className={classes.rightContainer}
          >
            <MapJobViewContent
              jobs={jobs}
              setMapRef={setMapRef}
              selectedJob={currentSelectedJob}
              onClickFxn={onJobCardClick}
              onSwipe={onJobCardSwipe}
            />
          </Grid>
        );
      } else if (!showNoJobs && !isJobDetailLoading) {
        if (currentSelectedJob) {
          return (
            <JobDetails
              showMap={showMap}
              isNotStrikeFilter={isNotStrikeFilter}
            />
          );
        } else if (hasOnlyRecommended) {
          return (
            <div style={{ paddingLeft: '24px', width: '100%' }}>
              <NoSearchedJobs isStrikeJobs={!isNotStrikeFilter} />
            </div>
          );
        }
      } else if (!showNoJobs && isJobDetailLoading) {
        return <JobDetailSkeleton />;
      } else {
        return (
          <div style={{ paddingLeft: '24px', width: '100%' }}>
            <NoSearchedJobs isStrikeJobs={!isNotStrikeFilter} />
          </div>
        );
      }
    },
    [
      classes.rightContainer,
      hasOnlyRecommended,
      isJobDetailLoading,
      jobs,
      onJobCardClick,
      onJobCardSwipe,
      currentSelectedJob,
      isNotStrikeFilter,
    ],
  );

  useEffect(() => {
    if (!isNotStrikeFilter) {
      props.setActivePage(MenuIcons.StrikeJobs);
      dispatch(setActivePage(MenuIcons.StrikeJobs));
      dispatch(fetchStrikeJobs(userId));
    } else {
      props.setActivePage(MenuIcons.Jobs);
      dispatch(setActivePage(MenuIcons.Jobs));
      dispatch(
        setJobSearchFilter({
          ...jobSearchFilter,
          isFavorite: false,
        }),
      );
      dispatch(
        fetchJobs({
          request: { userId, limit: getJobSearchLimit() },
        }),
      );
    }
    return () => {
      dispatch(closeAlert({ alertbarRefId: AlertBarType.JOB_ALERT }));
      dispatch(setSelectedJob(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotStrikeFilter]);

  useEffect(
    () => {
      if (loadedForTagManager) {
        dispatch(
          dataLayerActions.dataLayerReadyAction(
            DataLayerPageName.JOB_SEARCH,
            isMobileDevice,
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedForTagManager],
  );

  useEffect(() => {
    if (siteFooter && isMobileDevice) {
      showMap
        ? (siteFooter.style.display = 'none')
        : (siteFooter.style.display = 'flex');
    } else if (siteFooter) {
      siteFooter.style.display = 'flex';
    }
  }, [siteFooter, isMobileDevice, showMap]);

  useEffect(() => {
    if (jobs && totalJobs) {
      if (sort === JobSortType.Recommended.toString() && isNotStrikeFilter) {
        setTotal(0);
      } else {
        setTotal(totalJobs ?? 0);
      }
    }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, sort, totalJobs, isNotStrikeFilter]);

  useEffect(() => {
    onFilterSubmit();
    dispatch(setDisplayFilter(true));

    return () => {
      dispatch(setDisplayFilter(false));
    };
  }, [dispatch, jobSearchFilter.sort, onFilterSubmit]);

  useEffect(
    () => {
      if (!showMap) {
        if (hasOnlyRecommended) {
          setCurrentSelectedJob(undefined);
          dispatch(setSelectedJob(undefined));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasOnlyRecommended, showMap],
  );

  useEffect(() => {
    if (!hasOnlyRecommended || showMap) {
      if (selectedJobId) {
        setShowNoJobs(false);
        if (selectedJobId !== currentSelectedJob) {
          setCurrentSelectedJob(selectedJobId);
          dispatch(setSelectedJob(selectedJobId));
        }

        const item = jobs?.find(t => t.jobId === selectedJobId);
        if (!item && jobs.length > 0) {
          setCurrentSelectedJob(jobs[0].jobId);
          dispatch(setSelectedJob(jobs[0].jobId));
        }

        if (mapRef) {
          if (item?.address?.location?.coordinates) {
            mapRef.setView(item?.address?.location?.coordinates, 15);
            if (mobilemapRef) {
              mobilemapRef.setView(item?.address?.location?.coordinates, 15);
            }
          }
        }
      } else if (jobs?.length > 0 && !selectedJobId) {
        setShowNoJobs(false);
        setCurrentSelectedJob(jobs[0]?.jobId);
        dispatch(setSelectedJob(jobs[0]?.jobId));
        if (mapRef) {
          mapRef.setView(jobs[0]?.address?.location?.coordinates, 15);
        }
        if (mobilemapRef) {
          mobilemapRef.setView(jobs[0]?.address?.location?.coordinates, 15);
        }
      } else {
        setShowNoJobs(true);
      }
    }
  }, [
    mapRef,
    mobilemapRef,
    jobSearchFilter,
    userId,
    currentSelectedJob,
    hasOnlyRecommended,
    showMap,
    selectedJobId,
    jobs,
    dispatch,
  ]);

  useEffect(() => {
    setSorting(
      setSortingPlaceholder(jobSearchFilter?.sort) ||
        JSON.stringify(JobSortType.WeeklyPay),
    );
  }, [jobSearchFilter?.sort, setSortingPlaceholder]);

  const quickFilterContent = useMemo(() => {
    return (
      <QuickJobFilters
        setShowJobPreferenceDrawer={setShowJobPreferenceDrawer}
        toggleDrawer={toggleDrawer}
        jobSearchFilter={jobSearchFilter}
      />
    );
  }, [jobSearchFilter, setShowJobPreferenceDrawer, toggleDrawer]);

  const trackZoomInClick = () => {
    trackEvent(`JobSearch_MapZoom_In_Clicked`);
  };

  const trackZoomOutClick = () => {
    trackEvent(`JobSearch_MapZoom_Out_Clicked`);
  };

  useEffect(() => {
    let zoomIn: Element;
    let zoomOut: Element;
    if (mapRef || mobilemapRef) {
      zoomIn = document.getElementsByClassName('leaflet-control-zoom-in')[0];
      zoomOut = document.getElementsByClassName('leaflet-control-zoom-out')[0];
      if (zoomIn) {
        zoomIn.addEventListener('click', trackZoomInClick);
      }
      if (zoomOut) {
        zoomOut.addEventListener('click', trackZoomOutClick);
      }
    }
    return () => {
      if (!!zoomIn) zoomIn.removeEventListener('click', trackZoomInClick);
      if (!!zoomOut) zoomOut.removeEventListener('click', trackZoomOutClick);
    };
  }, [mapRef, mobilemapRef]);

  return (
    <div>
      <Box id="job-search-page" sx={{ display: { xs: 'none', md: 'flex' } }}>
        {/* This is Desktop view */}
        <LeftScrollPage
          leftContent={
            <>
              <div className={classes.filterHeader}>
                {isNotStrikeFilter && (
                  <>
                    <AMNFilterContainer
                      sort={sort}
                      handleSortChange={handleSortChange}
                      setShowMap={handleShowMap}
                      showMap={showMap}
                      toggleDrawer={toggleDrawer}
                      setShowJobPreferenceDrawer={setShowJobPreferenceDrawer}
                      locations={locations}
                      expertiseFormFields={expertiseFormFields}
                      getJobSearchLimit={getJobSearchLimit}
                      jobSearchFilter={jobSearchFilter}
                      onFilterSubmit={onFilterSubmit}
                    />

                    {hasOnlyRecommended && !jobsLoading && (
                      <>
                        <NoJobSearchSplashCard
                          sx={
                            isNotStrikeFilter
                              ? { marginTop: '24px !important' }
                              : {
                                  marginTop: {
                                    xs: '24px !important',
                                    md: '0px !important',
                                  },
                                }
                          }
                          isStrikeJobs={!isNotStrikeFilter}
                        />
                        {isNotStrikeFilter ? (
                          <>
                            <br />
                            <Divider className="top-divider" />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                )}

                {hasOnlyRecommended && <br />}
                {jobs && (selectedJobId || hasOnlyRecommended) && !jobsLoading && (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      {!(
                        jobSearchFilter.isFavorite ||
                        sort === JobSortType.Recommended.toString()
                      ) &&
                        !hasOnlyRecommended &&
                        isNotStrikeFilter && (
                          <Typography variant="body1" component="p">
                            {` ${total} jobs found based on the filters applied`}
                          </Typography>
                        )}
                      {jobSearchFilter.isFavorite && isNotStrikeFilter && (
                        <Typography variant="body1" component="p">
                          Favorites
                        </Typography>
                      )}
                      {hasOnlyRecommended && isNotStrikeFilter && (
                        <Typography
                          color={theme.palette.system.grayText}
                          align="left"
                          sx={{
                            font: 'normal normal normal 16px/22px Open Sans',
                          }}
                          aria-label="action-items-title"
                        >
                          Recommended Jobs
                        </Typography>
                      )}
                    </Grid>
                    {isNotStrikeFilter && (
                      <Grid item xs={1} justifyContent="flex-end">
                        <IconButton
                          onClick={() => setShowFlags(true)}
                          sx={{ padding: 0 }}
                        >
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
              {showFlags && (
                <CustomizedDialog
                  title="Job Flags"
                  body={<JobFlagDialogContent />}
                  isOpen={showFlags}
                  handleClose={handleClose}
                  dialogContentSX={{ height: '60vh', overflowY: 'scroll' }}
                />
              )}
              {!isMobileDevice && jobCardScroll}
            </>
          }
          rightContent={rightContainer(showMap, showNoJobs)}
          quickFilterContent={
            !isNotStrikeFilter ? undefined : quickFilterContent
          }
          drawerContent={
            showJobPreferenceDrawer ? (
              <JobPreference
                open={openPreferences}
                toggleDrawer={toggleDrawer}
              />
            ) : (
              <></>
            )
          }
        />
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        {LeftContainerMobileView(showMap)}
      </Box>
    </div>
  );
};
