import {
  ApplicationInsights,
  ICustomProperties,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

const browserHistory = createBrowserHistory();
const globalProperties = {
  ENV: process.env.REACT_APP_PASSPORT_ENV,
  LogSource: 'PassportWeb', // Add LogSource so that metrics for web and mobile app could be captured
};
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS as string,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
      '*.queue.core.windows.net',
      '*.onelogin.com',
      '*.amnhealthcare.com',
    ],
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

const trackException = (error: any) => {
  appInsights.trackException({ error });
};

const trackEvent = (
  event: string,
  customProperties?: ICustomProperties | null,
) => {
  if (!customProperties) {
    appInsights.trackEvent({ name: event }, globalProperties);
    ReactGA.event(event, globalProperties);
  } else {
    appInsights.trackEvent(
      { name: event },
      { ...globalProperties, ...customProperties },
    );
    ReactGA.event(event, { ...globalProperties, ...customProperties });
  }
};

export { reactPlugin, appInsights, trackException, trackEvent };
