import { httpSuccess } from 'helpers/serviceHelper';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getImpactTrackerService } from 'services/ImpactTracker/impactTrackerService';
import { RootState } from 'store/configureStore';
import { impactTrackerActions } from 'store/slices/ImpactTracker/impactTrackerSlice';

const trackedImpactTrackerGet = (fn, ...args) =>
  trackPromise(fn(...args), 'get-impact-tracker');

const userId = (state: RootState) => state.auth.userId;

function* getImpactTracker(action) {
  try {
    const currentUserId = yield select(userId);
    const response = yield call(
      trackedImpactTrackerGet,
      getImpactTrackerService,
      currentUserId,
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      yield put(impactTrackerActions.setImpactTracker(data));
    }
  } catch (error: any) {}
}

export function* impactTrackerSaga() {
  yield all([
    takeLatest(impactTrackerActions.getImpactTracker.type, getImpactTracker),
  ]);
}
