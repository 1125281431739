import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ArrowRightIcon = createSvgIcon(
  <svg
    id="Controls-Icon-Arrow-Right"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  >
    <rect id="Rectangle_2316" data-name="Rectangle 2316" />
    <path
      id="Path_3770"
      data-name="Path 3770"
      d="M42.892,35.24A1.742,1.742,0,0,0,42.376,34L25.894,17.631a1.766,1.766,0,0,0-2.486,0,1.737,1.737,0,0,0,0,2.471L38.646,35.24l-15,14.9a1.739,1.739,0,0,0,0,2.471,1.768,1.768,0,0,0,2.487,0L42.376,36.476A1.742,1.742,0,0,0,42.892,35.24Z"
      transform="translate(-13.892 -16.119)"
    />
  </svg>,
  'ArrowRight',
);
