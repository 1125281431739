import {
  StrikeAssignmentSummaryStatus,
  TravelAssignmentSummaryStatus,
} from 'enums/assignmentStatus';
import { IStrikeDetails } from 'interfaces/Assignment/AssignmentDetail';
import moment from 'moment';

/**
 * Method to find assignment status by assignmentStatusId
 *
 * @param activityStatusId - activityStatusId of each assignment item
 */

export const getAssignmentStatus = (
  activityStatusId: number,
  isStrike: boolean = false,
  strikeDetails?: IStrikeDetails,
) => {
  if (isStrike) {
    if (activityStatusId === 1 || activityStatusId === 2) {
      return strikeDetails &&
        isConfirmed(strikeDetails) &&
        activityStatusId === 2
        ? StrikeAssignmentSummaryStatus.CONFIRMED
        : StrikeAssignmentSummaryStatus.APPLIED;
    }
    if (activityStatusId >= 3 && activityStatusId <= 18) {
      return strikeDetails && isConfirmed(strikeDetails)
        ? StrikeAssignmentSummaryStatus.CONFIRMED
        : StrikeAssignmentSummaryStatus.SUBMITTED;
    }

    if (
      activityStatusId === 21 ||
      activityStatusId === 22 ||
      activityStatusId === 28
    ) {
      return StrikeAssignmentSummaryStatus.ASSIGNMENT_UPDATE;
    }

    if (activityStatusId === 23) {
      return StrikeAssignmentSummaryStatus.ON_ASSIGNMENT;
    }

    if (activityStatusId === 24) {
      return StrikeAssignmentSummaryStatus.ASSIGNMENT_COMPLETE;
    }

    if (
      activityStatusId === 20 ||
      (activityStatusId >= 25 && activityStatusId <= 27) ||
      activityStatusId === 29
    ) {
      return StrikeAssignmentSummaryStatus.ASSIGNMENT_ENDED;
    }
  } else {
    if (
      activityStatusId === 1 ||
      activityStatusId === 2 ||
      activityStatusId === 3 ||
      activityStatusId === 6 ||
      (activityStatusId >= 8 && activityStatusId < 10) ||
      activityStatusId === 16
    ) {
      return TravelAssignmentSummaryStatus.SUBMITTED;
    }
    if (
      (activityStatusId >= 10 && activityStatusId <= 15) ||
      activityStatusId === 17 ||
      activityStatusId === 18
    ) {
      return TravelAssignmentSummaryStatus.BOOKED;
    }
    if (activityStatusId === 23) {
      return TravelAssignmentSummaryStatus.ON_ASSIGNMENT;
    }
    if (activityStatusId >= 24 && activityStatusId <= 27) {
      return TravelAssignmentSummaryStatus.PAST_ASSIGNMENT;
    }
  }
};

const isConfirmed = (strikeDetails: IStrikeDetails) => {
  const postNotice =
    strikeDetails.noticeDate !== null &&
    moment(strikeDetails.noticeDate).isSameOrBefore(moment());
  const travelConfirmed = strikeDetails.travelConfirmed;
  const clearedToTravel = strikeDetails.okayToTravel;
  const arrived = strikeDetails.arrived;

  return postNotice && (travelConfirmed || clearedToTravel || arrived);
};
