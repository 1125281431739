import { PhoneNumberType } from 'enums/phoneNumberType';

export const defaultValues = {
  userId: '',
  sameAddress: false,
  emailAddress: '',
  emailAddress2: '',
  mobilePhone: {
    phoneNumberType: PhoneNumberType.CELLPHONE,
    countryDialingCode: '',
    phoneNumber: '',
  },
  homePhone: {
    phoneNumberType: PhoneNumberType.PERMANENT,
    countryDialingCode: '',
    phoneNumber: '',
  },
  fax: {
    phoneNumberType: PhoneNumberType.FAX,
    countryDialingCode: '',
    phoneNumber: '',
  },
  emergencyContact: {
    name: '',
    relationship: '',
    phoneNumber: {
      countryDialingCode: '',
      phoneNumber: '',
    },
    country: 'USA',
    state: '',
    zipCode: '',
    address1: '',
    address2: '',
    city: '',
    iso2CountryCode: 'US',
  },
  primarySocialMedia: '',
  linkedInUrl: '',
  facebookUrl: '',
  instagramUrl: '',
  twitterUrl: '',
  tiktokUrl: '',
  permanentAddress: {
    country: 'USA',
    state: '',
    zipCode: '',
    address1: '',
    address2: '',
    city: '',
    iso2CountryCode: 'US',
  },
  currentAddress: {
    country: 'USA',
    state: '',
    zipCode: '',
    address1: '',
    address2: '',
    city: '',
    iso2CountryCode: 'US',
  },
};
