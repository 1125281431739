import React from 'react';
import { theme } from 'styles/theme';
import { RadioGroup } from '@mui/material';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { IRadioItems, ItineraryConfirmation } from './ConfirmItinerary';

interface DisplayHandler {
  changeRadioSelection: (value: string) => void;
}

interface Props {
  displayComponentRef: React.RefObject<DisplayHandler>;
  dataRef: string;
  getInputDetails: (value: string) => void;
}

export const ConfirmItineraryRadioGroup: React.FC<Props> = ({
  displayComponentRef,
  dataRef,
  getInputDetails,
}) => {
  const [confirmItinerary, setConfirmItinerary] =
    React.useState<string>(dataRef);

  const handleConfirm = (value: string) => {
    setConfirmItinerary(value);
    displayComponentRef?.current?.changeRadioSelection(value);
    getInputDetails(value);
  };

  const radioButtonItems = React.useMemo<IRadioItems[]>(
    () => [
      {
        id: 'itineraryConfirm',
        name: 'itineraryConfirm',
        value: ItineraryConfirmation.CONFIRM,
        radioLabel: 'Travel Details Correct',
      },
      {
        id: 'changeTravelMode',
        name: 'changeTravelMode',
        value: ItineraryConfirmation.CHANGE_TRAVEL_MODE,
        radioLabel: 'Change Mode of Travel',
      },
      {
        id: 'itineraryReject',
        name: 'itineraryReject',
        value: ItineraryConfirmation.REJECT,
        radioLabel: 'Travel Details Incorrect',
      },
      {
        id: 'removeFromStrike',
        name: 'removeFromStrike',
        value: ItineraryConfirmation.REMOVE_FROM_STRIKE,
        radioLabel: 'Remove me from Strike',
      },
    ],
    [],
  );

  return (
    <RadioGroup
      aria-label="confirm-travel-itinerary"
      radioGroup="confirm-travel-itinerary"
      name="confirmTravelItinerary"
      value={confirmItinerary}
      onChange={e => handleConfirm(e.target.value)}
    >
      {radioButtonItems.map(val => (
        <RadioButton
          id={val.id}
          name={val.name}
          value={val.value}
          radioLabel={val.radioLabel}
          radioGroup="confirm-travel-itinerary"
          labelStyle={{
            fontSize: '14px',
            color: theme.palette.system.midnightBlue,
            lineHeight: '19px',
          }}
        ></RadioButton>
      ))}
    </RadioGroup>
  );
};
