import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ShareIcon = createSvgIcon(
  <svg
    id="Controls_icon_Share"
    data-name="Controls/icon/Share"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2310"
      data-name="Rectangle 2310"
      fill="none"
      opacity="0"
    />
    <g id="Group_5858" data-name="Group 5858" transform="translate(4 1)">
      <path
        id="Path_3763"
        data-name="Path 3763"
        d="M297.783,404.656a1.453,1.453,0,0,0-.428-.26l0,0c-.007-.005-.02,0-.029-.005a1.6,1.6,0,0,0-.469-.084,1.515,1.515,0,0,0-.154.011,2,2,0,0,0-.5.11,1.464,1.464,0,0,0-.434.261l-7.506,6.788a1.235,1.235,0,0,0,0,1.869,1.573,1.573,0,0,0,2.067,0l5.011-4.533,0,17.063a1.469,1.469,0,0,0,2.922.047v-17.11l5.011,4.533a1.573,1.573,0,0,0,2.067,0,1.235,1.235,0,0,0,0-1.869l-7.506-6.789C297.815,404.671,297.8,404.668,297.783,404.656Z"
        transform="translate(-281.29 -404.303)"
      />
      <path
        id="Path_4883"
        data-name="Path 4883"
        d="M311.333,416.033h-.006a1.367,1.367,0,0,0-1.409,1.32v17.683a.277.277,0,0,1-.282.264h-24.8a.278.278,0,0,1-.282-.264v-9.61h.006v-8.071a1.366,1.366,0,0,0-1.409-1.322h-.006a1.367,1.367,0,0,0-1.409,1.32v8.074h.006v9.61a3.012,3.012,0,0,0,3.1,2.908h24.8a3.012,3.012,0,0,0,3.1-2.908V417.355A1.366,1.366,0,0,0,311.333,416.033Z"
        transform="translate(-281.742 -401.943)"
      />
    </g>
  </svg>,
  'ShareIcon',
);
