import { AxiosResponse } from 'axios';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as service from 'services/sso/ssoService';
import { RootState } from 'store/configureStore';
import { ssoActions } from 'store/slices/sso/ssoSlice';
import { SsoResponse, ISsoTokenResponse } from 'types/SsoResponse';

const userId = (state: RootState) => state.auth.userId;

function* ssoKaiser(action) {
  let currentUserId = yield select(userId);
  try {
    const { data }: AxiosResponse<SsoResponse> = yield call(
      service.kaiserSso,
      currentUserId,
    );
    yield put(ssoActions.setToken(data as ISsoTokenResponse));
    window.open('/kaiser', '__blank');
  } catch (e) {
    yield put(ssoActions.setSsoError(e as IErrorResponse));
  }
}

// Watcher Sagas
function* watchSso() {
  yield takeEvery(ssoActions.getKaiserToken.type, ssoKaiser);
}

export function* ssoSaga() {
  yield all([watchSso()]);
}
