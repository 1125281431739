import React from 'react';
import { createSvgIcon } from '@mui/material';

export const MailIcon = createSvgIcon(
  <svg
    id="Controls-icon-Email"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2268"
      data-name="Rectangle 2268"
      fill="none"
      opacity="0"
    />
    <path
      id="Path_3734"
      data-name="Path 3734"
      d="M45.144,16.334H14.606a2.773,2.773,0,0,0-2.731,2.808V39.526a2.773,2.773,0,0,0,2.731,2.808H45.144a2.773,2.773,0,0,0,2.731-2.808V19.142A2.773,2.773,0,0,0,45.144,16.334Zm.248,22.228-8.875-9.127,8.875-8.077Zm-31.034-17.2,8.875,8.077-8.875,9.127Zm15.517,10.71L15.388,18.887H44.362Zm-5.524-1.618,4.7,4.279a1.218,1.218,0,0,0,1.645,0l4.7-4.279,9.073,9.33H15.278Z"
      transform="translate(-10.875 -10.334)"
    />
  </svg>,
  'MailIcon',
);
