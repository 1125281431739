import React from 'react';
import { createSvgIcon } from '@mui/material';

export const TrashIcon = createSvgIcon(
  <svg
    id="Controls-icon-Trash"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <rect
      id="Rectangle_2253"
      data-name="Rectangle 2253"
      fill="none"
      opacity="0"
    />
    <g id="Group_2525" data-name="Group 2525" transform="translate(4 1)">
      <path
        id="Path_2930"
        data-name="Path 2930"
        d="M46.064,16.576H38.413l-.5-2.358a.906.906,0,0,0-.889-.716h-8.9a.906.906,0,0,0-.889.716l-.494,2.358H19.092a1.5,1.5,0,1,0,0,2.992h.019V46.21A3.314,3.314,0,0,0,22.442,49.5H42.715a3.314,3.314,0,0,0,3.331-3.292V19.568h.018a1.5,1.5,0,1,0,0-2.992ZM28.866,15.3h7.423l.269,1.278H28.6ZM43.018,46.21a.3.3,0,0,1-.3.3H22.442a.3.3,0,0,1-.3-.3V19.568H43.018Z"
        transform="translate(-17.578 -13.502)"
      />
      <path
        id="Path_2931"
        data-name="Path 2931"
        d="M25.489,42.518a.9.9,0,0,0,.908-.9V21.3a.908.908,0,0,0-1.817,0V41.62A.9.9,0,0,0,25.489,42.518Z"
        transform="translate(-16.099 -12.144)"
      />
      <path
        id="Path_2932"
        data-name="Path 2932"
        d="M30.121,42.518a.9.9,0,0,0,.908-.9V21.3a.908.908,0,0,0-1.817,0V41.62A.9.9,0,0,0,30.121,42.518Z"
        transform="translate(-15.121 -12.144)"
      />
      <path
        id="Path_2933"
        data-name="Path 2933"
        d="M34.753,42.518a.9.9,0,0,0,.908-.9V21.3a.908.908,0,0,0-1.817,0V41.62A.9.9,0,0,0,34.753,42.518Z"
        transform="translate(-14.143 -12.144)"
      />
    </g>
  </svg>,
  'Trash',
);
