import React from 'react';
import Playstore from 'assets/icons/Playstore/Playstore.svg';

export const PlaystoreIcon = () => {
  return (
    <a href="http://play.google.com/store/apps/details?id=com.amn.amnone">
      <img
        height="40px"
        width="135px"
        src={Playstore}
        alt="GET IT ON Google Play"
      />
    </a>
  );
};
