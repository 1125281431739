import React, { useCallback, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SkyBlueArrowDownIcon } from 'assets/icons/Arrows/SkyBlue-Arrow-Down';
import { IDropdownItem } from 'interfaces/Props/IDropdownOption';
import { theme } from 'styles/theme';
import { createDropdownStyles } from './Dropdown.styles';

export const Dropdown = ({
  label,
  selectedValue,
  defaultValue,
  menuItems,
  disabled,
  handleOnChange,
}: IDropdownItem) => {
  const classes = createDropdownStyles();
  const selectRef = React.useRef();
  const [anchorEl, setAnchorEl] = useState<any>();

  const recordButtonPosition = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const onChange = useCallback(
    (e: any) => {
      handleOnChange?.(e.target.value);
    },
    [handleOnChange],
  );

  useEffect(() => {
    setAnchorEl(selectRef.current);
  }, [selectRef]);

  return (
    <FormControl
      sx={{
        width: '100%',
        backgroundColor: theme.palette.system.white,
      }}
      onClick={recordButtonPosition}
      className={classes.formContainer}
    >
      <Select
        className={classes.selectContainer}
        labelId="dropdown-select"
        id="dropdown-selector"
        data-testid="dropdown-select"
        defaultValue={defaultValue}
        value={selectedValue}
        ref={selectRef}
        disabled={disabled}
        onChange={onChange}
        startAdornment={
          <InputAdornment
            position="end"
            sx={{
              color: theme.palette.system.midnightBlue,
              pr: 1,
            }}
            data-testid="dropdown-select_label"
          >
            {label}
          </InputAdornment>
        }
        IconComponent={SkyBlueArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          anchorEl: anchorEl,
          PaperProps: {
            sx: {
              marginTop: '6px',
              width: {
                xs: anchorEl?.parentNode?.clientWidth || 'auto',
                md: anchorEl?.parentNode?.clientWidth - 6 || 'auto',
              },
              maxHeight: {
                xs: '288px',
                md: '180px',
              },
            },
          },
        }}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'system.grayBorder',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'system.grayBorder',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'system.grayBorder',
          },
        }}
      >
        {(menuItems || [])?.map(menu => (
          <MenuItem
            data-testid="dropdown-menuitem"
            key={menu.key}
            value={menu.key}
            sx={{ whiteSpace: 'break-spaces' }}
          >
            {menu.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
