import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserProfilePdfState } from './userProfilePdfState';

const initialState: IUserProfilePdfState = {
  userId: '',
  userProfilePdfRequest: {
    profile: {
      fullName: false,
      profileImage: false,
      verifiedDisciplineSpecialties: false,
      travelerId: false,
      contactInformation: {
        mobilePhone: false,
        otherPhone: false,
      },
      emailAddress: false,
      permanentAddress: false,
    },
    workHistory: {
      company: false,
      references: false,
    },
    education: {
      schools: false,
    },
    skillsAssessments: {
      skills: false,
    },
    credentials: {
      licenses: false,
      certifications: false,
      educationAndOnlineExams: false,
      humanResources: false,
      employeeHealth: false,
      clinicalQualification: false,
    },
    professionalSummary: '',
  },
  userProfilePdfResponse: {
    file: '',
  },
  checkedElements: [],
  isSubmitLoaderActive: false,
};

const userProfilePdfSlice = createSlice({
  name: 'userProfilePdf',
  initialState,
  reducers: {
    generateUserProfilePdf(state, action) {
      state.isSubmitLoaderActive = true;
    },
    setUserProfilePdf(state, action: PayloadAction<IUserProfilePdfState>) {
      state.isSubmitLoaderActive = false;
      state.userId = action.payload.userId;
      state.userProfilePdfRequest = action.payload.userProfilePdfRequest;
      state.userProfilePdfResponse = action.payload.userProfilePdfResponse;
      state.checkedElements = action.payload.checkedElements;
    },
    resetUserProfilePdf() {
      return { ...initialState };
    },
  },
});

export const {
  generateUserProfilePdf,
  setUserProfilePdf,
  resetUserProfilePdf,
} = userProfilePdfSlice.actions;
export default userProfilePdfSlice.reducer;
