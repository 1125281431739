import React from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { theme } from 'styles/theme';

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
}));

const StyledArrowForwardIosSharpIcon = styled(ArrowForwardIosSharpIcon)`
  color: ${theme.palette.system.skyBlue};
`;

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<StyledArrowForwardIosSharpIcon sx={{ fontSize: '1.1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-root': {
    margin: '0px 0px 24px 6px !important',
  },

  '& .MuiAccordionSummary-content': {
    margin: '0px 0px 0px 6px !important',
  },
  padding: 0,
  minHeight: 'auto',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '36px',
  },
}));

export const nestedAccordStyles = makeStyles(theme => ({
  container: {
    margin: '0 !important',
    '&:not(:last-child)': {
      '& .MuiAccordionSummary-root': {
        borderBottom: `1px solid ${theme.palette.system.border}`,
      },
      '& .MuiCollapse-entered': {
        borderBottom: `1px solid ${theme.palette.system.border}`,
      },
    },
    '&.Mui-expanded:last-child': {
      '& .MuiAccordionSummary-root': {
        borderBottom: `1px solid ${theme.palette.system.border}`,
      },
    },
  },
  summary: {
    minHeight: '43px !important',
    padding: 0,
    '& .MuiAccordionSummary-content': {
      padding: '12px 0',
      margin: '0 !important',
      '& .MuiBox-root': {
        padding: '0 !important',
      },
    },
  },
  details: {
    paddingRight: 0,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.system.border}`,
    },
  },
}));

export { AccordionSummary, Accordion, AccordionDetails };
