export enum JobBadge {
  Strike = 'Strike',
  FEMA = 'FEMA',
  CF = 'CF Considered',
  CriticalStaffing = 'Rapid Response/Crisis',
  Crisis = 'Crisis',
  Winter = 'Winter',
  EMR = 'EMR',
  MatChildHealth = 'Mat. Child Health',
  Exclusive = 'Exclusive',
  Vaccine = 'Vaccine',
  StrikeNoticeReceived = 'Strike Notice Received',
}
