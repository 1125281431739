import React, {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { ArrowRightIcon } from 'assets/icons/Arrows/Arrow-Right';
import InfoIcon from 'assets/icons/Info/InfoIcon';
import MenuIcon from 'assets/icons/NavMenuIcon/NavMenuIcon';
import AMNBadge from 'components/AMNBadge/AMNBadge';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { AppLink } from 'components/common/Link/Link';
import { NotificationsDropdown } from 'components/Notifications/NotificationsDropdown';
import { PhotoUpload } from 'components/PhotoUpload/PhotoUpload';
import { ProfileCardAvatar } from 'components/Profile/Profile.styles';
import ProfileMenu from 'components/Profile/ProfileMenu/ProfileMenu';
import NavMenuContainer from 'containers/NavMenuContainer/NavMenuContainer';
import { AvatarTextVariant } from 'enums/avatarTextVariant';
import { DrawerButtonTypes } from 'enums/buttons';
import { FeatureFlagType } from 'enums/featureFlags';
import { MenuIcons } from 'enums/menuIcons';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { generateAvatarShortText } from 'helpers/generateAvatarShortText';
import { openInNewTab } from 'helpers/openInNewTab';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { RootState, useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import {
  setActivePage,
  setMenuState,
} from 'store/slices/navigation/navigationSlice';
import { notificationsActions } from 'store/slices/notifications/notificationsSlice';
import { setPhotoEditingState } from 'store/slices/photo/photoSlice';
import { setProfileMenuState } from 'store/slices/profileMenu/profileMenuSlice';
import { theme } from 'styles/theme';
import {
  default as mapMenuIconSection,
  default as mapMenuIconsection,
} from 'utils/mapMenuIconNameSection';
import AMNLogo from '../../assets/images/Controls-Logo.svg';
import {
  StylesHomePageIcon,
  StylesNotificationIcon,
  StylesProfileMenuOpenBorder,
} from './SiteHeader.styles';
import { CommunityTabType } from 'enums/communityTabType';
import { BottomNavigationAction, useMediaQuery } from '@mui/material';
import { StylesBottomNavAction } from 'components/Navigation/MobileNavMenu/MobileNavMenu.styles';
import { mobileTabletWidth } from 'styles/media';
import { ADP_URL, PRINT_SHOP_NEAR_ME_URL } from 'constants/passportUrls';
import EndScreenShareButton from 'components/FaqHelpCenter/ScreenShare/EndScreenShareButton';
import { AppRouteNames } from 'routes/appRouteNames';
import ProfileLink from 'components/Profile/ProfileLink';
import { ArrowLeftIcon } from 'assets/icons/Arrows/Arrow-Left';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { isLocumsSelector } from 'store/selectors/authSelector';

export const SiteHeader = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const pdfRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [notifAnchorEl, setNotifAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const user: IUserProfile | undefined = useAppSelector(state => {
    if (state.userProfile) {
      return state.userProfile;
    } else {
      return {} as IUserProfile;
    }
  });
  const isOpen: boolean = useAppSelector(state => state.profileMenu.isOpen);
  const activePage: MenuIcons | string = useAppSelector(
    state => state.nav.activePage,
  );
  const isEditing: boolean = useAppSelector(
    state => state.photoEditing.isEditing,
  );
  const userStatusTypeId: number = useAppSelector(
    state => state.userProfile.userStatusTypeId,
  );
  const unreadCount = useAppSelector(
    state => state.notifications.unreadNotificationsCount,
  );
  const featureFlags = useAppSelector(state => state.userProfile.featureFlags);
  const isMenuOpen: boolean = useAppSelector(
    (state: RootState) => state.nav.isMenuOpen,
  );

  const isLocums: boolean = useAppSelector(isLocumsSelector);

  const matchesMaxWidth1024 = useMediaQuery(mobileTabletWidth);

  const handlePhotoEditingClose = () => {
    dispatch(setPhotoEditingState(false));
  };

  const toggleDrawer = (event: KeyboardEvent | MouseEvent, open: boolean) => {
    if (userStatusTypeId === UserStatusTypeId.LEAD) {
      history.push(mapMenuIconSection(MenuIcons.Profile));
      return;
    }

    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' ||
        (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const handleHomePageIconClick = () => {
    dispatch(setActivePage(MenuIcons.Home));
    dispatch(setMenuState(false));
    history.push(`/home`);
  };

  const handleProfileIconClick = () => {
    if (!isEditing) {
      dispatch(setProfileMenuState(!isOpen));
    }
  };

  const handleNotificationClick = (event: MouseEvent<HTMLButtonElement>) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotifAnchorEl(null);
  };

  const StylesProfileMenuOpenBorderDynamic = {
    ...StylesProfileMenuOpenBorder,
    borderColor: isOpen ? 'system.skyBlue' : 'system.white',
  };

  // Call unread notifications count
  useEffect(() => {
    dispatch(notificationsActions.getUnreadNotificationsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Per A.C.'s fetch just 30 notifications initially.
  const limit = 30;

  useEffect(() => {
    if (Boolean(notifAnchorEl)) {
      dispatch(
        notificationsActions.getNotificationsAction({
          limit,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifAnchorEl]);

  const NotificationIcon = useMemo(() => {
    return (
      <Badge
        color="error"
        variant="dot"
        overlap="circular"
        invisible={!unreadCount || activePage === MenuIcons.Notifications}
      >
        <MenuIcon
          color={theme.palette.system.coolGray}
          icon={MenuIcons.Notifications}
          active={Boolean(notifAnchorEl)}
        />
      </Badge>
    );
  }, [unreadCount, notifAnchorEl, activePage]);

  const profileIcon = useMemo(() => {
    return (
      <>
        <Badge
          color="error"
          variant="dot"
          overlap="circular"
          invisible={
            userStatusTypeId === UserStatusTypeId.LEAD ||
            (user?.promotionNotifications?.unreadCount || 0) === 0
          }
        >
          {user.profileImage && user.profileImage.url && (
            <ProfileCardAvatar
              sx={{ height: '31px', width: '31px', cursor: 'pointer' }}
              image={user?.profileImage?.url}
            />
          )}
          {(!user.profileImage || !user?.profileImage?.url) && (
            <Box
              sx={{
                borderRadius: '50%',
                backgroundColor: theme.palette.system.marineBlueLight,
                height: '37px',
                width: '37px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Typography
                fontSize="22px"
                fontWeight={600}
                color={theme.palette.system.white}
                lineHeight="14px"
              >
                {generateAvatarShortText(
                  `${user.firstName}`,
                  AvatarTextVariant.FIRST_ONLY,
                )}
              </Typography>
            </Box>
          )}
        </Badge>
      </>
    );
  }, [
    user.firstName,
    user.profileImage,
    user?.promotionNotifications?.unreadCount,
    userStatusTypeId,
  ]);

  /* Checking for postId after /community
  If postId is present then we don't want to show the current active page text that says Community.
  Instead we want to show the back arrow so the user can navigate back to the main Community page on mobile/tablet devices.  */
  const urlArray = window.location.href.split('/');
  const postId = parseInt(urlArray[urlArray.length - 1]);
  const activeTab = useAppSelector(state => {
    return state.community.activeCommunityTab ?? CommunityTabType.HIGHLIGHTS;
  }) as CommunityTabType;

  const returnToCommunitySection = () => {
    if (activeTab === CommunityTabType.BLOGS) {
      history.push(mapMenuIconsection(MenuIcons.Home));
    } else {
      history.push(mapMenuIconsection(MenuIcons.Community));
    }
  };

  const showCommunityBackArrow = useMemo(
    () => activePage && activePage === MenuIcons.Community && !isNaN(postId),
    [activePage, postId],
  );

  const showHearFromCommunityBackArrow = useMemo(
    () => activePage && activePage === MenuIcons.HearFromClinician,
    [activePage],
  );

  const useOpenRequirementsFlag = useMemo(() => {
    return (
      featureFlags && featureFlags[FeatureFlagType.Credential_Center]?.isEnabled
    );
  }, [featureFlags]);

  const handleToggleMenu = () => {
    dispatch(setMenuState(!isMenuOpen));
  };

  const assignmentData = useAppSelector(
    state => state.assignmentSummary.assignmentState,
  );

  const isFromRecommendedJobsPage = useAppSelector(
    state => state.jobsV2.isFromRecommendedJobsPage,
  );

  const handleBackToSearch = useCallback(() => {
    if (isFromRecommendedJobsPage) {
      dispatch(setActivePage(MenuIcons.Home));
      history.push(`/${AppRouteNames.HOME}`);
      dispatch(cognitiveJobActions.setIsFromRecommendedJobsPage(false));
    }
  }, [history, isFromRecommendedJobsPage]);

  const subHeaderBarContent = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ProfileLink
          id="back-to-search"
          sx={{ paddingLeft: '16px' }}
          underline={'none'}
          leftIcon={
            <ArrowLeftIcon
              sx={{
                width: '18px',
                height: '18px',
                color: theme.palette.system.coolGray,
              }}
            />
          }
          onClick={handleBackToSearch}
        ></ProfileLink>
      </Box>
    );
  }, [handleBackToSearch]);

  const handleClick = (value: MenuIcons | string) => {
    if (activePage !== value) {
      if (value === MenuIcons.Menu) {
        handleToggleMenu();
      } else {
        if (value === MenuIcons.Pay) {
          if (assignmentData?.items?.length === 0) {
            dispatch(setActivePage(value));
            dispatch(setMenuState(false));
            history.push('/payroll');
          } else {
            const adpWindow = window.open(
              ADP_URL,
              '_blank',
              'noopener,noreferrer',
            );
            if (adpWindow) adpWindow.opener = null;
          }
        } else {
          dispatch(setActivePage(value));
          dispatch(setMenuState(false));
          history.push(mapMenuIconsection(value));
        }
      }
    }
  };

  return (
    <>
      <EndScreenShareButton showInDesktop={false} />
      <Grid
        container
        id="header"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: isMenuOpen ? 'system.grey' : 'system.white',
          height: '72px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderBottomColor: 'system.border',
          position: { xs: 'static', sm: 'relative' },
          zIndex: { xs: theme.zIndex.appBar, md: theme.zIndex.appBar + 1000 },
        }}
      >
        {!(isFromRecommendedJobsPage && matchesMaxWidth1024) && (
          <NavMenuContainer />
        )}
        <Grid item xs={2} sm={4}>
          {matchesMaxWidth1024 &&
            !isFromRecommendedJobsPage &&
            !(showCommunityBackArrow || showHearFromCommunityBackArrow) && (
              <Badge
                color="error"
                variant="dot"
                overlap="circular"
                invisible={unreadCount === 0 || !unreadCount}
              >
                <BottomNavigationAction
                  role="menuitem"
                  aria-label="HamburgerMenu"
                  sx={StylesBottomNavAction}
                  onClick={() => handleClick(MenuIcons.Menu)}
                  icon={<MenuIcon icon={MenuIcons.Menu} active={isMenuOpen} />}
                />
              </Badge>
            )}
          {matchesMaxWidth1024 && isFromRecommendedJobsPage && (
            <Box>{subHeaderBarContent}</Box>
          )}
          <Box
            onClick={returnToCommunitySection}
            sx={{
              cursor: 'pointer',
              padding: '6px 0px !important',
              display: {
                xs:
                  showCommunityBackArrow || showHearFromCommunityBackArrow
                    ? 'block'
                    : 'none',
                md: 'none',
              },
              position: 'relative',
              pl: { xs: 0, md: '24px' },
            }}
          >
            <ArrowRightIcon
              htmlColor={theme.palette.system.coolGray}
              sx={{
                ml: 3,
                width: '17px',
                height: '17px',
                transform: 'rotate(180deg)',
              }}
            />
          </Box>
          <Box pl={4} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box
              role="img"
              aria-label="amn-passport-logo"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleHomePageIconClick()}
            >
              <img
                src={AMNLogo}
                style={StylesHomePageIcon}
                alt="AMN Passport Logo"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Box
            sx={{
              justifyContent: 'center',
            }}
          >
            <Typography
              role="heading"
              aria-label="current-page"
              color="system.midnightBlue"
              textAlign="center"
              textOverflow="ellipsis"
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
                fontSize: { sm: '24px' },
                lineHeight: { sm: '24px' },
                whiteSpace: { xs: 'nowrap' },
              }}
            >
              {activePage}
            </Typography>
            {(!isMenuOpen && activePage === MenuIcons.Home) || isOpen ? (
              <Box
                role="img"
                aria-label="amn-passport-logo"
                sx={{
                  cursor: 'pointer',
                  display: {
                    xs: 'flex',
                    md: 'none',
                  },
                  justifyContent: 'center',
                }}
                onClick={() => handleHomePageIconClick()}
              >
                <img
                  src={AMNLogo}
                  style={StylesHomePageIcon}
                  alt="AMN Passport Logo"
                />
              </Box>
            ) : !isMenuOpen ? (
              <Typography
                role="heading"
                aria-label="current-page"
                color="system.midnightBlue"
                textAlign="center"
                textOverflow="ellipsis"
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none',
                  },
                  fontSize: { xs: '16px' },
                  lineHeight: { xs: '22px' },
                  whiteSpace: { xs: 'nowrap' },
                }}
              >
                {activePage}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sm={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          pr={3}
        >
          <EndScreenShareButton showInMobile={false} />
          {/* Notifications Container */}
          {!isLocums && (
            <Grid item>
              <Box
                pr={2}
                sx={{ display: 'flex' }}
                style={StylesNotificationIcon}
              >
                {/* Web icon button */}
                <IconButton
                  sx={{
                    display: { xs: 'none', md: 'block' },
                  }}
                  aria-label="notifications icon dot web"
                  onClick={handleNotificationClick}
                >
                  {NotificationIcon}
                </IconButton>
                <Popover
                  id={open ? 'simple-popover' : undefined}
                  open={Boolean(notifAnchorEl)}
                  anchorEl={notifAnchorEl}
                  onClose={handleNotificationClose}
                  sx={{ display: { xs: 'none', md: 'block' }, zIndex: 3000 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <NotificationsDropdown
                    handleClose={handleNotificationClose}
                  />
                </Popover>
              </Box>
            </Grid>
          )}
          <Grid item>
            {!isMenuOpen && (
              <Box
                role="button"
                aria-label="profile-icon-wrapper"
                id="profile-icon-wrapper"
                onClick={() => handleProfileIconClick()}
                sx={StylesProfileMenuOpenBorderDynamic}
              >
                {profileIcon}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isOpen && !isEditing && (
        <ProfileMenu toggleDrawer={toggleDrawer} open={open} />
      )}
      <PhotoUpload
        user={user}
        openModal={isEditing}
        handleClose={handlePhotoEditingClose}
      />
      <DrawerPanel
        contentName="AMN Badge"
        actionButtonText="Download PDF"
        open={open}
        toggleDrawer={toggleDrawer}
        contentStyles={{
          backgroundColor: theme.palette.system.backgroundGray,
          height: 'inherit',
          paddingLeft: { xs: '24px !important', sm: '48px !important' },
          paddingRight: '40px !important',
        }}
        pdfRef={pdfRef}
        actionButtonType={DrawerButtonTypes.PDF}
      >
        <div ref={pdfRef}>
          <AMNBadge
            imageUrl={user?.profileImage?.url}
            travelerId={user?.travelerId}
            discipline={user?.disciplinesAndSpecialties[0]?.discipline}
            specialty={user?.disciplinesAndSpecialties[0]?.specialty}
            firstName={user?.firstName}
            lastName={user?.lastName}
          />
        </div>
        <Grid
          container
          item
          flexDirection="row"
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          mt={4}
          mb={4}
        >
          <Grid item xs={1}>
            {InfoIcon(theme.palette.system.coolGray, '15', '15')}
          </Grid>
          <Grid item xs={11}>
            <Typography
              variant="body1"
              lineHeight="19px"
              color={theme.palette.system.coolGray}
            >
              PDF can be used to create a physical CR80 badge at your local
              print shop.{' '}
              <AppLink
                component="a"
                underline="none"
                onClick={() => openInNewTab(PRINT_SHOP_NEAR_ME_URL)}
                id="badge-search-print-shops-near-me"
              >
                Find a print shop.
              </AppLink>
            </Typography>
          </Grid>
        </Grid>
      </DrawerPanel>
    </>
  );
};
