import React, { useEffect, useMemo, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { LinkIconNew } from 'assets/icons/Resources/Links';
import { fileTypes } from 'constants/fileTypes';
import { getMMMddyyyy } from 'helpers/dateFormatter';
import { openInNewTab } from 'helpers/openInNewTab';
import { getImageByUrl } from 'services/domain/domainService';
import { theme } from 'styles/theme';
import { getFileExtension } from 'utils/helper';
import { AppCircularProgress } from '../CircularProgress/CircularProgress';
import { AppLink } from '../Link/Link';

const DocumentPreview = ({
  width,
  height,
  alt,
  src,
  submissionDate,
}: {
  width: string;
  height?: string;
  alt: string;
  src: string;
  submissionDate?: string;
}) => {
  const innerRef = useRef<any>(null);

  const [docState, setDocState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [extension, setExtension] = useState(getFileExtension(src));

  useEffect(() => {
    setIsLoading(true);
    getImgByUrl(src);
    setExtension(getFileExtension(src));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getImgByUrl = async imageUrl => {
    await getImageByUrl(imageUrl).then(response => {
      setDocState(response);
      setIsLoading(false);
    });
  };

  const getComponentBasedOnUrlExtension = useMemo(() => {
    switch (extension) {
      case fileTypes.pdf:
        return (
          <iframe
            id="iframe-preview"
            style={{
              width: width,
              height: height,
            }}
            ref={innerRef}
            frame-ancestors="self"
            title="iframe-preview"
            src={docState}
          />
        );
      case fileTypes.html:
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="system.grayText"
                fontWeight={600}
                pb={2}
              >
                {alt}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="system.coolGray" pb={4}>
                {`Submitted ${getMMMddyyyy(submissionDate || '')}`}
              </Typography>
            </Grid>
            <Grid item sx={{ cursor: 'pointer' }}>
              <AppLink
                component="a"
                underline="none"
                onClick={() => {
                  openInNewTab(docState);
                }}
              >
                <div>
                  <LinkIconNew
                    width="15px"
                    height="15px"
                    color={theme.palette.system.skyBlue}
                  />

                  <Typography
                    component="span"
                    variant="body1"
                    color="system.skyBlue"
                    pl={1}
                  >
                    {alt}
                  </Typography>
                </div>
              </AppLink>
            </Grid>
          </Grid>
        );
      default:
        return (
          <img
            style={{
              width: width,
              height: height,
              cursor: 'pointer',
            }}
            src={docState}
            alt={alt}
            onClick={() => {
              openInNewTab(docState);
            }}
            loading="lazy"
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docState]);
  return (
    <>
      {isLoading && <AppCircularProgress />}
      {extension && (
        <Grid
          container
          sx={{
            height: '100%',
            left: 0,
            mb: 2,
          }}
        >
          {getComponentBasedOnUrlExtension}

          {extension?.toUpperCase() !== 'HTML' && (
            <Grid container justifyContent="center">
              <Typography
                variant="body1"
                color="system.gray"
                textAlign="center"
                pt={2}
              >
                {extension.toUpperCase()}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default DocumentPreview;
