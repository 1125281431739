import React from 'react';
import { theme } from 'styles/theme';

export default function CalendarIcon({
  color = `${theme.palette.system.black}`,
  width = '24px',
  height = '24px',
}: {
  color?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      id="Controls-icon-Calendar"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2282"
        data-name="Rectangle 2282"
        width="38"
        height="38"
        fill="none"
        opacity="0.5"
      />
      <g id="Group_2877" data-name="Group 2877" transform="translate(1 2)">
        <g id="Group_2876" data-name="Group 2876">
          <path
            id="Path_3745"
            data-name="Path 3745"
            d="M47.61,15.875H18.16a3.3,3.3,0,0,0-3.275,3.31V46.565a3.3,3.3,0,0,0,3.275,3.31H47.61a3.3,3.3,0,0,0,3.275-3.31V19.185A3.3,3.3,0,0,0,47.61,15.875Zm0,30.991H18.16a.3.3,0,0,1-.3-.3V23.591H47.908V46.565A.3.3,0,0,1,47.61,46.866Z"
            transform="translate(-14.885 -15.875)"
            fill={color}
          />
          <rect
            id="Rectangle_2283"
            data-name="Rectangle 2283"
            width="2.948"
            height="2.98"
            transform="translate(13.577 10.961)"
            fill={color}
          />
          <rect
            id="Rectangle_2284"
            data-name="Rectangle 2284"
            width="2.948"
            height="2.98"
            transform="translate(19.474 10.961)"
            fill={color}
          />
          <rect
            id="Rectangle_2285"
            data-name="Rectangle 2285"
            width="2.949"
            height="2.98"
            transform="translate(25.371 10.961)"
            fill={color}
          />
          <rect
            id="Rectangle_2286"
            data-name="Rectangle 2286"
            width="2.949"
            height="2.98"
            transform="translate(7.68 16.92)"
            fill={color}
          />
          <rect
            id="Rectangle_2287"
            data-name="Rectangle 2287"
            width="2.948"
            height="2.98"
            transform="translate(13.577 16.92)"
            fill={color}
          />
          <rect
            id="Rectangle_2288"
            data-name="Rectangle 2288"
            width="2.948"
            height="2.98"
            transform="translate(19.474 16.92)"
            fill={color}
          />
          <rect
            id="Rectangle_2289"
            data-name="Rectangle 2289"
            width="2.949"
            height="2.98"
            transform="translate(25.371 16.92)"
            fill={color}
          />
          <rect
            id="Rectangle_2290"
            data-name="Rectangle 2290"
            width="2.949"
            height="2.98"
            transform="translate(7.68 22.88)"
            fill={color}
          />
          <rect
            id="Rectangle_2291"
            data-name="Rectangle 2291"
            width="2.948"
            height="2.98"
            transform="translate(13.577 22.88)"
            fill={color}
          />
          <rect
            id="Rectangle_2292"
            data-name="Rectangle 2292"
            width="2.948"
            height="2.98"
            transform="translate(19.474 22.88)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
