enum ExtensionConsiderations {
  TimeOff = 100,
  AMNHousing = 200,
  Other = 300,
}

enum ExtensionType {
  Interested = 1,
  NotInterested = 2,
  StillConsidering = 3,
}

enum ExtensionPeriodType {
  Under = 1,
  At = 2,
  Over = 3,
}

enum ExtensionRejectReasons {
  ReadyToTravelSomewhereNew = 100,
  TooEarlyToDecide = 200,
  NeedTimeOffBreak = 300,
  TookPermanentJob = 400,
  BookedWithOtherAgency = 500,
  DontLikeFacilityStaff = 600,
  NotSatisfiedWithAMN = 700,
}

enum ExtensionRejectReasonDescription {
  TRAVEL = 'Ready to travel somewhere new',
  TOO_EARLY = 'Too early to decide',
  TIME_OFF = 'Need time off/break',
  PERMANENT_JOB = 'Took a permanent job',
  AGENCY = 'Booked with other agency',
  FACILITY_STAFF = "Don't like Facility/Staff",
  NOT_SATISFIED = 'Not satisfied with AMN',
}

enum ExtendAssignmentSentIcons {
  Check = 'check',
  Add = 'add',
}

enum AssignmentExtensionText {
  Default = 'Want to extend your assignment?',
  YesExtension = "We've informed your recruiter that you're interested in extending this assignment.",
  NoExtension = 'No extension desired. Your recruiter will reach out to you with other opportunities for your next assignment.',
}

enum AssignmentExtensionLinkText {
  Default = 'We can let your recruiter know.',
  YesNoExtension = 'Change',
}

enum AssignmentExtensionOptions {
  NONE = '',
  YES = 'Yes, Definitely',
  NO = 'No, Thanks',
  UNSURE = 'Not Sure Yet',
}

enum AssignmentExtensionDuration {
  NONE = '',
  UNDER = 'Under 13 weeks',
  AT = '13 Weeks',
  OVER = 'Over 13 Weeks',
}

enum AssignmentExtensionConsiderationOptions {
  NONE = '',
  HOUSING = 'AMN Housing',
  TIME_OFF = 'Time Off',
  OTHER = 'Other',
}

export {
  AssignmentExtensionText,
  AssignmentExtensionLinkText,
  ExtensionConsiderations,
  ExtensionType,
  ExtensionPeriodType,
  ExtensionRejectReasons,
  ExtendAssignmentSentIcons,
  AssignmentExtensionOptions,
  AssignmentExtensionDuration,
  AssignmentExtensionConsiderationOptions,
  ExtensionRejectReasonDescription,
};
