import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IJobShareState {
  canUseNativeShare: boolean;
  originUrl: string;
}

const initialState: IJobShareState = {
  canUseNativeShare: false,
  originUrl: '',
};

const jobShareSlice = createSlice({
  name: 'jobShare',
  initialState,
  reducers: {
    setCanUseNativeShare(state, action: PayloadAction<boolean>) {
      state.canUseNativeShare = action.payload;
    },
    setOriginUrl(state, action: PayloadAction<string>) {
      state.originUrl = action.payload;
    },
  },
});

export const jobShareActions = {
  ...jobShareSlice.actions,
};
export default jobShareSlice.reducer;
