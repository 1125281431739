import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ImageIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Controls-icon-Image"
    viewBox="0 0 38 38"
  >
    <g id="Group_2906" data-name="Group 2906" transform="translate(1 5.999)">
      <g id="Group_2905" data-name="Group 2905">
        <path
          id="Path_3780"
          data-name="Path 3780"
          d="M44.509,15.927H13.757a2.633,2.633,0,0,0-2.624,2.638V40.289a2.633,2.633,0,0,0,2.624,2.638H44.509a2.633,2.633,0,0,0,2.624-2.638V18.565A2.633,2.633,0,0,0,44.509,15.927Zm-30.753,2.4H44.509a.242.242,0,0,1,.239.24V40.289a.23.23,0,0,1-.157.206l-7.245-9.07L31.822,37.54l-10-14.787-8.3,10.854V18.565A.242.242,0,0,1,13.757,18.325Z"
          transform="translate(-11.133 -15.927)"
        />
        <path
          id="Path_3781"
          data-name="Path 3781"
          d="M36.886,28.038a2.985,2.985,0,1,0-2.969-2.985A2.977,2.977,0,0,0,36.886,28.038Z"
          transform="translate(-12.18 -16.177)"
        />
      </g>
    </g>
  </svg>,
  'Image',
);
