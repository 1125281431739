export enum ReimbursementType {
  Travel = 1,
  Job_Requirements,
  Baggage,
  Mileage,
}

export enum JobRequirementsType {
  ACLS = 1,
  BCLS_BLS,
  Fingerprinting,
  Flu_Vaccine,
  License,
  Miscellaneous_Work,
  PALS,
  Parking,
}
