import React from 'react';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ReactComponent as LandingTaskCenter } from 'assets/icons/SplashImages/checklist_lady.svg';
import InfoIcon from 'assets/icons/Info/InfoIcon';
import { theme } from 'styles/theme';
import { LightTooltip } from 'components/common/ToolTip/LightToolTip';

const TOOLTIP_TEXT =
  'We have implemented technical, administrative, and physical security measures designed to protect your personal information from unauthorized access, disclosure, use, and modification.';

export const TaskCenterSplash = () => {
  return (
    <Card
      id="credential-splash"
      sx={{
        padding: '48px',
        maxHeight: '480px',
        width: { md: '555px' },
        height: '100%',
        display: 'flex',
        borderRadius: '10px',
        boxShadow: {
          sm: `0px 1px 15px ${theme.palette.system.boxShadowGray}`,
        },
      }}
    >
      <Grid container justifyContent="center" alignContent="center">
        <Grid item xs={10} sm={7} mt="auto" mb="auto">
          <Typography variant="h2" color="system.midnightBlue" fontSize="20px">
            <i>All Your Tasks in One Place</i>
          </Typography>
          <Divider
            sx={{
              backgroundColor: 'system.skyBlue',
              borderWidth: '2px',
              margin: '12px 0 24px 0',
              width: '100px',
            }}
          />
          <ul style={{ paddingInlineStart: '20px' }}>
            {[
              'Get ahead of the game with Profile requirements.',
              'View completed licenses, credentials, documents, and forms in the respective categories.',
              'Be alerted to any needs and easily update and stay on track.',
            ].map((i, idx) => (
              <li key={idx}>
                <Typography variant="subtitle1" color="system.grayText" pb={2}>
                  {i}
                </Typography>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={{ xs: 6, sm: 0 }}
        >
          <LandingTaskCenter width="210px" height="208px" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="system.skyBlue">
            <LightTooltip title={TOOLTIP_TEXT}>
              <span style={{ verticalAlign: 'middle' }}>
                {InfoIcon(theme.palette.system.skyBlue, '15', '15')}
                <span style={{ paddingLeft: '6px' }}>
                  How We Protect Your Information
                </span>
              </span>
            </LightTooltip>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
