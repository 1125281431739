import React from 'react';
import { SliderWrap } from './Carousel.style';
import { ICarouselProps } from 'interfaces/Props/ICarouselProps';
import { Swiper, SwiperSlide as CarouselItem } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

const defaultProps: ICarouselProps = {
  children: '',
  spaceBetween: 12,
  slidesPerView: 3,
  slidesPerGroup: 1,
  breakpoints: {
    1920: {
      slidesPerView: 3,
      slidesPerGroup: 5,
    },

    1024: {
      slidesPerView: 3,
      slidesPerGroup: 5,
    },

    768: {
      slidesPerView: 3,
      slidesPerGroup: 5,
    },

    640: {
      slidesPerView: 2,
      slidesPerGroup: 5,
    },

    375: {
      slidesPerView: 1.5,
      slidesPerGroup: 5,
    },

    0: {
      slidesPerView: 1,
      slidesPerGroup: 5,
    },
  },
};

const Carousel: React.FC<ICarouselProps> = props => {
  const settings = {
    ...props,
    modules: [Pagination, Autoplay],
    navigation: true,
    autoplay: {
      delay: 10000,
    },
  };
  return (
    <SliderWrap>
      <Swiper {...settings} className="amn-carousel" style={props?.swiperStyle}>
        {props.children}
      </Swiper>
    </SliderWrap>
  );
};

Carousel.defaultProps = defaultProps;

export { Carousel, CarouselItem };
