import React from 'react';
import { theme } from 'styles/theme';

export function AutoSubIndicatorIcon({
  width = '17px',
  height = '17px',
  color = theme.palette.system.skyBlue,
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 18.744"
    >
      <g
        id="Controls_-icon_-_Auto_-_Filled"
        data-name="Controls -icon - Auto - Filled"
        transform="translate(0 0.872)"
      >
        <rect
          id="Rectangle_7561"
          data-name="Rectangle 7561"
          width={width}
          height={height}
          fill={color}
          opacity="0"
        />
        <path
          id="Union_121"
          data-name="Union 121"
          d="M4.722,9.19,0,9.177,5.278,0V5.811L10,5.824,4.722,15Z"
          transform="translate(3 1)"
          fill={color}
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
