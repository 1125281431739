import { CredentialStatusResponse } from '../../../types/CredentialStatus';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { CredentialStatusState } from './credentialStatusState';

const initialState: CredentialStatusState = {
  isloading: false,
  credentialStatus: null,
  receivedNoContent: false,
};

const CredentialStatusSlice = createSlice({
  name: 'credentialStatusSlice',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isloading = action.payload;
    },
    retrieveCredentialStatus(state, action: PayloadAction<string>) {
      state.isloading = true;
      state.error = undefined;
    },
    onFetchSuccess(state, action: PayloadAction<CredentialStatusResponse>) {
      state.isloading = false;
      state.credentialStatus = action.payload;
    },
    setReceivedNoContent(state, action: PayloadAction<boolean>) {
      state.receivedNoContent = action.payload;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        isloading: false,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
  },
});

export const {
  retrieveCredentialStatus,
  onFetchSuccess,
  setReceivedNoContent,
  setError,
  setLoading,
} = CredentialStatusSlice.actions;

export default CredentialStatusSlice.reducer;
