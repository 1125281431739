import React from 'react';
import { createSvgIcon } from '@mui/material';

export const LicensesIcon = createSvgIcon(
  <svg
    id="Controls-Icon-Licenses"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2363"
      data-name="Rectangle 2363"
      fill="none"
      opacity="0"
    />
    <g id="Group_3193" data-name="Group 3193" transform="translate(1 4.999)">
      <path
        id="Path_3871"
        data-name="Path 3871"
        d="M44.509,42.927H13.757a2.634,2.634,0,0,1-2.624-2.638V18.565a2.634,2.634,0,0,1,2.624-2.638H44.509a2.634,2.634,0,0,1,2.624,2.638V40.289A2.634,2.634,0,0,1,44.509,42.927Zm-30.753-24.6a.242.242,0,0,0-.239.24V40.289a.242.242,0,0,0,.239.24H44.509a.242.242,0,0,0,.239-.24V18.565a.242.242,0,0,0-.239-.24Z"
        transform="translate(-11.133 -15.927)"
      />
      <path
        id="Path_3872"
        data-name="Path 3872"
        d="M41.127,26.206h-8.9a.719.719,0,0,1,0-1.439h8.9a.719.719,0,0,1,0,1.439Z"
        transform="translate(-12.069 -16.287)"
      />
      <path
        id="Path_3873"
        data-name="Path 3873"
        d="M41.127,30.884h-8.9a.719.719,0,0,1,0-1.439h8.9a.719.719,0,0,1,0,1.439Z"
        transform="translate(-12.069 -16.477)"
      />
      <path
        id="Path_3874"
        data-name="Path 3874"
        d="M41.127,35.561h-8.9a.719.719,0,0,1,0-1.439h8.9a.719.719,0,0,1,0,1.439Z"
        transform="translate(-12.069 -16.668)"
      />
      <path
        id="Path_3876"
        data-name="Path 3876"
        d="M2.969,0A2.985,2.985,0,1,1,0,2.985,2.977,2.977,0,0,1,2.969,0Z"
        transform="translate(7.963 8.132)"
      />
      <path
        id="Path_3875"
        data-name="Path 3875"
        d="M27.87,36.627c0-3.829-2.647-4.508-3.438-4.944a11.719,11.719,0,0,0-2.2-.037,11.719,11.719,0,0,0-2.2.037c-.791.436-3.438,1.116-3.438,4.944Z"
        transform="translate(-11.384 -16.566)"
      />
    </g>
  </svg>,
  'LicensesIcon',
);
