import React from 'react';
import AutoSubmitShortcut from 'components/AutoSubmitShortcut/AutoSubmitShortcut';

export const getAutoSubmitShortcuts: { [key: string]: JSX.Element } = {
  AutoSubmitShortcut: (
    <AutoSubmitShortcut
      id="AutoShortcut1"
      titleText="Fast-track your job search!"
      linkText="Update Auto-Submission"
      splitText={false}
      trackEventName="Web_JobSearch_AutoSubmission_Card1"
      trackWebResponsiveEventName="Responsive_JobSearch_AutoSubmission_Card1"
      trackEventMessage="1st card has been clicked"
    />
  ),
  AutoSubmitShortcut2: (
    <AutoSubmitShortcut
      id="AutoShortcut2"
      titleText="Sit back, relax, and let us"
      linkText="auto-submit your application"
      splitText={false}
      trackEventName="Web_JobSearch_AutoSubmission_Card2"
      trackWebResponsiveEventName="Responsive_JobSearch_AutoSubmission_Card2"
      trackEventMessage="2nd card has been clicked"
    />
  ),
  AutoSubmitShortcut3: (
    <AutoSubmitShortcut
      id="AutoShortcut3"
      titleText="Can't Find what you are looking for?"
      linkText="Update Auto-Submission"
      trackEventName="Web_JobSearch_AutoSubmission_Card3"
      trackWebResponsiveEventName="Responsive_JobSearch_AutoSubmission_Card3"
      trackEventMessage="3rd card has been clicked"
    />
  ),
  AutoSubmitShortcut4: (
    <AutoSubmitShortcut
      id="AutoShortcut4"
      titleText="Scrolling Fatigue? Fast-track your application with"
      linkText="Auto-Submission"
      trackEventName="Web_JobSearch_AutoSubmission_Card4"
      trackWebResponsiveEventName="Responsive_JobSearch_AutoSubmission_Card4"
      trackEventMessage="4th card has been clicked"
    />
  ),
};
