import JobDetailSkeleton from 'components/Jobs/JobDetails/JobDetailSkeleton';
import { JobDetails } from 'components/Jobs/JobDetails/JobDetails';
import { JobDetailsMobile } from 'components/Jobs/JobDetails/JobDetailsMobile';
import { useSmMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

interface IJobDetailRendererProps {
  mobileJobListingCompoent: React.ReactNode;
  isSelectedJob: boolean;
  showMap: boolean;
  onDetailPageCloseClick: any;
  isNotStrikeFilter?: boolean;
}

/**
 * A Custom Conditional Rendering Component based on mobile vs desktop vs map view
 * @param props
 * @returns
 */
export const JobSelectedRenderer: React.FC<IJobDetailRendererProps> = props => {
  const isMobileDevice = useSmMediaQuery();

  const { promiseInProgress: isJobDetailLoading } = usePromiseTracker({
    area: 'get-job-details',
    delay: 0,
  });

  if (isMobileDevice && (props.isSelectedJob || props.showMap)) {
    if (isJobDetailLoading) {
      return <>{<JobDetailSkeleton />}</>;
    }
    return (
      <JobDetailsMobile
        onCloseClick={props.onDetailPageCloseClick}
        isNotStrikeFilter={props?.isNotStrikeFilter}
        showMap={props.showMap}
      />
    );
  }

  if (isMobileDevice && !props.isSelectedJob) {
    return <>{props.mobileJobListingCompoent}</>;
  }

  return <>{<JobDetails isNotStrikeFilter={props?.isNotStrikeFilter} />}</>;
};
