/**
 * Method to open link reference in new tab
 * @param url - url to navigated
 */
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank');
  if (newWindow) {
    newWindow.opener = null;
    newWindow.location.href = url;
  }
};

export const openInCurrentTab = (url: string): void => {
  const newWindow = window.open(url, '_self');
  if (newWindow) newWindow.opener = null;
};

export const isSafari = !!(
  navigator.userAgent.includes('Safari') &&
  !navigator.userAgent.includes('Chrome')
);

export const isSafariOrFirefox = !!(
  isSafari || navigator.userAgent.includes('Firefox')
);

export const isSafariOrChromeIos = !!(
  isSafari || !!navigator.userAgent.match('CriOS')
);
