import { Card, Grid, Skeleton } from '@mui/material';
import React from 'react';

export default function SkeletonSmilarJobs() {
  return (
    <Grid container item>
      <Grid item xs={12}>
        <Card sx={{ padding: '14px 12px' }}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Skeleton
                variant="rectangular"
                width={69}
                height={132}
                sx={{ borderRadius: '6px' }}
              />
            </Grid>
            <Grid
              container
              item
              xs={9}
              sx={{ paddingLeft: { md: '10px', xl: '0' } }}
            >
              <Grid container item xs={12}>
                <Grid item xs={10.5}>
                  <Skeleton
                    variant="rectangular"
                    height={19}
                    sx={{ borderRadius: '2px', maxWidth: '98px' }}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <Skeleton
                    variant="rectangular"
                    height={19}
                    sx={{ borderRadius: '2px', maxWidth: '24px' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} paddingTop="21px">
                <Skeleton
                  variant="rectangular"
                  height={19}
                  sx={{ borderRadius: '2px', maxWidth: '98px' }}
                />
              </Grid>
              <Grid item xs={12} paddingTop="8px">
                <Skeleton
                  variant="rectangular"
                  height={19}
                  sx={{ borderRadius: '2px', maxWidth: '212px' }}
                />
              </Grid>
              <Grid item xs={12} paddingTop="30px" paddingLeft="114px">
                <Skeleton
                  variant="rectangular"
                  height={19}
                  sx={{ borderRadius: '2px', maxWidth: '98px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
