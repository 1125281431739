import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { DrawerButtonTypes } from 'enums/buttons';
import { DrawerHeaderType } from 'enums/headerTypes';
import { IPreferredRecruiterDialogProps } from 'interfaces/Props/IApplyJobDialogProps';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IContactState } from 'store/slices/user/userContact/userContactState';
import {
  markPreferredRecruiter,
  resetPreferredRecruiter,
  retrieveUserPreference,
} from 'store/slices/user/userPreferenceSlice';
import { desktopWidthBreakpoint } from 'styles/media';
import { theme } from 'styles/theme';
import { PersonalInfo } from '../PersonalInfoCard/PersonalInfo/PersonalInfo';

export const PreferredRecruiterDialog = ({
  open,
  handleCancelClick,
  contentName = 'Select a Preferred Recruiter',
  actionButtonText = 'Mark as Preferred',
  cancelButtonText = 'Not Now',
  headerText = 'Select a Preferred Recruiter to be your main contact for jobs moving forward or "Not Now" if you\'re not ready to select one.',
}: IPreferredRecruiterDialogProps) => {
  const userContact = useAppSelector(
    state => state.userContact,
  ) as IContactState;

  const isPreferrenceUpdated = useAppSelector(
    state => state.userPreference.isPreferredRecruiterUpdated?.success,
  ) as boolean;

  const recruiters = userContact?.userContacts?.userContacts?.filter(
    g => g.employeeRoleId === AssignmentRoleId.RECRUITER,
  );

  const preferredRecruiterId = useAppSelector(
    state => state.userPreference.userPreference.preferredRecruiter,
  );

  const [selectedRecruiter, setSelectedRecruiter] = useState<number>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (recruiters && recruiters.length > 0) {
      // initialize to the preferred recruiter or the first recruiter in the list
      setSelectedRecruiter(preferredRecruiterId ?? recruiters[0].employeeId);
    }

    //On success close the drawer && update store user preference
    if (isPreferrenceUpdated && handleCancelClick) {
      dispatch(retrieveUserPreference());
      handleCancelClick();
    }
    return () => {
      if (recruiters && recruiters.length > 0) {
        setSelectedRecruiter(recruiters[0].employeeId);
      }
      dispatch(resetPreferredRecruiter());
    };
  }, [userContact, isPreferrenceUpdated]);

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent, open: boolean) => {
      if (!open && handleCancelClick) {
        handleCancelClick();
        if (recruiters && recruiters.length > 0) {
          setSelectedRecruiter(recruiters[0].employeeId);
        }
      }
    },
    [handleCancelClick, recruiters],
  );

  const handleChange = (userId: number) => {
    setSelectedRecruiter(userId);
  };

  const markAsPreferred = () => {
    if (selectedRecruiter) dispatch(markPreferredRecruiter(selectedRecruiter));
  };

  return (
    <DrawerPanel
      open={open}
      actionButtonText={actionButtonText}
      actionButtonType={DrawerButtonTypes.CUSTOM}
      actionButtonStyles={{
        width: '190px',
        padding: '0px',
      }}
      primaryOnClick={markAsPreferred}
      primaryBtnDisabled={selectedRecruiter === null}
      cancelButtonText={cancelButtonText}
      toggleDrawer={toggleDrawer}
      contentName={contentName}
      headerType={DrawerHeaderType.Close}
      secondaryButtonType={DrawerButtonTypes.CUSTOM}
      drawerCancelButtonStyle={{
        display: 'block !important',
      }}
      drawerButtonFooterStyle={{
        [theme.breakpoints.down(desktopWidthBreakpoint)]: {
          justifyContent: 'space-between !important',
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.system.coolGray,
              textAlign: 'left',
              fontSize: '16px',
              lineHeight: '22px',
            }}
          >
            {headerText}
          </Typography>
        </Grid>

        <Grid item xs={12} pt={4}>
          <RadioGroup
            aria-label="PreferredRecruiters"
            name="preferredrecruiters"
          >
            {recruiters &&
              recruiters.map((contact, index) => (
                <Grid item xs={12} key={index} pt={2}>
                  <FormControlLabel
                    value="true"
                    sx={{
                      color: theme.palette.system.midnightBlue,
                      fontSize: 16,
                    }}
                    disableTypography={true}
                    control={
                      <Radio
                        color="secondary"
                        checked={selectedRecruiter === contact.employeeId}
                        onChange={e => handleChange(contact.employeeId!)}
                      />
                    }
                    label={
                      <PersonalInfo
                        id="personal-info"
                        name={`${contact?.firstName ?? ''} ${
                          contact?.lastName ?? ''
                        }`}
                        nameStyle={{
                          color: theme.palette.system.midnightBlue,
                          fontSize: '16px',
                          marginBottom: '2px',
                        }}
                        image={contact?.contactImage?.url ?? ''}
                        direction="row"
                        imageSize={48}
                      />
                    }
                  />
                </Grid>
              ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </DrawerPanel>
  );
};
