import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IPersonalInfoProps } from 'interfaces/Props/IPersonalInfoProps';
import { theme } from 'styles/theme';
import { ContactCardImage } from 'components/ContactCard/ContactCardImage';
export const PersonalInfo = (props: IPersonalInfoProps) => {
  const {
    id,
    image,
    tag,
    name,
    nameStyle = { color: theme.palette.system.grayText, marginBottom: '2px' },
    designation,
    direction,
    imageSize = 80,
  } = props;

  return (
    <Box id={id} sx={{ display: 'flex', flexDirection: direction }}>
      <Box
        id="profile-image"
        sx={{
          marginRight: direction === 'row' ? '12px' : '0px',
          marginBottom: direction === 'column' ? '12px' : '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ContactCardImage
          id="contact-image"
          avatarVariant="text"
          image={image}
          avatarText={name}
          size={imageSize.toString() + 'px'}
          avatarTextSize={
            imageSize >= 80 ? '30px' : imageSize >= 60 ? '24px' : '20px'
          }
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: direction === 'column' ? 'center' : 'left',
          justifyContent: direction === 'row' ? 'center' : 'auto',
        }}
      >
        {tag && (
          <Typography
            sx={{
              color: theme.palette.system.skyBlue,
              backgroundColor: '#cceefd',
              textAlign: 'center',
              borderRadius: '2px',
              fontWeight: 'bold',
              padding: '3px 6px',
              marginBottom: '6px',
            }}
          >
            {tag}
          </Typography>
        )}
        <Typography sx={{ ...nameStyle }}>{name}</Typography>
        {designation && (
          <Typography sx={{ color: theme.palette.system.coolGray }}>
            {designation}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
