export const getInitialShiftTimes = () => {
  return [
    { isActive: true, type: 'Any Shift Time', typeId: 0 },
    { isActive: false, type: 'Day', typeId: 100 },
    { isActive: false, type: 'Evening', typeId: 200 },
    { isActive: false, type: 'Night', typeId: 300 },
  ];
};

export const getInitialShiftLengths = () => {
  return [
    { isActive: true, type: 'Any Shift Length', typeId: 0 },
    { isActive: false, type: '5 x 8 Hours', typeId: 400 },
    { isActive: false, type: '4 x 10 Hours', typeId: 500 },
    { isActive: false, type: '3 x 12 Hours', typeId: 600 },
  ];
};
