import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ICheckBoxProps } from 'interfaces/Props/ICheckBoxProps';
import React from 'react';

const CheckBox = (props: ICheckBoxProps) => {
  const {
    id,
    borderColor,
    fillColor,
    checklabel,
    font,
    checked,
    disabled,
    sx,
  } = props;
  return (
    <FormControlLabel
      id={`formControlLabel_${id}`}
      key={`formControlLabel_${id}`}
      disabled={disabled ? true : false}
      control={
        <Checkbox
          id={id}
          key={id}
          {...props}
          checked={checked}
          disabled={disabled}
          readOnly={disabled}
          sx={{
            ...sx,
            color: `${borderColor ?? 'system.coolGray'}`,
            '&.Mui-checked': {
              color: `${fillColor ?? 'system.skyBlue'}`,
            },
            '&.Mui-disabled': {
              color: `${fillColor ?? 'system.gray'}`,
            },
            '& .MuiSvgIcon-root': {
              fontSize: font
                ? `${font.fontSize}px`
                : sx?.['fontSize']
                ? `${sx['fontSize']}px`
                : '21px',
            },
          }}
          data-testid={id}
        />
      }
      label={
        <Typography
          sx={{ fontSize: `${font?.fontSize ?? 16}px`, ...sx?.['text'] }}
        >
          {checklabel}
        </Typography>
      }
      sx={{
        color: 'system.midnightBlue',
        ...sx?.['label'],
      }}
    />
  );
};

export default CheckBox;
