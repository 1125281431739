import { makeStyles } from '@mui/styles';

export const StylesNavMenuContainer = makeStyles(theme => ({
  navbar: {
    display: 'flex',
    height: `calc(100vh - 66px) !important`,
    position: 'absolute',
    flexWrap: 'wrap',
    width: '70px',
    borderRight: '1px solid',
    borderRightColor: theme.palette.system.navBorder,
    overflowWrap: 'anywhere',
    backgroundColor: theme.palette.system.white,
    zIndex: 1000,
    top: '72px',
    left: '0px',
  },
}));
