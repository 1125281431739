import { Grid } from '@mui/material';
import { GeneralErrorBoundary } from 'components/ErrorBoundaries/GeneralErrorBoundary';
import { fileTypes } from 'constants/fileTypes';
import { MenuIcons } from 'enums/menuIcons';
import { openInNewTab } from 'helpers/openInNewTab';
import React from 'react';

interface IFrameDrawerProps {
  url: string;
  fileType?: string;
  source?: MenuIcons;
  iframeHeight?: string;
  iframeSx?: {};
}

export const IFrameDrawer = (props: IFrameDrawerProps) => {
  const { url, fileType, iframeSx, iframeHeight } = props;

  return (
    <GeneralErrorBoundary>
      {fileType &&
      [fileTypes.jpg, fileTypes.jpeg, fileTypes.png].indexOf(fileType) > -1 ? (
        <img
          style={{
            width: '100%',
            height: '100%',
          }}
          src={url}
          alt={'document'}
          onClick={() => {
            openInNewTab(url);
          }}
        />
      ) : (
        <Grid
          container
          sx={{
            ...iframeSx,
            height: '100%',
          }}
        >
          <iframe
            style={{
              width: '100%',
              height: iframeHeight ?? '100%',
            }}
            frame-ancestors="self"
            title="iframe-drawer"
            src={url}
          />
        </Grid>
      )}
    </GeneralErrorBoundary>
  );
};
