import { Box, Typography } from '@mui/material';
import { generateAvatarShortText } from 'helpers/generateAvatarShortText';
import { IAvatarPlaceholderProps } from 'interfaces/Props/IAvatarPlaceholderProps';
import React, { FC } from 'react';
import { theme } from 'styles/theme';

const AvatarPlaceholder: FC<IAvatarPlaceholderProps> = ({
  size,
  border,
  variant,
  placeholderText,
}) => {
  return (
    <Box
      sx={{
        height: size ? size + 10 : 138,
        width: size ? size + 10 : 138,
        borderRadius: '50%',
        border: border ?? `2px solid ${theme.palette.system.purple}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        data-testid="placeholder"
        sx={{
          width: size ? size : 128,
          height: size ? size : 128,
          borderRadius: '50%',
          backgroundColor: `${theme.palette.system.marineBlueLight}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          fontWeight={400}
          lineHeight="22px"
          sx={{ color: 'white', fontSize: '50px' }}
        >
          {generateAvatarShortText(placeholderText, variant)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AvatarPlaceholder;
