import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IAssignmentRating } from 'types/AssignmentRating';
import { SuccessResponse } from 'types/SuccessResponse';
import { ErrorState } from '../error/errorState';

export interface AssignmentRatingState extends ErrorState {
  isRatingSaved: SuccessResponse;
  isLoading: boolean;
  isSubmitLoaderActive: boolean;
  rating: IAssignmentRating | null;
  oldRating: IAssignmentRating | null;
  computeAverage: boolean;
}

const initialState: AssignmentRatingState = {
  isRatingSaved: { success: false },
  isLoading: false,
  isSubmitLoaderActive: false,
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  downloadError: false,

  rating: null,
  oldRating: null,
  computeAverage: true,
};

const assignmentRatingSlice = createSlice({
  name: 'assignmentRating',
  initialState,
  reducers: {
    retrieveAssignmentRating(state, action) {
      state.isLoading = true;
    },

    postAssignmentRating(state, action) {
      state.isLoading = true;
      state.isSubmitLoaderActive = true;
    },

    setLoading(state, { payload = false }: PayloadAction<boolean | undefined>) {
      state.isLoading = payload;
    },

    setRating(state, { payload: { data } }: PayloadAction<any>) {
      state.isLoading = false;
      state.isSubmitLoaderActive = false;
      state.rating = data.rating;
      state.computeAverage = data.computeAverage ?? true;
    },

    setOldRating(state, { payload: { data } }: PayloadAction<any>) {
      state.oldRating = data.oldRating;
    },

    setRatingSuccess(state) {
      state.isRatingSaved.success = true;
      state.computeAverage = true;
    },

    resetRatingSaved(state) {
      state.isRatingSaved.success = false;
    },

    setError(state, action: PayloadAction<IErrorResponse>) {
      state.error = true;
      state.errorResponse = {
        errorCode: action.payload.errorCode,
        errorData: action.payload.errorData,
        errorDetails: action.payload.errorDetails,
        errorMessage: action.payload.errorMessage,
        operationId: action.payload.operationId,
        correlationId: action.payload.correlationId,
      };
      state.downloadError = false;
      state.isSubmitLoaderActive = false;
    },

    setErrorWithoutPayload(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },

    unSetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {
        errorCode: 0,
        errorData: {},
        errorDetails: '',
        errorMessage: '',
        operationId: '',
        correlationId: '',
      };
      return {
        ...stateValues,
      };
    },

    resetAssignmentRating() {
      return { ...initialState };
    },
  },
});

export const {
  retrieveAssignmentRating,
  postAssignmentRating,
  setRating,
  setOldRating,
  setRatingSuccess,
  resetRatingSaved,
  setLoading,
  setError,
  setErrorWithoutPayload,
  unSetError,
  resetAssignmentRating,
} = assignmentRatingSlice.actions;

export default assignmentRatingSlice.reducer;
