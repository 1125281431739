import React from 'react';
import { theme } from 'styles/theme';
import { createSvgIcon } from '@mui/material';

export const LinksIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <g id="Controls-icon-Globe" transform="translate(0 0.001)">
      <g id="Group_2910" data-name="Group 2910" transform="translate(0 0.002)">
        <rect
          id="Rectangle_2327"
          data-name="Rectangle 2327"
          transform="translate(0 -0.003)"
        />
        <path
          id="Path_3785"
          data-name="Path 3785"
          d="M32.635,14.635a18,18,0,1,0,18,18A18.02,18.02,0,0,0,32.635,14.635Zm0,2.929c.054,0,.105.008.159.008a5.5,5.5,0,0,1,3.037,1.639c.69.828-1.174,3.591-1.727,4.212s-.138,1.519.553,2.486,1.174,2.9.759,3.452-1.933-.344-2.969-1.1a1.29,1.29,0,0,0-2.141.484c-.206.69-.552.828-1.174.276s-1.143,2.282.138,3.073c.443.273.119.947,4.7,0,6.646-1.374,7.181,7.353,6.353,8.458S38.247,44.9,36.8,45.933,33.12,47.85,34.035,44.9a7.264,7.264,0,0,0-.344-4.144c-2.417,0-4.144-4.626-3.8-6.075-3.729,0-4.419-1.789-6.767-5.662-2.321-3.83.232-7.942,2.377-9.652A14.973,14.973,0,0,1,32.635,17.564Z"
          transform="translate(-13.636 -13.636)"
        />
      </g>
    </g>
  </svg>,
  'Links',
);

export function LinkIconNew({
  width = '18px',
  height = '18px',
  color = theme.palette.system.skyBlue,
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Controls-icon-Image"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <g id="Controls-icon-Globe" transform="translate(0 0.001)">
        <g
          id="Group_2910"
          data-name="Group 2910"
          transform="translate(0 0.002)"
        >
          <rect
            id="Rectangle_2327"
            data-name="Rectangle 2327"
            transform="translate(0 -0.003)"
            fill={color}
          />
          <path
            id="Path_3785"
            data-name="Path 3785"
            d="M32.635,14.635a18,18,0,1,0,18,18A18.02,18.02,0,0,0,32.635,14.635Zm0,2.929c.054,0,.105.008.159.008a5.5,5.5,0,0,1,3.037,1.639c.69.828-1.174,3.591-1.727,4.212s-.138,1.519.553,2.486,1.174,2.9.759,3.452-1.933-.344-2.969-1.1a1.29,1.29,0,0,0-2.141.484c-.206.69-.552.828-1.174.276s-1.143,2.282.138,3.073c.443.273.119.947,4.7,0,6.646-1.374,7.181,7.353,6.353,8.458S38.247,44.9,36.8,45.933,33.12,47.85,34.035,44.9a7.264,7.264,0,0,0-.344-4.144c-2.417,0-4.144-4.626-3.8-6.075-3.729,0-4.419-1.789-6.767-5.662-2.321-3.83.232-7.942,2.377-9.652A14.973,14.973,0,0,1,32.635,17.564Z"
            transform="translate(-13.636 -13.636)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
