import styled from 'styled-components';
import { amnFontSize } from 'styles/fontSizes';

export const StylesHomePageIcon = {
  cursor: 'pointer',
  width: 'clamp(169px, 40vw ,200px)',
  height: '32px',
};

export const MoreOptions = styled.div`
  position: absolute;
  top: 50%;
  right: 36px;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);
  .MuiButtonBase-root {
    padding: 0px;
    margin: 0px;
    width: 24px;
    height: 24px;
    svg.MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }
`;
export const MenuOption = styled.span`
  font-size: ${amnFontSize.fs14};
  color: ${'system.midnightBlue'};
`;
export const SeeMoreOrLessLink = styled.a`
  color: ${'system.midnightBlue'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: ${'system.white'} 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 5px ${'system.backgroundGray'};
  border: 1px solid ${'system.backgroundGray'};
  border-radius: 4px;
  position: relative;
  padding: 5px;
  font-size: ${amnFontSize.fs14};
  line-height: 24px;
  text-transform: uppercase;
  color: ${'system.skyBlue'};
  cursor: pointer;
  &:after {
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    border: 1px solid ${'system.skyBlue'};
    border-width: 0px 1px 1px 0;
    position: relative;
    right: -12px;
    top: 4px;
  }
  &.more {
    &:after {
      transform: rotate(45deg);
    }
  }
  &.less {
    &:after {
      transform: rotate(225deg);
      top: 10px;
    }
  }
`;
