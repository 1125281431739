import { cleanupPlacementType } from 'helpers/JobDetailsHelper';
import { roundToNearestNumber } from 'helpers/formatNumber';
import { IJobsData } from 'interfaces/Jobs/IJobsData';

export const jobSearchPageResultTransformer = (jobs: Array<IJobsData>) => {
  return jobs.map(job => ({
    ...job,
    maxPayRate: roundToNearestNumber(job?.maxPayRate),
    placementType: cleanupPlacementType(job?.placementType),
  }));
};
