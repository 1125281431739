import { timezoneDetails } from 'assets/timezoneDetails';

export function getTimezoneName(dateTimeStr) {
  try {
    // Extract the UTC offset from the date string
    const offsetMatch = dateTimeStr.match(/([-+]\d{2}:\d{2})$/);

    if (offsetMatch) {
      const offset = offsetMatch[1];

      // Lookup the time zone name using the offset
      const timezoneName = timezoneDetails[offset];

      if (timezoneName) {
        return timezoneName;
      }
    }

    return 'Unknown Time Zone';
  } catch (error) {
    return 'Invalid Date and Time';
  }
}
