import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuIcons } from 'enums/menuIcons';
import { NavigationState } from './navigationState';

const initialState: NavigationState = {
  activePage: MenuIcons.Home,
  isMenuOpen: false,
};

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setActivePage(state, action: PayloadAction<MenuIcons | string>) {
      state.activePage = action.payload;
    },
    setMenuState(state, action) {
      state.isMenuOpen = action.payload;
    },
  },
});

export const { setActivePage, setMenuState } = navigationSlice.actions;

export default navigationSlice.reducer;
