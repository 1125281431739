import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ClinicalQualificationsIcon = createSvgIcon(
  <svg
    id="Controls-icon-Clinical-Qualifications"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2360"
      data-name="Rectangle 2360"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Layer_2" data-name="Layer 2" transform="translate(6 1)">
      <g id="Capa_1" data-name="Capa 1">
        <path
          id="Path_1264"
          data-name="Path 1264"
          d="M34.641,26.781h-11.1a.657.657,0,0,1,0-1.314h11.1a.657.657,0,0,1,0,1.314Z"
          transform="translate(-17.203 -10.805)"
        />
        <path
          id="Path_1265"
          data-name="Path 1265"
          d="M34.641,31.141h-11.1a.657.657,0,0,1,0-1.314h11.1a.657.657,0,0,1,0,1.314Z"
          transform="translate(-17.203 -11.343)"
        />
        <path
          id="Path_1266"
          data-name="Path 1266"
          d="M34.641,35.492h-11.1a.657.657,0,0,1,0-1.315h11.1a.657.657,0,0,1,0,1.315Z"
          transform="translate(-17.203 -11.88)"
        />
        <path
          id="Path_1267"
          data-name="Path 1267"
          d="M37.826,8.74a5.538,5.538,0,0,0-4.736,2.631H17.455a1.09,1.09,0,0,0-1.088,1.092V43.647a1.091,1.091,0,0,0,1.088,1.093h21.58a1.09,1.09,0,0,0,1.087-1.093V19.43a5.606,5.606,0,0,0-2.3-10.69Zm.139,33.811H18.542V13.622H32.306a5.462,5.462,0,0,0-.052.755,5.587,5.587,0,0,0,5.572,5.6h.139Zm2.86-29.892-3.636,3.655a.617.617,0,0,1-.461.194.647.647,0,0,1-.462-.194L34.521,14.56a.659.659,0,0,1,0-.929.23.23,0,0,1,.071-.062.625.625,0,0,1,.784,0,.237.237,0,0,1,.07.062l1.264,1.27,1.239-1.244,1.962-1.929a.653.653,0,0,1,.929.918l-.012.012Z"
          transform="translate(-16.367 -8.74)"
        />
      </g>
    </g>
  </svg>,
  'ClinicalQualifications',
);
