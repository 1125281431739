import CloseIcon from '@mui/icons-material/Close';
import { Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ArrowDownSvg } from 'assets/icons/Controls/ArrowDownSvg';
import SearchIcon from 'assets/icons/Search/SearchIcon';
import { PillButton } from 'components/common/Button/PillButton';
import { TOAST_MESSAGE } from 'constants/helperText';
import _debounce from 'lodash/debounce';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import {
  addPreferredLocation,
  clearLocations,
  removePreferredLocation,
  searchLocation,
} from 'store/slices/user/userPreferenceSlice';
import { theme } from 'styles/theme';
import { parseSearchText } from 'utils/helper';

export const LocationForm = ({
  setFormDirty,
  placeholderText,
  ctaText,
  limit,
  name,
  setLocations,
}: {
  setFormDirty?: Function;
  placeholderText: string;
  ctaText?: string;
  limit?: number;
  name?: string;
  setLocations?: any;
}) => {
  const dispatch = useAppDispatch();
  const searchTextRef = useRef('');
  const { setValue, register } = useFormContext();

  const [height, setHeight] = useState(0);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [overFlowCount, setOverFlowCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');

  const locations = useAppSelector(
    state => state.userPreference.locations || [],
  );

  const searchLocations = useAppSelector(
    state => state.userPreference.searchLocations || [],
  );

  const handleDebounceFunction = inputValue => {
    dispatch(
      searchLocation({ limit: 20, payload: { searchText: inputValue } }),
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(handleDebounceFunction, 200), []);

  const targetSearchStrings = [
    'Washington',
    'D.C.',
    'DC',
    'Washington D.C.',
    'Washington DC',
  ];

  const handleLocationInputChange = (event, newInputValue) => {
    // fires when text is entered in the location field or when value selected from the dropdown
    if (event === null || event.type === 'blur') {
      return;
    }
    setSearchTerm(newInputValue);
    const isASearchText = searchLocations.some(
      obj => obj.searchText === newInputValue,
    );

    if (
      !isASearchText ||
      // special check for target strings so search function runs to return 'District of Columbia' whether string is found in searchLocations or not
      targetSearchStrings.some(targetSearch => targetSearch === newInputValue)
    ) {
      debounceFn(newInputValue);
    }
  };

  const highlightText = (text, search) => {
    const parts = parseSearchText(text, search);
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === search.toLowerCase()
                ? { fontWeight: 700 }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  const handleSelect = (event, value) => {
    if (!value) return;
    const isDuplicate = locations.some(
      loc => loc.searchText === value.searchText,
    );
    if (isDuplicate) {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.LocationAlreadyAdded,
        }),
      );
      setSearchTerm('');
      return;
    }
    if (limit && locations.length >= limit) {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.MaxCitiesReached,
        }),
      );
      setSearchTerm('');
      return;
    }
    setFormDirty?.(true);
    dispatch(addPreferredLocation(value));
    if (value) {
      setSearchTerm('');
    }
  };

  const handleRemoveLocation = useCallback(
    value => {
      dispatch(removePreferredLocation(value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClearAll = () => {
    setFormDirty?.(false);
    dispatch(clearLocations());
  };

  const handleShowMore = () => {
    setShowMore(prev => !prev);
  };

  const locationGrid = useMemo(() => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            id="location-grid"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 2,
              transition: 'max-height 0.25s linear',
              maxHeight: showMore ? `${height}px` : '76px',
              overflow: 'hidden',
            }}
          >
            {locations &&
              locations.map((loc, i) => {
                return (
                  <PillButton
                    key={`${loc.searchText}-${i}`}
                    label={loc.searchText}
                    onDeleteCallback={() => handleRemoveLocation(loc)}
                    onClick={null}
                    active={true}
                    deleteIcon={
                      <CloseIcon
                        sx={{
                          color: `${theme.palette.system.white} !important`,
                          fontSize: '18px !important',
                        }}
                      />
                    }
                  />
                );
              })}
          </Box>
          {height > 76 && (
            <Box
              display="flex"
              justifyContent="center"
              onClick={handleShowMore}
              sx={{
                marginTop: '16px',
                '& .MuiSvgIcon-root': {
                  height: '14px',
                  color: theme.palette.system.skyBlue,
                  transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s linear',
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                sx={{ cursor: 'pointer' }}
                gap={1}
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  textTransform="uppercase"
                  color={theme.palette.system.skyBlue}
                  fontWeight={600}
                  lineHeight="19px"
                  data-testid="show-more"
                >
                  {showMore ? 'SHOW LESS' : `SHOW MORE (${overFlowCount})`}
                </Typography>
                <ArrowDownSvg />
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }, [showMore, height, locations, overFlowCount, handleRemoveLocation]);

  // useRef doesn't play nicely with useEffect, so using this observer was the best way I could find to track the height and overflow of the locations
  // useLayoutEffect allows for checking the height of the element after it has been rendered to accomodate the overflow and display the show more button
  useLayoutEffect(() => {
    const elm = document.getElementById('location-grid');
    if (!elm) return;
    if (elm.scrollHeight > 0) {
      setHeight(elm.scrollHeight);
    }
    const mutationObserver = new MutationObserver(() => {
      setHeight(elm.scrollHeight);
    });
    mutationObserver.observe(elm, { childList: true });
    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const overFlowElm = document.getElementById('location-grid')!;
    if (overFlowElm) {
      const containerRect = overFlowElm.getBoundingClientRect();
      const childrenElms = overFlowElm.children;
      let count = 0;

      for (let i = 0; i < childrenElms.length; i++) {
        const childRect = childrenElms[i].getBoundingClientRect();
        if (
          childRect.bottom > containerRect.bottom ||
          childRect.right > containerRect.right
        ) {
          count++;
        }
      }
      setOverFlowCount(count);
    }
  }, [locations.length]);

  useEffect(() => {
    if (!name) return;
    register(name);
    setValue(name, locations, { shouldValidate: true });
  }, [locations, name, setLocations]);

  return (
    <>
      <Grid
        container
        item
        id="location-form"
        gap={2}
        display="flex"
        flexDirection="column"
      >
        <Grid
          item
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography
            variant="subtitle1"
            color="system.midnightBlue"
            textAlign="left"
          >
            Location
          </Typography>
          <Button
            variant="text"
            size="xsmall"
            id="clear-all"
            onClick={handleClearAll}
            sx={{
              padding: '0px',
              height: 'auto',
            }}
            data-testid="clear-all-button"
          >
            <Typography
              variant="body1"
              color="system.skyBlue"
              lineHeight="22px"
              sx={{
                cursor: 'pointer',
                textTransform: 'none',
              }}
            >
              Clear All
            </Typography>
          </Button>
        </Grid>
        {ctaText && (
          <Grid item>
            <Typography
              variant="body1"
              color={theme.palette.system.coolGray}
              data-testid="ctaText"
            >
              {ctaText}
            </Typography>
          </Grid>
        )}
        <Grid item sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(14px, 13px)',
              zIndex: 1200,
            }}
          >
            <SearchIcon size={25} />
          </Box>
          <Autocomplete
            aria-required="true"
            id="location-search-text"
            options={searchLocations}
            noOptionsText="No Options Found"
            autoHighlight
            getOptionLabel={option => option.searchText}
            onInputChange={(event, newInputValue) => {
              searchTextRef.current = newInputValue;
              handleLocationInputChange(event, newInputValue);
            }}
            inputValue={searchTerm}
            onChange={handleSelect}
            popupIcon={<ArrowDownSvg fontSize="small" />}
            sx={{
              '& #location-search-text-label': {
                left: '42px',
              },
              '& #location-search-text': {
                paddingLeft: '46px',
              },
            }}
            renderInput={params => (
              <TextField {...params} label={placeholderText} variant="filled" />
            )}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  style={{
                    fontSize: '16px',
                    color: theme.palette.system.grayText,
                    lineHeight: '22px',
                    height: '40px',
                  }}
                >
                  {option.searchText === 'District of Columbia' &&
                  !option.searchText.includes(searchTextRef.current.toString())
                    ? option.searchText
                    : highlightText(
                        option.searchText,
                        searchTextRef.current.toString(),
                      )}
                </li>
              );
            }}
            PaperComponent={props => <Paper elevation={4} {...props} />}
            filterOptions={(options, state) => options}
          />
        </Grid>
        <Grid item>{locationGrid}</Grid>
      </Grid>
    </>
  );
};
