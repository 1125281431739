import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';
export const createJobSearchStyles = makeStyles({
  mapContainer: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingRight: '18px',
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: '0',
    },
    '& .leaflet-container': {
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 66px)',
        width: '100vw',
      },
      height: 'calc(100vh - 169px)',
      width: '100%',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        borderRadius: '0',
      },
    },
    '& .leaflet-div-icon': {
      background: 'none',
      border: 'none',
    },
    '& .leaflet-bottom.leaflet-right': {
      display: 'none',
    },
    '& .leaflet-control-container': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  mapJobContainer: {
    position: 'absolute',
    height: 'fit-content',
    zIndex: 400,
    bottom: '24px !important',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& .amn-card': {
      marginBottom: '24px !important',
    },
    '& .leaflet-container': {
      zIndex: 390,
    },
  },
  mapJobContainerExpanded: {
    position: 'absolute',
    height: 'fit-content',
    zIndex: 400,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& .amn-card': {
      margin: '24px !important',
    },
    '& .leaflet-container': {
      zIndex: 390,
    },
  },
  mobileSwiper: {
    marginTop: '-190px',
    zIndex: 400,
    position: 'relative',
    paddingLeft: '24px',
    width: '100vw',
    '& .amn-card': {
      padding: '12px',
      '& .card-img': {
        width: '69px',
        height: '132px',
        borderRadius: '10px',
        marginRight: '12px',
      },
      '& .card-head': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '12px',
      },
      '& .card-price': {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
      },
    },
  },
});

export const NoJobsRightContainer = styled.div`
  @media (max-width: 1023px) {
    padding-top: 20px;
    margin: 20px 0px;
  }
  .amn-card {
    padding: 12px;
    padding-bottom: 0;
    border-radius: 10px;
    @media (min-width: 1366px) {
      margin-right: 24px !important;
    }
    position: relative;
    box-shadow: 0px 1px 15px ${theme.palette.system.boxShadowGray};
  }
`;

export const Divider = styled.div`
  margin-top: 12px;
  width: 100px;
  height: 4px;
  background: ${theme.palette.system.skyBlue};
  opacity: 1;
`;
