/**
 * Asynchronously loads the component for Assignment Details
 */

import { lazyLoad } from 'utils/loadable';

export const AssignmentsDetails = lazyLoad(
  () => import('./AssignmentsDetails'),
  module => module.AssignmentsDetails,
);
