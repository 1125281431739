import ICountry from 'interfaces/Common/ICountry';
import { ICountryAndProvince } from 'interfaces/Lookup/ICountryAndProvince';
import { IReferralRequest } from 'interfaces/Lookup/IReferralRequest';
import { ITravelCompanies } from 'interfaces/Lookup/ITravelCompanies';
import { IDisciplineResponse } from 'types/DisciplineResponse';
import { VerificationCodeRequest } from 'types/VerificationCodeRequest';
import { http } from '../BaseApi';

export const fetchCountries = async (): Promise<ICountry[]> => {
  return await http.get<ICountry[]>('/auth/countries');
};

export const fetchStates = async (): Promise<ICountryAndProvince[]> => {
  return await http.get('/auth/statesAndProvinces');
};

export const fetchDisciplines = async (
  offset: string,
  limit: string,
  userDisciplineTypeId: string,
): Promise<IDisciplineResponse> => {
  const url = `/auth/disciplines?&offset=${offset}&limit=${limit}&userDisciplineTypeId=${userDisciplineTypeId}`;
  return await http.get<IDisciplineResponse>(url);
};

export const postReferral = async (
  userId: string,
  postReferralBody: IReferralRequest,
): Promise<any> => {
  const url = `/referrals/${userId}`;
  return await http.post(url, postReferralBody);
};

/**
 * Create verification code for the user
 * @param request username and firstName model
 * @returns success in Json
 */
export const createUserCode = async (
  request: VerificationCodeRequest,
): Promise<IDisciplineResponse> => {
  return await http.post<VerificationCodeRequest>(
    '/auth/user/sendcode',
    request,
  );
};

export const fetchTravelCompanies = async (): Promise<ITravelCompanies[]> => {
  return await http.get('/travel-companies');
};
