import { IContact } from 'interfaces/User/Contact/IContact';

// setting this as the strike recruiter for all strike assignments regardless of api data.
// as of 5/2/2023 this is the only recruiter for strike assignments that should be displayed and isn't a guaranteed return from the api.
export const defaultStrikeRecruiter: IContact = {
  contactImage: undefined,
  emailAddress: 'strike@amnhealthcare.com',
  employeeId: 13074,
  employeeRole: 'Recruiter',
  employeeRoleId: 46,
  firstName: 'Strike',
  lastName: 'Support',
  phoneNumber: '(800) 458-8973',
  smsNumber: undefined,
  workDayId: undefined,
};
