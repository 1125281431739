export enum NotificationCategory {
  Benefits = 1,
  Credentialing,
  Booked_Assignment,
  Current_Assignment,
  Profile,
  Time_Pay,
  General,
  Job_Match,
  Virtual_Interview,
}
