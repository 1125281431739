export enum CredentialStatus {
  Open = 100,
  Pending = 200,
  Accepted = 250,
  Rejected = 300,
  Added = 400,
  Compliant = 500,
}

export enum TimeEntryStatus {
  Open = 100,
  Pending = 200,
  Approved = 300,
  Rejected = 400,
}
