import React from 'react';
import { createSvgIcon } from '@mui/material';

export const PendingIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_7662"
          data-name="Rectangle 7662"
          width="12"
          height="12"
        />
      </clipPath>
    </defs>
    <g id="Group_14688" data-name="Group 14688" clip-path="url(#clip-path)">
      <path
        id="Path_26898"
        data-name="Path 26898"
        d="M9.583,17.193V15.923a5.658,5.658,0,0,0,.674-.133,5.341,5.341,0,0,0,.643-.212l.626,1.1a4.938,4.938,0,0,1-.948.345,6.452,6.452,0,0,1-1,.173"
        transform="translate(-3.194 -5.193)"
      />
      <path
        id="Path_26899"
        data-name="Path 26899"
        d="M13.808,15.265l-.627-1.113q.266-.22.509-.463t.462-.509l1.113.627a7.491,7.491,0,0,1-.674.791,5.627,5.627,0,0,1-.783.666"
        transform="translate(-4.394 -4.393)"
      />
      <path
        id="Path_26900"
        data-name="Path 26900"
        d="M16.675,11.527l-1.1-.627a5.492,5.492,0,0,0,.212-.643,5.711,5.711,0,0,0,.133-.674h1.269a6.466,6.466,0,0,1-.173,1,5.034,5.034,0,0,1-.345.948"
        transform="translate(-5.193 -3.195)"
      />
      <path
        id="Path_26902"
        data-name="Path 26902"
        d="M10.667.667V2.233A5.821,5.821,0,0,0,8.592.583,5.96,5.96,0,0,0,6,0,5.8,5.8,0,0,0,3.658.475,6.032,6.032,0,0,0,.475,3.658,5.8,5.8,0,0,0,0,6,5.8,5.8,0,0,0,.475,8.342a6.032,6.032,0,0,0,3.183,3.183,5.634,5.634,0,0,0,1.481.409l0-1.341A4.463,4.463,0,0,1,2.691,9.309,4.5,4.5,0,0,1,1.333,6,4.5,4.5,0,0,1,2.691,2.691,4.5,4.5,0,0,1,6,1.333a4.5,4.5,0,0,1,2.15.533A4.952,4.952,0,0,1,9.833,3.333H8V4.667h4v-4Z"
        transform="translate(0)"
      />
    </g>
  </svg>,
  'PendingIcon',
);
