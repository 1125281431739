export const allowedFileTypes = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  tiff: 'image/tiff',
  pdf: 'application/pdf',
  gif: 'image/gif',
  png: 'image/png',
  bmp: 'image/bmp',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
};

export const ProfileReadiness = {
  MIN: 50,
  MAX: 61,
};
