import { PayloadAction } from '@reduxjs/toolkit';
import { cleanupPlacementType } from 'helpers/JobDetailsHelper';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IJobDetailRequest } from 'interfaces/Jobs/IJobDetailRequest';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/jobs/jobService';
import { RootState } from 'store/configureStore';
import { setError } from 'store/slices/error/errorSlice';
import { jobDetailsActions } from 'store/slices/jobs/jobDetailSlice';
import { setSelectedJob } from 'store/slices/jobs/jobsSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';

const trackedJobDetailsData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-job-details');

const userId = (state: RootState) => state.auth.userId;

export function* getJobDetailSaga(action: PayloadAction<number | undefined>) {
  if (action.payload === undefined) {
    yield put(jobDetailsActions.resetJobDetails());
    return;
  }
  let currentUserId = yield select(userId);
  let jobsRequest = {} as IJobDetailRequest;
  jobsRequest.userId = currentUserId;
  jobsRequest.jobId = action.payload;
  try {
    const response = yield call(
      trackedJobDetailsData,
      service.fetchJobDetails,
      jobsRequest,
    );
    const { data } = response;
    if (response && data !== '') {
      if (data) {
        let updatedJobDetails = { ...data?.items[0] };
        let { facility } = updatedJobDetails;
        facility = { ...facility };
        updatedJobDetails = { ...updatedJobDetails, facility };
        updatedJobDetails.placementType = cleanupPlacementType(
          updatedJobDetails?.placementType,
        );
        yield put(jobDetailsActions.setJobDetail({ ...updatedJobDetails }));
      }
    } else {
      yield put(setActivePage('Job Not Found'));
      yield put(jobDetailsActions.resetJobDetails());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

export function* jobDetailSaga() {
  yield all([takeLatest(setSelectedJob.type, getJobDetailSaga)]);
}
