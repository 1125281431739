import { http } from 'services/BaseApi';

export const getHomePageDataService = async (userId: string): Promise<any> => {
  try {
    return await http.get(`home/${userId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
