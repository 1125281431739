import { Grid, Typography } from '@mui/material';
import { ErrorIcon } from 'assets/icons/Alert/ErrorIcon';
import React, { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  test?: boolean;
}

interface State {
  error: boolean;
  errorInfo: any;
}

export class GeneralErrorBoundary extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    test: false,
  };

  state = {
    error: false,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState(() => ({ error: !!error, errorInfo }));
  }

  resetError = () => {
    this.setState(() => ({ error: false, errorInfo: null }));
  };

  render() {
    if (this.state.error || this.props.test) {
      return (
        <FallbackComponent
          errorInfo={this.state.errorInfo}
          test={this.props.test || false}
          resetError={this.resetError}
        />
      );
    }

    return <>{this.props.children}</>;
  }
}

interface FallbackProps {
  errorInfo: any;
  test: boolean;
  resetError: () => void;
}

const FallbackComponent = (props: FallbackProps) => {
  return (
    <>
      <Grid
        container
        justifyContent={'center'}
        width={'100%'}
        height={'100%'}
        alignItems={'center'}
      >
        <Grid item padding={1}>
          <ErrorIcon />
        </Grid>
        <Grid item padding={1}>
          <Typography>
            {'An unexpected error has occurred '}
            {props.test ? '**TEST**' : ''}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
