/* eslint-disable no-unused-vars */
export enum LeadPageType {
  CONTACTS = 'contacts',
  RESOURCES = 'resources',
  PROFILE = 'profile',
  SKILLS_CHECKLIST = 'skills checklist',
  TIME_AND_PAY = 'time and pay',
  PREFERENCES = 'preferences',
  NO_TYPE = 'no type',
  PROMOTIONS = 'promotions',
}
