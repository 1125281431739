import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

const ValidationRulesContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 12px 0 8px 0;
`;

const ValidationRuleRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
`;

const ValidationDot = styled.span.attrs(props => ({
  color: props.color,
}))`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 12px;
`;

const SuccessCheck = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${theme.palette.system.skyBlue};
`;

export {
  ValidationDot,
  ValidationRulesContainer,
  ValidationRuleRow,
  SuccessCheck,
};
