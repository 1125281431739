import React, { useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { theme } from 'styles/theme';
import { CardDetailsItem } from '../Cards/CardDetails';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import CloseIcon from 'assets/icons/Close/Close';
import { AppButton } from '../Button/Button';
import { buttonSizes } from '../Button/Button.styles';
import { desktopWidth } from 'styles/media';
import { DrawerStyles } from '../Drawer/Drawer.styles';

export const SearchResultsSplash = props => {
  let matchesMinWidth1024 = useMediaQuery(desktopWidth);
  const DrawerClasses = DrawerStyles({ showFooter: true });

  const [downloadActive, setDownloadActive] = useState<boolean>(false);

  const toggleDownload = useCallback(() => {
    setDownloadActive(prevState => {
      props.onDownloadIconClick?.(!prevState);
      return !prevState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onDownloadIconClick]);

  const onDownload = useCallback(() => {
    props.onDownloadClick?.();
    setDownloadActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onDownloadClick]);

  useEffect(() => {
    return () => {
      setDownloadActive(prevState => {
        if (prevState) {
          props.onDownloadIconClick?.(false);
        }
        return false;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      id="search-result-splash"
      sx={{
        display: 'flex',
        width: { md: '555px' },
        height: props?.searchResults?.length ? 'unset' : '188px',
        padding: '24px',
        borderRadius: '10px',
        boxShadow: `0px 1px 5px ${theme.palette.system.boxShadowGray}`,
        ...props.cardSx,
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiBox-root': {
            borderBottom: props.isMobile
              ? `1px solid ${theme.palette.system.border}`
              : 'unset',
          },
          '& .MuiBox-root:last-child': {
            borderBottom: 'unset',
          },
        }}
      >
        <Grid
          container
          item
          color={
            props.isMobile
              ? theme.palette.system.grayText
              : theme.palette.system.midnightBlue
          }
          mb={props?.searchResults?.length ? 2 : 10}
          justifyContent="space-between"
        >
          <Grid item display="block">
            Search Results
          </Grid>
          {!!props.onDownloadIconClick &&
            !!props?.searchResults?.length &&
            !(props?.isMobile ?? false) && (
              <Grid item sx={{ cursor: 'pointer' }} onClick={toggleDownload}>
                {downloadActive ? (
                  <CloseIcon width="18px" height="18px" />
                ) : (
                  <DownloadIcon />
                )}
              </Grid>
            )}
        </Grid>
        {props?.searchResults?.length ? (
          props.searchItemComponent ? (
            props.searchResults.map((result, index) => {
              return (
                <Grid
                  container
                  item
                  key={index}
                  display="flex"
                  flexDirection="column"
                  onClick={() => props.saveToCache(result)}
                >
                  {props.searchItemComponent(result, index)}
                </Grid>
              );
            })
          ) : (
            <div onClick={props.searchResultOnClick}>
              <CardDetailsItem
                cards={props.searchResults}
                onCardClick={props.saveToCache}
              />
            </div>
          )
        ) : (
          <Grid
            item
            color={theme.palette.system.coolGray}
            sx={{ fontStyle: 'italic', alignSelf: 'center' }}
          >
            No results found.
          </Grid>
        )}
        {downloadActive || props.isDownloadToggledOn ? (
          <Box
            className={DrawerClasses.buttonFooter}
            sx={{
              justifyContent: 'right',
              position: { xs: 'absolute !important', sm: 'unset !important' },
              backgroundColor: { xs: theme.palette.system.white, sm: 'none' },
            }}
          >
            <Box
              role="button"
              aria-label="download-cancel-button"
              className={DrawerClasses.cancelButton}
            >
              <AppButton
                onClick={toggleDownload}
                type="button"
                size="auto"
                variant="action-link"
                id="resource-download-cancel"
              >
                CANCEL
              </AppButton>
            </Box>
            <Box
              role="button"
              aria-label="download-action-button"
              sx={{
                display: 'flex',
                width: matchesMinWidth1024 ? buttonSizes.md : '100%',
                justifyContent: { xs: 'center', sm: 'flex-end' },
              }}
            >
              <AppButton
                type="button"
                variant="primary"
                onClick={onDownload}
                width={matchesMinWidth1024 ? buttonSizes.sm : buttonSizes.xl}
                id="resource-download-button"
              >
                Download
              </AppButton>
            </Box>
          </Box>
        ) : null}
      </Grid>
    </Card>
  );
};
