import React, { FC, useEffect } from 'react';
import { get, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';

import { ErrorAdornment } from 'components/common/Adornments/ErrorAdornment';
import { IReactHookFormTextFieldProps } from 'interfaces/Props/IReactHookFormTextFieldProps';
import useScrollToValidation from 'utils/hooks/useScrollToValidation';

export const ReactHookFormTextField: FC<IReactHookFormTextFieldProps> = ({
  label,
  name,
  required,
  onChange,
  disabled,
  value,
  maxLength,
  autoComplete,
  defaultValue,
  type,
  inputProps,
  sx,
  placeholder,
  rows,
  maxRows,
  multiline,
  helperText,
  endAdornment,
  inputLabelProps,
}: IReactHookFormTextFieldProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name ?? '');

  useScrollToValidation({ name });

  useEffect(() => {
    /*
     * This will not fire the onChange event passed in
     */
    setValue(name, defaultValue || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  return (
    <TextField
      id={name + '-textbox'}
      label={label}
      value={value}
      defaultValue={defaultValue}
      type={type}
      required={required}
      variant="filled"
      error={!!error}
      helperText={error?.message || helperText}
      fullWidth
      autoComplete={autoComplete}
      {...register(name)}
      disabled={disabled}
      inputProps={{
        maxLength: maxLength,
        'aria-label': name,
      }}
      onChange={e => onChange?.(e)}
      InputProps={{
        ...inputProps,
        endAdornment: endAdornment ?? ErrorAdornment(name, errors),
      }}
      InputLabelProps={{ ...inputLabelProps }}
      sx={sx}
      placeholder={placeholder}
      rows={rows ?? undefined}
      maxRows={multiline && maxRows ? maxRows : undefined}
      multiline={multiline ?? false}
      data-testid={name}
    />
  );
};
