import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const ShowHideAdornment = props => {
  const [showPassword, setShowPassword] = useState(false);
  const { callback } = props;

  const handleClick = () => {
    setShowPassword(prev => !prev);

    if (typeof callback === 'function') {
      callback(!showPassword);
    }
  };

  const adornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="Toggle password visibility"
        onClick={handleClick}
        size="large"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
  return adornment;
};
