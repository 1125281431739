import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IArbitrationAgreementState } from './arbitrationAgreementState';
import { IArbitrationAgreement } from 'interfaces/ArbitrationAgreement/ArbitrationAgreement';

const initialState: IArbitrationAgreementState = {
  currentArbitrationAgreement: {
    agreement: {
      signee: 'Jane Doe',
      authorizedSignatoryName: 'Robin Johnson',
      authorizedSignatoryTitle: 'Group President',
      authorizedSignatorySubtitle: 'Nursing and Allied Solutions',
      authorizedSignatorySignatureImageUrl: '',
    },
    signedDate: '2024-04-24T22:42:40.4061352Z',
    readyForSigningDate: '2024-04-20T22:42:40.4061352Z',
    ipAddress: '123.1.123.1',
    statusId: 200,
    status: 'Signed',
  },
  arbitrationAgreementUrl: '',
  signature: '',
  authorizedSignatureImgUrl: '',
};

const getAuthorizedImgUrl = createAction('GET_AUTHORIZED_IMG_URL');

const arbitrationAgreementSlice = createSlice({
  name: 'arbitrationAgreementSlice',
  initialState,
  reducers: {
    setAuthSignatureImgUrl(state, action: PayloadAction<string>) {
      state.authorizedSignatureImgUrl = action.payload;
    },
    setArbitrationAgreement(
      state,
      action: PayloadAction<IArbitrationAgreement>,
    ) {
      state.currentArbitrationAgreement = action.payload;
    },
    setArbitrationAgreementUrl(state, action: PayloadAction<string>) {
      state.arbitrationAgreementUrl = action.payload;
    },
    resetArbitrationAgreementUrl(state) {
      state.arbitrationAgreementUrl = '';
    },
    signArbitrationAgreement(state, action: PayloadAction<any>) {
      state.signature = action.payload.fullLegalName;
    },
  },
});

export const arbitrationAgreementSliceActions = {
  getAuthorizedImgUrl,
  ...arbitrationAgreementSlice.actions,
};

export default arbitrationAgreementSlice.reducer;
