import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { ErrorIcon } from 'assets/icons/Alert/ErrorIcon';
import { InfoIcon } from 'assets/icons/Alert/InfoIcon';
import { SuccessIcon } from 'assets/icons/Alert/SucessIcon';
import { WarningIcon } from 'assets/icons/Alert/WarningIcon';
import { AlertBarType } from 'enums/alertBarTypes';
import { IActionButtonProps } from 'interfaces/Props/IActionButtonProps';
import { IAlertbarProps } from 'interfaces/Props/IAlertbarProps';
import { RootState, useAppDispatch } from 'store/configureStore';
import {
  closeAlert,
  emptyAlertState,
} from 'store/slices/alertbar/alertbarSlice';
import styled from 'styled-components';
import { theme } from 'styles/theme';
import { alertbarStyles } from './AppAlertbar.styles';

const StylesActionButton = styled(Button)<IActionButtonProps>`
  color: ${props => props.textcolor ?? theme.palette.system.skyBlue};
  font-size: 14px;
`;
export interface SnackObject {
  variant: string;
  message: string;
}
const variantIcon = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const AlertContent = props => {
  let {
    classes,
    message,
    variant,
    actionButton,
    handleClose,
    style,
    ...other
  } = props;

  const typeofMessage = typeof message;
  const Icon = variantIcon[variant];
  const isValidReactNode =
    typeofMessage === 'string' ? true : React.isValidElement(message);

  if (!isValidReactNode) {
    message = <></>;
  }

  const handleActionMethod = (event: React.SyntheticEvent | Event) => {
    if (typeof actionButton?.onAction === 'function') {
      actionButton?.onAction();
      handleClose(event, true);
    }
  };

  const messageToDisplay =
    typeofMessage === 'string' ? (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Icon id="alertbar-snackbarcontent-icon" className={classes.icon} />
        </Box>
        <Typography
          id="alertbar-snackbarcontent-message"
          className={classes.message}
          sx={{
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '600',
          }}
        >
          {message}
        </Typography>
      </Box>
    ) : (
      message
    );

  const renderActionButton = (
    <StylesActionButton
      id="actionButton"
      textcolor={actionButton?.textcolor}
      size="small"
      onClick={handleActionMethod}
      variant={actionButton?.variant}
      sx={{ ...style['action-button'] }}
    >
      {actionButton?.text ?? ''}
    </StylesActionButton>
  );

  return (
    <SnackbarContent
      id="alertbar-snackbarcontent"
      aria-describedby="alert-snackbar-content"
      className={`${classes.wrapper}, ${classes[variant]}`}
      sx={{
        flexFlow: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiSnackbarContent-message': {
          width: '100% !important',
        },
      }}
      message={messageToDisplay}
      action={(actionButton?.text ?? '') !== '' ? renderActionButton : <></>}
      {...other}
    />
  );
};

export const AppAlertbar = (props: IAlertbarProps) => {
  const {
    id,
    refId = AlertBarType.APP_ALERT,
    sx = {},
    durationTime = 5000,
  } = props;

  const dispatch = useAppDispatch();
  const alertbarState = useSelector(
    (state: RootState) => state.alertbarComponent?.[refId] || emptyAlertState,
  );
  const {
    isOpen,
    variant,
    message,
    alertbarRefId,
    duration = durationTime,
    closeONWindowClick = true,
    actionButton,
    onClose,
    style,
  } = alertbarState;

  const handleClose = (event, fromAction) => {
    dispatch(closeAlert({ alertbarRefId: refId }));
    onClose && onClose(fromAction);
  };
  const classes = alertbarStyles(theme);

  if (!message) return <></>;

  return (
    <Snackbar
      id={id}
      sx={{
        [theme.breakpoints.up('sm')]: { minWidth: '362px' },
        [theme.breakpoints.down('sm')]: {
          width: '345px',
        },
        minHeight: '48px',
        top: '0',
        bottom: 'auto',
        width: 'fit-content',
        position: 'relative',
        ...sx,
        ...style,
      }}
      open={
        ((refId ?? '') !== ''
          ? refId === alertbarRefId
            ? isOpen
            : false
          : false) as boolean
      }
      ClickAwayListenerProps={{
        onClickAway: event => {
          if (closeONWindowClick) {
            handleClose(event, false);
          } else {
            return event;
          }
        },
      }}
      autoHideDuration={duration as number}
      onClose={event => {
        handleClose(event, false);
      }}
    >
      {AlertContent({
        variant: variant,
        message: message,
        classes: classes,
        actionButton: actionButton,
        handleClose: handleClose,
        style: style,
      })}
    </Snackbar>
  );
};
