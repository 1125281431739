import { Card, Grid, Typography } from '@mui/material';
import TravelImage from 'assets/images/travelImage2.svg';
import { AppLink } from 'components/common/Link/Link';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { PreferenceTypes } from 'enums/preferences';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppSelector } from 'store/hooks';
import { theme } from 'styles/theme';
import { Divider, NoJobsRightContainer } from './JobSearch.styles';
import { UserStatusTypeId } from 'enums/userStatusTypeId';

export default function NoSearchedJobs({
  isStrikeJobs = false,
}: {
  isStrikeJobs?: boolean;
}) {
  const history = useHistory();
  const jobSearchFilter = useAppSelector(g => g.jobs.jobSearchFilter);
  const isMobileDevice = useMobileMediaQuery();
  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );

  const noJobsText = useMemo(() => {
    if (isStrikeJobs) {
      return 'There are currently no available strike job opportunities for you. Please check back later.';
    } else {
      return jobSearchFilter?.isFavorite
        ? 'You haven`t favorited any jobs or they are no longer available.'
        : userStatusIsLead
        ? 'Change Preferences to See Jobs'
        : 'No jobs at this time!';
    }
  }, [isStrikeJobs, jobSearchFilter?.isFavorite, userStatusIsLead]);

  const goToAutoSubmission = React.useCallback(() => {
    history.push(
      `/${AppRouteNames.PREFERENCES}?categoryId=${PreferenceTypes.AUTO_SUBMISSION}`,
      {
        from: 'autoSubmitShortcutJob',
      },
    );
  }, [history]);

  const noJobsLinkText = useMemo(() => {
    if (isStrikeJobs || jobSearchFilter?.isFavorite) return;
    return (
      <Typography
        variant="body1"
        color={theme.palette.system.darkGray}
        component="div"
        fontSize="16px"
        lineHeight="1.4rem"
        marginTop="24px"
        letterSpacing="0px"
      >
        Update your job preferences or{' '}
        <AppLink
          component="a"
          underline="hover"
          id="job-search-auto-submission-link"
          linkStyles={{
            cursor: 'pointer',
          }}
          onClick={() => goToAutoSubmission()}
        >
          {'Enable Auto-Submission'}
        </AppLink>{' '}
        to fast-track your application for the perfect job.
      </Typography>
    );
  }, [goToAutoSubmission, isStrikeJobs, jobSearchFilter?.isFavorite]);

  const noJobsTextForLeadUser = useMemo(() => {
    if (isStrikeJobs || jobSearchFilter?.isFavorite) return;
    return (
      <Typography
        variant="body1"
        color={theme.palette.system.darkGray}
        component="div"
        fontSize="16px"
        lineHeight="1.4rem"
        marginTop="24px"
        letterSpacing="0px"
      >
        Hmm nothing is coming up with those filters, update your preferences to
        see jobs!
      </Typography>
    );
  }, [isStrikeJobs, jobSearchFilter?.isFavorite]);

  return (
    <NoJobsRightContainer>
      <Card id="no-jobs-right-container" className="amn-card">
        <Grid
          container
          item
          xs={12}
          sx={{ padding: { xs: '24px', md: '90px 120px 90px 120px' } }}
        >
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ paddingRight: { xs: '0', md: '0px' } }}
          >
            <Grid
              item
              xs={12}
              textAlign="left"
              sx={{
                paddingLeft: {
                  xs: '24px !important',
                  md: '0px !important',
                  lg: '85px !important',
                },
                paddingTop: { xs: 'padding-top: 24px !important', md: 'auto' },
                maxWidth: {
                  xs: '240px !important',
                  md: 'auto',
                  lg: '302px !important',
                },
              }}
            >
              <Typography
                sx={{
                  font: 'italic normal normal 20px/24px Open Sans',
                  letterSpacing: '0px',
                  color: 'system.midnightBlue',
                }}
              >
                {noJobsText}
              </Typography>
              <Divider />
              {userStatusIsLead ? noJobsTextForLeadUser : noJobsLinkText}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              marginTop: { xs: '36px', md: '0' },
              paddingLeft: '0px !important',
            }}
          >
            <img
              src={TravelImage}
              alt="NoSearchedJobs"
              width={isMobileDevice ? '123px' : '256px'}
              height={isMobileDevice ? '115px' : '256px'}
            />
          </Grid>
        </Grid>
      </Card>
    </NoJobsRightContainer>
  );
}
