import makeStyles from '@mui/styles/makeStyles';

export const createDropdownStyles = makeStyles(theme => ({
  selectContainer: {
    paddingLeft: '6px',
    '& .MuiSvgIcon-root': {
      width: '14px',
    },
  },
  formContainer: {
    '& #dropdown-selector': {
      backgroundColor: theme.palette.system.white,
      padding: '10px 20px 10px 0',
    },
  },
}));
