import { Box, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

export const JobTypeDialogContent = () => {
  return (
    <Box
      sx={{
        maxWidth: '568px',
        maxHeight: '331px',
        margin: '8px 24px 20px 0px',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '20px',
          fontSize: '14px',
          marginBottom: '5px',
          color: 'system.grayText',
        }}
      >
        Travel Assignment
      </Typography>
      <Typography variant="body1" color={theme.palette.system.coolGray}>
        These are traditional travel assignments that are typically 13 weeks in
        length, start dates vary.
      </Typography>

      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '20px',
          fontSize: '14px',
          marginTop: '24px',
          marginBottom: '5px',
          color: 'system.grayText',
        }}
      >
        Crisis/Rapid Response Assignment
      </Typography>
      <Typography variant="body1" color={theme.palette.system.coolGray}>
        These are the most urgent needs and typically have a start date within
        1-4 weeks, vary from 3-13 weeks in length and 36 - 60 GWW.
      </Typography>

      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '20px',
          fontSize: '14px',
          marginTop: '24px',
          marginBottom: '5px',
          color: 'system.grayText',
        }}
      >
        Strike Assignment
      </Typography>
      <Typography variant="body1" color={theme.palette.system.coolGray}>
        These assignments support Clients during a work stoppage. Typical
        assignment length is 5 days.
      </Typography>

      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '20px',
          fontSize: '14px',
          marginTop: '24px',
          marginBottom: '5px',
          color: 'system.grayText',
        }}
      >
        Clinical Fellowship
      </Typography>
      <Typography variant="body1" color={theme.palette.system.coolGray}>
        These Clients are looking for a CF to support SLP openings. Mentorship
        provided.
      </Typography>
    </Box>
  );
};
