const characterPool =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const guidGenerator = (): string => {
  let guid = '';
  for (let i = 0; i < 25; i++) {
    guid += characterPool[Math.floor(Math.random() * characterPool.length)];
    guid += i % 7 === 0 ? '-' : '';
  }
  return guid;
};
