import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPhotoEditingState } from './photoState';

const initialState: IPhotoEditingState = {
  isEditing: false,
};

const photoSlice = createSlice({
  name: 'photoSlice',
  initialState,
  reducers: {
    setPhotoEditingState(state, action: PayloadAction<boolean>) {
      state.isEditing = action.payload;
    },
  },
});

export const { setPhotoEditingState } = photoSlice.actions;

export default photoSlice.reducer;
