import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ErrorIcon = createSvgIcon(
  <svg
    id="Controls_Icon_Alert"
    data-name="Controls/Icon/Alert"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      id="Rectangle_2269"
      data-name="Rectangle 2269"
      width="24"
      height="24"
      fill="#d9553d"
      opacity="0"
    />
    <g
      id="Group_2868"
      data-name="Group 2868"
      transform="translate(0.632 0.632)"
    >
      <path
        id="Path_3735"
        data-name="Path 3735"
        d="M26,14.635A11.368,11.368,0,1,0,37.372,26,11.382,11.382,0,0,0,26,14.635Zm0,20.887A9.519,9.519,0,1,1,35.522,26,9.53,9.53,0,0,1,26,35.522Z"
        transform="translate(-14.635 -14.635)"
        fill="#d9553d"
      />
      <g
        id="Group_2867"
        data-name="Group 2867"
        transform="translate(10.259 5.066)"
      >
        <path
          id="Path_3736"
          data-name="Path 3736"
          d="M29.675,29.852a.925.925,0,0,0,.925-.925V22.407a.925.925,0,0,0-1.85,0v6.521A.925.925,0,0,0,29.675,29.852Z"
          transform="translate(-28.565 -21.482)"
          fill="#d9553d"
        />
        <circle
          id="Ellipse_601"
          data-name="Ellipse 601"
          cx="1.11"
          cy="1.11"
          r="1.11"
          transform="translate(0 10.385)"
          fill="#d9553d"
        />
      </g>
    </g>
  </svg>,
  'ErrorIcon',
);
