export const httpSuccess = status => {
  return status >= 200 && status <= 226;
};

export const httpServerError = status => {
  return status >= 500 && status <= 511;
};

export const httpClientError = status => {
  return status >= 400 && status <= 451;
};
