import React from 'react';
import { theme } from 'styles/theme';

export function StrikeIcon({
  width = '24px',
  height = '24px',
  color = theme.palette.system.midnightBlue,
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      id="Controls_icon_Strike"
      data-name="Controls/icon/Strike"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2268"
        data-name="Rectangle 2268"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <path
        id="strike_icon"
        d="M24.662,12.8,20,17.462,15.338,12.8,12.8,15.338,17.462,20,12.8,24.662,15.338,27.2,20,22.538,24.662,27.2,27.2,24.662,22.538,20,27.2,15.338ZM20,2A18,18,0,1,0,38,20,17.984,17.984,0,0,0,20,2Zm0,32.4A14.4,14.4,0,1,1,34.4,20,14.419,14.419,0,0,1,20,34.4Z"
        transform="translate(-1 -1)"
        fill={color}
      />
    </svg>
  );
}
