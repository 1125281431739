import React from 'react';

export default function CredentialBadgeIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls-icon-Credential-Badge"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2270"
        data-name="Rectangle 2270"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g id="Group_2872" data-name="Group 2872" transform="translate(1 4)">
        <g
          id="Group_2870"
          data-name="Group 2870"
          transform="translate(5.699 10.834)"
        >
          <ellipse
            id="Ellipse_602"
            data-name="Ellipse 602"
            cx="2.969"
            cy="2.939"
            rx="2.969"
            ry="2.939"
            transform="translate(2.75)"
            fill={color}
          />
          <path
            id="Path_3737"
            data-name="Path 3737"
            d="M28.379,37.736c0-3.771-2.647-4.439-3.438-4.868a11.807,11.807,0,0,0-2.2-.037,11.815,11.815,0,0,0-2.2.037c-.791.429-3.438,1.1-3.438,4.868Z"
            transform="translate(-17.107 -25.989)"
            fill={color}
          />
        </g>
        <g
          id="Group_2871"
          data-name="Group 2871"
          transform="translate(19.441 11.582)"
        >
          <path
            id="Path_3738"
            data-name="Path 3738"
            d="M41.128,27.8h-8.9a.708.708,0,1,1,0-1.417h8.9a.708.708,0,1,1,0,1.417Z"
            transform="translate(-31.51 -26.381)"
            fill={color}
          />
          <path
            id="Path_3739"
            data-name="Path 3739"
            d="M41.128,32.475h-8.9a.708.708,0,1,1,0-1.417h8.9a.708.708,0,1,1,0,1.417Z"
            transform="translate(-31.51 -26.64)"
            fill={color}
          />
          <path
            id="Path_3740"
            data-name="Path 3740"
            d="M41.128,37.152h-8.9a.708.708,0,1,1,0-1.417h8.9a.708.708,0,1,1,0,1.417Z"
            transform="translate(-31.51 -26.9)"
            fill={color}
          />
        </g>
        <path
          id="Path_3741"
          data-name="Path 3741"
          d="M44.509,17.734H36.526a2.159,2.159,0,0,1-.085.816L35.949,20.1h8.561a.24.24,0,0,1,.239.236V41.722a.24.24,0,0,1-.239.236H13.757a.24.24,0,0,1-.239-.236V20.331a.24.24,0,0,1,.239-.236H22.2l-.493-1.546a2.173,2.173,0,0,1-.084-.815H13.757a2.613,2.613,0,0,0-2.624,2.6V41.722a2.613,2.613,0,0,0,2.624,2.6H44.509a2.613,2.613,0,0,0,2.624-2.6V20.331A2.613,2.613,0,0,0,44.509,17.734Z"
          transform="translate(-11.133 -14.32)"
          fill={color}
        />
        <path
          id="Path_3742"
          data-name="Path 3742"
          d="M29.638,20.881h4.992a.463.463,0,0,0,.426-.345l.811-2.543a.869.869,0,0,0-.706-1.184H32.982c-.623,0-.91-.345-.91-1.07,0-.978-1.151-1.62-2.434-1.62s-2.434.642-2.434,1.62c0,.725-.309,1.07-.91,1.07h-2.18a.87.87,0,0,0-.706,1.184l.812,2.543a.462.462,0,0,0,.426.345Z"
          transform="translate(-11.695 -14.119)"
          fill={color}
        />
      </g>
    </svg>
  );
}
