import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { formatPhoneNumber } from 'helpers/formatUSPhoneNumber';

const EmergencyContact: React.FC = () => {
  const { watch, setValue } = useFormContext();
  const formData = watch();

  return (
    <>
      <Grid item xs={12} mb={2}>
        <ReactHookFormTextField
          label="Name"
          name="emergencyContact.name"
          defaultValue={formData?.emergencyContact?.name}
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <ReactHookFormTextField
          label="Relationship"
          name="emergencyContact.relationship"
          defaultValue={formData?.emergencyContact?.relationship}
        />
      </Grid>
      <Grid item xs={12} mb={2}>
        <ReactHookFormTextField
          label="Phone Number"
          name="emergencyContact.phoneNumber"
          defaultValue={formData?.emergencyContact?.phoneNumber}
          onChange={e =>
            setValue(
              'emergencyContact.phoneNumber',
              formatPhoneNumber(e.target.value),
            )
          }
        />
      </Grid>
    </>
  );
};

export default EmergencyContact;
