import { PayloadAction } from '@reduxjs/toolkit';
import { StatusCode } from 'enums/StatusCode';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchCredentialStatus } from 'services/credentials/credentialService';
import { RootState } from 'store/configureStore';
import {
  onFetchSuccess,
  retrieveCredentialStatus,
  setError,
  setLoading,
  setReceivedNoContent,
} from 'store/slices/credentialStatus/credentialStatusSlice';

export const userId = (state: RootState) => state.auth.userId;

export function* credentialStatusSaga() {
  yield all([Watcher()]);
}

function* getCredentialStatus(action: PayloadAction<string>) {
  let currentUserId = yield select(userId);
  let jobId: string = action.payload;

  try {
    const response = yield call(fetchCredentialStatus, currentUserId, jobId);
    if (response.status === StatusCode.NoContent) {
      yield put(setReceivedNoContent(true));
      yield put(setLoading(false));
    } else {
      const { data } = response;
      yield put(onFetchSuccess({ jobId, ...data }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoading(false));
  }
}

function* Watcher() {
  yield takeLatest(retrieveCredentialStatus.type, getCredentialStatus);
}
