import { ADPIcon } from 'assets/icons/ADPIcon/ADPIcon';
import ContractsIcon from 'assets/icons/Assignments/Contracts/ContractsIcon';
import CredentialBadgeIcon from 'assets/icons/Assignments/CredentialBadge/CredentialBadgeIcon';
import EvaluationRequestIcon from 'assets/icons/Assignments/EvaluationRequest/EvaluationRequestIcon';
import MyContactsIcon from 'assets/icons/Assignments/MyContacts/MyContacts';
import ScheduleShiftIcon from 'assets/icons/Assignments/ScheduleShift/ScheduleShiftIcon';
import TimePayIcon from 'assets/icons/Assignments/TimePay/TimePayIcon';
import TravelItinerary from 'assets/icons/Assignments/TravelItinerary/TravelItinerary';
import { HelpIcon } from 'assets/icons/HelpIcon';
import InfoIcon from 'assets/icons/Info/InfoIcon';
import QuestionsIcon from 'assets/icons/Jobs/QuestionsIcon';
import AlertIcon from 'assets/icons/Notifications/AlertIcon';
import ReimbursementIcon from 'assets/icons/Reimbursement/ReimbursementIcon';
import { WiselyPayIcon } from 'assets/icons/WiselyPayIcon/WiselyPayIcon';
import { theme } from 'styles/theme';

export enum LinkOptions {
  CONTRACT = 'Contract',
  CREDENTIALS = 'Credentials',
  CONTACTS = 'Contacts',
  TRAVEL_DETAILS = 'Travel Details',
  TIME_ENTRY = 'Time Entry',
  REIMBURSEMENTS = 'Reimbursements',
  QUALIFICATION_ITEMS = 'Qualification Items',
  QUESTIONS = 'Questions',
  ADP = 'ADP',
  WISELY = 'Wisely',
  EVALUATION = 'Evaluation',
  RESOURCES = 'Resources',
  HELP = 'Help',
  SCHEDULE_SHIFTS = 'Scheduled Shifts',
}

export enum CredentialStatusOptions {
  DONE = 'DONE',
  PENDING = 'PENDING',
}

export const assignmentLinkStackOptions = {
  [LinkOptions.CONTRACT]: {
    leftIcon: ContractsIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Contract',
  },
  [LinkOptions.CREDENTIALS]: {
    leftIcon: CredentialBadgeIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Credentials',
  },
  [LinkOptions.CONTACTS]: {
    leftIcon: MyContactsIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'My Contacts',
  },
  [LinkOptions.TRAVEL_DETAILS]: {
    leftIcon: TravelItinerary(theme.palette.system.skyBlue, '17.05', '17.05'),
    description: 'Travel Details',
  },
  [LinkOptions.TIME_ENTRY]: {
    leftIcon: TimePayIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Time Entry',
  },
  [LinkOptions.REIMBURSEMENTS]: {
    leftIcon: ReimbursementIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Reimbursements',
  },
  [LinkOptions.QUALIFICATION_ITEMS]: {
    leftIcon: AlertIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Qualification Items',
  },
  [LinkOptions.QUESTIONS]: {
    leftIcon: QuestionsIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Questions',
  },
  [LinkOptions.ADP]: {
    leftIcon: ADPIcon(theme.palette.system.skyBlue, '17', '17'),
    description: 'ADP Workforce',
  },
  [LinkOptions.WISELY]: {
    leftIcon: WiselyPayIcon(theme.palette.system.skyBlue, '25', '27'),
    description: 'Wisely',
  },
  [LinkOptions.EVALUATION]: {
    leftIcon: EvaluationRequestIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Evaluation',
  },
  [LinkOptions.RESOURCES]: {
    leftIcon: InfoIcon(theme.palette.system.skyBlue, '17', '17'),
    description: 'Resources',
  },
  [LinkOptions.HELP]: {
    leftIcon: HelpIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Help',
  },
  [LinkOptions.SCHEDULE_SHIFTS]: {
    leftIcon: ScheduleShiftIcon({
      color: theme.palette.system.skyBlue,
      height: '17',
      width: '17',
    }),
    description: 'Scheduled Shifts',
  },
};
