import React from 'react';

export default function ArrowRightIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls_Icon_Arrow-Right"
      data-name="Controls/Icon/Arrow-Right"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2316"
        data-name="Rectangle 2316"
        width="24"
        height="24"
        fill="none"
        opacity="0"
      />
      <path
        id="Path_3770"
        data-name="Path 3770"
        d="M42.892,35.24A1.742,1.742,0,0,0,42.376,34L25.894,17.631a1.766,1.766,0,0,0-2.486,0,1.737,1.737,0,0,0,0,2.471L38.646,35.24l-15,14.9a1.739,1.739,0,0,0,0,2.471,1.768,1.768,0,0,0,2.487,0L42.376,36.476A1.742,1.742,0,0,0,42.892,35.24Z"
        transform="translate(-13.892 -16.119)"
        fill={color}
      />
    </svg>
  );
}
