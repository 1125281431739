import { ITicketCloseRequest } from 'interfaces/SupportCenter/ITicketCloseRequest';
import { ITicketGetRequest } from 'interfaces/SupportCenter/ITicketGetRequest';
import { ITicketSubmitRequest } from 'interfaces/SupportCenter/ITicketSubmitRequest';
import { http } from 'services/BaseApi';

export const getExistingTicketsCountService = async (
  userId: string,
): Promise<any> => {
  try {
    return await http.get(`/support-tickets/${userId}/count`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getExistingTicketsService = async (
  request: ITicketGetRequest,
): Promise<any> => {
  try {
    return await http.get(
      `/support-tickets/${request?.userId}?offset=${
        request?.offset ?? 0
      }&limit=${request?.limit ?? 0}&status=${request?.status?.join(',')}`,
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postSupportTicketService = async (
  request: ITicketSubmitRequest,
): Promise<any> => {
  try {
    return await http.post(`/support-tickets/${request?.userId}`, request);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const patchCloseSupportTicketService = async (
  request: ITicketCloseRequest,
): Promise<any> => {
  try {
    return await http.patch(
      `/support-tickets/${request?.userId}/${request?.caseNumber}`,
    );
  } catch (error) {
    console.log(error);
    return error;
  }
};
