async function BlobUrlToBase64(blobUrl: string) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String.split(',')[1]); // Remove data:image/...;base64, part from the result
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export default BlobUrlToBase64;
