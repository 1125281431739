import { TOAST_MESSAGE } from 'constants/helperText';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as service from 'services/referrals/referralService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import {
  getReferrals,
  setReferrals,
  setError,
} from 'store/slices/referral/referralSlice';

export const userId = (state: RootState) => state.auth.userId;

function* getReferralSaga(action) {
  try {
    let currentUserId = yield select(userId);
    const response = yield call(service.getReferrals, currentUserId);
    yield put(setReferrals(response.data));
  } catch (error: any) {
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: error.errorMessage,
      }),
    );
  }
}
function* watchActionItem() {
  yield takeEvery(getReferrals.type, getReferralSaga);
}

export function* referralSaga() {
  yield all([watchActionItem()]);
}
