import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { TOAST_MESSAGE } from 'constants/helperText';
import { actionItemTypeId } from 'enums/actionType';
import { httpSuccess } from 'helpers/serviceHelper';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IPayTransparencyRequest } from 'interfaces/User/UserProfile/IPayTransparencyRequest';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { IUserProfileRequest } from 'interfaces/User/UserProfile/IUserProfileRequest';
import { getSecureImage } from 'services/domain/domainService';
import * as service from 'services/user/userService';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { setError } from 'store/slices/error/errorSlice';
import { AppUserType } from 'enums/AppUserType';
import store from '../../../store/configureStore';
import {
  deleteUserProfilePhoto,
  getUserProfile,
  getWebTravelerApplicationStatus,
  patchPayTransparencyDetailsAction,
  setUpdatedUserProfile,
  setUserProfile,
  setWelcomeBannerStatus,
  toggleSubmitLoader,
  updatePayTransparencyDetails,
  updateProfile,
  updateWelcomeBanner,
  uploadUserProfilePhoto,
} from 'store/slices/user/userProfile/userProfileSlice';
import {
  PhotoDeleteSuccessResponse,
  PhotoUploadRequest,
  PhotoUploadSuccessResponse,
} from 'types/Photo';

export const travelerApplicationStatus = (state: RootState) =>
  state.userProfile.travelerApplicationStatus;

export const userIdSelector = (state: RootState) => state.auth.userId;

export const actionItemSelector = (state: RootState) => state.actionItem;

// Worker Sagas
function* getUserProfileSaga(action) {
  try {
    const userType = store.getState()?.auth?.userType;
    const response = yield call(service.getUserProfile, action.payload);
    const { data } = response;
    if (userType === AppUserType.LOCUMS) {
      data.emailAddress = data.email;
    }
    if (data?.profileImage?.url) {
      let imageUrl = yield getSecureImage(
        `${data.profileImage.url}/url?width=128`,
      );
      data.profileImage.url = imageUrl.data.fileUrl;
    }
    yield put(setUserProfile(data as IUserProfile));
  } catch (error) {
    yield put(setError(error as IErrorResponse));
  }
}

function* getWebTravelerApplicationStatusSaga(action) {
  try {
    const response = yield call(
      service.getWebTravelerApplicationStatusService,
      action.payload,
    );
    const { data } = response;
    if (data?.profileImage?.url) {
      let imageUrl = yield getSecureImage(
        `${data.profileImage.url}/url?width=128`,
      );
      data.profileImage.url = imageUrl.data.fileUrl;
    }
    yield put(setUserProfile(data as IUserProfile));
  } catch (error) {
    yield put(setError(error as IErrorResponse));
  }
}

function* updateUserProfileSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    let updateRequest = { ...action.payload };

    if (
      updateRequest.emergencyContact &&
      updateRequest.emergencyContact.phoneNumber === ''
    ) {
      updateRequest.emergencyContact.phoneNumber = null;
    }

    if (
      updateRequest.emergencyContact &&
      updateRequest.emergencyContact.name === ''
    ) {
      updateRequest.emergencyContact.name = null;
    }

    const response = yield call(service.updateUserProfile, updateRequest);
    const { data } = response;
    //check the payload and pass it if updated is true
    if (data.isUpdated) {
      yield put(setUpdatedUserProfile(action.payload as IUserProfileRequest));
      yield put(getUserProfile(userId));
    }
    yield put(toggleSubmitLoader(false));
    yield put(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.ChangesSaved,
      }),
    );
  } catch (error) {
    yield put(setError(error as IErrorResponse));
    yield put(toggleSubmitLoader(false));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrong,
      }),
    );
  }
}

function* addProfileImage(action: PayloadAction<PhotoUploadRequest>) {
  try {
    if (action.payload.body) {
      const response: AxiosResponse<PhotoUploadSuccessResponse, any> =
        yield call<(image: string, userId: string) => Promise<string>>(
          service.postUserProfileImage,
          action.payload.body,
          action.payload.userId,
        );
      if (response.data.url) {
        const newAction: PayloadAction<any> = {
          payload: action.payload.userId,
          type: '',
        };
        yield call(getUserProfileSaga, newAction);
      }
    }
  } catch (e) {
    yield put(setError(e as IErrorResponse));
  }
}

function* deleteProfileImage(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<PhotoDeleteSuccessResponse, any> = yield call<
      (userId: string) => Promise<string>
    >(service.deleteUserProfileImage, action.payload);
    if (response.data.isDeleted) {
      const newAction: PayloadAction<any> = {
        payload: action.payload,
        type: '',
      };
      yield call(getUserProfileSaga, newAction);
    }
  } catch (e) {
    yield put(setError(e as IErrorResponse));
  }
}

function* getWelcomeBannerStatus() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(() =>
      service.updateWelcomeBannerStatus(userId),
    );
    const { data } = response;
    if (data?.isUpdated) {
      yield put(setWelcomeBannerStatus(data?.isUpdated));
      yield put(credentialActions.getCredentialDetailAction(true));
    }
  } catch (e) {
    yield put(setError(e as IErrorResponse));
  }
}

function* updateProfilePayTransparency(
  action: PayloadAction<IPayTransparencyRequest>,
) {
  let userId = yield select(userIdSelector);
  let actionItems = yield select(actionItemSelector);
  try {
    const response = yield call(
      service.updatePayTransparency,
      userId,
      action.payload,
    );
    if (response && httpSuccess(response.status)) {
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.SuccessfullySubmitted,
        }),
      );
      yield put(updatePayTransparencyDetails(action.payload));
      if (
        actionItems.selectedItem &&
        actionItems.selectedItem.actionItemTypeId ===
          actionItemTypeId.PayTransparencyDataCollection
      ) {
        const id = [actionItems.selectedItem.id];
        let dismissals: any = { dismissals: id };
        yield put(
          actionItemActions.removeActionItem({ dismissals: dismissals }),
        );
      }
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
  }
}

// Watcher Sagas
function* watchGetUserProfile() {
  yield takeLatest(getUserProfile.type, getUserProfileSaga);
}

function* watchGetWebTravelerApplicationStatus() {
  yield takeLatest(
    getWebTravelerApplicationStatus.type,
    getWebTravelerApplicationStatusSaga,
  );
}

function* watchUpdateUserProfile() {
  yield takeLatest(updateProfile.type, updateUserProfileSaga);
}

function* watchPhotoUpload() {
  yield takeLatest(uploadUserProfilePhoto.type, addProfileImage);
}

function* watchPhotoDelete() {
  yield takeLatest(deleteUserProfilePhoto.type, deleteProfileImage);
}

function* watchWelcomeBanner() {
  yield takeLatest(updateWelcomeBanner.type, getWelcomeBannerStatus);
}

function* watchUpdatePayTransparency() {
  yield takeLatest(
    patchPayTransparencyDetailsAction.type,
    updateProfilePayTransparency,
  );
}

export function* userProfileSaga() {
  yield all([
    watchGetUserProfile(),
    watchGetWebTravelerApplicationStatus(),
    watchUpdateUserProfile(),
    watchPhotoUpload(),
    watchPhotoDelete(),
    watchWelcomeBanner(),
    watchUpdatePayTransparency(),
  ]);
}
