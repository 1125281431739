import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactHookFormDatePicker } from 'components/common/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import {
  setStartDate,
  unSetError,
} from 'store/slices/user/userPreferenceSlice';

const StartDateForm = ({ setFormDirty }: { setFormDirty?: Function }) => {
  const dispatch = useAppDispatch();

  const [startDateValue, setStartDateValue] = useState<Moment>();
  const [initialStartDate, setInitialStartDate] = useState<Moment>();

  const startDate = useAppSelector(state => state.userPreference.startDate);

  const { setValue, clearErrors } = useFormContext();

  const handleStartDate = newValue => {
    let _newValue = moment(newValue).format('MM-DD-YYYY');
    let _startDate = moment(startDate).format('MM-DD-YYYY');

    if (_newValue !== _startDate) {
      dispatch(
        setStartDate(
          moment.utc(moment(newValue.toLocaleString()), 'ddd MMM DD YYYY'),
        ),
      );
    }
  };

  const clearStartDate = () => {
    setValue('startDate', '');
    clearErrors('startDate');
    dispatch(unSetError());
    setFormDirty?.(true);
  };

  useEffect(() => {
    setValue('startDate', startDateValue);
    if (
      !!initialStartDate &&
      !!startDateValue &&
      !initialStartDate?.isSame(startDateValue)
    ) {
      setFormDirty?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue]);

  useEffect(() => {
    if (!!startDate) {
      const strtDate = moment(startDate.toLocaleString());
      setStartDateValue(strtDate);
    }
  }, [startDate]);

  useEffect(() => {
    setInitialStartDate(moment(startDate.toLocaleString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item>
      <Grid
        container
        item
        id="start-date-form"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Grid item>
          <Typography
            variant="subtitle1"
            color="system.midnightBlue"
            lineHeight="22px"
            textAlign={'left'}
          >
            Start Date
          </Typography>
        </Grid>
        <Grid item width="auto">
          <Button
            variant="text"
            size="xsmall"
            onClick={clearStartDate}
            sx={{ padding: '0px', height: 'auto', minWidth: 'auto' }}
          >
            <Typography
              variant="body1"
              color="system.skyBlue"
              lineHeight="16px"
            >
              Clear
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} zIndex={0}>
        <ReactHookFormDatePicker
          label="Any Start Date"
          name="startDate"
          disableFuture={false}
          format="MMM DD, YYYY"
          minDate={moment(new Date())}
          onChange={handleStartDate}
        />
      </Grid>
    </Grid>
  );
};

export default StartDateForm;
