import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { AMNSnackbar } from 'components/common/Alert/AMNSnackbar';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { AlertBarType } from 'enums/alertBarTypes';
import { ContractStatusIdStates } from 'enums/contracts';
import {
  IContract,
  IContractDetailPanelProps,
  IContractsPanelType,
} from 'interfaces/Contracts/Contract';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeAlert } from 'store/slices/alertbar/alertbarSlice';
import { contractSliceActions } from 'store/slices/contracts/contractSlice';
import { boolean, object, string } from 'yup';
import ContractPanelSkeleton from './ContractPanelSkeleton';
import useTrackPageView from 'utils/hooks/useTrackPageView';

const ContractDetailPanel = React.forwardRef<
  IContractsPanelType,
  IContractDetailPanelProps
>((props, ref) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isSigned, setIsSigned] = useState(false);
  const [fullLegalName, setFullLegalName] = useState('');
  const authorizedSignatureImgUrl = useAppSelector(
    state => state.contracts.authorizedSignatureImgUrl,
  );

  const travelerId: number = useAppSelector(
    state => state.userProfile.travelerId,
  );

  const currentContract: IContract = useAppSelector(
    state => state.contracts.currentContract,
  );

  const currentContractUrl: string = useAppSelector(
    state => state.contracts.contractUrl,
  );

  const contractError: boolean = useAppSelector(
    state =>
      state.alertbarComponent?.[AlertBarType.CONTRACT_DRAWER]?.isOpen || false,
  );

  const isContractSaveSuccess = useAppSelector(
    state => state.contracts.isContractSaveSuccess,
  );

  // Expose the handleHeaderIconClick method to the parent component
  React.useImperativeHandle(ref, () => ({
    handleHeaderIconClick: handleHeaderIconClick,
  }));

  useTrackPageView('ContractDetailForm');

  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setActionButtonDisabled,
    contracts,
    setDrawerStateAfterSubmit,
    placementId,
    facilityName,
  } = props;

  const [urlError, setUrlError] = useState<boolean>(false);

  const validation = object({
    signed: boolean(),
    fullLegalName: string(),
  });

  const methods = useForm<{
    signed: boolean;
    fullLegalName: string;
  }>({
    mode: 'all',
    defaultValues: {
      signed: false,
      fullLegalName: '',
    },
    resolver: yupResolver(validation),
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (setActionButtonDisabled) {
      setActionButtonDisabled(!(isSigned === true && fullLegalName !== ''));
    }
  }, [fullLegalName, isSigned, setActionButtonDisabled]);

  const resetForm = () => {
    setIsSigned(false);
    setFullLegalName('');
  };

  const submit = useCallback(() => {
    dispatch(contractSliceActions.signContract(fullLegalName));
  }, [dispatch, fullLegalName]);

  useEffect(() => {
    if (onSubmitProcess) {
      handleSubmit(submit)();
      resetForm();
      setOnSubmitProcess?.(false);
      setDrawerStateAfterSubmit();
    }
  }, [
    dispatch,
    handleSubmit,
    onSubmitProcess,
    setOnSubmitProcess,
    submit,
    contracts?.length,
    setDrawerStateAfterSubmit,
  ]);

  useEffect(() => {
    dispatch(contractSliceActions.getAuthorizedImgUrl());
  }, [
    currentContract?.agreement?.authorizedSignatorySignatureImageUrl,
    dispatch,
  ]);

  useEffect(() => {
    // commenting out for now, not working as expected and causing CORS error
    // fetch(currentContractUrl).catch(err => {
    //   setUrlError(true);
    // });
    if (currentContractUrl || typeof currentContractUrl === 'undefined') {
      setIsLoading(false);
    }
    return () => {
      if (currentContractUrl) {
        dispatch(contractSliceActions.resetContractUrl());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContractUrl]);

  const isMobileDevice = useMobileMediaQuery();

  useEffect(() => {
    return () => {
      dispatch(closeAlert({ alertbarRefId: AlertBarType.CONTRACT_DRAWER }));
      dispatch(contractSliceActions.setIsContractSaveSuccess(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (contractError) {
      setIsLoading(false);
    }
  }, [contractError]);

  const handleHeaderIconClick = () => {
    let payload = {
      placementId,
      contractIds: [currentContract?.contractId],
      facilityName: facilityName,
    };
    dispatch(contractSliceActions.getContractDocument(payload));
  };

  return (
    <>
      <AMNSnackbar
        onClose={() =>
          dispatch(contractSliceActions.setIsContractSaveSuccess(false))
        }
        autoHideDuration={3000}
        open={isContractSaveSuccess}
        variant="success"
        message="Contract(s) Downloaded"
        sx={{
          margin: '10px 0px',

          '& .MuiSnackbarContent-root': {
            position: 'absolute',
            maxWidth: 'auto',
            zIndex: 2500,
            width: '98%',
            left: '5px',
          },
        }}
      />
      {isLoading ? (
        <ContractPanelSkeleton />
      ) : contractError ||
        typeof currentContractUrl === 'undefined' ||
        urlError ? (
        <Grid container item xs={12}>
          <AMNSnackbar
            id="credential-submission-alert-bar"
            open={true}
            variant="error"
            message={'Something went wrong. Try reloading the page again.'}
            sx={{
              '& .MuiSnackbarContent-root': {
                position: 'absolute',
                left: '12px',
                top: '84px',
                right: '12px',
              },
            }}
          />
        </Grid>
      ) : (
        <>
          <Grid container mb={4}>
            <Grid
              visibility={isContractSaveSuccess ? 'hidden' : 'visible'}
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {currentContract?.statusId ===
                ContractStatusIdStates.READY_TO_SIGN &&
                currentContract?.readyForSigningDate && (
                  <Typography
                    variant="subtitle1"
                    color="system.grayText"
                    data-testid="contract-date"
                  >
                    {moment(currentContract?.readyForSigningDate).format(
                      'MMM DD, YYYY',
                    )}
                  </Typography>
                )}

              {currentContract?.statusId === ContractStatusIdStates.SIGNED &&
                currentContract?.signedDate && (
                  <Typography variant="subtitle1" color="system.grayText">
                    {moment(currentContract?.signedDate).format('MMM DD, YYYY')}
                  </Typography>
                )}

              <Box
                sx={{
                  backgroundColor: 'system.midnightBlue',
                  borderRadius: '25px',
                  px: 2,
                  py: 1,
                }}
              >
                <Typography
                  variant="body1"
                  color="system.white"
                  fontWeight="600"
                  textTransform="uppercase"
                  data-testid="contract-status"
                >
                  {currentContract?.status}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {currentContractUrl && (
            <iframe
              width="100%"
              height="450px"
              title="Contracts"
              src={currentContractUrl}
            ></iframe>
          )}

          <Divider
            sx={{
              my: 4,
              width: '100%',
              height: '1px',
            }}
          />
          {currentContract?.statusId ===
            ContractStatusIdStates.READY_TO_SIGN && (
            <>
              <FormProvider {...methods}>
                <Grid
                  container
                  id="contract-sign-attestation-checkbox"
                  onClick={() => {
                    setIsSigned(!isSigned);
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  <Grid item xs={1}>
                    <CheckBox checked={isSigned} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      color="system.coolGray"
                      lineHeight="20px"
                      sx={{ pt: '8px' }}
                    >
                      I verify that by placing my name below and checking this
                      box constitutes my digital signature for this document.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid mt={4} mb={4} item xs={12}>
                  <ReactHookFormTextField
                    label="Full Legal Name"
                    name="full-legal-name"
                    value={fullLegalName}
                    onChange={e => setFullLegalName(e?.target?.value)}
                  />
                </Grid>
              </FormProvider>
            </>
          )}

          {currentContract?.statusId === ContractStatusIdStates.SIGNED && (
            <>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    color="system.coolGray"
                    fontWeight="700"
                  >
                    AGREED AND ACCEPTED
                  </Typography>
                </Grid>
              </Grid>
              <Grid pt={2} container>
                <Grid item xs={isMobileDevice ? 3.75 : 3}>
                  <Typography variant="body1" color="system.coolGray">
                    Professional:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    color="system.coolGray"
                    data-testid="contract-signee"
                  >
                    {currentContract?.agreement?.signee}
                  </Typography>
                </Grid>
              </Grid>
              <Grid pt={1} container>
                <Grid item xs={isMobileDevice ? 3.75 : 3}>
                  <Typography variant="body1" color="system.coolGray">
                    Traveler ID:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    color="system.coolGray"
                    data-testid="contract-traveler-id"
                  >
                    {travelerId}
                  </Typography>
                </Grid>
              </Grid>
              <Grid pt={1} container>
                <Grid item xs={isMobileDevice ? 3.75 : 3}>
                  <Typography variant="body1" color="system.coolGray">
                    Signed:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="body1"
                    color="system.coolGray"
                    data-testid="contract-signed-date"
                  >
                    {moment(currentContract.signedDate).format(
                      'MMM DD, YYYY hh:mm A',
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Typography py={2} variant="body1" color="system.coolGray">
                AMN Services, Inc. By:{' '}
              </Typography>

              <img
                height="75px"
                alt="authorized signatory signature"
                src={authorizedSignatureImgUrl}
              />

              <Typography
                pt={2}
                pb={0.5}
                variant="body1"
                color="system.coolGray"
                data-testid="contract-signatory-name"
              >
                {currentContract?.agreement?.authorizedSignatoryName}
              </Typography>
              <Typography pt={0.5} variant="body1" color="system.coolGray">
                {currentContract?.agreement?.authorizedSignatoryTitle}
              </Typography>
              <Typography
                pt={0.5}
                pb={10}
                variant="body1"
                color="system.coolGray"
              >
                {currentContract?.agreement?.authorizedSignatorySubtitle}
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
});

export default ContractDetailPanel;
