import { lazyLoad } from 'utils/loadable';

export const JobSearchPage = lazyLoad(
  () => import('./JobSearchPage'),
  module => module.JobSearchPage,
);

export const JobShareDetail = lazyLoad(
  () => import('./JobShareDetail'),
  module => module.JobShareDetail,
);

export const ACSJobSearchPage = lazyLoad(
  () => import('./CognitiveJobSearch/CognitiveJobSearchPage'),
  module => module.CognitiveJobSearchPage,
);

export const ACSJobShareDetail = lazyLoad(
  () =>
    import('./CognitiveJobSearch/CognitiveJobDetails/CognitiveJobDetailsPage'),
  module => module.CognitiveJobDetailsPage,
);
