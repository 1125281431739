// TOAST MESSAGES
export const TOAST_MESSAGE = {
  // success
  FormSubmitted: 'Form Submitted',
  SkillsChecklistSubmitted: 'Skills Checklist Submitted',
  CredentialSubmitted: 'Credential Submitted',
  LicenseSubmitted: 'License Submitted',
  IdentificationSubmitted: 'Identification Submitted',
  DocumentUploaded: 'Your document has been successfully updated.',
  PendingApproval: 'Requirement moved to pending approval.',
  ActionItemRemoved: 'Action Item Removed',
  StrikeTravelAcknowledged: 'Strike Travel Acknowledged',
  RequestSent: 'Request Sent',
  ConfirmationSent: 'Confirmation Sent',
  EducationAdded: 'Education Added',
  PasswordChanged: 'Your password has been successfully changed',
  WorkHistoryAdded: 'Work History Added',
  AssignmentRemoved: 'Assignment Removed',
  SuccessfullySubmitted: 'Successfully submitted.',
  ChangesSaved: 'Your changes have been saved.',
  CommunityInterestSaved: 'Your Community Interests have been saved.',
  ReimbursementSubmitted: 'Reimbursement Submitted',
  TimesheetSaved: 'Timesheet saved',
  NotificationRemoved: 'Notification Removed',
  MarkedAllAsRead: 'Marked All as Read.',
  AcknowledgementSave: 'Acknowledgement Required to Save',
  SearchSaved: 'Search Saved.',
  SavedSearchUpdated: 'Saved Search Updated.',
  SavedSearchRemoved: 'Saved Search Removed.',
  ImageSizeIsTooSmall: 'The image is too small. Please try again.',

  // error
  SomethingWentWrong: 'Something went wrong',
  SomethingWentWrongTryAgain: 'Something went wrong, try again!',
  SomethingWentWrongTryReloading:
    'Something went wrong. Try reloading the page again.',
  NoAttachment: `This item doesn't have any attachments.`,
  PlacementOrCredentialMissing: 'Placement or Credential Id Missing',
  NoDocumentForDownload: 'No documents are selected for download.',
  LocationAlreadyAdded: 'Location already added',
  MaxCitiesReached: 'Max cities reached',
  ChecklistNotFound: 'Checklist not found',
  ErrorUpdatingTimesheet: 'Error occurred while updating Timesheet Approver',
  InvalidShareLink: 'Invalid Share Link',
  CobrowseCodeExpired: 'code expired',
};

// NOTES
export const NOTE_MESSAGE = {
  CredentialUpload: `PLEASE NOTE: It may take up to 48 hours for credentials to be processed and accessible in Credential Center.`,
  LicenseUpload: 'PLEASE NOTE: License will be added after verification.',
};

// NO DATA MESSAGE
export const NO_DATA_MESSAGE = {
  CredentialWallet:
    'You currently have no approved credentials in this Category.',
  NoSubmisionsFound: 'No submissions found.',
  DisabledForSecurity: 'Image is disabled due to security reasons.',
};

export const DESCRIPTION_MESSAGE = {
  LicenseVerification: `Select the associated Discipline from the dropdown list below. If
  you have multiple licenses, submit your license information one at a
  time.`,
  LicenseRnOrLpn: 'Enter your healthcare licensure number and associated state',
  LicenseUpload:
    'Please upload a current copy of your license. Ensure your first name, last name, license number, license state are clearly visible.',
  SaveSearchAlert:
    'Receive alerts when new jobs matching this saved search are available.',
};

export const QUESTIONNAIRE_MESSAGE = {
  LicenseSuspended:
    'Have you ever had your license or certification, in any state, investigated, suspended, or had disciplinary action taken against it?',
  UsCitizen:
    'Are you either a U.S. citizen or can you submit verification of your legal right to work in the U.S.? ',
};

export const VERIFICATION_CODE_SENT_MESSAGE =
  'Verification code has been re-sent to the above email address.';
