import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import AddIcon from 'assets/icons/Add/AddIcon';
import EditIcon from 'assets/icons/Edit/EditIcon';
import { TrashIcon } from 'assets/icons/Trash/Trash';
import ProfileLink from 'components/Profile/ProfileLink';
import CheckBox from 'components/common/CheckBox/CheckBox';
import { ReactHookFormDropdown } from 'components/common/Form/Dropdown/ReactHookFormDropdown';
import { UserTypeId } from 'enums/userTypeId';
import { guidGenerator } from 'helpers/guidGenerator';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { cloneDeep } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setAlliedDisciplineAndSpecialties } from 'store/slices/user/userPreferenceSlice';
import { theme } from 'styles/theme';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';
import {
  IAlliedDisciplineAndSpecialties,
  ISpecialty,
} from '../IAlliedDisciplineAndSpecialties';

export const AlliedExpertise = ({
  disciplineError,
  setDisciplineError,
  setFormDirty,
}: {
  disciplineError: boolean;
  setDisciplineError: Function;
  setFormDirty?: Function;
}) => {
  const dispatch = useAppDispatch();

  const userProfile: IUserProfile = useAppSelector(state => state.userProfile);
  const alliedDisciplineAndSpecialties: IAlliedDisciplineAndSpecialties[] =
    useAppSelector(
      state => state.userPreference.alliedDisciplineAndSpecialties,
    );
  const disciplineList: ISuccessDisciplineResponse = useAppSelector(
    state => state.lookup.disciplines,
  );

  const [showRequired, setShowRequired] = useState<boolean>(false);

  const handleAlliedSpecialtyOnChange = (event, index, spIndex) => {
    const checkedValue = event?.target.checked;
    const tempAlliedDisciplineAndSpecialties = cloneDeep(
      alliedDisciplineAndSpecialties,
    );
    tempAlliedDisciplineAndSpecialties[index].specialities[spIndex].checked =
      checkedValue;
    if (tempAlliedDisciplineAndSpecialties[index].anySpecialty) {
      tempAlliedDisciplineAndSpecialties[index].anySpecialty = false;
    }

    const allChecked = tempAlliedDisciplineAndSpecialties[
      index
    ].specialities.every(spec => spec.checked === true);
    if (allChecked) {
      tempAlliedDisciplineAndSpecialties[index].anySpecialty = true;
      tempAlliedDisciplineAndSpecialties[index].specialities.forEach(spec => {
        spec.checked = false;
      });
    }

    dispatch(
      setAlliedDisciplineAndSpecialties(tempAlliedDisciplineAndSpecialties),
    );
    setFormDirty?.(true);
  };

  const handleAlliedDisciplineChange = (e, index) => {
    setDisciplineError(false);
    const tempAlliedDisciplineAndSpecialties = cloneDeep(
      alliedDisciplineAndSpecialties,
    );

    const selectedDisciplineId = e.target.value as string;
    const selectedDiscipline = disciplineList.items.find(
      item => item.disciplineId.toString() === selectedDisciplineId,
    );

    let specialtyOptions: IDropdownOption[] = [];
    selectedDiscipline?.specialities?.forEach(specialty => {
      // Don't add existing specialities to specialty options
      if (userProfile.userDisciplineTypeId === UserTypeId.ALLIED) {
        specialtyOptions.push({
          key: specialty.specialtyId.toString(),
          value: specialty.specialtyDescription,
        });
      }
    });

    specialtyOptions = [];
    const specialities: ISpecialty[] = [];
    selectedDiscipline?.specialities?.forEach(specialty => {
      specialtyOptions.push({
        key: specialty.specialtyId.toString(),
        value: specialty.specialtyDescription,
      });
      specialities.push({
        specialtyDescription: specialty.specialtyDescription,
        specialtyId: specialty.specialtyId?.toString() || '',
        checked: false,
      });
    });

    tempAlliedDisciplineAndSpecialties[index].discipline =
      selectedDiscipline?.disciplineDescription as string;
    tempAlliedDisciplineAndSpecialties[index].disciplineId =
      selectedDiscipline?.disciplineId as string;
    tempAlliedDisciplineAndSpecialties[index]['specialtyOptions'] =
      specialtyOptions;
    tempAlliedDisciplineAndSpecialties[index]['specialities'] = specialities;
    tempAlliedDisciplineAndSpecialties[index]['anySpecialty'] = true;

    if (
      tempAlliedDisciplineAndSpecialties[index]['specialtyOptions'].length === 1
    ) {
      tempAlliedDisciplineAndSpecialties[index]['specialities'][0].checked =
        false;
    }

    dispatch(
      setAlliedDisciplineAndSpecialties(tempAlliedDisciplineAndSpecialties),
    );
    setFormDirty?.(true);
  };

  const handleAlliedAnySpecialtyOnChange = (e, index) => {
    const checkedValue = e?.target.checked;
    const tempAlliedDisciplineAndSpecialties = cloneDeep(
      alliedDisciplineAndSpecialties,
    );
    if (!!tempAlliedDisciplineAndSpecialties[index]) {
      if (!tempAlliedDisciplineAndSpecialties[index].checked) {
        tempAlliedDisciplineAndSpecialties[index].checked = true;
      }
      tempAlliedDisciplineAndSpecialties[index].specialities.forEach(
        specialty => {
          specialty.checked = false;
        },
      );
      tempAlliedDisciplineAndSpecialties[index].anySpecialty = checkedValue;
      dispatch(
        setAlliedDisciplineAndSpecialties(tempAlliedDisciplineAndSpecialties),
      );
      setFormDirty?.(true);
    }
  };

  const atleastOneSpecialtyChecked = (specialities: ISpecialty[]): boolean => {
    let oneChecked = false;
    for (let i = 0; i < specialities.length; ++i) {
      if (specialities[i].checked) {
        oneChecked = true;
        break;
      }
    }
    return oneChecked;
  };

  const noneChecked = (obj: IAlliedDisciplineAndSpecialties): boolean => {
    let noChecks = true;
    for (let i = 0; i < obj.specialities.length; ++i) {
      if (obj.specialities[i].checked) {
        noChecks = false;
        break;
      }
    }
    return noChecks && !obj.anySpecialty;
  };

  const getDisciplineOptions = (
    selectedDiscipline: string,
  ): IDropdownOption[] => {
    const discOptions = [] as IDropdownOption[];
    disciplineList.items?.forEach(discipline =>
      discOptions.push({
        key: discipline.disciplineId.toString(),
        value: discipline.disciplineDescription,
      }),
    );

    const disciplinesToBeFiltered = alliedDisciplineAndSpecialties
      .map(disc => disc.disciplineId.toString())
      .filter(
        mappedDisc => selectedDiscipline.toString() !== mappedDisc.toString(),
      );

    const disciplineOptions: IDropdownOption[] = discOptions.filter(
      item => !disciplinesToBeFiltered.includes(item.key.toString()),
    );

    return disciplineOptions;
  };

  const handleExpertiseOnChange = (event, index) => {
    const tempAlliedDisciplineAndSpecialties = cloneDeep(
      alliedDisciplineAndSpecialties,
    );
    tempAlliedDisciplineAndSpecialties[index].checked = event.target.checked;
    tempAlliedDisciplineAndSpecialties[index].anySpecialty =
      event?.target.checked;

    if (!event.target.checked) {
      tempAlliedDisciplineAndSpecialties[index].specialities.forEach(item => {
        item.checked = false;
      });
    }

    dispatch(
      setAlliedDisciplineAndSpecialties(tempAlliedDisciplineAndSpecialties),
    );
    setFormDirty?.(true);
  };

  const handleExpertiseEdit = (e, index) => {
    let data = cloneDeep(alliedDisciplineAndSpecialties);
    data[index]['edit'] = true;
    dispatch(setAlliedDisciplineAndSpecialties(data));
  };

  const removeExpertiseFormField = (
    _event: React.SyntheticEvent,
    index: number,
  ) => {
    if (userProfile.userDisciplineTypeId === UserTypeId.ALLIED) {
      const tempAlliedDisciplineAndSpecialties = [
        ...alliedDisciplineAndSpecialties,
      ];
      tempAlliedDisciplineAndSpecialties.splice(index, 1);
      dispatch(
        setAlliedDisciplineAndSpecialties(tempAlliedDisciplineAndSpecialties),
      );
    }
  };

  const addExpertiseFormField = () => {
    const alliedDiscAndSpecialties = [...alliedDisciplineAndSpecialties];
    alliedDiscAndSpecialties.push({
      disciplineId: '',
      discipline: '',
      specialtyOptions: [] as IDropdownOption[],
      specialities: [] as ISpecialty[],
      anySpecialty: false,
      edit: true,
      verified: false,
      checked: true,
    });
    dispatch(setAlliedDisciplineAndSpecialties(alliedDiscAndSpecialties));
  };

  useEffect(() => {
    setShowRequired(false);
    if (userProfile.userDisciplineTypeId === UserTypeId.ALLIED) {
      let allUnchecked = true;
      alliedDisciplineAndSpecialties.forEach(formField => {
        if (!noneChecked(formField)) {
          allUnchecked = false;
        }
      });
      setShowRequired(allUnchecked);
    }
  }, [alliedDisciplineAndSpecialties, userProfile.userDisciplineTypeId]);

  return (
    <Grid container item id="allied-expertise" display={'flex'}>
      {alliedDisciplineAndSpecialties.map((obj, index) => (
        <Fragment key={`allied_expertise_${index}`}>
          {(index === 0 || (index > 0 && obj.edit)) && (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item mt={index === 0 ? 0 : 3}>
                {index === 0 ? (
                  <Typography
                    variant="subtitle1"
                    color="system.midnightBlue"
                    lineHeight="22px"
                    textAlign="left"
                    sx={{ display: 'inline-block' }}
                  >
                    Expertise
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    color={theme.palette.system.grayText}
                    lineHeight="19px"
                  >{`Expertise ${index + 1}`}</Typography>
                )}
                {index === 0 && showRequired && (
                  <Typography
                    variant="body1"
                    color="system.red"
                    lineHeight="12px"
                    sx={{ display: 'inline-block', ml: '12px' }}
                  >
                    Required
                  </Typography>
                )}
              </Grid>
              <Grid item sx={{ cursor: 'pointer' }} mt={index === 0 ? 0 : 3}>
                {index > 0 && (
                  <Button
                    id="delete-button"
                    variant="text"
                    onClick={e => removeExpertiseFormField(e, index)}
                    sx={{
                      justifyContent: 'right',
                      padding: '0px',
                      height: 'auto',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="system.skyBlue"
                      lineHeight="12px"
                      sx={{
                        cursor: 'pointer',
                        textTransform: 'none',
                      }}
                    >
                      <TrashIcon
                        htmlColor={theme.palette.system.skyBlue}
                        style={{ paddingRight: '5px' }}
                      />
                      Delete
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          )}

          <Grid container item key={guidGenerator()}>
            {!obj?.edit ? (
              <>
                <Grid item xs={9} textAlign="left">
                  <CheckBox
                    id={`allied_expertise_checkbox${index}`}
                    checked={
                      obj.anySpecialty ||
                      atleastOneSpecialtyChecked(obj.specialities)
                    }
                    checklabel={`${obj.discipline}`}
                    onChange={event => handleExpertiseOnChange(event, index)}
                  />
                </Grid>

                {obj.verified ? (
                  <Grid
                    item
                    xs={3}
                    textAlign="right"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="system.coolGray"
                      lineHeight="14px"
                      sx={{ fontStyle: 'italic' }}
                    >
                      (Verified)
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={3}
                    textAlign="right"
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                    onClick={e => handleExpertiseEdit(e, index)}
                  >
                    <Link
                      component="button"
                      underline="hover"
                      variant="body1"
                      sx={{
                        color: theme.palette.system.skyBlue,
                        fontFamily: theme.typography.fontFamily,
                      }}
                    >
                      {EditIcon(theme.palette.system.skyBlue, '17', '17')}
                      <Typography
                        component={'span'}
                        sx={{ paddingLeft: '5px' }}
                      >
                        Edit
                      </Typography>
                    </Link>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12} mt={2}>
                <ReactHookFormDropdown
                  label="Discipline"
                  name={`allied_discipline_${index}`}
                  data={getDisciplineOptions(
                    alliedDisciplineAndSpecialties[index].disciplineId,
                  )}
                  selectedValue={
                    alliedDisciplineAndSpecialties[index].disciplineId
                  }
                  onChange={e => handleAlliedDisciplineChange(e, index)}
                />
                {disciplineError && (
                  <FormHelperText sx={{ color: 'system.red', ml: '14px' }}>
                    {'Required'}
                  </FormHelperText>
                )}
              </Grid>
            )}
          </Grid>

          {(!noneChecked(obj) || (noneChecked(obj) && obj?.edit)) && (
            <Grid
              container
              item
              key={`allied_expertise_anysettings_${index}`}
              sx={{ marginLeft: '29px' }}
            >
              <Grid item xs={12} mt={1}>
                <Typography
                  variant="body1"
                  color="system.grayText"
                  lineHeight="19px"
                  textAlign="left"
                >
                  {'Setting or Specialty'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CheckBox
                  id={guidGenerator()}
                  checked={obj.anySpecialty}
                  checklabel={'Any Setting or Specialty'}
                  onChange={event =>
                    handleAlliedAnySpecialtyOnChange(event, index)
                  }
                />
              </Grid>

              {obj.specialities.map((specialty, spIndex) => (
                <Grid item xs={12} key={`specialty-check-${spIndex}`}>
                  <CheckBox
                    id={guidGenerator()}
                    value={specialty.specialtyId}
                    checked={specialty.checked}
                    checklabel={`${specialty.specialtyDescription}`}
                    onChange={event =>
                      handleAlliedSpecialtyOnChange(event, index, spIndex)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Fragment>
      ))}
      <Grid container item mt={1}>
        <Grid item>
          <Box onClick={addExpertiseFormField} data-testid="add-expertise">
            <ProfileLink
              id="add-expertise"
              underline="hover"
              leftIcon={AddIcon(theme.palette.system.skyBlue, '17', '17')}
            >
              <Box role="button" aria-label="expertise-add-btn">
                Add Expertise
              </Box>
            </ProfileLink>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
