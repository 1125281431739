export enum PhoneNumberType {
  CURRENT = 1,
  PERMANENT = 2,
  PERMANENT_2 = 3,
  TEMPORARY = 4,
  PAGER = 5,
  CELLPHONE = 6,
  WORK = 7,
  INTERNATIONAL = 8,
  FAX = 9,
  EMERGENCY = 10,
}

export enum PersonalInformationNumberType {
  HOMEPHONE = 'homePhone',
  MOBILEPHONE = 'mobilePhone',
  FAX = 'fax',
}
