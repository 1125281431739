import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import CalendarIcon from 'assets/icons/Calendar/CalendarIcon';
import { IReactHookFormDatePickerProps } from 'interfaces/Props/IReactHookFormDatePickerProps';
import { theme } from 'styles/theme';
import { Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row-reverse',
  },
}));

export const ReactHookFormDatePicker = (
  props: IReactHookFormDatePickerProps,
) => {
  const [open, setOpen] = useState(false);
  const {
    name,
    label,
    value,
    onChange,
    disabled = false,
    disableFuture = false,
    disablePast = false,
    minDate,
    maxDate,
    format = 'MM/DD/YYYY',
    defaultAsToday = false,
    endAdormentIcon = undefined,
    defaultCalendarMonth,
  } = props;

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const focusRef = React.useRef({ value: false });
  /*
   * Rerenders on field context change
   */
  const [fieldValue] = watch([name]);

  const classes = useStyles();

  const onInputChange = useCallback(e => {
    const setValueAndMoveCursor = (value: string) => {
      if (value) {
        if (value.match(/.*\/$/g)) {
          e.target.setSelectionRange?.(value.length, value.length);
          e.target.focus?.();
        }
      }
    };

    /*
     * Backspace event
     */
    if (e?.keyCode === 8) {
      let { value = '' } = e?.target;
      /*
       * Prevents backspace from taking place
       */
      e?.preventDefault();

      let newValue: string;

      // Remove 2 characters for closest digit
      if (value.match(/.*\/$/g)) {
        newValue = value.substring(0, value.length - 2);
        setValueAndMoveCursor(newValue);
      } else {
        newValue = value.substring(0, value.length - 1);
        setValueAndMoveCursor(newValue);
      }

      e.target.value = newValue;

      return;
    }

    setTimeout(() => {
      setValueAndMoveCursor(e?.target?.value);
    }, 100);
  }, []);

  const renderInput = useCallback(
    params => {
      const inputValue =
        (focusRef?.current?.value === true
          ? params?.inputProps?.value
          : fieldValue?.format?.(format)) || '';

      /*
       * The inputValue can differ because the date typed does not have to
       * follow the disablePast, disableFuture props. The input is being
       * controlled and this will update the input value to reflect a valid date
       * after the input field blurs.
       */
      if (
        params &&
        params.inputProps &&
        params.inputProps.value &&
        inputValue !== params?.inputProps?.value
      ) {
        params?.inputProps?.onChange?.({
          target: { name, value: inputValue },
          currentTarget: { name, value: inputValue },
        });
      }

      return (
        <Grid position="relative">
          <TextField
            variant="filled"
            onClick={() => {
              setOpen(true);
            }}
            fullWidth
            {...params}
            onKeyDown={onInputChange}
            inputProps={{
              ...params?.inputProps,
              value: inputValue,
            }}
            InputLabelProps={
              !!params?.InputProps?.endAdornment
                ? {
                    style: { marginLeft: 36 },
                  }
                : {}
            }
            onFocusCapture={() => {
              focusRef.current.value = true;
            }}
            onBlurCapture={() => {
              focusRef.current.value = false;
              setOpen(false);
            }}
            error={!!get(errors, name)}
            helperText={get(errors, name)?.message}
          />
          {!!get(errors, name) && endAdormentIcon && (
            <Grid position="absolute" top="14px" right="14px">
              {endAdormentIcon}
            </Grid>
          )}
        </Grid>
      );
    },
    [fieldValue, format, onInputChange, errors, name, endAdormentIcon],
  );

  const debouncedCallback = _.debounce((newDate: moment.Moment) => {
    const { onChange: onChangeRegister } = register(name);

    onChangeRegister?.({
      target: {
        name,
        value: newDate,
      },
    });
    onChange?.(newDate);
  }, 500);

  const onChangeCallback = useCallback(
    newDate => {
      if (!newDate) {
        return;
      }

      /*
       * This will control when the input field is updated.
       * Will revert to the last valid date on blur.
       */
      let updateDate = true;

      if (disableFuture) {
        if (newDate?.isBefore?.(moment())) {
          updateDate = true;
        } else {
          updateDate = false;
        }
      }

      if (disablePast) {
        if (newDate?.isAfter?.(moment())) {
          updateDate = true;
        } else {
          updateDate = false;
        }
      }

      if (updateDate) {
        debouncedCallback(newDate);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableFuture, disablePast],
  );

  useEffect(() => {
    /**
     * Allows user to pass in values, but keeps them registered on the form.
     */
    if (value) {
      const valueMoment = moment(value);
      const regesteredValueMoment = moment(fieldValue);
      if (valueMoment.diff(regesteredValueMoment)) {
        onChangeCallback(valueMoment);
      }
    } else if (!fieldValue && defaultAsToday) {
      onChangeCallback(moment());
    }
  }, [value, fieldValue, onChangeCallback, defaultAsToday]);

  document.addEventListener('click', (e: any) => {
    let elementClass = e.target.className;
    if (
      elementClass &&
      elementClass !== '' &&
      typeof elementClass == 'string'
    ) {
      if (elementClass?.includes('MuiPickersDay-dayWithMargin')) {
        setOpen(false);
      }
    }
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        autoFocus={false}
        {...register(name)}
        components={{
          OpenPickerIcon: () =>
            CalendarIcon({ color: theme.palette.system.coolGray }),
        }}
        label={label}
        value={fieldValue || ''}
        disabled={disabled}
        disableFuture={disableFuture}
        InputProps={{
          classes: {
            root: classes.root,
          },
        }}
        minDate={minDate}
        maxDate={maxDate}
        disablePast={disablePast}
        onChange={onChangeCallback}
        onAccept={onChangeCallback}
        renderInput={renderInput}
        inputFormat={format}
        desktopModeMediaQuery={theme.breakpoints.up('xs')}
        defaultCalendarMonth={defaultCalendarMonth}
        PopperProps={{
          open: open,
          onBlur: () => setOpen(false),
          onFocus: () => setOpen(true),
          modifiers: [
            {
              name: 'eventListeners',
              options: { scroll: false },
            },
          ],
          sx: {
            position: 'fixed',
            '& .MuiPickersDay-today': {
              backgroundColor: '#efefef',
              border: 'none',
            },

            '& .MuiPickersDay-dayWithMargin:hover': {
              backgroundColor: 'rgba(102, 57, 183, .2)',
            },
            '& .MuiPickersDay-dayWithMargin': {
              color: '#333333',
            },
            '& .Mui-selected': {
              backgroundColor: '#6639b7',
              color: '#fff',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
