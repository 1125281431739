import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { IFrameDrawer } from 'components/common/Drawer/IFrameDrawer';
import React, { useCallback, useEffect, useMemo } from 'react';
import { RootState } from 'store/configureStore';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';

interface IWebTravelerApplication {
  open: boolean;
  toggleDrawer?: (e, open) => void;
}
export const WebTravelerApplication = (props: IWebTravelerApplication) => {
  const { open, toggleDrawer } = props;

  const dispatch = useAppDispatch();
  const travelerApplicationStatus = useAppSelector(
    (state: RootState) => state.userProfile.travelerApplicationStatus,
  );
  const refreshToken = useAppSelector(state => {
    return state.auth.loginResponse.refreshToken;
  });

  const webAppUrl = useMemo(() => {
    return `${process.env.REACT_APP_WEB_TRAVELER_APPLICATION}${travelerApplicationStatus?.appId}`;
  }, [travelerApplicationStatus?.appId]);

  const toggleContainer = useCallback(
    (e, open) => {
      toggleDrawer?.(e, open);
    },
    [toggleDrawer],
  );

  useEffect(() => {
    //This is formstack message event handler
    const formStackMessegeHandler = event => {
      if (
        event?.data?.action === 'confirm' ||
        event?.data?.action === 'close'
      ) {
        toggleContainer(null, false);
        dispatch(
          authenticationActions.refreshAccessTokenAction({
            refreshToken,
            correlationId: '',
            updateLoggedInUser: true,
          }),
        );
      }
    };
    window.addEventListener('message', formStackMessegeHandler);
    return () => {
      window.removeEventListener('message', formStackMessegeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DrawerPanel
        showFooter={false}
        open={open}
        contentName="Complete Profile"
        contentStyles={{ height: '100%', padding: '0px !important' }}
        innerContainerStyles={{
          width: '60vw !important',
        }}
        toggleDrawer={toggleContainer}
      >
        <IFrameDrawer url={webAppUrl} />
      </DrawerPanel>
    </>
  );
};
WebTravelerApplication.defaultProps = {
  open: false,
};
