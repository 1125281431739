import React from 'react';
import { createSvgIcon } from '@mui/material';

export const SubmissionsIcon = createSvgIcon(
  <svg
    id="Controls-icon-Document-Check"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2344"
      data-name="Rectangle 2344"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Group_2948" data-name="Group 2948" transform="translate(3 1.001)">
      <path
        id="Path_3844"
        data-name="Path 3844"
        d="M41.067,23.89a1.178,1.178,0,0,0,1.178-1.179V13.067A2.368,2.368,0,0,0,39.88,10.7H25.105a4.676,4.676,0,0,0-2.857,1.109l-4.9,4.477a4.224,4.224,0,0,0-1.26,2.858v25.19A2.368,2.368,0,0,0,18.455,46.7H39.88a2.368,2.368,0,0,0,2.365-2.367V35.582a1.178,1.178,0,1,0-2.355,0l-.009,8.761-21.435-.009V20.089h6.7a1.177,1.177,0,0,0,1.178-1.179V13.058l13.568.008v9.644A1.177,1.177,0,0,0,41.067,23.89ZM19.26,17.732l4.577-4.182a1.249,1.249,0,0,1,.13-.091v4.272Z"
        transform="translate(-16.09 -10.7)"
      />
      <path
        id="Path_3845"
        data-name="Path 3845"
        d="M35.789,36.69a1.172,1.172,0,0,1-.833-.345l-6.115-6.12a1.178,1.178,0,0,1,1.665-1.666l5.283,5.287L47.8,21.827a1.178,1.178,0,0,1,1.665,1.666L36.621,36.345A1.171,1.171,0,0,1,35.789,36.69Z"
        transform="translate(-16.809 -11.316)"
      />
    </g>
  </svg>,
  'Submissions',
);
