import ListIcon from '@mui/icons-material/List';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ArrowDownSvg } from 'assets/icons/Controls/ArrowDownSvg';
import filterIcon from 'assets/icons/Jobs/Controls-icon-Filter.svg';
import { ExpertiseFormField } from 'components/Jobs/JobSearch/JobPreference/IExpertiseFormField';
import { JobSearchLimit } from 'enums/jobSearchLimit';
import { JobSortType } from 'enums/jobSortType';
import { UserTypeId } from 'enums/userTypeId';
import { guidGenerator } from 'helpers/guidGenerator';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';
import { DebouncedFunc } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { shallowEqual } from 'react-redux';
import { trackEvent } from 'services/logging/appInsights';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setJobSearchFilter } from 'store/slices/jobs/jobsSlice';
import { theme } from 'styles/theme';
import { ILocationSearchResponse } from 'types/UserPreference';

export const AMNFilterContainer = ({
  classname = '',
  sort,
  handleSortChange,
  showMap,
  setShowMap,
  toggleDrawer,
  setShowJobPreferenceDrawer,
  locations,
  expertiseFormFields,
  getJobSearchLimit,
  jobSearchFilter,
  onFilterSubmit,
}: {
  classname?: string;
  sort: string;
  showMap: boolean;
  handleSortChange: (event) => void;
  setShowMap: () => void;
  toggleDrawer: Function;
  setShowJobPreferenceDrawer: Function;
  locations: ILocationSearchResponse[];
  expertiseFormFields: ExpertiseFormField[];
  getJobSearchLimit: () => JobSearchLimit;
  jobSearchFilter: IJobSearchRequestSlice;
  onFilterSubmit: DebouncedFunc<() => void>;
}) => {
  const selectRef = React.useRef();
  const [anchorEl, setAnchorEl] = useState();
  const [jobsAvailable, setJobsAvailable] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAnchorEl(selectRef.current);
  }, [selectRef]);

  const recordButtonPosition = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const alliedDisciplineAndSpecialties = useAppSelector(
    state => state.userPreference.alliedDisciplineAndSpecialties,
  );
  const userProfile: IUserProfile = useAppSelector(state => state.userProfile);
  const hasRecommended = useAppSelector(state => state.jobs.hasRecommended);
  const hasOnlyRecommended = useAppSelector(
    state => state.jobs.hasOnlyRecommended,
  );
  const jobResponse = useAppSelector(state => state.jobs, shallowEqual);

  const { promiseInProgress: jobsLoading } = usePromiseTracker({
    area: 'get-jobs',
    delay: 0,
  });

  useEffect(() => {
    if (jobResponse && jobResponse.items && jobResponse.items.length > 0) {
      setJobsAvailable(true);
    } else setJobsAvailable(false);
  }, [jobResponse]);

  const showFavouritesClick = e => {
    e.stopPropagation();

    dispatch(
      setJobSearchFilter({
        ...jobSearchFilter,
        limit: getJobSearchLimit(),
        sort: jobSearchFilter.sort,
        isFavorite: jobSearchFilter.isFavorite ? false : true,
      }),
    );
    onFilterSubmit();
    trackEvent('FavouritedJob_Clicked');
  };

  const getLocationFilterDisplay = useCallback(
    (location: ILocationSearchResponse) => {
      return location.searchText;
    },
    [],
  );

  const getExpertiseFilterDisplay = useCallback(expertiseItem => {
    let expertise = `${expertiseItem.disciplineValue} - ${expertiseItem.specialtyValue}`;
    return expertise;
  }, []);

  const clinicianExpertise = useMemo(() => {
    return userProfile.userDisciplineTypeId === UserTypeId.ALLIED
      ? alliedDisciplineAndSpecialties?.map((item, index) =>
          item.anySpecialty ? (
            <Typography
              key={`allied-discipline-${index}`}
              variant="body1"
              component="span"
            >
              {item.discipline}
              {index < alliedDisciplineAndSpecialties.length - 1 ? ', ' : ''}
            </Typography>
          ) : (
            <Fragment key={index}>
              {item.specialities.map(
                (specialty, idx) =>
                  specialty.checked && (
                    <Typography
                      key={`allied-specialty-${idx}`}
                      variant="body1"
                      component="span"
                    >
                      {`${item.discipline} - ${specialty.specialtyDescription}`}
                      {idx <
                      item.specialities.filter(sp => sp.checked).length - 1
                        ? ', '
                        : index < alliedDisciplineAndSpecialties.length - 1
                        ? ', '
                        : ''}
                    </Typography>
                  ),
              )}
            </Fragment>
          ),
        )
      : expertiseFormFields.map((item, indx) => (
          <Typography
            key={`nurse-expertise-${indx}`}
            variant="body1"
            component="span"
          >
            {getExpertiseFilterDisplay(item)}
            {indx < expertiseFormFields.length - 1 ? ', ' : ''}
          </Typography>
        ));
  }, [
    alliedDisciplineAndSpecialties,
    expertiseFormFields,
    getExpertiseFilterDisplay,
    userProfile.userDisciplineTypeId,
  ]);

  const handleMapViewClick = () => {
    setShowMap();
  };

  return (
    <Grid item container id="filter-container">
      <Grid container display="flex" flexDirection="row">
        <Box
          component="div"
          className={classname}
          sx={{ width: '100%', marginBottom: { xs: '0px', md: '6px' } }}
        >
          {/* Mobile View Filter Starts Here */}
          {!jobSearchFilter.isFavorite && (
            <Grid
              container
              item
              flexDirection="row"
              mb={2}
              xs={12}
              mt={showMap ? 4 : 0}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <Grid
                item
                xs={10}
                sx={{
                  backgroundColor: showMap
                    ? `${theme.palette.system.white}`
                    : `${theme.palette.system.inputBackground}`,
                  borderRadius: '4px',
                  border: showMap
                    ? `1px solid ${theme.palette.system.border}`
                    : 'none',
                  padding: showMap ? '6px' : '0px',
                }}
              >
                <Fragment>
                  <Typography variant="body1" component="span" fontWeight="700">
                    Filters:{' '}
                  </Typography>
                  {locations.map((item, index) => (
                    <Typography
                      key={guidGenerator()}
                      variant="body1"
                      component="span"
                    >
                      {getLocationFilterDisplay(item)}
                      {expertiseFormFields.length ? ', ' : ''}
                    </Typography>
                  ))}

                  {clinicianExpertise}
                </Fragment>
              </Grid>

              <Grid item xs={2} display="flex" justifyContent="flex-end">
                <IconButton
                  id="Show Filter Button"
                  aria-label="Show Filter"
                  onClick={e => {
                    setShowJobPreferenceDrawer(true);
                    toggleDrawer(e, true);
                  }}
                  size="small"
                  sx={{
                    background: theme.palette.system.white,
                    height: '40px',
                    width: '40px',
                    borderRadius: '4px',
                    border: `1px solid ${theme.palette.system.border}`,
                  }}
                >
                  <img
                    height="24px"
                    width="24px"
                    src={filterIcon}
                    alt="Show Filter"
                  />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {/* Mobile View Filter End Here */}

          <Grid
            id="this-is-the-grid"
            container
            item
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="nowrap"
          >
            {/* sort dropdown */}
            <Grid item display="flex" maxWidth="165px">
              <FormControl
                sx={{
                  maxWidth: '165px',
                  '& .MuiOutlinedInput-root': {
                    minHeight: '40px',
                    maxHeight: '40px',
                    background: theme.palette.system.white,
                    paddingLeft: '10px',

                    '& svg': {
                      color: theme.palette.system.midnightBlue,
                      width: '13px',
                    },

                    '& .Mui-disabled svg': {
                      color: theme.palette.system.gray,
                    },

                    '& .MuiInputAdornment-root': {
                      padding: '0px',
                      margin: '0px',
                    },

                    '& .MuiOutlinedInput-input': {
                      fontFamily: 'Open Sans',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '14px',
                      lineHeight: '18px',
                      color: theme.palette.system.midnightBlue,
                      marginTop: '2px',
                      paddingLeft: '2px',
                    },
                  },
                }}
                onClick={recordButtonPosition}
                disabled={hasOnlyRecommended || jobsLoading || !jobsAvailable}
              >
                <Select
                  labelId="pay-select"
                  id="pay-selector"
                  value={sort}
                  ref={selectRef}
                  disabled={
                    jobSearchFilter.isFavorite ||
                    hasOnlyRecommended ||
                    jobsLoading ||
                    !jobsAvailable
                  }
                  onChange={handleSortChange}
                  startAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        color:
                          jobSearchFilter.isFavorite ||
                          hasOnlyRecommended ||
                          jobsLoading ||
                          !jobsAvailable
                            ? theme.palette.system.gray
                            : theme.palette.system.midnightBlue,
                      }}
                    >
                      Sort:{' '}
                    </InputAdornment>
                  }
                  IconComponent={ArrowDownSvg}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    anchorEl: anchorEl,
                    PaperProps: {
                      sx: {
                        width: '165px',
                        marginTop: '6px',
                      },
                    },
                  }}
                  sx={{
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'system.grayBorder',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'system.grayBorder',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'system.grayBorder',
                    },
                  }}
                >
                  <MenuItem value={JobSortType.WeeklyPay}>Weekly Pay</MenuItem>
                  <MenuItem value={JobSortType.MostRecentPost}>
                    Most Recent
                  </MenuItem>
                  <MenuItem value={JobSortType.StartDate}>Start Date</MenuItem>
                  {hasRecommended && (
                    <MenuItem
                      value={JobSortType.Recommended}
                      sx={{ overflow: 'hidden' }}
                    >
                      Recommended
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            {/* map view button */}
            <Grid item display="flex">
              <Button
                onClick={handleMapViewClick}
                variant="contained"
                size="small"
                color="inherit"
                disabled={jobSearchFilter.isFavorite}
                sx={{
                  minHeight: '40px',
                  maxHeight: '40px',
                  background: theme.palette.system.white,
                  borderRadius: '4px',
                  border: `1px solid ${theme.palette.system.border}`,
                  fontFamily: 'Open Sans',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '16px',
                  boxShadow: 'none',
                  color: theme.palette.system.midnightBlue,
                  textTransform: 'inherit',
                  minWidth: '100px',
                  '& .MuiButton-iconSizeSmall': {
                    color: theme.palette.system.skyBlue,
                    marginLeft: '0px',
                  },
                  '&.Mui-disabled': {
                    color: `${theme.palette.system.gray} !important`,
                  },
                }}
                startIcon={
                  !showMap ? (
                    <MapOutlinedIcon
                      htmlColor={
                        jobSearchFilter.isFavorite
                          ? theme.palette.system.gray
                          : ''
                      }
                    />
                  ) : (
                    <ListIcon
                      htmlColor={
                        jobSearchFilter.isFavorite
                          ? theme.palette.system.gray
                          : ''
                      }
                    />
                  )
                }
              >
                {!showMap ? 'Map View' : 'List View'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
