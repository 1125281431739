import React, { FC } from 'react';
import { IProfileLinkProps } from 'interfaces/Props/IProfileLinkProps';
import { Grid } from '@mui/material';
import { theme } from 'styles/theme';
import { AppLink } from 'components/common/Link/Link';

/* TODO  this entire ProfileLink should probably be called something less specific to Profile.  Make it a common link component */
export const ProfileLink: FC<IProfileLinkProps> = ({
  id,
  onClick,
  href,
  children,
  underline,
  rightIcon,
  leftIcon,
  justifyContent = 'center',
  fontSize = '14px' /* TODO  Temporary fix to try and keep fontSizes uniform on profile page for demo.  Fix when we convert it to a common link component. */,
  sx,
}) => (
  <>
    <Grid
      container
      item
      id={id + '-link'}
      sx={{ display: 'flex', flexDirection: 'row', ...sx }}
      onClick={onClick}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent,
          alignItems: 'center',
          cursor: 'pointer',
          ...sx?.['linkContainerStyle'],
        }}
      >
        {leftIcon ?? null}
        <AppLink
          component="button"
          href={href}
          underline={underline}
          linkStyles={{
            marginLeft: leftIcon ? theme.spacing(1) : theme.spacing(0),
            marginRight: rightIcon ? theme.spacing(1) : theme.spacing(0),
            fontWeight: 400,
            lineHeight: '20px',
            fontSize: fontSize,
            ...sx?.['linkStyle'],
          }}
          id="profile-link"
        >
          {children}
        </AppLink>
        {rightIcon ?? null}
      </Grid>
    </Grid>
  </>
);

export default ProfileLink;
