import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toolTipsTourActions } from 'store/slices/toolTipsTour/toolTipsTourSlice';
import { RootState } from 'store/configureStore';
import {
  CookieService,
  toolTipApplyJobCookie,
} from 'services/cookie/cookieService';

const userProfileToolTipsSelector = (state: RootState) =>
  state.userProfile?.toolTips || [];

function* showToolTip() {
  try {
    // When we show the tooltip, we want to clear the cookie because the absence of the cookie determines that we show the tooltip.
    yield call(() => CookieService.clearToolTipCookie());
  } catch (error: any) {}
}

function* hideToolTip() {
  try {
    // When we hide the tooltip, we want to set the cookie because the presence of the cookie determines that we hide the tooltip.
    // The cookie is set to expire after 6 months.
    yield call(() => CookieService.setToolTipCookie(toolTipApplyJobCookie));
  } catch (error: any) {}
}

function* restoreStateFromCookie() {
  try {
    const hideToolTip = document.cookie.includes(`${toolTipApplyJobCookie}=`);

    const userProfileToolTips = yield select(userProfileToolTipsSelector);

    const applyForJobToolTip = userProfileToolTips.find(
      toolTip => toolTip.toolTipsTypeId === 100,
    );

    if (!hideToolTip && applyForJobToolTip && applyForJobToolTip.isEnabled) {
      yield put(toolTipsTourActions.showToolTip());
    }
  } catch (error: any) {}
}

export function* toolTipsTourSaga() {
  yield all([
    takeLatest(toolTipsTourActions.showToolTip, showToolTip),
    takeLatest(toolTipsTourActions.hideToolTip, hideToolTip),
    takeLatest(
      toolTipsTourActions.restoreStateFromCookie,
      restoreStateFromCookie,
    ),
  ]);
}
