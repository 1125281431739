import {
  IFormRequest,
  IResourcesRequest,
  IResourceSubmissionRequest,
} from 'interfaces/Resources/IResources';
import { http } from 'services/BaseApi';

export async function fetchResources(resourcesRequest: IResourcesRequest) {
  try {
    let resourcesQuery = `resource-center/${resourcesRequest.userId}?limit=1000`;

    if (resourcesRequest.offset)
      resourcesQuery += `&offset=${resourcesRequest.offset}`;

    return await http.get<string>(resourcesQuery);
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function fetchFormSubmissions(
  resourcesRequest: IResourcesRequest,
) {
  let resourcesQuery = `forms/${resourcesRequest.userId}`;
  if (resourcesRequest.limit)
    resourcesQuery += `?limit=${resourcesRequest.limit}`;

  if (resourcesRequest.offset)
    resourcesQuery += `&offset=${resourcesRequest.offset}`;

  return await http.get<string>(resourcesQuery);
}

export async function fetchLatestFormSubmission(
  resourcesRequest: IResourceSubmissionRequest,
) {
  let resourcesQuery = `forms/${resourcesRequest.userId}/submission/${resourcesRequest.urlKey}`;

  return await http.get<string>(resourcesQuery);
}

export async function fetchFormById(resourcesRequest: Partial<IFormRequest>) {
  let resourcesQuery = `forms/${resourcesRequest.userId}/${resourcesRequest.id}`;

  return await http.get<string>(resourcesQuery);
}

export async function fetchFormByUrlPath(
  resourcesRequest: IResourceSubmissionRequest,
) {
  let resourcesQuery = `forms/${resourcesRequest.userId}/url/${resourcesRequest.urlKey}`;
  return await http.get<string>(resourcesQuery);
}
