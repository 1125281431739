import React from 'react';
import { createSvgIcon } from '@mui/material';

export const TextIcon = createSvgIcon(
  <svg
    id="Controls-icon-Text"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2267"
      data-name="Rectangle 2267"
      fill="none"
      opacity="0"
    />
    <g id="Group_2864" data-name="Group 2864" transform="translate(1 3.001)">
      <path
        id="Path_3730"
        data-name="Path 3730"
        d="M24.925,25.7A1.606,1.606,0,1,0,26.547,27.3,1.614,1.614,0,0,0,24.925,25.7Z"
        transform="translate(-11.705 -12.884)"
      />
      <path
        id="Path_3731"
        data-name="Path 3731"
        d="M29.925,25.7A1.606,1.606,0,1,0,31.547,27.3,1.614,1.614,0,0,0,29.925,25.7Z"
        transform="translate(-11.925 -12.884)"
      />
      <path
        id="Path_3732"
        data-name="Path 3732"
        d="M34.925,25.7A1.606,1.606,0,1,0,36.547,27.3,1.614,1.614,0,0,0,34.925,25.7Z"
        transform="translate(-12.146 -12.884)"
      />
      <path
        id="Path_3733"
        data-name="Path 3733"
        d="M29.17,12.161c-9.925,0-18,6.335-18,14.123,0,4.347,2.576,8.478,6.92,11.141a38.58,38.58,0,0,1-1.025,5.264,1.176,1.176,0,0,0,.473,1.258,1.2,1.2,0,0,0,1.355.009c.475-.318,4.239-2.848,5.781-3.992a22.821,22.821,0,0,0,4.495.443c9.925,0,18-6.335,18-14.124S39.1,12.161,29.17,12.161Zm0,25.88a20.441,20.441,0,0,1-4.52-.5,1.2,1.2,0,0,0-1.032.244c-.46.379-2.043,1.479-3.544,2.5.814-3.987.257-4.305-.325-4.638-3.934-2.254-6.19-5.667-6.19-9.366,0-6.482,7-11.756,15.61-11.756S44.78,19.8,44.78,26.284,37.778,38.041,29.17,38.041Z"
        transform="translate(-11.17 -12.161)"
      />
    </g>
  </svg>,
  'TextIcon',
);
