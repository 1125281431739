import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState } from './filterState';

const initialState: FilterState = {
  showFilter: false,
};

export const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setDisplayFilter(state, action: PayloadAction<boolean>) {
      state.showFilter = action.payload;
    },
    resetFilter() {
      return {
        ...initialState,
      };
    },
  },
});

export const { setDisplayFilter, resetFilter } = filterSlice.actions;

export default filterSlice.reducer;
