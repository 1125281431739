/*To be removed on actual integration with api*/
export const mockArbitrationActionItem = {
  credentialTypeId: 0,
  credentialType: 'None',
  taskCenterCategoryTypeId: 6,
  taskCenterCategoryType: 'Contract',
  actionItem: {
    id: '00112233ababab',
    title: 'Arbitration Agreement - UT Health Tyler',
    stateTypeId: 1,
    stateType: 'Open',
    actionItemTypeId: 60,
    actionItemType: 'Arbitration Agreement - UT Health Tyler',
    typeId: 3,
    type: 'mobileDeepLink',
    links: [
      {
        title: 'Arbitration Agreement - UT Health Tyler',
        url: 'some url',
        typeId: 3,
        type: 'mobileDeepLink',
      },
    ],
    placementId: null,
    expirationDate: null,
    facilityName: null,
    taskCenterCategoryTypeId: 6,
    taskCenterCategoryType: 'Contract',
  },
  actionItemTypeId: 60,
  actionItemType: 'Arbitration Agreement - UT Health Tyler',
};

export const currentArbitrationMockData = {
  agreement: {
    signee: 'Jane Doe',
    authorizedSignatoryName: 'Robin Johnson',
    authorizedSignatoryTitle: 'Group President',
    authorizedSignatorySubtitle: 'Nursing and Allied Solutions',
  },
  signedDate: '2024-04-24T22:42:40.4061352Z',
  readyForSigningDate: '2024-04-20T22:42:40.4061352Z',
  ipAddress: '123.1.123.1',
  statusId: 200,
  status: 'Signed',
};
