import { createTheme } from '@mui/material';
import { theme } from 'styles/theme';

const HeaderContainer = {
  display: 'flex',
  justifyContent: 'end',
  backgroundColor: theme.palette.system.white,
};

const StylesHeaderCommonProps = {
  height: '60px',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.system.navBorder,
};

const StylesMobileTabletHeader = {
  ...StylesHeaderCommonProps,
  width: '100%',
};

const StylesDesktopHeader = {
  ...StylesHeaderCommonProps,
  width: '100%',
  zIndex: '6000',
};

const commonFontProperties = {
  fontSize: 24,
  color: theme.palette.system.grayText,
  fontFamily: 'Open Sans, sans-serif',
};

let siteHeaderTheme = createTheme({
  typography: {
    body1: {
      ...commonFontProperties,
      lineHeight: '24px',
    },
    body2: {
      ...commonFontProperties,
    },
  },
});

const StylesAppBarHeader = {
  backgroundColor: theme.palette.system.white,
  height: '72px',
  boxShadow: 'none',
  borderBottom: '1px solid',
  borderBottomColor: theme.palette.system.navBorder,
  display: 'flex',
  justifyContent: 'center',
};

const StylesAppBarHeaderInner = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
};

const StylesAppBarHeaderMobileInner = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
};

const StylesCommonHeaderWrapperWidth = {
  width: 'auto',
};

const StylesHomePageIconWrapper = {
  ...StylesCommonHeaderWrapperWidth,
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '26px',
};

const StylesHomePageIcon = {
  cursor: 'pointer',
  width: 'clamp(169px, 40vw ,200px)',
  height: '32px',
};

const StylesHomePageIconMobileSize = {
  width: '169px',
  height: '27px',
};

const StylesHeaderLocationText = { textAlign: 'center' };

const StylesHeaderNotificationsProfileWrapper = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  justifyContent: 'end',
  width: '226px',
  paddingRight: '27px',
};

const StylesNotificationIcon = {
  transform: 'scale(0.8)',
};

const StylesProfileIcon = {
  borderRadius: '50%',
  width: '32px',
  height: '31px',
  cursor: 'pointer',
};

const StylesProfileMenuOpenBorder = {
  border: '2px solid',
  borderRadius: '50%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const StylesProfileMenuOpenBorderMobile = {
  ...StylesProfileMenuOpenBorder,
  position: 'absolute' as 'absolute',
  top: 16,
  right: 25,
};

export {
  HeaderContainer,
  StylesHeaderCommonProps,
  StylesMobileTabletHeader,
  StylesDesktopHeader,
  StylesAppBarHeader,
  StylesAppBarHeaderInner,
  StylesHomePageIconWrapper,
  StylesHomePageIcon,
  StylesCommonHeaderWrapperWidth,
  StylesHeaderLocationText,
  StylesHeaderNotificationsProfileWrapper,
  StylesNotificationIcon,
  StylesProfileIcon,
  StylesAppBarHeaderMobileInner,
  StylesHomePageIconMobileSize,
  StylesProfileMenuOpenBorder,
  StylesProfileMenuOpenBorderMobile,
  siteHeaderTheme as theme,
};
