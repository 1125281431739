import React from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ReactComponent as Splash_Tasks } from 'assets/icons/SplashImages/jumping_woman_icon.svg';

export const OpenTasksSplash = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      sx={{
        height: { xs: '640px', sm: '480px' },
        padding: '48px',
      }}
    >
      <Grid
        item
        xs={12}
        sm={7}
        mt="auto"
        mb="auto"
        sx={{
          display: 'flex',
          flexFlow: 'column',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h2" color="system.midnightBlue" fontSize="20px">
          <i>You are all caught up!</i>
        </Typography>
        <Divider
          sx={{
            backgroundColor: 'system.skyBlue',
            borderWidth: '2px',
            margin: '12px 0 24px 0',
            width: '100px',
          }}
        />
        <Typography variant="subtitle1" color="system.grayText">
          No tasks to complete at this time.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        display="flex"
        justifyContent="center"
        mt={{ xs: 6, sm: 0 }}
      >
        <Splash_Tasks width="220px" height="225px" />
      </Grid>
    </Grid>
  );
};
