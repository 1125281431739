import { Grid, Typography } from '@mui/material';
import CheckBox from 'components/common/CheckBox/CheckBox';
import _ from 'lodash';
import React from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setFacilityTypes } from 'store/slices/user/userPreferenceSlice';
import {
  areAllOptionsChecked,
  areAllOptionsUnchecked,
  handleAllChecked,
  handleAnyChecked,
} from '../utils';

const FacilityTypeForm = ({ setFormDirty }: { setFormDirty?: Function }) => {
  const dispatch = useAppDispatch();

  const facilityTypes = useAppSelector(
    state => state.userPreference.facilityTypes,
  );

  const handleFacilityTypeChange = (e, index) => {
    setFormDirty?.(true);
    const anyIdx = facilityTypes.findIndex(option => option.typeId === 0);
    let tempFacilityTypes = _.cloneDeep(facilityTypes);

    if (tempFacilityTypes[anyIdx].isActive && index === anyIdx) return;

    tempFacilityTypes[index].isActive = !tempFacilityTypes[index].isActive;

    if (
      tempFacilityTypes[index].typeId === 0 &&
      tempFacilityTypes[index].isActive
    ) {
      tempFacilityTypes = handleAnyChecked(tempFacilityTypes);
    } else {
      tempFacilityTypes[0].isActive = false;
    }

    if (areAllOptionsChecked(tempFacilityTypes)) {
      tempFacilityTypes = handleAllChecked(tempFacilityTypes);
    } else if (
      areAllOptionsUnchecked(tempFacilityTypes) &&
      !tempFacilityTypes[0].isActive
    ) {
      return;
    }

    dispatch(setFacilityTypes(tempFacilityTypes));
  };

  return (
    <Grid
      container
      item
      id="facility-type-form"
      display="flex"
      flexDirection="column"
    >
      <Grid item>
        <Typography
          variant="subtitle1"
          color="system.midnightBlue"
          lineHeight="22px"
          textAlign={'left'}
        >
          Facility Type
        </Typography>
      </Grid>
      <Grid item display="flex" flexDirection="column">
        {facilityTypes.map((facilityType, index) => (
          <CheckBox
            key={index}
            id={`facilityType_type_${index}`}
            checked={facilityType.isActive}
            onChange={e => handleFacilityTypeChange(e, index)}
            checklabel={facilityType.type}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default FacilityTypeForm;
