import React from 'react';
import { theme } from 'styles/theme';

export function HelpIcon({
  width = '18px',
  height = '18px',
  color = theme.palette.system.skyBlue,
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      id="Controls-icon-Help"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2352"
        data-name="Rectangle 2352"
        width="38"
        height="38"
        fill={color}
        opacity="0"
      />
      <g id="Group_8903" data-name="Group 8903" transform="translate(1 1)">
        <path
          id="Ellipse_1456"
          data-name="Ellipse 1456"
          d="M17-1A18,18,0,0,1,29.728,29.728,18,18,0,0,1,4.272,4.272,17.882,17.882,0,0,1,17-1Zm0,32.727A14.727,14.727,0,1,0,2.273,17,14.744,14.744,0,0,0,17,31.727Z"
          transform="translate(1 1)"
          fill={color}
        />
        <path
          id="Path_6622"
          data-name="Path 6622"
          d="M14.357,19.095a1.637,1.637,0,0,1-.517-3.189c.98-.332,3.79-1.665,3.79-3.357a3.273,3.273,0,0,0-6.36-1.093,1.636,1.636,0,1,1-3.087-1.086A6.545,6.545,0,0,1,20.9,12.551c0,1.33-.552,3.287-3.183,5.041a13.277,13.277,0,0,1-2.845,1.418A1.636,1.636,0,0,1,14.357,19.095Z"
          transform="translate(3.512 2.178)"
          fill={color}
        />
        <path
          id="Line_210"
          data-name="Line 210"
          d="M.653,2.273H.636A1.636,1.636,0,0,1,.636-1H.653a1.636,1.636,0,1,1,0,3.273Z"
          transform="translate(17.363 25.545)"
          fill={color}
        />
      </g>
    </svg>
  );
}
