import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISupportCenterState } from 'interfaces/SupportCenter/ISupportCenterState';
import { ITicketCloseRequest } from 'interfaces/SupportCenter/ITicketCloseRequest';
import { ITicketGetRequest } from 'interfaces/SupportCenter/ITicketGetRequest';
import { ITicketSubmitRequest } from 'interfaces/SupportCenter/ITicketSubmitRequest';
import { RootState } from 'store/configureStore';

const initialState: ISupportCenterState = {
  existingTicketsCount: 0,
  existingTickets: {
    items: [],
    paging: {
      limit: '0',
      offset: '0',
      total: '0',
    },
  },
  submitStatus: false,
  closeStatus: false,
  assignments: [],
};
const getExistingTickets = createAction<ITicketGetRequest>(
  'GET_EXISTING_TICKETS',
);

export const postSubmitSupportTicket = createAction(
  'POST_SUBMIT_SUPPORT_TICKET',
  function prepare(submitRequest: ITicketSubmitRequest) {
    return {
      payload: {
        ...submitRequest,
      },
    };
  },
);

export const patchCloseOrResolveTicket = createAction(
  'PATCH_CLOSE_OR_RESOLVE_TICKET',
  function prepare(cancelRequest: ITicketCloseRequest) {
    return {
      payload: {
        cancelRequest,
      },
    };
  },
);

const getAssignmentByStatus = createAction<{ status: string }>(
  'GET_ASSIGNMENT_BY_STATUS',
);

const supportCenterSlice = createSlice({
  name: 'supportCenterSlice',
  initialState,
  reducers: {
    getExistingTicketsCount() {},
    setExistingTicketsCount(state, action: PayloadAction<number>) {
      state.existingTicketsCount = action.payload;
    },
    setExistingTickets(state, action) {
      state.existingTickets = action.payload;
    },
    setSubmitTicketStatus(state, action) {
      state.submitStatus = action.payload;
    },
    setCloseOrResolveTicketStatus(state, action) {
      state.closeStatus = action.payload;
    },
    resetCloseOrResolveTicketStatus(state) {
      state.closeStatus = false;
    },
    setAssignments(state, action) {
      state.assignments = action.payload;
    },
  },
});

export const selectExistingTicketsCount = (state: RootState) =>
  state.supportCenter.existingTicketsCount;

export const supportCenterActions = {
  getExistingTickets,
  getAssignmentByStatus,
  postSubmitSupportTicket,
  patchCloseOrResolveTicket,
  ...supportCenterSlice.actions,
};

export default supportCenterSlice.reducer;
