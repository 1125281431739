import { ILocationSearch, ILocationSearchResponse } from 'types/UserPreference';

export const locationResponseToLocation = (
  locationResponse: ILocationSearchResponse,
): ILocationSearch => {
  return {
    searchText: locationResponse.searchText,
    cityInfo: locationResponse.cityInfo
      ? {
          cityId: locationResponse.cityInfo?.cityId,
          city: locationResponse.cityInfo.city,
        }
      : null,
    stateInfo: locationResponse.stateInfo
      ? {
          stateId: locationResponse.stateInfo?.stateId,
          stateDescription: locationResponse.stateInfo?.stateName,
        }
      : null,
    regionInfo: locationResponse.regionInfo
      ? {
          regionId: locationResponse.regionInfo?.regionId,
          region: locationResponse.regionInfo.region,
        }
      : null,
  };
};
