import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { PlaystoreIcon } from 'assets/icons/Playstore/PlaystoreIcon';
import background from 'assets/images/bkgdimage.png';
import heroImage from 'assets/images/HeroImage/HeroImage1@2x.png';
import React from 'react';
import { theme } from 'styles/theme';
import { loginPageStyle } from './LoginWrapper.styles';
import { ApplestoreIcon } from 'assets/icons/Applestore/ApplestoreIcon';

export const LoginWrapper = ({ children }: any) => {
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));

  const loginStyle = loginPageStyle();

  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems={{ xs: 'flex-start', md: 'center' }}
      className={loginStyle.root}
      sx={{
        backgroundImage: `url(${background})}`,
        backgroundSize: { xs: 'contain', md: 'cover' },
        backgroundPosition: { xs: 'right 50% bottom 0%', md: 'bottom' },
        height: { xs: 'unset', md: '100%' },
        maxHeight: { xs: 'unset', md: 'inherit' },
      }}
    >
      <Grid
        container
        item
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
        width="auto"
        sx={{
          flexWrap: 'nowrap',
        }}
        pt={{ xs: 4, md: 2 }}
        gap={{ xs: '24px', md: '90px', lg: '100px' }}
      >
        <Grid container item md={4} display="flex" justifyContent="center">
          {children}
        </Grid>
        <Grid
          container
          item
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          {matchesMd && (
            <Grid
              container
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              mt="10px"
              mb="10px"
              width="300px"
            >
              <ApplestoreIcon />
              <PlaystoreIcon />
            </Grid>
          )}

          <Grid item sx={{ padding: '5px 0px' }}>
            <Typography
              sx={{
                fontSize: {
                  lg: '30px',
                  md: '24px',
                  xs: '20px',
                },
              }}
              lineHeight={{ xs: '24px', sm: '33px', lg: '35px' }}
              color={theme.palette.system.midnightBlue}
              fontWeight={400}
              textAlign="center"
              mb={1}
              variant="h1"
            >
              Experience AMN Passport: <br />
              The Leading Healthcare Career App
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: {
                  lg: '20px',
                  xs: '16px',
                },
              }}
              lineHeight="24px"
              color={theme.palette.system.grayText}
              fontWeight={400}
              mb={2}
              textAlign="center"
              variant="h2"
            >
              Customize Your Healthcare Career: Explore Travel, Strike, Per
              Diem, and Permanent Opportunities
            </Typography>
          </Grid>
          <Grid item>
            <Box
              component="img"
              alt="amn-marketing-display"
              src={heroImage}
              sx={{
                maxWidth: { xs: '290px', md: '400px', lg: '616px' },
              }}
            />
          </Grid>
          {!matchesMd && (
            <Grid
              container
              item
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flexWrap="nowrap"
            >
              <Grid
                container
                item
                ml={6}
                sx={{
                  width: '295px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ApplestoreIcon />
                <PlaystoreIcon />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
