export const cleanupPlacementType = (value: any): string => {
  if (value == null || value === 'None' || value === '') return 'N/A';
  return value;
};

export const areEqual = (array1: any, array2: any) => {
  if (array1.length !== array2.length) return false;

  const isEqual = array1.every((element, index) => {
    return array2.find(
      arr2 =>
        arr2['type'] === element['type'] &&
        arr2['isActive'] === element['isActive'],
    );
  });
  return isEqual;
};
