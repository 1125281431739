import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/configureStore';
import { AppUserType } from 'enums/AppUserType';

const profileDomain = (state: RootState) => state.userProfile;
const authDomain = (state: RootState) => state.auth;
export const userIdSelector = (state: RootState) => state.auth.userId;

export const authenticationSelector = createSelector(
  [profileDomain, authDomain],
  (profState, authState) =>
    !!profState.hasAcceptedTermsAndConditions && authState.isLoggedIn,
);

export const isLocumsSelector = createSelector([authDomain], authState =>
  authState.userType === AppUserType.LOCUMS ? true : false,
);
