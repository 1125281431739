import React, { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchResultsSplash } from 'components/common/PageLayout/SearchResultsSplash';
import { createCardListPageStyles } from 'components/common/PageLayout/PageLayout.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import SearchBar from './SearchBar';
import { useHistory } from 'react-router-dom';
import { theme } from 'styles/theme';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { DrawerHeaderType } from 'enums/headerTypes';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import CloseIcon from 'assets/icons/Close/Close';

export const PageSearchBar = props => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [openSearchDrawer, setOpenSearchDrawer] = useState<boolean>(false);
  const [downloadToggledOn, setDownloadToggledOn] = useState<boolean>(false);

  const [searchResults, setSearchResults] = useState([]);
  const onResultsChange = useCallback((results, searchTerm) => {
    setIsSearch(!!searchTerm?.trim?.());
    setSearchResults(results);
  }, []);
  const history = useHistory();
  const [recentItems, setRecentItems] = useState([]);
  const [cacheName] = useState('RecentSearches');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const classes = createCardListPageStyles();
  const showFilter: boolean = useSelector(
    (state: RootState) => state.filter.showFilter,
  );

  const [savedItem, setSavedItem] = useState(true);

  const saveToCache = useCallback(
    item => {
      caches.open(cacheName).then(cacheStorage => {
        cacheStorage
          ?.match?.(history.location.pathname)
          ?.then?.(cachedResponse => {
            const setCacheItem = (itemIds = undefined) => {
              setSavedItem(true);
              const data = new Response(
                JSON.stringify(
                  [
                    item?.id || item?.question,
                    ...(Array.isArray(itemIds)
                      ? (itemIds as []).filter(
                          itemId => itemId !== (item?.id || item?.question),
                        )
                      : []),
                  ].splice(0, props.cacheSize || 5),
                ),
              );

              cacheStorage.put(history.location.pathname, data);
            };

            if (cachedResponse === undefined) {
              setCacheItem();
            }
            cachedResponse?.json?.()?.then?.(itemIds => {
              setCacheItem(itemIds);
            });
          });
      });
    },
    [cacheName, history.location.pathname, props.cacheSize],
  );

  useEffect(() => {
    if (savedItem) {
      caches.open(cacheName).then(cacheStorage => {
        cacheStorage
          ?.match?.(history.location.pathname)
          ?.then?.(cachedResponse => {
            cachedResponse?.json?.()?.then?.(itemIds => {
              setSavedItem(false);
              setRecentItems(
                itemIds
                  ?.map(itemId => {
                    let itemFound = null;

                    props.items?.some(item => {
                      if ((item?.id || item?.question) === itemId) {
                        itemFound = item;
                        return true;
                      }
                    });

                    return itemFound;
                  })
                  .filter(item => item),
              );
            });
          });
      });
    }
  }, [cacheName, history.location.pathname, props.items, savedItem]);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={4}
        md={4.5}
        className={classes.scrollContainer}
        maxHeight={showFilter ? 'calc(100vh - 99px)' : '100%'}
        display="flex"
        flexDirection="column"
        pb={8}
      >
        {!!props.enableSearchBar && (
          <Box>
            <SearchBar
              items={props.items}
              itemKey={props.searchKey}
              onResultsChange={onResultsChange}
              onFocus={props.onFocus}
              recentItems={recentItems}
              disableRecentItems={
                isMobile ? true : props.disableRecentItems || isSearch
              }
              onClick={() => setOpenSearchDrawer(isMobile ? true : false)}
              placeholder={props.searchPlaceholder}
            />
          </Box>
        )}
        {props.navItems}
      </Grid>
      {isSearch && (props.onFocus ? !props.navItemSelected : true) ? (
        <Grid
          item
          sm={8}
          pr={3}
          pb={6}
          md={7}
          display={{ xs: 'none', sm: 'block' }}
          id="searchSplash"
        >
          <SearchResultsSplash
            searchResults={searchResults}
            searchResultOnClick={props.searchResultOnClick}
            searchItemComponent={props.searchItemComponent}
            onDownloadIconClick={props.onSearchDownloadIconClick}
            onDownloadClick={props.onSearchDownloadClick}
            saveToCache={saveToCache}
          />
        </Grid>
      ) : (
        <>{props.cardDetails}</>
      )}
      <DrawerPanel
        open={openSearchDrawer && isMobile}
        headerType={DrawerHeaderType.Back}
        toggleDrawer={(e, open) => open === false && setOpenSearchDrawer(false)}
        contentName="Search Results"
        showFooter={false}
        backRightAdornment={
          props.onSearchDownloadIconClick && props.onSearchDownloadClick ? (
            downloadToggledOn ? (
              <CloseIcon width="18px" height="18px" />
            ) : (
              <DownloadIcon width="18px" height="18px" />
            )
          ) : (
            <></>
          )
        }
        backRightAdornmentClick={() => {
          setDownloadToggledOn(prevState => !prevState);
          props.onSearchDownloadIconClick();
        }}
        leftBackHeaderClick={() => {
          props.leftBackHeaderClick && props.leftBackHeaderClick();
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <SearchBar
              items={props.items}
              itemKey={props.searchKey}
              onResultsChange={onResultsChange}
              onFocus={props.onFocus}
              recentItems={recentItems}
              disableRecentItems={
                props.disableRecentItems || (isSearch && isMobile)
              }
              onClick={() => setOpenSearchDrawer(isMobile ? true : false)}
              searchBarSx={{
                marginBottom: '0px !important',
                width: '100% !important',
              }}
              isMobile={isMobile}
            />
          </Grid>
          <Grid item xs={12}>
            {(props.disableRecentItems || (isMobile && isSearch)) && (
              <SearchResultsSplash
                searchResults={searchResults}
                searchResultOnClick={props.searchResultOnClick}
                searchItemComponent={props.searchItemComponent}
                isMobile={isMobile}
                isDownloadToggledOn={downloadToggledOn}
                onDownloadIconClick={props.onSearchDownloadIconClick}
                onDownloadClick={() => {
                  setDownloadToggledOn(false);
                  props.onSearchDownloadClick();
                }}
                saveToCache={saveToCache}
                cardSx={{ padding: '24px 0px 24px 0px !important' }}
              />
            )}
          </Grid>
        </Grid>
      </DrawerPanel>
    </>
  );
};
