import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import { IReactHookFormDropdownProps } from 'interfaces/Props/IReactHookFormDropdownProps';
import { ArrowDownSvg } from 'assets/icons/Controls/ArrowDownSvg';

export const ReactHookFormDropdown: FC<IReactHookFormDropdownProps> = ({
  label,
  name,
  required,
  showOptionalIndicator,
  selectedValue,
  data,
  excludeExhaustedItem = false,
  getSelectedItemName,
  defaultValue,
  onChange,
  variant = 'filled',
  selectStyles,
  labelSx,
  disabled = false,
  notched,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const selectedItemName = React.useMemo(() => {
    if (getSelectedItemName) {
      return getSelectedItemName(selectedValue);
    }
    return '';
  }, [getSelectedItemName, selectedValue]);

  return (
    <>
      <FormControl
        id={'form-dropdown-' + name}
        variant={variant}
        required={required}
        fullWidth
        error={!!errors[name]}
      >
        <InputLabel
          htmlFor={name + '-dropdown'}
          sx={{ ...labelSx }}
          data-testid="label"
          shrink={notched || selectedValue}
        >
          {label}
        </InputLabel>
        <Select
          {...register(name)}
          onChange={onChange}
          defaultValue={defaultValue}
          value={selectedValue}
          fullWidth
          name={name}
          disabled={disabled}
          inputProps={{
            id: `${name}-dropdown`,
          }}
          MenuProps={{
            PaperProps: { sx: { maxHeight: 300 } },
          }}
          displayEmpty={excludeExhaustedItem}
          IconComponent={ArrowDownSvg}
          notched={notched || selectedValue}
          sx={{
            ...selectStyles,
            '& .MuiSvgIcon-root': {
              marginRight: '10px',
              height: '18px',
              marginTop: '5px',
            },
            color: 'system.grayText',
          }}
        >
          {data?.map((option, index) => (
            <MenuItem key={index} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
          {excludeExhaustedItem && selectedItemName && (
            <MenuItem
              key={selectedValue}
              value={selectedValue}
              disabled
              style={{ display: 'none' }}
            >
              {selectedItemName || ''}
            </MenuItem>
          )}
        </Select>
        {!!errors[name] && (
          <FormHelperText>{errors[name]?.message ?? ''}</FormHelperText>
        )}
        {showOptionalIndicator && (
          <FormHelperText
            sx={{
              position: 'absolute',
              top: '52px',
              color: 'system.coolGray',
            }}
          >
            Optional
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
