import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssignmentExtensionLinkText,
  AssignmentExtensionText,
} from 'enums/assignmentExtension';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { IAssignmentDetails } from 'interfaces/Assignment/AssignmentDetail';
import { IAssignmentSummary } from 'interfaces/Assignment/AssignmentSummary';
import { AssignmentSummaryItem } from 'interfaces/Assignment/AssignmentSummaryItem';
import {
  IExtendAssignment,
  IExtendAssignmentPreference,
} from 'interfaces/Assignment/ExtendAssignment';
import { IAssignmentState } from './assignmentState';

const initialState: IAssignmentState = {
  assignmentState: {
    items: [],
    itemsToRemove: [],
  },
  activeCardDetails: {
    items: [],
    paging: {},
  },
  placementId: 0,
  drawerOpen: DrawerContentNames.CLOSED_DRAWER,
  extendedAssignments: [],
  assignmentExtensionText: AssignmentExtensionText.Default,
  assignmentExtensionLinkText: AssignmentExtensionLinkText.Default,
  loadedForTagManager: false,
};

const getAssignmentDetailsAction = createAction(
  'GET_ASSIGNMENT_DETAILS',
  function prepare(userId: string, activePlacementId: number) {
    return {
      payload: {
        userId,
        activePlacementId,
      },
    };
  },
);
const getAssignmentSummaryAction = createAction('GET_ASSIGNMENT_SUMMARY');
const confirmItineraryAction = createAction(
  'CONFIRM_ITINERARY',
  function prepare(placementId: string, travelConfirmed: boolean) {
    return {
      payload: {
        placementId,
        travelConfirmed,
      },
    };
  },
);
const postAssignmentExtensionAction = createAction(
  'POST_ASSIGNMENT_EXTENSION',
  function prepare(
    extendAssignmentData: IExtendAssignment,
    isMobileDevice: boolean,
  ) {
    return {
      payload: {
        extendAssignmentData,
        isMobileDevice,
      },
    };
  },
);
const dismissCancelledAssignmentAction = createAction(
  'DISMISS_CANCELLED_ASSIGNMENTS',
  function prepare(placementId: number) {
    return {
      payload: {
        placementId,
      },
    };
  },
);

const strikeAssignmentAcknowledgement = createAction(
  'STRIKE_ASSIGNMENT_ACKNOWLEDGEMENT',
  function prepare(placementId: number, isHomepageAssignmentCard?: boolean) {
    return {
      payload: {
        placementId,
        isHomepageAssignmentCard,
      },
    };
  },
);

const setConfirmExtensionOffer = createAction(
  'STRIKE_EXTENSION_OFFER_CONFIRMATION',
  function prepare(placementId: number, hasExtensionConfirmed?: boolean) {
    return {
      payload: {
        placementId,
        hasExtensionConfirmed,
      },
    };
  },
);

const assignmentSlice = createSlice({
  name: 'assignmentSlice',
  initialState,
  reducers: {
    setAssignmentSummary(state, action: PayloadAction<IAssignmentSummary>) {
      state.assignmentState.items = action.payload.items;
    },
    setRemoveAssignment(state, action: PayloadAction<string>) {
      let itemToRemove = state.assignmentState.items?.find(
        a => a.placementId.toString() === action.payload,
      ) as AssignmentSummaryItem;
      if (!state.assignmentState.itemsToRemove)
        state.assignmentState.itemsToRemove = [];
      state.assignmentState.itemsToRemove.push(itemToRemove);
      state.assignmentState.items = state.assignmentState.items.filter(
        a => a.placementId.toString() !== action.payload,
      );
      state.placementId = 0;
    },
    undoRemoveAssignment(state, action: PayloadAction<string>) {
      let itemToRemove = state.assignmentState.itemsToRemove?.find(
        a => a.placementId.toString() === action.payload,
      ) as AssignmentSummaryItem;
      state.assignmentState.items.push(itemToRemove);
      state.assignmentState.itemsToRemove = [];
      state.placementId = parseInt(action.payload);
    },
    setAssignmentDetails(state, action: PayloadAction<IAssignmentDetails>) {
      state.activeCardDetails = action.payload;
    },
    setPlacementId(state, action: PayloadAction<number>) {
      state.placementId = action.payload;
    },
    setDrawerState(state, action: PayloadAction<DrawerContentNames>) {
      state.drawerOpen = action.payload;
    },
    setExtendedAssignment(
      state,
      action: PayloadAction<IExtendAssignmentPreference>,
    ) {
      const assignmentExistsIndex = state.extendedAssignments.findIndex(
        ea => ea.assignmentPlacementId === action.payload.assignmentPlacementId,
      );

      if (assignmentExistsIndex > -1) {
        const assignments = state.extendedAssignments.map(a => {
          if (
            a.assignmentPlacementId === action.payload.assignmentPlacementId
          ) {
            return {
              ...action.payload,
            };
          }
          return a;
        });
        state.extendedAssignments = assignments;
      } else {
        state.extendedAssignments = [
          ...state.extendedAssignments,
          action.payload,
        ];
      }
    },
    setAssignmentExtensionText(
      state,
      action: PayloadAction<AssignmentExtensionText>,
    ) {
      state.assignmentExtensionText = action.payload;
    },
    setAssignmentExtensionLinkText(
      state,
      action: PayloadAction<AssignmentExtensionLinkText>,
    ) {
      state.assignmentExtensionLinkText = action.payload;
    },
    removeAssignmentForStrike(state, action) {},
    savePaycardInfo(state, action) {},
    setLoadedForTagManager(state, action: PayloadAction<boolean>) {
      state.loadedForTagManager = action.payload;
    },
    setAssigmentAfterDismissal(state, action) {
      //Find index of specific object using findIndex method.
      const objIndex = state.assignmentState.items?.findIndex(
        obj => obj.placementId === action.payload,
      );
      if (objIndex >= 0) {
        state.assignmentState.items[objIndex].isDismissed = true;
      }
    },
    setTravelConfirmedForAssignment(state, action) {
      const objIndex = state.assignmentState.items?.findIndex(
        obj => obj.placementId === action.payload,
      );
      if (objIndex >= 0) {
        state.assignmentState.items[objIndex].strike.travelConfirmed = true;
      }
      if (state?.activeCardDetails?.items[0]?.placementId === action.payload) {
        state.activeCardDetails.items[0].strike.travelConfirmed = true;
      }
    },
    // used to remove action items attached to an assignment/placement
    removeActionItemForAssignment(state, action) {
      let isAssignmentPage = action.payload.isAssignmentPage;
      let clearanceItemId = action.payload.clearanceItemId;

      state.assignmentState.items?.forEach(assignment => {
        const actionItemIndex = assignment.actionItems?.findIndex(
          actionItem => actionItem.id === clearanceItemId,
        );
        if (actionItemIndex >= 0) {
          assignment.actionItems?.splice(actionItemIndex, 1);
        }
      });
      if (isAssignmentPage) {
        state.activeCardDetails.items?.forEach(assignment => {
          const actionItemIndex = assignment.actionItems?.findIndex(
            actionItem => actionItem.id === clearanceItemId,
          );
          if (actionItemIndex >= 0) {
            assignment.actionItems?.splice(actionItemIndex, 1);
          }
        });
      }
    },
  },
});

export const assignmentActions = {
  ...assignmentSlice.actions,
  getAssignmentDetailsAction,
  getAssignmentSummaryAction,
  confirmItineraryAction,
  dismissCancelledAssignmentAction,
  postAssignmentExtensionAction,
  strikeAssignmentAcknowledgement,
  setConfirmExtensionOffer,
};
export default assignmentSlice.reducer;
