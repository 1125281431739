import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IJobCardProps } from 'interfaces/Props/IJobCardProps';
import styled from 'styled-components/macro';
import { mediaMax } from 'styles/media';
import { theme } from 'styles/theme';

const JobDetailStyle = styled.div<IJobCardProps>`
  padding-bottom: ${props => (props.showMap ? '' : '60px')};
  width: 100%;
  max-width: 897px;
  ${mediaMax.medium`
    max-height: ${props => (props.showMap ? '' : '545px')};
    max-width: ${props => (props.showMap ? '' : '555px')};
  `}
  .amn-card {
    width: 100%;
    margin: ${props => (props.showMap ? '0px 24px' : '0px 0px')};
    padding: ${props => (props.isCardExpanded ? '24px 24px 0px 24px' : '24px')};
    ${mediaMax.medium`
    max-width: ${props => (props.isCardExpanded ? '' : '507px')};
  `}
    border-radius: 10px;
    position: relative;
    overflow: visible;
    box-shadow: 0px 1px 15px ${theme.palette.system.boxShadowGray};
    .close-btn {
      position: absolute;
      top: -10px;
      right: -10px;
    }
    .card-img {
      height: 190px;
      border-radius: 10px;
    }
    .amn-badge {
      background: ${theme.palette.system.grassGreen} 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 0.9;
      color: ${theme.palette.system.white};
      padding: 5px;
      display: flex;
      align-items: center;
    }

    .card-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .card-price {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
    }

    .card-tray {
      color: ${theme.palette.system.coolGray};
      font-size: 14px;
      padding-left: 7px;
    }
    .card-tray-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 12px;
    }
    .card-tray-padding {
      padding-left: 0px !important;
      padding-top: 0px !important;
      line-height: 19px;
      min-width: min-content;
      width: min-content;
      max-width: max-content;
    }
    .top-divider {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .detail-parent {
      display: flex;
      scrollbar-width: none;
      flex-direction: column;
      height: 100%;
      padding-left: 3px;
      @media (min-width: 1366px) {
        max-height: ${props =>
          props.badgeDetails
            ? `calc(100vh - ${
                props.clientHeight ? props.clientHeight + 426 + 'px' : '460px'
              })`
            : `calc(100vh - ${
                props.clientHeight ? props.clientHeight + 375 + 'px' : '460px'
              })`};
      }
      @media (max-width: 1366px) {
        max-height: ${props =>
          props.clientHeight
            ? `calc(100vh - ${
                props.clientHeight +
                (props.jobResponseLength && props.jobResponseLength > 2
                  ? 470
                  : 537) +
                'px'
              })`
            : '555px'};
      }
      overflow-y: auto;
      width: '100%';
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.palette.system.white};
        border-radius: 10px;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      .job-details {
        display: flex;
        flex-direction: row;
      }
    }

    .credential {
      display: flex;
      flex-direction: column;
    }

    .left-content {
      width: 100%;
      @media (max-width: 1024px) {
        max-width: 205px;
      }
      display: flex;
      flex-direction: column;
      flex: auto;
    }
  }
`;

const AmountTextTypography = styled(Typography)({
  color: `${theme.palette.system.grassGreen}`,
  alignItems: 'end',
  fontSize: '18px !important',
  opacity: '1',
  letterSpacing: '0px',
  fontWeight: 600,
});

const AmountTextStrikeTypography = styled(Typography)({
  color: 'system.coolGray',
  alignItems: 'end',
  fontSize: '18px !important',
  fontStyle: 'italic',
  opacity: '0.5',
  letterSpacing: '0px',
  marginBottom: '12px',
});

const AmountPerWeekTypography = styled(Typography)({
  marginBottom: '3px !important',
  height: '17px',
  fontSize: '12px !important',
  letterSpacing: '0px',
  color: `${theme.palette.system.coolGray}`,
  opacity: '1',
});

export const amountContainerStyles = makeStyles({
  amountContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    height: '33px',
  },
});

export {
  AmountTextTypography,
  JobDetailStyle,
  AmountPerWeekTypography,
  AmountTextStrikeTypography,
};
