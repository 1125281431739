import React from 'react';
import { createSvgIcon } from '@mui/material';

export const WarningIcon = createSvgIcon(
  <svg
    id="Warning"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      id="Rectangle_7378"
      data-name="Rectangle 7378"
      width="24"
      height="24"
      fill="#ffa22e"
      opacity="0"
    />
    <g
      id="Group_13245"
      data-name="Group 13245"
      transform="translate(0.632 1.263)"
    >
      <path
        id="Path_45625"
        data-name="Path 45625"
        d="M13.219,5.6A.242.242,0,0,0,13,5.7L3.751,23.181l0,.006c.012.024.062.1.217.1H22.473c.155,0,.205-.076.217-.1l0-.006L13.433,5.7a.242.242,0,0,0-.214-.094m0-1.895a2.1,2.1,0,0,1,1.889,1.1l9.254,17.484a2,2,0,0,1-1.889,2.887H3.965a2,2,0,0,1-1.889-2.887L11.33,4.811A2.1,2.1,0,0,1,13.219,3.708Z"
        transform="translate(-1.85 -3.708)"
        fill="#ffa22e"
      />
      <path
        id="Path_3736"
        data-name="Path 3736"
        d="M29.675,29.852a.925.925,0,0,0,.925-.925V22.407a.925.925,0,0,0-1.85,0v6.521A.925.925,0,0,0,29.675,29.852Z"
        transform="translate(-18.351 -15.873)"
        fill="#ffa22e"
      />
      <circle
        id="Ellipse_601"
        data-name="Ellipse 601"
        cx="1.11"
        cy="1.11"
        r="1.11"
        transform="translate(10.214 15.994)"
        fill="#ffa22e"
      />
    </g>
  </svg>,
  'WarningIcon',
);
