import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.system.coolGray,
    boxShadow: `0px 2px 8px ${theme.palette.system.popOverBoxShadow}`,
    fontSize: 14,
    padding: '24px',
    maxWidth: '483px',
  },
}));
