import React from 'react';
import { IFeaturedContent } from 'types/FeaturedContentResponse';
import {
  Darkoverlay,
  FeaturedCard,
  FeaturedLink,
} from './FeaturedContentCard.styles';
import { openInNewTab } from 'helpers/openInNewTab';
import { getUrlRegex } from 'helpers/validationHelper';
import { useAppDispatch } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import { MenuIcons } from 'enums/menuIcons';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import mapMenuIconsection from 'utils/mapMenuIconNameSection';
import { CommunityUpdateType } from 'enums/communityUpdateType';
import mapApiLinkToRoutes from 'utils/Link/mapApiLinkToRoutes';
import Box from '@mui/material/Box';
import { trackEvent } from 'services/logging/appInsights';
import { TaskCenterCategoryTypeId, userCredentials } from 'enums/credential';
import mapMenuIconSection from 'utils/mapMenuIconNameSection';
import { AppRouteNames } from 'routes/appRouteNames';

/**
 * Component props
 */
interface IProps {
  data?: IFeaturedContent;
  onClickCallback?: () => void;
  sx?: any;
}

/**
 * Featured content card.
 */
const FeaturedContentCard: React.FC<IProps> = props => {
  // props destructed
  const { data, onClickCallback, sx } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const goToCommunitySectionPost = (postId: number | undefined) => {
    dispatch(setActivePage(MenuIcons.Community));
    history.push(mapMenuIconsection(MenuIcons.Community) + '/' + postId);
  };

  const handleClick = () => {
    const isCredentialDeepLinkItem =
      data?.actionUrl?.includes('TaskCenter') ||
      data?.actionUrl?.includes('OpenRequirements') ||
      data?.actionUrl?.includes('Docusign') ||
      data?.actionUrl?.includes('ResourcesPage');

    if (isCredentialDeepLinkItem) {
      if (data?.actionUrl.includes('TaskCenter')) {
        if (data?.actionUrl.includes('id')) {
          const credentialId = data?.actionUrl.substring(
            data?.actionUrl.indexOf('=') + 1,
          );
          history.push(
            `/${AppRouteNames.TASK_CENTER}?credentialId=${credentialId}&credentialType=${TaskCenterCategoryTypeId.Credentials}`,
          );
        } else if (data?.actionUrl.includes('tab=open'))
          history.push(
            `/${AppRouteNames.TASK_CENTER}?categoryId=${userCredentials.OpenTasks}`,
          );
        else history.push(`/${AppRouteNames.TASK_CENTER}`);
      } else if (data?.actionUrl.includes('Docusign')) {
        if (data?.actionUrl.includes('envelopeId')) {
          const envelopeId = data?.actionUrl.substring(
            data?.actionUrl.indexOf('=') + 1,
          );
          history.push(
            `/${AppRouteNames.TASK_CENTER}?envelopeId=${envelopeId}`,
          );
        } else history.push(`/${AppRouteNames.TASK_CENTER}?docusign=${true}`);
      } else if (data?.actionUrl?.includes('ResourcesPage')) {
        if (data?.actionUrl.includes('categoryid')) {
          const categoryid = data?.actionUrl.substring(
            data?.actionUrl.indexOf('=') + 1,
          );
          history.push(`/resources?categoryId=${categoryid}`);
        } else history.push(`/resources`);
      }
    }

    if (
      (data?.type ?? CommunityUpdateType.FEATURE_CONTENT) ===
        CommunityUpdateType.FEATURE_CONTENT &&
      !isCredentialDeepLinkItem
    ) {
      switch (data?.actionUrl) {
        case data?.actionUrl.match(getUrlRegex())?.input:
          openInNewTab(data?.actionUrl);
          break;
        case 'AMNOne://VaccinationRecord':
          if (onClickCallback) onClickCallback();
          break;
        default:
          history.push(mapApiLinkToRoutes(data?.actionUrl ?? ''));
          break;
      }
    } else if (
      (data?.type ?? CommunityUpdateType.FEATURE_CONTENT) ===
      CommunityUpdateType.BLOG_POST
    ) {
      goToCommunitySectionPost(data?.postId);
    }

    trackEvent('FeaturedContent_Clicked');
  };

  // component is rendered here
  return (
    <FeaturedCard backgroundImage={data?.image} sx={{ ...sx }}>
      <FeaturedLink
        onClick={handleClick}
        rel="noopener,noreferrer"
        underline="none"
        data-testid="featured-content-card-link"
      >
        <Box
          sx={{
            display: '-webkit-box',
            '-webkit-line-clamp': '4',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
          }}
        >
          {data?.text}
        </Box>
      </FeaturedLink>
      {data?.text && <Darkoverlay />}
    </FeaturedCard>
  );
};

export default FeaturedContentCard;
