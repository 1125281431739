import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SearchIcon from 'assets/icons/Search/SearchIcon';
import React from 'react';
import { theme } from 'styles/theme';

const NoJobSearchSplashCard = props => {
  const { sx, isStrikeJobs } = props;
  //========= Determine styles and properties for strike & travel jobs ============//
  const cardColor = isStrikeJobs ? 'system.white' : theme.palette.background;
  const borderColor = isStrikeJobs ? theme.palette.system.border : 'none';
  const iconColor = isStrikeJobs
    ? theme.palette.system.midnightBlue
    : theme.palette.system.ashGray;
  const message = isStrikeJobs ? 'Hmm, no strike jobs found.' : 'No jobs found';
  //==============================================================================//
  return (
    <Box
      id="no-job-search-splash-card"
      sx={{
        padding: '26px 15px',
        backgroundColor: cardColor,
        borderRadius: '10px',
        border: `1px solid ${borderColor}`,
        marginTop: '24px',
        ...sx,
      }}
    >
      <Grid
        container
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        gap={2}
        justifyContent={isStrikeJobs ? 'flex-start' : 'center'}
      >
        {isStrikeJobs && (
          <Grid item display="flex" alignItems="center">
            <SearchIcon size={25} color={iconColor} />
          </Grid>
        )}
        <Grid item>
          <Typography
            variant="subtitle1"
            color={iconColor}
            fontStyle="italic"
            lineHeight="22px"
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoJobSearchSplashCard;
