import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import 'react-html5-camera-photo/build/css/index.css';
import 'react-image-crop/dist/ReactCrop.css';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { PlusIcon } from 'assets/icons/Add/PlusIcon';
import { TrashIcon } from 'assets/icons/Trash/Trash';
import { AppButton } from 'components/common/Button/Button';
import AlertDialog from 'components/common/Dialog/ErrorDialog';
import { allowedFileTypes } from 'constants/credentials';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_BYTES } from 'constants/fileTypes';
import { LockoutMessageRow } from 'containers/LoginContainer/LoginWrapper.styles';
import { FileUploadErrorsEnum } from 'enums/fileUploadType';
import { getCroppedImg } from 'helpers/cropImage';
import { guidGenerator } from 'helpers/guidGenerator';
import { getUrl } from 'services/domain/domainService';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { IUploadedFileState } from 'store/slices/fileUpload/fileUploadState';
import { theme } from 'styles/theme';
import { FileUploadThumbnail } from './FileUploadThumbnail';
import BlobUrlToBase64 from 'utils/BlobUrlToBase64';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { TOAST_MESSAGE } from 'constants/helperText';

const fileDialogStyles = makeStyles(theme => ({
  cropper: {
    display: 'flex',
    '& .ReactCrop__crop-selection': {
      border: `1px ${theme.palette.system.inputBackground} solid`,
      '& .ReactCrop__drag-elements': {
        '& .ReactCrop__drag-handle::after': {
          border: 'none',
          outline: 'none',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '& .ReactCrop__drag-handle.ord-ne': {
          height: '24px',
          width: '24px',
          borderRight: `5px solid ${theme.palette.system.white}`,
          borderTop: `5px solid ${theme.palette.system.white}`,
        },
        '& .ReactCrop__drag-handle.ord-nw': {
          height: '24px',
          width: '24px',
          borderLeft: `5px solid ${theme.palette.system.white}`,
          borderTop: `5px solid ${theme.palette.system.white}`,
        },
        '& .ReactCrop__drag-handle.ord-se': {
          height: '24px',
          width: '24px',
          borderRight: `5px solid ${theme.palette.system.white}`,
          borderBottom: `5px solid ${theme.palette.system.white}`,
        },
        '& .ReactCrop__drag-handle.ord-sw': {
          height: '24px',
          width: '24px',
          borderLeft: `5px solid ${theme.palette.system.white}`,
          borderBottom: `5px solid ${theme.palette.system.white}`,
        },
      },
    },
  },
}));

export const FileUploadModal = ({
  id,
  handleClose: handleCloseOptions,
  required,
  isSubmitted = false,
  label,
  customMaxFileSizeError,
  canUploadMultiple,
  acceptedFileTypes = allowedFileTypes,
  onUploadedFile,
}: {
  id?: string;
  handleClose?: any;
  required?: boolean;
  isSubmitted?: boolean;
  label?: string;
  customMaxFileSizeError?: string;
  canUploadMultiple?: boolean;
  acceptedFileTypes?: any;
  onUploadedFile?: (data: IUploadedFileState) => void;
}) => {
  const dispatch = useAppDispatch();
  const dialogStyles = fileDialogStyles();
  // for multiple file upload
  const filesUploaded = useAppSelector(state => state.fileUpload.uploadedFiles);
  // for single file upload
  const fileUploaded = useAppSelector(state => state.fileUpload.uploadedFile);

  const [fileEditorOpen, setFileEditorOpen] = useState(false);
  const [fileObject, setFileObject] = useState<File>();
  const [fileSrc, setFileSrc] = useState('');
  const [fileType, setFileType] = useState<string>('');
  const fileRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [rotate, setRotate] = useState(0);
  const [error, setError] = useState(FileUploadErrorsEnum.NO_ERROR);
  const errorTypes = useMemo(
    () => ({
      [FileUploadErrorsEnum.INVALID_FILE_TYPE]: {
        title: 'Invalid File Type',
        message: (
          <>
            {`This file type is not supported. You can only upload `}
            {Object.keys(acceptedFileTypes).map(
              (f, idx) =>
                Object.keys(acceptedFileTypes).length - 1 !== idx && (
                  <Typography
                    key={f}
                    variant="body1"
                    fontWeight="700"
                    component="span"
                  >
                    {`${f}, `}
                  </Typography>
                ),
            )}
            or
            <Typography variant="body1" fontWeight="700" component="span">
              {` ${
                Object.keys(acceptedFileTypes)[
                  Object.keys(acceptedFileTypes).length - 1
                ]
              } `}
            </Typography>
            files.
          </>
        ),
      },
      [FileUploadErrorsEnum.EXCEEDS_FILE_SIZE_LIMIT]: {
        title: 'Exceeds File Size Limit',
        message: customMaxFileSizeError
          ? customMaxFileSizeError
          : `The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE}MB. Optimize file to reduce size.`,
      },
    }),
    [customMaxFileSizeError, acceptedFileTypes],
  );

  const handleCloseError = useCallback(() => {
    setError(FileUploadErrorsEnum.NO_ERROR);
  }, []);

  const removeDocument = (id, canUploadMultiple) => {
    if (canUploadMultiple) {
      dispatch(fileUploadsActions.removeUploadedFileById(id));
    } else {
      dispatch(fileUploadsActions.removeUploadedFile(undefined));
    }
  };

  const handleCloseFileEditor = () => {
    setFileEditorOpen(false);
    setFileSrc('');
    setFileType('');
    setFileObject(undefined);
  };

  // Handles adding file to redux state and clearing state values
  const addUploadedFile = (data: IUploadedFileState) => {
    if (canUploadMultiple === true) {
      dispatch(fileUploadsActions.appendUploadedFile(data));
    } else {
      dispatch(fileUploadsActions.setUploadedFile(data));
    }
    onUploadedFile?.(data);
    setFileSrc('');
    setFileType('');
    setFileObject(undefined);
    setCrop(undefined);
    setCompletedCrop(undefined);
    setRotate(0);
  };

  const urlToFile = (url, filename, mimeType) => {
    return getUrl(url).then((buf: any) => {
      return new File([buf], filename, { type: mimeType });
    });
  };

  const urlToBase64 = (url, filename) => {
    return BlobUrlToBase64(url).then((data: any) => {
      return {
        fileName: filename,
        content: data,
      };
    });
  };

  // Handles PDF/Doc files after upload
  const handlePDFDocDone = (ft: string, fo: File) => {
    urlToBase64(URL.createObjectURL(fo), fo.name).then(response => {
      let data = {
        fileType: ft,
        fileObject: fo,
        fileLocalUrl: URL.createObjectURL(fo),
        fileBase64: response,
      };
      addUploadedFile(data);
    });
  };

  // Handles when a user selects a file from upload modal
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      let fs = e.target.files[0].size;
      let fn = e.target.files[0].name;
      let ft = e.target.files[0].type.toString();

      if (checkFileExtension(fn) || checkFileType(ft)) {
        setError(FileUploadErrorsEnum.INVALID_FILE_TYPE);
      } else if (fs > MAX_FILE_SIZE_BYTES) {
        setError(FileUploadErrorsEnum.EXCEEDS_FILE_SIZE_LIMIT);
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', async e => {
          setFileSrc(e?.target?.result?.toString() || '');
        });
        reader.readAsDataURL(e.target.files[0]);
        setFileObject(e.target.files[0]);
        setFileType(ft);
        setCrop(undefined);

        // If file is of type PDF/Doc/DocX,
        // then it will skip the rotate/crop modal
        // else it will show rotate/crop modal
        if (
          ft === allowedFileTypes.pdf ||
          ft === allowedFileTypes.doc ||
          ft === allowedFileTypes.docx
        ) {
          handlePDFDocDone(ft, (e.target.files || [])[0]);
        } else {
          setFileEditorOpen(true);
        }
      }
    }
  };

  // On input click fxn
  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
  ) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  // On image upload --> when user uploads a image, it will collect crop data
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  // Opens up native upload modal on click
  const openFile = () => {
    const inputItem = id
      ? document.getElementById(`file_input-${id}`)
      : document.getElementById('file_input');
    inputItem?.click();
  };

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  // On rotate fxn
  const handleSetRotate = () => {
    if (rotate === 360) {
      setRotate(0);
    } else {
      setRotate(prevState => prevState + 90);
    }
  };

  // Handles image updates after cropping
  const handleCrop = () => {
    const croppedData = getCroppedImg(
      fileRef.current,
      completedCrop as PixelCrop,
      rotate,
      fileType,
    );
    if (croppedData && completedCrop?.height && completedCrop?.width) {
      urlToFile(croppedData, fileObject?.name, fileType || 'text/plain').then(
        tempUrl => {
          if (tempUrl?.size > MAX_FILE_SIZE_BYTES) {
            setError(FileUploadErrorsEnum.EXCEEDS_FILE_SIZE_LIMIT);
          } else {
            urlToBase64(croppedData || '', tempUrl.name).then(response => {
              const data = {
                fileType,
                fileObject: tempUrl,
                fileLocalUrl: croppedData || '',
                fileBase64: response,
              };
              addUploadedFile(data);
              setFileEditorOpen(false);
              dispatch(fileUploadsActions.toggleFileModalOpenState(false));
              handleCloseOptions && handleCloseOptions();
            });
          }
        },
      );
    } else {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.ImageSizeIsTooSmall,
        }),
      );
    }
  };

  const returnBorderColor = (fileType: string) => {
    switch (fileType) {
      case allowedFileTypes.pdf:
      case allowedFileTypes.doc:
      case allowedFileTypes.docx:
        return theme.palette.system.skyBlue;
      default:
        return theme.palette.system.border;
    }
  };

  const checkFileType = (fileType: string) => {
    const exists = Object.values(acceptedFileTypes || []).find(
      ft => fileType === ft,
    );
    return !exists;
  };

  const checkFileExtension = (fileName: string) => {
    const exists = Object.keys(acceptedFileTypes || []).find(
      fn =>
        (
          (fileName.split('.') || [])[fileName.split('.').length - 1] || ''
        ).toLowerCase() === fn,
    );
    return !exists;
  };

  // handling the display in case of single/multiple upload
  const fileUploadedDisplay = useCallback(
    (f, filesUploaded, canUploadMultiple?: boolean | undefined, idx?: any) => {
      return (
        <Grid
          item
          key={guidGenerator()}
          xs={canUploadMultiple ? 6 : 12}
          pr={idx % 2 === 1 ? 0 : canUploadMultiple ? '12px' : 0}
          pl={idx % 2 === 1 ? '12px' : 0}
          display={filesUploaded?.length > 0 ? 'block' : 'none'}
          pb={(filesUploaded || [])?.length === 0 ? 0 : '12px'}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            overflow="hidden"
            height="84px"
            borderRadius={1}
            position="relative"
            border={`1px solid ${returnBorderColor(f?.fileType || '')}`}
            data-testid="file-upload-modal_uploaded-preview-container"
          >
            <IconButton
              onClick={() => {
                removeDocument(idx, canUploadMultiple);
              }}
              sx={{
                zIndex: 1,
                position: 'absolute',
                top: 'calc(100% - 59px)',
                right: 12,
                backgroundColor: 'rgb(51 51 51 / 50%)',
                color: theme.palette.system.white,
                '&:hover': {
                  backgroundColor: 'rgb(51 51 51 / 70%)',
                },
              }}
            >
              <TrashIcon sx={{ width: '18px', height: '18px' }} />
            </IconButton>
            <FileUploadThumbnail file={f} />
          </Box>
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <AlertDialog
        open={error !== FileUploadErrorsEnum.NO_ERROR}
        handleClose={handleCloseError}
        title={errorTypes[error]?.title}
        dismissText="CLOSE"
      >
        <LockoutMessageRow>{errorTypes[error]?.message}</LockoutMessageRow>
      </AlertDialog>

      <Grid
        container
        sx={{ width: '100%' }}
        id={!!id ? `file_upload-${id}` : 'file_upload'}
        data-testid="file-upload-modal_container"
      >
        {filesUploaded?.map((f, idx) =>
          fileUploadedDisplay(f, filesUploaded, canUploadMultiple, idx),
        )}

        {fileUploaded &&
          fileUploadedDisplay(fileUploaded, [fileUploaded], canUploadMultiple)}

        {((canUploadMultiple && (filesUploaded || []).length >= 0) ||
          !fileUploaded) && (
          <Grid
            item
            xs={(filesUploaded || [])?.length % 2 === 1 ? 6 : 12}
            pl={(filesUploaded || [])?.length % 2 === 1 ? '12px' : 0}
            pb={(filesUploaded || [])?.length === 0 ? 0 : '12px'}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                minHeight: '63px',
                backgroundColor: '#009AD90D',
                borderRadius: '6px',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: `2px dashed ${theme.palette.system.skyBlue}`,
                padding: '12px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#E9F0F3',
                },
              }}
              onClick={openFile}
              data-testid="file-upload-modal_input-container"
            >
              <label
                htmlFor={id !== null ? `file_upload-${id}` : 'file_upload'}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexFlow: 'column',
                  cursor: 'pointer',
                }}
              >
                <PlusIcon
                  htmlColor={theme.palette.system.skyBlue}
                  sx={{ width: '17px', height: '17px', mb: '2px' }}
                />
                <Typography
                  variant="body1"
                  color={theme.palette.system.skyBlue}
                  textAlign="center"
                >
                  {label}
                </Typography>
                <input
                  id={!!id ? `file_input-${id}` : 'file_input'}
                  type="file"
                  onChange={onSelectFile}
                  onClick={onInputClick}
                  name={!!id ? `file_upload-${id}` : 'file_upload'}
                  hidden={true}
                  data-testid="file-upload-modal_input"
                />
              </label>
            </Box>
          </Grid>
        )}
        {canUploadMultiple && required && (
          <Grid item xs={12} mt="24">
            <Typography
              variant="body1"
              color={
                isSubmitted && (filesUploaded || []).length === 0
                  ? theme.palette.system.error
                  : theme.palette.system.coolGray
              }
              sx={{
                mt: '12px',
              }}
            >
              <span style={{ color: theme.palette.system.error }}>*</span>
              At least one document is required.
            </Typography>
          </Grid>
        )}
      </Grid>

      {Boolean(fileSrc) &&
        !(
          fileType === allowedFileTypes.pdf ||
          fileType === allowedFileTypes.doc ||
          fileType === allowedFileTypes.docx
        ) && (
          <Dialog
            open={fileEditorOpen}
            onClose={handleCloseFileEditor}
            maxWidth={'md'}
            sx={{
              '& .MuiDialog-paperWidthMd': {
                marginLeft: '24px',
                marginRight: '24px',
              },
            }}
            data-testid="file-upload-modal_cropper-modal"
          >
            <DialogTitle
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                color: `${theme.palette.system.midnightBlue}`,
                lineHeight: '35px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              Crop & Rotate
              <CloseIcon
                onClick={handleCloseFileEditor}
                htmlColor={theme.palette.system.coolGray}
                height="17px"
                width="17px"
              />
            </DialogTitle>
            <DialogContent
              sx={{
                padding: '0px',
                borderTop: `1px solid ${theme.palette.system.inputBackground}`,
                borderBottom: `1px solid ${theme.palette.system.inputBackground}`,
                maxHeight: '450px',
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                className={dialogStyles.cropper}
              >
                <img
                  ref={fileRef}
                  alt="Crop me"
                  src={fileSrc}
                  style={{
                    transform: `scale(1) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </DialogContent>

            <DialogActions
              sx={{
                padding: {
                  xs: `${theme.spacing(2)} ${theme.spacing(2)}`,
                  md: `${theme.spacing(2)} ${theme.spacing(4)}`,
                },
              }}
            >
              <Grid
                container
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                data-testid="file-upload-modal_cropper-btns"
              >
                <Grid item>
                  <Typography
                    onClick={handleCloseFileEditor}
                    variant="body1"
                    color={theme.palette.system.skyBlue}
                    mr={3}
                    letterSpacing="1.25px"
                    lineHeight="16px"
                    fontWeight={600}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    CANCEL
                  </Typography>
                </Grid>

                <Grid item>
                  <AppButton
                    id="rotate-input"
                    variant="primary"
                    type="button"
                    width="100px"
                    disabled={!fileSrc}
                    onClick={handleSetRotate}
                    sx={{
                      marginRight: '6px',
                    }}
                  >
                    Rotate
                  </AppButton>
                </Grid>
                <Grid item ml={2}>
                  <AppButton
                    id="crop-input"
                    type="button"
                    variant="primary"
                    width="100px"
                    disabled={!fileSrc}
                    onClick={handleCrop}
                  >
                    Done
                  </AppButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
};
