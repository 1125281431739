import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ILocationSearchResponse } from 'types/UserPreference';
import { searchLocationState } from './searchLocation';

const initialState: searchLocationState = {
  isLoading: false,
  searchLocations: [],
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  downloadError: false,
};

const searchLocationSlice = createSlice({
  name: 'searchLocationSlice',
  initialState,
  reducers: {
    searchLocation(state, action) {
      state.isLoading = true;
    },

    setSearchLocation(state, action: PayloadAction<ILocationSearchResponse[]>) {
      state.isLoading = false;
      let responseData = action.payload;
      if (!Boolean(responseData)) {
        responseData = [];
      }
      state.searchLocations = responseData;
    },

    setLoading(state, { payload = false }: PayloadAction<boolean | undefined>) {
      state.isLoading = payload;
    },

    setError(state, action: PayloadAction<IErrorResponse>) {
      state.error = true;
      state.errorResponse = {
        errorCode: action.payload.errorCode,
        errorData: action.payload.errorData,
        errorDetails: action.payload.errorDetails,
        errorMessage: action.payload.errorMessage,
        operationId: action.payload.operationId,
        correlationId: action.payload.correlationId,
      };
      state.downloadError = false;
    },
    unSetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {
        errorCode: 0,
        errorData: {},
        errorDetails: '',
        errorMessage: '',
        operationId: '',
        correlationId: '',
      };
      return {
        ...stateValues,
      };
    },
  },
});
export const {
  searchLocation,
  setSearchLocation,
  setLoading,
  setError,
  unSetError,
} = searchLocationSlice.actions;

export default searchLocationSlice.reducer;
