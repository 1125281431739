import { Grid, RadioGroup, Typography } from '@mui/material';
import { RadioButton } from 'components/common/RadioButton/RadioButton';
import { raceEthnicityOptions } from 'constants/raceEthnicityOptions';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { Gender } from 'enums/gender';
import { RaceEthnicity } from 'enums/raceEthnicity';
import { IPayTransparencyRequest } from 'interfaces/User/UserProfile/IPayTransparencyRequest';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/configureStore';
import { patchPayTransparencyDetailsAction } from 'store/slices/user/userProfile/userProfileSlice';
import { theme } from 'styles/theme';

enum IsHispanicOrLatino {
  UNSET = 0,
  YES = 1,
  NO = 2,
}

export const PayTransparencyForm = props => {
  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setActionButtonDisabled,
    setDrawerOpen,
    gender,
    ethnicityType,
  } = props;

  const dispatch = useAppDispatch();
  const [selectedGender, setSelectedGender] = useState<string>('');
  const [isHispanicOrLatino, setIsHispanicOrLatino] =
    useState<IsHispanicOrLatino>(IsHispanicOrLatino.UNSET);
  const [selectedRaceEthnicity, setSelectedRaceEthnicity] = useState<number>(0);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const {
    formState: { isDirty },
  } = methods;

  const handleSetIsHispanicOrLatino = (value: number) => {
    setIsHispanicOrLatino(value);
    if (value === IsHispanicOrLatino.YES) {
      setSelectedRaceEthnicity(RaceEthnicity.HISPANIC_OR_LATINO);
    }
  };

  const raceEthnicityRadioOptions = useMemo(
    () => {
      return raceEthnicityOptions.map((option, i) => {
        return (
          <RadioButton
            key={i}
            value={option.value}
            id={`raceEthnicity-${option.value}`}
            name={`raceEthnicity-${option.value}`}
            radioGroup="raceEthnicityRadioGroup"
            radioLabel={option.description}
            labelStyle={{
              color: theme.palette.system.midnightBlue,
              fontSize: '16px',
              lineHeight: '22px',
              padding: '7.5px 0px',
            }}
            formInputLabelStyle={{ alignItems: 'flex-start' }}
          />
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isDisabled = useMemo(() => {
    let genderValid = selectedGender !== '';
    let isHispanicOrLatinoValid = isHispanicOrLatino > 0;
    let selectedRaceEthnicityValid =
      isHispanicOrLatino === 2
        ? selectedRaceEthnicity !== RaceEthnicity.HISPANIC_OR_LATINO &&
          selectedRaceEthnicity > 0
        : true;
    let isDisabled =
      ((!genderValid ||
        !selectedRaceEthnicityValid ||
        !isHispanicOrLatinoValid) &&
        isDirty) ||
      !isDirty;
    return isDisabled;
  }, [selectedGender, isHispanicOrLatino, selectedRaceEthnicity, isDirty]);

  const submit = useCallback(
    () => {
      let payTransparencyRequest: IPayTransparencyRequest = {
        gender: selectedGender,
        ethnicityTypeId: selectedRaceEthnicity,
      };
      dispatch(patchPayTransparencyDetailsAction(payTransparencyRequest));
      setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGender, selectedRaceEthnicity],
  );

  useEffect(() => {
    if (onSubmitProcess) {
      submit();
      setOnSubmitProcess?.(false);
    }
  }, [onSubmitProcess, setOnSubmitProcess, submit]);

  useEffect(
    () => {
      setActionButtonDisabled(isDisabled);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDisabled],
  );

  useEffect(() => {
    if (gender) {
      setSelectedGender(gender);
    }

    if (ethnicityType && ethnicityType === RaceEthnicity.HISPANIC_OR_LATINO) {
      handleSetIsHispanicOrLatino(IsHispanicOrLatino.YES);
    } else if (
      ethnicityType &&
      ethnicityType !== RaceEthnicity.HISPANIC_OR_LATINO
    ) {
      handleSetIsHispanicOrLatino(IsHispanicOrLatino.NO);
      setSelectedRaceEthnicity(ethnicityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Grid container gap={4} mb={6}>
          <Grid container item gap={2} display="flex" flexDirection="column">
            <Grid item>
              <Typography
                variant="body1"
                color={theme.palette.system.coolGray}
                lineHeight="21px"
              >
                AMN Healthcare believes that our healthcare professionals are
                best able to deliver safe and competent care when we embrace our
                diverse backgrounds, experiences, and perspectives of every
                person encountered.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                color={theme.palette.system.coolGray}
                lineHeight="21px"
              >
                AMN Healthcare is subject to government statistical data
                collection and reporting requirements for the administration of
                civil rights laws and regulations. To comply with these laws,
                AMN, on behalf of the Senate, will need employees to
                self-identify their Gender, Race or Ethnicity.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                color={theme.palette.system.coolGray}
                lineHeight="21px"
              >
                The information obtained will be kept confidential and will only
                be used in accordance with the provisions of applicable laws,
                executive orders, and regulations, including those that require
                the information to be summarized and reported to the federal
                government for civil rights enforcement. When reported, data
                will not identify any specific individual.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                color={theme.palette.system.coolGray}
                lineHeight="21px"
              >
                We recognize that your individual identities may not fit the
                options below, which we are required by law to include and
                collect. Please select the options that most closely align with
                how you identify.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item display="flex" flexDirection="column">
            <Grid item>
              <Typography
                color={theme.palette.system.midnightBlue}
                variant="h4"
              >
                What is your Gender?
              </Typography>
            </Grid>
            <Grid item>
              <RadioGroup
                aria-label="gender-radio-group"
                radioGroup="genderRadioGroup"
                name="gender"
                value={selectedGender}
                onChange={e => setSelectedGender(e.target.value)}
              >
                <RadioButton
                  value={Gender.MALE}
                  id="gender2"
                  name="gender2"
                  radioGroup="genderRadioGroup"
                  radioLabel={'Male'}
                  labelStyle={{
                    color: theme.palette.system.midnightBlue,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                />
                <RadioButton
                  value={Gender.FEMALE}
                  id="gender1"
                  name="gender1"
                  radioGroup="genderRadioGroup"
                  radioLabel={'Female'}
                  labelStyle={{
                    color: theme.palette.system.midnightBlue,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                />
                <RadioButton
                  value={Gender.NON_BINARY}
                  id="gender3"
                  name="gender3"
                  radioGroup="genderRadioGroup"
                  radioLabel={'Nonbinary'}
                  labelStyle={{
                    color: theme.palette.system.midnightBlue,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container item gap={2}>
            <Grid item>
              <Typography
                color={theme.palette.system.midnightBlue}
                variant="h4"
              >
                Are you Hispanic or Latino?
              </Typography>
            </Grid>
            <Grid item>
              <Typography color={theme.palette.system.coolGray} variant="body1">
                A person of Cuban, Mexican, Puerto Rican, South or Central
                American, or other Spanish culture or origin regardless of race.
              </Typography>
            </Grid>
            <Grid item>
              <RadioGroup
                aria-label="is-hispanic-or-radio-group"
                radioGroup="isHispanicOrLatinoRadioGroup"
                name="isHispanicOrLatino"
                value={isHispanicOrLatino}
                onChange={e => handleSetIsHispanicOrLatino(+e.target.value)}
              >
                <RadioButton
                  value={IsHispanicOrLatino.YES}
                  id="hispanicYes"
                  name="hispanicYes"
                  radioGroup="isHispanicOrLatinoRadioGroup"
                  radioLabel={'Yes'}
                  labelStyle={{
                    color: theme.palette.system.midnightBlue,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                />
                <RadioButton
                  value={IsHispanicOrLatino.NO}
                  id="gender2"
                  name="gender2"
                  radioGroup="isHispanicOrLatinoRadioGroup"
                  radioLabel={'No'}
                  labelStyle={{
                    color: theme.palette.system.midnightBlue,
                    fontSize: '16px',
                    lineHeight: '22px',
                  }}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          {isHispanicOrLatino === 2 && (
            <Grid container item gap={2}>
              <Grid item>
                <Typography
                  color={theme.palette.system.midnightBlue}
                  variant="h4"
                >
                  Select your Race/Ethnicity
                </Typography>
              </Grid>
              <Grid item>
                <RadioGroup
                  aria-label="race-ethnicity-radio-group"
                  radioGroup="raceEthnicityRadioGroup"
                  name="raceEthnicity"
                  value={selectedRaceEthnicity}
                  onChange={e => setSelectedRaceEthnicity(+e.target.value)}
                >
                  {raceEthnicityRadioOptions}
                </RadioGroup>
              </Grid>
            </Grid>
          )}
        </Grid>
      </FormProvider>
    </>
  );
};
