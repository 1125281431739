import React from 'react';
import { theme } from 'styles/theme';
import { Grid, Typography } from '@mui/material';
import {
  PseudoSlantedDiv,
  PseudoSlantedRoundedDiv,
  SlopedButton,
} from './ProgressTrackerButton.styled';

interface IButtonTabObj {
  key: number;
  name: string;
  id: number[];
  message?: string | null;
  isSelected?: boolean;
}

interface IProgressTrackerButtonProps {
  isSequenceBased?: boolean;
  hoverEffects?: boolean;
  isSlantedView?: boolean;
  tabBackgroundStyle?: any;
  contentTextSize?: string;
  barHeight?: string;
  barContentMargin?: string;
  messageTextStyle?: any;
  messageTextVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'subtitle1'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit'
    | 'body3'
    | undefined;
  title?: string | undefined;
  titleStyle?: any;
  buttonTabs: IButtonTabObj[];
  activeTabId: number;
  expandedProgressBarWidth?: string;
  collapsedProgressBarWidth?: string;
}

interface IMouseHoverDataProps {
  id: number;
  hovered: boolean;
  activeId: number[];
  completed: boolean;
}

const ProgressTrackerButton: React.FC<IProgressTrackerButtonProps> = props => {
  const {
    isSequenceBased = false,
    hoverEffects = false,
    isSlantedView = true,
    contentTextSize = '10px',
    barHeight,
    barContentMargin,
    tabBackgroundStyle,
    messageTextStyle,
    messageTextVariant,
    title,
    titleStyle,
    buttonTabs,
    activeTabId,
    expandedProgressBarWidth = '120px',
    collapsedProgressBarWidth = '20px',
  } = props;
  const [mouseHoveredData, setMouseHoveredData] = React.useState(
    {} as IMouseHoverDataProps[],
  );
  const [activeApplicationMsg, setActiveApplicationMsg] = React.useState('');
  const activeTab = buttonTabs?.find((val: IButtonTabObj | undefined) =>
    val?.id?.includes(activeTabId),
  )?.key as number;

  const setHoveredData = val => {
    let isHovered: boolean = false;
    if (isSequenceBased && val.isSelected !== undefined) {
      isHovered = val.isSelected;
    } else {
      isHovered = val?.id?.includes(activeTabId);
    }
    return isHovered;
  };

  React.useEffect(() => {
    setMouseHoveredData(
      buttonTabs?.map((val, index) => {
        return {
          id: val.key,
          hovered: setHoveredData(val),
          activeId: val?.id,
          completed:
            isSequenceBased && val.isSelected !== undefined
              ? val.isSelected
              : val?.key <= (activeTab || 0),
        };
      }),
    );
    setActiveApplicationMsg(
      buttonTabs?.find((val: IButtonTabObj | undefined) =>
        val?.id?.includes(activeTabId),
      )?.message as string,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonTabs]);

  const updateFieldsOnMouseEnter = (key: number, responseIds: number[]) => {
    if (hoverEffects) {
      let newArr = [...mouseHoveredData];
      newArr = newArr?.map((val: IMouseHoverDataProps, index: number) => {
        return {
          ...val,
          hovered: key === index,
        };
      });
      setMouseHoveredData(newArr);
      setActiveApplicationMsg(
        buttonTabs?.find((val: IButtonTabObj | undefined) => val?.key === key)
          ?.message as string,
      );
    }
  };

  const updateFieldsOnMouseLeave = (key: number, responseIds: number[]) => {
    if (hoverEffects) {
      let newArr = [...mouseHoveredData];
      newArr = newArr?.map((val: IMouseHoverDataProps, index: number) => {
        return {
          ...val,
          hovered: val?.activeId?.includes(activeTabId) ? true : false,
        };
      });
      setMouseHoveredData(newArr);
      setActiveApplicationMsg(
        buttonTabs?.find((val: IButtonTabObj | undefined) =>
          val?.id?.includes(activeTabId),
        )?.message as string,
      );
    }
  };
  const getTextColorBasedonActiveInactive = (index: number) => {
    let color: string | undefined = theme.palette.system.white;
    if (mouseHoveredData[index]?.hovered) {
      if (mouseHoveredData[index]?.completed) {
        color = theme.palette.system.white;
      } else {
        color = theme.palette.system.midnightBlue;
      }
    } else {
      if (hoverEffects) {
        color = 'transparent';
      } else {
        if (mouseHoveredData[index]?.completed) {
          if (index === activeTab) {
            color = theme.palette.system.white;
          } else {
            color = theme.palette.system.navIconGray;
          }
        } else {
          color = theme.palette.system.midnightBlue;
        }
      }
    }
    return color;
  };

  const getWidthValue = (index: number) => {
    let width = '';
    width = hoverEffects
      ? mouseHoveredData[index]?.hovered
        ? expandedProgressBarWidth
        : collapsedProgressBarWidth
      : expandedProgressBarWidth;
    return width;
  };

  return (
    <Grid
      display={'flex'}
      item
      id={'progress-tracker-button-' + props.title}
      pt={4}
      px={4}
      pb={4}
      xs={12}
      sx={tabBackgroundStyle}
    >
      {title?.length ? (
        <Typography sx={titleStyle} data-testid="title">
          {title}
        </Typography>
      ) : (
        <></>
      )}
      <Grid
        display="flex"
        position="relative"
        width="100%"
        height={barHeight ? barHeight : '29px'}
        overflow="hidden"
      >
        {buttonTabs.map((val: IButtonTabObj, index: number) => (
          <SlopedButton
            id={'sloped-button-' + index}
            barHeight={barHeight}
            isSlantedView={isSlantedView}
            position={index}
            totalTabs={buttonTabs?.length || 0}
            isActiveTab={val?.id?.includes(activeTabId)}
            responseActiveTabId={activeTabId}
            buttonTabId={val?.id}
            className={`button_class_${activeTabId}`}
            onMouseOver={() => {
              updateFieldsOnMouseEnter(index, val?.id);
            }}
            onMouseLeave={() => {
              updateFieldsOnMouseLeave(index, val?.id);
            }}
            bgColor={
              mouseHoveredData[index]?.completed
                ? theme.palette.system.midnightBlue
                : theme.palette.system.navIconGray
            }
            isActive={mouseHoveredData[index]?.completed ? true : false}
            width={getWidthValue(index)}
          >
            {(isSequenceBased ? index >= 0 : index > 0) &&
              index < buttonTabs.length - 1 && (
                <PseudoSlantedDiv
                  barHeight={barHeight}
                  bgColor={
                    mouseHoveredData[index]?.completed
                      ? theme.palette.system.midnightBlue
                      : theme.palette.system.navIconGray
                  }
                  isActive={mouseHoveredData[index]?.completed ? true : false}
                />
              )}
            {index === buttonTabs.length - 1 && (
              <PseudoSlantedRoundedDiv
                barHeight={barHeight}
                bgColor={
                  mouseHoveredData[index]?.completed
                    ? theme.palette.system.midnightBlue
                    : theme.palette.system.navIconGray
                }
                isActive={mouseHoveredData[index]?.completed ? true : false}
              />
            )}
            <Typography
              position={'absolute'}
              width="100%"
              display={'flex'}
              color={() => getTextColorBasedonActiveInactive(index)}
              fontSize={contentTextSize}
              fontWeight="bold"
              sx={{
                textAlign: 'center',
                margin: barContentMargin
                  ? barContentMargin
                  : !hoverEffects
                  ? '7px 0 0'
                  : '5.5px 0 0',
                transform: isSlantedView
                  ? index > 0 && index < buttonTabs.length - 1
                    ? 'skewX(45deg)'
                    : 'skewX(0deg)'
                  : 'skewX(0deg)',
                overflow: 'hidden',
                justifyContent: 'center',
                paddingRight: index === 0 ? '14px' : '0px',
              }}
              data-testid="button-name"
            >
              {val.name}
            </Typography>
          </SlopedButton>
        ))}
      </Grid>
      {activeApplicationMsg?.length ? (
        <Grid marginTop="12px">
          <Typography
            sx={messageTextStyle}
            variant={messageTextVariant || 'body1'}
            data-testid="application-message"
          >
            {activeApplicationMsg || ''}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default ProgressTrackerButton;
