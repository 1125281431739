import React from 'react';
import { theme } from 'styles/theme';

export default function QuestionsIcon({
  color = theme.palette.system.skyBlue,
  height = '6',
  width = '6',
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Component_1_1"
      data-name="Component 1 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <rect
        id="Rectangle_2352"
        data-name="Rectangle 2352"
        width={width}
        height={height}
        fill={color}
        opacity="0"
      />
      <g
        id="Group_8903"
        data-name="Group 8903"
        transform="translate(0.474 0.474)"
      >
        <path
          id="Ellipse_1456"
          data-name="Ellipse 1456"
          d="M7.526-1a8.526,8.526,0,0,1,8.526,8.526,8.526,8.526,0,0,1-8.526,8.526A8.526,8.526,0,0,1-1,7.526,8.526,8.526,0,0,1,7.526-1Zm0,15.5A6.976,6.976,0,1,0,.55,7.526,6.984,6.984,0,0,0,7.526,14.5Z"
          transform="translate(1 1)"
          fill={color}
        />
        <path
          id="Path_6622"
          data-name="Path 6622"
          d="M11.059,12.2a.775.775,0,0,1-.245-1.511c.464-.157,1.8-.789,1.8-1.59A1.55,1.55,0,0,0,9.6,8.581a.775.775,0,1,1-1.462-.514A3.1,3.1,0,0,1,14.159,9.1a2.865,2.865,0,0,1-1.508,2.388,6.289,6.289,0,0,1-1.348.672A.775.775,0,0,1,11.059,12.2Z"
          transform="translate(-2.594 -2.123)"
          fill={color}
        />
        <path
          id="Line_210"
          data-name="Line 210"
          d="M-.217.55H-.225A.775.775,0,0,1-1-.225.775.775,0,0,1-.225-1h.008a.775.775,0,0,1,.775.775A.775.775,0,0,1-.217.55Z"
          transform="translate(8.751 12.626)"
          fill={color}
        />
      </g>
    </svg>
  );
}
