import React, { FC, useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, Paper, TextField } from '@mui/material';
import { get, useFormContext } from 'react-hook-form';
import SearchIcon from 'assets/icons/Search/SearchIcon';
import { ArrowDownSvg } from 'assets/icons/Controls/ArrowDownSvg';

export interface ICustomAutocompleteProps {
  label?: string;
  name?: string;
  options?: any[];
  optionLabel?: string;
  value?: string;
  valueLabel?: string;
  onChange?: any;
  freeSolo?: boolean;
  searchIcon?: boolean;
  maxListHeight?: string;
  defaultValue?: any;
}

const CustomPaper = props => {
  return <Paper elevation={3} {...props} />;
};

export const CustomAutocomplete: FC<ICustomAutocompleteProps> = ({
  label,
  name,
  options,
  optionLabel,
  value,
  valueLabel,
  onChange,
  freeSolo,
  searchIcon,
  defaultValue,
  maxListHeight = 'auto',
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const getSelectedValue = () =>
    options?.find(o => o[valueLabel ?? ''] === value);

  const [selectedValue, setSelectedValue] = useState(getSelectedValue());
  useEffect(() => {
    setSelectedValue(getSelectedValue());
  }, [value]);
  const error = get(errors, name ?? '');

  const NoSearchIcon = () => {
    if (searchIcon) {
      return (
        <Box
          sx={{
            position: 'absolute',
            transform: 'translate(14px, 13px)',
            zIndex: 1200,
          }}
        >
          <SearchIcon size={22} />
        </Box>
      );
    }
    return <></>;
  };

  return (
    <Grid item position="relative">
      {NoSearchIcon()}
      <Autocomplete
        ListboxProps={{ style: { maxHeight: maxListHeight } }}
        aria-required="true"
        id={`combo-box-${name}`}
        freeSolo={freeSolo}
        options={options ?? []}
        value={selectedValue}
        defaultValue={defaultValue}
        PaperComponent={CustomPaper}
        autoHighlight
        getOptionLabel={option => {
          return option[optionLabel ?? ''];
        }}
        popupIcon={<ArrowDownSvg />}
        sx={{
          '& label': {
            left: !searchIcon ? 0 : '40px',
          },
          '& .MuiInputBase-root > input': {
            paddingLeft: !searchIcon ? 0 : '44px !important',
          },
          '& .MuiSvgIcon-root': {
            margin: '5px 5px',
            height: '18px',
          },
        }}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            error={!!error}
            helperText={error ? error?.message : ''}
            variant="filled"
            onChange={onChange}
          />
        )}
      />
    </Grid>
  );
};
