export enum DataLayerEvent {
  DL_READY = 'dlready',
  FORM_COMPLETE = 'form_complete',
  JOB_SEARCH = 'job_search',
  EEC_CLICK = 'eec.impressionClick',
  EEC_ADD = 'eec.add',
  EEC_PURCHASE = 'eec.purchase',
}

export enum DataLayerFormType {
  CREATE_ACCOUNT = 'create_account',
  JOB_PREFERENCES = 'job_preferences',
  TIME_ENTRY = 'time_entry',
  ADD_CREDENTIAL = 'add_credential',
  REQUEST_REIMBURSEMENT = 'request_reimbursement',
  APPLICATION = 'application',
}

export enum DataLayerPageName {
  HOME = 'home',
  CONTACTS = 'contacts',
  JOB_SEARCH = 'job_search',
  ASSIGNMENTS = 'assignments',
  CREDENTIALS = 'credentials',
  SKILLS_CHECKLISTS = 'skills_checklists',
  RESOURCES = 'resources',
  PREFERENCES = 'preferences',
  SHARE_AND_REFER = 'share_and_refer',
  PAYROLL = 'payroll',
  TIME_ENTRY = 'time_entry',
  REIMBURSEMENTS = 'reimbursements',
  FAQ = 'faq',
  PROFILE = 'profile',
}

export enum DataLayerImpressionList {
  TOP_JOBS = 'top_jobs',
  JOB_SEARCH = 'job_search',
}
