import { LinkType } from 'enums/linkConfig';
import { ResourceCategories } from 'enums/resources';
import {
  IErrorResponse,
  IErrorResponseData,
} from 'interfaces/Common/IErrorResponse';
import { IResources } from 'interfaces/Resources/IResources';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  fetchResources,
  fetchFormSubmissions,
  fetchLatestFormSubmission,
  fetchFormById,
  fetchFormByUrlPath,
} from 'services/resources/resourcesService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { setError } from 'store/slices/error/errorSlice';
import { resourcesActions } from 'store/slices/resources/resourcesSlice';
import { downloadPDFSaga, openPDFSaga } from '../domain/domainSaga';
import { ILinkConfig } from 'interfaces/Common/ILink';
import { TOAST_MESSAGE } from 'constants/helperText';

export const userIdSelector = (state: RootState) => state.auth.userId;
export const formSubmissionSelector = (state: RootState) =>
  state.resources.formsResources;

const link: ILinkConfig = {
  title: 'Reimbursement Request',
  url: 'reimbursements',
  type: 'InAppLink',
  typeId: LinkType.InAppLink,
};

const defaultPayRoll: IResources = {
  category: 'Payroll, Tax and Reimbursements',
  categoryId: ResourceCategories.Payroll,
  id: 'reimbursement-request',
  subcategory: 'Reimbursements',
  subcategoryId: 50,
  title: 'Reimbursement Request',
  type: LinkType.InAppLink,
  typeId: LinkType.InAppLink,
  links: [link],
};

export function* getCardFormatResourcesSaga() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchResources, { userId });
    const { data } = response;
    if (data) {
      data.items.unshift(defaultPayRoll);
      yield put(resourcesActions.getCardFormattedResources({ ...data }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
  }
}

function* getFormSubmissionsSaga() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchFormSubmissions, { userId });
    const { data } = response;
    if (data) {
      yield put(resourcesActions.setFormSubmissions({ ...data }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

export function* loadMoreFormSubmissionSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const existingFormSubmission = yield select(formSubmissionSelector);
    const response = yield call(fetchFormSubmissions, {
      userId,
      offset: action.payload.offset,
      limit: action.payload.limit,
    });
    const { data } = response;
    if (data) {
      const existingItems = existingFormSubmission.items;
      const responseItems = data.items;
      const finalResult = [...existingItems, ...responseItems] as any;
      yield put(
        resourcesActions.setFormSubmissions({
          items: finalResult,
          paging: data.paging,
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

function* getLatestFormSubmissionSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchLatestFormSubmission, {
      userId,
      urlKey: action?.payload?.urlKey,
    });
    const { data } = response;

    if (data) {
      yield put(
        resourcesActions.setLatestFormSubmission({ ...data, hasForm: true }),
      );
    } else {
      yield put(resourcesActions.setLatestFormSubmission({ hasForm: false }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

function* getFormByIdSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchFormById, {
      userId,
      id: action?.payload,
    });
    const { data } = response;

    if (data) {
      yield call(openPDFSaga, { payload: data?.url });
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;

    yield put(
      openAlert({
        variant: 'error',
        message: err?.message,
      }),
    );
  }
}

function* downloadResourceByIdSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchFormById, {
      userId,
      id: action?.payload,
    });
    const { data } = response;

    if (data) {
      yield call(downloadPDFSaga, {
        payload: { url: data?.url, fileName: data?.formTitle },
      });
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;

    yield put(
      openAlert({
        variant: 'error',
        message: err?.message,
      }),
    );
  }
}

function* getFormSubmissionByIdSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchFormById, {
      userId,
      id: action?.payload,
    });
    const { data } = response;

    if (data) {
      yield call(resourcesActions.getLatestFormSubmission, {
        urlKey: data?.urlKey,
      });

      yield put(
        resourcesActions.setLatestFormSubmission({ ...data, hasForm: true }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;

    yield put(
      openAlert({
        variant: 'error',
        message: err?.message,
      }),
    );
  }
}

export function* getFormstackResourcesDataSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchFormByUrlPath, {
      userId,
      urlKey: action.payload,
    });
    const { data } = response;

    if (data) {
      yield put(resourcesActions.setOpenFormContent(data?.url));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;

    yield put(
      openAlert({
        variant: 'error',
        message: err.message,
      }),
    );
  }
}

export function* resourcesSaga() {
  yield all([
    takeLatest(resourcesActions.getFormById.type, getFormByIdSaga),
    takeEvery(
      resourcesActions.downloadResourceById.type,
      downloadResourceByIdSaga,
    ),
    takeLatest(
      resourcesActions.getFormSubmissionById.type,
      getFormSubmissionByIdSaga,
    ),
    takeLatest(
      resourcesActions.getLatestFormSubmission.type,
      getLatestFormSubmissionSaga,
    ),
    takeLatest(
      resourcesActions.getFormSubmissions.type,
      getFormSubmissionsSaga,
    ),
    takeLatest(
      resourcesActions.getResourcesAction.type,
      getCardFormatResourcesSaga,
    ),
    takeLatest(
      resourcesActions.getFormstackAction.type,
      getFormstackResourcesDataSaga,
    ),
    takeLatest(
      resourcesActions.loadMoreFormSubmissions.type,
      loadMoreFormSubmissionSaga,
    ),
  ]);
}
