import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import { GeneralErrorBoundary } from 'components/ErrorBoundaries/GeneralErrorBoundary';

export const Sso = () => {
  const ssoObject = useSelector((state: RootState) => state.sso);
  const formRef = React.createRef<HTMLFormElement>();

  useEffect(() => {
    formRef.current?.submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoObject.tokenResponse.samlToken, ssoObject.tokenResponse.targetUrl]);

  return (
    <GeneralErrorBoundary>
      <div>
        <form
          name="frm1"
          action={ssoObject.tokenResponse.targetUrl}
          method="POST"
          ref={formRef}
        >
          <input
            type="hidden"
            name="TARGET"
            value={ssoObject.tokenResponse.targetUrl}
          />
          <input
            type="hidden"
            name="SAMLResponse"
            value={ssoObject.tokenResponse.samlToken}
          />
        </form>
      </div>
    </GeneralErrorBoundary>
  );
};
