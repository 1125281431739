import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import profileIcon from 'assets/icons/Profile/Controls-icon-Profile.svg';
import { AvatarTextVariant } from 'enums/avatarTextVariant';
import {
  generateAvatarShortText,
  isHSG,
} from 'helpers/generateAvatarShortText';
import { useGetAuthenticatedImage } from 'helpers/getAuthenticatedImage';
import { IContactCardImage } from 'interfaces/Props/IContactCardImage';
import React from 'react';
import { theme } from 'styles/theme';
import { ContactCardImageStyles } from './ContactCardImage.styles';

export const enum AvatarVariant {
  image = 'image',
  text = 'text',
}

export const ContactCardImage = ({
  id,
  size = '60px',
  image,
  avatarVariant = AvatarVariant.image,
  avatarText = 'I',
  avatarBackgoundColor = theme.palette.system.paleBlue,
  avatarTextColor = theme.palette.system.white,
  avatarTextSize = '24px',
  avatarImage = profileIcon,
  sx,
}: IContactCardImage) => {
  const contactCardImageStyles = ContactCardImageStyles();
  const contactImg = useGetAuthenticatedImage(image, { width: 60 });
  const variant = isHSG(avatarText)
    ? AvatarTextVariant.HSG
    : AvatarTextVariant.FIRST_LAST;

  const handleImageError = event => {
    event.target.style.display = 'none';
  };

  return (
    <Box
      id={id}
      className={contactCardImageStyles.imageBox}
      sx={{
        backgroundColor: avatarBackgoundColor,
        height: size,
        width: size,
        border: `1px solid ${theme.palette.system.gray}`,
        overflow: 'hidden',
        position: 'relative',
        ...sx,
      }}
    >
      <Typography
        className={contactCardImageStyles.avatarText}
        position="absolute"
        sx={{ color: avatarTextColor, fontSize: avatarTextSize }}
      >
        {generateAvatarShortText(avatarText, variant)}
      </Typography>
      {contactImg && (
        <img
          onError={handleImageError}
          src={
            contactImg
              ? contactImg
              : avatarVariant === AvatarVariant.image
              ? avatarImage
              : ''
          }
          style={{ width: size, position: 'absolute' }}
          alt=""
        />
      )}
    </Box>
  );
};
