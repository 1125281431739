import { useEffect } from 'react';
import { isSafariOrChromeIos } from 'helpers/openInNewTab';
import { useFormContext } from 'react-hook-form';

export default function useScrollToValidation(props: { name: string }) {
  const {
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (!isSafariOrChromeIos) {
      return;
    }

    /*
      This hook is used to fix the behavior of react hook form not
      behaving properly in Safari or Chrome running in iOS;
      when there's at least one error in react hook form, we want
      to focus on the first field that failed validation.
    */
    const getFirstError = (errors, key) => {
      if (errors.ref) {
        return { key, error: errors };
      }
      const cur = Object.keys(errors).reduce((field, a) => {
        return !!errors[field] ? field : a;
      }, '');

      if (cur) {
        return getFirstError(errors[cur], key === '' ? cur : key + '.' + cur);
      }
      return key;
    };

    const firstError = getFirstError(errors, '');
    if (firstError && props.name === firstError.key) {
      // scroll to the first error
      const ele = document.getElementById(firstError?.error?.ref?.id);
      ele?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [errors, props.name]);
}
