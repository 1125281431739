import * as React from 'react';
import { theme } from 'styles/theme';

export default function AlertIcon({
  color = theme?.palette?.system?.error,
  height = '24.001',
  width = '24',
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls_icon_Alert"
      data-name="Controls/icon/Alert"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24.001"
    >
      <path
        id="Path_448"
        data-name="Path 448"
        d="M26.635,14.455a12,12,0,1,0,12,12A12.014,12.014,0,0,0,26.635,14.455Zm0,22.048A10.048,10.048,0,1,1,36.682,26.455,10.059,10.059,0,0,1,26.635,36.5Z"
        transform="translate(-14.635 -14.455)"
        fill={color}
      />
      <path
        id="Path_449"
        data-name="Path 449"
        d="M29.637,29.865a.888.888,0,0,0,.888-.888V22.719a.888.888,0,1,0-1.775,0v6.258A.888.888,0,0,0,29.637,29.865Z"
        transform="translate(-17.637 -15.766)"
        fill={color}
      />
      <ellipse
        id="Ellipse_243"
        data-name="Ellipse 243"
        cx="1.065"
        cy="1.065"
        rx="1.065"
        ry="1.065"
        transform="translate(10.935 15.31)"
        fill={color}
      />
    </svg>
  );
}
