import makeStyles from '@mui/styles/makeStyles';

const subHeaderBar = {
  marginTop: 0,
  marginLeft: 0,
};

export const createLeftScrollPageStyles = makeStyles(theme => ({
  leftScrollContainer: {
    maxWidth: '1370px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    maxHeight: 'inherit',
    [theme?.breakpoints?.down('md')]: {
      padding: '24px ',
    },
  },
  filterHeader: {
    padding: 0,
  },
  scrollContainer: {
    maxHeight: 'calc(100vh - 82px)',
    [theme?.breakpoints?.down('md')]: {
      paddingLeft: '0px !important',
      paddingTop: '0px !important',
      maxHeight: '100%',
    },
  },
  quickFilter: subHeaderBar,
}));

export const createRightScrollPageStyles = makeStyles(theme => ({
  rightScrollContainer: {
    maxWidth: '1284px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    maxHeight: 'inherit',
    [theme?.breakpoints?.down('md')]: {
      padding: '24px ',
    },
  },
  scrollContainer: {
    paddingRight: '24px',
  },
  actionBar: {
    maxWidth: '1284px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    borderBottom: `1px solid ${theme.palette.system.border}`,
    height: '90px',
  },
}));

export const createCardListPageStyles = makeStyles(theme => ({
  cardContainer: {
    maxWidth: '980px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    justifyContent: 'center',
  },
  cardNavItem: {},
  scrollContainer: {
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme?.breakpoints?.up('md')]: {
      maxWidth: '351px',
    },
    [theme?.breakpoints?.down('sm')]: {
      paddingRight: theme?.spacing(3),
      maxHeight: '100%',
    },
  },
}));

export const createDetailFeatureStyles = makeStyles(theme => ({
  detailFeatureContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '1370px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0',
    maxHeight: 'inherit',
    padding: '0px',
    [theme?.breakpoints?.up('xs')]: {
      padding: '24px',
    },
  },
  subHeaderBar: subHeaderBar,
}));
