import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components/macro';
import { theme } from 'styles/theme';

const AMNCardContainer = styled.div`
  margin-top: 12px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  > div {
    height: 100% !important;
  }

  .amn-card {
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 12px;
    .card-img {
      width: 69px;
      border-radius: 10px;
    }

    &.show-more-btn {
      width: 100%;
      border-radius: 4px;
      background: ${theme.palette.system.white} 0% 0% no-repeat padding-box;
      text-transform: none;
      border: 2px solid transparent;
      color: ${theme.palette.system.skyBlue};
    }

    .amn-badge {
      background: ${theme.palette.system.grassGreen} 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 0.9;
      color: ${theme.palette.system.white};
      padding: 5px;
      display: flex;
      align-items: center;
    }

    .card-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .card-price {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
    }
  }
`;

export const createJobSearchStyles = makeStyles({
  filterHeader: {
    width: '100%',
    backgroundColor: theme.palette.system.inputBackground,
  },
  rightContainer: {
    display: 'flex',
  },
  mapFilter: {
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '0 24px !important',
    },
  },
  customScroll: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    scrollbarWidth: 'none',
    width: '100%',
    padding: '0px 0px 49px 0px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
});

export { AMNCardContainer };
