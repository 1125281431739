import React from 'react';
import { theme } from 'styles/theme';

export default function InfoIcon(
  color?: string,
  height?: string,
  width?: string,
) {
  return (
    <svg
      id="Controls_Icon_Info"
      data-name="Controls/Icon/Info"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <g id="Group_2953" data-name="Group 2953" transform="translate(-11 -11)">
        <rect
          id="Rectangle_2346"
          data-name="Rectangle 2346"
          width="38"
          height="38"
          transform="translate(11 11)"
          fill="none"
          opacity="0"
        />
        <g
          id="Group_2952"
          data-name="Group 2952"
          transform="translate(-2.635 -2.455)"
        >
          <path
            id="Path_3846"
            data-name="Path 3846"
            d="M32.636,50.456a18,18,0,1,0-18-18A18.02,18.02,0,0,0,32.636,50.456Zm0-33.072A15.072,15.072,0,1,1,17.564,32.456,15.088,15.088,0,0,1,32.636,17.384Z"
            transform="translate(0 0)"
            fill={color ?? theme.palette.system.coolGray}
          />
          <g
            id="Group_2951"
            data-name="Group 2951"
            transform="translate(30.878 22.688)"
          >
            <path
              id="Path_3847"
              data-name="Path 3847"
              d="M30.214,27.2a1.465,1.465,0,0,0-1.464,1.464V38.994a1.464,1.464,0,1,0,2.929,0V28.669A1.465,1.465,0,0,0,30.214,27.2Z"
              transform="translate(-28.457 -20.501)"
              fill={color ?? theme.palette.system.coolGray}
            />
            <circle
              id="Ellipse_606"
              data-name="Ellipse 606"
              cx="1.757"
              cy="1.757"
              r="1.757"
              fill={color ?? theme.palette.system.coolGray}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
