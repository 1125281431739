import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { get } from 'react-hook-form';
import { theme } from 'styles/theme';

export const ErrorAdornment = (name: string, errors: any) => {
  const adornment = (
    <InputAdornment position="end">
      <ErrorOutlineOutlinedIcon
        style={{ color: `${theme.palette.system.error}` }}
      ></ErrorOutlineOutlinedIcon>
    </InputAdornment>
  );
  if (errors && (!!errors[name] || get(errors, name))) {
    return adornment;
  }
};
