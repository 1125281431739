import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAcceptTerms, ITerms } from './termsState';

const initialState: ITerms = {
  acceptedTerms: undefined,
  items: [{ terms: '' }],
  paging: {},
};
const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    acceptTermsAndConditions(state, action: PayloadAction<IAcceptTerms>) {},
    fetchTerms() {},
    setTerms(state, action: PayloadAction<ITerms>) {
      const items = action.payload.items;
      return { ...state, items };
    },
    termsAccepted(state) {
      return {
        ...state,
        acceptedTerms: true,
      };
    },
  },
});
export const { acceptTermsAndConditions, fetchTerms, setTerms, termsAccepted } =
  termsSlice.actions;
export default termsSlice.reducer;
