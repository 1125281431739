import React, { useMemo } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { AssignmentLink } from 'components/AssignmentDetail/AssignmentLink';
import { WISELY_PAY_URL } from 'constants/passportUrls';
import { actionItemTypeId } from 'enums/actionType';
import { getFormattedDateOnly } from 'helpers/dateFormatter';
import { openInNewTab } from 'helpers/openInNewTab';

export const ActionDialogBody = ({
  actionItemType,
  name,
  expirationDate,
  title,
  onBodyActionClick,
}: {
  actionItemType: actionItemTypeId;
  name?: string;
  expirationDate?: string;
  title?: string;
  onBodyActionClick?: (e) => void;
}) => {
  const getBody = useMemo(() => {
    if (
      actionItemType ===
      actionItemTypeId.StrikeWiselyPayCardExistingPayCardHolder
    ) {
      if (name?.includes('Non Existing') || title?.includes('Opt-in')) {
        return (
          <Grid container id={'action-dialog-body-' + title}>
            <Grid item>
              <Typography
                variant="subtitle1"
                color="system.coolGray"
                textAlign="center"
                aria-label="optin-optout-text"
              >
                Would you like to Opt-In/Opt-Out of the Wisely Pay Card? Please
                make your Wisely Pay Card decision by
              </Typography>
              <Typography
                variant="subtitle1"
                color="system.coolGray"
                textAlign="center"
              >
                {expirationDate
                  ? getFormattedDateOnly(expirationDate, 'MM-DD-YYYY')
                  : ''}
                .
              </Typography>
            </Grid>
            <Grid pt={2} xs={12} justifyContent="center" item>
              <AssignmentLink
                id="learn-more-link"
                underline="hover"
                justifyContent="center"
                onClick={() => {
                  openInNewTab(WISELY_PAY_URL);
                }}
              >
                Learn More
              </AssignmentLink>
              <Grid
                container
                xs={12}
                pt={2}
                justifyContent="center"
                display={'flex'}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="wisely-pay-card"
                    name="wiselyPayCard"
                    onChange={e => {
                      if (onBodyActionClick) {
                        onBodyActionClick(e?.target?.value);
                      }
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="secondary" />}
                      label="Opt In"
                      sx={{
                        color: 'system.midnightBlue',
                        fontSize: 20,
                        '& .MuiTypography-root': {
                          fontSize: '16px',
                        },
                      }}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="secondary" />}
                      label="Opt Out"
                      sx={{
                        color: 'system.midnightBlue',
                        fontSize: 20,
                        '& .MuiTypography-root': {
                          fontSize: '16px',
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container>
            <Grid item>
              <Typography
                variant="subtitle1"
                color="system.coolGray"
                textAlign="center"
                aria-label="non-optin-optout-text"
              >
                Please bring your Wisely Pay Card to the Event Check-in. If you
                are unable to find the card, we can reissue you a new card.
              </Typography>
            </Grid>
            <Grid pt={2} xs={12} justifyContent="center" item>
              <AssignmentLink
                id="learn-more-link"
                underline="hover"
                justifyContent="center"
                onClick={() => {
                  openInNewTab(WISELY_PAY_URL);
                }}
              >
                Learn More
              </AssignmentLink>
            </Grid>
          </Grid>
        );
      }
    }
  }, [actionItemType, expirationDate, name, onBodyActionClick, title]);
  return <>{getBody}</>;
};
