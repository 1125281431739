import { Box, Divider, Grid } from '@mui/material';
import { CallIcon } from 'assets/icons/Contacts/CallIcon';
import { MailIcon } from 'assets/icons/Contacts/MailIcon';
import { TextIcon } from 'assets/icons/Contacts/TextIcon';
import { AppLink } from 'components/common/Link/Link';
import { formatPhoneNumber } from 'helpers/formatUSPhoneNumber';
import React from 'react';

const ContactDetails = ({
  emailAddress,
  phoneNumber,
  smsNumber,
}: {
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  smsNumber: string | undefined;
}) => {
  const emailBreak = (emailAddress: string) => {
    // 29 characters is the length that causes word wrap with current spacing
    if (emailAddress.length > 29) {
      let a = emailAddress.split('@');
      return (
        <span>
          {`${a[0]}`}
          <wbr></wbr>
          {`@${a[1]}`}
        </span>
      );
    }
    return emailAddress;
  };

  return (
    <Grid
      container
      item
      sx={{
        width: 'auto',
        marginTop: '24px',
      }}
    >
      <Grid
        item
        display={'flex'}
        flexDirection={'row'}
        sx={{
          wordBreak: 'break-word',
        }}
        data-testid="email-id"
      >
        {emailAddress && (
          <>
            <MailIcon
              sx={{
                color: 'system.skyBlue',
                width: '18px',
                height: '18px',
                mr: 1,
              }}
            />
            <AppLink component={'a'} href={`mailto:${emailAddress}`}>
              {emailAddress ? emailBreak(emailAddress) : '-'}
            </AppLink>
          </>
        )}
      </Grid>
      {phoneNumber && (
        <>
          <Divider sx={{ mt: 2, mb: 2, width: '100%' }} />
          <Grid
            item
            display={'flex'}
            flexDirection={'row'}
            sx={{
              wordBreak: 'break-word',
            }}
            data-testid="phone-number"
          >
            <CallIcon
              sx={{
                color: 'system.skyBlue',
                width: '18px',
                height: '18px',
                mr: 1,
              }}
            />
            <AppLink component={'a'} href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </AppLink>
          </Grid>
        </>
      )}
      {smsNumber && (
        <>
          <Divider
            sx={{
              mt: 2,
              mb: 2,
              width: '100%',
              display: { xs: 'flex', sm: 'none' },
            }}
          />
          <Grid
            item
            display={'flex'}
            flexDirection={'row'}
            sx={{
              wordBreak: 'break-word',
            }}
            data-testid="sms-number"
          >
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <TextIcon
                sx={{
                  color: 'system.skyBlue',
                  width: '18px',
                  height: '18px',
                  mr: 1,
                }}
              />
              <AppLink component={'a'} href={`sms:${smsNumber}`}>
                {smsNumber ? formatPhoneNumber(smsNumber) : '-'}
              </AppLink>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ContactDetails;
