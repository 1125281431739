import React from 'react';
import { createSvgIcon } from '@mui/material';

export const SuccessIcon = createSvgIcon(
  <svg
    id="Sucess"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      id="Rectangle_2269"
      data-name="Rectangle 2269"
      width="24"
      height="24"
      fill="#5fb661"
      opacity="0"
    />
    <path
      id="Path_3735"
      data-name="Path 3735"
      d="M26,14.635A11.368,11.368,0,1,0,37.372,26,11.382,11.382,0,0,0,26,14.635Zm0,20.887A9.519,9.519,0,1,1,35.522,26,9.53,9.53,0,0,1,26,35.522Z"
      transform="translate(-14.003 -14.003)"
      fill="#5fb661"
    />
    <path
      id="Path_45625"
      data-name="Path 45625"
      d="M13.225,20.84a.944.944,0,0,1-.664-.271L9.11,17.181a.947.947,0,1,1,1.327-1.352l2.782,2.731,6.746-6.732A.947.947,0,1,1,21.3,13.169l-7.41,7.394A.945.945,0,0,1,13.225,20.84Z"
      transform="translate(-3.252 -4.195)"
      fill="#5fb661"
    />
  </svg>,
  'SuccessIcon',
);
