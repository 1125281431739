import { DismissActionItemResponse } from 'types/ActionItemResponse';
import { http } from 'services/BaseApi';
import { ActionItemElement } from 'interfaces/ActionItem/IActionitemElement';
import { ISuccessResponse } from 'interfaces/Common/ICommonContract';

export const getActionItems = async (
  userId: string,
): Promise<ActionItemElement> => {
  return await http.get(`/v2/action-items/${userId}`);
};

export const getActionItemsV3 = async (
  userId: string,
): Promise<ActionItemElement> => {
  return await http.get(`/v3/action-items/${userId}`);
};

export const dismissActionItem = async (
  userId: string,
  dismissals: string[],
): Promise<DismissActionItemResponse> => {
  return await http.put(`/action-items/${userId}`, dismissals);
};

export const saveWiselyPaycardInfo = async (
  userId: string,
  placementId: string,
  optIn: boolean,
): Promise<ISuccessResponse> => {
  return await http.post(`/assignments/${userId}/${placementId}/pay-card`, {
    optIn: optIn,
  });
};

export const getDocusignEnvelopeUrl = async (
  userId: string,
  envelopeId: string,
): Promise<any> => {
  return await http.get(`/docusign-envelopes/${userId}/${envelopeId}/url`);
};
