import React from 'react';
import { theme } from 'styles/theme';

export function TaskIcon({
  width = '24px',
  height = '24px',
  color = theme.palette.system.midnightBlue,
}: {
  width?: string;
  height?: string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        id="Group_14590"
        data-name="Group 14590"
        transform="translate(-23.999 -18)"
      >
        <g
          id="Controls-icon-Open_Tasks"
          data-name="Controls-icon-Open Tasks"
          transform="translate(24 18)"
        >
          <rect
            id="Rectangle_2270"
            data-name="Rectangle 2270"
            width="24"
            height="24"
            transform="translate(-0.001)"
            fill={color}
            opacity="0"
          />
          <g
            id="Group_14573"
            data-name="Group 14573"
            transform="translate(0.631 5.053)"
          >
            <g
              id="Group_14569"
              data-name="Group 14569"
              transform="translate(0)"
            >
              <g
                id="Group_2962"
                data-name="Group 2962"
                transform="translate(7.955 1.299)"
              >
                <path
                  id="Path_3852"
                  data-name="Path 3852"
                  d="M40.651,21.106H26.977a.707.707,0,0,1,0-1.38H40.651a.707.707,0,0,1,0,1.38Z"
                  transform="translate(-26.423 -19.726)"
                  fill={color}
                />
              </g>
              <g id="Group_2963" data-name="Group 2963">
                <path
                  id="Path_3853"
                  data-name="Path 3853"
                  d="M19.7,22.293a.69.69,0,0,1-.488-.2l-1.238-1.237a.69.69,0,0,1,.976-.976l.75.75,2.11-2.11a.69.69,0,1,1,.976.975l-2.6,2.6A.69.69,0,0,1,19.7,22.293Z"
                  transform="translate(-17.775 -18.314)"
                  fill={color}
                />
              </g>
              <g
                id="Group_2964"
                data-name="Group 2964"
                transform="translate(7.955 6.054)"
              >
                <path
                  id="Path_3854"
                  data-name="Path 3854"
                  d="M40.651,26.275H26.977a.707.707,0,0,1,0-1.38H40.651a.707.707,0,0,1,0,1.38Z"
                  transform="translate(-26.423 -24.895)"
                  fill={color}
                />
              </g>
              <g
                id="Group_2965"
                data-name="Group 2965"
                transform="translate(0 4.754)"
              >
                <path
                  id="Path_3855"
                  data-name="Path 3855"
                  d="M19.7,27.462a.69.69,0,0,1-.488-.2l-1.238-1.237a.69.69,0,1,1,.976-.976l.75.75,2.11-2.111a.69.69,0,0,1,.976.976l-2.6,2.6A.69.69,0,0,1,19.7,27.462Z"
                  transform="translate(-17.775 -23.482)"
                  fill={color}
                />
              </g>
              <g
                id="Group_2966"
                data-name="Group 2966"
                transform="translate(7.955 10.809)"
              >
                <path
                  id="Path_3856"
                  data-name="Path 3856"
                  d="M40.651,31.444H26.977a.707.707,0,0,1,0-1.38H40.651a.707.707,0,0,1,0,1.38Z"
                  transform="translate(-26.423 -30.064)"
                  fill={color}
                />
              </g>
              <g
                id="Group_2967"
                data-name="Group 2967"
                transform="translate(0 9.509)"
              >
                <path
                  id="Path_3857"
                  data-name="Path 3857"
                  d="M19.7,32.631a.693.693,0,0,1-.488-.2L17.977,31.19a.69.69,0,0,1,.976-.975l.75.75,2.11-2.111a.69.69,0,1,1,.976.976l-2.6,2.6A.693.693,0,0,1,19.7,32.631Z"
                  transform="translate(-17.775 -28.651)"
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
