import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { theme } from 'styles/theme';

export const ToolTipContentText = styled(Typography)(() => ({
  color: theme?.palette?.system?.alertInfoColor,
  letterSpacing: '0px',
  padding: '0px',
  width: '100%',
  font: 'normal normal 600 14px/24px Open Sans',
}));
