import React, { useMemo } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { theme } from 'styles/theme';
import { IMoreButtonProps } from 'interfaces/Common/IMoreButtonProps';

export const MoreButton = (props: IMoreButtonProps) => {
  const { id, menuList, sx } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMoreButtonClick = event => {
    if (event && event.stopPropagation) event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    if (event && event.stopPropagation) event.stopPropagation();
    setAnchorEl(null);
  };

  const menuItems = useMemo(() => {
    return (
      menuList &&
      menuList?.map((menu, idx) => {
        return (
          <MenuItem
            key={`menu-item-${idx}`}
            onClick={(e: any) => {
              if (e && e.stopPropagation) e.stopPropagation();
              if (menu?.onClick) {
                menu?.onClick(e);
              }
              setAnchorEl(null);
            }}
            sx={{
              padding: '10px 20px 10px 20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              color={theme.palette.system.grayText}
              lineHeight="20px"
            >
              {menu?.name}
            </Typography>
          </MenuItem>
        );
      })
    );
  }, [menuList]);

  return (
    <Box
      id={id}
      sx={{
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...sx,
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={e => handleMoreButtonClick(e)}
        sx={{
          '&.MuiIconButton-root': {
            height: '24px',
            width: '24px',
            padding: '0px',
            ...sx?.['icon-button'],
          },
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={event => handleClose(event)}
        PaperProps={{
          style: {
            padding: '10px 0px 10px 0px',
            boxShadow: `0px 2px 8px ${theme?.palette?.system?.popOverBoxShadow}`,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems}
      </Menu>
    </Box>
  );
};
