import React from 'react';
import { theme } from 'styles/theme';

export default function EditIcon(
  color?: string,
  height?: string,
  width?: string,
) {
  return (
    <svg
      id="Controls_icon_Edit"
      data-name="Controls/icon/Edit"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '16'}
      height={height ?? '16'}
      viewBox="0 0 38 38"
    >
      <g id="Group_2859" data-name="Group 2859" transform="translate(-11 -11)">
        <rect
          id="Rectangle_2263"
          data-name="Rectangle 2263"
          width="16"
          height="16"
          transform="translate(11 11)"
          fill="currentColor"
          opacity="0"
        />
        <path
          id="Path_3726"
          data-name="Path 3726"
          d="M52.606,21.519l-4.749-4.753a3.8,3.8,0,0,0-5.24,0L19.481,39.925A6.777,6.777,0,0,0,17.7,44.679l.21,4.445c.133,1.954.42,2.24,2.371,2.372l4.439.21c.1.007.2.011.3.011a6.844,6.844,0,0,0,4.448-1.794L52.6,26.764a3.717,3.717,0,0,0,0-5.245ZM46.126,28.48,40.9,23.25l.534-.534,5.225,5.23ZM24.946,48.343l-1.438-.1L21.156,45.89l-.1-1.438a3.485,3.485,0,0,1,.454-1.64l5.07,5.075A3.728,3.728,0,0,1,24.946,48.343Zm3.143-1.807-5.225-5.23L39.472,24.681l5.225,5.23ZM50.224,24.379l-2.134,2.136-5.225-5.23L45,19.148a.336.336,0,0,1,.238-.094.332.332,0,0,1,.238.1L50.224,23.9a.317.317,0,0,1,.1.237A.327.327,0,0,1,50.224,24.379Z"
          transform="translate(-5.688 -3.717)"
          fill={color || `${theme.palette.system.coolGray}`}
        />
      </g>
    </svg>
  );
}
