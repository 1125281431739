import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CompactStateBadge } from 'components/common/CompactStateBadge/CompactStateBadge';
import { JobBadge } from 'components/common/JobBadge/JobBadge';
import { useGetAuthenticatedImage } from 'helpers/getAuthenticatedImage';
import { IJobCardProps } from 'interfaces/Props/IJobCardProps';
import moment from 'moment';
import React, { useMemo } from 'react';
import { getJobBadgeInformation } from 'services/jobs/jobUIService';
import { trackEvent } from 'services/logging/appInsights';
import { theme } from 'styles/theme';

import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { FeatureToggles } from 'enums/featureToggles';

export const JobSearchCard: React.FC<IJobCardProps> = props => {
  const isACSJobSearch = useFeatureToggle(FeatureToggles.ENABLE_ACS_JOB_SEARCH);

  const priorityTypes = props?.topJob?.priorityTypes?.[0];
  const isStrike = props.topJob?.placementTypeId === 500;
  const badgeDetails = getJobBadgeInformation(priorityTypes ?? 0, isStrike);

  const facilityImage = useGetAuthenticatedImage(
    props?.topJob?.image || undefined,
    { height: 132 },
  );

  const displayDateInfo = useMemo(() => {
    if (isStrike) {
      const postedDate = moment(props?.topJob?.postedDate || '');
      var days = moment().diff(postedDate, 'days');

      if (days === 1) {
        return `Posted ${days} Day ago`;
      } else if (days < 1) {
        days = moment().diff(postedDate, 'hours');
        return `Posted ${days} Hours ago`;
      } else {
        return `Posted ${days} Days ago`;
      }
    }
    return `${moment(props?.topJob?.startDate).format('MMM DD')} • ${
      props?.topJob?.duration
    } weeks`;
  }, [props?.topJob, isStrike]);

  const handleClick = () => {
    trackEvent('Job_Clicked');
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Grid container marginBottom={2}>
      <Grid item xs={12}>
        <Card
          id={props.id}
          key={props.topJob?.jobId}
          sx={{
            [theme.breakpoints.up('md')]: {
              border: `1px solid  ${
                props.topJob?.jobId === props.selectedJobId
                  ? theme.palette.system.skyBlue
                  : theme.palette.system.border
              }`,
              borderRadius: '10px',
            },
            [theme.breakpoints.down('md')]: {
              border: `1px solid ${theme.palette.system.border}`,

              borderRadius: '10px',
            },
            padding: '12px',
            minHeight: '178px',
          }}
          onClick={handleClick}
        >
          <Grid
            container
            item
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            gap={2}
          >
            <Grid item>
              {facilityImage ? (
                <CardMedia
                  component="img"
                  className="card-img"
                  image={facilityImage}
                  alt={props?.topJob?.facilityName}
                  height={132}
                  sx={{
                    borderRadius: '10px',
                    width: '69px',
                  }}
                />
              ) : (
                <Box
                  className="card-img"
                  width={69}
                  height={132}
                  sx={{ backgroundColor: 'system.lightGrayBg' }}
                />
              )}
            </Grid>
            <Grid
              container
              item
              flexDirection="column"
              sx={{ width: 'calc(100% - 69px - 12px)' }}
            >
              <Grid
                container
                item
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item alignItems="center" display="flex">
                  <JobBadge
                    name={badgeDetails.name}
                    src={badgeDetails.src}
                    color={badgeDetails.color}
                  />
                  {props?.topJob?.isInCompactState && (
                    <Grid item margin={badgeDetails.name ? '0px 12px' : '0px'}>
                      <CompactStateBadge
                        name={'C'}
                        src={''}
                        color={'system.shadowGray'}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item paddingTop="12px">
                {!isStrike && (
                  <Typography
                    variant="subtitle1"
                    color="system.subTitleBlack"
                    data-testid="city-state"
                  >
                    {props?.topJob?.address?.city},{' '}
                    {props?.topJob?.address?.state}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                flexWrap="nowrap"
                sx={{
                  width: 'calc(100% - 12px)',
                }}
              >
                {isStrike && props?.topJob?.facilityName && (
                  <Typography
                    variant="subtitle1"
                    color="system.subTitleBlack"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    margin=" 3px 0px"
                    data-testid="facility-name"
                  >
                    {props.topJob.facilityName}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  data-testid="title"
                >
                  {props?.topJob?.title}
                </Typography>
                {!isStrike && props?.topJob?.facilityName && (
                  <Typography
                    variant="body1"
                    color="system.coolGray"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    margin=" 3px 0px"
                    data-testid="facility-name"
                  >
                    {props.topJob.facilityName}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  marginBottom="6px"
                  marginTop={isStrike ? '6px' : '0px'}
                  data-testid="display-date-info"
                >
                  {displayDateInfo}
                </Typography>
              </Grid>

              {!isStrike && (
                <Grid
                  container
                  item
                  direction="row"
                  padding={0}
                  justifyContent="right"
                >
                  {(props?.topJob?.maxPayRate ?? 0) > 0 ? (
                    <>
                      <Grid item>
                        <Typography
                          variant="h4"
                          color="system.grassGreen"
                          component="div"
                          justifyContent="flex-end"
                          fontWeight="600"
                          data-testid="max-pay-rate"
                        >
                          {props?.topJob?.maxPayRate?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          component="span"
                          color="system.coolGray"
                        >
                          /wk
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Typography
                        variant="body2"
                        component="span"
                        color="system.coolGray"
                      >
                        Contact for Details
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
