import React from 'react';

export default function AddIcon(
  color?: string,
  height?: string,
  width?: string,
) {
  return (
    <svg
      id="Controls_Icon_Add"
      data-name="Controls/Icon/Add"
      xmlns="http://www.w3.org/2000/svg"
      width={width || '38'}
      height={height || '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2333"
        data-name="Rectangle 2333"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g id="Group_2928" data-name="Group 2928" transform="translate(-12 -11)">
        <path
          id="Path_3811"
          data-name="Path 3811"
          d="M47.237,24.773H36.172V13.708a1.288,1.288,0,0,0-1.288-1.288H26.166a1.287,1.287,0,0,0-1.288,1.288V24.773H13.814a1.287,1.287,0,0,0-1.288,1.288v8.718a1.287,1.287,0,0,0,1.288,1.288H24.878V47.132a1.287,1.287,0,0,0,1.288,1.288h8.718a1.287,1.287,0,0,0,1.288-1.288V36.067H47.237a1.288,1.288,0,0,0,1.288-1.288V26.061A1.288,1.288,0,0,0,47.237,24.773Z"
          transform="translate(0.474 -0.42)"
          fill={color}
        />
      </g>
    </svg>
  );
}
