import React from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { CallIcon } from 'assets/icons/Contacts/CallIcon';
import { ContactCardImage } from 'components/ContactCard/ContactCardImage';
import { AppLink } from '../Link/Link';
import { MailIcon } from 'assets/icons/Contacts/MailIcon';
import { theme } from 'styles/theme';
import { IContact } from 'interfaces/User/Contact/IContact';
import { TextIcon } from 'assets/icons/Contacts/TextIcon';
import { formatPhoneNumber } from 'helpers/formatUSPhoneNumber';

export const HelpContact = ({
  contactDetails,
}: {
  contactDetails: IContact | undefined;
}) => {
  const emailBreak = (emailAddress: string) => {
    // 29 characters is the length that causes word wrap with current spacing
    if (emailAddress.length > 29) {
      let a = emailAddress.split('@');
      return (
        <span>
          {`${a[0]}`}
          <wbr></wbr>
          {`@${a[1]}`}
        </span>
      );
    }
    return emailAddress;
  };
  return (
    <Card
      id="help-contact-details"
      sx={{
        maxWidth: '555px',
        width: '100%',
        border: '1px solid #E5E5E5',
        borderRadius: '10px',
        mt: 4,
        p: '24px 24px 36px',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            color={theme.palette.system.grayText}
            fontSize="16px"
            textAlign={{ xs: 'center', sm: 'inherit' }}
          >
            Have questions? I can help.
          </Typography>
        </Grid>
        <Grid container item xs={12} pt={2}>
          <Grid item xs={3} sm={1.5}>
            <ContactCardImage
              id="contact-image"
              avatarVariant="text"
              avatarText={
                contactDetails?.firstName + ' ' + contactDetails?.lastName
              }
            />
          </Grid>
          <Grid item xs={8} sm={3} ml={1} pt={{ xs: 1, sm: 0 }}>
            <Typography
              variant="body1"
              color={theme.palette.system.grayText}
              lineHeight="19px"
              pb={1}
            >
              {`${contactDetails?.firstName || ''} ${
                contactDetails?.lastName || ''
              }`}
            </Typography>
            <Typography variant="body1" color={theme.palette.system.coolGray}>
              {contactDetails?.employeeRole}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={7}
            sx={{
              width: 'auto',
              pt: { xs: 4, sm: 0 },
            }}
          >
            {contactDetails?.emailAddress && (
              <Grid
                item
                display={'flex'}
                flexDirection={'row'}
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                <MailIcon
                  sx={{
                    color: 'system.skyBlue',
                    width: '18px',
                    height: '18px',
                    mr: 1,
                  }}
                />
                <AppLink
                  component={'a'}
                  href={`mailto:${contactDetails?.emailAddress}`}
                >
                  {contactDetails?.emailAddress
                    ? emailBreak(contactDetails?.emailAddress)
                    : '-'}
                </AppLink>
              </Grid>
            )}
            {contactDetails?.phoneNumber && (
              <>
                <Divider sx={{ my: 2, width: '100%' }} />
                <Grid
                  item
                  display={'flex'}
                  flexDirection={'row'}
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  <CallIcon
                    sx={{
                      color: 'system.skyBlue',
                      width: '18px',
                      height: '18px',
                      mr: 1,
                    }}
                  />
                  <AppLink
                    component={'a'}
                    href={`tel:${contactDetails?.phoneNumber}`}
                  >
                    {contactDetails?.phoneNumber}
                  </AppLink>
                </Grid>
              </>
            )}
            {contactDetails?.smsNumber && (
              <>
                <Divider
                  sx={{
                    mt: 2,
                    mb: 2,
                    width: '100%',
                    display: { xs: 'flex', sm: 'none' },
                  }}
                />
                <Grid
                  item
                  display={'flex'}
                  flexDirection={'row'}
                  sx={{
                    wordBreak: 'break-word',
                  }}
                >
                  <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                    <TextIcon
                      sx={{
                        color: 'system.skyBlue',
                        width: '18px',
                        height: '18px',
                        mr: 1,
                      }}
                    />
                    <AppLink
                      component={'a'}
                      href={`sms:${contactDetails?.smsNumber}`}
                    >
                      {formatPhoneNumber(contactDetails?.smsNumber)}
                    </AppLink>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
