import React from 'react';

export default function DocumentCheckedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="65"
      viewBox="0 0 57 65"
    >
      <g
        id="Group_8677"
        data-name="Group 8677"
        transform="translate(-144.911 -227.001)"
      >
        <g id="document" transform="translate(4)">
          <path
            id="Path_366"
            data-name="Path 366"
            d="M53.845,9.946h-23.1A9.136,9.136,0,0,0,25.6,11.753l-7.347,6.025a6.77,6.77,0,0,0-2.3,4.73V58.156a3.961,3.961,0,0,0,4.1,3.79H53.845a3.961,3.961,0,0,0,4.1-3.79V13.736A3.961,3.961,0,0,0,53.845,9.946ZM28.073,14.33a3.074,3.074,0,0,1,.542-.339v6.1H21.044ZM54.218,58.156a.365.365,0,0,1-.373.345H20.051a.364.364,0,0,1-.373-.345V23.541h10.8a1.8,1.8,0,0,0,1.865-1.723V13.391h21.5a.365.365,0,0,1,.373.345Z"
            transform="translate(124.963 217.055)"
            fill="#75787b"
          />
          <path
            id="Path_367"
            data-name="Path 367"
            d="M42.123,25.289H24.7a1.841,1.841,0,1,0,0,3.682H42.123a1.841,1.841,0,1,0,0-3.682Z"
            transform="translate(128.318 222.692)"
            fill="#75787b"
          />
          <path
            id="Path_368"
            data-name="Path 368"
            d="M42.123,30.981H24.7a1.841,1.841,0,1,0,0,3.682H42.123a1.841,1.841,0,1,0,0-3.682Z"
            transform="translate(128.318 224.783)"
            fill="#75787b"
          />
          <path
            id="Path_369"
            data-name="Path 369"
            d="M42.123,36.674H24.7a1.841,1.841,0,1,0,0,3.682H42.123a1.841,1.841,0,1,0,0-3.682Z"
            transform="translate(128.318 226.875)"
            fill="#75787b"
          />
        </g>
        <circle
          id="Ellipse_685"
          data-name="Ellipse 685"
          cx="15"
          cy="15"
          r="15"
          transform="translate(171.911 262)"
          fill="#fff"
        />
        <path
          id="Path_212"
          data-name="Path 212"
          d="M24.435,12.33a12,12,0,1,0,12,12A12,12,0,0,0,24.435,12.33Zm6.072,8.01-7.829,7.83a.852.852,0,0,1-1.207,0l-3.729-3.729a.854.854,0,0,1,1.207-1.207l3.126,3.125L29.3,19.133a.854.854,0,0,1,1.207,1.207Z"
          transform="translate(162.477 252.67)"
          fill="#0098db"
        />
      </g>
    </svg>
  );
}
