import { Box, useMediaQuery } from '@mui/material';
import { ShareIcon } from 'assets/icons/Share-Icon';
import React, { FC, useEffect, useState } from 'react';
import { RWebShare } from 'react-web-share';
import { useAppSelector } from 'store/hooks';
import { theme } from 'styles/theme';
import { trackEvent } from 'services/logging/appInsights';

export const JobShare: FC<{ jobId: string; shareTitle: string }> = ({
  jobId,
  shareTitle,
}) => {
  const shareMessage = 'Check out this Job Listing on AMN Passport!';

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const canUseNativeShare = useAppSelector(
    state => state.jobShare.canUseNativeShare,
  );

  const [shareData, setShareData] = useState({
    text: shareMessage,
    url: `${window.location.origin}/jobs/${jobId}`,
    title: shareTitle,
  });

  const handleShareClick = () => {
    navigator.share(shareData);
    trackEvent('job_share', { jobId });
  };

  useEffect(() => {
    setShareData(prev => ({
      ...prev,
      url: `${window.location.origin}/jobs/${jobId}`,
    }));
  }, [jobId]);

  return (
    <>
      {isMobile && canUseNativeShare ? (
        <Box
          id="job-share"
          onClick={handleShareClick}
          sx={{ cursor: 'pointer' }}
        >
          <ShareIcon htmlColor={theme.palette.system.skyBlue} />
        </Box>
      ) : (
        <Box
          id="web-share"
          sx={{ marginRight: isMobile ? '0px' : '16px', cursor: 'pointer' }}
        >
          <RWebShare
            data={shareData}
            sites={['facebook', 'twitter', 'linkedin', 'mail', 'copy']}
            onClick={() => trackEvent('job_share', { jobId })}
          >
            <ShareIcon htmlColor={theme.palette.system.skyBlue} />
          </RWebShare>
        </Box>
      )}
    </>
  );
};
