export const fileTypes = {
  pdf: 'pdf',
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
  html: 'html',
};

export const MAX_FILE_SIZE = 5;
export const MAX_FILE_SIZE_BYTES = 5000000;
export const MAX_ATTACHMENT_LIMIT = 6;

export const MAIN_FLOWS = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
};

export const PROFILE_PIC_FLOWS = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
};
