import { JobBadge } from 'enums/topJobBadges';
import { IBadge } from 'interfaces/Jobs/IBadge';
import { theme } from 'styles/theme';
import cf from '../../assets/icons/jobBadgeIcons/cf.svg';
import emr from '../../assets/icons/jobBadgeIcons/emr.svg';
import exclusive from '../../assets/icons/jobBadgeIcons/exclusive.svg';
import fema from '../../assets/icons/jobBadgeIcons/fema.svg';
import matChildHealth from '../../assets/icons/jobBadgeIcons/matChildHealth.svg';
import strike from '../../assets/icons/jobBadgeIcons/strike.svg';
import vaccine from '../../assets/icons/jobBadgeIcons/vaccine.svg';
import winter from '../../assets/icons/jobBadgeIcons/winter.svg';
/**
 *
 * @param priorityTypes The priority types
 * @returns
 */

export function getJobBadgeInformation(
  priorityTypes: number,
  isStrike: boolean = false,
  strikeNoticeReceived: boolean | undefined = false,
): IBadge {
  if (isStrike) {
    return {
      name: strikeNoticeReceived
        ? JobBadge.StrikeNoticeReceived
        : JobBadge.Strike,
      color: `${theme.palette.system.black}`,
      src: strike,
    };
  }
  const badgeDetails = (priorityTypes?: number): IBadge => {
    switch (priorityTypes) {
      case 1100:
        return {
          name: JobBadge.StrikeNoticeReceived,
          color: `${theme.palette.system.black}`,
          src: strike,
        };
      case 1000:
        return {
          name: JobBadge.Vaccine,
          color: `${theme.palette.system.marineBlue}`,
          src: vaccine,
        };
      case 900:
        return {
          name: JobBadge.Strike,
          color: `${theme.palette.system.black}`,
          src: strike,
        };
      case 800:
        return {
          name: JobBadge.FEMA,
          color: `${theme.palette.system.marineBlue}`,
          src: fema,
        };
      case 700:
        return {
          name: JobBadge.CF,
          color: `${theme.palette.system.purple}`,
          src: cf,
        };
      case 600:
      case 500:
        return {
          name: JobBadge.CriticalStaffing,
          color: `${theme.palette.system.error}`,
          src: '',
        };
      case 400:
        return {
          name: JobBadge.Winter,
          color: `${theme.palette.system.skyBlue}`,
          src: winter,
        };
      case 300:
        return {
          name: JobBadge.EMR,
          color: `${theme.palette.system.midnightBlue}`,
          src: emr,
        };
      case 200:
        return {
          name: 'Mat. Child Health',
          color: `${theme.palette.system.teal}`,
          src: matChildHealth,
        };
      case 100:
        return {
          name: JobBadge.Exclusive,
          color: `${theme.palette.system.grassGreen}`,
          src: exclusive,
        };
      default:
        return { name: '', color: '', src: '' };
    }
  };
  const badgeDetail = badgeDetails(priorityTypes);
  return badgeDetail;
}

export function getJobBadgeInformationByTags(
  tag: string,
  isStrike: boolean = false,
  strikeNoticeReceived: boolean | undefined = false,
) {
  if (isStrike) {
    return {
      name: strikeNoticeReceived
        ? JobBadge.StrikeNoticeReceived
        : JobBadge.Strike,
      color: `${theme.palette.system.black}`,
      src: strike,
    };
  }
  const badgeDetails = (tag: string): IBadge => {
    switch (tag) {
      case JobBadge.StrikeNoticeReceived:
        return {
          name: JobBadge.StrikeNoticeReceived,
          color: `${theme.palette.system.black}`,
          src: strike,
        };
      case JobBadge.Vaccine:
        return {
          name: JobBadge.Vaccine,
          color: `${theme.palette.system.marineBlue}`,
          src: vaccine,
        };
      case JobBadge.Strike:
        return {
          name: JobBadge.Strike,
          color: `${theme.palette.system.black}`,
          src: strike,
        };
      case JobBadge.FEMA:
        return {
          name: JobBadge.FEMA,
          color: `${theme.palette.system.marineBlue}`,
          src: fema,
        };
      case JobBadge.CF:
        return {
          name: JobBadge.CF,
          color: `${theme.palette.system.purple}`,
          src: cf,
        };
      case JobBadge.CriticalStaffing:
        return {
          name: JobBadge.CriticalStaffing,
          color: `${theme.palette.system.error}`,
          src: '',
        };
      case JobBadge.Winter:
        return {
          name: JobBadge.Winter,
          color: `${theme.palette.system.skyBlue}`,
          src: winter,
        };
      case JobBadge.EMR:
        return {
          name: JobBadge.EMR,
          color: `${theme.palette.system.midnightBlue}`,
          src: emr,
        };
      case 'Mat. Child Health':
        return {
          name: 'Mat. Child Health',
          color: `${theme.palette.system.teal}`,
          src: matChildHealth,
        };
      case JobBadge.Exclusive:
        return {
          name: JobBadge.Exclusive,
          color: `${theme.palette.system.grassGreen}`,
          src: exclusive,
        };
      default:
        return { name: '', color: '', src: '' };
    }
  };
  const badgeDetail = badgeDetails(tag);
  return badgeDetail;
}
