import { createSvgIcon } from '@mui/material';
import * as React from 'react';

export const CheckIcon = createSvgIcon(
  <svg
    id="Controls_icon_Confirm"
    data-name="Controls/icon/Confirm"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2338"
      data-name="Rectangle 2338"
      fill="none"
      opacity="0"
    />
    <path
      id="Path_3821"
      data-name="Path 3821"
      d="M48.822,22.111,27.575,43.456a2.31,2.31,0,0,1-3.277,0L14.179,33.29a2.336,2.336,0,0,1,0-3.292,2.309,2.309,0,0,1,3.277,0l8.481,8.519,19.608-19.7a2.311,2.311,0,0,1,3.277,0A2.336,2.336,0,0,1,48.822,22.111Z"
      transform="translate(-12.5 -12.137)"
    />
  </svg>,
  'CheckIcon',
);
