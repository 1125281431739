/**
 * Asynchronously loads the component for Sign in Validation
 */

import { lazyLoad } from 'utils/loadable';

export const SignIn = lazyLoad(
  () => import('./SignIn'),
  module => module.SignIn,
);
