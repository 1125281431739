import React, { Fragment, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';

import { PageSearchBar } from 'components/SearchBar/PageSearchBar';
import { ICardDetails, ICardList } from 'interfaces/PageLayout/ICardListPage';
import { IContact } from 'interfaces/User/Contact/IContact';
import { CardDetails } from '../Cards/CardDetails';
import { CardNavItem } from '../Cards/CardNavItem';
import { createCardListPageStyles } from './PageLayout.styles';

/**
 *
 * @param cardListData: ICardList
 *  This is for the left side nav content list data
 *  It is customizable to hide or show a title above specific cards,
 *  you will just need to group those card items together in the a dictionary
 *  object that matches follows the pattern to the cardList prop
 *    [categoryId: string | number]: CardItems
 *  To make sure that the data is properly sorted by category, make sure to set
 *  the correct ID through to the key
 *  e.g) if showTitle is false and you don't want
 *  to display a title
 *  e.g) if showTitle is true and you want to display
 *  titles in the nav
 *  The title of these cards will be displayed through the title prop in CardItems
 *  Take a look at interfaces/PageLayout/ICardListPage.ts for more info
 *
 *  You also will need to set the flag showTitle to true here
 */
export const CardListPage = ({
  id = '',
  cardListData = {},
  splashScreen = <></>,
  showSplashScreen,
  titleSx = {},
  cardDetails,
  selectedId,
  cardNavSx = { display: 'flex', justifyContent: 'flex-end' },
  bottomComponent = <></>,
  isPreference = false,
  component = <></>,
  containerSx = undefined,
  searchItems = [],
  searchKey = '',
  searchResultOnClick = undefined,
  onSearchBarFocus,
  searchItemComponent,
  onSearchDownloadIconClick,
  onSearchDownloadClick,
  disableRecentItems = false,
  enableSearchBar = false,
  searchPlaceholder,
  marginSx = '',
  clearanceItem = undefined,
  expandAccordion = undefined,
  helpContact = undefined,
  leftBackHeaderClick,
}: {
  id: string;
  cardListData?: ICardList;
  cardDetails?: ICardDetails;
  selectedId?: number | string;
  splashScreen?: ReactElement;
  showSplashScreen?: boolean;
  titleSx?: SxProps<Theme>;
  cardNavSx?: SxProps<Theme>;
  bottomComponent?: ReactElement;
  isPreference?: boolean;
  component?: ReactElement;
  containerSx?: SxProps<Theme>;
  searchItems?: any[];
  searchKey?: string;
  searchResultOnClick?: (searchItem: any) => void;
  onSearchBarFocus?: (e?: any) => void;
  searchItemComponent?: (searchItem: any, index?: number) => JSX.Element;
  onSearchDownloadIconClick?: () => void;
  onSearchDownloadClick?: () => void;
  disableRecentItems?: boolean;
  enableSearchBar?: boolean;
  searchPlaceholder?: string;
  marginSx?: string;
  clearanceItem?: any;
  expandAccordion?: string;
  helpContact?: IContact | undefined;
  leftBackHeaderClick?: () => void;
}) => {
  const classes = createCardListPageStyles();

  return (
    <Grid
      container
      id={id}
      spacing={4}
      className={classes?.cardContainer}
      sx={{ ...containerSx }}
    >
      <PageSearchBar
        items={searchItems}
        searchKey={searchKey}
        searchResultOnClick={searchResultOnClick}
        navItemSelected={!!selectedId}
        onFocus={onSearchBarFocus}
        searchItemComponent={searchItemComponent}
        onSearchDownloadIconClick={onSearchDownloadIconClick}
        onSearchDownloadClick={onSearchDownloadClick}
        leftBackHeaderClick={leftBackHeaderClick}
        disableRecentItems={disableRecentItems}
        enableSearchBar={enableSearchBar}
        searchPlaceholder={searchPlaceholder}
        navItems={Object.keys(cardListData?.cardList || {})
          .sort(
            (key1, key2) =>
              (((cardListData?.cardList || {})[key1] || {}).order || -1) -
              (((cardListData?.cardList || {})[key2] || {}).order || -1),
          )
          .map((key, index: number) => (
            <Fragment key={key}>
              {index === 0 && clearanceItem}
              <div key={key} className={classes?.cardNavItem}>
                {cardListData?.showTitle &&
                  !!(cardListData?.cardList || {})[key]?.items &&
                  ((cardListData?.cardList || {})[key].items?.length ?? 0) >
                    0 && (
                    <Typography variant="body1" sx={{ ...titleSx }}>
                      {((cardListData?.cardList || {})[key] || {}).title}
                    </Typography>
                  )}
                {(cardListData?.cardList || {})[key]?.showComponent && (
                  <Grid my={2} pt={2}>
                    <CardDetails
                      cardDetails={cardDetails}
                      component={component}
                      isPreference={isPreference}
                      expandAccordion={expandAccordion}
                      showTitle={true}
                    />
                  </Grid>
                )}
                {!!(cardListData?.cardList || {})[key]?.items &&
                  ((cardListData?.cardList || {})[key].items?.length ?? 0) >
                    0 &&
                  !(cardListData?.cardList || {})[key]?.showComponent && (
                    <CardNavItem
                      cards={
                        ((cardListData?.cardList || {})[key] || {}).items || []
                      }
                      selectedId={selectedId}
                      cardNavSx={{
                        '& .MuiCard-root': {
                          mb: marginSx ? marginSx : '12px',
                        },
                        ...cardNavSx,
                      }}
                    />
                  )}
              </div>
            </Fragment>
          ))}
        cardDetails={
          <Grid
            item
            sm={8}
            pr={3}
            pb={10}
            md={7.5}
            display={{ xs: 'none', sm: 'block' }}
            id="cardDetails"
          >
            {!selectedId || showSplashScreen ? (
              splashScreen
            ) : isPreference ? (
              <CardDetails
                cardDetails={cardDetails}
                component={component}
                isPreference={isPreference}
                expandAccordion={expandAccordion}
              />
            ) : (
              <CardDetails
                cardDetails={cardDetails}
                titleSx={titleSx}
                expandAccordion={expandAccordion}
                helpContact={helpContact}
              />
            )}
          </Grid>
        }
      />

      {bottomComponent && bottomComponent}
    </Grid>
  );
};
