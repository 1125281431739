import * as crypto from 'crypto-js';

export const decodeToken = (accessToken: string) => {
  if (!accessToken || accessToken === '') {
    return '';
  }
  let payload = accessToken.split('.');
  let token = crypto.enc.Base64.parse(payload[1]);
  let jwt = token.toString(crypto.enc.Utf8);
  let obj = JSON.parse(jwt);
  return obj;
};
