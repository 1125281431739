import { createAction, createSlice } from '@reduxjs/toolkit';
import { IToolTipsTourState } from 'interfaces/ToolTipsTour/IToolTipsTourState';
import { CookieService } from 'services/cookie/cookieService';

const initialState: IToolTipsTourState = {
  toolTipsTourDetail: {
    showToolTip: false,
    currentStateIndex: 0,
  },
};

const initializeToolTipTour = createAction<string>('INITIALIZE_TOOL_TIP_TOUR');
const restoreStateFromCookie = createAction('RESTORE_STATE_FROM_COOKIE');

const toolTipsTourSlice = createSlice({
  name: 'toolTipsTourSlice',
  initialState,
  reducers: {
    setToolTipTours(state, action) {
      state.toolTipsTourDetail = action.payload;
    },
    setIndex(state, action) {
      if (action.payload > state.toolTipsTourDetail.currentStateIndex) {
        state.toolTipsTourDetail.currentStateIndex = action.payload;
      }
    },
    showToolTip(state) {
      state.toolTipsTourDetail.showToolTip = true;
    },
    hideToolTip(state) {
      state.toolTipsTourDetail.showToolTip = false;
      CookieService.setToolTipCookie('passport_tooltip_cookie_apply_job');
    },
    reset(state) {
      state.toolTipsTourDetail = { ...initialState.toolTipsTourDetail };
    },
  },
});

export const toolTipsTourActions = {
  initializeToolTipTour,
  restoreStateFromCookie,
  ...toolTipsTourSlice.actions,
};

export default toolTipsTourSlice.reducer;
