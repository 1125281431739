import {
  StrikeAssignmentSummaryStatus,
  TravelAssignmentSummaryStatus,
} from 'enums/assignmentStatus';
import {
  DataLayerEvent,
  DataLayerFormType,
  DataLayerPageName,
} from 'enums/dataLayer';
import moment from 'moment';
import { all, call, select, takeLatest } from 'redux-saga/effects';
import { fetchJobDetails } from 'services/jobs/jobService';
import { RootState } from 'store/configureStore';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { jobDetailsActions } from 'store/slices/jobs/jobDetailSlice';

const userId = (state: RootState) => {
  return state.userProfile.userId;
};
const userEmail = (state: RootState) => {
  return state.userProfile.emailAddress;
};
const isOnAssignemnt = (state: RootState) => {
  return state.assignmentSummary?.assignmentState?.items?.some(
    assignment =>
      assignment.assignmentActivityStatus ===
        TravelAssignmentSummaryStatus.ON_ASSIGNMENT ||
      assignment.assignmentActivityStatus ===
        StrikeAssignmentSummaryStatus.ON_ASSIGNMENT,
  );
};

const daysRemaining = (state: RootState) => {
  let currentAssignment = state.assignmentSummary?.assignmentState?.items?.find(
    assignment =>
      assignment.assignmentActivityStatus ===
        TravelAssignmentSummaryStatus.ON_ASSIGNMENT ||
      assignment.assignmentActivityStatus ===
        StrikeAssignmentSummaryStatus.ON_ASSIGNMENT,
  );
  if (currentAssignment) {
    let today = moment();
    let assignmentEnd = moment(currentAssignment.endDate);
    return assignmentEnd.diff(today, 'days');
  }
  return undefined;
};

const totalPlacements = (state: RootState) =>
  state.assignmentSummary?.assignmentState?.items?.length || 0;

const jobImpressionList = (state: RootState) =>
  state.dataLayer.impressionClickList;

const jobImpressionPosition = (state: RootState) =>
  state.dataLayer.impressionClickPosition;

const jobDetails = (state: RootState) => state.jobDetail;
const jobId = (state: RootState) => state.jobs.selectedJobId;

const pushToDataLayer = (obj: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

function* dlReady(action) {
  const remaining = yield select(daysRemaining);
  let onAssignment = yield select(isOnAssignemnt);
  let id: string = yield select(userId);
  const obj = {
    event: DataLayerEvent.DL_READY,
    user_id: id,
    user_email: yield select(userEmail),
    traveler_on_assignment: onAssignment,
    traveler_lifetime_placements: yield select(totalPlacements),
    page_section: action.payload.pageName,
  };
  if (remaining) {
    obj['traveler_placement_days_remaining'] = remaining;
  }
  if (
    action.payload.pageName === DataLayerPageName.JOB_SEARCH &&
    !action.payload.isMobile
  ) {
    let job: number | undefined = yield select(jobId);
    if (job) {
      let req = {
        userId: id,
        jobId: job,
      };
      let { status, data } = yield call(fetchJobDetails, req);
      if (status === 200 && data?.items?.[0]) {
        let job = data.items[0];
        obj['job_id'] = job.jobId;
        obj['job_name'] = job.title;
        obj['job_discipline'] = job.discipline;
        obj['job_specialty'] = job.specialty;
        obj['job_location_state'] = job.facility.address.state;
        obj['job_location_city'] = job.facility.address.city;
        obj['job_date_posted'] = job.postedDate;
      }
    }
  }
  pushToDataLayer(obj);
}

// eslint-disable-next-line require-yield
function* formComplete(action) {
  const obj = {
    event: DataLayerEvent.FORM_COMPLETE,
    form_type: action.payload,
  };
  pushToDataLayer(obj);
}

// eslint-disable-next-line require-yield
function* jobSearch(action) {
  const obj = {
    event: DataLayerEvent.JOB_SEARCH,
    search_results: action.payload,
  };
  pushToDataLayer(obj);
}

function* jobImpression(action) {
  let jobDetails = action.payload;
  let list = yield select(jobImpressionList);
  let position: number = yield select(jobImpressionPosition);
  const obj = {
    event: DataLayerEvent.EEC_CLICK,
    ecommerce: {
      click: {
        actionField: { list: list },
        products: [
          {
            id: jobDetails.id,
            name: jobDetails.title,
            price: jobDetails.maxPayRate ?? '0.00',
            category: `${jobDetails.discipline} / ${jobDetails.specialty}`,
            variant: jobDetails.placementType,
            position: position + 1,
            dimension2: `${jobDetails.discipline} > ${jobDetails.specialty}`,
            dimension3: jobDetails.placementType,
            dimension5: `${jobDetails.facility.address.city}, ${jobDetails.facility.address.state}`,
            dimension8: jobDetails.id,
          },
        ],
      },
    },
  };
  pushToDataLayer(obj);
}

function* jobAdd() {
  let { jobDetailsData } = yield select(jobDetails);
  let list = yield select(jobImpressionList);
  let position: number = yield select(jobImpressionPosition);
  const obj = {
    event: DataLayerEvent.EEC_ADD,
    ecommerce: {
      add: {
        actionField: { list: list },
        products: [
          {
            id: jobDetailsData.id,
            name: jobDetailsData.title,
            price: jobDetailsData.maxPayRate ?? '0.00',
            category: `${jobDetailsData.discipline} / ${jobDetailsData.specialty}`,
            variant: jobDetailsData.placementType,
            position: position + 1,
            dimension2: `${jobDetailsData.discipline} > ${jobDetailsData.specialty}`,
            dimension3: jobDetailsData.placementType,
            dimension5: `${jobDetailsData.facility.address.city}, ${jobDetailsData.facility.address.state}`,
            dimension8: jobDetailsData.id,
          },
        ],
      },
    },
  };
  pushToDataLayer(obj);
}

function* jobApplication() {
  let { jobDetailsData } = yield select(jobDetails);
  const obj = {
    event: DataLayerEvent.EEC_PURCHASE,
    form_type: DataLayerFormType.APPLICATION,
    ecommerce: {
      purchase: {
        actionField: {
          id: jobDetailsData.id,
          revenue: '0.00',
          tax: '0.00',
          shipping: '0.00',
        },
        products: [
          {
            id: jobDetailsData.id,
            name: jobDetailsData.title,
            price: jobDetailsData.maxPayRate ?? '0.00',
            category: `${jobDetailsData.discipline} / ${jobDetailsData.specialty}`,
            variant: jobDetailsData.placementType,
            quantity: 1,
            dimension2: `${jobDetailsData.discipline} > ${jobDetailsData.specialty}`,
            dimension3: jobDetailsData.placementType,
            dimension5: `${jobDetailsData.facility.address.city}, ${jobDetailsData.facility.address.state}`,
            dimension8: jobDetailsData.id,
          },
        ],
      },
    },
  };
  pushToDataLayer(obj);
}

// eslint-disable-next-line require-yield
function* resetDataLayer() {
  window?.dataLayer?.push(function () {
    this.reset();
  });
}

export function* dataLayerSaga() {
  yield all([
    takeLatest(dataLayerActions.dataLayerReadyAction.type, dlReady),
    takeLatest(dataLayerActions.formCompleteAction.type, formComplete),
    takeLatest(dataLayerActions.dataLayerJobSearchAction.type, jobSearch),
    takeLatest(jobDetailsActions.setJobDetail.type, jobImpression),
    takeLatest(dataLayerActions.dataLayerAddJobAction.type, jobAdd),
    takeLatest(dataLayerActions.dataLayerApplyJobAction.type, jobApplication),
    takeLatest(dataLayerActions.resetDataLayerAction.type, resetDataLayer),
  ]);
}
