import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { TOAST_MESSAGE } from 'constants/helperText';
import {
  fetchAuthImgUrl,
  signArbitrationAgreement,
} from 'services/ArbitrationAgreement/ArbitrationAgreementService';
import { arbitrationAgreementSliceActions } from 'store/slices/arbitrationAgreement/arbitrationAgreementSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';

const signatureSelector = (state: RootState) =>
  state.arbitrationAgreement.signature;
const userIdSelector = (state: RootState) => state.auth.userId;
const signatureImgUrlSelector = (state: RootState): string | undefined =>
  state?.arbitrationAgreement?.currentArbitrationAgreement?.agreement
    ?.authorizedSignatorySignatureImageUrl;

export function* getAuthImgUrlWatcher() {
  try {
    const imgUrl = yield select(signatureImgUrlSelector);
    if (imgUrl) {
      const authImgUrlResponse = yield call(fetchAuthImgUrl, imgUrl);
      if (authImgUrlResponse?.data?.fileUrl) {
        yield put(
          arbitrationAgreementSliceActions.setAuthSignatureImgUrl(
            authImgUrlResponse?.data?.fileUrl,
          ),
        );
      }
    }
  } catch (err: any) {
    console.error(err);
  }
}

export function* signArbitrationAgreementWatcher(action) {
  const { entityName } = action.payload;
  try {
    const userId: string = yield select(userIdSelector);
    const signature: string = yield select(signatureSelector);
    const signResponse = yield call(signArbitrationAgreement, {
      userId,
      entityName,
      signature,
    });
    if (signResponse?.status === 200 && signResponse?.data?.isCreated) {
      yield put(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.ChangesSaved,
        }),
      );
      yield put(credentialActions.getCredentialDetailAction(true));
    }
  } catch (e: any) {
    console.error(e);
  }
}

export function* arbitrationAgreementSaga() {
  yield all([
    takeLatest(
      arbitrationAgreementSliceActions.getAuthorizedImgUrl.type,
      getAuthImgUrlWatcher,
    ),
    takeLatest(
      arbitrationAgreementSliceActions.signArbitrationAgreement.type,
      signArbitrationAgreementWatcher,
    ),
  ]);
}
