import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { theme } from 'styles/theme';

export const SkeletonNotificationItem = ({
  showMult = false,
}: {
  showMult?: boolean;
}) => {
  const arr = showMult ? [0, 1, 2, 3] : [0];
  return (
    <>
      {arr.map(value => (
        <Grid container width="calc(100% + 48px)" ml={-4} mr={-4} key={value}>
          <Grid
            container
            flexWrap="nowrap"
            pt={2}
            pb={2}
            mr={4}
            ml={4}
            width={'100%'}
            borderBottom={`1px solid ${theme.palette.system.border}`}
          >
            <Grid item alignSelf="center" pr="12px">
              <Skeleton variant="rectangular" height={'24px'} width={'24px'} />
            </Grid>
            <Grid container item width={'100%'}>
              <Grid
                container
                item
                xs={12}
                pb={2}
                justifyContent="space-between"
                flexWrap="nowrap"
              >
                <Grid item width={'100%'}>
                  <Skeleton
                    variant="rectangular"
                    height={'19px'}
                    width={'100%'}
                    sx={{ maxWidth: '232px' }}
                  />
                </Grid>
                <Grid item width={'100%'}>
                  <Skeleton
                    variant="rectangular"
                    height={'19px'}
                    width={'100%'}
                    sx={{ maxWidth: '56px', float: 'right' }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} flexWrap="nowrap">
                <Grid item width={'100%'}>
                  <Skeleton
                    variant="rectangular"
                    height={'37px'}
                    width={'100%'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
