import React from 'react';
import { createSvgIcon } from '@mui/material';

export const EducationOnlineExamsIcon = createSvgIcon(
  <svg
    id="Controls-Icon-Online-Exams"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2350"
      data-name="Rectangle 2350"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Group_2968" data-name="Group 2968" transform="translate(1 2)">
      <g id="Group_2961" data-name="Group 2961">
        <path
          id="Path_3851"
          data-name="Path 3851"
          d="M45.8,11.92H12.161A1.18,1.18,0,0,0,10.978,13.1V36.4a1.18,1.18,0,0,0,1.183,1.175H22.466V39.63h-.387a3.307,3.307,0,0,0-3.312,3.3v1.589a1.409,1.409,0,0,0,1.409,1.4H37.789a1.409,1.409,0,0,0,1.41-1.4V43.165A3.553,3.553,0,0,0,35.65,39.63h-.161V37.57H45.8A1.18,1.18,0,0,0,46.978,36.4V13.1A1.18,1.18,0,0,0,45.8,11.92ZM35.65,41.98a1.188,1.188,0,0,1,1.183,1.185v.4h-15.7V42.93a.947.947,0,0,1,.946-.95ZM24.832,39.63V38.05h8.291v1.58Zm19.78-4.41H13.344V14.271H44.612Z"
          transform="translate(-10.978 -11.92)"
        />
      </g>
      <g
        id="Group_2962"
        data-name="Group 2962"
        transform="translate(14.617 7.34)"
      >
        <path
          id="Path_3852"
          data-name="Path 3852"
          d="M41.06,21.136H26.993a.721.721,0,0,1,0-1.41H41.06a.721.721,0,0,1,0,1.41Z"
          transform="translate(-26.423 -19.726)"
        />
      </g>
      <g
        id="Group_2963"
        data-name="Group 2963"
        transform="translate(6.433 6.012)"
      >
        <path
          id="Path_3853"
          data-name="Path 3853"
          d="M19.759,22.381a.712.712,0,0,1-.5-.207l-1.274-1.265a.7.7,0,0,1,0-1,.713.713,0,0,1,1,0l.771.766,2.171-2.157a.713.713,0,0,1,1,0,.7.7,0,0,1,0,1l-2.673,2.656A.713.713,0,0,1,19.759,22.381Z"
          transform="translate(-17.775 -18.314)"
        />
      </g>
      <g
        id="Group_2964"
        data-name="Group 2964"
        transform="translate(14.617 12.2)"
      >
        <path
          id="Path_3854"
          data-name="Path 3854"
          d="M41.06,26.305H26.993a.721.721,0,0,1,0-1.41H41.06a.721.721,0,0,1,0,1.41Z"
          transform="translate(-26.423 -24.895)"
        />
      </g>
      <g
        id="Group_2965"
        data-name="Group 2965"
        transform="translate(6.433 10.872)"
      >
        <path
          id="Path_3855"
          data-name="Path 3855"
          d="M19.759,27.55a.712.712,0,0,1-.5-.207l-1.274-1.265a.7.7,0,0,1,0-1,.713.713,0,0,1,1,0l.771.766,2.171-2.158a.713.713,0,0,1,1,0,.7.7,0,0,1,0,1l-2.673,2.656A.713.713,0,0,1,19.759,27.55Z"
          transform="translate(-17.775 -23.482)"
        />
      </g>
      <g
        id="Group_2966"
        data-name="Group 2966"
        transform="translate(14.617 17.06)"
      >
        <path
          id="Path_3856"
          data-name="Path 3856"
          d="M41.06,31.474H26.993a.721.721,0,0,1,0-1.41H41.06a.721.721,0,0,1,0,1.41Z"
          transform="translate(-26.423 -30.064)"
        />
      </g>
      <g
        id="Group_2967"
        data-name="Group 2967"
        transform="translate(6.433 15.732)"
      >
        <path
          id="Path_3857"
          data-name="Path 3857"
          d="M19.759,32.719a.715.715,0,0,1-.5-.207l-1.274-1.266a.7.7,0,0,1,0-1,.713.713,0,0,1,1,0l.771.766,2.171-2.158a.713.713,0,0,1,1,0,.7.7,0,0,1,0,1l-2.673,2.656A.715.715,0,0,1,19.759,32.719Z"
          transform="translate(-17.775 -28.651)"
        />
      </g>
    </g>
  </svg>,
  'EducationOnlineExams',
);
