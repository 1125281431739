import React, { useCallback, useEffect, useMemo } from 'react';
import _cloneDeep from 'lodash/cloneDeep';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AlertIcon from 'assets/icons/Notifications/AlertIcon';
import { FileUploadModal } from 'components/common/FileUploadModal/FileUploadModal';
import { MAIN_FLOWS, MAX_FILE_SIZE } from 'constants/fileTypes';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { ILink } from 'interfaces/ActionItem/ILink';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { theme } from 'styles/theme';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';

export const LicenseUpdate = props => {
  const dispatch = useAppDispatch();

  const filesUploaded = useAppSelector(state => state.fileUpload.uploadedFiles);
  const selectedItemVal = useAppSelector(state => {
    return state.actionItem.selectedClearanceItem;
  });

  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setDrawerOpen,
    setActionButtonDisabled,
  } = props;

  const submit = useCallback(() => {
    setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
    dispatch(credentialActions.setModalState(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDrawerOpen]);

  useEffect(
    () => {
      if (onSubmitProcess) {
        submit();
        const data = {
          isLicenseUpdate: true,
          isLicense: true,
        };
        dispatch(
          credentialActions.submitCredentialForApprovalFromDrawerAction(data),
        );
        setOnSubmitProcess?.(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmitProcess, setOnSubmitProcess, submit],
  );

  useEffect(() => {
    setActionButtonDisabled(filesUploaded?.length === 0);
  }, [setActionButtonDisabled, filesUploaded]);

  useEffect(() => {
    dispatch(fileUploadsActions.reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bannerMessage = useMemo(() => {
    if (
      !selectedItemVal ||
      !selectedItemVal?.links ||
      selectedItemVal.links?.length === 0
    )
      return;

    let selectedLink: ILink = _cloneDeep(selectedItemVal?.links[0]);

    const splitDescriptionFromUrl = selectedLink.url?.split('description=');
    if (splitDescriptionFromUrl && splitDescriptionFromUrl.length > 0) {
      selectedLink.actionText = decodeURIComponent(
        splitDescriptionFromUrl[1].replace(/\+/g, '%20'),
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          p: '0px 0px 12px 0px',
          border: `1px solid ${theme?.palette?.system?.alertErrorBorder}`,
          padding: '10px',
          borderRadius: '6px',
          marginBottom: '15px',
        }}
      >
        <AlertIcon width="30px" height="30px" />
        <Grid display="flex" alignItems="flex-start" flexDirection="column">
          <Typography
            sx={{
              color: theme?.palette?.system?.error,
              fontWeight: 600,
              ml: 2,
              height: '31px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
            }}
            data-testid="title"
          >
            {selectedLink.title}
          </Typography>
          <Typography
            sx={{
              color: theme?.palette?.system?.error,
              fontWeight: 400,
              ml: 2,
              fontSize: '14px',
            }}
            data-testid="action-text"
          >
            {selectedLink.actionText}
          </Typography>
        </Grid>
      </Box>
    );
  }, [selectedItemVal]);

  return (
    <Grid>
      {bannerMessage}
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'center', padding: '0px 12px' }}
      >
        <Grid container maxWidth={'550px'}>
          <Grid item xs={12}>
            <Typography color="system.midnightBlue" variant="subtitle1">
              Add new or updated credentials.
            </Typography>
          </Grid>
          <Grid item pt={2} pb={2} xs={12}>
            <Typography color="system.grayText" variant="body1">
              Information will be reviewed and verified.
            </Typography>
          </Grid>

          <FileUploadModal
            required
            canUploadMultiple
            id={'upload-credential-file-upload'}
            label={'Add Documents'}
            customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
            acceptedFileTypes={MAIN_FLOWS}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
