import React, { useEffect, useState } from 'react';
import { PdfViewer } from 'components/PdfViewer/PdfViewer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

export const PDFViewerPage = () => {
  const [pdfString, setPdf] = useState('');
  const pdfbase64String = useSelector(
    (state: RootState) => state.domain.pdfLink?.file,
  );
  const pdf = useSelector((state: RootState) => state.domain.pdfLink);

  useEffect(() => {
    if (pdfbase64String) {
      setPdf(pdfbase64String);
    }
  }, [pdfbase64String]);

  const downloadPDF = () => {
    b64toBlob(pdfString).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = pdf?.fileName || 'pdf-file.pdf';
      link.click();
    });
  };

  const b64toBlob = (base64, type = 'application/octet-stream') =>
    fetch(`data:${type};base64,${base64}`).then(res => res.blob());

  return (
    <PdfViewer
      id="pdf-viewer"
      title={pdf?.modalTitle ?? 'PDF Viewer'}
      pdfData={pdfString ?? ''}
      menuItems={[
        {
          id: 'DownloadPDF',
          menuItem: 'Download PDF',
          onClick: () => {
            downloadPDF();
          },
        },
      ]}
    />
  );
};
