import { Box } from '@mui/material';
import { ArrowLeftIcon } from 'assets/icons/Arrows/Arrow-Left';
import { DetailFeaturePage } from 'components/common/PageLayout/DetailFeaturePage';
import { JobDetails } from 'components/Jobs/JobDetails/JobDetails';
import JobDetailSkeleton from 'components/Jobs/JobDetails/JobDetailSkeleton';
import { JobDetailsMobile } from 'components/Jobs/JobDetails/JobDetailsMobile';
import { NoJobDetailsSplash } from 'components/Jobs/NoJobDetailsSpash';
import ProfileLink from 'components/Profile/ProfileLink';
import { useSmMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { MenuIcons } from 'enums/menuIcons';
import React, { useCallback, useEffect, useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory, useParams } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedJob } from 'store/slices/jobs/jobsSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { theme } from 'styles/theme';

type JobDetailsParams = {
  jobId: string;
};

export const JobShareDetail = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobileDevice = useSmMediaQuery();
  const jobDetail = useAppSelector(state => state.jobDetail);

  const { jobId } = useParams<JobDetailsParams>();

  const { promiseInProgress: getJobDetailsLoading } = usePromiseTracker({
    area: 'get-job-details',
    delay: 0,
  });

  const resetSelectedJobDetails = useCallback(() => {
    dispatch(setSelectedJob(undefined));
  }, []);

  const handleBackToSearch = useCallback(() => {
    resetSelectedJobDetails();
    history.push(`/${AppRouteNames.JOB_SEARCH}`);
  }, [history, resetSelectedJobDetails]);

  useEffect(
    () => {
      let id = Number(jobId);
      if (Number.isNaN(id)) {
        resetSelectedJobDetails();
      } else if (!Number.isNaN(id)) {
        dispatch(setSelectedJob(id));
      }
      return () => {
        resetSelectedJobDetails();
        dispatch(setActivePage(MenuIcons.Jobs));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const jobFound = useMemo(() => {
    return jobDetail.jobDetailsData && Number(jobDetail.jobDetailsData?.id) > 0;
  }, [jobDetail]);

  useEffect(() => {
    dispatch(setActivePage(jobDetail.jobDetailsData?.title ?? 'Job Not Found'));
  }, [dispatch, jobFound, jobDetail.jobDetailsData?.title]);

  const subHeaderBarContent = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ProfileLink
          id="back-to-search"
          sx={{ paddingLeft: '28px' }}
          underline={'none'}
          leftIcon={
            <ArrowLeftIcon
              sx={{
                width: '17px',
                height: '17px',
                color: theme.palette.system.skyBlue,
              }}
            />
          }
          onClick={handleBackToSearch}
        >
          Back to Search
        </ProfileLink>
      </Box>
    );
  }, [handleBackToSearch]);

  const jobDetailContent = useMemo(() => {
    if (jobFound) {
      return isMobileDevice ? (
        <JobDetailsMobile onCloseClick={handleBackToSearch} />
      ) : (
        <DetailFeaturePage
          featureContent={
            !getJobDetailsLoading ? <JobDetails /> : <JobDetailSkeleton />
          }
          subHeaderBarContent={subHeaderBarContent}
        />
      );
    } else {
      return (
        <DetailFeaturePage
          featureContent={
            !getJobDetailsLoading ? (
              <NoJobDetailsSplash />
            ) : (
              <JobDetailSkeleton />
            )
          }
          subHeaderBarContent={subHeaderBarContent}
        />
      );
    }
  }, [
    jobFound,
    isMobileDevice,
    handleBackToSearch,
    getJobDetailsLoading,
    subHeaderBarContent,
  ]);

  return <>{jobDetailContent}</>;
};
