import React, { ReactElement } from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { ErrorIcon } from 'assets/icons/Alert/ErrorIcon';
import { InfoIcon } from 'assets/icons/Alert/InfoIcon';
import { SuccessIcon } from 'assets/icons/Alert/SucessIcon';
import { WarningIcon } from 'assets/icons/Alert/WarningIcon';
import { theme } from 'styles/theme';
import { alertbarStyles } from './AppAlertbar.styles';
import { SxProps, Theme } from '@mui/material';

export interface SnackObject {
  variant: string;
  message: string;
}
export interface IAMNSnackbarProps {
  id?: string;
  variant?: string;
  message?: string | ReactElement;
  open?: boolean;
  displayAs?: 'common' | 'InsideParent';
  sx?: SxProps<Theme>;
  autoHideDuration?: number;
  onClose?: Function;
}
const variantIcon = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  rejected: ErrorIcon,
  info: InfoIcon,
};

const AlertContent = props => {
  const { message, variant, ...other } = props;
  const typeofMessage = typeof message;
  const Icon = variantIcon[variant];
  const classes = alertbarStyles(theme);

  const messageToDisplay =
    typeofMessage === 'string' ? (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Icon id="alertbar-snackbarcontent-icon" className={classes.icon} />
        </Box>
        <Typography
          id="alertbar-snackbarcontent-message"
          className={classes.message}
          sx={{
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 600,
          }}
        >
          {message}
        </Typography>
      </Box>
    ) : (
      message
    );

  return (
    <SnackbarContent
      id="alertbar-snackbarcontent"
      aria-describedby="alert-snackbar-content"
      className={`${classes.wrapper}, ${classes[variant]}`}
      sx={{
        padding: '12px',
        fontSize: '14px',
        fontWeight: 600,
        pointerEvents: typeofMessage === 'string' ? 'none' : 'inherit',
        boxShadow: 'none',
        '& .MuiSnackbarContent-message': {
          width: '100% !important',
          padding: 0,
        },
      }}
      message={messageToDisplay}
      {...other}
    />
  );
};

export const AMNSnackbar = (props: IAMNSnackbarProps) => {
  const {
    autoHideDuration,
    id,
    open = false,
    variant,
    message = '',
    displayAs = 'common',
    sx = {},
    onClose,
  } = props;

  return open ? (
    <Snackbar
      onClose={() => onClose?.(false)}
      autoHideDuration={autoHideDuration ?? null}
      resumeHideDuration={autoHideDuration}
      id={id}
      sx={{
        [theme.breakpoints.up('sm')]: { minWidth: '362px' },
        [theme.breakpoints.down('sm')]: { width: '327px' },
        top: displayAs === 'common' ? '66px !important' : '0px !important',
        bottom: 'auto',
        width: 'fit-content',
        position: 'unset',
        '& .MuiSnackbarContent-root': {
          position: 'absolute',
          left: '12px',
          top: '84px',
          right: '12px',
        },
        ...sx,
      }}
      open={open}
    >
      {AlertContent({
        variant,
        message,
      })}
    </Snackbar>
  ) : (
    <></>
  );
};
