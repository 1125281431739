export enum ActivityStatusId {
  WaitingForClearance = 1,
  RequestFileOut = 2,
  Sent = 3,
  DeclinedByTraveler = 4,
  DeclinedByFacility = 5,
  Interviewed = 6,
  RequestCancelled = 7,
  OkToSend = 8,
  OfferByFacility = 9,
  OfferBookedByTraveler = 10,
  TravelerAccepts = 11,
  FacilityConfirms = 12,
  Booked = 13,
  BookingApproved = 14,
  WaitingGoodToGo = 15,
  InterviewScheduled = 16,
  PostponementWarning = 17,
  GoodToGo = 18,
  BookingCanceledByTraveler = 20,
  BookingCanceledByFacility = 21,
  BookingCanceledByAmh = 22,
  OnAssignment = 23,
  AssignmentComplete = 24,
  AssignmentCanceledByTraveler = 25,
  AssignmentCanceledByFacility = 26,
  AssignmentCanceledByAmh = 27,
  PreAssignmentCanceledbySettlement = 28,
  PostAssignmentCanceledbySettlement = 29,
}
