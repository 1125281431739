import React from 'react';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ArrowRightIcon } from 'assets/icons/Arrows/Arrow-Right';
import { theme } from 'styles/theme';
import { ICard } from 'interfaces/PageLayout/ICardListPage';

/**
 *
 * @param cards: ICard[]
 *  Card navigation item that has dynamic icon and titles
 *  If cards are grouped by subcategories, they will be
 *  mapped out here
 *
 *  If a card needs to display a subtitle, make sure to pass
 *  a subtitle through to the ICard card item
 *
 *  To display the icon, make sure you pass a SVG as a JSX Element
 */
export const CardNavItem = ({
  cards,
  selectedId,
  cardNavSx,
}: {
  cards?: ICard[];
  selectedId?: number | string;
  cardNavSx?: SxProps<Theme>;
}) => {
  return (
    <Grid container sx={{ ...cardNavSx }}>
      {cards?.map(card => (
        <Card
          id={`${card?.id}`}
          key={card?.id}
          sx={[
            {
              maxWidth: { lg: '327px' },
              width: '100%',
              minHeight: '60px',
              height: '100%',
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '12px 12px 12px 24px',
              mb: '12px',
              borderRadius: '4px',
              cursor: 'pointer',
              borderStyle: 'solid',
              borderWidth: selectedId === card?.id ? `2px` : `1px`,
              borderColor:
                selectedId === card?.id
                  ? `${theme.palette.system.skyBlue} !important`
                  : theme.palette.system.border,
              [theme.breakpoints.down('sm')]: {
                border: `1px solid ${theme.palette.system.border}`,
              },
            },
            theme => ({
              '&:hover': {
                backgroundColor:
                  selectedId === card?.id
                    ? theme?.palette?.system?.white
                    : theme?.palette?.system?.hover,
              },
            }),
            ...(Array.isArray(card.sx) ? card.sx : [card.sx ?? {}]),
          ]}
          onClick={card?.onClick && card?.onClick}
        >
          {card?.icon && <Box pr={2}>{card?.icon}</Box>}

          <Grid container sx={{ maxWidth: { lg: '215px' }, width: '100%' }}>
            {card?.title && (
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  variant="body1"
                  color="system.midnightBlue"
                  sx={{
                    maxWidth: { lg: '270px' },
                    width: '100%',
                    wordBreak: 'break-word',
                  }}
                >
                  {card?.title}
                  {!!(card?.numberOfItems || card?.numberOfItems === 0) && (
                    <span
                      style={{
                        paddingLeft: '6px',
                        color: theme.palette.system.coolGray,
                      }}
                    >
                      {`(${card?.numberOfItems})`}
                    </span>
                  )}
                </Typography>
              </Grid>
            )}
            {card?.subtitle && (
              <Grid item xs={12} pt="2px">
                <Typography
                  variant="body1"
                  color="system.coolGray"
                  sx={{
                    maxWidth: '250px',
                    width: '100%',
                    wordBreak: 'break-word',
                  }}
                >
                  {card?.subtitle}
                </Typography>
              </Grid>
            )}
          </Grid>
          {!!card?.visualIndicator && (
            <Grid
              item
              xs={card?.visualIndicator?.content === 'REJECTED' ? 3.5 : 0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingRight="6px"
              mx={card?.visualIndicator?.content === 'REJECTED' ? '12px' : 0}
            >
              <Badge
                badgeContent={card?.visualIndicator?.content}
                color="error"
                sx={{
                  ...card.visualIndicator.sx,
                }}
                max={10000000}
              />
            </Grid>
          )}
          <ArrowRightIcon
            htmlColor={theme.palette.system.coolGray}
            sx={{ width: '16px', height: '16px', ml: '12px' }}
          />
        </Card>
      ))}
    </Grid>
  );
};
