/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop and https://www.npmjs.com/package/react-image-crop demo
 */

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export const getCroppedImg = (image, pixelCrop, rotation, fileType) => {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(pixelCrop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(pixelCrop.height * scaleY * pixelRatio);

    if (pixelCrop.height === 0 && pixelCrop.width === 0) {
      return image.src;
    }

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = pixelCrop.x * scaleX;
    const cropY = pixelCrop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    // ctx.rotate(rotateRads);
    ctx.rotate(getRadianAngle(rotation));
    // 2) Scale the image
    ctx.scale(1, 1);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    );

    // As Base64 string
    return canvas.toDataURL(fileType, 0.75);
  } catch (e) {
    throw new Error('Error');
  }
};
