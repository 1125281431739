import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IJobDetailResponse } from 'interfaces/Jobs/IJobDetailResponse';
import { IJobDetailsData } from './jobsState';

const initialState: IJobDetailsData = {
  jobDetailsData: {
    jobId: 0,
    priorityTypes: [],
    facility: {
      address: {
        location: {
          coordinates: [0, 0],
        },
      },
    },
  },
};

const fetchJobDetailsAction = createAction<number>('FETCH_JOB_DETAILS');

const jobDetailSlice = createSlice({
  name: 'jobDetails',
  initialState,
  reducers: {
    setJobDetail(state, action: PayloadAction<IJobDetailResponse>) {
      state.jobDetailsData = action.payload;
    },
    resetJobDetails(state) {
      state.jobDetailsData = initialState.jobDetailsData;
    },
    setAppliedDateForJob(state) {
      state.jobDetailsData!.appliedDate = new Date(Date.now()).toISOString();
    },
  },
});
export const jobDetailsActions = {
  fetchJobDetailsAction,
  ...jobDetailSlice.actions,
};
export default jobDetailSlice.reducer;
