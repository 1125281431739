import React from 'react';
import { theme } from 'styles/theme';

export default function ReimbursementIcon({
  color = theme.palette.system.skyBlue,
  height = '6',
  width = '6',
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls-Icons-Reimbursement"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2270"
        data-name="Rectangle 2270"
        width={width ?? '38'}
        height={height ?? '38'}
        fill={color ?? '#003c69'}
        opacity="0"
      />
      <g id="Group_8895" data-name="Group 8895" transform="translate(1 1)">
        <path
          id="Path_6610"
          data-name="Path 6610"
          d="M447.567,406.287a17.994,17.994,0,0,1-16.452-10.5,18.274,18.274,0,0,1-1.581-8.994,17.892,17.892,0,0,1,14.038-16.057,19.872,19.872,0,0,1,4.1-.444,17.787,17.787,0,0,1,11.174,4.04l1.075.793v-1.609c0-.422,0-.818,0-1.214a1.343,1.343,0,0,1,1.354-1.376,1.291,1.291,0,0,1,1.331,1.329q.036,3.4.016,6.806a1.27,1.27,0,0,1-1.214,1.229q-2.8.039-5.6.041l-1.165,0a1.337,1.337,0,1,1-.033-2.672c.463-.023.928-.025,1.392-.027l.719-.007a1.1,1.1,0,0,0,.655-.212l.59-.411-.581-.425-.459-.346c-.344-.259-.688-.519-1.048-.754a15.328,15.328,0,1,0-11.515,27.821,16.061,16.061,0,0,0,3.116.309,15.285,15.285,0,0,0,15.012-12.253,13.929,13.929,0,0,0,.238-4.433,1.473,1.473,0,0,1,.835-1.7,1.361,1.361,0,0,1,.489-.094,1.384,1.384,0,0,1,1.349,1.3,17.916,17.916,0,0,1-10.338,18.18A17.357,17.357,0,0,1,447.567,406.287Z"
          transform="translate(-429.478 -370.287)"
          fill={color ?? '#003c69'}
        />
        <path
          id="Path_814"
          data-name="Path 814"
          d="M448.032,387.1h-1.87a1.933,1.933,0,0,1-.093-3.864h3.825a.876.876,0,1,0,.151-1.746h0a.746.746,0,0,0-.151,0H447.96v-1.933a.876.876,0,0,0-1.747,0v1.933h-.064a3.684,3.684,0,0,0,0,7.355h1.887a1.933,1.933,0,0,1,0,3.863h-3.747a.876.876,0,1,0-.151,1.746.746.746,0,0,0,.151,0h1.934v1.933a.877.877,0,0,0,1.747.151h0a.926.926,0,0,0,0-.151v-1.933h.062a3.684,3.684,0,0,0,0-7.355Z"
          transform="translate(-429.091 -370.045)"
          fill={color ?? '#003c69'}
        />
      </g>
    </svg>
  );
}
