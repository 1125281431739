import React, { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DocumentPreview from 'components/common/DocumentPreview/DocumentPreview';
import { CredSubType } from 'enums/credSubTypeConfig';
import { getFileExtension } from 'utils/helper';
import { getMMMddyyyy } from 'helpers/dateFormatter';
import { RequirementTypes } from '../../../enums/requirementTypes';

export const UploadedCredentialDocuments = ({
  selectedOpenRequirementsItem,
}: {
  selectedOpenRequirementsItem: any;
}) => {
  const getDocumentPreview = useCallback(
    (src: string, alt: string) => {
      return (
        <Box
          id="document-preview"
          sx={{
            width: '100%',
            height: alt?.endsWith('pdf' || 'doc' || 'docx') ? '70vh' : 'auto',
            mb: selectedOpenRequirementsItem?.documents?.length > 1 ? 7 : 0,
          }}
        >
          <DocumentPreview width="100%" height="100%" src={src} alt={alt} />
        </Box>
      );
    },
    [selectedOpenRequirementsItem?.documents],
  );

  const secureView = useMemo(() => {
    return (
      <>
        <Box id="secure-view">
          <Typography
            variant="subtitle1"
            color="system.grayText"
            sx={{
              font: 'normal normal 600 14px/19px Open Sans',
              marginBottom: '12px',
            }}
            data-testid="title"
          >
            {selectedOpenRequirementsItem?.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="system.coolGray"
            sx={{ font: 'normal normal normal 14px/20px Open Sans' }}
            data-testid="submit-date"
          >
            Submitted {getMMMddyyyy(selectedOpenRequirementsItem?.submitDate)}
          </Typography>
        </Box>
        <Grid
          container
          sx={{ justifyContent: 'center', alignItems: 'center', pt: '65%' }}
        >
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="system.coolGray"
              textAlign="center"
              data-testid="image-text"
            >
              <i>Image is disabled due to security reasons.</i>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }, [selectedOpenRequirementsItem]);

  return (
    <>
      {selectedOpenRequirementsItem?.isSecureDocument ||
      selectedOpenRequirementsItem?.requirementTypeId ===
        RequirementTypes.SOCIAL_SECURITY_CARD ? (
        secureView
      ) : selectedOpenRequirementsItem?.documents?.length > 0 ? (
        selectedOpenRequirementsItem.documents.map(doc =>
          selectedOpenRequirementsItem?.credentialSubtype ===
          CredSubType.UserCredential ? (
            <>{getDocumentPreview(doc, getFileExtension(doc))}</>
          ) : (
            <>
              {getDocumentPreview(
                doc?.credentialDocument?.url,
                doc?.documentType,
              )}
            </>
          ),
        )
      ) : (
        <Grid container justifyContent="center" alignItems="center" pt="65%">
          <Typography variant="subtitle1" color="system.coolGray">
            <i>No document provided.</i>
          </Typography>
        </Grid>
      )}
    </>
  );
};
