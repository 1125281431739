import makeStyles from '@mui/styles/makeStyles';

export const createCardStyles = makeStyles(theme => ({
  filterContainer: {
    '& #dropdown-selector': {
      color: theme?.palette?.system?.midnightBlue,
    },
    '& .MuiInputAdornment-root > p': {
      color: theme?.palette?.system?.midnightBlue,
    },
  },
}));
