import React from 'react';
import { ISeeMoreProps } from 'interfaces/Props/ISeeMoreProps';
import { ShowMoreOrLessLink } from 'components/ScheduleEventInfoPanel/ScheduleShift.style';

const ShowMoreLink: React.FC<ISeeMoreProps> = props => {
  const { moreOrLess, onClickShowMore, moreText } = props;
  return (
    <ShowMoreOrLessLink
      className={moreOrLess ? 'more' : 'less'}
      onClick={onClickShowMore()}
      color="system.marineBlue"
    >
      {moreOrLess ? moreText || 'Show More' : 'Show Less'}
    </ShowMoreOrLessLink>
  );
};

export default ShowMoreLink;
