import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from 'assets/icons/Search/SearchIcon';
import { AddressComponentTypes } from 'enums/googleAddressComponents';
import { SearchTypes } from 'enums/googleSearchTypeEnum';
import { IPlaceType } from 'interfaces/GoogleLocation/IPlaceType';
import throttle from 'lodash/throttle';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { get, useFormContext } from 'react-hook-form';
import useScrollToValidation from 'utils/hooks/useScrollToValidation';

const googleService = { autoCompleteService: null, placeService: null };

interface IFieldTypeObject {
  type: AddressComponentTypes | AddressComponentTypes[];
  useShortName?: boolean;
}
interface IFieldMap {
  [key: string]:
    | AddressComponentTypes
    | AddressComponentTypes[]
    | IFieldTypeObject
    | IFieldTypeObject[];
}

const CustomPaper = props => {
  return <Paper elevation={3} {...props} />;
};

export function ReactHookFormLocationField(props: {
  type: SearchTypes;
  fieldMap?: IFieldMap;
  resetFields?: string[];
  name: string;
  label: string;
  addressType?: AddressComponentTypes | AddressComponentTypes[];
  hideDropdown?: boolean;
  hideIcon?: boolean;
  country?: string;
  preventFormUpdateUntilBlur?: boolean;
  useShortName?: boolean;
  displayLongName?: boolean;
}) {
  const {
    register,
    setValue: setFormValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const [inputValue, setInputValue] = useState(watch(props.name));
  const [focused, setFocus] = useState(false);
  const [options, setOptions] = useState<readonly IPlaceType[]>([]);
  const inputRef = useRef<null | HTMLElement>(null);
  const [longName, setLongName] = useState('');
  const error = get(errors, props.name ?? '');
  const watchedVal = watch(props.name);

  useScrollToValidation({ name: props.name });

  useEffect(() => {
    setInputValue(watchedVal);

    if (watchedVal && errors[props.name]) {
      clearErrors(props.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedVal]);

  const allFieldsForFetch: IFieldMap = useMemo(() => {
    if (props.addressType) {
      return {
        [props.name]: {
          type: props.addressType,
          useShortName: !!props.useShortName,
        },
        ...(props.fieldMap || {}),
      };
    }

    return props.fieldMap || {};
  }, [props.addressType, props.fieldMap, props.name, props.useShortName]);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: readonly IPlaceType[]) => void,
        ) => {
          (googleService.autoCompleteService as any).getPlacePredictions(
            {
              ...request,
              componentRestrictions: props.country
                ? { country: [props.country] }
                : {},
              types: [props.type],
            },
            callback,
          );
        },
        200,
      ),
    [props.country, props.type],
  );

  const fetchDetails = useMemo(
    () =>
      throttle(
        (
          request: { placeId: string },
          callback: (results?: readonly IPlaceType[]) => void,
        ) => {
          (googleService.placeService as any).getDetails(
            {
              ...request,
              fields: [
                'name',
                'formatted_address',
                'place_id',
                'address_components',
              ],
            },
            callback,
          );
        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!googleService.autoCompleteService && (window as any).google) {
      googleService.autoCompleteService = new (
        window as any
      ).google.maps.places.AutocompleteService();
      googleService.placeService = new (
        window as any
      ).google.maps.places.PlacesService(document.createElement('div'));
    }
    if (!googleService.autoCompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly IPlaceType[]) => {
      if (active) {
        let newOptions: readonly IPlaceType[] = [];

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const onTypeChange = useCallback(
    (event: any, newValue: IPlaceType | null, reason) => {
      if (newValue && Object.keys(allFieldsForFetch).length) {
        fetchDetails({ placeId: newValue?.place_id || '' }, (details: any) => {
          const { address_components, name } = details || {};
          const smallCache = {
            [AddressComponentTypes.NAME]: { long_name: name },
          };
          const compoundTypes: {
            [field: string]: AddressComponentTypes[] | IFieldTypeObject[];
          } = {};

          address_components?.some(part => {
            const fieldType = part.types[0];
            if (!smallCache[fieldType]) {
              smallCache[fieldType] = {};

              smallCache[fieldType]['long_name'] = part.long_name;

              if (part.short_name) {
                smallCache[fieldType]['short_name'] = part.short_name;
              }
            }

            switch (fieldType) {
              case AddressComponentTypes.CITY2:
                if (smallCache[AddressComponentTypes.CITY]) {
                  smallCache[fieldType] =
                    smallCache[AddressComponentTypes.CITY];
                } else if (smallCache[AddressComponentTypes.CITY3]) {
                  smallCache[fieldType] =
                    smallCache[AddressComponentTypes.CITY3];
                }
                break;
              case AddressComponentTypes.CITY3:
                if (smallCache[AddressComponentTypes.CITY]) {
                  smallCache[fieldType] =
                    smallCache[AddressComponentTypes.CITY];
                }
                break;
              case AddressComponentTypes.CITY:
                smallCache[AddressComponentTypes.CITY2] = smallCache[fieldType];
                smallCache[AddressComponentTypes.CITY3] = smallCache[fieldType];
                break;
            }
          });

          if (!smallCache[AddressComponentTypes.CITY]) {
            if (smallCache[AddressComponentTypes.CITY2]) {
              smallCache[AddressComponentTypes.CITY] =
                smallCache[AddressComponentTypes.CITY2];
            } else if (smallCache[AddressComponentTypes.CITY3]) {
              smallCache[AddressComponentTypes.CITY] =
                smallCache[AddressComponentTypes.CITY3];
            }
          }

          Object.keys(allFieldsForFetch).map(field => {
            let fieldType:
              | AddressComponentTypes
              | AddressComponentTypes[]
              | IFieldTypeObject
              | IFieldTypeObject[] = allFieldsForFetch[field];

            let useShortName = false;
            if (typeof fieldType === 'object') {
              useShortName =
                (fieldType as IFieldTypeObject)?.useShortName || false;
              fieldType = (fieldType as IFieldTypeObject)?.type;
            }

            if (typeof fieldType === 'string') {
              if (smallCache.hasOwnProperty(fieldType)) {
                let value = smallCache[fieldType].long_name;
                setLongName(value);
                if (useShortName) {
                  value = smallCache[fieldType].short_name || value;
                }
                if (field === props.name) {
                  setInputValue(value);
                }
                setFormValue(field, value);
                return;
              } else {
                setFormValue(field, '');
              }
            } else {
              compoundTypes[field] = fieldType;
            }
          });

          Object.keys(compoundTypes).map(field => {
            const fieldTypes: AddressComponentTypes[] | IFieldTypeObject[] =
              compoundTypes[field];

            const compoundField: string = (
              fieldTypes as IFieldTypeObject[]
            ).reduce(
              (
                prev: string,
                curr: AddressComponentTypes | IFieldTypeObject,
              ): string => {
                let fieldType: AddressComponentTypes | IFieldTypeObject = curr;

                let useShortName = false;
                if (typeof fieldType === 'object') {
                  useShortName =
                    (fieldType as IFieldTypeObject)?.useShortName || false;
                  fieldType = (fieldType as IFieldTypeObject)
                    ?.type as AddressComponentTypes;
                }
                if (smallCache[fieldType]) {
                  let value = smallCache[fieldType].long_name;

                  if (useShortName) {
                    value = smallCache[fieldType].short_name || value;
                  }

                  if (prev) {
                    return `${prev} ${value}`;
                  } else {
                    return value;
                  }
                } else if (
                  (curr as AddressComponentTypes) ===
                  AddressComponentTypes.COMMA
                ) {
                  return `${prev}, `;
                }

                return prev;
              },
              '',
            );
            if (field === props.name) {
              setInputValue(compoundField);
            }
            setFormValue(field, compoundField);
          });

          props.resetFields?.map(field => setFormValue(field, ''));

          inputRef.current?.blur();
        });
      } else if (reason === 'clear') {
        setInputValue('');
        setFormValue(props.name, '');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allFieldsForFetch,
      fetchDetails,
      props.name,
      props.resetFields,
      setFormValue,
    ],
  );

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {!props.hideIcon && (
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(15px, 15px)',
              zIndex: 1,
            }}
          >
            <SearchIcon size={24} />
          </Box>
        )}
        <Autocomplete
          id={`${props.name}-location-search`}
          sx={{
            '& label': {
              left: props.hideIcon ? 0 : '40px',
            },
            '& .MuiInputBase-root > input': {
              paddingLeft: props.hideIcon ? 0 : '52px !important',
            },
            [`& #${props.name}-location-search`]: {
              paddingLeft: props.hideIcon ? '' : '46px', // 10px to reach search icon, 24px for icon width, 12px for space from icon
            },
            '& .MuiFilledInput-root': {
              paddingLeft: props.hideIcon ? '' : '0px', // removes the initial input offset
            },
            [`& #${props.name}-location-search-label:is([data-shrink="true"])`]:
              {
                transform: props.hideIcon
                  ? ''
                  : 'translate(14px, 7px) scale(.75)',
              },
            [`& #${props.name}-location-search-label`]: {
              transform: props.hideIcon ? '' : 'translate(14px, 16px) scale(1)',
            },
            '& .MuiAutocomplete-popupIndicator': {
              display: props.hideDropdown ? 'none' : '',
            },
          }}
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.description
          }
          filterOptions={x => x}
          options={options}
          open={options?.length > 0 && focused}
          PaperComponent={CustomPaper}
          autoComplete
          includeInputInList
          filterSelectedOptions
          autoHighlight
          openOnFocus
          value={props.displayLongName ? longName : watch(props.name)}
          onChange={onTypeChange}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          renderInput={params => (
            <>
              <TextField
                {...params}
                {...register(props.name)}
                inputRef={inputRef}
                inputProps={{
                  ...params.inputProps,
                  value: focused
                    ? inputValue
                    : props.displayLongName
                    ? longName.length > 0
                      ? longName
                      : inputValue
                    : watch(props.name),
                  onChange: (e: React.BaseSyntheticEvent) => {
                    setInputValue(e.target?.value || '');
                  },
                }}
                onChange={
                  props.preventFormUpdateUntilBlur
                    ? () => {}
                    : register(props.name)?.onChange
                }
                onBlur={
                  !props.preventFormUpdateUntilBlur
                    ? register(props.name)?.onBlur
                    : e => {
                        setFormValue(props.name, e.target.value);
                        register(props.name)?.onBlur(e);
                      }
                }
                label={props.label}
                fullWidth
                error={!!error}
                helperText={error?.message ?? ''}
                variant="filled"
                InputLabelProps={{
                  shrink: watch(props.name) || focused ? true : false,
                }}
              />
            </>
          )}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option?.place_id}
                style={{ padding: '16px 12px' }}
              >
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="subtitle1" color="text.secondary">
                      {option.description}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Box>
    </>
  );
}
ReactHookFormLocationField.defaultProps = {
  preventFormUpdateUntilBlur: true,
};
