import { http } from 'services/BaseApi';
import { IContactResponse } from 'types/ContactResponse';
import { FaqResponse } from 'types/FAQResponse';

export const getFaqs = async (userId: string): Promise<FaqResponse> => {
  return await http.get<FaqResponse>(`/frequently-asked-questions/${userId}`);
};

export const getContactsOfAssignmentsAndContracts = async (
  userId: string,
): Promise<IContactResponse[]> => {
  return await http.get<string>(`/contacts/${userId}/help`);
};
