import { Link, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const FeaturedCard = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundImage',
})<{
  backgroundImage?: string | undefined;
  sx?: any;
}>(({ theme, backgroundImage, sx }) => ({
  backgroundImage: `url(${backgroundImage})`,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: '290px',
  height: '132px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  fontSize: '20px',
  borderRadius: '10px',
  cursor: 'pointer',
  zIndex: 10,
  position: 'relative',
  ...sx,
}));

export const FeaturedLink = styled(Link)({
  fontSize: '20px',
  fontWeight: 'bold',
  width: '100%',
  height: '100%',
  padding: '24px',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '24px',
  '&.MuiLink-root': {
    color: theme.palette.system.white,
  },
  zIndex: 30,
});

export const Darkoverlay = styled(Link)({
  width: '100%',
  height: '100%',
  zIndex: 20,
  backgroundColor: theme.palette.system.opaqueOverlay,
  backgroundSize: 'cover',
  position: 'absolute',
  borderRadius: '10px',
});
