import styled from 'styled-components';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import makeStyles from '@mui/styles/makeStyles';

const PdfDocument = styled(Document)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PageStyle = makeStyles(theme => ({
  page: {
    display: 'flex',
    margin: '20px',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `1px 0px 18px ${theme?.palette?.system?.border}`,
    [theme?.breakpoints?.down('md')]: {
      margin: '10px',
      width: '90% !important',
      '& .react-pdf__Page__canvas': {
        width: '100% !important',
        height: 'auto !important',
      },
    },
    [theme?.breakpoints?.up('md')]: {
      margin: '20px',
      width: '640px !important',
      '& .react-pdf__Page__canvas': {
        width: '640px !important',
        height: 'auto !important',
      },
    },
  },
}));

export { PdfDocument, PageStyle };
