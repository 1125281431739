import React, { useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { authenticationSelector } from 'store/selectors/authSelector';

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated: boolean = useAppSelector(authenticationSelector);

  const getQueryVariables = useCallback(location => {
    const { search } = location || {};

    const propsToPass = {};

    if (search && typeof search === 'string') {
      let queryParams: string[] = search.substring(1).split('&');

      queryParams.map((param: string) => {
        const [key, ...value] = param.split('=');
        if (typeof key === 'string') {
          propsToPass[key.charAt(0)?.toLowerCase() + key?.substring(1)] =
            value?.join('=');
        }
      });
    }
    return propsToPass;
  }, []);

  return (
    <Route
      {...restOfProps}
      render={props => {
        const propPass = getQueryVariables(props?.location);

        return isAuthenticated ? (
          <Component
            setActivePage={restOfProps.setActivePage}
            {...props}
            {...propPass}
          />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
};

export { ProtectedRoute };
