import React from 'react';
import { IButtonProps } from 'interfaces/Props/IButtonProps';
import { StyledButton } from './Button.styles';

/**
 * Common Button Component
 */
export const AppButton = ({
  id,
  type,
  children,
  onClick,
  onKeyDown,
  disabled,
  width,
  variant,
  size,
  sx,
}: IButtonProps) => {
  return (
    <StyledButton
      id={id}
      type={type}
      width={width}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      variant={variant}
      size={size}
      sx={sx}
      data-testid={id}
    >
      {children}
    </StyledButton>
  );
};
