import React from 'react';

export const WiselyPayIcon = (
  color?: string,
  height?: string,
  width?: string,
) => {
  return (
    <svg
      id="Controls-icon-Wisely"
      xmlns="http://www.w3.org/2000/svg"
      width={width || '38'}
      height={height || '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2268"
        data-name="Rectangle 2268"
        width={width || '38'}
        height={height || '38'}
        fill="none"
        opacity="0"
      />
      <path
        id="Path_6463"
        data-name="Path 6463"
        d="M-21.75,383.9a23.4,23.4,0,0,1-2.686,3.114c-2.151,1.756-4.784,1.841-7.367,1.49-4.63-.63-7.163-3.3-7.795-8.082a40.764,40.764,0,0,1-.3-5.058q-.038-5.813.063-11.627c.024-1.409.627-1.97,2.009-2.034,1.118-.052,2.241-.035,3.362-.012,1.716.034,2.274.577,2.286,2.332.026,3.726,0,7.452.016,11.179a29.25,29.25,0,0,0,.158,3.15,5.98,5.98,0,0,0,.52,1.823,2.556,2.556,0,0,0,2.539,1.511,2.421,2.421,0,0,0,2.488-1.568,9.6,9.6,0,0,0,.548-2.953c.088-4.438.051-8.879.147-13.316.036-1.652.618-2.117,2.232-2.155,1.083-.026,2.168-.029,3.25,0,1.531.044,2.142.578,2.164,2.108.062,4.327.035,8.655.091,12.982a11.107,11.107,0,0,0,.375,2.775,2.656,2.656,0,0,0,2.8,2.125,2.659,2.659,0,0,0,2.783-2.144,12.943,12.943,0,0,0,.4-3.112c.044-3.952.012-7.9.02-11.856,0-2.43.467-2.882,2.89-2.886.9,0,1.794-.024,2.69.01,1.488.057,2.064.551,2.1,2.044q.106,4.909.052,9.821a54.267,54.267,0,0,1-.3,6.749c-.931,6.813-5.368,8.789-11.117,8.219A7.124,7.124,0,0,1-21.75,383.9Z"
        transform="translate(40.908 -355.674)"
        fill={color}
      />
    </svg>
  );
};
