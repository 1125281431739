import { Box, Grid, SxProps, Typography } from '@mui/material';
import { ErrorIcon } from 'assets/icons/Alert/ErrorIcon';
import React from 'react';
import { theme } from 'styles/theme';

export interface IClearanceItemProps {
  id?: string;
  header: string;
  message?: string;
  sx?: SxProps;
  headerProps?: SxProps;
  iconProps?: SxProps;
}

const ClearanceItem = (props: IClearanceItemProps) => {
  return (
    <Box
      id={props.id}
      sx={{
        borderColor: theme.palette.system.border,
        border: '1px solid #D9553D',
        borderRadius: '6px',
        display: 'flex',
        marginTop: '12px',
        marginBottom: '12px',
        padding: '12px',
        ...props.sx,
      }}
    >
      <Grid container sx={{ alignItems: props?.message ? 'normal' : 'center' }}>
        <Grid
          item
          xs={1}
          sx={{
            padding: '0px !important',
            height: '23px',
            width: '23px',
            display: 'contents',
            ...props.iconProps,
          }}
        >
          <ErrorIcon />
        </Grid>
        <Grid item xs={10}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              textAlign: 'left',
              color: '#d9553d',
              paddingLeft: '12px',
              ...props.headerProps,
            }}
            data-testid="clearance-item-header"
          >
            {props.header}
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{ display: 'contents' }}></Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'left',
              color: '#d9553d',
              paddingLeft: '36px',
              paddingRight: '12px',
            }}
            data-testid="clearance-item-message"
          >
            {props.message}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClearanceItem;
