import { Grid, Typography } from '@mui/material';
import CheckBox from 'components/common/CheckBox/CheckBox';
import _ from 'lodash';
import React from 'react';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setShiftLengths } from 'store/slices/user/userPreferenceSlice';
import {
  areAllOptionsChecked,
  areAllOptionsUnchecked,
  handleAllChecked,
  handleAnyChecked,
} from '../utils';

const ShiftLengthForm = ({ setFormDirty }: { setFormDirty?: Function }) => {
  const dispatch = useAppDispatch();

  const shiftLengths = useAppSelector(
    state => state.userPreference.shiftLengths,
  );

  const handleShiftLengthChange = (e, index) => {
    setFormDirty?.(true);
    const anyIdx = shiftLengths.findIndex(option => option.typeId === 0);
    let tempShiftLengths = _.cloneDeep(shiftLengths);

    if (tempShiftLengths[anyIdx].isActive && index === anyIdx) return;

    tempShiftLengths[index].isActive = !tempShiftLengths[index].isActive;

    if (
      tempShiftLengths[index].typeId === 0 &&
      tempShiftLengths[index].isActive
    ) {
      tempShiftLengths = handleAnyChecked(tempShiftLengths);
    } else {
      tempShiftLengths[0].isActive = false;
    }

    if (areAllOptionsChecked(tempShiftLengths)) {
      tempShiftLengths = handleAllChecked(tempShiftLengths);
    } else if (
      areAllOptionsUnchecked(tempShiftLengths) &&
      !tempShiftLengths[0].isActive
    ) {
      return;
    }

    dispatch(setShiftLengths(tempShiftLengths));
  };

  return (
    <Grid
      container
      item
      id="shift-length-form"
      display="flex"
      flexDirection="column"
    >
      <Grid item>
        <Typography
          variant="subtitle1"
          color="system.midnightBlue"
          lineHeight="22px"
          textAlign={'left'}
        >
          Shift Length
        </Typography>
      </Grid>
      <Grid item display="flex" flexDirection="column">
        {shiftLengths.map((shiftLength, index) => (
          <CheckBox
            key={index}
            id={`shiftLength_type_${index}`}
            checked={shiftLength.isActive}
            onChange={e => handleShiftLengthChange(e, index)}
            checklabel={shiftLength.type}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ShiftLengthForm;
