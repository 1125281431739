import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import _isEmpty from 'lodash/isEmpty';
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { object, string } from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { DownloadIcon } from 'assets/icons/DownloadIcon';
import { ContactCardImage } from 'components/ContactCard/ContactCardImage';
import { AMNSnackbar } from 'components/common/Alert/AMNSnackbar';
import { AppCircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { FileUploadModal } from 'components/common/FileUploadModal/FileUploadModal';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { ReactHookFormStateField } from 'components/common/LocationSearch/ReactHookFormStateField';
import { MAIN_FLOWS, MAX_FILE_SIZE } from 'constants/fileTypes';
import { ChecklistDescription } from 'enums/checklistDescriptionType';
import {
  CredentialOpenRequirementsStatus,
  DocumentType,
} from 'enums/credential';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { LinkType } from 'enums/linkConfig';
import { CredentialStatus } from 'enums/status';
import { getFormattedDateOnly } from 'helpers/dateFormatter';
import {
  isCredentialStatusApproved,
  isCredentialStatusOpen,
  isCredentialStatusPending,
} from 'helpers/statusChecker';
import { trackEvent } from 'services/logging/appInsights';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import {
  ICredentialChecklistRequest,
  ILicenseVerificationRequest,
} from 'store/slices/credentials/credentialState';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { theme } from 'styles/theme';
import { NOTE_MESSAGE, TOAST_MESSAGE } from 'constants/helperText';
import { RequirementTypes } from 'enums/requirementTypes';

const DUPLICATE_ID = 1;

export const CredentialsChecklistCard = forwardRef(
  (
    {
      openCredentials,
      showContactsDialog,
      cardSx,
      hideFooterContent = false,
      onSubmitProcess,
      setOnSubmitProcess,
      setDrawerOpen,
      isLicense,
      setActionButtonDisabled,
      isOpenRequirement = false,
      resetSelectedCard,
      actionButtonText,
      setActionButtonText,
    }: {
      openCredentials: { [statusId: number]: any };
      showContactsDialog?: () => void;
      cardSx?: SxProps;
      hideFooterContent?: boolean;
      onSubmitProcess?: any;
      setOnSubmitProcess?: any;
      setDrawerOpen?: any;
      isLicense?: boolean;
      setActionButtonDisabled?: any;
      isOpenRequirement?: boolean;
      resetSelectedCard?: () => void;
      actionButtonText?: string;
      setActionButtonText?: any;
    },
    ref: any,
  ) => {
    const dispatch = useAppDispatch();
    const filesUploaded = useAppSelector(
      state => state.fileUpload.uploadedFiles,
    );
    const fileUploaded = useAppSelector(state => state.fileUpload.uploadedFile);
    const helpContact = useAppSelector(
      state => state.credential?.credentialsChecklist?.helpContact,
    );
    const placementId = useAppSelector(
      state => state.credential?.credentialsChecklist?.placementId,
    );
    const selectedChecklistItem = useAppSelector(
      state => state.credential?.selectedChecklist,
    );
    const [showOtherReqs, setShowOtherReqs] = useState(false);
    const [satisfiedReqs, setSatisfiedReqs] = useState<number[]>([]);
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [amnSnackBarProps, setAmnSnackBarProps] = useState({
      open: false,
      variant: '',
      message: '',
      sx: {},
      autoHideDuration: 0,
    });
    const docUrl = useAppSelector(state => state.credential.formStackData);
    const skillsChecklistUrl = useAppSelector(
      state => state.credential.skillsChecklistUrl,
    );
    const documentUploadResponse = useAppSelector(
      state => state.credential.documentUploadResponse,
    );

    const isReadOnlyCredentialItem =
      selectedChecklistItem?.links &&
      selectedChecklistItem?.links[0]?.url?.includes('ReadOnlyCredential');

    const isRnOrLpn =
      (selectedChecklistItem &&
        selectedChecklistItem?.description?.includes(
          ChecklistDescription.LICENSED_PRACTICAL_NURSE,
        )) ||
      selectedChecklistItem?.description?.includes(
        ChecklistDescription.REGISTERED_NURSE,
      );

    const isRegisteredNurse = selectedChecklistItem?.description?.includes(
      ChecklistDescription.REGISTERED_NURSE,
    );

    const isLicenseChecklistItem = selectedChecklistItem?.description?.includes(
      ChecklistDescription.LICENSURE,
    );

    const isImageVerificationItem = (
      (selectedChecklistItem?.links || [])[0]?.url || ''
    ).includes('ImageVerification');

    const validationSchema = object({
      licenseNumber: isRnOrLpn
        ? string()
            .required('Required')
            .matches(/^[0-9a-zA-Z]*$/, 'This field must be alphanumeric')
        : string().notRequired(),
      stateAbbreviation: isRnOrLpn
        ? string().required('Required')
        : string().notRequired(),
    });

    const formMethods = useForm({
      mode: 'all',
      reValidateMode: 'onChange',
      resolver: yupResolver(validationSchema),
    });
    const {
      control,
      reset,
      watch,
      getValues,
      formState: { isSubmitted },
    } = formMethods;
    const commentValue = watch('comment');

    const licenseNumber = watch('licenseNumber');
    const stateAbbreviation = watch('stateAbbreviation');

    const selectedItem = useRef('');

    const openRequirementItemToRemove = useAppSelector(
      state => state.credential.openRequirementItemToRemove,
    );

    selectedItem.current = openRequirementItemToRemove;

    const setOpenSnackbar = () => {
      dispatch(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.PendingApproval,
          actionButton: {
            text: 'UNDO',
            variant: 'text',
            onAction: () => {
              dispatch(credentialActions.restoreOpenRequirementItem());
              selectedItem.current = '';
            },
          },
        }),
      );
    };

    const onSubmit = (formData: FieldValues) => {
      if (isReadOnlyCredentialItem) {
        setOpenSnackbar();
        dispatch(
          credentialActions.removeOpenRequirementItem({
            credentialId: selectedChecklistItem?.id,
            isChecklistItem: !isOpenRequirement,
            isReadOnlyCredentialItem: isReadOnlyCredentialItem,
          }),
        );
        if (!isOpenRequirement) {
          resetSelectedCard?.();
          dispatch(credentialActions.setSelectedChecklistItem(undefined));
        } else setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
      } else if (isRnOrLpn) {
        const data = {
          placementId: placementId,
          licenseNumber: formData.licenseNumber,
          stateAbbreviation: formData.stateAbbreviation,
          disciplineId: isRegisteredNurse ? 1 : 2,
          credentialId: selectedChecklistItem?.id,
        } as ILicenseVerificationRequest;
        dispatch(credentialActions.submitLicenseForApprovalAction(data));
      } else if (isImageVerificationItem) {
        setActionButtonText('Submitting...');
        let documentTypeId = DocumentType.Identity;
        if (
          selectedChecklistItem?.requirementTypeId ===
            RequirementTypes.DRIVER_LICENSE ||
          (selectedChecklistItem?.links || [])[0]?.url.includes('type=0')
        ) {
          documentTypeId = DocumentType.DriverLicense;
        }
        if (
          fileUploaded?.fileBase64 &&
          fileUploaded?.fileBase64?.content &&
          fileUploaded?.fileBase64?.fileName
        ) {
          dispatch(
            credentialActions.submitIdentificationAction({
              credentialIds: [selectedChecklistItem?.id],
              placementId: placementId,
              docFiles: {
                documentTypeId: documentTypeId,
                files: [fileUploaded.fileBase64],
              },
            }),
          );
        }
      } else {
        if (
          filesUploaded &&
          !filesUploaded?.some(
            x => !x?.fileBase64?.content || !x?.fileBase64?.fileName,
          )
        ) {
          dispatch(
            credentialActions.submitCredentialForApprovalAction({
              placementId,
              comments: formData?.comment,
              credentialIds: [selectedChecklistItem?.id].concat(satisfiedReqs),
              isLicense: isLicense || isLicenseChecklistItem,
            } as ICredentialChecklistRequest),
          );
        }
      }
      if (!isReadOnlyCredentialItem && !isImageVerificationItem) {
        if (isOpenRequirement) setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
      }
    };

    useEffect(() => {
      if (openRequirementItemToRemove.length > 0) {
        setTimeout(() => {
          let credentialId = isOpenRequirement
            ? openRequirementItemToRemove[0]?.assignmentCredential.id
            : openRequirementItemToRemove[0]?.id;
          if (
            selectedItem.current.length > 0 &&
            !!credentialId &&
            !!placementId
          ) {
            dispatch(
              credentialActions.setCredentialStatusAction({
                placementId,
                credentialId,
              }),
            );
          }
          selectedItem.current = '';
        }, 6000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openRequirementItemToRemove]);

    useEffect(() => {
      if (
        documentUploadResponse?.errorMessage &&
        documentUploadResponse?.errorMessage?.length > 0
      ) {
        setActionButtonText('Submit for Approval');
        setAmnSnackBarProps({
          open: true,
          message: documentUploadResponse?.errorMessage ?? '',
          variant: 'error',
          sx: {
            '& .MuiSnackbarContent-root': {
              maxWidth: { xs: '327px', sm: '400px' },
              width: '100%',
              position: 'absolute',
              top: '76px',
            },
          },
          autoHideDuration: 6000,
        });
        dispatch(fileUploadsActions.reset());
      } else if (documentUploadResponse?.success === true) {
        setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
        setAmnSnackBarProps({
          open: false,
          variant: '',
          message: '',
          sx: {},
          autoHideDuration: 0,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUploadResponse]);

    useEffect(() => {
      //This is formstack message event handler
      const formStackMessegeHandler = event => {
        if (event?.data === 'fs-form-submit') {
          setTimeout(() => {
            dispatch(
              openAlert({
                variant: 'success',
                message: TOAST_MESSAGE.FormSubmitted,
              }),
            );
          }, 3000);

          if (!!placementId && !!selectedChecklistItem?.id) {
            dispatch(
              credentialActions.setCredentialStatusAction({
                placementId: placementId,
                credentialId: selectedChecklistItem?.id,
              }),
            );
          }
          dispatch(credentialActions.getSubmissions());
          dispatch(credentialActions.setSelectedChecklistItem(undefined));
        }
      };
      window.addEventListener('message', formStackMessegeHandler);
      return () => {
        window.removeEventListener('message', formStackMessegeHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
      () => {
        if (onSubmitProcess) {
          onSubmit(getValues());
          setOnSubmitProcess?.(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onSubmitProcess, setOnSubmitProcess],
    );

    // Skills Checklist card event listener
    useEffect(() => {
      //This is formstack message event handler
      const formStackMessegeHandler = event => {
        if (
          event?.data?.action === 'close' ||
          event?.data?.action === 'cancel'
        ) {
          dispatch(
            openAlert({
              variant: 'success',
              message: TOAST_MESSAGE.SkillsChecklistSubmitted,
            }),
          );
          if (!!selectedChecklistItem?.id && !!placementId)
            dispatch(
              credentialActions.setCredentialStatusAction({
                placementId: placementId,
                credentialId: selectedChecklistItem?.id,
              }),
            );
          dispatch(credentialActions.getSubmissions());
          dispatch(credentialActions.getCredentialDetailAction(true));
          dispatch(credentialActions.setSelectedChecklistItem(undefined));
        }
      };
      window.addEventListener('message', formStackMessegeHandler);
      return () => {
        window.removeEventListener('message', formStackMessegeHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (
        selectedChecklistItem?.links &&
        selectedChecklistItem?.links?.length > 0
      ) {
        setPdfUrl(selectedChecklistItem?.links[0]?.url);
      }
    }, [selectedChecklistItem]);

    useEffect(() => {
      setShowOtherReqs(false);
      setSatisfiedReqs([]);
      reset({
        satisfy_other_reqs: false,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChecklistItem]);

    useEffect(() => {
      if (documentUploadResponse?.errorMessage === null) {
        setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
        dispatch(credentialActions.resetDocumentUploadResponse());
        dispatch(credentialActions.getSubmissions());
      }
    }, [dispatch, setDrawerOpen, onSubmitProcess, documentUploadResponse]);

    useEffect(() => {
      setActionButtonText('Submit for Approval');
      return () => {
        setShowOtherReqs(false);
        setSatisfiedReqs([]);
        reset({
          satisfy_other_reqs: false,
        });
        dispatch(fileUploadsActions.reset());
        dispatch(credentialActions.resetDocumentUploadResponse());
        dispatch(credentialActions.setSelectedChecklistItem(undefined));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!!filesUploaded?.length || fileUploaded) {
        dispatch(
          openAlert({
            variant: 'success',
            message: TOAST_MESSAGE.DocumentUploaded,
          }),
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesUploaded, fileUploaded]);

    useEffect(() => {
      if (isOpenRequirement) {
        if (
          (!isRnOrLpn &&
            (filesUploaded || [])?.length === 0 &&
            _isEmpty(commentValue) &&
            !isImageVerificationItem &&
            !isReadOnlyCredentialItem) ||
          (isRnOrLpn &&
            licenseNumber?.length < 1 &&
            stateAbbreviation?.length < 1 &&
            !isImageVerificationItem &&
            !isReadOnlyCredentialItem) ||
          (isImageVerificationItem && !fileUploaded) ||
          (isImageVerificationItem &&
            documentUploadResponse?.errorMessage &&
            documentUploadResponse.errorMessage.length > 0) ||
          (actionButtonText === 'Submitting...' && _isEmpty(commentValue))
        ) {
          setActionButtonDisabled(true);
        } else {
          setActionButtonDisabled(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filesUploaded,
      commentValue,
      licenseNumber,
      stateAbbreviation,
      isOpenRequirement,
      isRnOrLpn,
      isReadOnlyCredentialItem,
      isImageVerificationItem,
      fileUploaded,
      documentUploadResponse,
      actionButtonText,
    ]);

    // AppInsights Tracking: Navigating to Assignment Credential Checklist page
    useEffect(() => {
      trackEvent('CredentialChecklistPage_Viewed');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openCredsOrderedArray = useMemo(() => {
      let tempCred = Object.assign({}, openCredentials);
      delete tempCred[CredentialStatus.Pending];
      delete tempCred[CredentialStatus.Accepted];

      return Object.keys(tempCred || {}).sort(
        (key1, key2) =>
          (((openCredentials || {})[key1] || {}).order || -1) -
          (((openCredentials || {})[key2] || {}).order || -1),
      );
    }, [openCredentials]);

    const showAdditionalChecklistItems = useMemo(() => {
      let credCount = 0;
      openCredsOrderedArray.forEach(
        key => (credCount += openCredentials[key]?.items?.length),
      );

      return (
        credCount > 1 &&
        isCredentialStatusOpen(selectedChecklistItem?.statusId || -1)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openCredsOrderedArray, selectedChecklistItem]);

    return (
      <FormProvider {...formMethods}>
        <Card
          id="credential-drawer"
          ref={ref}
          sx={{
            padding: { xs: 0, sm: '24px' },
            marginBottom: { xs: 0, sm: '36px' },
            borderRadius: '10px',
            boxShadow: {
              sm: `0px 1px 15px ${theme?.palette?.system?.darkShadowGray}`,
            },
            overflow: 'visible',
            ...cardSx,
          }}
        >
          {(docUrl || skillsChecklistUrl) && !isOpenRequirement ? (
            <Grid
              container
              sx={{
                position: { xs: 'absolute', sm: 'inherit' },
                left: { xs: 0, sm: 'auto' },
                height: { xs: 'calc(100% - 80px)', sm: '80vh' },
              }}
            >
              <iframe
                src={docUrl.url ?? skillsChecklistUrl}
                width={'100%'}
                title={'formstack-iframe'}
              />
            </Grid>
          ) : !!selectedChecklistItem ? (
            <>
              {amnSnackBarProps.open && (
                <AMNSnackbar
                  id="credential-checklist-dynamic-alert-bar"
                  open={amnSnackBarProps.open}
                  variant={amnSnackBarProps.variant}
                  message={amnSnackBarProps.message || ''}
                  sx={amnSnackBarProps.sx}
                  autoHideDuration={amnSnackBarProps.autoHideDuration}
                  onClose={() => {
                    setAmnSnackBarProps(() => ({
                      open: false,
                      variant: '',
                      message: '',
                      sx: {},
                      autoHideDuration: 0,
                    }));
                  }}
                />
              )}
              {selectedChecklistItem?.statusId ===
                CredentialOpenRequirementsStatus.Rejected &&
                selectedChecklistItem?.reasonId !== DUPLICATE_ID && (
                  <AMNSnackbar
                    id="credential-checklist-rejected-alert-bar"
                    open={
                      selectedChecklistItem?.statusId ===
                        CredentialOpenRequirementsStatus.Rejected &&
                      selectedChecklistItem?.reasonId !== DUPLICATE_ID
                    }
                    variant="rejected"
                    message={selectedChecklistItem?.reason || ''}
                    sx={{
                      '& .MuiSnackbarContent-root': {
                        position: 'relative',
                        left: '0',
                        top: '0',
                        right: '0',
                        width: '100%',
                      },
                      margin: '-18px -18px 24px -18px',
                      width: 'calc(100% + 36px) !important',
                    }}
                  />
                )}
              <Grid container justifyContent="space-between">
                <Grid
                  container
                  item
                  id="category-description-expiration"
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexFlow: 'nowrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <Typography
                      sx={{
                        marginBottom: '12px',
                        textTransform: 'uppercase',
                      }}
                      variant="body1"
                      color="system.coolGray"
                      data-testid="credentials-category"
                    >
                      {selectedChecklistItem?.category}
                    </Typography>
                    <Typography
                      sx={{ marginBottom: '12px' }}
                      variant="subtitle1"
                      color="system.midnightBlue"
                      display={{ xs: 'none', sm: 'block' }}
                      data-testid="credentials-description"
                    >
                      {selectedChecklistItem?.description}
                    </Typography>
                    {selectedChecklistItem?.expirationDate && (
                      <Typography
                        fontWeight="600"
                        variant="body1"
                        color="system.black"
                        data-testid="credentials-expiration-date"
                      >
                        {`Due ${getFormattedDateOnly(
                          selectedChecklistItem?.expirationDate || '',
                          'MMM DD, yyyy',
                        )}`}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid
                  item
                  id="credential-instructions"
                  xs={12}
                  color="system.coolGray"
                  fontSize="14px"
                  mt={2}
                >
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {selectedChecklistItem?.instructions}
                  </span>
                </Grid>

                {selectedChecklistItem.linkTypeId === Number(LinkType.PDF) && (
                  <Grid item xs={12} pt={4}>
                    <Button
                      id="download-pdf"
                      variant="secondary"
                      sx={{ maxWidth: '650px', width: '100%' }}
                      onClick={() => {
                        dispatch(credentialActions.getPdfContentAction(pdfUrl));
                        setAmnSnackBarProps({
                          open: true,
                          message: 'PDF has been downloaded.',
                          variant: 'success',
                          sx: {
                            '& .MuiSnackbarContent-root': {
                              maxWidth: { xs: '327px', sm: '400px' },
                              width: '100%',
                              position: 'absolute',
                              top: '76px',
                            },
                          },
                          autoHideDuration: 6000,
                        });
                      }}
                    >
                      <DownloadIcon width="24px" height="24px" />
                      Download PDF
                    </Button>
                  </Grid>
                )}

                {!isReadOnlyCredentialItem &&
                  !isRnOrLpn &&
                  isCredentialStatusOpen(
                    selectedChecklistItem?.statusId || -1,
                  ) && (
                    <Grid container item xs={12} mt="24px" pb="24px">
                      <FileUploadModal
                        canUploadMultiple={!isImageVerificationItem}
                        id={'upload-credential'}
                        label={
                          isImageVerificationItem
                            ? selectedChecklistItem?.requirementTypeId ===
                                RequirementTypes.DRIVER_LICENSE ||
                              (selectedChecklistItem?.links ||
                                [])[0]?.url.includes('type=0')
                              ? `Add Driver's License`
                              : 'Add Identification'
                            : 'Add Documents'
                        }
                        isSubmitted={isSubmitted}
                        customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
                        acceptedFileTypes={MAIN_FLOWS}
                        onUploadedFile={() => {
                          if (isImageVerificationItem)
                            dispatch(
                              credentialActions.resetDocumentUploadResponse(),
                            );
                        }}
                      />
                    </Grid>
                  )}
                {!isReadOnlyCredentialItem &&
                  !isRnOrLpn &&
                  (filesUploaded || []).length > 0 &&
                  showAdditionalChecklistItems &&
                  isCredentialStatusOpen(
                    selectedChecklistItem?.statusId || -1,
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: '24px',
                        pb: '24px',
                        mt: '12px',
                        borderTop: `2px solid ${theme?.palette?.system?.border}`,
                      }}
                    >
                      <FormControlLabel
                        id={`satisfy-other-reqs`}
                        control={
                          <Controller
                            name={'satisfy_other_reqs'}
                            control={control}
                            render={({ field: { value, ...field } }) => (
                              <Checkbox
                                color="secondary"
                                checked={showOtherReqs}
                                onChange={e =>
                                  setShowOtherReqs(e.target.checked)
                                }
                              />
                            )}
                          />
                        }
                        label="Does this upload satisfy other Credential Checklist requirements?"
                        labelPlacement="end"
                        sx={{ color: theme?.palette?.system?.coolGray }}
                      />
                    </Grid>
                  )}
                {showOtherReqs && showAdditionalChecklistItems && (
                  <>
                    <Grid item xs={12} mt="12px">
                      <Typography variant="body1" color="system.coolGray">
                        SELECT CHECKLIST ITEMS
                      </Typography>
                    </Grid>
                    {openCredsOrderedArray.map(
                      (key, idx) =>
                        openCredentials[key]?.items?.length > 0 && (
                          <Grid container item xs={12} key={key}>
                            <Typography
                              key={key}
                              variant="subtitle1"
                              color="system.midnightBlue"
                              textTransform="capitalize"
                              mt="24px"
                              mb="3px"
                              width="100%"
                            >
                              {openCredentials[key]?.title}
                            </Typography>
                            <div style={{ paddingLeft: '24px' }}>
                              {openCredentials[key]?.items.map(c => (
                                <FormControlLabel
                                  key={c?.id}
                                  id={`open-credential-checklist-item-${c?.id}`}
                                  control={
                                    <Controller
                                      name={'open_credentials'}
                                      control={control}
                                      render={({
                                        field: { value, ...field },
                                      }) => (
                                        <Checkbox
                                          color="secondary"
                                          defaultChecked={
                                            selectedChecklistItem?.id === c?.id
                                          }
                                          disabled={
                                            selectedChecklistItem?.id === c?.id
                                          }
                                          onChange={e =>
                                            e.target.checked
                                              ? setSatisfiedReqs(reqs => {
                                                  reqs.push(c?.id);
                                                  return reqs;
                                                })
                                              : setSatisfiedReqs(reqs =>
                                                  reqs.filter(r => r !== c?.id),
                                                )
                                          }
                                        />
                                      )}
                                    />
                                  }
                                  label={c?.title}
                                  labelPlacement="end"
                                  sx={{
                                    width: '100%',
                                    color: theme?.palette?.system?.midnightBlue,
                                  }}
                                />
                              ))}
                            </div>
                          </Grid>
                        ),
                    )}
                  </>
                )}
                {isRnOrLpn &&
                  !isCredentialStatusPending(
                    selectedChecklistItem?.statusId || -1,
                  ) &&
                  !isCredentialStatusApproved(
                    selectedChecklistItem?.statusId || -1,
                  ) && (
                    <>
                      <Typography
                        pt={4}
                        pb={2}
                        variant="subtitle1"
                        color="system.midnightBlue"
                        display={{ xs: 'none', sm: 'block' }}
                      >
                        License Information
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        pb={4}
                        sx={{ pt: { xs: '12px', sm: 'auto' } }}
                      >
                        <ReactHookFormTextField
                          label={'License Number'}
                          name={'licenseNumber'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ReactHookFormStateField
                          hideIcon
                          name="stateAbbreviation"
                        />
                      </Grid>
                    </>
                  )}
                {isCredentialStatusOpen(
                  selectedChecklistItem?.statusId || -1,
                ) && (
                  <>
                    {!isReadOnlyCredentialItem &&
                      !isRnOrLpn &&
                      !isImageVerificationItem && (
                        <>
                          <Grid
                            item
                            xs={12}
                            mt="12px"
                            pt="24px"
                            mb="12px"
                            sx={{
                              borderTop: `2px solid ${theme?.palette?.system?.border}`,
                            }}
                          >
                            <Controller
                              name="comment"
                              control={control}
                              rules={{ maxLength: 300 }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  multiline
                                  id="credential-checklist-comment"
                                  label="Comments"
                                  variant="filled"
                                  sx={{ width: '100%' }}
                                  inputProps={{ maxLength: 300 }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              p: '0px',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            paddingRight="16px"
                            paddingLeft="16px"
                          >
                            <Typography
                              align="right"
                              alignItems="flex-end"
                              variant="body2"
                              component="div"
                              sx={{ pl: '16px' }}
                            >
                              Optional
                            </Typography>
                            <Typography
                              align="right"
                              alignItems="flex-end"
                              variant="body2"
                              component="div"
                              sx={{ pr: '16px' }}
                            >
                              {commentValue?.length || 0}/{300}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="system.coolGray"
                        mt={4}
                      >
                        {!isReadOnlyCredentialItem &&
                        !isRnOrLpn &&
                        !isLicenseChecklistItem &&
                        !isImageVerificationItem
                          ? NOTE_MESSAGE.CredentialUpload
                          : !isReadOnlyCredentialItem &&
                            !isImageVerificationItem
                          ? NOTE_MESSAGE.LicenseUpload
                          : ''}
                      </Typography>
                    </Grid>
                    {!hideFooterContent && !isReadOnlyCredentialItem && (
                      <Grid
                        container
                        justifyContent="center"
                        mt={4}
                        item
                        xs={12}
                        sx={{
                          display: { xs: 'flex', sm: 'none' },
                        }}
                      >
                        <ContactCardImage
                          id="contact-image"
                          avatarVariant="text"
                          image={
                            helpContact?.contactImage?.url
                              ? helpContact?.contactImage?.url
                              : ''
                          }
                          avatarText={
                            helpContact?.firstName + ' ' + helpContact?.lastName
                          }
                          size={'36px'}
                          avatarTextSize={'17px'}
                        />
                        <Button
                          id="question"
                          variant="text"
                          sx={{
                            cursor: 'pointer',
                            textDecoration: 'none !important',
                            textTransform: 'capitalize',
                            pl: '12px',
                            minWidth: '0',
                            textAlign: 'left',
                            paddingLeft: '12px',
                          }}
                          onClick={() => showContactsDialog?.()}
                        >
                          <Typography variant="body1" color="system.skyBlue">
                            Have Questions? I can help.
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      id="help-contact-card"
                      xs={12}
                      sx={{
                        display: 'flex',
                        flexFlow: 'nowrap',
                        justifyContent: 'space-between',
                        mb: { xs: '0px', sm: '12px' },
                        mt: { xs: '0px', sm: '24px' },
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexFlow: 'nowrap',
                          paddingRight: '6px',
                        }}
                      >
                        {helpContact?.contactImage?.url && (
                          <>
                            <Box
                              sx={{
                                display: { xs: 'none', sm: 'flex' },
                              }}
                            >
                              <ContactCardImage
                                id="contact-image"
                                avatarVariant="text"
                                image={
                                  helpContact?.contactImage?.url
                                    ? helpContact?.contactImage?.url
                                    : ''
                                }
                                avatarText={
                                  helpContact?.firstName +
                                  ' ' +
                                  helpContact?.lastName
                                }
                                size={'36px'}
                                avatarTextSize={'17px'}
                              />
                              <Button
                                variant="text"
                                sx={{
                                  cursor: 'pointer',
                                  textDecoration: 'none !important',
                                  textTransform: 'capitalize',
                                  pl: '12px',
                                  minWidth: '0',
                                  textAlign: 'left',
                                }}
                                onClick={() => showContactsDialog?.()}
                              >
                                <Typography
                                  variant="body1"
                                  color="system.skyBlue"
                                >
                                  Have Questions? I can help.
                                </Typography>
                              </Button>
                            </Box>
                          </>
                        )}
                      </div>
                    </Grid>
                  </>
                )}
                {!hideFooterContent &&
                  !isCredentialStatusOpen(
                    selectedChecklistItem?.statusId || -1,
                  ) && (
                    <Grid item id="comments" xs={12}>
                      {selectedChecklistItem?.comments ? (
                        <>
                          <Typography
                            sx={{ marginBottom: '12px', width: '100%' }}
                            variant="subtitle1"
                            color="system.coolGray"
                          >
                            COMMENTS
                          </Typography>
                          <Typography
                            sx={{ marginBottom: '12px' }}
                            variant="body1"
                            color="system.coolGray"
                          >
                            {selectedChecklistItem?.comments}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          sx={{
                            marginBottom: '12px',
                            width: '100%',
                            fontStyle: 'italic',
                            padding: '48px 12px',
                            textAlign: 'center',
                          }}
                          variant="subtitle1"
                          color="system.coolGray"
                        >
                          No comments entered.
                        </Typography>
                      )}
                    </Grid>
                  )}
              </Grid>
            </>
          ) : (
            <AppCircularProgress />
          )}
        </Card>
      </FormProvider>
    );
  },
);
