export const timezoneDetails = {
  '-04:00': 'Atlantic Standard Time',
  '-05:00': 'Eastern Standard Time',
  '-06:00': 'Central Standard Time',
  '-07:00': 'Mountain Standard Time',
  '-08:00': 'Pacific Standard Time',
  '-09:00': 'Alaska Standard Time',
  '-10:00': 'Hawaii-Aleutian Standard Time',
  '-11:00': 'Samoa Standard Time',
  '+05:30': 'Indian Standard Time',
};
