import React from 'react';
import { createSvgIcon } from '@mui/material';

export const HumanResourcesIcon = createSvgIcon(
  <svg
    id="Controls-Icon-HR-Documentation"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2351"
      data-name="Rectangle 2351"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Group_2978" data-name="Group 2978" transform="translate(6 1.002)">
      <g
        id="Group_2970"
        data-name="Group 2970"
        transform="translate(5.68 21.951)"
      >
        <path
          id="Path_3858"
          data-name="Path 3858"
          d="M34.638,35.1h-11.1a.657.657,0,0,1,0-1.315h11.1a.657.657,0,0,1,0,1.315Z"
          transform="translate(-22.888 -33.783)"
        />
      </g>
      <g
        id="Group_2971"
        data-name="Group 2971"
        transform="translate(5.68 25.767)"
      >
        <path
          id="Path_3859"
          data-name="Path 3859"
          d="M34.638,39.451h-11.1a.657.657,0,0,1,0-1.315h11.1a.657.657,0,0,1,0,1.315Z"
          transform="translate(-22.888 -38.136)"
        />
      </g>
      <g
        id="Group_2972"
        data-name="Group 2972"
        transform="translate(5.68 29.581)"
      >
        <path
          id="Path_3860"
          data-name="Path 3860"
          d="M34.638,43.8h-11.1a.657.657,0,0,1,0-1.315h11.1a.657.657,0,0,1,0,1.315Z"
          transform="translate(-22.888 -42.487)"
        />
      </g>
      <g id="Group_2973" data-name="Group 2973">
        <path
          id="Path_3861"
          data-name="Path 3861"
          d="M37.8,8.74A5.531,5.531,0,0,0,33.072,11.4H17.456a1.093,1.093,0,0,0-1.089,1.1V43.644a1.093,1.093,0,0,0,1.089,1.1H39.03a1.093,1.093,0,0,0,1.089-1.1V19.434A5.607,5.607,0,0,0,37.8,8.74Zm.139,33.809h-19.4V13.587H32.288a5.389,5.389,0,0,0-.052.754,5.582,5.582,0,0,0,5.565,5.6.744.744,0,0,0,.139-.009ZM40.8,12.658l-3.632,3.655a.616.616,0,0,1-.462.193.644.644,0,0,1-.462-.193l-1.725-1.736a.664.664,0,0,1,0-.929.235.235,0,0,1,.07-.061.625.625,0,0,1,.784,0,.235.235,0,0,1,.07.061L36.7,14.92l1.237-1.245,1.934-1.946a.655.655,0,0,1,.923.929Z"
          transform="translate(-16.367 -8.74)"
        />
      </g>
      <g
        id="Group_2977"
        data-name="Group 2977"
        transform="translate(6.728 8.025)"
      >
        <g id="Group_2976" data-name="Group 2976" transform="translate(0 0)">
          <g id="Group_2974" data-name="Group 2974" transform="translate(2.51)">
            <ellipse
              id="Ellipse_608"
              data-name="Ellipse 608"
              cx="2.71"
              cy="2.728"
              rx="2.71"
              ry="2.728"
            />
          </g>
          <g
            id="Group_2975"
            data-name="Group 2975"
            transform="translate(0 6.323)"
          >
            <path
              id="Path_3862"
              data-name="Path 3862"
              d="M34.383,29.687c0-3.5-2.417-4.12-3.139-4.519a10.708,10.708,0,0,0-2.007-.033,10.708,10.708,0,0,0-2.007.033c-.722.4-3.139,1.019-3.139,4.519Z"
              transform="translate(-24.092 -25.108)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>,
  'HumanResources',
);
