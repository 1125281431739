import styled from 'styled-components';

const SliderWrap = styled.div`
  overflow-x: hidden;
  margin: 0 auto;
  width: 100%;

  .amn-carousel {
    padding-bottom: 36px;

    .swiper-pagination-bullet {
      height: 12px;
      width: 12px;
    }

    .swiper-container {
      overflow: visible;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0;
    }
    .swiper-slide {
      height: auto;
      display: flex;
    }
  }
`;

export { SliderWrap };
