import React from 'react';
import { Typography } from '@mui/material';
import { styled as MuiStyled } from '@mui/styles';
import { Box } from '@mui/system';
import { JobBadge } from 'components/common/JobBadge/JobBadge';
import { getJobBadgeInformation } from 'services/jobs/jobUIService';
import { CompactStateBadge } from 'components/common/CompactStateBadge/CompactStateBadge';

const JobFlagBox = MuiStyled(Box)({
  marginBottom: '24px',
});

export const JobFlagDialogContent = () => {
  const exclusive = getJobBadgeInformation(100);
  const crisis = getJobBadgeInformation(500);
  const criticalStaffing = getJobBadgeInformation(600);
  const matChildHealth = getJobBadgeInformation(200);
  const emr = getJobBadgeInformation(300);
  const winter = getJobBadgeInformation(400);
  const cf = getJobBadgeInformation(700);
  const strike = getJobBadgeInformation(900);
  const vaccine = getJobBadgeInformation(1000);
  const strikeNoticeReceived = getJobBadgeInformation(1100);

  return (
    <Box id="job-flag-dialog-content" sx={{ marginTop: '8px' }}>
      <JobFlagBox>
        <CompactStateBadge name={'C'} src={''} color={'system.shadowGray'} />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          States participating in the Nurse Licensure Compact (NLC) and Physical
          Therapy Compact.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge
          name={exclusive.name}
          src={exclusive.src}
          color={exclusive.color}
        />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Position that is available through AMN first.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge
          name={criticalStaffing.name}
          src={criticalStaffing.src}
          color={criticalStaffing.color}
        />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Nurses are needed on units ASAP. Typically, the facility has an
          increase in patients and does not have the staff to provide the best
          patient care.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge name={vaccine.name} src={vaccine.src} color={vaccine.color} />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Vaccine administration in a variety of settings.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge
          name={matChildHealth.name}
          src={matChildHealth.src}
          color={matChildHealth.color}
        />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Encompasses all specialties that help with labor, delivery and care of
          babies just after birth (Labor and Delivery, Postpartum, NICU).
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge name={emr.name} src={emr.src} color={emr.color} />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Electronic Medical Record Project - Nurses are needed to cover units
          while the facility switches to a new EMR system.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge name={winter.name} src={winter.src} color={winter.color} />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Encompasses all specialties that see increases during cold and flu
          season (ICU, PCU, TELE, MS).
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge name={cf.name} src={cf.src} color={cf.color} />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Facility will consider a CF for their SLP opening. Facility or AMN
          will provide mentorship.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge name={strike.name} src={strike.src} color={strike.color} />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          Replacement Clinicians to support facilities during a work stoppage.
        </Typography>
      </JobFlagBox>
      <JobFlagBox>
        <JobBadge
          name={strikeNoticeReceived.name}
          src={strikeNoticeReceived.src}
          color={strikeNoticeReceived.color}
        />
        <Typography
          variant="body1"
          color="system.coolGray"
          paddingTop="12px"
          gutterBottom
        >
          The work stoppage event is confirmed or ongoing.
        </Typography>
      </JobFlagBox>
    </Box>
  );
};
