import { Dispatch } from '@reduxjs/toolkit';
import { ResetCreateAccountFlow } from 'services/storeServices/storeRefreshService';

function ResetCreateUserFlow(history: any, dispatch: Dispatch) {
  //Reset the store and navigate to email validation page
  ResetCreateAccountFlow(dispatch);
  history.push('/email-validation');
}

export default ResetCreateUserFlow;
