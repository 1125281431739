import React from 'react';

export default function ContractsIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls_icon_Contracts"
      data-name="Controls/icon/Contracts"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2342"
        data-name="Rectangle 2342"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g
        id="Group_2943"
        data-name="Group 2943"
        transform="translate(-11, -9.441)"
      >
        <g
          id="Group_2942"
          data-name="Group 2942"
          transform="translate(15.989 10.441)"
        >
          <path
            id="Path_3832"
            data-name="Path 3832"
            d="M40.934,18.722a1.2,1.2,0,0,0,1.2-1.2V12.851a2.411,2.411,0,0,0-2.406-2.41H25.03a4.751,4.751,0,0,0-2.906,1.129L17.27,16.009a4.308,4.308,0,0,0-1.281,2.911V44.031a2.41,2.41,0,0,0,2.4,2.41H39.726a2.411,2.411,0,0,0,2.406-2.41V37.4a1.2,1.2,0,1,0-2.4,0l-.01,6.639-21.341-.01V19.865h6.661a1.2,1.2,0,0,0,1.2-1.2V12.842l13.492.009v4.671A1.2,1.2,0,0,0,40.934,18.722Zm-21.7-1.258,4.507-4.122c.028-.025.074-.049.108-.074v4.2Z"
            transform="translate(-15.989 -10.441)"
            fill={color}
          />
          <path
            id="Path_3833"
            data-name="Path 3833"
            d="M31.3,28.08H23.86a1.2,1.2,0,0,1,0-2.4H31.3a1.2,1.2,0,0,1,0,2.4Z"
            transform="translate(-16.266 -11.049)"
            fill={color}
          />
          <path
            id="Path_3834"
            data-name="Path 3834"
            d="M27.511,33.733H23.86a1.2,1.2,0,0,1,0-2.4h3.651a1.2,1.2,0,0,1,0,2.4Z"
            transform="translate(-16.266 -11.274)"
            fill={color}
          />
          <path
            id="Path_3835"
            data-name="Path 3835"
            d="M26.168,39.387H23.86a1.2,1.2,0,0,1,0-2.4h2.308a1.2,1.2,0,0,1,0,2.4Z"
            transform="translate(-16.266 -11.499)"
            fill={color}
          />
          <path
            id="Path_3836"
            data-name="Path 3836"
            d="M43.48,23.435"
            transform="translate(-17.13 -10.959)"
            fill={color}
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_3837"
            data-name="Path 3837"
            d="M47.847,22.575,46.316,21a2.63,2.63,0,0,0-3.695,0L32.61,31.308h0a7.363,7.363,0,0,0-1.628,2.948l-.412,1.689a2.131,2.131,0,0,0,.465,2.007,2.038,2.038,0,0,0,1.512.627,2.535,2.535,0,0,0,.46-.043l1.8-.327a6.375,6.375,0,0,0,3.03-1.616L47.846,26.285A2.682,2.682,0,0,0,47.847,22.575ZM34.381,35.848,33,36.1l.31-1.269a4.3,4.3,0,0,1,.416-.983l1.593,1.64A3.49,3.49,0,0,1,34.381,35.848Zm2.067-1.265-1.836-1.891,7.314-7.532,1.836,1.89Zm9.681-9.971-1.364,1.4-1.836-1.89,1.411-1.453a.175.175,0,0,1,.258,0l1.532,1.577A.275.275,0,0,1,46.128,24.612Z"
            transform="translate(-16.591 -10.832)"
            fill={color}
          />
          <path
            id="Path_3838"
            data-name="Path 3838"
            d="M33.076,35.632"
            transform="translate(-16.698 -11.445)"
            fill={color}
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
