import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ErrorState } from './errorState';

const initialState: ErrorState = {
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  downloadError: false,
};

export const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<IErrorResponse>) {
      // Set in BaseApi.ts
      if (action.payload?.overlookError) {
        return { ...initialState };
      }

      return {
        error: true,
        errorResponse: {
          errorCode: action.payload?.errorCode,
          errorData: action.payload?.errorData,
          errorDetails: action.payload?.errorDetails,
          errorMessage: action.payload?.errorMessage,
          operationId: action.payload?.operationId,
          correlationId: action.payload?.correlationId,
        },
        downloadError: false,
      };
    },
    setErrorWithoutPayload(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    unSetError() {
      return {
        ...initialState,
      };
    },
    setDownloadError(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        downloadError: action.payload,
      };
    },
  },
});

export const {
  setError,
  setErrorWithoutPayload,
  unSetError,
  setDownloadError,
} = errorSlice.actions;

export default errorSlice.reducer;
