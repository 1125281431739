/**
 * Asynchronously loads the component for Terms And Conditions Page
 */

import { lazyLoad } from 'utils/loadable';

export const TermsAndConditions = lazyLoad(
  () => import('./TermsAndConditions'),
  module => module.TermsAndConditions,
);
