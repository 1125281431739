import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import Pdf from 'react-to-pdf';
import { RootState, useAppDispatch } from 'store/configureStore';
import { setDownloadError } from 'store/slices/error/errorSlice';
import { AppButton } from '../Button/Button';
import { ConfirmationPopup } from '../ConfirmationPopup/ConfirmationPopup';

export const GeneratePDF = ({
  pdfRef,
  actionButtonText,
  screenSize,
  setLoaderActive,
}: {
  pdfRef: ReactNode;
  actionButtonText: string | undefined;
  screenSize: boolean;
  setLoaderActive: Function;
}) => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const userProfileImage = useSelector(
    (state: RootState) => state?.userProfile?.profileImage?.url,
  );

  const pdfOptions = {
    orientation: 'portrait',
    unit: 'in',
    format: [3.64, 4.95], //width=350px, height=475px
  };

  const preventPdfDownload = () => {
    dispatch(setDownloadError(true));
    setModalOpen(true);
  };

  return (
    <>
      <Pdf
        targetRef={pdfRef}
        filename="AmnBadge"
        options={pdfOptions}
        onComplete={() => setLoaderActive(false)}
      >
        {({ toPdf }) => (
          <AppButton
            type="button"
            size={screenSize ? 'md' : 'xl'}
            variant="primary"
            onClick={
              userProfileImage
                ? () => {
                    setLoaderActive(true);
                    let editIcon = document.getElementById('badge-edit-icon');
                    if (editIcon) {
                      editIcon.style.visibility = 'hidden';
                    }
                    toPdf();
                  }
                : preventPdfDownload
            }
          >
            {actionButtonText}
          </AppButton>
        )}
      </Pdf>
      {modalOpen && (
        <ConfirmationPopup
          title={'Photo required'}
          content={'Please add your photo to download PDF.'}
          isPanelOpen={modalOpen}
          handleClose={() => setModalOpen(false)}
          negative={'Close'}
          isHandleProceedEnabled={false}
        />
      )}
    </>
  );
};
