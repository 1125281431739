import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ICountry from 'interfaces/Common/ICountry';
import { ICountryAndProvince } from 'interfaces/Lookup/ICountryAndProvince';
import { IReferralRequest } from 'interfaces/Lookup/IReferralRequest';
import { ITravelCompanies } from 'interfaces/Lookup/ITravelCompanies';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';

import { LookupState } from './lookupsState';

const initialState: LookupState = {
  countries: [],
  stateAndProvince: [],
  disciplines: {
    paging: {
      limit: '60',
      offset: '0',
      total: '60',
    },
    items: [
      {
        disciplineId: '',
        disciplineDescription: '',
        specialities: [
          {
            specialtyId: '',
            specialtyDescription: '',
          },
        ],
      },
    ],
  },
  travelCompanies: [],
  showThanksForReferralModal: false,
};

export const fetchCompanyLookupAction = createAction('GET_TRAVELER_COMPANIES');
export const postFriendReferralAction = createAction(
  'POST_FRIEND_REFERRAL',
  function prepare(userId: string, referralRequest: IReferralRequest) {
    return {
      payload: {
        userId,
        referralRequest,
      },
    };
  },
);

const lookupSlice = createSlice({
  name: 'lookupSlice',
  initialState,
  reducers: {
    fetchCountries() {},
    setCountries(state, action: PayloadAction<ICountry[]>) {
      state.countries = action.payload;
    },
    fetchState() {},
    setStates(state, action: PayloadAction<ICountryAndProvince[]>) {
      state.stateAndProvince = action.payload;
    },
    fetchDiscipline(state, action) {},
    setDiscipline(state, action: PayloadAction<ISuccessDisciplineResponse>) {
      state.disciplines = action.payload;
    },
    setTravelCompanies(state, action: PayloadAction<ITravelCompanies[]>) {
      return { ...state, travelCompanies: action.payload };
    },
    setShowThanksForReferralModalState(state, action: PayloadAction<boolean>) {
      state.showThanksForReferralModal = action.payload;
    },
  },
});

export const {
  fetchCountries,
  setCountries,
  fetchState,
  setStates,
  fetchDiscipline,
  setDiscipline,
  setTravelCompanies,
  setShowThanksForReferralModalState,
} = lookupSlice.actions;

export default lookupSlice.reducer;
