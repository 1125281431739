import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { theme } from 'styles/theme';

/**
 * TextButton: A simple text button component that can also include an icon
 * @param {function} onClick - The function to be called when the button is clicked
 * @param {any} children - The text or React Component to be displayed in the button
 * @param {any} icon - The icon to be displayed in the button
 * @param {string} justifyContent - The alignment of the text and icon
 * @param {string} fontSize - The font size of the text
 * @param {string} maxWidth - The maxWidth of the text
 * */
export const TextButton = ({
  id,
  onClick,
  children,
  icon,
  justifyContent = 'center',
  fontSize = '14px',
  maxWidth = '100%',
}) => (
  <Box
    id={id}
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent,
      cursor: 'pointer',
      color: 'system.skyBlue',
      '&:hover': {
        textDecoration: 'underline',
      },
    }}
    onClick={onClick}
  >
    {icon ?? null}
    <Typography
      variant="body1"
      sx={{
        marginLeft: icon ? theme.spacing(1) : theme.spacing(0),
        fontWeight: 400,
        lineHeight: '20px',
        fontSize,
        maxWidth: { xs: maxWidth, sm: '100%' },
      }}
      id="textbutton"
    >
      {children}
    </Typography>
  </Box>
);
