import React from 'react';
import Applestore from 'assets/icons/Applestore/Applestore.svg';

export const ApplestoreIcon = () => {
  return (
    <a href="https://apps.apple.com/us/app/amn-passport-find-book-manage/id1476101853">
      <img
        height="40px"
        width="135px"
        src={Applestore}
        alt="Download on the App Store"
      />
    </a>
  );
};
