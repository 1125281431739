import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from 'assets/icons/Close/Close';

export const CloseButton = ({
  classes,
  onClick,
}: {
  classes?: any;
  onClick?: () => void;
}) => {
  return (
    <IconButton
      aria-label="close"
      className={classes}
      onClick={e => {
        e.stopPropagation();
        onClick && onClick();
      }}
      size="small"
      sx={{
        border: `1px solid #707070`,
        backgroundColor: 'system.white',
        width: '24px',
        height: '24px',
        '&:hover': {
          backgroundColor: 'system.backgroundGray',
        },
      }}
    >
      <CloseIcon width="9px" height="9px" />
    </IconButton>
  );
};
