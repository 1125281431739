import { Box, Grid, Typography } from '@mui/material';
import {
  formatDate,
  formatShiftTimeRange,
} from 'components/ScheduleEventInfoPanel/scheduleEventInfoUtils';
import { guidGenerator } from 'helpers/guidGenerator';
import React from 'react';

export const ShiftItem = ({ shift }) => {
  const renderTags = tags => {
    if (tags?.includes('Charge Nurse')) {
      return (
        <Box
          sx={{
            backgroundColor: 'system.marineBlue',
            borderRadius: '25px',
            px: 2,
            py: '4px',
          }}
        >
          <Typography
            variant="body1"
            color="system.white"
            fontWeight="700"
            fontSize="13px"
            data-testid="tag"
          >
            {'Charge'}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Grid
      key={guidGenerator()}
      item
      py={2}
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography color="system.grayText" mb={1} data-testid="work-site-name">
          {shift?.workSiteName}
        </Typography>
        <Typography color="system.grayText" mb={1} data-testid="unit-name">
          {shift?.unitName}
        </Typography>
        <Typography
          color="system.grayText"
          fontWeight="700"
          data-testid="start-date-time"
        >
          {formatDate(shift?.startDateTime)}
        </Typography>
        <Typography color="system.grayText" my={1} data-testid="end-date-time">
          {formatShiftTimeRange(shift?.startDateTime, shift?.endDateTime)}
        </Typography>
      </Grid>
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        {renderTags(shift?.tags)}
      </Grid>
    </Grid>
  );
};
