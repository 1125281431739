import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateAccount } from 'interfaces/CreateAccount/ICreateAccount';
import { IUserEmailResponse } from 'interfaces/User/UserEmail/IUserEmailResponse';
import { IUserVerification } from 'interfaces/User/UserVerification/IUserVerification';
import { CreateUserRequest } from 'types/CreateUserRequest';
import { IUserLogin } from './userLoginState';

const createAccountDefaultValue = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  address: '',
  address2: '',
  country: '',
  city: '',
  state: '',
  zip: '',
  code: '',

  discipline: '',
  specialty: '',
  secondSpecialty: '',

  travelAssignments: null,
  criticalStaffing: null,
  localPerDiem: null,
  strike: null,
};

const initialState: IUserLogin = {
  loginState: {
    exists: undefined,
    hasLoggedInBefore: false,
    userEmail: '',
  },
  createAccountData: createAccountDefaultValue,
  userVerificationStatus: {
    success: false,
    userExists: false,
  },
  isNewUserAccountCreated: undefined,
  createAccount: undefined,
  isLoading: false,
  redirectRoute: undefined,
};

/**
 * Slice to check if the user exists or not
 */
const userLoginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    checkForValidUserLogin(state, action) {},
    setUserStatusLogin(state, action: PayloadAction<IUserEmailResponse>) {
      const { exists, hasLoggedInBefore, userEmail } = action.payload;
      state.loginState.exists = exists;
      state.loginState.hasLoggedInBefore = hasLoggedInBefore;
      state.loginState.userEmail = userEmail;
      state.redirectRoute = action.payload.redirectRoute;
    },
    resetUserCode(state, action) {},
    validateNewUserCode(state, action) {},
    setNewUserCode(state, action: PayloadAction<IUserVerification>) {
      state.userVerificationStatus.success = action.payload.success;
    },
    saveCreateUserAccountinStore(state, action: PayloadAction<ICreateAccount>) {
      state.createAccountData = action.payload;
    },
    createUserAccount(state, action: PayloadAction<CreateUserRequest>) {
      state.createAccount = action.payload;
    },
    setNewUserAccount(state, action: PayloadAction<boolean>) {
      state.isNewUserAccountCreated = action.payload;
    },
    cancelCreateUserFlow(state) {
      state.userVerificationStatus.success = false;
      state.userVerificationStatus.userExists = false;
    },
    setLoadingAction(state, action) {
      state.isLoading = action.payload;
    },
    setUserEmailForVerification(state, action) {
      state.loginState.userEmail = action.payload;
    },
    setRedirectRoute(state, action) {
      state.redirectRoute = action.payload;
    },
  },
});

export const {
  checkForValidUserLogin,
  setUserStatusLogin,
  validateNewUserCode,
  setNewUserCode,
  createUserAccount,
  setNewUserAccount,
  saveCreateUserAccountinStore,
  cancelCreateUserFlow,
  setLoadingAction,
  setUserEmailForVerification,
  setRedirectRoute,
} = userLoginSlice.actions;
export default userLoginSlice.reducer;
