import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICredentialUploadedNotSubmitted } from 'interfaces/Credential/ICredentialUploadedNotSubmitted';
import { IFileUploadState, IUploadedFileState } from './fileUploadState';

const initialState: IFileUploadState = {
  isOpen: false,
  canUploadMultiple: false,
  modalTitle: 'File Upload',
  acceptedFileTypes: {
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
  },
  showDeleteFile: false,
  fileNameType: 'File',
  uploadedFile: undefined,
  uploadedFiles: [],
  uploadedNotSubmittedYet: [],
  customMaxFileSizeError: undefined,
};

const fileUploadSlice = createSlice({
  name: 'fileUploadSlice',
  initialState,
  reducers: {
    toggleFileModalOpenState(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
    setUploadedFile(
      state,
      action: PayloadAction<IUploadedFileState | undefined>,
    ) {
      state.uploadedFile = action.payload;
    },
    setUploadedFiles(
      state,
      action: PayloadAction<IUploadedFileState[] | undefined>,
    ) {
      state.uploadedFiles = action.payload;
    },
    appendUploadedFile(state, action: PayloadAction<IUploadedFileState>) {
      if (state.uploadedFiles && action.payload !== undefined) {
        state.uploadedFiles.push(action.payload);
      }
    },
    appendUploadedNotSubmittedYet(
      state,
      action: PayloadAction<ICredentialUploadedNotSubmitted>,
    ) {
      state.uploadedNotSubmittedYet = [
        ...(state.uploadedNotSubmittedYet || []),
        action.payload,
      ];
    },
    removeUploadedFile(
      state,
      action: PayloadAction<IUploadedFileState | undefined>,
    ) {
      if (state.uploadedFiles && action.payload) {
        state.uploadedFiles = state.uploadedFiles.filter(
          f => f.fileObject?.name !== action.payload?.fileObject?.name,
        );

        state.uploadedNotSubmittedYet = state.uploadedNotSubmittedYet?.filter(
          funsy => funsy.originalFileName !== action.payload?.fileObject?.name,
        );
      } else {
        state.uploadedFile = undefined;
      }
    },
    removeUploadedFileById(state, action: PayloadAction<number | undefined>) {
      state.uploadedFiles = (state.uploadedFiles || []).filter(
        (f, idx) => idx !== action.payload,
      );

      state.uploadedNotSubmittedYet = state.uploadedNotSubmittedYet?.filter(
        (f, idx) => idx !== action.payload,
      );
    },
    clearUploadedFiles(state) {
      state.uploadedFiles = [];
      state.uploadedFile = undefined;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const fileUploadsActions = {
  ...fileUploadSlice.actions,
};

export default fileUploadSlice.reducer;
