import React, { FC } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { theme } from 'styles/theme';

export interface IActionListDialogProps extends DialogProps {
  open: boolean;
  title: string;
  onClose?: () => void;
}

export const ActionListDialog: FC<IActionListDialogProps> = ({
  open,
  title,
  onClose,
  children,
  maxWidth,
  sx,
}) => (
  <>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={maxWidth}
      sx={sx}
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          textAlign: 'left',
          fontSize: '20px',
          color: `${theme.palette.system.midnightBlue}`,
          fontWeight: 600,
          padding: '24px 24px 12px 24px',
        }} // adding font styles inline to avoid Typography error
        data-testid="title"
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{ padding: '0 24px 24px 24px' }}
        data-testid="children"
      >
        {children}
      </DialogContent>
    </Dialog>
  </>
);
