import { AvatarTextVariant } from 'enums/avatarTextVariant';
import { HSGNameOption } from 'enums/hsgNameOption';

export const generateAvatarShortText = (
  text: string,
  variant: AvatarTextVariant,
): string => {
  switch (variant) {
    case AvatarTextVariant.FIRST_LAST:
      const wordArray = text.split(' ');
      let shortWord = '';
      if (wordArray.length > 0) {
        for (let index = 0; index < wordArray.length; index++) {
          shortWord += wordArray[index].trim().substring(0, 1);
          if (index + 1 === 2) break;
        }
      }
      return shortWord.toUpperCase();
    case AvatarTextVariant.FIRST_ONLY:
      return text.substring(0, 1).toUpperCase();
    case AvatarTextVariant.HSG:
      return text.substring(0, 4).toUpperCase();
  }
};

export const isHSG = (text: string): boolean => {
  return (
    text === HSGNameOption.RECRUITER || text === HSGNameOption.STRIKE_QUEUE
  );
};
