import React from 'react';
import { IBadge } from 'interfaces/Jobs/IBadge';
import { Typography, Box } from '@mui/material';
import { theme } from 'styles/theme';

export const JobBadge: React.FC<IBadge> = badgeDetail => {
  return (
    <>
      {!badgeDetail.name && <span></span>}
      {badgeDetail.name && (
        <Box
          id="job-badge"
          sx={{
            backgroundColor: badgeDetail.color,
            borderRadius: '5px 5px 5px 5px',
            display: 'inline-block',
            padding: '4px 6px',
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {badgeDetail.src && (
              <img
                src={badgeDetail.src}
                alt="favorite-badge"
                height="14px"
                width="14px"
              ></img>
            )}
            <Typography
              variant="body1"
              fontWeight={700}
              color={theme.palette.system.white}
              sx={{ marginLeft: badgeDetail.src ? '6px' : '' }}
            >
              {badgeDetail.name}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};
