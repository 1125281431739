import React, { useEffect, useState } from 'react';
import { PdfViewer } from 'components/PdfViewer/PdfViewer';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';

const GenerateCVPdfViewer = () => {
  const [pdfString, setPdf] = useState('');
  const pdfbase64String = useSelector(
    (state: RootState) => state.userProfilePdf.userProfilePdfResponse.file,
  );

  useEffect(() => {
    if (pdfbase64String) {
      setPdf(pdfbase64String);
    }
  }, [pdfbase64String]);

  const downloadPDF = () => {
    b64toBlob(pdfString).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `profile-resume.pdf`;
      link.click();
    });
  };

  const b64toBlob = (base64, type = 'application/octet-stream') =>
    fetch(`data:${type};base64,${base64}`).then(res => res.blob());

  return (
    <PdfViewer
      id="GenerateCV"
      title="CV/Resume"
      pdfData={pdfString ?? ''}
      menuItems={[
        {
          id: 'DownloadPDF',
          menuItem: 'Download PDF',
          onClick: () => {
            downloadPDF();
          },
        },
      ]}
    />
  );
};

export { GenerateCVPdfViewer };
