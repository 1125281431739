import React from 'react';
import Box from '@mui/system/Box';

import { theme } from 'styles/theme';
import { IProfileAvatarProps } from '../../interfaces/Profile/IProfileAvatarProps';
import AvatarPlaceholder from './AvatarPlaceholder';
import ProfileEditBadge from './ProfileEditBadge';
import { isLocumsSelector } from 'store/selectors/authSelector';
import { useAppSelector } from 'store/hooks';

function ProfileAvatar({
  image,
  size,
  border,
  onClick,
  placeholderVariant,
  userName,
}: IProfileAvatarProps) {
  const isLocums: boolean = useAppSelector(isLocumsSelector);

  return (
    <>
      <ProfileEditBadge onClick={onClick} editable={isLocums ? false : true}>
        {image && (
          <Box
            sx={{
              height: size ? size + 10 : 138,
              width: size ? size + 10 : 138,
              borderRadius: '50%',
              border: border ?? `2px solid ${theme.palette.system.purple}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              data-testid="profile-image"
              sx={{
                width: size ? size : 128,
                height: size ? size : 128,
                borderRadius: '50%',
                backgroundSize: 'cover',
                backgroundImage: `url(${image})`,
              }}
            />
          </Box>
        )}
        {!image && (
          <AvatarPlaceholder
            size={size ?? 128}
            border={border}
            data-testid="placeholder"
            variant={placeholderVariant}
            placeholderText={userName}
          />
        )}
      </ProfileEditBadge>
    </>
  );
}

export default ProfileAvatar;
