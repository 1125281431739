export enum LinkType {
  None = '0',
  PDF = '1',
  Web = '2',
  MobileDeepLink = '3',
  Formstack = '10',
  Multiple = '11',
  //Used as In Applink navigation
  InAppLink = '1000',
}
