import React from 'react';

export default function TravelItinerary(
  color?: string,
  height?: string,
  width?: string,
) {
  return (
    <svg
      id="Controls-icon-baggage"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2263"
        data-name="Rectangle 2263"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <path
        id="luggage_FILL0_wght400_GRAD0_opsz48"
        d="M12.679,38.2a2.573,2.573,0,0,1-1.9-.787A2.614,2.614,0,0,1,10,35.5V14.35a3.047,3.047,0,0,1,.915-2.227,3,3,0,0,1,2.21-.922h4.018V5.35a1.317,1.317,0,0,1,.379-.967A1.3,1.3,0,0,1,18.482,4h8.036a1.3,1.3,0,0,1,.96.383,1.317,1.317,0,0,1,.379.967V11.2h4.464a2.573,2.573,0,0,1,1.9.788A2.614,2.614,0,0,1,35,13.9V35.5a2.614,2.614,0,0,1-.781,1.912,2.573,2.573,0,0,1-1.9.787,1.749,1.749,0,0,1-.513,1.283,1.823,1.823,0,0,1-2.545,0A1.749,1.749,0,0,1,28.75,38.2H16.25a1.749,1.749,0,0,1-.513,1.283,1.823,1.823,0,0,1-2.545,0A1.749,1.749,0,0,1,12.679,38.2Zm6.25-27h7.143V5.8H18.929Zm-6.25,24.3H32.321V13.9H12.679V35.5ZM15.8,32.8h1.786V16.6H15.8Zm5.8,0h1.786V16.6H21.607Zm5.8,0H29.2V16.6H27.411ZM12.679,35.5h0V13.9h0V35.5Z"
        transform="translate(-4 -3)"
        fill={color}
      />
    </svg>
  );
}
