import { CreateWorkHistoryRequest } from '../../types/CreateWorkHistoryRequest';
import { http } from '../BaseApi';
import { SuccessResponse } from '../../types/SuccessResponse';

export const createWorkHistory = async (
  userId: number,
  payload: CreateWorkHistoryRequest,
): Promise<SuccessResponse> => {
  return await http.post<CreateWorkHistoryRequest>(
    `/userprofile/${userId}/employment-history`,
    payload,
  );
};
