import styled from 'styled-components';
import { amnFontSize } from 'styles/fontSizes';
import { theme } from 'styles/theme';

export const ShowMoreOrLessLink = styled.a`
  color: ${theme.palette.system.midnightBlue};
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: ${theme.palette.system.white} 0% 0% no-repeat padding-box;
  position: relative;
  padding: 5px;
  font-size: ${amnFontSize.fs14};
  line-height: 24px;
  color: ${theme.palette.system.skyBlue};
  cursor: pointer;
  &:after {
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    border: 1px solid ${theme.palette.system.skyBlue};
    border-width: 0px 1px 1px 0;
    position: relative;
    right: -12px;
    top: 4px;
  }
  &.more {
    @media (min-width: 1366px) {
      width: 330px;
    }
    &:after {
      transform: rotate(45deg);
    }
  }
  &.less {
    @media (min-width: 1366px) {
      width: 330px;
    }
    &:after {
      transform: rotate(225deg);
      top: 10px;
    }
  }
`;
