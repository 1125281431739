import { IDisciplinesAndSpecialty } from 'interfaces/Lookup/IDisciplinesAndSpecialty';
import _ from 'lodash';
import { IJobDisciplineAndSpecialties } from 'types/UserPreference';
import { ISpecialty } from './IAlliedDisciplineAndSpecialties';
import { IFieldOptions } from './IFieldOptions';

export const anyJobType = (checked: boolean) => {
  return {
    isActive: checked,
    note: 'Any Job Type',
    type: 'Any Job Type',
    typeId: 0,
  };
};

export const anyShiftTime = (checked: boolean) => {
  return { isActive: checked, type: 'Any Shift Time', typeId: 0 };
};

export const anyShiftLength = (checked: boolean) => {
  return { isActive: checked, type: 'Any Shift Length', typeId: 0 };
};

export const anyFacilityType = (checked: boolean) => {
  return { isActive: checked, type: 'Any Facility Type', typeId: 0 };
};

export const areAllOptionsChecked = (
  optionList: IFieldOptions[],
  startIndex: number = 1,
): boolean => {
  let allChecked = true;
  optionList.forEach((option, index) => {
    if (index > startIndex - 1 && !option.isActive) {
      allChecked = false;
    }
  });
  return allChecked;
};

export const areAllOptionsUnchecked = (
  optionList: IFieldOptions[],
  startIndex: number = 1,
): boolean => {
  let allUnchecked = true;
  optionList.forEach((option, index) => {
    if (index > startIndex - 1 && option.isActive) {
      allUnchecked = false;
      return;
    }
  });
  return allUnchecked;
};

export const allSpecialtiesChecked = (specialties: ISpecialty[]): boolean => {
  let allChecked = true;
  for (let i = 0; i < specialties.length; ++i) {
    if (!specialties[i].checked) {
      allChecked = false;
      break;
    }
  }
  return allChecked;
};

export const alterAllOptions = (
  optionList: IFieldOptions[],
  toValue: boolean,
): IFieldOptions[] => {
  const optList = _.cloneDeep(optionList);
  optList.forEach((option, index) => {
    if (index > 0) option.isActive = toValue;
  });
  return optList;
};

export const handleAnyChecked = (
  optionList: IFieldOptions[],
): IFieldOptions[] => {
  const optList = [...optionList];
  optList.forEach((option, index) => {
    if (option.typeId !== 0) option.isActive = false;
  });
  return optList;
};

export const handleAllChecked = (
  optionList: IFieldOptions[],
): IFieldOptions[] => {
  let optList = handleAnyChecked(optionList);
  let anyIdx = optList.findIndex(option => option.typeId === 0);
  if (anyIdx > -1) {
    optList[anyIdx].isActive = true;
  }
  return optList;
};

const isChecked = (
  verifiedExpertise: IDisciplinesAndSpecialty,
  autoSubmitExpertise: IDisciplinesAndSpecialty[],
): boolean => {
  let index = -1;
  index = autoSubmitExpertise.findIndex(
    exp =>
      exp.disciplineId === verifiedExpertise.disciplineId &&
      exp.specialtyId === verifiedExpertise.specialtyId,
  );
  return index > -1;
};

export const mapExpertiseForAutoSubmit = (
  userProfileDisciplinesAndSpecialties: IDisciplinesAndSpecialty[],
  autoSubmitDisciplineAndSpecialties: IDisciplinesAndSpecialty[],
): IJobDisciplineAndSpecialties[] => {
  const expertise = userProfileDisciplinesAndSpecialties.map(exp => {
    return {
      disciplineId: exp.disciplineId,
      discipline: exp.discipline,
      specialtyId: exp.specialtyId,
      specialty: exp.specialty,
      checked: isChecked(exp, autoSubmitDisciplineAndSpecialties),
    };
  });

  return expertise;
};

// generic methods for seeing if all options are checked/unchecked
// can pass an array of objects and any property name to check
interface AnyObject {
  [key: string]: any;
}

export const allOptionsCheckedGeneric = <T extends AnyObject>(
  optionList: T[],
  propertyName: keyof T,
  startIndex: number,
): boolean => {
  let allChecked = true;
  for (let i = startIndex; i < optionList.length; i++) {
    if (!optionList[i][propertyName]) {
      allChecked = false;
    }
  }
  return allChecked;
};

export const allOptionsUncheckedGeneric = <T extends AnyObject>(
  optionList: T[],
  propertyName: keyof T,
  startIndex: number,
): boolean => {
  let allUnChecked = true;
  for (let i = startIndex; i < optionList.length; i++) {
    if (optionList[i][propertyName]) {
      allUnChecked = false;
    }
  }
  return allUnChecked;
};
