import Chip from '@mui/material/Chip';
import { IPillButton } from 'interfaces/Common/IButton';
import React from 'react';
import { theme } from 'styles/theme';

export const PillButton = ({
  id,
  label,
  onClick,
  active = false,
  disabled = false,
  buttonSx,
  onDeleteCallback,
  deleteIcon,
}: IPillButton) => {
  return (
    <Chip
      label={label}
      onClick={!disabled && onClick}
      id={id}
      key={id}
      data-testid={id}
      sx={[
        active && {
          backgroundColor: `${theme.palette.system.skyBlue} !important`,
          color: `${theme.palette.system.white} !important`,
        },
        disabled && { color: `${theme.palette.system.gray} !important` },
        {
          mr: '12px',
          backgroundColor: 'system.buttonBackground',
          color: 'system.coolGray',
        },
        ...(Array.isArray(buttonSx) ? buttonSx : [buttonSx ?? {}]),
      ]}
      onDelete={onDeleteCallback}
      deleteIcon={deleteIcon}
    />
  );
};
