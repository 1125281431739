import React from 'react';
import { createSvgIcon } from '@mui/material';

export const CheckIconFill = createSvgIcon(
  <svg
    id="Controls_icon_Check"
    data-name="Controls/icon/Check"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect id="Rectangle_2312" data-name="Rectangle 2312" opacity="0" />
    <path
      id="Path_3766"
      data-name="Path 3766"
      d="M30.435,12.435a18,18,0,1,0,18,18A18,18,0,0,0,30.435,12.435ZM40.543,25.45,28.8,37.2a1.28,1.28,0,0,1-1.811,0L21.4,31.6a1.28,1.28,0,0,1,1.811-1.811l4.688,4.687L38.732,23.64a1.28,1.28,0,0,1,1.811,1.811Z"
      transform="translate(-11.435 -11.436)"
    />
  </svg>,
  'CheckIconFill',
);
