import React, { useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { MenuIcons } from 'enums/menuIcons';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { theme } from 'styles/theme';
import mapMenuIconsection from 'utils/mapMenuIconNameSection';
import { NotificationItem } from './NotificationItem';
import { SkeletonNotificationItem } from './SkeletonNotificationItem';
import { Box } from '@mui/material';
import AlertIcon from 'assets/icons/Notifications/AlertIcon';

export const NotificationsDropdown = ({
  handleClose,
}: {
  handleClose: any;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const notifications = useAppSelector(
    state => state.notifications.notifications,
  );
  const unreadCount = useAppSelector(
    state => state.notifications.unreadNotificationsCount,
  );

  const error = useAppSelector(state => state.notifications.error);

  const { promiseInProgress: getNotificationsLoader } = usePromiseTracker({
    area: 'get-notifications',
    delay: 0,
  });
  const { promiseInProgress: getUnreadNotificationsLoader } = usePromiseTracker(
    {
      area: 'get-unread-notifications-count',
      delay: 0,
    },
  );

  const handleViewAll = () => {
    handleClose();
    dispatch(setActivePage(MenuIcons.Notifications));
    history.push(mapMenuIconsection(MenuIcons.Notifications));
  };

  const errorMessage = useMemo(() => {
    if (error?.errorMessage) {
      return (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            p: '0px 0px 12px 0px',
            alignItems: 'center',
          }}
        >
          <AlertIcon />
          <Typography
            sx={{
              color: theme?.palette?.system?.alertErrorColor,
              fontWeight: 600,
              ml: 2,
            }}
          >
            {error.errorMessage}
          </Typography>
        </Box>
      );
    }
  }, [error?.errorMessage]);

  return (
    <Grid container maxWidth="375px" p={4}>
      <Grid item xs={12}>
        {getUnreadNotificationsLoader ? (
          <Skeleton
            variant="rectangular"
            height={'19px'}
            width={'100%'}
            sx={{ maxWidth: '192px' }}
          />
        ) : error?.errorMessage ? (
          errorMessage
        ) : (
          <Typography variant="subtitle1" color="system.midnightBlue" pb={2}>
            {unreadCount > 0
              ? `You have ${unreadCount} unread notification(s).`
              : 'You are all caught up!'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {getNotificationsLoader ? (
          <SkeletonNotificationItem showMult />
        ) : notifications?.length > 0 ? (
          notifications
            .slice(0, 4)
            .map((n, index) => (
              <NotificationItem
                key={index}
                lastItem={false}
                notification={n}
                handleClick={handleClose}
                isDropdown={true}
              />
            ))
        ) : (
          <Divider sx={{ pt: 2 }} />
        )}
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="text"
          sx={{
            color: theme.palette.system.skyBlue,
            pt: 4,
            pb: 0,
            fontSize: 14,
            fontWeight: 500,
          }}
          onClick={handleViewAll}
          data-testId="view-all"
        >
          View All Notifications
        </Button>
      </Grid>
    </Grid>
  );
};
