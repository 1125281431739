import { IButtonProps } from 'interfaces/Props/IButtonProps';
import styled, { css } from 'styled-components';
import { theme } from 'styles/theme';
import { amnFontSize } from 'styles/fontSizes';

export const buttonSizes = {
  auto: 'auto',
  xs: '100px',
  sm: '140px',
  md: '190px',
  lg: '300px',
  xl: '327px',
};

export const StyledButton = styled.button<IButtonProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.primaryTextColor};
  align-items: center;
  min-width: ${props => props.width || buttonSizes.lg};
  word-wrap: break-word;
  line-height: ${amnFontSize.fs18};
  border-radius: 7px;
  border: none;
  font-size: ${amnFontSize.fs16};
  font-family: 'Open Sans';
  cursor: pointer;
  padding: ${props => props?.sx?.['padding'] || '12px'};
  box-sizing: border-box;
  margin: 6px 0;
  ${props =>
    props.variant === 'primary' &&
    css`
      background: ${theme.palette.system.midnightBlue};
      color: ${theme.palette.system.white};
      &:hover {
        opacity: 0.92;
      }
    `}
  ${props =>
    props.variant === 'secondary' &&
    css`
      background: ${theme.palette.system.white};
      color: ${theme.palette.system.midnightBlue};
      border: 1px solid ${theme.palette.system.gray};
    `}
    ${props =>
    props.variant === 'tertiary' &&
    css`
      background: ${theme.palette.system.skyBlue};
      color: ${theme.palette.system.white};
      width: ${buttonSizes.md};
    `}
    ${props =>
    props.variant === 'action-link' &&
    css`
      background: transparent;
      color: ${theme.palette.system.skyBlue};
      width: ${buttonSizes.auto};
      font-size: ${amnFontSize.fs14};
      text-transform: uppercase;
      letter-spacing: 1.25px;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    `}
    ${props =>
    props.variant === 'add-text-btn' &&
    css`
      padding: 0px;
      margin: 0px;
      background: transparent;
      color: ${theme.palette.system.skyBlue};
      width: ${buttonSizes.auto};
      font-size: ${amnFontSize.fs14};
      letter-spacing: 1.25px;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    `}
    ${props =>
    props.disabled &&
    css`
      background: ${theme.palette.system.coolGray};
      color: ${theme.palette.system.white};
      cursor: default;
    `}
    ${props =>
    props.disabled &&
    props.variant === 'secondary' &&
    css`
      background: ${theme.palette.system.white};
      color: ${theme.palette.system.coolGray};
      border: 1px solid ${theme.palette.system.gray};
      cursor: default;
    `}
    ${props =>
    props.size === 'auto' &&
    css`
      min-width: ${buttonSizes.auto};
    `}
 ${props =>
    props.size === 'xs' &&
    css`
      min-width: ${buttonSizes.xs};
    `}
 ${props =>
    props.size === 'sm' &&
    css`
      min-width: ${buttonSizes.sm};
    `}
 ${props =>
    props.size === 'md' &&
    css`
      min-width: ${buttonSizes.md};
    `}
 ${props =>
    props.size === 'lg' &&
    css`
      min-width: ${buttonSizes.lg};
    `}
 ${props =>
    props.size === 'xl' &&
    css`
      min-width: ${buttonSizes.xl};
    `}
`;
