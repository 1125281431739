import { createTheme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface Palette {
    system: Palette['primary'];
    credentialsCategory: CredentialsPaletteColor;
  }
  interface PaletteOptions {
    system: PaletteOptions['primary'];
    credentialsCategory: CredentialsPaletteColor;
  }

  interface PaletteColor {
    primary?: string;
    white?: string;
    gray?: string;
    darkGray?: string;
    lightGrayBg?: string;
    fadedGray?: string;
    coolGray?: string;
    inputBackground?: string;
    seaGreen?: string;
    softSeaGreenBg?: string;
    paleGreen?: string;
    skyBlue?: string;
    skyBlueHover?: string;
    midnightBlue?: string;
    brightMidnightBlue?: string;
    softBlueBg?: string;
    poloBlue?: string;
    teal?: string;
    purple?: string;
    lavender?: string;
    selago?: string;
    lightCyan?: string;
    marineBlueLight?: string;
    marineBlue?: string;
    mossGreen?: string;
    grassGreen?: string;
    lightGreen?: string;
    greenishBlue?: string;
    backgroundGray?: string;
    shadowGray?: string;
    darkShadowGray?: string;
    boxShadowGray?: string;
    paleGray?: string;
    grayishBlack?: string;
    opaqueOverlay?: string;
    border?: string;
    error?: string;
    yellow?: string;
    scrollBarColor?: string; //@TODO: Lets put this in the global styles so all scrollbars are styled the same
    softPurpleBg?: string;
    grayText?: string;
    grayText2?: string;
    black?: string;
    subTitleBlack?: string;
    alertSuccessBorder?: string;
    alertSuccessColor?: string;
    alertSuccessBackground?: string;
    alertSuccessBackgroundHover?: string;
    alertErrorBorder?: string;
    alertErrorColor?: string;
    alertErrorBackground?: string;
    alertErrorBackgroundHover?: string;
    alertInfoBorder?: string;
    alertInfoColor?: string;
    alertInfoBackground?: string;
    alertInfoBackgroundHover?: string;
    alertWarningBorder?: string;
    alertWarningColor?: string;
    alertWarningBackground?: string;
    alertWarningBackgroundHover?: string;
    hover?: string;
    primaryButtonHover?: string;
    buttonBackground?: string;
    blueGray?: string;
    paleBlue?: string;
    deleteIconGray?: string;
    deleteIconGrayHover?: string;
    red?: string;
    cherryRed?: string;
    vividRed?: string;
    dialogTextGray?: string;
    navIconGray?: string;
    popOverBoxShadow?: string;
    activeStatusGreen?: string;
    navBorder?: string;
    iconShadowGray?: string;
    sliderRailColor?: string;
    progressTrackerBg?: string;
    progressBarBg?: string;
    ashGray?: string;
    lilacMist?: string;
    lavenderDusk?: string;
    selected?: string;
  }
  interface SimplePaletteColorOptions {
    primary?: string;
    white?: string;
    grayText?: string;
    grayText2?: string;
    grayBorder?: string;
    darkGray?: string;
    lightGrayBg?: string;
    fadedGray?: string;
    coolGray?: string;
    paleGray?: string;
    inputBackground?: string;
    seaGreen?: string;
    softSeaGreenBg?: string;
    paleGreen?: string;
    skyBlue?: string;
    skyBlueHover?: string;
    midnightBlue?: string;
    brightMidnightBlue?: string;
    softBlueBg?: string;
    poloBlue?: string;
    teal?: string;
    purple?: string;
    lavender?: string;
    selago?: string;
    lightCyan?: string;
    marineBlueLight?: string;
    marineBlue?: string;
    mossGreen?: string;
    greenishBlue?: string;
    grassGreen?: string;
    lightGreen?: string;
    backgroundGray?: string;
    shadowGray?: string;
    grayishBlack?: string;
    opaqueOverlay?: string;
    darkShadowGray?: string;
    boxShadowGray?: string;
    border?: string;
    error?: string;
    yellow?: string;
    scrollBarColor?: string; //@TODO: Lets put this in the global styles so all scrollbars are styled the same
    softPurpleBg?: string;
    gray?: string;
    black?: string;
    subTitleBlack?: string;
    alertSuccessBorder?: string;
    alertSuccessColor?: string;
    alertSuccessBackground?: string;
    alertSuccessBackgroundHover?: string;
    alertErrorBorder?: string;
    alertErrorColor?: string;
    alertErrorBackground?: string;
    alertErrorBackgroundHover?: string;
    alertInfoBorder?: string;
    alertInfoColor?: string;
    alertInfoBackground?: string;
    alertInfoBackgroundHover?: string;
    alertWarningBorder?: string;
    alertWarningColor?: string;
    alertWarningBackground?: string;
    alertWarningBackgroundHover?: string;
    hover?: string;
    primaryButtonHover?: string;
    buttonBackground?: string;
    blueGray?: string;
    paleBlue?: string;
    deleteIconGray?: string;
    deleteIconGrayHover?: string;
    red?: string;
    cherryRed?: string;
    vividRed?: string;
    dialogTextGray?: string;
    navIconGray?: string;
    popOverBoxShadow?: string;
    activeStatusGreen?: string;
    navBorder?: string;
    iconShadowGray?: string;
    sliderRailColor?: string;
    progressTrackerBg?: string;
    progressBarBg?: string;
    ashGray?: string;
    lilacMist?: string;
    lavenderDusk?: string;
    selected?: string;
  }

  interface CredentialsPaletteColor {
    licenses: string;
    licenses2: string;
    certifications: string;
    certifications2: string;
    humanResources: string;
    humanResources2: string;
    employeeHealth: string;
    employeeHealth2: string;
    clinicalQual: string;
    clinicalQual2: string;
    credentials: string;
    credentials2: string;
    credentials3: string;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    md2: true;
    lg: true;
    xl: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    subtitle2: false;
    h5: false;
    h6: false;
  }
}

// Regular 400
// Medium 500
// SemiBold 600
// Bold 700

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    contact: true;
    link: true;
    round: true;
  }

  interface ButtonPropsSizeOverrides {
    xlarge: true;
    xsmall: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  spacing: 6,
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'md2', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      md2: 1110, // For Community Page
      lg: 1366,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '42px',
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            background: '#003C69',
            color: '#FFFFFF',
            maxWidth: '327px',
            fontSize: '16px',
            fontWeight: 'normal',
            borderRadius: '7px',
            textTransform: 'none',
            ':hover': {
              background: '#003C69',
              color: '#FFFFFF',
              opacity: 0.92,
            },
            ':disabled': {
              background: '#75787B',
              color: '#FFFFFF',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            background: '#FFFFFF',
            color: '#003C69',
            maxWidth: '327px',
            fontSize: '16px',
            fontWeight: 'normal',
            border: '1px solid #CCCCCC',
            borderRadius: '7px',
            textTransform: 'none',
            ':hover': {
              background: '#F0F0F0',
              color: '#003C69',
            },
            ':disabled': {
              background: '#FFFFFF',
              color: '#75787B',
              border: '1px solid #CCCCCC',
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            background: '#0098DB',
            color: '#FFFFFF',
            maxWidth: '327px',
            fontSize: '18px',
            fontWeight: 'normal',
            borderRadius: '7px',
            ':hover': {
              background: '#0098DB',
              color: '#FFFFFF',
            },
          },
        },
        {
          props: { variant: 'contact' },
          style: {
            color: 'transparent',
            width: '42px',
            borderRadius: '50%',
            minWidth: 'unset',
            ':hover': {
              background: '#0098DB',
              color: '#FFFFFF',
            },
            ':disabled': {
              background: '#CCCCCC',
              color: '#FFFFFF',
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            background: 'transparent',
            color: '#0098DB',
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'none',
            ':hover': {
              background: 'transparent',
              color: '#0098DB',
              textDecoration: 'underline',
            },
            ':disabled': {
              background: 'transparent',
              color: '#75787B',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            background: 'transparent',
            color: '#0098DB',
            fontSize: '16px',
            fontWeight: 600,
            textTransform: 'none',
            ':hover': {
              background: 'transparent',
              color: '#0098DB',
              textDecoration: 'underline',
            },
            ':disabled': {
              background: 'transparent',
              color: '#75787B',
            },
          },
        },
        {
          props: { variant: 'round' },
          style: {
            borderRadius: '100%',
            background: '#003C69',
            color: '#FFFFFF',
            fontSize: '16px',
            textTransform: 'none',
            padding: '18px !important',
            width: '60px',
            minWidth: '60px',
            minHeight: '60px',
            height: '60px',
            ':disabled': {
              background: '#75787B',
            },
          },
        },
        {
          props: { size: 'xlarge' },
          style: {
            padding: '10px 106px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '10px 80px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '10px 45px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '10px 24px',
          },
        },
        {
          props: { size: 'xsmall' },
          style: {
            padding: '10px 0',
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F7F7F7 !important',
          '& .Mui-focused': {
            backgroundColor: '#EFEFEF !important',
          },
          '&:hover': {
            backgroundColor: '#F7F7F7 !important',
          },
          '&:before': {
            borderBottom: '1px solid #CCCCCC !important',
          },
          '&.Mui-error': {
            '&:after': {
              borderBottomColor: '#D9553D',
            },
          },
          height: '56px',
          fontSize: '16px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#75787B',
          fontSize: '16px',
          '& .Mui-focused': {
            color: '#6639B7',
          },
          '&.Mui-error': {
            color: '#D9553D',
          },
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#D9553D',
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: '35px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: '327px',
        },
        paperWidthMd: {
          maxWidth: '768px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: false,
      },
      styleOverrides: {
        rounded: {
          borderRadius: '4px',
        },
        root: {
          overflow: 'hidden',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1248px',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#E5E5E5',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h1: {
      fontSize: 30,
      lineHeight: '35px',
      letterSpacing: 0,
      fontWeight: 400,
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '35px',
      letterSpacing: 0,
    },
    h3: {
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0,
    },
    h4: {
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: 0,
    },
    h5: undefined,
    h6: undefined,
    subtitle1: {
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: 0,
      fontWeight: 'normal',
    },
    subtitle2: undefined,
    body1: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0,
      fontWeight: 'normal',
    },
    body2: {
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0,
      fontWeight: 'normal',
    },
    body3: {
      fontSize: 10,
      lineHeight: '14px',
      letterSpacing: 0,
      fontWeight: 'normal',
    },
  },
  palette: {
    primary: {
      main: '#6639B7',
    },
    secondary: {
      main: '#0098DB',
    },
    system: {
      primary: '#6639B7',
      main: '#ffffff',
      white: '#ffffff',
      darkGray: '#222222',
      lightGrayBg: '#EFEFEF',
      fadedGray: '#F2F2F2',
      grayBorder: '#E8E8E8',
      backgroundGray: '#F5F5F5',
      border: '#E5E5E5',
      coolGray: '#75787B',
      inputBackground: '#F7F7F7',
      seaGreen: '#148B95',
      paleGreen: '#99D898',
      softSeaGreenBg: '#148B950D',
      skyBlue: '#0098DB',
      skyBlueHover: '#0088CB',
      midnightBlue: '#003C69',
      brightMidnightBlue: '#194F77',
      softBlueBg: '#009AD90D',
      poloBlue: '#7FACDD',
      teal: '#008B95',
      purple: '#6639B7',
      lavender: '#AC98DB',
      selago: '#F3EFFE',
      lightCyan: '#5EB6E4',
      marineBlueLight: '#7FCBED',
      marineBlue: '#005BBB',
      mossGreen: '#7AB800',
      grassGreen: '#34B233',
      lightGreen: '#E9F7F8',
      greenishBlue: '#0B8B95',
      grayText: '#333333',
      grayText2: '#555555FF',
      gray: '#CCCCCC',
      shadowGray: '#B9BBBC',
      darkShadowGray: '#B9BBBC40',
      boxShadowGray: '#75787B40',
      grayishBlack: '#00000004',
      opaqueOverlay: '#0000004D',
      paleGray: '#E6E6E6',
      black: '#000000',
      subTitleBlack: '#4b4b4b',
      error: '#D9553D',
      yellow: '#F28D17',
      scrollBarColor: '#00000061', //@TODO: Lets put this in the global styles so all scrollbars are styled the same
      softPurpleBg: '#6639B70D',
      alertSuccessBorder: '#34B233',
      alertSuccessColor: '#1D4620',
      alertSuccessBackground: '#EDF7ED',
      alertSuccessBackgroundHover: '#e3ece3',
      alertErrorBorder: '#D9553D',
      alertErrorColor: '#611A16',
      alertErrorBackground: '#FDEDE9',
      alertErrorBackgroundHover: '#eedfdc',
      alertInfoBorder: '#3CA0F5',
      alertInfoColor: '#0E3C61',
      alertInfoBackground: '#E8F4FD',
      alertInfoBackgroundHover: '#dde8f0',
      alertWarningBorder: '#FFA22E',
      alertWarningColor: '#663B01',
      alertWarningBackground: '#FFF4E5',
      alertWarningBackgroundHover: '63615f',
      hover: '#F4F4F4',
      primaryButtonHover: '#809FB4',
      buttonBackground: '#EFEFEF',
      paleBlue: '#7FC5CA',
      blueGray: '#7F9DB3',
      deleteIconGray: '#989898',
      deleteIconGrayHover: '#7A7A7A',
      red: '#D32F2F',
      cherryRed: '#F44646',
      vividRed: '#D60800',
      dialogTextGray: '#00000099',
      navIconGray: '#809EB4',
      popOverBoxShadow: '#0000004D',
      activeStatusGreen: '#7AB827',
      navBorder: '#DDDDDD',
      iconShadowGray: '#00000029',
      sliderRailColor: '#959595',
      progressTrackerBg: '#F1F1F1',
      progressBarBg: '#E1E1E1',
      ashGray: '#7C7E81',
      lilacMist: '#F8F6FC',
      lavenderDusk: '#E3DBF2',
      selected: '#F2FAFD',
    },
    credentialsCategory: {
      licenses: '#6639B7',
      licenses2: '#B29BDB',
      certifications: '#005BBB',
      certifications2: '#7FACDD',
      humanResources: '#7FACDD',
      humanResources2: '#BED5EE',
      employeeHealth: '#008B95',
      employeeHealth2: '#7FC5CA',
      clinicalQual: '#7AB800',
      clinicalQual2: '#BCDB7F',
      credentials: '#75787B',
      credentials2: '#B9BBBC',
      credentials3: '#6639B7',
    },
  },
});

export { theme };
