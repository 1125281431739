export enum ContractStatusIdStates {
  READY_TO_SIGN = 100,
  SIGNED = 200,
}

export enum ContractTypeIds {
  CONTRACT = 1,
  ADDENDUM = 2,
}

export enum ContractStatus {
  READY_TO_SIGN = 100,
}
