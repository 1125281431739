import { Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { DrawerHeaderType } from 'enums/headerTypes';
import { IMultipleRecruiterDialogProps } from 'interfaces/Props/IApplyJobDialogProps';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { IContactState } from 'store/slices/user/userContact/userContactState';
import { theme } from 'styles/theme';
import { PersonalInfo } from '../PersonalInfoCard/PersonalInfo/PersonalInfo';

export const ApplyJobToMultipleRecruiterDialog = (
  props: IMultipleRecruiterDialogProps,
) => {
  const {
    open,
    handleCancelClick,
    handleSendClick,
    handleApplyJob,
    setSelectedRecruiters,
  } = props;

  const userContact = useAppSelector(
    state => state.userContact,
  ) as IContactState;

  const preferredRecruiterId = useAppSelector(
    state => state.userPreference.userPreference?.preferredRecruiter,
  ) as number | null;

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const recruiters = userContact?.userContacts?.userContacts?.filter(
    g => g.employeeRoleId === AssignmentRoleId.RECRUITER,
  );

  const toggleDrawer = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent, open: boolean) => {
      if (!open && handleCancelClick) {
        handleCancelClick();
        if (recruiters && recruiters.length > 0) {
          setSelectedItems([recruiters[0].employeeId!]);
        }
      }
    },
    [recruiters, handleCancelClick],
  );

  const userRadioSelected = (userId: number) => {
    const newList = [userId];
    setSelectedItems(prev => newList);
  };

  useEffect(() => {
    if (recruiters && recruiters.length > 0 && recruiters[0].employeeId) {
      setSelectedItems([recruiters[0].employeeId]);
    }
    return () => {
      setSelectedItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    handleSendClick?.();
    handleApplyJob?.(selectedItems);
  };

  return (
    <>
      <DrawerPanel
        open={open}
        actionButtonText="Send It!"
        primaryOnClick={handleSubmit}
        primaryBtnDisabled={!selectedItems}
        cancelButtonText="CANCEL"
        toggleDrawer={toggleDrawer}
        contentName="Send this Job to Your Recruiter?"
        headerType={DrawerHeaderType.Close}
        buttonId="submitButtonApplyJobToMultiple"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: theme.palette.system.coolGray,
                textAlign: 'left',
                fontSize: '16px',
                lineHeight: '22px',
              }}
            >
              Completing this action informs your recruiter that you are
              interested in applying for this job.
            </Typography>
          </Grid>

          {recruiters && recruiters.length > 1 && (
            <>
              {recruiters.map((contact, index) => (
                <Grid item xs={12} key={index} pt={2}>
                  <FormControlLabel
                    id={`checkbox${contact.employeeId}`}
                    control={
                      <Radio
                        color="secondary"
                        checked={selectedItems.includes(contact.employeeId!)}
                        onChange={e => userRadioSelected(contact.employeeId!)}
                      />
                    }
                    label={
                      <PersonalInfo
                        id="personal-info"
                        name={`${contact?.firstName ?? ''} ${
                          contact?.lastName ?? ''
                        }`}
                        image={contact?.contactImage?.url ?? ''}
                        nameStyle={{
                          color: theme.palette.system.midnightBlue,
                          fontSize: '16px',
                          marginBottom: '2px',
                        }}
                        direction="row"
                        imageSize={48}
                      />
                    }
                    sx={{
                      color: theme.palette.system.midnightBlue,
                      fontSize: '16px',
                      lineHeight: '22px',
                    }}
                  />
                </Grid>
              ))}
            </>
          )}

          {recruiters && recruiters.length === 1 && (
            <>
              {recruiters.map((contact, index) => (
                <Grid item xs={12} key={index} pt={4}>
                  <PersonalInfo
                    id="personal-info"
                    name={`${contact?.firstName ?? ''} ${
                      contact?.lastName ?? ''
                    }`}
                    image={contact?.contactImage?.url ?? ''}
                    direction="row"
                    tag={!preferredRecruiterId ? '' : 'Preferred'}
                    designation="Recruiter"
                    imageSize={80}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </DrawerPanel>
    </>
  );
};
