import React from 'react';
import { createSvgIcon } from '@mui/material';

export const DownloadIcon = createSvgIcon(
  <svg
    id="Controls-icon-Docusign"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 38 38"
  >
    <g id="Group_10923" data-name="Group 10923">
      <rect
        id="Rectangle_5302"
        data-name="Rectangle 5302"
        width="23"
        height="23"
        fill="none"
      />
    </g>
    <g id="Group_2891" data-name="Group 2891" transform="translate(1 1.999)">
      <rect
        id="Rectangle_5300"
        data-name="Rectangle 5300"
        width="36"
        height="3.196"
        rx="1.598"
        transform="translate(0 30.804)"
      />
      <path
        id="Path_3763"
        data-name="Path 3763"
        d="M10.578.377A1.542,1.542,0,0,0,10.124.1l0,0c-.007-.006-.021,0-.031-.006A1.7,1.7,0,0,0,9.59,0a1.611,1.611,0,0,0-.164.011,2.112,2.112,0,0,0-.536.117,1.553,1.553,0,0,0-.461.278L.454,7.645a1.32,1.32,0,0,0,0,1.993,1.667,1.667,0,0,0,2.2,0L7.974,4.8,7.977,23A1.481,1.481,0,0,0,9.5,24.432a1.492,1.492,0,0,0,1.58-1.384V4.8L16.4,9.637a1.667,1.667,0,0,0,2.2,0,1.32,1.32,0,0,0,0-1.993L10.626.4C10.612.392,10.593.389,10.578.377Z"
        transform="translate(27.527 24.432) rotate(180)"
      />
    </g>
  </svg>,
  'Download',
);
