import React from 'react';
import { theme } from 'styles/theme';

export default function CloseIcon({
  color,
  width = '38px',
  height = '38px',
}: {
  color?: string;
  width?: string;
  height?: string;
}) {
  return (
    <svg
      id="Controls_Icon_Close"
      data-name="Controls/Icon/Close"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2362"
        data-name="Rectangle 2362"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g
        id="Group_3195"
        data-name="Group 3195"
        transform="translate(-16.251 -16.164)"
      >
        <path
          id="Path_2795"
          data-name="Path 2795"
          d="M52.732,52.666a1.776,1.776,0,0,1-2.465,0L35.224,37.71,20.493,52.324a1.754,1.754,0,0,1-2.468,0,1.725,1.725,0,0,1,0-2.45l14.85-14.749L17.786,20.144a1.724,1.724,0,0,1-.048-2.45,1.752,1.752,0,0,1,2.465-.048l.048.048L35.3,32.65,50.019,18.027a1.751,1.751,0,0,1,2.465.048,1.722,1.722,0,0,1,0,2.4L42.24,30.657l-4.593,4.565,6.838,6.792,8.248,8.192a1.717,1.717,0,0,1,.018,2.441Z"
          transform="translate(0)"
          fill={color || theme.palette.system.coolGray}
        />
      </g>
    </svg>
  );
}
