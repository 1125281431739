import { TravelAssignmentSubStatus } from 'enums/assignmentStatus';

export const bookedAssignmentTrackerSteps = [
  {
    name: TravelAssignmentSubStatus.AWAITING_CONTRACT,
    id: [TravelAssignmentSubStatus.AWAITING_CONTRACT],
    message:
      'Your contract is being prepared. Complete credentials to get ahead.',
  },
  {
    name: TravelAssignmentSubStatus.CONTRACT_READY,
    id: [TravelAssignmentSubStatus.CONTRACT_READY],
    message: 'Sign your contract and complete credentials.',
  },
  {
    name: TravelAssignmentSubStatus.IN_CREDENTIALING,
    id: [TravelAssignmentSubStatus.IN_CREDENTIALING],
    message:
      'Your assignment is starting soon! Complete credentials to ensure an on-time start.',
  },
  {
    name: TravelAssignmentSubStatus.CREDENTIALING_COMPLETE,
    id: [TravelAssignmentSubStatus.CREDENTIALING_COMPLETE],
    message: 'Credentials submitted and under review.',
  },
  {
    name: TravelAssignmentSubStatus.CLEAR_TO_START,
    id: [TravelAssignmentSubStatus.CLEAR_TO_START],
    message: "Congrats! You're ready to start your assignment.",
  },
];
