import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { DefaultDialog } from 'components/common/Dialog/DefaultDialog';
import { TOAST_MESSAGE } from 'constants/helperText';
import { actionItemTypeId } from 'enums/actionType';
import { MenuItems } from 'enums/menuItems';
import { openInNewTab } from 'helpers/openInNewTab';
import { useAppSelector } from 'store/hooks';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { theme } from 'styles/theme';

export const OpenRequirementsMenu = (card: any) => {
  const dispatch = useDispatch();

  const openRequirementItemToRemove = useAppSelector(
    state => state.credential.openRequirementItemToRemove,
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuItems, setMenuItems] = useState<string[]>([]);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, value: string) => {
    event.stopPropagation();
    if (card?.card?.actionItem && card?.card?.actionItemTypeId) {
      dispatch(
        credentialActions.setSelectedActionMenuItem({
          menuItem: value,
          taskId: card?.card?.actionItem?.id,
        }),
      );
    } else if (value === MenuItems.GO_TO_ITEM) {
      const url =
        card?.card?.actionItemTypeId === actionItemTypeId.Survey &&
        card?.card?.survey
          ? card?.card?.survey?.url
          : card?.card?.links[0]?.url;
      openInNewTab(url);
    } else if (value === MenuItems.MARK_AS_COMPLETE) {
      setOpenDialog(true);
    }
    setAnchorEl(null);
  };

  const handleDialogConfirm = (credentialId: number) => {
    setOpenSnackbar();
    dispatch(
      credentialActions.removeOpenRequirementItem({
        credentialId: credentialId,
      }),
    );
    setOpenDialog(false);
  };

  const setOpenSnackbar = () => {
    dispatch(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.PendingApproval,
        duration: 6000,
        actionButton: {
          text: 'UNDO',
          variant: 'text',
          onAction: () => {
            dispatch(credentialActions.restoreOpenRequirementItem());
          },
        },
      }),
    );
  };

  useEffect(() => {
    card?.card?.actionItem
      ? setMenuItems([
          MenuItems.GO_TO_ITEM,
          MenuItems.REMOVE_ITEM,
          MenuItems.CANCEL,
        ])
      : setMenuItems([
          MenuItems.GO_TO_ITEM,
          MenuItems.MARK_AS_COMPLETE,
          MenuItems.CANCEL,
        ]);
  }, [card?.card]);

  useEffect(() => {
    if (openRequirementItemToRemove.length > 0) {
      const timeOut = setTimeout(() => {
        let credentialId =
          openRequirementItemToRemove[0]?.assignmentCredential.id;
        let placementId =
          openRequirementItemToRemove[0]?.assignmentCredential.placementId;
        if (!!credentialId && !!placementId) {
          dispatch(
            credentialActions.setCredentialStatusAction({
              placementId,
              credentialId,
            }),
          );
        }
      }, 6000);

      return () => {
        // clears timeout before running the new effect
        clearTimeout(timeOut);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRequirementItemToRemove]);

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={e => {
          handleClick(e);
        }}
        sx={{
          '&.MuiIconButton-root': {
            height: '16px',
            width: '16px',
            padding: '0px',
          },
          zIndex: 1,
        }}
        data-testid="more-icon"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={e => handleClose(e, '')}
        PaperProps={{
          style: {
            padding: '10px 0px 10px 0px',
            boxShadow: `0px 2px 8px ${theme.palette.system.popOverBoxShadow}`,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          zIndex: 1,
        }}
      >
        {menuItems.map(option => (
          <MenuItem
            key={option}
            onClick={e => handleClose(e, option)}
            data-testid="menu-item"
          >
            <Typography
              variant="body1"
              color={theme.palette.system.grayText}
              lineHeight="20px"
            >
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <DefaultDialog
        open={openDialog}
        title={'Mark as Complete'}
        body={
          'Confirm requirement is complete and can be moved to pending approval.'
        }
        onClose={e => {
          e.stopPropagation();
          setOpenDialog(false);
        }}
        onConfirm={e => {
          e.stopPropagation();
          if (!!card?.card?.id) handleDialogConfirm(Number(card?.card?.id));
          if (!!card?.card?.survey?.id)
            handleDialogConfirm(Number(card?.card?.survey?.id));
        }}
        dialogPaperSx={{ maxWidth: '280px' }}
      />
    </>
  );
};
