import { Divider, Grid, Typography } from '@mui/material';
import { ContactCardImage } from 'components/ContactCard/ContactCardImage';
import ContactDetails from 'components/Jobs/PersonalInfoDetails/ContactDetails';
import PersonalDetails from 'components/Jobs/PersonalInfoDetails/PersonalDetails';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { AssignmentRoleId } from 'enums/assignmentRoleId';
import { AssignmentType } from 'enums/assignmentType';
import { IAssignmentContacts } from 'interfaces/Assignment/AssignmentSummaryItem';
import { IContact } from 'interfaces/User/Contact/IContact';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';

export const ContactsDrawerTemplate = ({
  assignmentContactDetails,
  defaultContactDetails,
}: {
  assignmentContactDetails: IAssignmentContacts[] | undefined;
  defaultContactDetails: IContact[];
}) => {
  const isMobileDevice = useMobileMediaQuery();
  const isStrike = useAppSelector(
    state =>
      (
        ((state.assignmentSummary.activeCardDetails || {})?.items || [])[0] ||
        {}
      )?.typeId === AssignmentType.STRIKE,
  );

  const contacts =
    assignmentContactDetails?.length && !isStrike
      ? [...assignmentContactDetails].sort((a, b) => {
          return a?.employeeRole?.localeCompare(b?.employeeRole);
        })
      : defaultContactDetails;

  let showPreferredRecruiterForUserContacts = useMemo(() => {
    let count = 0;
    contacts.forEach((c: IContact | IAssignmentContacts) => {
      if (c.employeeRoleId === AssignmentRoleId.RECRUITER) {
        count++;
      }
    });
    return count > 1 && !isStrike;
  }, [contacts]);

  return (
    <>
      <Typography color="system.grayText" variant="subtitle1" mb={2}>
        Contact Us for Assistance
      </Typography>
      {contacts?.map(
        (contactItem: IContact | IAssignmentContacts, index: number) => (
          <Grid container rowSpacing={2} key={index}>
            <Grid
              item
              container
              justifyContent="start"
              display="flex"
              flexDirection="row"
              mt={index === 0 ? 0 : 2}
            >
              <Grid item xs={2} width={60} height={60}>
                <ContactCardImage
                  id="contact-image"
                  avatarVariant="text"
                  image={contactItem?.contactImage?.url}
                  avatarText={
                    contactItem?.firstName + ' ' + contactItem?.lastName
                  }
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="start"
                alignContent="flex-start"
                mt="10px"
                sx={{
                  pl: { xs: '24px', sm: '10px' },
                }}
              >
                <PersonalDetails
                  name={contactItem.firstName + ' ' + contactItem.lastName}
                  designation={contactItem.employeeRole}
                  contact={contactItem}
                  showPreferredBadge={showPreferredRecruiterForUserContacts}
                  isStrike={isStrike}
                />
                {!isMobileDevice && (
                  <ContactDetails
                    emailAddress={contactItem.emailAddress}
                    phoneNumber={contactItem.phoneNumber}
                    smsNumber={contactItem.smsNumber}
                  />
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                {isMobileDevice && (
                  <ContactDetails
                    emailAddress={contactItem.emailAddress}
                    phoneNumber={contactItem.phoneNumber}
                    smsNumber={contactItem.smsNumber}
                  />
                )}
              </Grid>
            </Grid>

            {++index !== contacts.length && (
              <Divider
                variant="fullWidth"
                sx={{
                  mt: 4,
                  mb: 2,
                  width: '100%',
                }}
              />
            )}
          </Grid>
        ),
      )}
    </>
  );
};
