import { Grid } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';
import { createLeftScrollPageStyles } from './PageLayout.styles';

export const LeftScrollPage = ({
  leftContent,
  rightContent,
  quickFilterContent,
  drawerContent,
}: {
  leftContent?: any;
  rightContent?: any;
  quickFilterContent?: any;
  drawerContent?: any;
}) => {
  const classes = createLeftScrollPageStyles();
  return (
    <>
      <Grid container id="left-scroll-container">
        {quickFilterContent && (
          <Grid
            container
            spacing={3}
            className={classes?.quickFilter}
            sx={{
              borderBottom: `1px solid ${theme.palette.system.border}`,
              height: '60px',
              backgroundColor: 'system.white',
            }}
          >
            {quickFilterContent}
            {drawerContent}
          </Grid>
        )}
        <Grid container spacing={3} className={classes?.leftScrollContainer}>
          <Grid
            item
            xs={12}
            md={4}
            lg={3.41}
            pb={{ xs: '0px !important', md: '37px !important' }}
            className={classes?.scrollContainer}
            pt={{ xs: '0px !important', md: '24px !important' }}
            pl={{ xs: '0px !important', md: '24px !important' }}
          >
            {leftContent}
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={8.59}
            sx={{ display: { xs: 'none', md: 'flex' } }}
            p={{ xs: 0, md: '24px 24px 0 24px !important' }}
          >
            {rightContent}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
