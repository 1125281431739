import { Grid, Typography } from '@mui/material';
import { DrawerContentNames } from 'enums/drawerContentNames';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { homeActions } from 'store/slices/home/homeSlice';
import { theme } from 'styles/theme';
import { DisplayHandler, ItineraryConfirmation } from './ConfirmItinerary';
import { ConfirmItineraryRadioGroup } from './ConfirmItineraryRadioGroup';
import ConfirmItineraryStatements from './ConfirmItineraryStatements';

export const ConfirmItineraryContent = props => {
  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setDrawerOpen,
    setActionButtonDisabled,
    setIsDirty,
    placementId,
    setActionButtonText,
  } = props;

  const dispatch = useAppDispatch();
  const customCloseEvent = new CustomEvent('drawer-close');

  const displayComponentRef = useRef<DisplayHandler>({
    changeRadioSelection: (value: string) => {},
  });
  const confirmItineraryRef = useRef({
    value: '',
  });

  const methods = useForm();
  const {
    formState: { isDirty },
  } = methods;

  const removeFromStrike = async () => {
    if (placementId) {
      dispatch(
        assignmentActions.removeAssignmentForStrike(placementId.toString()),
      );
      dispatch(homeActions.getHomePageData());
    }
  };

  const closeDrawer = () => {
    dispatchEvent(customCloseEvent);
    if (setDrawerOpen) {
      setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
    } else {
      dispatch(
        assignmentActions.setDrawerState(DrawerContentNames.CLOSED_DRAWER),
      );
    }
  };

  const submit = useCallback(
    () => {
      switch (confirmItineraryRef.current.value) {
        case ItineraryConfirmation.CONFIRM:
        case ItineraryConfirmation.REJECT:
          dispatch(
            assignmentActions.confirmItineraryAction(
              placementId,
              confirmItineraryRef.current.value ===
                ItineraryConfirmation.CONFIRM,
            ),
          );
          closeDrawer();
          break;
        case ItineraryConfirmation.CHANGE_TRAVEL_MODE:
          setDrawerOpen(DrawerContentNames.FORMSTACK);
          break;
        case ItineraryConfirmation.REMOVE_FROM_STRIKE:
          removeFromStrike();
          closeDrawer();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmItineraryRef.current.value, placementId],
  );

  useEffect(() => {
    if (onSubmitProcess) {
      submit();
      setOnSubmitProcess?.(false);
    }
  }, [onSubmitProcess, setOnSubmitProcess, submit]);

  useEffect(
    () => {
      setIsDirty(isDirty);
      setActionButtonDisabled(!isDirty);
      return () => {
        setActionButtonDisabled(false);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, setActionButtonDisabled],
  );

  useEffect(() => {
    return () => {
      setIsDirty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRadioSelectionChange = (value: string) => {
    confirmItineraryRef.current.value = value;
    setActionButtonText(
      value === 'remove-from-strike'
        ? 'Remove Assignment'
        : value === 'travel-mode-change'
        ? 'Continue to Travel Form'
        : 'Submit',
    );
  };

  return (
    <>
      <Grid container gap={1}>
        <Grid item>
          <Typography
            color={theme.palette.system.grayText}
            variant="body1"
            lineHeight="20px"
          >
            Please review and confirm your Travel Details.
          </Typography>
        </Grid>
        <FormProvider {...methods}>
          <Grid item>
            <ConfirmItineraryRadioGroup
              displayComponentRef={displayComponentRef}
              dataRef={confirmItineraryRef.current.value}
              getInputDetails={onRadioSelectionChange}
            />
          </Grid>
        </FormProvider>
        <ConfirmItineraryStatements
          ref={displayComponentRef}
          setActionButtonDisabled={setActionButtonDisabled}
          isDirty={isDirty}
        />
      </Grid>
    </>
  );
};
