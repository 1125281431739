import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IConfirmationModal } from 'interfaces/Props/IConfirmationModal';
import { AppButton } from '../Button/Button';
import DocumentCheckedIcon from 'assets/icons/Credentials/DocumentCheckedIcon';
import { Grid } from '@mui/material';

export const ConfirmationPopup = ({
  affirmative = 'CONFIRM',
  negative = 'CANCEL',
  isPanelOpen,
  handleClose,
  handleProceed,
  title,
  content,
  isHandleProceedEnabled,
  isTextCentered = false,
  isIcon = false,
  titleSx,
  buttonSx,
}: IConfirmationModal) => {
  return (
    <>
      <Dialog
        id={`${title}-confirmation-popup`}
        PaperProps={{
          style: {
            borderRadius: '4px',
          },
        }}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            minWidth: isIcon ? '327px' : '280px',
            maxWidth: isIcon ? '327px' : '280px',
          },
        }}
        open={isPanelOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isIcon && (
          <Grid
            container
            sx={{
              pt: 6,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <DocumentCheckedIcon />
            </Grid>
          </Grid>
        )}
        <DialogTitle
          sx={{
            textAlign: isTextCentered ? 'center' : 'start',
            fontSize: '20px',
            fontWeight: 600,
            pt: isIcon ? 2 : 4,
            pb: '12px',
            color: 'system.midnightBlue',
            ...titleSx,
          }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: isIcon ? '16px' : '14px',
              textAlign: isTextCentered ? 'center' : 'start',
              lineHeight: isIcon ? '22px' : '20px',
              pb: 0,
            }}
            id="alert-dialog-description"
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: isIcon ? 'center' : 'auto',
            pt: 0,
            pr: 4,
            pb: 3,
            '&.MuiDialogActions-root.MuiDialogActions-spacing': {
              px: isIcon ? 0 : 4,
            },
          }}
        >
          <AppButton
            size="auto"
            variant="action-link"
            type="button"
            onClick={handleClose}
            sx={buttonSx}
          >
            {negative}
          </AppButton>
          {isHandleProceedEnabled && (
            <AppButton
              size={isIcon ? 'sm' : 'xs'}
              variant="primary"
              type="button"
              onClick={handleProceed}
              sx={buttonSx}
            >
              {affirmative}
            </AppButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
