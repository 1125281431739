export function formatPhoneNumber(value) {
  const defaultLength = 10;
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  let phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  //removing country code from the phone number
  if (phoneNumberLength > defaultLength) {
    phoneNumber = phoneNumber.slice(0, phoneNumber.length - 1);
  }

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, defaultLength)}`;
}

export function formatPhoneNumberLastDigits(value: string) {
  // clean the input for any non-digit values.
  value = value?.replaceAll?.(/[^\d]/g, '');

  // check that the number provided has at least 4 digits
  if (value?.length >= 4) {
    return value.slice(value.length - 4);
  }

  // invalid number provided to return null
  return null;
}

/**
 * removes all '-' '.' ' ' from phone number since the html tel: requires there to be no characters between numbers */
export const normalizePhoneNumber = (rawPhoneStr: string): string => {
  return `tel:${rawPhoneStr.replace(/-|\(|\)|\./g, '')}`;
};

/** converts phone numbers in various formats
  (i.e. 123-456-7890 or 123.456.7890 or 1234567890)
  to standard (xxx) xxx-xxxx */
export const standardizePhoneNumber = (rawPhoneStr: string): string | null => {
  let cleaned = rawPhoneStr.replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};
