import { Avatar, CircularProgress, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CredentialControlIcon from 'assets/icons/CredentialControlIcon/CredentialControlIcon';
import { StatusCode } from 'enums/StatusCode';
import React, { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import { theme } from 'styles/theme';

interface ICredentialPercentageProps {
  width: string;
  height: string;
  marginRight: string;
  fontSize?: string;
  credentialPercentage: number;
}

const CredentialPercentage: FC<ICredentialPercentageProps> = ({
  width,
  height,
  marginRight,
  fontSize,
  credentialPercentage,
}) => {
  const gray = theme.palette.system.gray;
  let boxShadow = `inset 0 0 0px 4px ${gray}`;

  let percentageSX = {
    color: 'system.grassGreen',
    position: 'absolute',
    fontWeight: 600,
  };
  if (fontSize) {
    percentageSX['fontSize'] = fontSize;
    boxShadow = `inset 0 0 0px 3px ${gray}`;
  }

  const credentialStatus = useAppSelector(state => {
    return state.credentialStatus;
  });

  const error = credentialStatus?.error;

  const getCredentialProgress = () => {
    if (Boolean(credentialPercentage)) {
      return (
        <>
          <CircularProgress
            variant="determinate"
            style={{
              width: width,
              height: height,
              borderRadius: '100%',
              boxShadow: boxShadow,
              backgroundColor: 'transparent',
            }}
            color="inherit"
            size={3}
            value={credentialPercentage}
            data-testid="circular-progress"
          />

          <Typography
            variant="caption"
            component="span"
            sx={{ ...percentageSX }}
            data-testid="circular-progress-value"
          >{`${Math.round(credentialPercentage || 0)}%`}</Typography>
        </>
      );
    }
    return <></>;
  };
  return (
    <Box
      id="credential-percentage"
      sx={{
        position: 'relative',
        display: 'inline-flex',
        width: width,
        height: height,
        marginRight: marginRight,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        verticalAlign: 'middle',
        color: 'system.grassGreen',
      }}
    >
      {credentialStatus.isloading ? (
        <Skeleton variant="circular" width={30} height={30}>
          <Avatar />
        </Skeleton>
      ) : (error && error?.errorCode >= StatusCode.InternalServerError) ||
        credentialStatus?.receivedNoContent ? (
        <CredentialControlIcon width={'18'} height={'19'} />
      ) : (
        getCredentialProgress()
      )}
    </Box>
  );
};
export default CredentialPercentage;
