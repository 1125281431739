import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCobrowse } from 'utils/hooks/useCobrowse';
import { theme } from 'styles/theme';
import { buttonSizes } from 'components/common/Button/Button.styles';

const EndScreenShareButton = ({
  showInDesktop = true,
  showInMobile = true,
}) => {
  const { cobrowsing, stopCobrowseSession } = useCobrowse();

  if (!cobrowsing) return <></>;

  return (
    <React.Fragment>
      {/* For web responsive - end share screen button */}
      {showInMobile && (
        <Box
          id="btn-stop-screen-share-web-responsive"
          sx={{
            background: theme.palette.system.cherryRed,
            height: '44px',
            justifyContent: 'center',
            color: 'system.white',
            display: { xs: 'flex', md: 'none' },
          }}
          onClick={stopCobrowseSession}
        >
          <Typography position="absolute" top="12px">
            Stop Sharing
          </Typography>
        </Box>
      )}
      {/* web responsive end share screen button html ends here */}
      {/* For web end share screen button */}
      {showInDesktop && (
        <Grid
          item
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Button
            onClick={stopCobrowseSession}
            id="btn-stop-screen-share"
            variant="contained"
            sx={{
              width: buttonSizes.sm,
              height: '42px',
              backgroundColor: theme.palette.system.vividRed,
              textTransform: 'none',
              padding: '12px',
              fontSize: '16px',
              fontWeight: '400',
              borderRadius: '7px',
              marginRight: '10px',
              '&:hover': {
                backgroundColor: theme.palette.system.vividRed,
                boxShadow: 'none',
              },
            }}
            data-testid="btn-stop-screen-share"
          >
            Stop Sharing
          </Button>
        </Grid>
      )}
      {/* web end share screen button html ends here */}
    </React.Fragment>
  );
};

export default EndScreenShareButton;
