import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ArrowLeftIcon = createSvgIcon(
  <svg
    id="Controls-Icon-Arrow-Left"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
  >
    <rect id="Rectangle_2317" data-name="Rectangle 2317" />
    <path
      id="Path_3771"
      data-name="Path 3771"
      d="M22.892,35a1.742,1.742,0,0,0,.516,1.235L39.89,52.609a1.766,1.766,0,0,0,2.486,0,1.737,1.737,0,0,0,0-2.471L27.138,35l15-14.9a1.739,1.739,0,0,0,0-2.471,1.768,1.768,0,0,0-2.487,0L23.408,33.764A1.742,1.742,0,0,0,22.892,35Z"
      transform="translate(-13.892 -16.119)"
    />
  </svg>,
  'ArrowLeft',
);
