import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISkillChecklist } from 'interfaces/SkillsChecklists/ISkillChecklist';
import { ISkillsChecklistsDetails } from 'interfaces/SkillsChecklists/ISkillsChecklistsDetails';
import { ISkillsChecklistsState } from './skillsChecklistsState';

const initialState: ISkillsChecklistsState = {
  skillsChecklists: [],
  nurseSkillsList: [],
  alliedSkillsList: [],
  isLoading: false,
};

const skillsChecklistsSlice = createSlice({
  name: 'skillsChecklistsSlice',
  initialState,
  reducers: {
    getSkillsChecklists() {},
    setSkillsChecklists(
      state,
      action: PayloadAction<ISkillsChecklistsDetails[]>,
    ) {
      state.skillsChecklists = action.payload;
    },
    getNurseSkillList(state) {
      state.isLoading = true;
    },
    setNurseSkillsList(state, action: PayloadAction<ISkillChecklist[]>) {
      state.isLoading = false;
      state.nurseSkillsList = action.payload?.sort((a, b) =>
        a.type.localeCompare(b.type),
      );
    },
    getAlliedSkillList(state) {
      state.isLoading = true;
    },
    setAlliedSkillsList(state, action: PayloadAction<ISkillChecklist[]>) {
      state.isLoading = false;
      state.alliedSkillsList = action.payload?.sort((a, b) =>
        a.type.localeCompare(b.type),
      );
    },
    resetSkillsList(state) {
      state.nurseSkillsList = [];
      state.alliedSkillsList = [];
    },
  },
});

export const {
  setSkillsChecklists,
  getSkillsChecklists,
  getNurseSkillList,
  setNurseSkillsList,
  getAlliedSkillList,
  setAlliedSkillsList,
  resetSkillsList,
} = skillsChecklistsSlice.actions;

export default skillsChecklistsSlice.reducer;
