import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';

import { MobileNavMenu } from 'components/Navigation/MobileNavMenu/MobileNavMenu';
import NavMenu from 'components/Navigation/NavMenu/NavMenu';
import { RootState, useAppDispatch } from 'store/configureStore';
import { setMenuState } from 'store/slices/navigation/navigationSlice';
import { desktopWidth } from 'styles/media';
import { StylesNavMenuContainer } from './NavMenuContainer.styles';

const NavMenuContainer = () => {
  const dispatch = useAppDispatch();
  const totalTimeStyle = StylesNavMenuContainer();
  const matchesMinWidth1025 = useMediaQuery(desktopWidth);

  const isMenuOpen: boolean = useSelector(
    (state: RootState) => state.nav.isMenuOpen,
  );

  useEffect(() => {
    if (matchesMinWidth1025 && isMenuOpen) {
      dispatch(setMenuState(false));
    }
  }, [dispatch, isMenuOpen, matchesMinWidth1025]);

  return matchesMinWidth1025 ? (
    <div className={totalTimeStyle.navbar}>
      <NavMenu />
    </div>
  ) : (
    <MobileNavMenu />
  );
};

export default NavMenuContainer;
