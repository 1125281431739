export function addRedirectUrl(originalUrl, redirectUrl) {
  const hasQueryParams = originalUrl?.includes('?');

  const param = `redirectUrl=${redirectUrl}`;

  const finalUrl = hasQueryParams
    ? `${originalUrl}&${param}`
    : `${originalUrl}?${param}`;

  return finalUrl;
}
