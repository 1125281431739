import React from 'react';

export default function EvaluationRequestIcon({
  color,
  height,
  width,
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls-icon-Document"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '38'}
      height={height ?? '38'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2261"
        data-name="Rectangle 2261"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <g id="Group_2856" data-name="Group 2856" transform="translate(5 1)">
        <path
          id="Path_3718"
          data-name="Path 3718"
          d="M40.31,10.2H25.461a5.614,5.614,0,0,0-3.311,1.252L17.427,15.62a4.828,4.828,0,0,0-1.479,3.273v24.68A2.634,2.634,0,0,0,18.586,46.2H40.31a2.634,2.634,0,0,0,2.638-2.624V12.821A2.634,2.634,0,0,0,40.31,10.2ZM23.743,13.232A2.014,2.014,0,0,1,24.091,13v4.226H19.224ZM40.55,43.573a.242.242,0,0,1-.24.239H18.586a.242.242,0,0,1-.24-.239V19.609H25.29a1.2,1.2,0,0,0,1.2-1.193V12.582H40.31a.242.242,0,0,1,.24.239Z"
          transform="translate(-15.948 -10.197)"
          fill={color}
        />
        <path
          id="Path_3719"
          data-name="Path 3719"
          d="M35.4,25.54H24.056a1.193,1.193,0,1,0,0,2.385H35.4a1.193,1.193,0,1,0,0-2.385Z"
          transform="translate(-16.23 -10.902)"
          fill={color}
        />
        <path
          id="Path_3720"
          data-name="Path 3720"
          d="M35.4,31.233H24.056a1.193,1.193,0,1,0,0,2.385H35.4a1.193,1.193,0,1,0,0-2.385Z"
          transform="translate(-16.23 -11.164)"
          fill={color}
        />
        <path
          id="Path_3721"
          data-name="Path 3721"
          d="M35.4,36.925H24.056a1.193,1.193,0,1,0,0,2.385H35.4a1.193,1.193,0,1,0,0-2.385Z"
          transform="translate(-16.23 -11.425)"
          fill={color}
        />
      </g>
    </svg>
  );
}
