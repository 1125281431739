import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { AppButton } from 'components/common/Button/Button';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, pt: '20px !important', pb: '21px !important' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          size="large"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface ICustomDialog {
  isOpen: boolean;
  handleClose: any;
  title: string;
  body: any;
  dialogContentSX?: any;
  paperPropSx?: React.CSSProperties;
}

export const CustomizedDialog: FC<ICustomDialog> = props => {
  return (
    <>
      <CustomDialog
        id={'custom-dialog-' + props.title}
        onClose={props.handleClose}
        aria-labelledby="JobFlag-dialog"
        open={props.isOpen}
        scroll="body"
        maxWidth="md"
        fullWidth={true}
        sx={{
          borderRadius: '10px',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: {
            md: '768px',
            lg: '1366px',
          },
          zIndex: 2200,
        }}
        PaperProps={{
          style: { borderRadius: '10px', ...props.paperPropSx },
        }}
      >
        <CustomDialogTitle
          id="JobFlag-dialog-title"
          onClose={props.handleClose}
        >
          <Typography
            variant="h4"
            color="system.midnightBlue"
            data-testid="dialog-title"
          >
            {props.title}
          </Typography>
        </CustomDialogTitle>

        {props.dialogContentSX ? (
          <DialogContent
            dividers
            sx={{ ...props.dialogContentSX }}
            data-testid="dialog-content"
          >
            {props.body}
          </DialogContent>
        ) : (
          <DialogContent dividers>{props.body}</DialogContent>
        )}

        <DialogActions
          sx={{
            display: 'flex',
            pt: 0,
            pr: 4,
            pb: 3,
            height: '67px',
          }}
        >
          <AppButton
            size="auto"
            variant="action-link"
            type={'button'}
            onClick={props.handleClose}
            sx={{ height: '39px' }}
          >
            Dismiss
          </AppButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};
