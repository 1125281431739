import { createAction, createSlice } from '@reduxjs/toolkit';
import { DataLayerPageName } from 'enums/dataLayer';

const initialState = {
  impressionClickList: undefined,
  impressionClickPosition: undefined,
};

const dataLayerReadyAction = createAction(
  'DL_READY',
  function prepare(pageName: DataLayerPageName, isMobile?: boolean) {
    return {
      payload: {
        pageName,
        isMobile,
      },
    };
  },
);
const formCompleteAction = createAction<string>('FORM_COMPLETE');
const dataLayerJobSearchAction = createAction<string>('DATA_LAYER_JOB_SEARCH');
const dataLayerAddJobAction = createAction('DATA_LAYER_ADD_ACTION');
const dataLayerApplyJobAction = createAction('DATA_LAYER_APPLY_JOB');
const resetDataLayerAction = createAction('RESET_DATA_LAYER_ACTION');

export const dataLayerSlice = createSlice({
  name: 'dataLayerSlice',
  initialState,
  reducers: {
    setJobImpressionClick(state, action) {
      state.impressionClickList = action.payload.list;
      state.impressionClickPosition = action.payload.position;
    },
  },
});

export const dataLayerActions = {
  ...dataLayerSlice.actions,
  dataLayerReadyAction,
  formCompleteAction,
  dataLayerJobSearchAction,
  dataLayerAddJobAction,
  dataLayerApplyJobAction,
  resetDataLayerAction,
};
export default dataLayerSlice.reducer;
