import { IFileDetails } from 'interfaces/Credential/ICredentialDetails';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';
import { ITimeEntryWork } from 'interfaces/TimeEntry/ITimeEntryWork';
import moment from 'moment';

export const convertBase64ToUrl = (fileType: string, data: string) => {
  return `data:application/${fileType};base64,${data}`;
};
export const downloadBase64 = (fileDetails: IFileDetails) => {
  b64toBlob(fileDetails.data, `application/${fileDetails.fileType}`).then(
    blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileDetails.filename}`; //filetype is already received in filename
      link.click();
    },
  );
};
const b64toBlob = (base64, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`).then(res => res.blob());

export const calculateDuration = (
  clockIn: string,
  clockOut: string,
): number => {
  let duration: number = 0;
  let cIn = moment.duration(clockIn).asMilliseconds();
  let cOut = moment
    .duration(clockOut)
    .add(clockIn >= clockOut ? 1 : 0, 'days')
    .asMilliseconds();
  duration = Math.abs(cOut - cIn);
  return duration;
};

export const getWorkEntrysMealPeriodInMinutes = (
  we: ITimeEntryWork,
): number => {
  let mealPeriodMinutes = 0;
  we?.mealPeriods?.forEach(mp => {
    if (mp.clockInTime && mp.clockOutTime) {
      //For calculating the meal period it should be end time - start time.so swapped the variables
      //34546
      const mealDur = calculateDuration(mp.clockOutTime, mp.clockInTime);
      mealPeriodMinutes += moment.duration(mealDur).asMinutes();
    } else if (mp.elapsedTime) {
      mealPeriodMinutes += moment.duration(mp.elapsedTime).asMinutes();
    }
  });
  return mealPeriodMinutes;
};

export const convertTimeFormatFromHHMMSSToHHMMAMPM = (
  timeInHHMMSSFormat: string,
): string => {
  const timeInMS = moment.duration(timeInHHMMSSFormat).asMilliseconds();
  return moment.utc(timeInMS).format('h:mm A');
};

export const getFileExtension = filename => {
  const extension = (filename || '').split('.').pop();
  return extension;
};

export const createFlagDictionary = (featureFlags: any): any => {
  const flagDict = Object.fromEntries(
    (featureFlags || []).map(flag => [flag?.featureFlagTypeId, flag]),
  );
  return flagDict;
};

export const parseSearchText = (text: string, search: string) => {
  return text.split(
    new RegExp(`(${search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi'),
  );
};

export const showRadiusFilter = (filter?: IACSJobRequest) => {
  return filter?.locationSearch?.includes(', ') ? true : false;
};
