import { Collapse, Divider, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CredentialStatus from 'components/Jobs/CredentialStatus';
import { CloseButton } from 'components/common/Button/CloseButton';
import { JobBadge } from 'components/common/JobBadge/JobBadge';
import { AppLink } from 'components/common/Link/Link';
import domPurify from 'dompurify';
import { ShiftEnum } from 'enums/jobShifts';
import { getMMMddyyyy } from 'helpers/dateFormatter';
import { useGetAuthenticatedImage } from 'helpers/getAuthenticatedImage';
import { IApplyJobRequest } from 'interfaces/Jobs/IApplyJobRequest';
import { IJobDetailsPage } from 'interfaces/Jobs/IJobDetailsPage';
import { IJobsData } from 'interfaces/Jobs/IJobsData';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { getJobBadgeInformation } from 'services/jobs/jobUIService';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { IJobDetailsData, IJobState } from 'store/slices/jobs/jobsState';
import { contactActions } from 'store/slices/user/userContact/userContactSlice';
import { IContactState } from 'store/slices/user/userContact/userContactState';
import { theme } from 'styles/theme';
import Bed from '../../../assets/icons/Bed.svg';
import Calendar from '../../../assets/icons/Calendar.svg';
import Clock from '../../../assets/icons/Clock.svg';
import Day from '../../../assets/icons/Day.svg';
import Flag from '../../../assets/icons/Flag.svg';
import Map from '../../../assets/icons/Map-Pinpoint.svg';
import Mid from '../../../assets/icons/Mid.svg';
import Hours from '../../../assets/icons/Minimum-Hours-Time.svg';
import Night from '../../../assets/icons/Night.svg';
import Stethescope from '../../../assets/icons/Stethescope.svg';
import Travel from '../../../assets/icons/Travel-Jobs.svg';
import Questions from '../Questions';
import {
  AmountPerWeekTypography,
  AmountTextTypography,
  JobDetailStyle,
  amountContainerStyles,
} from './JobDetails.style';

import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { FeatureToggles } from 'enums/featureToggles';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import { shallowEqual } from 'react-redux';
import { trackEvent } from 'services/logging/appInsights';
import { applyJob } from 'store/slices/jobs/ApplyJobsSlice';
import { jobDetailsActions } from 'store/slices/jobs/jobDetailSlice';
import { ApplyJobCard } from '../ApplyJob/ApplyJobCard';
import { JobShare } from './JobShare';

export const JobDetails: React.FC<IJobDetailsPage> = ({
  classes,
  onCardClick,
  isCardExpanded = true,
  showClose = false,
  onCloseClick,
  showMap,
  isNotStrikeFilter = true,
}: {
  classes?: any;
  onCardClick?: () => void;
  isCardExpanded?: boolean;
  showClose?: boolean;
  onCloseClick?: () => void;
  showMap?: boolean;
  isNotStrikeFilter?: boolean;
}) => {
  const amountStyles = amountContainerStyles();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.auth.userId);
  const [selectedRecruiters, setSelectedRecruiters] = useState<number[]>([]);
  const isACSJobSearch = useFeatureToggle(FeatureToggles.ENABLE_ACS_JOB_SEARCH);

  const isTabletMode = useMediaQuery(theme.breakpoints.down('lg'));
  const clientHeight = document.getElementById('cardTrayWrapper')?.clientHeight;

  const { jobDetailsData } = useAppSelector(
    state => state.jobDetail,
    shallowEqual,
  ) as IJobDetailsData;

  const selectedJobId = useAppSelector(state => state.jobs.selectedJobId);
  const jobResponse = useAppSelector(state => state.jobs, shallowEqual);
  const jobResponseLength = jobResponse
    ? jobResponse.items
      ? jobResponse.items.length
      : 0
    : 0;
  const userContact = useAppSelector(
    state => state.userContact,
  ) as IContactState;

  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );

  const jobsData = useAppSelector(state => state.jobs) as IJobState;
  const jobSearchFilter = useAppSelector(state => {
    return state.jobs.jobSearchFilter;
  }) as IJobSearchRequestSlice;

  const job = jobsData?.items?.filter(j => j.jobId === jobDetailsData?.jobId);

  const jobData = job ? (job[0] as IJobsData) : null;

  const priorityTypes = jobDetailsData?.priorityTypes[0];
  const isStrike = jobDetailsData?.placementTypeId === 500;

  const badgeDetails = getJobBadgeInformation(priorityTypes ?? 0, isStrike);

  const sanitizedHTMLBenefits: any = domPurify.sanitize(
    jobDetailsData?.benefits === undefined ? '' : jobDetailsData?.benefits,
  );
  const benefits: any = { __html: sanitizedHTMLBenefits.toString() };

  const sanitizedHTMLCompany: any = domPurify.sanitize(
    jobDetailsData?.company === undefined ? '' : jobDetailsData?.company,
  );
  const company: any = { __html: sanitizedHTMLCompany.toString() };

  const sanitizedHTMLRequirements: any = domPurify.sanitize(
    jobDetailsData?.requirements === undefined
      ? ''
      : jobDetailsData?.requirements,
  );
  const requirements: any = { __html: sanitizedHTMLRequirements.toString() };

  const refreshJob = () => {
    dispatch(jobDetailsActions.setAppliedDateForJob());
  };

  const startDate = getMMMddyyyy(
    jobDetailsData?.startDate?.toString() === undefined
      ? ''
      : jobDetailsData?.startDate?.toString(),
  );

  const endDate = getMMMddyyyy(
    jobDetailsData?.endDate?.toString() === undefined
      ? ''
      : jobDetailsData?.endDate?.toString(),
  );

  const rate = useMemo(() => {
    let payRate = '$***';
    let maxPayRate = (jobDetailsData?.maxPayRate || '').toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );
    let minPayRate = (jobDetailsData?.minPayRate || '').toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );

    if (
      Math.round(jobDetailsData?.maxPayRate || -1) !== 0 &&
      Math.round(jobDetailsData?.minPayRate || -1) !== 0 &&
      minPayRate !== maxPayRate
    ) {
      payRate = `${minPayRate} - ${maxPayRate}`;
    } else if (Math.round(jobDetailsData?.maxPayRate || -1) !== 0) {
      payRate = maxPayRate;
    } else {
      payRate = minPayRate;
    }
    return payRate;
  }, [jobDetailsData?.maxPayRate, jobDetailsData?.minPayRate]);

  const postedDay = useMemo(() => {
    let days = 0;
    let postedDays = '';

    const postedDate = moment(jobDetailsData?.postedDate || '');
    days = moment().diff(postedDate, 'days');

    if (days === 1) {
      postedDays = `Posted ${days} Day ago`;
    } else if (days < 1) {
      days = moment().diff(postedDate, 'hours');
      postedDays = `Posted ${days} Hours ago`;
    } else {
      postedDays = `Posted ${days} Days ago`;
    }
    return postedDays;
  }, [jobDetailsData?.postedDate]);

  const facilityImage = useGetAuthenticatedImage(
    jobDetailsData?.facility?.image || undefined,
    { height: 190 },
  );

  useEffect(() => {
    if (!userContact || userContact.userContacts?.userContacts?.length === 0) {
      dispatch(contactActions.fetchUserContacts(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContact]);

  // listening for Esc key
  useEffect(() => {
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape' && onCloseClick) {
        onCloseClick();
      }
    });
    return () => {
      document.removeEventListener('keydown', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShiftIcon = shiftArr => {
    if (isStrike || shiftArr?.includes(200))
      return <img height="17px" src={Mid} alt="" />;
    if (shiftArr?.includes(100)) return <img height="17px" src={Day} alt="" />;
    if (shiftArr?.includes(300))
      return <img height="17px" src={Night} alt="" />;
  };

  const getShiftTimeString = shiftArr => {
    let shifts: string[] = [];
    if (isStrike) {
      shifts?.push(ShiftEnum.Days);
      shifts?.push(ShiftEnum.Mid);
      shifts?.push(ShiftEnum.Nights);
    } else {
      shiftArr?.forEach(shift => {
        if (shift === 100) {
          if (!shifts?.includes(ShiftEnum.Days)) {
            shifts?.push(ShiftEnum.Days);
          }
        } else if (shift === 200) {
          if (!shifts?.includes(ShiftEnum.Mid)) {
            shifts?.push(ShiftEnum.Mid);
          }
        } else if (shift === 300) {
          if (!shifts?.includes(ShiftEnum.Nights)) {
            shifts?.push(ShiftEnum.Nights);
          }
        }
      });
    }
    return shifts?.join(', ') ?? '';
  };

  const handleApplyJob = (recruiters: number[] | []) => {
    let recruiterArray: number[] | [] = [];
    if (Array.isArray(recruiters) && recruiters.length > 0) {
      recruiterArray = recruiters;
    }
    let request = {
      jobId: jobDetailsData?.jobId?.toString(),
      userId: userId,
      matchRanking: jobData?.matchRanking,
      recruiters: recruiterArray,
      searchSortTypeId: jobSearchFilter.sort,
    } as IApplyJobRequest;

    dispatch(
      applyJob({
        applyJob: { ...request },
        hasApplied: false,
        isLoading: false,
        externalLinkDrawerForStrikeJobApply: false,
        isStrike,
        strikeJobSignUpUrl: '',
      }),
    );
    if (showMap) {
      trackEvent('ApplyJob_MapView', {
        userId: `${userId}`,
        jobId: `${jobDetailsData?.jobId}`,
      });
    }
  };

  const address = useMemo(() => {
    return `${jobDetailsData?.facility?.address?.address1}${
      jobDetailsData?.facility?.address?.address2
        ? ' ' + jobDetailsData?.facility?.address?.address2
        : ''
    }, ${jobDetailsData?.facility?.address?.city}, ${
      jobDetailsData?.facility?.address?.state
    } ${jobDetailsData?.facility?.address?.zipCode}`;
  }, [jobDetailsData]);

  const addressCoordinates = useMemo(() => {
    const latitude =
      jobDetailsData?.facility?.address?.location?.coordinates[1];
    const longitude =
      jobDetailsData?.facility?.address?.location?.coordinates[0];
    return `${latitude},${longitude}`;
  }, [jobDetailsData]);

  const showStartEnd = useMemo(() => {
    return isTabletMode;
  }, [isTabletMode]);

  const jobRange = useMemo(() => {
    return (
      <>
        <Grid
          item
          container
          id="job-range"
          xs={12}
          display="flex"
          flexDirection="row"
          sx={{
            [theme.breakpoints.down('lg')]: {
              justifyContent: !isCardExpanded ? '' : 'space-between',
              gap: !isCardExpanded ? '24px' : '',
            },
          }}
        >
          <Grid
            item
            xs={9}
            sx={{
              [theme.breakpoints.down('lg')]: {
                maxWidth: !isCardExpanded
                  ? '120px !important'
                  : '210px !important',
              },
              [theme.breakpoints.up('lg')]: {
                maxWidth: '215px !important',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  paddingRight: '6px',
                }}
              >
                <img height="17px" src={Flag} alt="" />
              </Box>

              {!isStrike && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    [theme.breakpoints.down('lg')]: {
                      flexDirection: !isCardExpanded ? 'column' : '',
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    color={theme.palette.system.coolGray}
                    maxHeight="20px"
                  >
                    {startDate}&nbsp;–&nbsp;
                  </Typography>
                  <Typography
                    variant="body1"
                    color={theme.palette.system.coolGray}
                  >
                    {endDate}
                  </Typography>
                </Box>
              )}
              {isStrike && (
                <Typography
                  style={{ paddingLeft: '8px' }}
                  variant="body1"
                  color={theme.palette.system.coolGray}
                >
                  Start Date TBD
                </Typography>
              )}
            </Box>
          </Grid>
          {!isStrike && (
            <Grid item xs={4}>
              <Typography variant="body1" color="system.coolGray">
                <img height="17px" src={Hours} alt="" />
                <span style={{ paddingLeft: '8px', marginBottom: '12px' }}>
                  {jobDetailsData?.minimumRequiredHours} Hrs/wk
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  }, [
    endDate,
    isCardExpanded,
    isStrike,
    jobDetailsData?.minimumRequiredHours,
    startDate,
  ]);

  const jobDescription = useMemo(() => {
    return (
      <Grid
        item
        id="job-description"
        sx={{
          width: '100%',
          [theme.breakpoints.down('lg')]: {
            maxWidth: !isCardExpanded ? '148px !important' : '203px !important',
          },
          [theme.breakpoints.up('lg')]: {
            maxWidth: !isCardExpanded
              ? '409px !important'
              : showMap
              ? '387px !important'
              : '435px !important',
          },
          marginBottom: '12px',
        }}
        gap={
          isTabletMode
            ? badgeDetails?.name
              ? '8px'
              : '15px'
            : badgeDetails?.name
            ? '11px'
            : '8px'
        }
        display="flex"
        flexDirection="column"
      >
        {badgeDetails?.name && (
          <Box>
            <JobBadge
              name={badgeDetails.name}
              src={badgeDetails.src}
              color={badgeDetails.color}
            />
          </Box>
        )}

        <Box
          className={amountStyles.amountContainer}
          sx={{
            [theme.breakpoints.down('lg')]: {
              flexDirection: !isCardExpanded ? 'column !important' : '',
            },
          }}
        >
          {(jobDetailsData?.maxPayRate ?? 0) > 0 ? (
            <>
              <AmountTextTypography variant="h2" lineHeight="19px">
                {rate}
              </AmountTextTypography>
              <AmountPerWeekTypography
                variant="body2"
                color="textSecondary"
                paddingLeft="4px"
                lineHeight="19px"
              >
                {isStrike ? 'per hour' : `per week${isCardExpanded ? '*' : ''}`}
              </AmountPerWeekTypography>
            </>
          ) : (
            <AmountPerWeekTypography
              variant="body2"
              color="textSecondary"
              paddingLeft="4px"
              lineHeight="19px"
            >
              Contact for Details
            </AmountPerWeekTypography>
          )}
        </Box>
        <Typography variant="subtitle1" color="system.subTitleBlack">
          {jobDetailsData?.title}
        </Typography>
        <Box
          mb={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: '0px',
          }}
        >
          <Typography
            variant="subtitle1"
            color="system.coolGray"
            fontSize="14px"
            marginBottom={!isStrike ? '7px' : '0px'}
          >
            {jobDetailsData?.facility?.name || ''}
          </Typography>
          {!isStrike && (
            <Box display="flex">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingRight: '6px',
                }}
              >
                <AppLink
                  component="a"
                  target="_blank"
                  href={`https://www.google.com/maps/place/${address}/@${addressCoordinates}`}
                  id="map-icon-link"
                >
                  <img height="17px" src={Map} alt="" />
                </AppLink>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <AppLink
                  component="a"
                  target="_blank"
                  href={`https://www.google.com/maps/place/${address}/@${addressCoordinates}`}
                  id="map-address-link"
                  underline="none"
                  linkStyles={{
                    color: 'system.skyBlue',
                  }}
                >
                  <Typography variant="body1">{address}</Typography>
                </AppLink>
              </Box>
            </Box>
          )}
        </Box>
        <Grid container item xs={12}>
          {!showStartEnd && jobRange}
        </Grid>
      </Grid>
    );
  }, [
    isCardExpanded,
    showMap,
    isTabletMode,
    badgeDetails.name,
    badgeDetails.src,
    badgeDetails.color,
    amountStyles.amountContainer,
    rate,
    isStrike,
    jobDetailsData?.title,
    jobDetailsData?.facility?.name,
    address,
    addressCoordinates,
    showStartEnd,
    jobRange,
  ]);

  const jobDetailsTop = useMemo(() => {
    return (
      <>
        <Grid item id="job-details-top">
          {facilityImage ? (
            <CardMedia
              component="img"
              className="card-img"
              image={facilityImage}
              alt={jobDetailsData?.facility?.name || 'facility-image'}
              sx={{
                width: { xs: '108px', lg: '150px' },
              }}
            />
          ) : (
            <Box
              className="card-img"
              height={132}
              sx={{
                backgroundColor: 'system.lightGrayBg',
                width: { xs: '108px', lg: '150px' },
              }}
            />
          )}
        </Grid>
      </>
    );
  }, [facilityImage]);

  const details = (
    <>
      {jobDetailsData?.requirements && (
        <>
          <Typography id="job-requirements" marginBottom="6px">
            Requirements
          </Typography>
          <Typography
            color="system.coolGray"
            marginBottom="12px"
            dangerouslySetInnerHTML={requirements}
            whiteSpace="pre-line"
          ></Typography>
        </>
      )}
      {!isStrike && jobDetailsData?.benefits && (
        <>
          <Typography id="job-benefits" marginBottom="6px">
            Job Benefits
          </Typography>
          <Typography
            color="system.coolGray"
            marginBottom="12px"
            dangerouslySetInnerHTML={benefits}
            sx={{
              '& .li': { whiteSpace: 'unset' },
            }}
          ></Typography>
        </>
      )}
      {isStrike && (
        <>
          <Typography id="strike-job-benefits" marginBottom="6px">
            Job Benefits
          </Typography>
          <Typography color="system.coolGray" marginBottom="12px">
            Not Included
          </Typography>
        </>
      )}
      {!isStrike && jobDetailsData?.company && (
        <>
          <Typography id="job-about-the-company" marginBottom="6px">
            About the Company
          </Typography>
          <Typography
            color="system.coolGray"
            marginBottom="24px"
            dangerouslySetInnerHTML={company}
            sx={{
              '& .li': { whiteSpace: 'unset' },
            }}
          ></Typography>
        </>
      )}
      {isStrike && (
        <>
          <Typography id="strike-job-about-the-company" marginBottom="6px">
            About the Company
          </Typography>
          <Typography color="system.coolGray" marginBottom="24px">
            AMN Healthcare is a leading strike nursing staffing agency that
            places skilled health professionals in quality positions across the
            United States. We have become an industry leader by providing a
            comprehensive service that ensures health professionals a safe and
            rewarding journey. Our extensive experience gives us the unique
            ability to understand and meet a wide variety of staffing needs.
          </Typography>
        </>
      )}
      {!isStrike && (
        <Typography
          id="job-note"
          variant="body1"
          lineHeight="20px"
          paddingBottom="30px"
          sx={{ fontStyle: 'italic', fontSize: '14px' }}
          color={theme.palette.system.coolGray}
        >
          {
            '*Estimate of weekly payments is intended for informational purposes and includes hourly wages, as well as reimbursements for meal & incidental expenses and housing expenses incurred on behalf of the Company. Please speak with a recruiter for additional details.'
          }
        </Typography>
      )}
    </>
  );

  const jobDetails = (
    <>
      <Grid
        container
        item
        id="job-card-details"
        display="flex"
        flexDirection="row"
        xs={12}
      >
        <Grid container item xs={12}>
          {showStartEnd && jobRange}
        </Grid>
      </Grid>
      <Collapse in={isCardExpanded} sx={{ width: 'inherit' }}>
        <Grid
          height={
            !isTabletMode
              ? `calc(100vh - ${badgeDetails?.name ? '398px' : '347px'})`
              : jobResponseLength > 2
              ? 'calc(100vh - 442px)'
              : 'calc(100vh - 510px)'
          }
        >
          <Divider sx={{ marginBottom: '12px' }} />
          {!isStrike ? (
            <Grid id="cardTrayWrapper" className="card-tray-wrapper">
              <Grid
                xs={6}
                lg={3}
                item
                width={isTabletMode ? '50%' : '25%'}
                justifyContent="flex-start"
              >
                <img height="17px" src={Calendar} alt="" />
                <span className="card-tray">
                  {jobDetailsData?.duration
                    ? `${jobDetailsData.duration} weeks`
                    : 'N/A'}
                </span>
              </Grid>
              <Grid
                xs={6}
                lg={3}
                item
                width={isTabletMode ? '50%' : '25%'}
                justifyContent="flex-start"
              >
                <img height="17px" src={Travel} alt="" />
                <span className="card-tray">
                  {jobDetailsData?.placementType}
                </span>
              </Grid>
              <Grid
                xs={6}
                lg={3}
                item
                width={isTabletMode ? '50%' : '25%'}
                justifyContent="flex-start"
              >
                {getShiftIcon(jobDetailsData?.shifts)}
                <span className="card-tray">
                  {getShiftTimeString(jobDetailsData?.shifts)}
                </span>
              </Grid>
              <Grid
                xs={6}
                lg={3}
                item
                width={isTabletMode ? '50%' : '25%'}
                justifyContent="flex-start"
              >
                <img height="17px" src={Clock} alt="" />
                <span className="card-tray">
                  {jobDetailsData?.shiftType ? jobDetailsData.shiftType : 'N/A'}
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid
              id="cardTrayWrapper"
              className="card-tray-wrapper"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                xs={6}
                md={6}
                lg={4}
                item
                container
                className="card-tray-padding"
                justifyContent="flex-start"
              >
                {getShiftIcon(jobDetailsData?.shifts)}
                <span className="card-tray">Variable</span>
              </Grid>
              <Grid
                xs={6}
                md={6}
                lg={4}
                item
                container
                className="card-tray-padding"
                justifyContent={{ lg: 'center', sm: 'center' }}
              >
                <img height="17px" src={Clock} alt="" />
                <span className="card-tray">Variable</span>
              </Grid>
              <Grid
                xs={6}
                md={6}
                lg={4}
                item
                container
                justifyContent={{
                  lg: 'flex-end',
                  sm: 'flex-start',
                }}
                className="card-tray-padding"
              >
                <img height="17px" src={Travel} alt="" />
                <span className="card-tray">Strike</span>
              </Grid>
            </Grid>
          )}
          <Divider sx={{ marginTop: '12px' }} />
          <Box className="detail-parent">
            {(isStrike ||
              jobDetailsData?.patientRatio ||
              jobDetailsData?.beds !== 0 ||
              jobDetailsData?.description) && (
              <Typography marginBottom="6px" marginTop="24px">
                Job Details
              </Typography>
            )}
            {!isStrike && (
              <div className="job-details">
                {jobDetailsData?.patientRatio !== '' &&
                  jobDetailsData?.patientRatio !== null && (
                    <Typography
                      color="system.coolGray"
                      marginBottom="12px"
                      paddingRight="20px"
                    >
                      <img height="17px" src={Stethescope} alt="" />
                      <span className="card-tray">
                        {`${jobDetailsData?.patientRatio} Patient Ratio`}
                      </span>
                    </Typography>
                  )}
                {jobDetailsData?.beds !== 0 && (
                  <Typography color="system.coolGray" marginBottom="12px">
                    <img height="17px" src={Bed} alt="" />
                    <span className="card-tray card-tray-patient-ratio">
                      {jobDetailsData?.beds} Beds
                    </span>
                  </Typography>
                )}
              </div>
            )}
            <div className="job-details">
              <Typography color="system.coolGray" marginBottom="12px">
                <span>{jobDetailsData?.description}</span>
                {isStrike && (
                  <span
                    style={{
                      display: 'block',
                      paddingTop: jobDetailsData?.description ? '24px' : 0,
                    }}
                  >
                    Pay and dates will be finalized once an offer is extended.
                    Clinicians must be flexible on shift.
                  </span>
                )}
              </Typography>
            </div>
            {details}
          </Box>
        </Grid>
      </Collapse>
    </>
  );

  const jobSideBar = useMemo(
    () => {
      return (
        <Grid container item id="job-sidebar" display="flex" width="100%">
          <Box display="flex" height="100%" width="100%">
            <Grid item width="100%">
              <Box
                sx={{
                  width: {
                    md: '148px',
                    lg: showMap ? 'auto' : '216px',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  height: '100%',
                }}
              >
                <Box width="100%">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: '24px',
                      [theme.breakpoints.down('lg')]: {
                        alignItems: 'flex-end',
                        flexDirection: 'column-reverse',
                        marginBottom: '10px',
                      },
                    }}
                  >
                    <Typography color="system.coolGray" lineHeight="19px">
                      {postedDay}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                      justifyContent="space-between"
                      sx={{
                        width: 'auto',
                        [theme.breakpoints.down('lg')]: {
                          marginBottom: '12px',
                        },
                      }}
                    >
                      {jobDetailsData?.id && (
                        <JobShare
                          jobId={jobDetailsData?.id}
                          shareTitle={`${jobDetailsData?.discipline} - ${jobDetailsData?.specialty}`}
                        />
                      )}
                    </Box>
                  </Box>

                  {!userStatusIsLead && (
                    <Box justifyContent="right" width="100%">
                      <>
                        <CredentialStatus
                          jobId={selectedJobId?.toString() || ''}
                        />
                        {isCardExpanded && (
                          <Questions
                            isStrike={isStrike}
                            jobId={jobDetailsData?.jobId}
                          />
                        )}
                      </>
                    </Box>
                  )}
                </Box>
                <Box
                  width="100%"
                  height="inherit"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Box
                      width="100%"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: '12px',
                      }}
                    >
                      <Typography
                        color="system.coolGray"
                        sx={{
                          font: 'normal normal 400 14px/19px Open Sans',
                        }}
                      >
                        ID:&nbsp;
                      </Typography>

                      <Typography
                        color="system.coolGray"
                        sx={{
                          font: 'normal normal 600 14px/19px Open Sans',
                          fontWeight: '600',
                        }}
                      >
                        {` ${jobDetailsData?.sourceJobId}`}
                      </Typography>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      sx={{
                        p: '12px 0',
                        [theme.breakpoints.up('md')]: {
                          pb: !isCardExpanded ? '0' : '24px',
                        },
                      }}
                    >
                      <ApplyJobCard
                        id="apply-job-card"
                        handleApplyJob={handleApplyJob}
                        setSelectedRecruiters={setSelectedRecruiters}
                        isMFS={jobDetailsData?.maxFilesSent}
                        appliedDate={jobDetailsData?.appliedDate}
                        jobId={(jobDetailsData?.jobId ?? '').toString()}
                        isExpanded={isCardExpanded}
                        isStrike={isStrike}
                        secondaryAction={refreshJob}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      showMap,
      isCardExpanded,
      postedDay,
      userId,
      isStrike,
      isNotStrikeFilter,
      userContact,
      jobDetailsData,
    ],
  );

  return (
    <JobDetailStyle
      showClose={isCardExpanded}
      className={classes}
      showMap={showMap}
      isCardExpanded={isCardExpanded}
      badgeDetails={badgeDetails?.name ? true : false}
      clientHeight={clientHeight}
      jobResponseLength={jobResponseLength}
    >
      <Card className="amn-card" id="job-details-card" onClick={onCardClick}>
        {showClose && (
          <CloseButton onClick={onCloseClick} classes="close-btn" />
        )}
        <Grid container>
          <Grid
            container
            item
            xs={12}
            gap={3}
            sx={{ flexFlow: 'nowrap', justifyContent: 'space-between' }}
          >
            <Grid
              container
              item
              gap="24px"
              xs={9}
              sx={{
                [theme.breakpoints.down('lg')]: {
                  maxWidth: !isCardExpanded
                    ? '301px !important'
                    : '339px !important',
                },
              }}
            >
              <Grid
                container
                item
                xs={12}
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    gap: '24px',
                  },
                  [theme.breakpoints.up('lg')]: {
                    maxWidth: '150px !important',
                  },
                }}
              >
                {jobDetailsTop}
                {isTabletMode && jobDescription}
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    maxWidth: !isCardExpanded
                      ? '409px !important'
                      : showMap
                      ? '387px !important'
                      : '435px !important',
                  },
                }}
              >
                {!isTabletMode && jobDescription}
                {jobDetails}
              </Grid>
            </Grid>
            <Grid container item xs={3}>
              {jobSideBar}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </JobDetailStyle>
  );
};
