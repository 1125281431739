import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { IApplyJobMessageDialogProps } from 'interfaces/Props/IApplyJobMessageDialogProps';
import { ApplyJobStatusMessageContent } from '../Message/ApplyJobStatusMessageContent';

export const ApplyJobStatusMessageMobileDialog = (
  props: IApplyJobMessageDialogProps,
) => {
  const { id, description, message, open, handleCancelClick, sx } = props;

  return (
    <Dialog
      id={id}
      open={open}
      PaperProps={{
        style: {
          width: '327px',
          maxWidth: '100%',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'hidden',
        },
      }}
      sx={{ ...sx }}
    >
      <ApplyJobStatusMessageContent
        id="apply-job-message"
        message={message}
        description={description}
        iconSIze="36px"
        isStrike={props.isStrike}
      />
      <DialogActions sx={{ padding: '10px 0 0 0' }}>
        <Button
          variant="link"
          size="xsmall"
          onClick={handleCancelClick}
          autoFocus
          sx={{
            padding: 0,
            height: 'auto',
          }}
        >
          DISMISS
        </Button>
      </DialogActions>
    </Dialog>
  );
};
