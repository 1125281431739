import React from 'react';
import { Grid, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { AutoSubIndicatorIcon } from 'assets/icons/AutoSubIndicatorIcon';

interface IAutoSubIndicator {
  iconOnly?: boolean;
  margin?: string;
  color?: string;
  iconWidth?: string;
  iconHeight?: string;
}

const AutoSubIndicator: React.FC<IAutoSubIndicator> = props => {
  const {
    iconOnly = false,
    margin = '0px 12px',
    color = theme.palette.system.coolGray,
    iconWidth = '17px',
    iconHeight = '17px',
  } = props;
  return (
    <Grid id="auto-sub-indicator" margin={margin}>
      <AutoSubIndicatorIcon
        color={color}
        width={iconWidth}
        height={iconHeight}
      />
      {!iconOnly && (
        <Typography variant="caption" color={theme.palette.system.coolGray}>
          Auto
        </Typography>
      )}
    </Grid>
  );
};

export default AutoSubIndicator;
