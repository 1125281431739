import React from 'react';
import { createSvgIcon } from '@mui/material';

export const EmployeeHealthIcon = createSvgIcon(
  <svg
    id="Controls-icon-Employee-Health"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2349"
      data-name="Rectangle 2349"
      width="38"
      height="38"
      fill="none"
      opacity="0"
    />
    <g id="Group_2959" data-name="Group 2959" transform="translate(1 4)">
      <g id="Group_2958" data-name="Group 2958" transform="translate(0)">
        <path
          id="Path_3850"
          data-name="Path 3850"
          d="M32.067,46.088a1.282,1.282,0,0,1-.74.232,1.317,1.317,0,0,1-.752-.232,97.492,97.492,0,0,1-11.622-9.006,1.318,1.318,0,0,1-.03-1.858,1.277,1.277,0,0,1,1.833-.032,89.92,89.92,0,0,0,10.57,8.2c5.94-4.181,10.089-7.243,12.445-10.284H34.433l-4.024,4.065a1.278,1.278,0,0,1-.917.391,1.514,1.514,0,0,1-.228-.021,1.285,1.285,0,0,1-.959-.739l-3.388-7.433-1.846,3.009a1.306,1.306,0,0,1-1.1.612H14.034a1.32,1.32,0,0,1,0-2.64h7.2L23.989,25.9a1.285,1.285,0,0,1,2.282.148l3.617,7.929,3.075-3.115a1.3,1.3,0,0,1,.929-.391H45.357a9.241,9.241,0,0,0,.74-4.382c-.324-5.184-3.388-7.8-6.276-8.1-2.512-.253-5.92,1.172-7.244,6.082a1.3,1.3,0,0,1-2.512,0c-1.313-4.91-4.722-6.335-7.233-6.082-2.9.3-5.963,2.914-6.286,8.1-.009.116-.009.222-.009.327a1.329,1.329,0,0,1-1.356,1.278,1.3,1.3,0,0,1-1.25-1.362c0-.137.009-.275.02-.412.418-6.726,4.629-10.147,8.621-10.559a9.057,9.057,0,0,1,8.756,4.889,9.019,9.019,0,0,1,8.754-4.889c3.982.412,8.193,3.833,8.621,10.559C49.192,33.692,43.335,38.179,32.067,46.088Z"
          transform="translate(-12.731 -15.32)"
        />
      </g>
    </g>
  </svg>,
  'EmployeeHealth',
);
