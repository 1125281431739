import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { Grid, IconButton, Typography } from '@mui/material';
import { LicensesIcon } from 'assets/icons/Licenses/LicensesIcon';
import { QRCodeIcon } from 'assets/icons/QR/QRCodeIcon';
import { FileUploadModal } from 'components/common/FileUploadModal/FileUploadModal';
import { MAIN_FLOWS, MAX_FILE_SIZE } from 'constants/fileTypes';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { IUploadedFileState } from 'store/slices/fileUpload/fileUploadState';
import { theme } from 'styles/theme';
import { allowedFileTypes } from 'constants/credentials';
import EditIcon from 'assets/icons/Edit/EditIcon';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { COVIDDrawer } from 'enums/credential';

export const CovidDrawerContent = props => {
  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setDrawerOpen,
    setActionButtonDisabled,
  } = props;
  const dispatch = useAppDispatch();
  const [vaccineFile, setVaccineFile] = useState<IUploadedFileState>();
  const [QRFile, setQRFile] = useState<IUploadedFileState>();

  const submit = useCallback(() => {
    dispatch(
      credentialActions.submitCredentialForApprovalFromDrawerAction(undefined),
    );
    dispatch(fileUploadsActions.clearUploadedFiles());
    dispatch(fileUploadsActions.setUploadedFile(undefined));
    setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDrawerOpen]);

  useEffect(() => {
    if (onSubmitProcess) {
      let files = [] as IUploadedFileState[];
      if (vaccineFile) {
        files.push(vaccineFile);
        localStorage.setItem(
          COVIDDrawer.VaccineCard,
          JSON.stringify(vaccineFile),
        );
      }
      if (QRFile) {
        files.push(QRFile);
        localStorage.setItem(COVIDDrawer.QRCode, JSON.stringify(QRFile));
      }

      dispatch(fileUploadsActions.setUploadedFiles(files));
      submit();
      setOnSubmitProcess?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmitProcess, setOnSubmitProcess, submit]);

  useEffect(() => {
    setActionButtonDisabled(!vaccineFile && !QRFile);
  }, [setActionButtonDisabled, vaccineFile, QRFile]);

  useEffect(() => {
    const vaccineCard = localStorage.getItem(COVIDDrawer.VaccineCard);
    const QRCard = localStorage.getItem(COVIDDrawer.QRCode);
    if (vaccineCard) setVaccineFile(JSON.parse(vaccineCard));
    if (QRCard) setQRFile(JSON.parse(QRCard));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(fileUploadsActions.clearUploadedFiles());
      dispatch(fileUploadsActions.setUploadedFile(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      id="covid-content"
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <Grid container item>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="system.midnightBlue" variant="subtitle1">
            Vaccination Card Image
          </Typography>
          {vaccineFile && (
            <IconButton
              edge="end"
              onClick={() => {
                document
                  .getElementById('file_input-credential-covid-vaccine-record')
                  ?.click();
              }}
              aria-label="Edit vaccine card image"
            >
              {EditIcon(theme.palette.system.skyBlue, '17', '17')}
            </IconButton>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          textAlign="center"
          display={vaccineFile ? 'block' : 'none'}
        >
          {vaccineFile?.fileType !== allowedFileTypes.pdf ? (
            <img
              alt="Vaccination QR code/Digital Vaccine Record*"
              src={vaccineFile?.fileLocalUrl}
              width="400px"
            />
          ) : (
            <embed src={vaccineFile?.fileLocalUrl} width="400px" />
          )}
        </Grid>

        <Grid
          item
          pt={4}
          pb={4}
          xs={12}
          textAlign="center"
          display={!vaccineFile ? 'block' : 'none'}
        >
          <LicensesIcon
            htmlColor={theme.palette.system.gray}
            sx={{ width: '36px !important', height: '36px !important' }}
          />
          <Typography color="system.coolGray" variant="subtitle1" pt={3}>
            <i>You currently have no approved physical vaccine card.</i>
          </Typography>
        </Grid>
        <div
          style={
            !vaccineFile
              ? { visibility: 'visible', width: '100%' }
              : { visibility: 'hidden', width: '100%', display: 'none' }
          }
        >
          <FileUploadModal
            required
            id="credential-covid-vaccine-record"
            label="Add Document"
            customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
            onUploadedFile={data => {
              setVaccineFile(data);
            }}
            acceptedFileTypes={MAIN_FLOWS}
          />
        </div>
      </Grid>
      <Grid
        container
        item
        mt={6}
        pt={4}
        pb={6}
        borderTop={`2px solid ${theme.palette.system.border}`}
        borderBottom={`2px solid ${theme.palette.system.border}`}
      >
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography color="system.midnightBlue" variant="subtitle1">
            Vaccination QR code/Digital Vaccine Record*
          </Typography>
          {QRFile && (
            <IconButton
              edge="end"
              onClick={() => {
                document
                  .getElementById(
                    'file_input-credential-covid-qr-vaccine-record',
                  )
                  ?.click();
              }}
              aria-label="Edit vaccine QR image"
            >
              {EditIcon(theme.palette.system.skyBlue, '17', '17')}
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          textAlign="center"
          display={QRFile ? 'block' : 'none'}
        >
          {QRFile?.fileType !== allowedFileTypes.pdf ? (
            <img
              alt="Vaccination QR code/Digital Vaccine Record*"
              src={QRFile?.fileLocalUrl}
              width="400px"
            />
          ) : (
            <embed src={QRFile?.fileLocalUrl} width="400px" />
          )}
        </Grid>

        <Grid
          item
          pt={4}
          pb={4}
          xs={12}
          textAlign="center"
          display={!QRFile ? 'block' : 'none'}
        >
          <QRCodeIcon
            htmlColor={theme.palette.system.gray}
            sx={{ width: '36px !important', height: '36px !important' }}
          />
          <Typography color="system.coolGray" variant="subtitle1" pt={3}>
            <i>You currently have no approved digital vaccine card.</i>
          </Typography>
        </Grid>
        <div
          style={
            !QRFile
              ? { visibility: 'visible', width: '100%' }
              : { visibility: 'hidden', width: '100%', display: 'none' }
          }
        >
          <FileUploadModal
            required
            id="credential-covid-qr-vaccine-record"
            label="Add Document"
            customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
            onUploadedFile={data => {
              setQRFile(data);
            }}
            acceptedFileTypes={MAIN_FLOWS}
          />
        </div>
      </Grid>
      <Grid container item mt={3} xs={12} flexWrap="nowrap">
        <Grid item>
          <Typography color="system.coolGray" variant="subtitle1">
            *{' '}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="system.coolGray" variant="body1">
            Provide an image of your Vaccination QR Code/Digital Vaccine Record
            from one of the following places:
            <ol style={{ marginTop: '6px' }}>
              <li>State Records;</li>
              <li>Chain Records (CVS, Kroger, Rite Aid, Walgreens, etc.);</li>
              <li>Insurance Records (Aetna, UHC, Blue Cross, etc.)</li>
            </ol>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
