import { Avatar, Card, Grid, Skeleton } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

export default function JobDetailSkeleton() {
  return (
    <>
      <Grid
        id="detail-feature-page-skeleton"
        container
        maxWidth={897}
        maxHeight={1185}
        sx={{ marginBottom: { md: '60px' } }}
      >
        <Grid
          xs={12}
          item
          container
          padding={4}
          sx={{
            display: { xs: 'flex', md: 'none' },
            background: theme.palette.system.white,
            borderBottom: '1px solid',
            borderColor: theme.palette.system.border,
            position: { xs: 'absolute', md: 'flex' },
            top: { xs: 0, md: 'auto' },
            zIndex: 1,
          }}
        >
          <Grid item xs={10}>
            <Skeleton
              variant="rectangular"
              height={21}
              width={252}
              sx={{ borderRadius: '2px' }}
            />
          </Grid>
          <Grid item xs={2} paddingLeft="30px">
            <Skeleton
              variant="rectangular"
              height={24}
              width={24}
              sx={{ borderRadius: '2px' }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: { xs: '72px', md: 0 } }}>
          <Card
            sx={{
              padding: '12px 24px 12px 12px',
            }}
          >
            <Grid container item lg={12}>
              <Grid item xs={3.5} md={3} lg={2.5}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    borderRadius: '10px',
                    mt: '12px',
                    ml: '12px',
                    width: { xs: '80px', md: '120px', lg: '150px' },
                    height: { xs: '93px', md: '190px' },
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs={8.5}
                md={9}
                lg={9.5}
                height="27px"
                sx={{ paddingTop: { xs: 0, md: '12px' } }}
              >
                <Grid container item xs={12}>
                  <Grid item xs={11} lg={8}>
                    <Skeleton
                      variant="rectangular"
                      width={98}
                      height={27}
                      sx={{ borderRadius: '2px' }}
                    />
                  </Grid>
                  <Grid item xs={1} lg={4}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        borderRadius: '2px',
                        width: { xs: '24px', md: '66px', lg: '218px' },
                        height: { xs: '24px', md: '19px', lg: '24px' },
                        paddingLeft: { lg: '9px' },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={8} lg={9}>
                  <Grid item xs={12} paddingTop={2}>
                    <Skeleton
                      variant="rectangular"
                      height={19}
                      sx={{
                        width: { xs: '128px', md: '170px', lg: '195px' },
                        borderRadius: '2px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} paddingTop={2}>
                    <Skeleton
                      variant="rectangular"
                      height={24}
                      sx={{
                        width: { xs: '194px', md: '200px', lg: '298px' },
                        borderRadius: '2px',
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} paddingTop="8px">
                    <Skeleton
                      variant="rectangular"
                      height={19}
                      sx={{
                        width: { xs: 0, md: '170px', lg: '409px' },
                        display: { xs: 'none', md: 'inline-block' },
                        borderRadius: '2px',
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12} paddingTop="17px">
                    <Grid item md={2} lg={9}>
                      <Skeleton
                        variant="rectangular"
                        height={19}
                        sx={{
                          width: { md: '18px', lg: '224px' },
                          borderRadius: '2px',
                        }}
                      />
                    </Grid>
                    <Grid item md={10} lg={3}>
                      <Skeleton
                        variant="rectangular"
                        height={19}
                        sx={{
                          width: { md: '177px', lg: '104px' },
                          borderRadius: '2px',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  sx={{
                    paddingTop: { lg: '29px' },
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs={10.5}
                      md={12}
                      lg={10}
                      sx={{
                        paddingLeft: { lg: '12px' },
                        paddingTop: { lg: '5px' },
                        display: { lg: 'none' },
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={135}
                        height={19}
                        sx={{ borderRadius: '2px' }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      md={12}
                      lg={2}
                      sx={{ paddingLeft: { xs: '52px', lg: 0 } }}
                    >
                      <Skeleton variant="circular" width={30} height={30}>
                        <Avatar />
                      </Skeleton>
                    </Grid>
                    <Grid
                      item
                      xs={10.5}
                      md={12}
                      lg={10}
                      sx={{
                        paddingLeft: { lg: '12px' },
                        paddingTop: { lg: '5px' },
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={135}
                        height={19}
                        sx={{ borderRadius: '2px' }}
                      />
                    </Grid>
                    <Grid item xs={10} md={12}>
                      <Skeleton
                        variant="rectangular"
                        height={19}
                        sx={{
                          width: { md: '135px', lg: '177px' },
                          display: { xs: 'none', md: 'inline-block' },
                          borderRadius: '2px',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{ display: { xs: 'flex', md: 'none' }, paddingTop: '24px' }}
            >
              <Grid item xs={1.5} md={12} lg={2}>
                <Skeleton variant="circular" width={30} height={30}>
                  <Avatar />
                </Skeleton>
              </Grid>
              <Grid item xs={10.5} paddingTop="5px">
                <Skeleton
                  variant="rectangular"
                  width={135}
                  height={19}
                  sx={{ borderRadius: '2px' }}
                />
              </Grid>
              <Grid item xs={10} paddingTop={2}>
                <Skeleton
                  variant="rectangular"
                  width={111}
                  height={19}
                  sx={{
                    borderRadius: '2px',
                    paddingTop: '12px',
                  }}
                />
              </Grid>
              <Grid item xs={10} paddingTop={2}>
                <Skeleton
                  variant="rectangular"
                  width={298}
                  height={24}
                  sx={{
                    borderRadius: '2px',
                  }}
                />
              </Grid>
              <Grid item xs={10} paddingTop={2}>
                <Skeleton
                  variant="rectangular"
                  width={326}
                  height={39}
                  sx={{
                    borderRadius: '2px',
                  }}
                />
              </Grid>
              <Grid item xs={10} paddingTop={2} paddingBottom={4}>
                <Skeleton
                  variant="rectangular"
                  width={326}
                  height={19}
                  sx={{
                    borderRadius: '2px',
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                paddingLeft: { lg: '180px' },
                paddingTop: { md: '8px', lg: '24px' },
              }}
            >
              <Grid
                container
                item
                xs={12}
                md={9}
                sx={{
                  padding: '12px 0',
                  borderTop: '1px solid',
                  borderBottom: '1px solid',
                  borderColor: theme.palette.system.border,
                }}
              >
                <Grid
                  item
                  xs={6}
                  lg={3}
                  sx={{ paddingBottom: { xs: '21px', lg: '0' } }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={84}
                    height={19}
                    sx={{ borderRadius: '2px' }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={3}
                  sx={{ paddingBottom: { xs: '21px', lg: '0' } }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={84}
                    height={19}
                    sx={{ borderRadius: '2px' }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={84}
                    height={19}
                    sx={{ borderRadius: '2px' }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Skeleton
                    variant="rectangular"
                    width={84}
                    height={19}
                    sx={{ borderRadius: '2px' }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={10} paddingTop={4}>
                <Skeleton
                  variant="rectangular"
                  width={90}
                  height={17}
                  sx={{ borderRadius: '2px' }}
                />
              </Grid>
              <Grid item xs={12} md={10} paddingTop={2}>
                <Skeleton
                  variant="rectangular"
                  width={84}
                  height={19}
                  sx={{ borderRadius: '2px' }}
                />
              </Grid>
              <Grid item xs={12} md={10} paddingTop={2}>
                <Skeleton
                  variant="rectangular"
                  width={92}
                  height={19}
                  sx={{ borderRadius: '2px' }}
                />
              </Grid>
              <Grid item xs={12} md={10} paddingTop="14px">
                <Skeleton
                  variant="rectangular"
                  height={119}
                  sx={{
                    width: { md: '370px', lg: '486px' },
                    borderRadius: '2px',
                  }}
                />
              </Grid>
              <Grid container item xs={12} md={9} lg={9} paddingTop={2}>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rectangular"
                    width={98}
                    height={16}
                    sx={{ borderRadius: '2px' }}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: { md: '370px', lg: '486px' },
                      height: { xs: '199px', md: '42px' },
                      borderRadius: '2px',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={3}
                lg={3}
                paddingTop={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sx={{ paddingLeft: { md: '12px', lg: '34px' } }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={111}
                    height={19}
                    sx={{ borderRadius: '2px' }}
                  />
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: { md: '135px', lg: '177px' },
                      height: { xs: '170px', md: '42px' },
                      borderRadius: '2px',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid
          container
          item
          xs={12}
          padding="21px 24px 21px 44px"
          sx={{
            position: 'absolute',
            bottom: 0,
            display: { xs: 'flex', md: 'none' },
            borderTop: '1px solid',
            borderColor: theme.palette.system.border,
            background: theme.palette.system.white,
          }}
        >
          <Grid item xs={5} paddingTop={2}>
            <Skeleton
              variant="rectangular"
              width={98}
              height={23}
              sx={{ borderRadius: '2px' }}
            />
          </Grid>
          <Grid item xs={7}>
            <Skeleton
              variant="rectangular"
              width={166}
              height={42}
              sx={{ borderRadius: '2px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
