import React from 'react';
import { theme } from 'styles/theme';

export default function FilterIcon({
  color = `${theme.palette.system.skyBlue}`,
  height = '17',
  width = '17',
}: {
  color?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      id="Controls-icon-Filter"
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '16'}
      height={height ?? '16'}
      viewBox="0 0 38 38"
    >
      <rect
        id="Rectangle_2313"
        data-name="Rectangle 2313"
        width="38"
        height="38"
        fill="none"
        opacity="0"
      />
      <path
        id="Path_3767"
        data-name="Path 3767"
        d="M34.382,48.68a1.349,1.349,0,0,1-.974-.416L27.33,41.928a1.363,1.363,0,0,1-.377-.942V28.261L13.739,14.994a1.356,1.356,0,0,1,.955-2.314h33.3a1.355,1.355,0,0,1,.955,2.314L35.733,28.261V47.324a1.355,1.355,0,0,1-.846,1.257A1.323,1.323,0,0,1,34.382,48.68Zm-4.728-8.24,3.377,3.52V27.7a1.356,1.356,0,0,1,.4-.959l11.3-11.348H17.954l11.3,11.348a1.358,1.358,0,0,1,.4.959Z"
        transform="translate(-12.343 -11.68)"
        fill={color || `${theme.palette.system.coolGray}`}
      />
    </svg>
  );
}
