import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BadRequestMessages } from 'enums/badRequestMessages';
import { httpSuccess } from 'helpers/serviceHelper';
import { StatusCode } from '../enums/StatusCode';
import store from '../store/configureStore';

const headers: Readonly<Record<string, string | boolean>> = {
  accept: 'application/json',
  'content-type': 'application/json; charset=utf-8',
  'ocp-apim-subscription-key': process.env.REACT_APP_AUTH_API_KEY as string,
  'x-source': 'PassportWeb',
};

const headersInjected = () => {
  const correlationId = store.getState()?.auth?.loginResponse?.correlationId;
  var headersTemp = {
    accept: 'application/json',
    'content-type': 'application/json; charset=utf-8',
    'ocp-apim-subscription-key': process.env.REACT_APP_AUTH_API_KEY as string,
    'x-correlationId': correlationId,
    'x-source': 'PassportWeb',
  };
  return headersTemp;
};

interface IConfigObject extends AxiosRequestConfig {
  additional?: { useRefreshToken?: boolean };
}

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: IConfigObject): AxiosRequestConfig => {
  try {
    const accessToken = store.getState()?.auth?.loginResponse?.accessToken;
    const refreshToken = store.getState()?.auth?.loginResponse?.refreshToken;
    const token = config.additional?.useRefreshToken
      ? refreshToken
      : store.getState()?.auth.accessExpired === false
      ? accessToken
      : refreshToken;

    const correlationId = store.getState()?.auth?.loginResponse?.correlationId;

    if (token !== null && token !== '') {
      config!.headers!.Authorization = `Bearer ${token}`;
      config!.headers!['x-correlationId'] = correlationId;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

class Http {
  private instance: AxiosInstance | null = null;
  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }
  public constructor() {
    this.initHttp();
  }
  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers,
      withCredentials: true,
    });
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );
    http.interceptors.request.use(injectToken, error => Promise.reject(error));
    http.interceptors.response.use(
      response => response,
      error => {
        const { response } = error;
        return this.handleError(response);
      },
    );
    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any>(url: string, config?: IConfigObject): Promise<any> {
    return this.http.get<T>(url, config);
  }

  async getFetch(url: string): Promise<any> {
    try {
      const headers = headersInjected();
      var response = await this.http.get(url, { headers });
      if (response && httpSuccess(response?.status)) {
        return await response.data.blob();
      } else {
        return response;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getFetchFile(url: string): Promise<any> {
    try {
      const headers = headersInjected();
      var response = await fetch(url, {
        method: 'GET',
        headers,
      });
      if (response && httpSuccess(response?.status)) {
        return await response.blob();
      } else {
        return await response;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getWithoutAuthorization(url: string): Promise<any> {
    try {
      const headers = headersInjected();
      var response = await this.http.get(url, { headers });
      if (response && httpSuccess(response?.status)) {
        return await response.data;
      } else {
        return response;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  patch<T = any>(url: string, data?: T, config?: IConfigObject): Promise<any> {
    return this.http.patch<T>(url, data, config);
  }

  post<T = any>(url: string, data?: T, config?: IConfigObject): Promise<any> {
    return this.http.post<T>(url, data, config);
  }

  postUpload<T = any>(url: string, data: any): Promise<any> {
    const formData = new FormData();
    formData.append(data?.name, data, data?.name);
    return this.http.post<T>(url, formData, { headers });
  }

  put<T = any>(url: string, data?: T, config?: IConfigObject): Promise<any> {
    return this.http.put<T>(url, data, config);
  }

  delete<T = any>(url: string, config?: IConfigObject): Promise<any> {
    return this.http.delete<T>(url, config);
  }
  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error) {
    const { status } = error || {};

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      case StatusCode.BadRequest: {
        // Handle BadRequest
        switch (error?.data?.message) {
          case BadRequestMessages.MSSING_X_CORRELATIONID:
            // Missing x-correlationId
            error.data = { ...error.data, overlookError: true };
            break;
        }
        break;
      }
    }
    return Promise.reject(error);
  }
}

export const http = new Http();
