import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Avatar, Box } from '@mui/material';

export default function CredentialStatusSkeleton() {
  return (
    <>
      <Box>
        <Skeleton variant="circular" width={25} height={25}>
          <Avatar />
        </Skeleton>
      </Box>
      <Box>
        <Skeleton
          variant="rectangular"
          width={135}
          height={19}
          sx={{ borderRadius: '2px', paddingLeft: '5px' }}
        />
      </Box>
    </>
  );
}
