import { createAction, createSlice } from '@reduxjs/toolkit';
import { IHomeState } from 'interfaces/Home/IHomeState';

const initialState: IHomeState = {
  banner: {
    title: undefined,
    description: undefined,
  },
  strikeInfo: {
    arrivalDate: undefined,
    arrivalTime: undefined,
    isDriveTravelConfirmFormActive: false,
    startDate: undefined,
    transportationType: undefined,
    placementId: undefined,
  },
};

const getHomePageData = createAction('GET_HOME_PAGE_DATA');

const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    setHomePageData(state, action) {
      state.banner = action.payload.banner;
      state.strikeInfo = action.payload.strikeInfo;
    },
    hideStrikeDateConfirmPopup(state) {
      if (state.strikeInfo) {
        state.strikeInfo.isDriveTravelConfirmFormActive = false;
      }
    },
  },
});

export const homeActions = {
  getHomePageData,
  ...homeSlice.actions,
};

export default homeSlice.reducer;
