import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ExternalLinkDrawer } from 'components/ActionItem/Drawer/ExternalLinkDrawer';
import { AppLink } from 'components/common/Link/Link';
import { AddressType } from 'enums/addressType';
import { ResourceId } from 'enums/resourceId';
import { ILinkConfig } from 'interfaces/Common/ILink';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { ReactHookFormDatePicker } from 'components/common/DatePicker/DatePicker';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { theme } from 'styles/theme';
import moment from 'moment';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { ILegalInformation } from 'interfaces/Props/ILegalInformation';
import { ShowHideAdornment } from 'components/common/Adornments/ShowHideAdornment';

const min_dob = '01/01/1940';

const LegalInformation: React.FC<ILegalInformation> = ({ isSsnActionItem }) => {
  const userProfile = useAppSelector(state => state.userProfile);
  const taxAddress = userProfile.addresses.find(
    add => add.addressType === AddressType.TAXHOME,
  );
  const formattedTaxAddressLine1 = taxAddress
    ? `${taxAddress.address1} ${taxAddress.address2 ?? ''}`
    : '';
  const formattedTaxAddressLine2 = taxAddress
    ? `${taxAddress.city}, ${taxAddress.state} ${taxAddress.zipCode}`
    : '';
  const [showExternalLinkDrawer, setShowExternalLinkDrawer] = useState<
    ILinkConfig | undefined
  >(undefined);
  const [ssn, setSSN] = useState<string>('');
  const [ssnVisible, setSsnVisible] = useState(false);

  const resources = useAppSelector(state => state.resources);

  const onTaxAddressLinkClick = () => {
    if (resources && resources.payrollResources) {
      const links = resources.payrollResources?.find(
        link => link.id === ResourceId.TaxHomeDeclaration,
      )?.links;
      if (links) {
        setShowExternalLinkDrawer(links[0]);
      }
    }
  };

  const handleSsnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedSSN = formatSSN(e.target.value);
    setSSN(formattedSSN);
  };

  const handleVisibilityToggle = useCallback(visible => {
    setSsnVisible(visible);
  }, []);

  const ssnVisibilityAdornment = useMemo(() => {
    return <ShowHideAdornment callback={handleVisibilityToggle} />;
  }, [handleVisibilityToggle]);

  const formatSSN = (value: string): string => {
    // Remove non-numeric characters
    const numericValue = value.replace(/\D/g, '');

    // Add dashes at appropriate positions
    let formattedSSN = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 3 || i === 5) {
        formattedSSN += '-';
      }
      formattedSSN += numericValue[i];
    }

    // Ensure maximum length of 11 characters
    return formattedSSN.slice(0, 11);
  };

  return (
    <Grid container item mt={4} gap={2}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          color="system.midnightBlue"
          lineHeight="24px"
        >
          Legal Information
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReactHookFormDatePicker
          label="Date of Birth"
          name="birthDate"
          disableFuture={false}
          format="MM/DD/YYYY"
          minDate={moment(min_dob)}
          maxDate={moment(new Date())}
          endAdormentIcon={
            <ErrorOutlineOutlinedIcon
              style={{ color: `${theme.palette.system.error}` }}
            ></ErrorOutlineOutlinedIcon>
          }
        />
      </Grid>

      {isSsnActionItem && (
        <Grid item xs={12} py={2}>
          <ReactHookFormTextField
            label="Social Security Number"
            name="socialSecurityNumber"
            type={ssnVisible ? 'text' : 'password'}
            endAdornment={ssnVisibilityAdornment}
            placeholder="000-00-0000"
            value={ssn}
            onChange={handleSsnChange}
          />
        </Grid>
      )}

      {userProfile.isSocialSecurityNumberSubmitted && !isSsnActionItem && (
        <>
          <Grid item xs={12} py={2}>
            <Typography variant="body1" color="system.coolGray" pb={2}>
              Social Security Number
            </Typography>
            <Typography variant="body1" color="system.grayText">
              On Record
            </Typography>
          </Grid>
        </>
      )}

      <Grid container item mb={2}>
        <Grid item xs={12} mb={1}>
          <Typography variant="body1" color="system.coolGray">
            Tax Address
          </Typography>
        </Grid>
        <Grid item xs={12} data-testid="tax-address">
          {taxAddress ? (
            <>
              <Typography variant="body1" color="system.grayText">
                {formattedTaxAddressLine1}
              </Typography>
              <Typography variant="body1" color="system.grayText">
                {formattedTaxAddressLine2}
              </Typography>
            </>
          ) : (
            <AppLink
              id="tax-address-link"
              component="a"
              underline="hover"
              onClick={() => onTaxAddressLinkClick()}
            >
              Please update your tax address
            </AppLink>
          )}
        </Grid>
      </Grid>
      {showExternalLinkDrawer && (
        <ExternalLinkDrawer
          title={showExternalLinkDrawer?.title}
          url={showExternalLinkDrawer?.url ?? ''}
          onClose={() => {
            setShowExternalLinkDrawer(undefined);
          }}
          width="765px"
        />
      )}
    </Grid>
  );
};

export default LegalInformation;
