import React, { useEffect, useState } from 'react';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { IFrameDrawer } from 'components/common/Drawer/IFrameDrawer';
import { getSecureContent } from 'services/domain/domainService';
import { AppCircularProgress } from 'components/common/CircularProgress/CircularProgress';

export interface IExternalLinkDrawer {
  title?: string;
  url: string;
  onClose?: () => void;
  width?: string;
  showFooter?: boolean;
}
export const ExternalLinkDrawer = (props: IExternalLinkDrawer) => {
  const { title, url, onClose } = props;
  const [open, setOpen] = useState(true);
  const [authUrl, setAuthUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDetailsByUrl(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetailsByUrl = async contentUrl => {
    await getSecureContent(contentUrl).then(response => {
      setAuthUrl(response.url);
      setIsLoading(false);
    });
  };

  return (
    <DrawerPanel
      showFooter={props.showFooter ?? true}
      open={open}
      toggleDrawer={(e, open) => {
        if (!open) {
          setOpen(open);
          if (onClose) onClose();
        }
      }}
      innerContainerStyles={{
        width: {
          xs: '100%',
          sm: props.width ? 'width' : '800px !important',
        },
        '& .makeStyles-drawerContent': {
          padding: '0px',
        },
      }}
      contentName={title || 'Form'}
      contentStyles={{ height: '100%' }}
    >
      <>
        {isLoading && <AppCircularProgress />}
        <IFrameDrawer url={authUrl} />
      </>
    </DrawerPanel>
  );
};
