import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/jobs/jobService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { setError } from 'store/slices/error/errorSlice';
import {
  applyJob,
  setApplyJob,
  setLoading,
} from 'store/slices/jobs/ApplyJobsSlice';
import { IApplyJobState } from 'store/slices/jobs/applyJobsState';
import { jobDetailsActions } from 'store/slices/jobs/jobDetailSlice';

export function* applyJobSaga() {
  yield all([watchApplyJobs()]);
}
const versionCheckSelector = (state: RootState) => state.auth.versionCheck;
const userIdSelector = (state: RootState) => state.auth.userId;

export function* postApplyJobSaga(action) {
  let applyJobRequest = action.payload as IApplyJobState;
  try {
    const versionCheck = yield select(versionCheckSelector);
    const userId = yield select(userIdSelector);

    const response = yield call(service.postApplyJob, applyJobRequest.applyJob);
    yield put(jobDetailsActions.setAppliedDateForJob());
    if (applyJobRequest?.isStrike) {
      if (
        response.data &&
        response.data.hasApplied &&
        response.data.placementId
      ) {
        applyJobRequest.hasApplied = response.data.hasApplied;
        // If strike job - we will open up external link drawer in case of success
        applyJobRequest.externalLinkDrawerForStrikeJobApply =
          applyJobRequest?.isStrike ? response.data?.hasApplied : false;
        applyJobRequest.strikeJobSignUpUrl =
          versionCheck?.formstackStrikeSignUpFormUrlV2
            .replace('{userId}', `${userId}`)
            .replace('{placementId}', `${response.data.placementId}`);
        yield put(setApplyJob(applyJobRequest));
      } else {
        yield put(setLoading(false));
      }
    } else {
      applyJobRequest.hasApplied = response.data;
      yield put(setApplyJob(applyJobRequest));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    yield put(setLoading(false));
    yield put(
      openAlert({
        variant: 'error',
        message: error.data.message,
      }),
    );
  }
}

function* watchApplyJobs() {
  yield takeLatest(applyJob.type, postApplyJobSaga);
}
