import React from 'react';
import { createSvgIcon } from '@mui/material';

export const QRCodeIcon = createSvgIcon(
  <svg
    id="Controls-icon-QR-Code"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_3854"
      data-name="Rectangle 3854"
      fill="none"
      opacity="0"
    />
    <g id="Group_8831" data-name="Group 8831" transform="translate(1 1)">
      <path
        id="Rectangle_3853"
        data-name="Rectangle 3853"
        d="M3,3V13H13V3H3M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
      />
      <path
        id="Rectangle_3855"
        data-name="Rectangle 3855"
        d="M3,3V13H13V3H3M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
        transform="translate(20)"
      />
      <path
        id="Rectangle_3856"
        data-name="Rectangle 3856"
        d="M3,3V13H13V3H3M2,0H14a2,2,0,0,1,2,2V14a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
        transform="translate(0 20)"
      />
      <circle
        id="Ellipse_1453"
        data-name="Ellipse 1453"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(6.703 6.703)"
      />
      <circle
        id="Ellipse_1454"
        data-name="Ellipse 1454"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(26.297 6.703)"
      />
      <circle
        id="Ellipse_1455"
        data-name="Ellipse 1455"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(6.703 26.297)"
      />
      <rect
        id="Rectangle_3857"
        data-name="Rectangle 3857"
        width="6"
        height="3"
        rx="1.5"
        transform="translate(23.793 19.594)"
      />
      <rect
        id="Rectangle_3860"
        data-name="Rectangle 3860"
        width="9"
        height="3"
        rx="1.5"
        transform="translate(27 33)"
      />
      <rect
        id="Rectangle_3858"
        data-name="Rectangle 3858"
        width="5"
        height="3"
        rx="1.5"
        transform="translate(22.594 27) rotate(90)"
      />
      <rect
        id="Rectangle_3859"
        data-name="Rectangle 3859"
        width="4"
        height="3"
        rx="1.5"
        transform="translate(31 28.781) rotate(180)"
      />
      <rect
        id="Rectangle_3861"
        data-name="Rectangle 3861"
        width="6"
        height="3"
        rx="1.5"
        transform="translate(33 36) rotate(-90)"
      />
    </g>
  </svg>,
  'QRCodeIcon',
);
