import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import profilePlaceholder from '../../assets/icons/Profile/Controls-icon-Profile.svg';

const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ProfileCardAvatar = styled(Box, {
  shouldForwardProp: prop => prop !== 'image',
})<{ image?: string }>(({ theme, image }) => ({
  width: 128,
  height: 128,
  borderRadius: '50%',
  backgroundImage: image ? `url(${image})` : `url(${profilePlaceholder})`,
  backgroundSize: 'cover',
}));

const IconWrapper = styled('span')(({ theme }) => ({
  transform: 'scale(0.46)',
}));

const FlexSpan = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

const EditIcon = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: `${theme?.palette?.system?.white}`,
  border: `2px solid ${theme?.palette?.system?.skyBlue}`,
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const PhotoInput = styled('input')(({ theme }) => ({
  display: 'none',
}));

export {
  CardContainer,
  ProfileCardAvatar,
  IconWrapper,
  FlexSpan,
  EditIcon,
  PhotoInput,
};
