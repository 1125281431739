import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISimilarJobsData } from './jobsState';

const initialState: ISimilarJobsData = {
  similarJobsData: [],
};

const fetchSimilarJobsAction = createAction<{
  userId: string;
  jobId: number;
}>('FETCH_SIMILAR_JOBS');

const similarJobsSlice = createSlice({
  name: 'similarJobs',
  initialState,
  reducers: {
    setSimilarJobs(state, action: PayloadAction<any>) {
      state.similarJobsData = action.payload;
    },
    resetSimilarJobs(state) {
      state.similarJobsData = initialState.similarJobsData;
    },
  },
});
export const similarJobsActions = {
  fetchSimilarJobsAction,
  ...similarJobsSlice.actions,
};
export default similarJobsSlice.reducer;
