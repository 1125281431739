import React, { useEffect, useRef, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SearchIcon from 'assets/icons/Search/SearchIcon';
import {
  ICountryAndProvince,
  StatesAndProvince,
} from 'interfaces/Lookup/ICountryAndProvince';
import { RootState, useAppDispatch } from 'store/configureStore';
import { fetchState } from 'store/slices/lookups/lookupsSlice';
import useScrollToValidation from 'utils/hooks/useScrollToValidation';
import { ArrowDownSvg } from 'assets/icons/Controls/ArrowDownSvg';

export const ReactHookFormStateField = ({
  name,
  country = 'USA',
  label,
  hideIcon,
  hideDropdown,
}: {
  name: string;
  country?: string;
  label?: string;
  hideIcon?: boolean;
  hideDropdown?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name ?? '');
  const stateVal = watch(name);

  const stateList: ICountryAndProvince[] = useSelector(
    (state: RootState) => state.lookup.stateAndProvince || [],
  );

  const [states, setStates] = useState<StatesAndProvince[]>(
    (stateList || []).find(s => s.countryCode === country)
      ?.statesAndProvinces || [],
  );
  const [currState, setCurrState] = useState<string>('');

  // We manually control the dropdown open/close state
  // to support closing the dropdown when the user fills out the state field via autofill
  const dropdownInput = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const closeDropdown = () => setOpen(false);
  const openDropdown = () => setOpen(true);

  useScrollToValidation({ name });

  const onBlurFxn = () => {
    if (currState !== stateVal) {
      setValue(name, '');
      setCurrState('');
    }
  };

  const onChangeFxn = (event: any, newValue: any) => {
    setValue(name, newValue?.stateAbbreviation || '');
    setCurrState(newValue?.stateAbbreviation || '');
  };

  useEffect(() => {
    if (stateVal && errors[name]) {
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateVal]);

  useEffect(() => {
    if (stateList.length === 0) {
      dispatch(fetchState());
    } else {
      setStates(
        (stateList || []).find(s => s.countryCode === country)
          ?.statesAndProvinces || [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList, country]);

  return (
    <Box sx={{ position: 'relative' }}>
      {!hideIcon && (
        <Box
          sx={{
            position: 'absolute',
            transform: 'translate(15px, 15px)',
            zIndex: 1,
          }}
        >
          <SearchIcon size={24} />
        </Box>
      )}
      <Autocomplete
        autoHighlight
        filterSelectedOptions
        clearOnBlur
        id={`${name}-location-search`}
        sx={{
          '& label': {
            left: hideIcon ? 0 : '40px',
          },
          '& .MuiInputBase-root > input': {
            paddingLeft: hideIcon ? 0 : '52px !important',
          },
          [`& #${name}-location-search`]: {
            paddingLeft: hideIcon ? '' : '46px', // 10px to reach search icon, 24px for icon width, 12px for space from icon
          },
          '& .MuiFilledInput-root': {
            paddingLeft: hideIcon ? '' : '0px', // removes the initial input offset
          },
          [`& #${name}-location-search-label:is([data-shrink="true"])`]: {
            transform: hideIcon ? '' : 'translate(14px, 7px) scale(.75)',
          },
          [`& #${name}-location-search-label`]: {
            transform: hideIcon ? '' : 'translate(14px, 16px) scale(1)',
          },
          '& .MuiAutocomplete-popupIndicator': {
            display: hideDropdown ? 'none' : '',
          },
          '& .MuiSvgIcon-root': {
            marginRight: '10px',
            height: '18px',
            marginTop: '5px',
          },
        }}
        PaperComponent={props => <Paper elevation={3} {...props} />}
        getOptionLabel={option => option.stateAbbreviation}
        filterOptions={createFilterOptions({
          matchFrom: 'any',
          stringify: (option: StatesAndProvince) => option.stateName,
        })}
        popupIcon={<ArrowDownSvg fontSize="small" />}
        options={states}
        onChange={onChangeFxn}
        onBlur={onBlurFxn}
        open={open}
        onOpen={openDropdown}
        onClose={closeDropdown}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option?.stateAbbreviation}
            style={{ padding: '16px 12px' }}
          >
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="subtitle1" color="text.secondary">
                  {option.stateName}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            {...register(name)}
            fullWidth
            label={label ?? 'State'}
            variant="filled"
            error={!!error}
            helperText={error?.message ?? ''}
            inputProps={{
              ...params.inputProps,
              value: stateVal,
            }}
            InputLabelProps={{ shrink: !!stateVal }}
            inputRef={dropdownInput}
            onChange={e => {
              const existingState = states.find(
                state => state.stateAbbreviation === e.target.value,
              );

              if (document.activeElement !== dropdownInput.current) {
                if (!!existingState) {
                  setValue(name, e.target.value);
                  setCurrState(e.target.value);
                }
                closeDropdown();
              }
            }}
          />
        )}
      />
    </Box>
  );
};
