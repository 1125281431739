import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContract } from 'interfaces/Contracts/Contract';
import { IContractState } from './contractState';

const initialState: IContractState = {
  currentContract: {
    agreement: null,
    companyId: 0,
    contractDocument: {
      date: null,
      url: null,
    },
    contractId: "0",
    readyForSigningDate: null,
    signedDate: null,
    status: '',
    statusId: 0,
    type: '',
    typeId: 0,
  },
  contractUrl: '',
  signature: '',
  authorizedSignatureImgUrl: '',
  returningFromSingleContract: false,
  isContractSaveSuccess: false,
  contractSaveInProgress: false,
};

const getContract = createAction('GET_CONTRACT');
const getAuthorizedImgUrl = createAction('GET_AUTHORIZED_IMG_URL');

const contractSlice = createSlice({
  name: 'contractSlice',
  initialState,
  reducers: {
    setAuthSignatureImgUrl(state, action: PayloadAction<string>) {
      state.authorizedSignatureImgUrl = action.payload;
    },
    setContract(state, action: PayloadAction<IContract>) {
      state.currentContract = action.payload;
    },
    setContractUrl(state, action: PayloadAction<string>) {
      state.contractUrl = action.payload;
    },
    resetContractUrl(state) {
      state.contractUrl = '';
    },
    signContract(state, action: PayloadAction<string>) {
      state.signature = action.payload;
    },
    setReturningFromSingleContract(state, action: PayloadAction<boolean>) {
      state.returningFromSingleContract = action.payload;
    },
    getContractDocument(state, action) {},
    setIsContractSaveSuccess(state, action: PayloadAction<boolean>) {
      state.isContractSaveSuccess = action.payload;
    },
    setContractDownloadProgress(state, action: PayloadAction<boolean>) {
      state.contractSaveInProgress = action.payload;
    },
  },
});

export const contractSliceActions = {
  getContract,
  getAuthorizedImgUrl,
  ...contractSlice.actions,
};

export default contractSlice.reducer;
