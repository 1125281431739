import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, CarouselItem } from 'components/common/Carousel/Carousel';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { getFeaturedContent } from 'store/slices/domain/domainSlice';
import { DomainState } from 'store/slices/domain/domainState';
import {
  IFeatureContentSuccessResponse,
  IFeaturedContent,
} from 'types/FeaturedContentResponse';
import FeaturedContentCard from './FeaturedContentCard';
import SkeletonFeaturedContent from './SkeletonFeaturedContent';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { CovidDrawer } from 'components/Credentials/CovidDrawer/CovidDrawer';
import { useAppSelector } from 'store/hooks';
import { INewsFeed } from 'interfaces/Community/INewsFeed';
import { CommunityUpdateType } from 'enums/communityUpdateType';
import { IFeaturedContentProps } from 'interfaces/FeaturedContent/IFeaturedContentProps';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';

/**
 * Featured content carousel.
 */
const FeaturedContent: React.FC<IFeaturedContentProps> = props => {
  const { type } = props;
  // ----- hooks -----
  const domainResponse = useSelector((state: RootState) => {
    return state.domain;
  }) as DomainState;
  const [drawerOpen, setDrawerOpen] = useState<DrawerContentNames>(
    DrawerContentNames.CLOSED_DRAWER,
  );
  const featuredContentResponse =
    domainResponse.featuredContent as IFeatureContentSuccessResponse;
  const isLoading = domainResponse.isLoading as boolean;
  const error = domainResponse.error as IErrorResponse;
  const dispatch = useDispatch();

  const communityNewsFeed = useAppSelector(
    state => ((state as any).community as any).newsFeeds,
  );

  const isMobileDevice = useMobileMediaQuery();

  const feedLimit = 9;

  let mergedFeaturedCommunityContentFeed: IFeaturedContent[] = [];

  if (type && type === CommunityUpdateType.FEATURE_CONTENT) {
    mergedFeaturedCommunityContentFeed = [...featuredContentResponse?.items];
  } else if (type && type === CommunityUpdateType.BLOG_POST) {
    mergedFeaturedCommunityContentFeed = [
      ...communityNewsFeed?.items?.map((feedItem: INewsFeed) => ({
        text: feedItem.name,
        actionUrl: `${window.location.origin}/community/${feedItem?.contentLink?.id}`,
        image: feedItem?.mainImage?.url,
        actionText: '',
        postId: feedItem?.contentLink?.id,
        type: CommunityUpdateType.BLOG_POST,
      })),
    ];
  } else {
    mergedFeaturedCommunityContentFeed = [
      ...featuredContentResponse?.items,
      ...communityNewsFeed?.items?.map((feedItem: INewsFeed) => ({
        text: feedItem.name,
        actionUrl: `${window.location.origin}/community/${feedItem?.contentLink?.id}`,
        image: feedItem?.mainImage?.url,
        actionText: '',
        postId: feedItem?.contentLink?.id,
        type: CommunityUpdateType.BLOG_POST,
      })),
    ];
  }

  // ----- side effects -----
  useEffect(() => {
    const requestPayload = { offset: 0, limit: feedLimit };
    dispatch(getFeaturedContent(requestPayload));
    if (error?.errorMessage) {
      dispatch(
        openAlert({
          variant: 'error',
          message: error.errorMessage,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const openCovidDrawer = () => {
    setDrawerOpen(DrawerContentNames.CREDENTIAL_COVID_VACCINE_RECORD);
  };

  // ----- component render -----
  return (
    <>
      {isLoading ? (
        <SkeletonFeaturedContent />
      ) : (
        <>
          <Carousel
            pagination={
              isMobileDevice
                ? false
                : {
                    clickable: true,
                  }
            }
            breakpoints={{
              375: {
                slidesPerView: 1.25,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              1920: {
                slidesPerView: 3,
              },
            }}
            slidesPerGroup={isMobileDevice ? 1 : 3}
            swiperStyle={{ paddingBottom: isMobileDevice ? '0px' : '36px' }}
          >
            {mergedFeaturedCommunityContentFeed &&
              mergedFeaturedCommunityContentFeed
                .slice(0, feedLimit)
                .map((item: IFeaturedContent, index: number) => (
                  <CarouselItem
                    id={'featured-content-card-' + index}
                    key={index}
                  >
                    <FeaturedContentCard
                      data={
                        type && type === CommunityUpdateType.BLOG_POST
                          ? item
                          : { ...item, text: '' }
                      }
                      onClickCallback={openCovidDrawer}
                    />
                  </CarouselItem>
                ))}
          </Carousel>
          <CovidDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={open => {
              setDrawerOpen(open);
            }}
          />
        </>
      )}
    </>
  );
};

export { FeaturedContent };
