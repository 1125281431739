import { lazyLoad } from 'utils/loadable';

export const CommunityPage = lazyLoad(
  () => import('./CommunityPage'),
  module => module.CommunityPage,
);

export const CommunityBlogPostPage = lazyLoad(
  () => import('./CommunityBlogPost'),
  module => module.CommunityBlogPost,
);
