import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { AppLink } from 'components/common/Link/Link';
import {
  COMPACT_MORE_INFO_NURSES_URL,
  COMPACT_MORE_INFO_PTAS_URL,
} from 'constants/passportUrls';
import { openInNewTab } from 'helpers/openInNewTab';
import { theme } from 'styles/theme';

export const CompactStateFlagDialogContent = () => {
  return (
    <Box
      sx={{
        maxWidth: '568px',
        maxHeight: '331px',
        margin: '8px 24px 20px 0px',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          lineHeight: '20px',
          fontSize: '14px',
          marginBottom: '5px',
          color: 'system.grayText',
        }}
      >
        What are Compact States?
      </Typography>
      <Typography variant="body1" color={theme.palette.system.coolGray}>
        The Nurse Licensure Compact (NLC) and Physical Therapy Compact allow
        healthcare professionals to have one multistate license and practice in
        any participating state. Selecting this option will add all
        participating states to your job search.
      </Typography>
      <br />
      <AppLink
        component="button"
        onClick={() => {
          openInNewTab(COMPACT_MORE_INFO_NURSES_URL);
        }}
        linkStyles={{
          fontWeight: 400,
          lineHeight: '20px',
          fontSize: '14px',
        }}
        id="compact-state-link"
      >
        More Info for Nurses
      </AppLink>
      <br />
      <AppLink
        component="button"
        onClick={() => {
          openInNewTab(COMPACT_MORE_INFO_PTAS_URL);
        }}
        linkStyles={{
          fontWeight: 400,
          lineHeight: '20px',
          fontSize: '14px',
        }}
        id="compact-state-link"
      >
        More Info for PTs and PTAs
      </AppLink>
    </Box>
  );
};
