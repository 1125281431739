import React, { FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';

export interface IQRCodeProps {
  value: string;
  size: number;
}

const AMNQRCode: FC<IQRCodeProps> = ({ value, size }) => {
  return <QRCodeSVG value={value} size={75} />;
};

export default AMNQRCode;
