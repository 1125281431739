import React from 'react';
import { DrawerPanel } from 'components/common/Drawer/Drawer';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { CovidDrawerContent } from './CovidDrawerContent';

export const CovidDrawer = props => {
  return (
    <DrawerPanel
      open={props?.drawerOpen}
      actionButtonText="Submit for Approval"
      cancelButtonText="CANCEL"
      toggleDrawer={(e, open) =>
        open === false && props?.setDrawerOpen(DrawerContentNames.CLOSED_DRAWER)
      }
      contentName="COVID-19 Vaccination Record"
    >
      <CovidDrawerContent setDrawerOpen={props?.setDrawerOpen} />
    </DrawerPanel>
  );
};
