import { Card, Typography, Grid } from '@mui/material';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import React, { useEffect, useMemo } from 'react';
import { RootState } from 'store/configureStore';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createCardListPageStyles } from 'components/common/PageLayout/PageLayout.styles';
import LeadContactIcon from 'assets/icons/Lead/Contact.svg';
import LeadResourceIcon from 'assets/icons/Lead/Resource.svg';
import LeadProfileIcon from 'assets/icons/Lead/Profile.svg';
import { LeadButton } from './LeadButton';
import { theme } from 'styles/theme';
import { LeadPageType } from 'enums/leadPageTypes';
import { useHistory } from 'react-router-dom';
import { MenuIcons } from 'enums/menuIcons';
import mapMenuIconSection from 'utils/mapMenuIconNameSection';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import ProgressTrackerButton from 'components/ProgressTrackerButton/ProgressTrackerButton';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { IUserProfile } from 'interfaces/User/UserProfile/IUserProfile';

export const LeadSplashPage = props => {
  const { children, type } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMobileDevice = useMobileMediaQuery();

  const userStatusIsLead = useAppSelector(
    (state: RootState) =>
      state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );

  const classes = createCardListPageStyles();

  useEffect(() => {
    if (userStatusIsLead) {
      switch (type) {
        case LeadPageType.SKILLS_CHECKLIST:
        case LeadPageType.TIME_AND_PAY:
        case LeadPageType.PROMOTIONS:
          dispatch(setActivePage(MenuIcons.Profile));
          history.push(mapMenuIconSection(MenuIcons.Profile));
          return;
      }
    }
  }, [userStatusIsLead, type, history, dispatch]);

  const splashIcon = useMemo(() => {
    switch (type) {
      case LeadPageType.CONTACTS:
        return (
          <img
            src={LeadContactIcon}
            alt="Contact"
            width={isMobileDevice ? '142px' : '241px'}
            height={isMobileDevice ? '150px' : '250px'}
          />
        );
      case LeadPageType.RESOURCES:
        return (
          <img
            src={LeadResourceIcon}
            alt="Resource"
            width={isMobileDevice ? '142px' : '241px'}
            height={isMobileDevice ? '150px' : '250px'}
          />
        );
      case LeadPageType.PROFILE:
        return (
          <img
            src={LeadProfileIcon}
            alt="Profile"
            width={isMobileDevice ? '142px' : '241px'}
            height={isMobileDevice ? '150px' : '250px'}
          />
        );
    }

    return null;
  }, [type, isMobileDevice]);

  const splashTitle = useMemo(() => {
    switch (type) {
      case LeadPageType.CONTACTS:
        return 'Your Contacts';
      case LeadPageType.RESOURCES:
        return 'Resources';
      case LeadPageType.PROFILE:
        return 'Your Profile';
    }

    return null;
  }, [type]);

  const splashMessage = useMemo(() => {
    switch (type) {
      case LeadPageType.CONTACTS:
        return 'You can access a full list of AMN contacts including your very own recruiter.';
      case LeadPageType.RESOURCES:
        // eslint-disable-next-line quotes
        return 'Download employment forms and resources here.';
      case LeadPageType.PROFILE:
        return 'Here you can manage and update your credentials, set your preferences as well as view time and pay details.';
    }

    return null;
  }, [type]);

  const user: IUserProfile | undefined = useAppSelector(
    (state: RootState) => !!state.userProfile && state.userProfile,
  );

  const isPersonalInformationComplete = React.useMemo(
    () => !!user?.travelerApplicationStatus?.personalInformation,
    [user?.travelerApplicationStatus],
  );

  const isCredentialsAndEducationComplete = React.useMemo(
    () =>
      !!user?.travelerApplicationStatus?.certifications &&
      !!user?.travelerApplicationStatus?.education,
    [user?.travelerApplicationStatus],
  );

  const isWorkHistoryComplete = React.useMemo(
    () => !!user?.travelerApplicationStatus?.employmentHistory,
    [user?.travelerApplicationStatus],
  );

  return (
    <>
      {userStatusIsLead ? (
        <Grid
          container
          spacing={4}
          marginBottom={10}
          className={classes.cardContainer}
          sx={{
            width: '100%',
            placeContent: 'center',
            [theme.breakpoints.down('md')]: {
              paddingBottom: '62px',
              paddingRight: '24px',
            },
          }}
        >
          <Grid item container>
            <Card
              sx={{
                padding: {
                  xs: '89px 24px 36px 24px',
                  sm: '65px 24px 36px 24px',
                },
                display: 'flex',
                width: '894px',
                height: '617px',
                borderRadius: '10px',
                boxShadow: `0px 1px 5px ${theme.palette.system.boxShadowGray}`,
                [theme.breakpoints.down('md')]: {
                  height: '100%',
                  width: '100%',
                },
              }}
            >
              <Grid
                container
                justifyContent="center"
                textAlign="center"
                gap="24px"
                sx={{ width: { xs: '100%', sm: '846px !important' } }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ marginBottom: { xs: '41px', sm: '0px' } }}
                >
                  {splashIcon}
                </Grid>
                <Grid
                  item
                  xs={12}
                  justifySelf="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent={'space-between'}
                  height={'auto'}
                >
                  <Typography
                    variant="h2"
                    color="system.midnightBlue"
                    data-testid="title"
                  >
                    {splashTitle}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="system.grayText"
                    data-testid="message"
                  >
                    {splashMessage}
                  </Typography>
                </Grid>
                <Grid item xs={12} height={'42px'}>
                  <ProgressTrackerButton
                    isSequenceBased={true}
                    hoverEffects={false}
                    isSlantedView={false}
                    contentTextSize={'14px'}
                    barHeight={'38px'}
                    barContentMargin={'9.5px 0 0'}
                    tabBackgroundStyle={{
                      marginBottom: '0px',
                      borderRadius: '6px',
                      backgroundColor: 'transparent',
                      flexDirection: 'column',
                      width: '100%',
                      padding: '2px',
                    }}
                    buttonTabs={[
                      {
                        key: 0,
                        name: isMobileDevice ? '' : 'Personal Information',
                        id: [],
                        message: '',
                        isSelected: isPersonalInformationComplete,
                      },
                      {
                        key: 1,
                        name: isMobileDevice ? '' : 'Credentials & Education',
                        id: [],
                        message: '',
                        isSelected: isCredentialsAndEducationComplete,
                      },
                      {
                        key: 2,
                        name: isMobileDevice ? '' : 'Work History',
                        id: [],
                        message: '',
                        isSelected: isWorkHistoryComplete,
                      },
                    ]}
                    expandedProgressBarWidth={isMobileDevice ? '40%' : '100%'}
                    activeTabId={0}
                  />
                </Grid>
                <Grid xs={12} item display="flex" justifyContent="Center">
                  <LeadButton />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </>
  );
};
