import { ISkillChecklist } from 'interfaces/SkillsChecklists/ISkillChecklist';
import { ISkillsChecklistsDetails } from 'interfaces/SkillsChecklists/ISkillsChecklistsDetails';
import { http } from 'services/BaseApi';

/**
 * Method to get skills checklists data based on user id
 *
 * @param userId - user Id of the logged in user
 */
export const fetchSkillsChecklistsData = async (
  userId: string,
): Promise<ISkillsChecklistsDetails> => {
  return await http.get<string>(`/skills-checklists/${userId}/suggested`);
};

export const fetchSkillsChecklist = async (
  userId: string,
  division: string,
): Promise<ISkillChecklist[]> => {
  return await http.get<string>(
    `/skills-checklists/${userId}?division=${division}`,
  );
};
