/*
 * Constant file for all URLS in passport
 * please continue to add any new URLs here
 */

// Assignments
export const WISELY_PAY_URL = 'https://info.mywisely.com/pay';

// Credentials
export const NURSE_LICENSURE_GUIDE_URL =
  'https://www.ncsbn.org/nurse-licensure-guidance.htm';

// Jobs
export const COMPACT_MORE_INFO_NURSES_URL =
  'https://www.ncsbn.org/compacts.page';
export const COMPACT_MORE_INFO_PTAS_URL = 'https://ptcompact.org/';

// General
export const PRIVACY_POLICY_URL =
  'https://www.amnhealthcare.com/privacy-policy/';
export const COOKIE_POLICY_URL = 'https://www.amnhealthcare.com/cookie-policy/';
export const RIGHTS_PROTECTIONS_URL =
  'https://www.amnhealthcare.com/medical-bills-rights-protections/';
export const LEGAL_URL = 'https://www.amnhealthcare.com/legal/';

export const ADP_URL =
  'https://oneamn.onelogin.com/trust/saml2/http-post/sso/d62e4755-200d-4906-a139-187fd36cc634?RelayState=https%3A%2f%2ffed.adp.com%2fsaml%2ffedlanding.html%3FREDBOX';

export const INSTAGRAM_URL = 'https://www.instagram.com/amnhealthcare/';
export const YOUTUBE_URL = 'https://www.youtube.com/c/amnhealthcare';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/amn-healthcare/';
export const TWITTER_URL = 'https://twitter.com/amnhealthcare/';
export const FACEBOOK_URL = 'https://www.facebook.com/amnhealthcare/';
export const TIKTOK_URL = 'https://www.tiktok.com/@amnhealthcare';

export const PRINT_SHOP_NEAR_ME_URL =
  'https://www.google.com/maps/search/print+shops+near+me/';

export const HEALTHSTREAM_URL =
  'https://oneamn.onelogin.com/client/apps/launch/1737638';

export const BENEFITS_CONTACT_URL = 'http://www.amnhealthcarebenefits.com/';
