import React from 'react';
import { DrawerPanel } from '../Drawer/Drawer';

const Upslider = props => {
  const { open, toggleDrawer, render, sx } = props;

  const toggleUpslider = (
    event: React.KeyboardEvent | React.MouseEvent | null,
    open: boolean,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (!open) {
      toggleDrawer();
    }
  };

  return (
    <DrawerPanel
      anchor="bottom"
      contentName=""
      open={open}
      toggleDrawer={toggleUpslider}
      showFooter={false}
      contentStyles={sx?.['contentStyles']}
      drawerContainerStyles={sx?.['drawerContainerStyles']}
    >
      {render}
    </DrawerPanel>
  );
};

export default Upslider;
