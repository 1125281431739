import React from 'react';
import { createSvgIcon } from '@mui/material';

export const InfoIcon = createSvgIcon(
  <svg
    id="Controls_Icon_Info"
    data-name="Controls/Icon/Info"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="Group_2953" data-name="Group 2953">
      <rect
        id="Rectangle_2346"
        data-name="Rectangle 2346"
        width="24"
        height="24"
        fill="#3ca0f5"
        opacity="0"
      />
      <g
        id="Group_2952"
        data-name="Group 2952"
        transform="translate(0.632 0.632)"
      >
        <path
          id="Path_3846"
          data-name="Path 3846"
          d="M26,37.193A11.369,11.369,0,1,0,14.635,25.824,11.381,11.381,0,0,0,26,37.193ZM26,16.3a9.519,9.519,0,1,1-9.519,9.519A9.529,9.529,0,0,1,26,16.3Z"
          transform="translate(-14.635 -14.455)"
          fill="#3ca0f5"
        />
        <g
          id="Group_2951"
          data-name="Group 2951"
          transform="translate(10.259 5.2)"
        >
          <path
            id="Path_3847"
            data-name="Path 3847"
            d="M29.675,27.2a.925.925,0,0,0-.925.925v6.521a.925.925,0,1,0,1.85,0V28.13A.925.925,0,0,0,29.675,27.2Z"
            transform="translate(-28.565 -22.971)"
            fill="#3ca0f5"
          />
          <circle
            id="Ellipse_606"
            data-name="Ellipse 606"
            cx="1.11"
            cy="1.11"
            r="1.11"
            fill="#3ca0f5"
          />
        </g>
      </g>
    </g>
  </svg>,
  'InfoIcon',
);
