import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import ArrowRightIcon from 'assets/icons/Controls/ArrowRight';
import { ExternalLinkDrawer } from 'components/ActionItem/Drawer/ExternalLinkDrawer';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { AppLink } from 'components/common/Link/Link';
import { TOAST_MESSAGE } from 'constants/helperText';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { FeatureToggles } from 'enums/featureToggles';
import { isSafariOrFirefox, openInCurrentTab } from 'helpers/openInNewTab';
import { formatDateRange } from 'pages/Assignments/assignmentHelpers';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getSecureContent } from 'services/domain/domainService';
import { useAppSelector } from 'store/hooks';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { setSelectedJob } from 'store/slices/jobs/jobsSlice';
import { theme } from 'styles/theme';
import useQueryParams from 'utils/hooks/useQueryParams';

const OngoingStrikeUpdate = props => {
  const {
    onSubmitProcess,
    setOnSubmitProcess,
    setActionButtonDisabled,
    setDrawerOpen,
  } = props;
  const dispatch = useDispatch();
  const query = useQueryParams();

  const userId = useAppSelector(state => state.userProfile.userId);
  const isACSJobSearch = useFeatureToggle(FeatureToggles.ENABLE_ACS_JOB_SEARCH);

  const [jobId, setJobId] = React.useState('');
  const [confirm, setConfirm] = React.useState<boolean | null>(null);
  const [navigation, setNavigation] = React.useState(false);
  const [authUrl, setAuthUrl] = React.useState('');
  const [applyJobDialodOpen, setApplyJobDialogOpen] = React.useState(false);
  const [showExternalLinkDrawer, setShowExternalLinkDrawer] =
    React.useState(false);

  //=============== values from redux =================//
  const selectedItemValRedux = useAppSelector(
    state => state.actionItem.selectedClearanceItem,
  );

  const assignmentDataRedux = useAppSelector(
    state => state.assignmentSummary.activeCardDetails.items[0],
  );
  //====================================================//

  //=============== Memoised values ====================//
  const assignmentDataValue = React.useMemo(
    () => !!assignmentDataRedux && assignmentDataRedux,
    [assignmentDataRedux],
  );

  const selectedItemVal = React.useMemo(
    () => !!selectedItemValRedux && selectedItemValRedux,
    [selectedItemValRedux],
  );
  //=====================================================//

  //========= Getting assignment details from placementId ================//
  React.useEffect(() => {
    if (userId && selectedItemVal && selectedItemVal.placementId) {
      dispatch(
        assignmentActions.getAssignmentDetailsAction(
          userId,
          selectedItemVal.placementId,
        ),
      );
      if (
        selectedItemVal &&
        selectedItemVal.links &&
        selectedItemVal.links.length > 0
      ) {
        let linkUrl = selectedItemVal.links[0]?.url;
        setJobId(linkUrl ? (linkUrl.match(/job_id=([^&]+)/) || [])[1] : '');
      }
    }
  }, [dispatch, selectedItemVal, userId]);
  //======================================================================//

  // ========= Getting upcoming wave start date ============//
  const findNearestUpcomingWave = (waves, currentDate) => {
    return waves.reduce((nearestWave, obj) => {
      const startDateString = obj?.startDate;
      if (startDateString) {
        const startDate = new Date(startDateString);
        startDate.setUTCHours(0, 0, 0, 0);

        if (
          startDate > currentDate &&
          (nearestWave === null || startDate < nearestWave.startDate)
        ) {
          return { startDate, endDate: new Date(obj.endDate) };
        }
      }
      return nearestWave;
    }, null);
  };

  const getUpcomingWaveDateRange = () => {
    const waves = assignmentDataValue?.strike?.waves || [];
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);

    const nearestWave = findNearestUpcomingWave(waves, currentDate);

    if (nearestWave !== null && nearestWave.startDate && nearestWave.endDate) {
      return formatDateRange(nearestWave.startDate, nearestWave.endDate);
    }

    return null;
  };
  //========================================================//

  const signUpUrl = React.useMemo(() => {
    if (selectedItemVal) {
      if (selectedItemVal.links && selectedItemVal.links.length > 0) {
        return selectedItemVal.links[0]?.url;
      }
    }
    return null;
  }, [selectedItemVal]);

  const getDetailsByUrl = React.useCallback(
    async contentUrl => {
      await getSecureContent(
        contentUrl +
          `&redirectUrl=${window.location.origin}/jobs?` +
          encodeURIComponent(`jobId=${jobId}`),
      ).then(response => {
        setAuthUrl(response.url);
      });
    },
    [jobId],
  );

  const onSubmitClick = React.useCallback(() => {
    if (!isSafariOrFirefox) {
      setShowExternalLinkDrawer(true);
      setConfirm(null);
    }
  }, [setShowExternalLinkDrawer, setConfirm]);

  const secondaryAction = React.useCallback(() => {
    dispatch(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.FormSubmitted,
      }),
    );
    setDrawerOpen(DrawerContentNames.CLOSED_DRAWER);
  }, [dispatch, setDrawerOpen]);

  const dismissActionItem = React.useCallback(
    id => {
      let dismissals: any = { dismissals: [id] };
      dispatch(actionItemActions.removeActionItem({ dismissals: dismissals }));
    },
    [dispatch],
  );

  const submit = React.useCallback(() => {
    if (confirm) {
      onSubmitClick();
    } else {
      if (selectedItemVal && selectedItemVal.id) {
        dismissActionItem(selectedItemVal.id);
        secondaryAction && secondaryAction();
      }
    }
  }, [
    confirm,
    dismissActionItem,
    onSubmitClick,
    secondaryAction,
    selectedItemVal,
  ]);

  React.useEffect(() => {
    if (navigation) getDetailsByUrl(signUpUrl);
  }, [navigation, signUpUrl, getDetailsByUrl]);

  React.useEffect(() => {
    if (!!authUrl) openInCurrentTab(authUrl);
  }, [authUrl]);

  React.useEffect(() => {
    if (onSubmitProcess) {
      submit();
      setOnSubmitProcess?.(false);
    }
  }, [onSubmitProcess, setOnSubmitProcess, submit]);

  React.useEffect(() => {
    setActionButtonDisabled(confirm === null);
  }, [confirm, setActionButtonDisabled, showExternalLinkDrawer]);

  React.useEffect(() => {
    //This is formstack message event handler
    const formStackMessegeHandler = event => {
      if (event?.data === 'fs-form-submit') {
        setTimeout(() => {
          setShowExternalLinkDrawer(false);
          secondaryAction && secondaryAction();
        }, 3000);
      }
    };
    window.addEventListener('message', formStackMessegeHandler);
    return () => {
      window.removeEventListener('message', formStackMessegeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Strike formStack for firefox/safari
  React.useEffect(() => {
    if (isSafariOrFirefox) {
      const strikeFormStackJobId = query.get('jobId');
      if (!!strikeFormStackJobId) {
        setNavigation(false);
        secondaryAction && secondaryAction();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafariOrFirefox]);

  const onStrikeDetailsLinkClick = () => {
    if (jobId) {
      isACSJobSearch
        ? dispatch(cognitiveJobActions.getJobDetailsAction(Number(jobId)))
        : dispatch(setSelectedJob(Number(jobId)));
      if (setDrawerOpen) {
        setDrawerOpen(DrawerContentNames.SHOW_JOB_DETAILS);
      } else {
        dispatch(
          assignmentActions.setDrawerState(DrawerContentNames.SHOW_JOB_DETAILS),
        );
      }
    }
  };

  return (
    <Grid>
      {showExternalLinkDrawer || applyJobDialodOpen ? (
        <ExternalLinkDrawer
          showFooter={false}
          width="100%"
          title={'Strike Confirmation'}
          url={signUpUrl || ''}
          onClose={() => {
            setShowExternalLinkDrawer(false);
            setApplyJobDialogOpen(false);
          }}
        />
      ) : (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0px 12px',
          }}
        >
          <Grid container maxWidth={'550px'}>
            <Grid item xs={12}>
              <Typography color="system.midnightBlue" variant="subtitle1">
                Ongoing Strike
              </Typography>
            </Grid>
            <Grid item pt={2} pb={4} xs={12}>
              <Typography color="system.grayText" variant="body1">
                There is a strike ongoing and they are looking for people like
                you to work an upcoming wave.
              </Typography>
              <Grid
                display="flex"
                width="100%"
                justifyContent="space-between"
                pt={4}
              >
                <Grid item xs={6} pb={2}>
                  <Typography
                    variant="body1"
                    color={theme.palette.system.darkGray}
                  >
                    Hourly Rate
                  </Typography>
                  <Typography
                    variant="body1"
                    lineHeight="20px"
                    color={theme.palette.system.coolGray}
                    mt={1}
                    width="108px"
                  >
                    {assignmentDataValue?.hourlyRate
                      ? `$${assignmentDataValue?.hourlyRate}/Hour*`
                      : ''}
                  </Typography>
                </Grid>

                <Grid item xs={6} pb={2}>
                  <Typography
                    variant="body1"
                    color={theme.palette.system.darkGray}
                  >
                    Dates
                  </Typography>
                  <Typography
                    variant="body1"
                    lineHeight="20px"
                    color={theme.palette.system.coolGray}
                    mt={1}
                  >
                    {getUpcomingWaveDateRange()}
                  </Typography>
                </Grid>
              </Grid>
              {assignmentDataValue?.facilityName && (
                <Grid item pb={2} pt={4}>
                  <Typography
                    variant="body1"
                    color={theme.palette.system.darkGray}
                  >
                    Strike Event
                  </Typography>
                  <Typography
                    variant="body1"
                    lineHeight="20px"
                    color={theme.palette.system.coolGray}
                    mt={1}
                  >
                    {assignmentDataValue.facilityName}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid
              item
              xs={true}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                minHeight: '23px',
              }}
              pb={4}
            >
              <AppLink
                component="button"
                id="ongoing-strike-link"
                onClick={onStrikeDetailsLinkClick}
                linkStyles={{
                  fontSize: '14px',
                }}
              >
                View Strike Details
              </AppLink>
              <Grid item margin={'0px 5px'}>
                {ArrowRightIcon({
                  color: theme.palette.system.skyBlue,
                  height: '15',
                  width: '15',
                })}
              </Grid>
            </Grid>

            <Divider
              variant="fullWidth"
              sx={{
                mt: 4,
                mb: 4,
                width: '100%',
              }}
            />

            <Grid item xs={12} pt={4}>
              <Typography color="system.midnightBlue" variant="subtitle1">
                Apply for this Strike?
              </Typography>
            </Grid>
            <Grid container xs={12} pt={2} display={'flex'}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="consecutive-wave-extension"
                  name="consecutiveWaveExtension"
                  onChange={e => setConfirm(e.target.value === 'true')}
                  sx={{
                    margin: '0px 10px',
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="secondary" />}
                    label="Apply"
                    sx={{
                      color: 'system.midnightBlue',
                      fontSize: 20,
                      '& .MuiTypography-root': {
                        fontSize: '16px',
                      },
                    }}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="secondary" />}
                    label="No, Thank You"
                    sx={{
                      color: 'system.midnightBlue',
                      fontSize: 20,
                      '& .MuiTypography-root': {
                        fontSize: '16px',
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item pt={3}>
            <Typography
              variant="body1"
              color="system.coolGray"
              lineHeight="20px"
            >
              <b>PLEASE NOTE: </b>
              {confirm === true || confirm === null
                ? 'You must be committed for the full duration to apply for this event. We are unable to accept time off for this assignment and need full flexibility of your schedule.'
                : 'We will contact you if additional positions become available.'}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { OngoingStrikeUpdate };
