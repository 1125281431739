import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { ICredentialChecklist } from 'interfaces/Credential/ICredentialChecklist';
import {
  ICredentialDetails,
  ICredentialItemToRemove,
  ICredentialSubmissionResponse,
} from 'interfaces/Credential/ICredentialDetails';
import {
  IDocumentUpload,
  IUploadedFileState,
} from '../fileUpload/fileUploadState';
import {
  IAssignmentCredentialSubmission,
  ICredentialChecklistRequest,
  ICredentialChecklistState,
  ICredentialState,
  ICredentialStatus,
  IDocumentUploadResponse,
  ILicenseVerificationRequest,
} from './credentialState';

const initialState: ICredentialState = {
  credentials: [],
  openRequirements: [],
  isLoading: true,
  selectedChecklist: undefined,
  credentialsChecklist: {
    list: [],
    facilityName: '',
    credentialCloseDate: '',
    helpContact: undefined,
  },
  isOpenModal: false,
  submissions: {
    items: [],
    paging: { limit: '20', offset: '0', total: '0' },
  },
  isDocDataLoading: true,
  docuSignEnvelopeUrl: {},
  formStackData: { url: '' },
  openRequirementItemToRemove: [],
  openRequirementsCopy: [],
  skillsChecklistUrl: '',
  checklistItemsCopy: [],
  documentUploadResponse: undefined,
  assignmentCredentialSubmissions: [],
  selectedActionMenuItem: undefined,
  actionItemDrawer: DrawerContentNames.CLOSED_DRAWER,
  taskCredentialActionDrawer: DrawerContentNames.CLOSED_DRAWER,
  licenseTypeAndDisciplineId: { licenseType: null, disciplineId: null },
};

const getCredentialDetailAction = createAction<boolean>(
  'GET_CREDENTIAL_DETAIL',
);
const submitCredentialForApprovalAction =
  createAction<ICredentialChecklistRequest>('SUBMIT_CRED_FOR_APPROVAL');
const submitIdentificationAction = createAction<any>(
  'SUBMIT_OPEN_REQUIREMENTS_ITEM',
);
const submitCredentialForApprovalFromDrawerAction = createAction<any>(
  'SUBMIT_CRED_FOR_APPROVAL_FROM_DRAWER',
);
const deleteCredentialAction =
  createAction<IUploadedFileState>('DELETE_CREDENTIAL');
const getFormStackDataAction = createAction<string>('GET_CREDENTIAL_FORMSTACK');
const setCredentialStatusAction = createAction<ICredentialStatus>(
  'GET_CREDENTIAL_STATUS',
);
const getCredentialChecklistFromCredCenterData = createAction<any>(
  'GET_CREDENTIAL_CHECKLIST_FROM_CRED_CENTER',
);
const getPdfContentAction = createAction<string>('GET_PDF_CONTENT');
const submitLicenseForApprovalAction =
  createAction<ILicenseVerificationRequest>('SUBMIT_LICENSE_FOR_APPROVAL');
const submitCredentialDocumentAction = createAction<IDocumentUpload>(
  'SUBMIT_CREDENTIAL_DOCUMENT',
);

const credentialSlice = createSlice({
  name: 'credentialSlice',
  initialState,
  reducers: {
    setSelectedChecklistItem(
      state,
      action: PayloadAction<ICredentialChecklist | undefined>,
    ) {
      state.selectedChecklist = action.payload;
    },
    setCredentialDetail(state, action: PayloadAction<ICredentialDetails[]>) {
      state.credentials = action.payload;
    },
    getCredentialChecklist(state, action: PayloadAction<any>) {},
    setCredentialChecklist(
      state,
      action: PayloadAction<ICredentialChecklistState>,
    ) {
      state.credentialsChecklist = {
        ...action.payload,
      };
    },
    setLoading(state, { payload = false }: PayloadAction<boolean | undefined>) {
      state.isLoading = payload;
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      return {
        ...state,
        isLoading: true,
        error: {
          errorCode: action.payload.errorCode,
          errorData: action.payload.errorData,
          errorDetails: action.payload.errorDetails,
          errorMessage: action.payload.errorMessage,
          operationId: action.payload.operationId,
          correlationId: action.payload.correlationId,
        },
      };
    },
    resetCredentialChecklist(state) {
      return {
        ...state,
        credentialsChecklist: { ...initialState.credentialsChecklist },
      };
    },
    reset() {
      return { ...initialState };
    },
    setModalState(state, action: PayloadAction<boolean>) {
      state.isOpenModal = action.payload;
    },
    getCredentialDetailDoc(state, action) {
      state.isDocDataLoading = true;
    },
    setCredentialDetailDoc(state, action) {
      state.isDocDataLoading = false;
      state.docData = action.payload;
    },
    resetCredentialDetailDoc(state) {
      state.isDocDataLoading = false;
      state.docData = undefined;
    },
    getCredentialDocuments(state, action) {},
    setCredentialDocuments(state, action) {
      state.docData = action.payload;
    },
    getSubmissions(state) {},
    loadMoreSubmissions(state, action) {},
    setSubmissions(
      state,
      action: PayloadAction<ICredentialSubmissionResponse>,
    ) {
      state.submissions.items = action.payload?.items;
      state.submissions.paging = action.payload.paging;
    },
    setOpenRequirements(state, action) {
      state.openRequirements = action.payload;
    },
    setDocEnvUrl(state, action) {
      state.docuSignEnvelopeUrl = action.payload;
    },
    getDocEnvUrl(state, action) {},
    setFormStackData(state, action) {
      state.formStackData = action.payload;
    },
    removeOpenRequirementItem(
      state,
      action: PayloadAction<ICredentialItemToRemove>,
    ) {
      if (action.payload.isChecklistItem) {
        state.checklistItemsCopy = state.credentialsChecklist?.list;
        state.openRequirementItemToRemove = [
          state.credentialsChecklist?.list?.find(
            item => item.id === action.payload.credentialId,
          ),
        ];
        let filteredChecklistItems = state.credentialsChecklist?.list?.filter(
          item => item.id !== action.payload.credentialId,
        );
        state.credentialsChecklist = {
          ...state.credentialsChecklist,
          list: filteredChecklistItems,
        };
      } else {
        state.openRequirementsCopy = state.openRequirements;
        state.openRequirementItemToRemove = [
          state.openRequirements.find(
            item =>
              +item?.assignmentCredential?.id === action.payload.credentialId ||
              +item?.actionItem?.id === action.payload.credentialId ||
              +item?.survey?.id === action.payload.credentialId ||
              +item?.userCredential?.id === action.payload.credentialId,
          ),
        ];
        state.openRequirements = state.openRequirements.filter(
          item =>
            !(
              +item?.assignmentCredential?.id === action.payload.credentialId ||
              +item?.actionItem?.id === action.payload.credentialId ||
              +item?.survey?.id === action.payload.credentialId ||
              +item?.userCredential?.id === action.payload.credentialId
            ),
        );
      }
    },
    restoreOpenRequirementItem(state) {
      state.openRequirements = state.openRequirementsCopy;
      state.openRequirementItemToRemove = [];
      state.credentialsChecklist = {
        ...state.credentialsChecklist,
        list: state.checklistItemsCopy,
      };
    },
    resetOpenRequirementsItemToRemove(state) {
      state.openRequirementItemToRemove = [];
    },
    setSkillsChecklistUrl(state, action) {
      state.skillsChecklistUrl = action.payload;
    },
    setDocumentUploadResponse(
      state,
      action: PayloadAction<IDocumentUploadResponse>,
    ) {
      state.documentUploadResponse = action.payload;
    },
    resetDocumentUploadResponse(state) {
      state.documentUploadResponse = undefined;
    },
    getAssignmentCredentialSubmission(state) {},
    setAssignmentCredentialSubmission(
      state,
      action: PayloadAction<IAssignmentCredentialSubmission[]>,
    ) {
      state.assignmentCredentialSubmissions = action.payload;
    },
    setSelectedActionMenuItem(state, action) {
      state.selectedActionMenuItem = action.payload;
    },
    setActionItemDrawer(state, action) {
      state.actionItemDrawer = action.payload;
    },
    setTaskCredentialActionDrawer(state, action) {
      state.taskCredentialActionDrawer = action.payload;
    },
    setLicenseTypeAndDiscipline(state, action) {
      state.licenseTypeAndDisciplineId = action.payload;
    },
    setContractDetailsId(state, action) {
      state.contractDetailsId = action.payload;
    },
  },
});

export const credentialActions = {
  ...credentialSlice.actions,
  getCredentialDetailAction,
  submitCredentialForApprovalAction,
  submitCredentialForApprovalFromDrawerAction,
  deleteCredentialAction,
  getFormStackDataAction,
  setCredentialStatusAction,
  getCredentialChecklistFromCredCenterData,
  getPdfContentAction,
  submitLicenseForApprovalAction,
  submitCredentialDocumentAction,
  submitIdentificationAction,
};
export default credentialSlice.reducer;
