import { makeStyles } from '@mui/styles';

const alertbarStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints?.up('md')]: {
      padding: '0px 10px 0px 10px',
    },
    [theme.breakpoints?.down('md')]: {
      padding: '0px 5px 0px 5px',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    borderRadius: '6px',
  },
  success: {
    border: `1px solid ${theme.palette?.system?.alertSuccessBorder}`,
    color: theme.palette?.system?.alertSuccessColor,
    backgroundColor: theme.palette?.system?.alertSuccessBackground,
    '&:hover': {
      backgroundColor: theme.palette?.system?.alertSuccessBackgroundHover,
    },
  },
  error: {
    border: `1px solid ${theme.palette?.system?.alertErrorBorder}`,
    color: theme.palette?.system?.alertErrorColor,
    backgroundColor: theme.palette?.system?.alertErrorBackground,
    '&:hover': {
      backgroundColor: theme.palette?.system?.alertErrorBackgroundHover,
    },
  },
  info: {
    border: `1px solid ${theme.palette?.system?.alertInfoBorder}`,
    color: theme.palette?.system?.alertInfoColor,
    backgroundColor: theme.palette?.system?.alertInfoBackground,
    '&:hover': {
      backgroundColor: theme.palette?.system?.alertInfoBackgroundHover,
    },
  },
  warning: {
    border: `1px solid ${theme.palette?.system?.alertWarningBorder}`,
    color: theme.palette?.system?.alertWarningColor,
    backgroundColor: theme.palette?.system?.alertWarningBackground,
    '&:hover': {
      backgroundColor: theme.palette?.system?.alertWarningBackgroundHover,
    },
  },
  rejected: {
    border: `1px solid ${theme.palette?.system?.error}`,
    color: theme.palette?.system?.error,
    backgroundColor: theme.palette?.system?.white,
  },
  icon: {
    width: '24px !important',
  },
  message: {
    paddingLeft: '12px',
  },
}));

export { alertbarStyles };
