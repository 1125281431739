import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { ArrowRightIcon } from 'assets/icons/Arrows/Arrow-Right';
import CloseIcon from 'assets/icons/Close/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export const SearchBar = props => {
  const {
    items,
    itemKey,
    onResultsChange,
    onFocus,
    recentItems,
    disableRecentItems,
    onClick,
    searchBarSx,
    isMobile,
    placeholder = 'Search',
    startAdornment,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [, setSearchResults] = useState([]);
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    const results = items?.filter(item =>
      item[itemKey]?.toLowerCase?.().includes(searchTerm?.toLowerCase?.()),
    );
    setSearchResults(results);
    onResultsChange?.(results, searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, itemKey, onResultsChange, items]);

  const onClear = useCallback(() => {
    setSearchTerm('');
    setSearchResults([]);
    onResultsChange?.([]);
  }, [onResultsChange]);

  const onFocusCallback = useCallback(() => {
    setFocus(true);
    onFocus?.();
  }, [onFocus]);

  const onBlurCallback = useCallback(() => {
    setTimeout(() => setFocus(false), 250);
  }, []);

  return (
    <>
      <TextField
        id="search-field"
        sx={{
          width: '100%',
          background: theme.palette.system.white,
          mb: '24px',
          ...searchBarSx,
        }}
        placeholder={placeholder}
        value={searchTerm}
        onChange={event => setSearchTerm(event.target.value)}
        onFocus={onFocusCallback}
        onBlur={onBlurCallback}
        onClick={onClick}
        inputProps={{
          sx: { height: '36px', padding: 0 },
        }}
        InputProps={{
          startAdornment: startAdornment ?? (
            <SearchIcon
              sx={{
                color: theme => theme.palette.grey[500],
                marginRight: '12px',
              }}
            />
          ),
          endAdornment: searchTerm?.trim?.() ? (
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClear}
              sx={{
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon width="12px" height="12px" />
            </IconButton>
          ) : (
            <></>
          ),
        }}
      />
      {(focus || (isMobile && !searchTerm?.trim?.())) &&
        !!recentItems?.length &&
        !disableRecentItems && (
          <Grid
            container
            position="absolute"
            sx={{
              background: theme.palette.system.white,
              boxShadow: isMobile
                ? ''
                : `0px 1px 5px ${theme.palette.system.boxShadowGray}`,
            }}
            mt={1}
            maxWidth="327px"
            zIndex="1"
          >
            <Grid item pt={3} pl={isMobile ? 0 : 2} pb={isMobile ? 0 : 2}>
              Recent Searches
            </Grid>
            {recentItems?.map(item => {
              return (
                <Grid
                  item
                  key={item?.id || item?.question}
                  display="flex"
                  flexDirection="row"
                  gap="15px"
                  width="inherit"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      background: theme.palette.system.backgroundGray,
                    },
                    padding: '13px',
                    paddingLeft: isMobile ? '0px' : '13px',
                    borderBottom: isMobile
                      ? `1px solid ${theme.palette.system.border}`
                      : 'unset',
                  }}
                  onClick={item?.onClick}
                >
                  <Box>{item?.icon || ''}</Box>
                  <Box
                    display="block"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    width="inherit"
                    color={theme.palette.system.midnightBlue}
                    whiteSpace="nowrap"
                  >
                    {item[itemKey] || ''}
                  </Box>
                  {isMobile && !item.disableEndAdornment && (
                    <ArrowRightIcon
                      htmlColor={theme.palette.system.coolGray}
                      sx={{ width: '16px', height: '16px' }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
    </>
  );
};
SearchBar.defaultProps = {
  onFocus: () => {},
};
export default SearchBar;
