import React from 'react';
import { createSvgIcon } from '@mui/material';

export const DocIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 60">
    <g id="doc_icon" data-name="doc icon" transform="translate(15069 13102)">
      <path
        id="Path_7598"
        data-name="Path 7598"
        d="M49.5,78.834H4.5a4.505,4.505,0,0,1-4.5-4.5v-51a4.5,4.5,0,0,1,4.5-4.5H36.557L54,36.278V74.334A4.505,4.505,0,0,1,49.5,78.834Z"
        transform="translate(-15069 -13120.834)"
        fill="#0098db"
      />
      <path
        id="Path_7597"
        data-name="Path 7597"
        d="M18.29,30.31H5.54a4.5,4.5,0,0,1-4.5-4.5V13.061L18.289,30.309Z"
        transform="translate(-15033.29 -13115.061)"
        fill="#0279ad"
      />
      <path
        id="Path_7601"
        data-name="Path 7601"
        d="M10.681-9.082a5.273,5.273,0,0,1-1.505,4.05A6.138,6.138,0,0,1,4.829-3.625H1.8V-14.333H5.159a5.661,5.661,0,0,1,4.072,1.384A5.092,5.092,0,0,1,10.681-9.082Zm-2.358.059q0-3.45-3.047-3.45H4.067V-5.5h.974Q8.323-5.5,8.323-9.023Zm14.3.029A5.81,5.81,0,0,1,21.3-4.907a4.887,4.887,0,0,1-3.779,1.428,4.887,4.887,0,0,1-3.779-1.428,5.833,5.833,0,0,1-1.318-4.1,5.754,5.754,0,0,1,1.322-4.083,4.944,4.944,0,0,1,3.79-1.41,4.875,4.875,0,0,1,3.776,1.421A5.825,5.825,0,0,1,22.62-8.994Zm-7.815,0a4.473,4.473,0,0,0,.681,2.7,2.383,2.383,0,0,0,2.036.908q2.717,0,2.717-3.611t-2.7-3.618a2.4,2.4,0,0,0-2.043.912A4.454,4.454,0,0,0,14.8-8.994Zm14.583-3.6a2.314,2.314,0,0,0-1.985.963,4.481,4.481,0,0,0-.7,2.684q0,3.582,2.688,3.582a8.5,8.5,0,0,0,2.732-.564v1.9a7.587,7.587,0,0,1-2.944.549A4.491,4.491,0,0,1,25.6-4.9a6,6,0,0,1-1.238-4.069,6.644,6.644,0,0,1,.608-2.926,4.394,4.394,0,0,1,1.747-1.926,5.168,5.168,0,0,1,2.67-.67,7.205,7.205,0,0,1,3.135.754l-.732,1.846a12.124,12.124,0,0,0-1.208-.5A3.605,3.605,0,0,0,29.387-12.6Z"
        transform="translate(-15059.159 -13063.01)"
        fill="#fff"
      />
    </g>
  </svg>,
  'DocIcon',
);
