import { makeStyles } from '@mui/styles';
import styled from 'styled-components/macro';
import { mediaMax, mediaMin } from 'styles/media';
import { theme } from 'styles/theme';
import background from '../../assets/images/bkgdimage.png';

const PageContainer = styled.span`
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  background-image: url(${background});
  background-size: cover;
  align-items: center;
  ${mediaMin.xlarge`
    height: 100%;
  `}
  ${mediaMax.xlarge`
    height: 100%;
  `}
  ${mediaMax.small`
    background-size: contain;
    background-position: bottom;
    overflow-y: scroll;

  `}
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 665px;
  ${mediaMin.xlarge`
    max-width: 1054px;
    flex-direction: row;
  `}
  ${mediaMax.xlarge`
    max-width: 1054px;
    flex-direction: row;
  `}
  ${mediaMax.medium`
    max-width: 784px;
  `}
  ${mediaMax.small`
    flex-direction: column;
    justify-content: flex-start;
    max-height: none;
    margin-top: 4rem;
  `}
`;

// Form

const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  ${mediaMax.medium`
    justify-content: flex-start;
  `}
  ${mediaMax.small`
    margin-top: 0;
  `}
`;

const FormContentContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mediaMax.small`
    margin-bottom: 2rem
  `}
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaMin.xlarge`
    width: 300px;
  `}
  ${mediaMax.xlarge`
    width: 300px;
  `}
  ${mediaMax.medium`
    width: 300px;
    margin: 0;
  `}
  ${mediaMax.small`
    width: 188px;
    margin: 0;
  `}
  margin-bottom: 2rem;
`;

const LogoImage = styled.img`
  ${mediaMin.xlarge`
    width: 300px;
  `}
  ${mediaMax.xlarge`
    width: 300px;
  `}
  ${mediaMax.small`
    width: 188px;
  `}
`;

const EmailEditContainer = styled.span`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing(2)} 0px;
`;

// Marketing

const MarketingContainer = styled.div`
  overflow-x: hidden;
  height: 871px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaMax.large`
      width:640px;
  `}
  ${mediaMax.small`
      display: none;
  `}
`;

const HeroImage = styled.img`
  width: 100%;
`;

const LockoutMessageRow = styled.span`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
  text-align: center;
  font-size: 14px;
  color: ${theme.palette.system.coolGray};
  letter-spacing: 0;
`;

const loginPageStyle = makeStyles(() => ({
  root: {
    backgroundColor: `${theme.palette.system.white}`,
  },
}));

export {
  PageContainer,
  FormContainer,
  MarketingContainer,
  LogoContainer,
  FormContentContainer,
  ContentContainer,
  HeroImage,
  LogoImage,
  LockoutMessageRow,
  EmailEditContainer,
  loginPageStyle,
};
