import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ArrowDownIcon = createSvgIcon(
  <svg
    id="Controls-Icon-Arrow-Down"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect id="Rectangle_2314" data-name="Rectangle 2314" opacity="0" />
    <path
      id="Path_3768"
      data-name="Path 3768"
      d="M35.24,42.892A1.742,1.742,0,0,1,34,42.376L17.631,25.894a1.766,1.766,0,0,1,0-2.486,1.737,1.737,0,0,1,2.471,0L35.24,38.646l14.9-15a1.739,1.739,0,0,1,2.471,0,1.768,1.768,0,0,1,0,2.487L36.476,42.376A1.742,1.742,0,0,1,35.24,42.892Z"
      transform="translate(-16.12 -13.893)"
    />
  </svg>,
  'ArrowDown',
);
