import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, Button, Divider, Typography } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Bed from 'assets/icons/Bed.svg';
import Calendar from 'assets/icons/Calendar.svg';
import Clock from 'assets/icons/Clock.svg';
import Day from 'assets/icons/Day.svg';
import Flag from 'assets/icons/Flag.svg';
import Map from 'assets/icons/Map-Pinpoint.svg';
import Mid from 'assets/icons/Mid.svg';
import Hours from 'assets/icons/Minimum-Hours-Time.svg';
import Night from 'assets/icons/Night.svg';
import Stethescope from 'assets/icons/Stethescope.svg';
import Travel from 'assets/icons/Travel-Jobs.svg';
import { JobBadge } from 'components/common/JobBadge/JobBadge';
import { AppLink } from 'components/common/Link/Link';
import domPurify from 'dompurify';
import { ShiftEnum } from 'enums/jobShifts';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { getMMMddyyyy } from 'helpers/dateFormatter';
import { useGetAuthenticatedImage } from 'helpers/getAuthenticatedImage';
import { IJobDetailsPage } from 'interfaces/Jobs/IJobDetailsPage';
import { IJobsData } from 'interfaces/Jobs/IJobsData';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getJobBadgeInformation } from 'services/jobs/jobUIService';
import { useAppDispatch } from 'store/configureStore';
import { fetchJobs } from 'store/slices/jobs/jobsSlice';
import { IJobDetailsData, IJobState } from 'store/slices/jobs/jobsState';
import { contactActions } from 'store/slices/user/userContact/userContactSlice';
import { IContactState } from 'store/slices/user/userContact/userContactState';
import { theme } from 'styles/theme';
import { ApplyJobCard } from '../ApplyJob/ApplyJobCard';
import CredentialStatus from '../CredentialStatus';

import SimilarJobs from 'components/SimilarJobs/SimilarJobs';
import SkeletonSimilarJobs from 'components/SimilarJobs/SkeletonSimilarJobs';
import {
  useMobileMediaQuery,
  useSmMediaQuery,
} from 'containers/MobileDesktopContainer/useMediaQuery';
import { IApplyJobRequest } from 'interfaces/Jobs/IApplyJobRequest';
import { IJobSearchRequestSlice } from 'interfaces/Jobs/IJobSearchRequestSlice';
import { debounce } from 'lodash';
import { usePromiseTracker } from 'react-promise-tracker';
import { trackEvent } from 'services/logging/appInsights';
import { useAppSelector } from 'store/hooks';
import { applyJob } from 'store/slices/jobs/ApplyJobsSlice';
import { jobDetailsActions } from 'store/slices/jobs/jobDetailSlice';
import { similarJobsActions } from 'store/slices/jobs/similarJobsSlice';
import { JobShare } from './JobShare';
import { TextWithUnderline } from './TextWithUnderline';

enum JobDetailSections {
  JOB_DETAILS = 'job-details-section',
  REQUIREMENTS = 'requirements-section',
  BENEFITS = 'benefits-section',
  ABOUT = 'about-section',
  SIMILAR_JOBS = 'similar-jobs-section',
}

export const JobDetailsMobile: React.FC<IJobDetailsPage> = props => {
  const {
    isFromNonConsecutiveDrawer = false,
    isNotStrikeFilter = true,
    showMap = false,
  } = props;
  const dispatch = useAppDispatch();
  const pageContent = isFromNonConsecutiveDrawer
    ? document.getElementById('drawerContent')
    : document.getElementById('pageContentId');

  // height of header + sticky horizontal scroll bar
  const upperOffset = 120;

  const { jobDetailsData } = useAppSelector(state => {
    return state.jobDetail;
  }) as IJobDetailsData;
  const userStatusTypeId = useAppSelector(state => {
    return state.userProfile.userStatusTypeId;
  }) as number;
  const userContact = useAppSelector(state => {
    return state.userContact;
  }) as IContactState;
  const userId = useAppSelector(state => state.auth.userId);
  const jobsData = useAppSelector(state => {
    return state.jobs;
  }) as IJobState;
  const similarJobsData = useAppSelector(
    state => state.similarJobs.similarJobsData,
  ) as IJobsData[];
  const jobSearchFilter = useAppSelector(state => {
    return state.jobs.jobSearchFilter;
  }) as IJobSearchRequestSlice;

  const [isSticky, setIsSticky] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState<string>(
    JobDetailSections.JOB_DETAILS,
  );
  const [selectedRecruiters, setSelectedRecruiters] = useState<number[]>([]);

  const similarJobsRef = useRef<HTMLDivElement>(null);

  const { promiseInProgress: similarJobsLoading } = usePromiseTracker({
    area: 'get-similar-jobs',
    delay: 0,
  });

  const isStrike = jobDetailsData?.placementTypeId === 500;
  const priorityTypes = jobDetailsData?.priorityTypes[0];
  const badgeDetails = getJobBadgeInformation(priorityTypes ?? 0, isStrike);

  const startDate = getMMMddyyyy(
    jobDetailsData?.startDate?.toString() === undefined
      ? ''
      : jobDetailsData?.startDate?.toString(),
  );

  const endDate = getMMMddyyyy(
    jobDetailsData?.endDate?.toString() === undefined
      ? ''
      : jobDetailsData?.endDate?.toString(),
  );

  const getShiftIcon = shiftArr => {
    if (isStrike || shiftArr?.includes(200))
      return <img height="17px" src={Mid} alt="" />;
    if (shiftArr?.includes(200))
      return <img height="17px" src={Mid} alt="Mid day icon" />;
    if (shiftArr?.includes(100))
      return <img height="17px" src={Day} alt="Day icon" />;
    if (shiftArr?.includes(300))
      return <img height="17px" src={Night} alt="Shift Icon" />;
  };

  const getShiftTimeString = shiftArr => {
    let shifts: string[] = [];
    if (isStrike) {
      shifts?.push(ShiftEnum.Days);
      shifts?.push(ShiftEnum.Mid);
      shifts?.push(ShiftEnum.Nights);
    } else {
      shiftArr?.forEach(shift => {
        if (shift === 100) {
          if (!shifts?.includes(ShiftEnum.Days)) {
            shifts?.push(ShiftEnum.Days);
          }
        } else if (shift === 200) {
          if (!shifts?.includes(ShiftEnum.Mid)) {
            shifts?.push(ShiftEnum.Mid);
          }
        } else if (shift === 300) {
          if (!shifts?.includes(ShiftEnum.Nights)) {
            shifts?.push(ShiftEnum.Nights);
          }
        }
      });
    }
    return shifts?.join(', ') ?? '';
  };

  const refreshJob = () => {
    dispatch(jobDetailsActions.setAppliedDateForJob());
  };

  const sanitizedHTMLBenefits: any = domPurify.sanitize(
    jobDetailsData?.benefits === undefined ? '' : jobDetailsData?.benefits,
  );
  const benefits: any = { __html: sanitizedHTMLBenefits.toString() };

  const sanitizedHTMLCompany: any = domPurify.sanitize(
    jobDetailsData?.company === undefined ? '' : jobDetailsData?.company,
  );
  const company: any = { __html: sanitizedHTMLCompany.toString() };

  const sanitizedHTMLRequirements: any = domPurify.sanitize(
    jobDetailsData?.requirements === undefined
      ? ''
      : jobDetailsData?.requirements,
  );
  const requirements: any = { __html: sanitizedHTMLRequirements.toString() };

  const job = jobsData?.items?.filter(j => j.jobId === jobDetailsData?.jobId);

  const jobData = job ? (job[0] as IJobsData) : null;

  const facilityImage = useGetAuthenticatedImage(
    jobDetailsData?.facility?.image || undefined,
    { height: 125 },
  );

  const onBackButtonClick = () => {
    props.onCloseClick(false);
    if (isNotStrikeFilter) {
      dispatch(fetchJobs());
    }
  };

  const handleScrollSimilarJobsIntoView = () => {
    similarJobsRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const debouncedSetActiveTabIndex = debounce(id => {
    setActiveTabIndex(prev => id);
  }, 400);

  const rate = useMemo(() => {
    let payRate = '$***';
    let maxPayRate = (jobDetailsData?.maxPayRate || '').toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );
    let minPayRate = (jobDetailsData?.minPayRate || '').toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );

    if (
      Math.round(jobDetailsData?.maxPayRate || -1) !== 0 &&
      Math.round(jobDetailsData?.minPayRate || -1) !== 0 &&
      minPayRate !== maxPayRate
    ) {
      payRate = `${minPayRate} - ${maxPayRate}`;
    } else if (Math.round(jobDetailsData?.maxPayRate || -1) !== 0) {
      payRate = maxPayRate;
    } else {
      payRate = minPayRate;
    }

    return payRate;
  }, [jobDetailsData?.maxPayRate, jobDetailsData?.minPayRate]);

  const postedDay = useMemo(() => {
    let days = 0;
    let postedDays = '';

    const postedDate = moment(jobDetailsData?.postedDate || '');
    days = moment().diff(postedDate, 'days');

    if (days === 1) {
      postedDays = `Posted ${days} Day ago`;
    } else if (days < 1) {
      days = moment().diff(postedDate, 'hours');
      postedDays = `Posted ${days} Hours ago`;
    } else {
      postedDays = `Posted ${days} Days ago`;
    }
    return postedDays;
  }, [jobDetailsData?.postedDate]);

  const handleSectionClick = useCallback(
    (id: string) => {
      const sectionElement = document.getElementById(id);

      const elementPosition = sectionElement?.getBoundingClientRect().top;
      if (!elementPosition) return;
      const offsetPosition =
        elementPosition + pageContent?.scrollTop! - upperOffset;

      pageContent?.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setActiveTabIndex(prev => id);
    },
    [pageContent],
  );

  const addressCoordinates = useMemo(() => {
    const latitude =
      jobDetailsData?.facility?.address?.location?.coordinates[1];
    const longitude =
      jobDetailsData?.facility?.address?.location?.coordinates[0];
    return `${latitude},${longitude}`;
  }, [jobDetailsData]);

  const address = useMemo(() => {
    return `${jobDetailsData?.facility?.address?.address1}${
      jobDetailsData?.facility?.address?.address2
        ? ' ' + jobDetailsData?.facility?.address?.address2
        : ''
    }, ${jobDetailsData?.facility?.address?.city}, ${
      jobDetailsData?.facility?.address?.state
    } ${jobDetailsData?.facility?.address?.zipCode}`;
  }, [jobDetailsData]);

  const hasJobDetails = useMemo(() => {
    return isStrike
      ? true
      : (jobDetailsData?.patientRatio !== '' &&
          jobDetailsData?.patientRatio !== null) ||
          jobDetailsData?.beds !== 0 ||
          jobDetailsData?.description;
  }, [
    isStrike,
    jobDetailsData?.beds,
    jobDetailsData?.description,
    jobDetailsData?.patientRatio,
  ]);

  const similarJobs = useMemo(() => {
    return (
      <div ref={similarJobsRef} style={{ width: '100%' }}>
        {similarJobsLoading ? (
          <SkeletonSimilarJobs />
        ) : similarJobsData.length > 0 ? (
          <SimilarJobs similarJobs={similarJobsData} />
        ) : null}
      </div>
    );
  }, [similarJobsData, similarJobsLoading]);

  const sectionDetails = useMemo(() => {
    let details: any = [];

    if (hasJobDetails) {
      details.push({
        description: 'Job Details',
        id: JobDetailSections.JOB_DETAILS,
      });
    }
    if (jobDetailsData?.requirements) {
      details.push({
        description: 'Requirements',
        id: JobDetailSections.REQUIREMENTS,
      });
    }
    if (jobDetailsData?.benefits) {
      details.push({
        description: 'Benefits',
        id: JobDetailSections.BENEFITS,
      });
    }
    if (jobDetailsData?.company) {
      details.push({
        description: 'About',
        id: JobDetailSections.ABOUT,
      });
    }
    if (similarJobsData?.length > 0) {
      details.push({
        description: 'Similar Jobs',
        id: JobDetailSections.SIMILAR_JOBS,
      });
    }

    return details;
  }, [
    jobDetailsData?.benefits,
    jobDetailsData?.company,
    jobDetailsData?.requirements,
    similarJobsData,
    hasJobDetails,
  ]);

  const horizontalScroll = useMemo(() => {
    return (
      <Box
        display="flex"
        sx={{
          height: isSticky ? '46px' : '34px',
          columnGap: '12px',
          paddingTop: isSticky ? '12px' : '0px',
          width: '100%',
        }}
        flexDirection="row"
      >
        {sectionDetails.map((section, idx) => {
          return (
            <TextWithUnderline
              key={`${section.description}-${idx}`}
              isActive={activeTabIndex === section.id}
              onClickCallback={() => handleSectionClick(section.id)}
            >
              {section.description}
            </TextWithUnderline>
          );
        })}
      </Box>
    );
  }, [isSticky, activeTabIndex, sectionDetails, handleSectionClick]);

  const handleSectionScroll = useCallback(() => {
    sectionDetails.forEach(section => {
      const element = document.getElementById(section.id);
      if (!element || !pageContent) return;
      const sectionTop = element.offsetTop - upperOffset;
      // add a range to avoid issue with floating point precision that is evident when using ===
      if (
        pageContent.scrollTop >= sectionTop - 5 &&
        pageContent.scrollTop <= sectionTop + 5
      ) {
        debouncedSetActiveTabIndex(section.id);
      }
    });
  }, [pageContent, sectionDetails, debouncedSetActiveTabIndex]);

  useEffect(
    () => {
      if (
        !userContact ||
        userContact.userContacts?.userContacts?.length === 0
      ) {
        dispatch(contactActions.fetchUserContacts(userId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobDetailsData?.maxPayRate, jobDetailsData?.minPayRate],
  );

  useEffect(() => {
    if (!isFromNonConsecutiveDrawer) {
      let header = document.getElementById('header');
      if (!header) return;
      header.style.display = 'none';
      return () => {
        if (header) {
          header.style.display = 'flex';
        }
      };
    }
  }, []);

  useEffect(() => {
    if (jobDetailsData?.id && userId && !isFromNonConsecutiveDrawer) {
      dispatch(
        similarJobsActions.fetchSimilarJobsAction({
          userId: userId,
          jobId: +jobDetailsData.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDetailsData?.id, userId]);

  useEffect(
    () => {
      setActiveTabIndex(sectionDetails[0].id);
      const sectionElement = document.getElementById('sticky-header');
      if (!sectionElement || !pageContent) return;

      const sectionOffsetTop = sectionElement.offsetTop - 70;

      pageContent?.addEventListener('scroll', event => {
        setIsSticky(pageContent.scrollTop >= sectionOffsetTop);
      });

      pageContent?.addEventListener('scroll', handleSectionScroll);

      return () => {
        pageContent?.removeEventListener('scroll', e => {});
        pageContent?.removeEventListener('scroll', handleSectionScroll);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isMobileDevice = useMobileMediaQuery();
  const isTabletDevice = useSmMediaQuery();

  const isWebDevices = !isMobileDevice && !isTabletDevice;

  const handleApplyJob = (recruiters: number[] | []) => {
    let recruiterArray: number[] | [] = [];
    if (Array.isArray(recruiters) && recruiters.length > 0) {
      recruiterArray = recruiters;
    }
    let request = {
      jobId: jobDetailsData?.jobId?.toString(),
      userId: userId,
      matchRanking: jobData?.matchRanking,
      recruiters: recruiterArray,
      searchSortTypeId: jobSearchFilter.sort,
    } as IApplyJobRequest;

    dispatch(
      applyJob({
        applyJob: { ...request },
        hasApplied: false,
        isLoading: false,
        externalLinkDrawerForStrikeJobApply: false,
        isStrike,
        strikeJobSignUpUrl: '',
      }),
    );

    if (showMap) {
      trackEvent('ApplyJob_MapView', {
        userId: `${userId}`,
        jobId: `${jobDetailsData?.jobId}`,
      });
    }
  };

  return (
    <>
      <Grid container id="job-details-mobile">
        <Grid
          container
          item
          xs={12}
          sx={{
            padding: '0px 32px 0px 24px',
            backgroundColor: theme.palette.system.white,
            position: isWebDevices ? 'absolute' : 'fixed',
            height: '72px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.system.border,
            zIndex: theme.zIndex.appBar,
            flexFlow: 'nowrap',
          }}
        >
          <Grid item xs={1} display="flex" justifyContent="center">
            <Button
              id="Close-JobDetailPage"
              variant="text"
              size="small"
              onClick={onBackButtonClick}
              startIcon={
                <ArrowBackIosNewIcon
                  sx={{
                    color: 'system.coolGray',
                    height: '22px',
                    width: '22px',
                  }}
                />
              }
              sx={{
                padding: '0px 0px',
              }}
            />
          </Grid>
          <Grid item xs={10} display="flex" justifyContent="center">
            <Typography
              role="job-title"
              aria-label="job-title"
              variant="subtitle1"
              color={theme.palette.system.midnightBlue}
              textAlign="center"
              noWrap
              lineHeight="24px"
              sx={{
                maxWidth: '228px',
                textOverflow: 'ellipsis',
              }}
            >
              {jobDetailsData?.title}
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            {jobDetailsData?.id && (
              <JobShare
                jobId={jobDetailsData?.id}
                shareTitle={`${jobDetailsData?.discipline} - ${jobDetailsData?.specialty}`}
              />
            )}
          </Grid>
          <Divider />
        </Grid>

        <Grid
          container
          item
          p={4}
          gap={2}
          mt={'70px'}
          sx={{
            top: '70px !important',
            backgroundColor: theme.palette.system.white,
          }}
        >
          <Grid
            container
            item
            gap={4}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ flexWrap: 'nowrap' }}
          >
            <Grid item xs={3}>
              {facilityImage ? (
                <CardMedia
                  component="img"
                  className="card-55img"
                  image={facilityImage}
                  alt={jobDetailsData?.facility?.name}
                  sx={{
                    borderRadius: '8px',
                    width: '100%',
                    height: '125px',
                  }}
                />
              ) : (
                <Box
                  className="card-55img"
                  height="125px"
                  width="80px"
                  sx={{
                    backgroundColor: 'system.lightGrayBg',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={9}>
              <Grid container gap={2}>
                <Grid
                  container
                  item
                  display="flex"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <JobBadge
                      name={badgeDetails.name}
                      src={badgeDetails.src}
                      color={badgeDetails.color}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <span className="card-tray">{postedDay}</span>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="div"
                    color={theme.palette.system.coolGray}
                  >
                    ID:
                    <Typography
                      component="span"
                      color={theme.palette.system.coolGray}
                      variant="body1"
                      fontWeight="600"
                    >
                      {` ${jobDetailsData?.sourceJobId}`}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {(jobDetailsData?.maxPayRate ?? 0) > 0 ? (
                    <Typography
                      variant="h4"
                      fontWeight="600"
                      component="div"
                      color={theme.palette.system.grassGreen}
                    >
                      {rate}
                      <Typography
                        variant="body1"
                        component="span"
                        color={theme.palette.system.coolGray}
                      >
                        {isStrike ? ' per hour' : ' per week*'}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      color={theme.palette.system.coolGray}
                    >
                      Contact for Details
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              flexWrap="wrap"
              color={theme.palette.system.subTitleBlack}
            >
              {jobDetailsData?.title}
            </Typography>
          </Grid>
          <Typography
            variant="subtitle1"
            color="system.coolGray"
            fontSize="14px"
            marginBottom="7px"
            data-testid="facility-name"
          >
            {jobDetailsData?.facility?.name || ''}
          </Typography>
          {!isStrike && (
            <Grid item xs={12} display="flex">
              <AppLink
                component="a"
                target="_blank"
                rel="noopener"
                href={`https://www.google.com/maps/place/${address}/@${addressCoordinates}`}
                id="map-icon-link"
                linkStyles={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img height="17px" src={Map} alt="Map Icon" />
              </AppLink>
              <AppLink
                component="a"
                target="_blank"
                rel="noopener"
                href={`https://www.google.com/maps/place/${address}/@${addressCoordinates}`}
                id="map-address-link"
                underline="none"
                linkStyles={{
                  color: 'system.skyBlue',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingLeft: '8px',
                }}
              >
                {address}
              </AppLink>
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            mt={1}
            display="flex"
            flexDirection="row"
          >
            <Grid item display="flex" alignItems="center">
              <img height="13px" src={Flag} alt="Flag" />
              <Typography
                variant="body1"
                color={theme.palette.system.coolGray}
                sx={{ paddingLeft: '8px', lineHeight: '19px' }}
              >
                {!isStrike && (
                  <span>
                    {startDate} – {endDate}
                  </span>
                )}
                {isStrike && <span>Start Date TBD</span>}
              </Typography>
            </Grid>
            {!isStrike && jobDetailsData?.minimumRequiredHours && (
              <Grid item display="flex" alignItems="center" pl={3}>
                <img height="14px" src={Hours} alt="Hour Glass" />
                <Typography
                  variant="body1"
                  color={theme.palette.system.coolGray}
                >
                  <span style={{ paddingLeft: '8px' }}>
                    {jobDetailsData?.minimumRequiredHours} Hrs/wk
                  </span>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {userStatusTypeId === UserStatusTypeId.ACTIVE && (
              <CredentialStatus jobId={jobDetailsData?.id?.toString() || ''} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Divider />
              <Grid
                container
                id="job-description"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {!isStrike ? (
                  <>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Calendar} alt="Calendar" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        {jobDetailsData?.duration} weeks
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Travel} alt="Travel" />{' '}
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        {jobDetailsData?.placementType}
                      </Typography>
                    </Grid>
                    {jobDetailsData?.shifts &&
                      jobDetailsData?.shifts?.length > 0 && (
                        <Grid
                          item
                          xs={6}
                          paddingTop={2}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Typography
                            variant="body1"
                            color={theme.palette.system.coolGray}
                            lineHeight="19px"
                          >
                            {getShiftIcon(jobDetailsData?.shifts)}{' '}
                            {getShiftTimeString(jobDetailsData?.shifts)}
                          </Typography>
                        </Grid>
                      )}
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Clock} alt="Clock" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        {jobDetailsData?.shiftType
                          ? jobDetailsData?.shiftType
                          : 'N/A'}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                      >
                        {getShiftIcon(jobDetailsData?.shifts)} Variable
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="17px" src={Clock} alt="Clock" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        Variable
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      paddingTop={2}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <img height="15px" src={Travel} alt="Travel" />
                      <Typography
                        variant="body1"
                        color={theme.palette.system.coolGray}
                        lineHeight="19px"
                        sx={{ paddingLeft: '6px' }}
                      >
                        Strike
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider sx={{ paddingTop: '12px' }} />
            </Box>
          </Grid>

          <>
            <Grid
              id="sticky-header"
              item
              sx={{
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                position: isSticky ? 'sticky' : 'inherit',
                top: isSticky ? '72px' : 'inherit',
                zIndex: 1,
                width: '100%',
                backgroundColor: theme.palette.system.white,
              }}
            >
              {horizontalScroll}
            </Grid>

            <Grid container item gap={1}>
              {hasJobDetails && (
                <>
                  <Grid item xs={12} id={JobDetailSections.JOB_DETAILS}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      Job Details
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                  >
                    {!isStrike && (
                      <>
                        {jobDetailsData?.patientRatio !== '' &&
                          jobDetailsData?.patientRatio !== null && (
                            <Grid
                              container
                              item
                              display="flex"
                              flexDirection="row"
                              gap={1}
                              xs={6}
                            >
                              <>
                                <Grid item>
                                  <span>
                                    <img
                                      height="17px"
                                      src={Stethescope}
                                      alt="Stethescope"
                                    />
                                  </span>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color={theme.palette.system.coolGray}
                                  >
                                    {`${jobDetailsData?.patientRatio}Patient Ratio`}
                                  </Typography>
                                </Grid>
                              </>
                            </Grid>
                          )}
                        {jobDetailsData?.beds !== 0 && (
                          <Grid
                            container
                            item
                            display="flex"
                            flexDirection="row"
                            gap={1}
                            xs={6}
                          >
                            <>
                              <Grid item>
                                <span>
                                  <img
                                    height="18px"
                                    width="18px"
                                    src={Bed}
                                    alt="Bed"
                                  />
                                </span>
                              </Grid>
                              <Grid item>
                                <Typography
                                  color={theme.palette.system.coolGray}
                                >
                                  {jobDetailsData?.beds} Beds
                                </Typography>
                              </Grid>
                            </>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid container item pb={3}>
                    <Grid item>
                      <Typography color={theme.palette.system.coolGray}>
                        {jobDetailsData?.description}
                      </Typography>
                    </Grid>

                    {isStrike && (
                      <Grid item pt={jobDetailsData?.description ? 4 : 0}>
                        <Typography color={theme.palette.system.coolGray}>
                          Pay and dates will be finalized once an offer is
                          extended. Clinicians must be flexible on shift.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}

              {jobDetailsData?.requirements && (
                <>
                  <Grid item xs={12} id={JobDetailSections.REQUIREMENTS}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      Requirements
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pb={3}>
                    <Typography
                      color={theme.palette.system.coolGray}
                      dangerouslySetInnerHTML={requirements}
                    />
                  </Grid>
                </>
              )}

              {jobDetailsData?.benefits && (
                <>
                  <Grid item xs={12} id={JobDetailSections.BENEFITS}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      Job Benefits
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pb={3}>
                    {!isStrike ? (
                      <Typography
                        color="system.coolGray"
                        dangerouslySetInnerHTML={benefits}
                      ></Typography>
                    ) : (
                      <Typography color="system.coolGray">
                        Not Included
                      </Typography>
                    )}
                  </Grid>
                </>
              )}

              {jobDetailsData?.company && (
                <>
                  <Grid item xs={12} id={JobDetailSections.ABOUT}>
                    <Typography
                      variant="body1"
                      color={theme.palette.system.grayText}
                      lineHeight="19px"
                    >
                      About the Company
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!isStrike ? (
                      <Typography
                        color="system.coolGray"
                        marginBottom="12px"
                        dangerouslySetInnerHTML={company}
                      ></Typography>
                    ) : (
                      <Typography color="system.coolGray" marginBottom="12px">
                        AMN Healthcare is a leading strike nursing staffing
                        agency that places skilled health professionals in
                        quality positions across the United States. We have
                        become an industry leader by providing a comprehensive
                        service that ensures health professionals a safe and
                        rewarding journey. Our extensive experience gives us the
                        unique ability to understand and meet a wide variety of
                        staffing needs.
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
              {!isStrike && (
                <Typography
                  variant="body1"
                  lineHeight="20px"
                  sx={{ fontStyle: 'italic' }}
                  color={theme.palette.system.coolGray}
                >
                  {
                    '*Estimate of weekly payments is intended for informational purposes and includes hourly wages, as well as reimbursements for meal & incidental expenses and housing expenses incurred on behalf of the Company. Please speak with a recruiter for additional details.'
                  }
                </Typography>
              )}
            </Grid>
          </>
        </Grid>
        {!isFromNonConsecutiveDrawer && (
          <>
            <Grid
              id={JobDetailSections.SIMILAR_JOBS}
              container
              item
              xs={12}
              sx={{
                backgroundColor: `${theme.palette.system.inputBackground}`,
                padding: '24px',
                zIndex: 0,
              }}
            >
              {similarJobs}
            </Grid>
            <Grid container item>
              <Grid
                item
                container
                xs={12}
                sx={{
                  backgroundColor: theme.palette.system.white,
                  position: 'absolute',
                  bottom: 0,
                }}
              >
                <ApplyJobCard
                  id="apply-job-mobile"
                  handleApplyJob={handleApplyJob}
                  setSelectedRecruiters={setSelectedRecruiters}
                  isMFS={jobDetailsData?.maxFilesSent}
                  appliedDate={jobDetailsData?.appliedDate}
                  jobId={(jobDetailsData?.jobId ?? '').toString()}
                  isStrike={isStrike}
                  isExpanded={true}
                  secondaryAction={refreshJob}
                  similarJobs={similarJobsData?.length > 0}
                  onScrollIntoView={handleScrollSimilarJobsIntoView}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
