import { Divider, Grid, Typography } from '@mui/material';
import { createOptions } from 'components/ActionItem/helper';
import { QualificationItemPanelListItem } from 'components/QualificationItemPanelListItem/QualificationItemPanelListItem';
import { IActionItemMenuOptions } from 'enums/actionItemMenuOptions';
import { actionItemTypeId, actionType } from 'enums/actionType';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { MenuIcons } from 'enums/menuIcons';
import { AssignmentSummaryItem } from 'interfaces/Assignment/AssignmentSummaryItem';
import { IActionItemProps } from 'interfaces/Props/IActionItemProps';
import { qualificationItemTargetTypes } from 'pages/Assignments/assignmentHelpers';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { theme } from 'styles/theme';
import mapMenuIconSection from 'utils/mapMenuIconNameSection';

export const QualificationItemPanel = ({
  selectedPlacementId,
  setDrawerOpen,
}) => {
  const [passingList, setPassingLst] = useState<any>([]);
  const selectedItem = useRef('');
  const dispatch = useDispatch();
  const history = useHistory();

  const placement: AssignmentSummaryItem = useAppSelector(state => {
    let assignments = state.assignmentSummary.assignmentState.items;
    let placementIdx = assignments.findIndex(
      assignment => assignment.placementId === selectedPlacementId,
    );
    return assignments[placementIdx];
  });

  const placementQualificationItems = useMemo(() => {
    return (placement?.actionItems || []).filter(
      item =>
        item.actionItemTypeId &&
        qualificationItemTargetTypes.includes(item.actionItemTypeId),
    );
  }, [placement]);

  const performAppNavigation = useCallback(
    selectedItem => {
      dispatch(actionItemActions.setSelectedClearanceItem(selectedItem));
      switch (selectedItem?.actionItemTypeId) {
        case actionItemTypeId.SkillsChecklistUpdateRequest:
        case actionItemTypeId.SkillsChecklistPreQualificationUpdate:
          dispatch(setActivePage(MenuIcons.SkillsChecklist));
          history.push(mapMenuIconSection(MenuIcons.SkillsChecklist));
          break;
        case actionItemTypeId.StrikeEducationUpdateRequest:
        case actionItemTypeId.WorkHistoryExperienceUpdateRequest:
        case actionItemTypeId.WorkHistoryGapUpdate:
          dispatch(setActivePage(MenuIcons.Profile));
          history.push(
            `${mapMenuIconSection(MenuIcons.Profile)}#qualification-item`,
          );
          break;
        case actionItemTypeId.LicenseUpdate:
          setDrawerOpen(DrawerContentNames.LICENSE_UPDATE);
          break;
        case actionItemTypeId.CertificationsUpdate:
          setDrawerOpen(DrawerContentNames.CERTIFICATIONS_UPDATE);
          break;
      }
    },
    [dispatch, history, setDrawerOpen],
  );

  const performActionsForSelectedItem = useCallback(
    selectedItem => {
      performAppNavigation(selectedItem);
    },
    [performAppNavigation],
  );

  const onMenuClick = useCallback(
    (menuOption: string, id: string) => {
      selectedItem.current = id;
      switch (IActionItemMenuOptions[menuOption]) {
        case IActionItemMenuOptions['Go to Item']:
          let selectItem = placementQualificationItems.find(
            item => item.id === id,
          );
          dispatch(actionItemActions.setSelectedItem(selectItem));
          performActionsForSelectedItem(selectItem);
          break;
      }
    },
    [dispatch, performActionsForSelectedItem, placementQualificationItems],
  );

  useEffect(() => {
    attachEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attachEvent = useCallback(() => {
    let passingList = placementQualificationItems.map(item => {
      let passingItem: IActionItemProps = {
        id: '',
        title: '',
        options: [],
        actionItemTypeId: 0,
        createDate: undefined,
        onMenuClick: () => {},
      };
      passingItem.id = item.id || '';
      passingItem.title = item.title || '';
      passingItem.actionItemTypeId = item.actionItemTypeId || 0;
      passingItem.options = createOptions(actionType.missingInformation);
      passingItem.onMenuClick = onMenuClick;
      return passingItem;
    });
    setPassingLst(passingList);
  }, [onMenuClick, placementQualificationItems]);

  const renderListItems = useMemo(() => {
    return passingList.length > 0 ? (
      <Grid container marginTop={'27px'}>
        {passingList &&
          passingList.map((item: IActionItemProps, index: number) => (
            <>
              <QualificationItemPanelListItem data={item} key={item.id} />
              {++index !== passingList.length && (
                <Divider
                  variant="fullWidth"
                  sx={{
                    marginTop: '15px',
                    marginBottom: '15px',
                    width: '100%',
                  }}
                />
              )}
            </>
          ))}
      </Grid>
    ) : (
      <></>
    );
  }, [passingList]);

  return (
    <>
      <Typography
        variant="body1"
        lineHeight="20px"
        color={theme.palette.system.coolGray}
      >
        Any open qualification items need to be completed before the facility
        can review your application
      </Typography>
      {renderListItems}
    </>
  );
};
