/**
 * Asynchronously loads the component for Email Validation
 */

import { lazyLoad } from 'utils/loadable';

export const EmailValidation = lazyLoad(
  () => import('./EmailValidation'),
  module => module.EmailValidation,
);
