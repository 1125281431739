import React from 'react';
import { createSvgIcon } from '@mui/material';

export const CertificationsIcon = createSvgIcon(
  <svg
    id="Controls_Icon_Certifications"
    data-name="Controls/Icon/Certifications"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 38"
  >
    <rect
      id="Rectangle_2332"
      data-name="Rectangle 2332"
      fill="none"
      opacity="0"
    />
    <g id="Group_2926" data-name="Group 2926" transform="translate(4 1.001)">
      <g
        id="Group_2921"
        data-name="Group 2921"
        transform="translate(5.453 6.83)"
      >
        <g id="Group_2919" data-name="Group 2919">
          <path
            id="Path_3806"
            data-name="Path 3806"
            d="M21.292,20.2h9.214a.842.842,0,1,0,0-1.684H21.292a.842.842,0,1,0,0,1.684Z"
            transform="translate(-20.428 -18.514)"
          />
        </g>
        <g
          id="Group_2920"
          data-name="Group 2920"
          transform="translate(0 4.155)"
        >
          <path
            id="Path_3807"
            data-name="Path 3807"
            d="M21.292,24.471H33.846a.842.842,0,1,0,0-1.684H21.292a.842.842,0,1,0,0,1.684Z"
            transform="translate(-20.428 -22.787)"
          />
        </g>
      </g>
      <g id="Group_2922" data-name="Group 2922">
        <path
          id="Path_3808"
          data-name="Path 3808"
          d="M39.983,14.165V28.287a3.755,3.755,0,0,0-.568.281,1.883,1.883,0,0,1-.678.283,1.75,1.75,0,0,1-.679-.283,3.792,3.792,0,0,0-.567-.281V14.165a.257.257,0,0,0-.249-.243H17.7a.25.25,0,0,0-.249.243V42.057a.25.25,0,0,0,.249.243h17.4v2.431H17.7a2.708,2.708,0,0,1-2.743-2.674V14.165A2.714,2.714,0,0,1,17.7,11.491H37.241A2.721,2.721,0,0,1,39.983,14.165Z"
          transform="translate(-14.96 -11.491)"
        />
      </g>
      <g
        id="Group_2925"
        data-name="Group 2925"
        transform="translate(17.674 17.383)"
      >
        <g id="Group_2923" data-name="Group 2923">
          <path
            id="Path_3809"
            data-name="Path 3809"
            d="M44.292,34.043a1.665,1.665,0,0,1-.46-.5,1.634,1.634,0,0,1,.067-.7,1.514,1.514,0,0,0-.069-1.252,1.621,1.621,0,0,0-1.208-.452,1.775,1.775,0,0,1-.7-.151,1.684,1.684,0,0,1-.356-.593c-.179-.4-.382-.849-.821-.988a.86.86,0,0,0-.256-.038,2.083,2.083,0,0,0-.971.36,1.765,1.765,0,0,1-.677.283,1.768,1.768,0,0,1-.678-.283,2.083,2.083,0,0,0-.971-.36.853.853,0,0,0-.255.038c-.44.139-.642.59-.822.988a1.7,1.7,0,0,1-.355.593,1.785,1.785,0,0,1-.7.151,1.622,1.622,0,0,0-1.208.452,1.521,1.521,0,0,0-.069,1.252,1.637,1.637,0,0,1,.068.7,1.678,1.678,0,0,1-.46.5c-.336.291-.716.621-.716,1.079s.38.788.716,1.079a1.688,1.688,0,0,1,.46.5,1.657,1.657,0,0,1-.068.7,1.519,1.519,0,0,0,.069,1.251,1.623,1.623,0,0,0,1.208.453,1.776,1.776,0,0,1,.7.151,1.678,1.678,0,0,1,.355.592c.18.4.382.849.822.988a.824.824,0,0,0,.255.039,2.083,2.083,0,0,0,.971-.36,1.772,1.772,0,0,1,.678-.284,1.754,1.754,0,0,1,.677.284,2.083,2.083,0,0,0,.971.36.86.86,0,0,0,.256-.039c.439-.139.642-.59.821-.988a1.679,1.679,0,0,1,.356-.592,1.775,1.775,0,0,1,.7-.151,1.621,1.621,0,0,0,1.208-.453A1.512,1.512,0,0,0,43.9,37.4a1.638,1.638,0,0,1-.067-.7,1.675,1.675,0,0,1,.46-.5c.335-.292.715-.621.715-1.079S44.627,34.334,44.292,34.043Zm-5.447,3.267a2.188,2.188,0,1,1,2.243-2.188A2.216,2.216,0,0,1,38.845,37.309Z"
            transform="translate(-32.681 -29.366)"
          />
        </g>
        <g
          id="Group_2924"
          data-name="Group 2924"
          transform="translate(3.147 11.344)"
        >
          <path
            id="Path_3810"
            data-name="Path 3810"
            d="M40.96,41.769a1.506,1.506,0,0,1-.459.07,2.664,2.664,0,0,1-1.3-.446c-.1-.056-.254-.144-.347-.184-.094.04-.248.127-.348.184a2.664,2.664,0,0,1-1.3.446h0a1.511,1.511,0,0,1-.459-.07,1.657,1.657,0,0,1-.909-.735v6.922a.346.346,0,0,0,.212.317.363.363,0,0,0,.383-.061l2.422-2.172,2.422,2.172a.364.364,0,0,0,.24.091.356.356,0,0,0,.355-.347V41.031A1.686,1.686,0,0,1,40.96,41.769Z"
            transform="translate(-35.836 -41.031)"
          />
        </g>
      </g>
    </g>
  </svg>,
  'CertificationsIcon',
);
